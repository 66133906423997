import React from 'react';
import { Alert as AntAlert } from 'antd';
import clsx from 'clsx';
import { useAlertStyles } from './Alert.styles';
import { ALERT_TESTID } from 'utils/testIds';
import { AlertProps } from './Alert.types';
import { AlertIcon } from './components';

/**
 * Antd Alert component wrapped with ALX logic and styles. Use it as a main alert component in the app.
 * You can override any default Antd Alert props and styles when using this component although it is highly
 * recommended to keep the default styles for consistency.
 */
export const Alert = ({
  message,
  className,
  type = 'warning',
  large = false,
  ...rest
}: AlertProps) => {
  const styles = useAlertStyles({ type, isLarge: large });

  return (
    <AntAlert
      className={clsx(styles.alert, className)}
      type={type}
      message={message}
      description={undefined}
      banner={true}
      icon={<AlertIcon type={type} className={styles.alertIcon} />}
      data-testid={ALERT_TESTID[type]}
      {...rest}
    />
  );
};
