import { StatusCodes } from 'http-status-codes';
import { generatePath } from 'react-router-dom';
import axios from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  APPEND_DOCUMENT_TEMPLATES_PANEL,
  RESET_DOCUMENT_TEMPLATES_PANEL,
  SET_DOCUMENT_TEMPLATES_PANEL,
  SET_DOCUMENT_TEMPLATES_PANEL_FETCHING,
  SET_DOCUMENT_TEMPLATES_PANEL_SELECTED_ROW,
  SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS,
  SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS,
  SET_DOCUMENT_TEMPLATES_PANEL_ERROR,
  SET_DOCUMENT_TEMPLATES_PANEL_STATUS,
  FETCH_DOCUMENTS_PANEL_PERISTANT_QUERY_PARAMS,
} from 'store/constants/documentTemplatePanel.consts';
import { selectDocumentTemplatesPanel } from 'store/selectors/documentTemplatesPanelSelectors';
import { RootAction, RootState } from 'store/reducers';
import DataFetchType from 'utils/Enums/DataFetchType';
import { apiCall } from 'utils/api';
import {
  DOCUMENT_TEMPLATE_PANEL,
  DOCUMENT_TEMPLATE_PANEL_CANCEL,
  DOCUMENT_TEMPLATE_PANEL_LIST,
} from 'utils/endpoints';
import { createTableUrl } from 'utils/functions/createTableUrl';
import {
  DocumentStatus,
  DocumentTemplatePanel,
} from 'utils/types/api/documentTemplate.types';
import {
  ActionObject,
  ColumnsMetadata,
  Restrictions,
  SetTableAction,
} from 'utils/types/api/table.types';
import { GetResponse, OptionsResponse } from 'utils/types';
import { ResponseError } from 'utils/types/errorResponse';
import isEqual from 'lodash/isEqual';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { isForbidden, isNotFound } from 'utils/apiUtils';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';
import { showSuccessToast } from 'features/toasts/utils/showSuccessToast';

export type SetDocumentTemplatesPanelAction = SetTableAction<
  Pick<ActionObject<DocumentTemplatePanel>, 'list' | 'total' | 'filtered'>,
  typeof SET_DOCUMENT_TEMPLATES_PANEL
>;

export type AppendDocumentTemplatesPanelAction = SetTableAction<
  Pick<ActionObject<DocumentTemplatePanel>, 'list' | 'total' | 'filtered'>,
  typeof APPEND_DOCUMENT_TEMPLATES_PANEL
>;

export type ResetDocumentTemplatesAction = Action<
  typeof RESET_DOCUMENT_TEMPLATES_PANEL
>;

export type SetDocumentTemplatesFetchingAction = SetTableAction<
  Pick<ActionObject<DocumentTemplatePanel>, 'fetching'>,
  typeof SET_DOCUMENT_TEMPLATES_PANEL_FETCHING
>;

export type SetDocumentTemplatesPanelSelectedRowAction = SetTableAction<
  Pick<ActionObject<DocumentTemplatePanel>, 'selectedRow'>,
  typeof SET_DOCUMENT_TEMPLATES_PANEL_SELECTED_ROW
>;

export type SetDocumentTemplatesPanelColumnsAction = SetTableAction<
  Pick<ActionObject<DocumentTemplatePanel>, 'payload'>,
  typeof SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS
>;

export type SetDocumentTemplatesPanelRestrictionsAction = SetTableAction<
  { restrictions: Restrictions },
  typeof SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS
>;

export type SetDocumentTemplatesPanelErrorAction = SetTableAction<
  { error: ResponseError | undefined },
  typeof SET_DOCUMENT_TEMPLATES_PANEL_ERROR
>;

export type SetDocumentTemplatesPanelStatusAction = SetTableAction<
  { id: string; status: DocumentStatus },
  typeof SET_DOCUMENT_TEMPLATES_PANEL_STATUS
>;

export type DocumentTemplatesPanelAction =
  | SetDocumentTemplatesPanelAction
  | AppendDocumentTemplatesPanelAction
  | ResetDocumentTemplatesAction
  | SetDocumentTemplatesFetchingAction
  | SetDocumentTemplatesPanelColumnsAction
  | SetDocumentTemplatesPanelRestrictionsAction
  | SetDocumentTemplatesPanelErrorAction
  | SetDocumentTemplatesPanelStatusAction;

export const setDocumentTemplatesPanel = (
  list: DocumentTemplatePanel[],
  total: number,
  filtered: number
): SetDocumentTemplatesPanelAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL,
  list,
  total,
  filtered,
});

export const appendDocumentTemplatesPanel = (
  list: DocumentTemplatePanel[],
  total: number,
  filtered: number
): AppendDocumentTemplatesPanelAction => ({
  type: APPEND_DOCUMENT_TEMPLATES_PANEL,
  list,
  total,
  filtered,
});

export const resetDocumentTemplates = (): ResetDocumentTemplatesAction => ({
  type: RESET_DOCUMENT_TEMPLATES_PANEL,
});

export const setFetchingDocumentTemplatesPanel = (
  fetching: boolean
): SetDocumentTemplatesFetchingAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL_FETCHING,
  fetching,
});

export const setDocumentTemplatesPanelSelectedRow = (
  data: string | undefined
): SetDocumentTemplatesPanelSelectedRowAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL_SELECTED_ROW,
  selectedRow: data,
});

export const setDocumentTemplatePanelColumns = (
  columns: ColumnsMetadata[]
): SetDocumentTemplatesPanelColumnsAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL_COLUMNS,
  payload: columns,
});

export const setDocumentTemplatePanelRestrictions = (
  restrictions: Restrictions
): SetDocumentTemplatesPanelRestrictionsAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL_RESTRICTIONS,
  restrictions,
});

export const setDocumentTemplatePanelError = (
  error: ResponseError | undefined
): SetDocumentTemplatesPanelErrorAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL_ERROR,
  error,
});

export const setDocumentTemplatePanelStatus = (
  id: string,
  status: DocumentStatus
): SetDocumentTemplatesPanelStatusAction => ({
  type: SET_DOCUMENT_TEMPLATES_PANEL_STATUS,
  id,
  status,
});

export const getDocumentTemplatePanelColumnConfiguration = (
  recordId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const {
      status,
      data: { restrictions, list: { columns = [] } = {} } = {},
    } = await apiCall.options<OptionsResponse>(
      generatePath(DOCUMENT_TEMPLATE_PANEL_LIST, { recordId })
    );
    if (status === StatusCodes.OK) {
      dispatch(setDocumentTemplatePanelColumns(columns));
      if (restrictions !== undefined) {
        dispatch(setDocumentTemplatePanelRestrictions(restrictions));
      }
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status)
      dispatch(
        setDocumentTemplatePanelError({
          status: err.response?.status,
        })
      );
  }
};

export const getDocumentTemplatesPanel = (
  recordId: string,
  queryParams?: string,
  fetchType:
    | DataFetchType.Append
    | DataFetchType.Overwrite = DataFetchType.Overwrite
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(setFetchingDocumentTemplatesPanel(true));
  try {
    const { status, data } = await apiCall.get(
      createTableUrl(
        generatePath(DOCUMENT_TEMPLATE_PANEL_LIST, { recordId }),
        `${FETCH_DOCUMENTS_PANEL_PERISTANT_QUERY_PARAMS}&${queryParams}`
      )
    );

    if (status === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setDocumentTemplatesPanel(
            data.results,
            data.filtered_count,
            data.filtered_count
          )
        );
      } else {
        dispatch(
          appendDocumentTemplatesPanel(
            data.results,
            data.filtered_count,
            data.filtered_count
          )
        );
      }
    }
  } catch (err) {
    if (axios.isAxiosError(err) && err.response?.status)
      dispatch(
        setDocumentTemplatePanelError({
          status: err.response?.status,
        })
      );
  } finally {
    dispatch(setFetchingDocumentTemplatesPanel(false));
  }
};

export const refetchDocumentTemplatePanelByIds = (
  recordId: string,
  documentTemplateIds: string[]
): ThunkAction<void, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  const showFailedDownloadToast = () =>
    showErrorToast({
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage({
        id: 'documentTemplatesPanel.failedToDownloadDocument',
        defaultMessage: 'Failed to download the document.',
      }),
    });

  try {
    const documentTemplates = await dispatch(
      getDocumentTemplatesPanelByIds(recordId, documentTemplateIds)
    );

    if (documentTemplates) {
      documentTemplateIds.forEach(id => {
        const index = documentTemplates.results.findIndex(
          item => item.id === id
        );
        if (index < 0) {
          documentTemplates.results[index].generated_document = null;
          showFailedDownloadToast();
        }
      });

      dispatch(setDocumentTemplateItems(documentTemplates.results, recordId));
    }
  } catch {
    //Reset document template status to initial value if document generate process failed
    const documentTemplates = [...selectDocumentTemplatesPanel(getState())];
    documentTemplateIds.forEach(id => {
      const index = documentTemplates.findIndex(item => item.id === id);
      documentTemplates[index].generated_document = null;
    });
    dispatch(setDocumentTemplateItems(documentTemplates, recordId));

    showFailedDownloadToast();
  }
};

export const generateAndDownloadDocumentTemplatePanel = (
  object_record: string,
  document_template: string,
  object_record_field?: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    await apiCall.post(generatePath(DOCUMENT_TEMPLATE_PANEL), {
      object_record,
      document_template,
      object_record_field,
    });
    dispatch(
      setDocumentTemplatePanelStatus(
        document_template,
        DocumentStatus.Processing
      )
    );
  } catch (err) {
    //when no permission to edit record - only display toast
    if (isForbidden(err) && object_record_field) {
      showErrorToast({
        title: GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.error',
          defaultMessage: 'Error!',
        }),
        subtitle: GlobalIntlSingleton.intl.formatMessage({
          id: 'documentTemplatesPanel.noPermissionToEditTheRecord',
          defaultMessage:
            'No permission to edit the record. Unable to upload the generated document.',
        }),
      });
      return;
    }

    if (axios.isAxiosError(err) && err.response?.status) {
      const code = err.response?.status;

      dispatch(
        setDocumentTemplatePanelError({
          // Treat 'bad request' as 'not found' because API cannot handle custom code errors ¯\_(ツ)_/¯
          status:
            code === StatusCodes.BAD_REQUEST ? StatusCodes.NOT_FOUND : code,
        })
      );
    }

    showErrorToast({
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage({
        id: 'documentTemplatesPanel.failedToDownloadDocument',
        defaultMessage: 'Failed to download the document.',
      }),
    });
  }
};

export const cancelDocumentTemplateGeneration = (
  recordId: string,
  documentTemplateId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    await apiCall.post(
      generatePath(DOCUMENT_TEMPLATE_PANEL_CANCEL, {
        recordId,
        id: documentTemplateId,
      })
    );
    dispatch(
      setDocumentTemplatePanelStatus(
        documentTemplateId,
        DocumentStatus.Canceled
      )
    );
  } catch (err) {
    if (
      axios.isAxiosError(err) &&
      err.response?.status &&
      err.response?.status !== 400
    )
      dispatch(
        setDocumentTemplatePanelError({
          status: err.response?.status,
        })
      );
  }
};

export const downloadGeneratedDocumentTemplate = (
  fileUrl: string,
  fileName?: string,
  displayToast = false
): ThunkAction<void, RootState, undefined, RootAction> => async () => {
  try {
    //We only need pathname without origin which cause CORS error on localhost environment
    const { pathname } = new URL(fileUrl);
    const { data } = await apiCall.get(pathname, { responseType: 'blob' });

    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = URL.createObjectURL(data);
    link.download = fileName ?? 'file.docx';

    link.click();
    URL.revokeObjectURL(link.href);

    if (displayToast)
      showSuccessToast({
        title: GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.success',
          defaultMessage: 'Success!',
        }),
        subtitle: GlobalIntlSingleton.intl.formatMessage({
          id: 'documentTemplatesPanel.documentSuccessfullyGenerated',
          defaultMessage:
            'The document has been successfully generated and is being downloaded.',
        }),
      });
  } catch (err) {
    if (isNotFound(err))
      showErrorToast({
        title: GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.error',
          defaultMessage: 'Error!',
        }),
        subtitle: GlobalIntlSingleton.intl.formatMessage({
          id: 'documentTemplatesPanel.templateNotFound',
          defaultMessage: 'Document template not found or inaccessible.',
        }),
      });

    if (isForbidden(err))
      showErrorToast({
        title: GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.error',
          defaultMessage: 'Error!',
        }),
        subtitle: GlobalIntlSingleton.intl.formatMessage({
          id: 'documentTemplatesPanel.insufficientPermissions',
          defaultMessage:
            'Insufficient permissions to generate the document. Please contact System Administrator.',
        }),
      });
  }
};

//
// PRIVATE HELPFUL METHODS
//

const setDocumentTemplateItems = (
  newDocumentTemplates: DocumentTemplatePanel[],
  recordId?: string,
  newTotal?: number,
  newFiltered?: number
): ThunkAction<void, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  const { filtered, total } = getState().documentTemplatesPanel;
  const originalDocumentTemplates = selectDocumentTemplatesPanel(getState());
  const updatedDocumentTemplates = [...originalDocumentTemplates];

  newDocumentTemplates.forEach(item => {
    const index = updatedDocumentTemplates.findIndex(el => el.id === item.id);
    updatedDocumentTemplates[index] = { ...item, key: item.id };
  });

  //Only mutate if arrays are different
  if (!isEqual(originalDocumentTemplates, updatedDocumentTemplates)) {
    if (recordId) {
      dispatch(
        handleNewDocumentTemplateStatuses(
          originalDocumentTemplates,
          updatedDocumentTemplates
        )
      );
    }

    dispatch(
      setDocumentTemplatesPanel(
        updatedDocumentTemplates,
        newTotal ?? total,
        newFiltered ?? filtered
      )
    );
  }
};

const getDocumentTemplatesPanelByIds = (
  recordId: string,
  documentTemplateIds: string[]
): ThunkAction<
  Promise<GetResponse<DocumentTemplatePanel> | undefined>,
  RootState,
  undefined,
  RootAction
> => async () => {
  const { data } = await apiCall.get<GetResponse<DocumentTemplatePanel>>(
    generatePath(DOCUMENT_TEMPLATE_PANEL_LIST, {
      recordId,
    }),
    {
      params: {
        id__in: documentTemplateIds.join(','),
      },
    }
  );
  return data;
};

const handleNewDocumentTemplateStatuses = (
  originalDocumentTemplates: DocumentTemplatePanel[],
  updatedDocumentTemplates: DocumentTemplatePanel[]
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  for (const template of updatedDocumentTemplates) {
    const originalTemplate = originalDocumentTemplates.find(({ id }) => {
      return id === template.id;
    });

    const wasProcessing =
      originalTemplate?.generated_document?.status ===
      DocumentStatus.Processing;
    const newStatus = template.generated_document?.status;
    const fileUrl = template.generated_document?._meta?.labels?.document?.url;
    const fileName = template.generated_document?._meta?.labels?.document?.name;

    if (!wasProcessing) {
      continue;
    }

    switch (newStatus) {
      case DocumentStatus.Completed: {
        if (!fileUrl) {
          break;
        }

        dispatch(downloadGeneratedDocumentTemplate(fileUrl, fileName, true));

        break;
      }
      case DocumentStatus.FieldUpdated: {
        if (!fileUrl) {
          break;
        }

        showSuccessToast({
          title: GlobalIntlSingleton.intl.formatMessage({
            id: 'misc.success',
            defaultMessage: 'Success!',
          }),
          subtitle: GlobalIntlSingleton.intl.formatMessage({
            id: 'documentTemplatesPanel.documentSuccessfullyGeneratedAndSaved',
            defaultMessage:
              'The document has been successfully generated and is saved to the field.',
          }),
        });

        break;
      }
      case DocumentStatus.FieldNotUpdated: {
        showErrorToast({
          title: GlobalIntlSingleton.intl.formatMessage({
            id: 'documentTemplatesPanel.documentUploadUnsuccessful',
            defaultMessage: 'Document upload unsuccessful!',
          }),
          subtitle: template.generated_document?.error_message,
        });

        break;
      }
      case DocumentStatus.Failed: {
        showErrorToast({
          title: GlobalIntlSingleton.intl.formatMessage({
            id: 'documentTemplatesPanel.documentGenerationUnsuccessful',
            defaultMessage: 'Document generation unsuccessful!',
          }),
          subtitle: template.generated_document?.error_message,
        });

        break;
      }
    }
  }
};
