import { useIntl } from 'react-intl';

export const useEventSourceName = (
  eventSourceName: string | null,
  sourceId: string | number
): string => {
  const intl = useIntl();

  return (
    eventSourceName ||
    intl.formatMessage(
      { id: 'activityLog.user', defaultMessage: 'user {sourceId}' },
      { sourceId }
    )
  );
};
