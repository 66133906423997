import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseCustomUserWidgetStyles = {
  isFieldDisabled?: boolean;
  withUnderline?: boolean;
};

export const useUsersAndGroupsExpandableSelectStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      checkmarkIcon: {
        color: theme['primary-color'],
      },
      mainDropdown: {
        padding: 0,
        maxWidth: '100%',
        minWidth: '200px !important',
        overflow: 'visible',
      },
      selectItem: {
        minHeight: 47,
        lineHeight: '19px',
        '& .ant-select-item-option-content': {
          display: 'flex',
          alignItems: 'center',
        },
        '& mark': {
          padding: 0,
          backgroundColor: 'inherit',
        },
        '&.ant-select-item-option-disabled ': {
          '& .ant-avatar': {
            background: theme['border-color-base'],
          },
          '& .ant-select-item-option-content > div:not(:first-child)': {
            color: theme['disabled-switch-gray'],
            '& mark': {
              color: theme['disabled-switch-gray'],
            },
          },
        },
      },
      avatars: ({
        isFieldDisabled,
        withUnderline,
      }: UseCustomUserWidgetStyles) => {
        const cursor = isFieldDisabled ? 'not-allowed' : 'pointer';
        const borderBottom = withUnderline
          ? `1px solid ${theme['border-color-base']}`
          : 'none';

        return {
          display: 'flex',
          alignItems: 'center',
          background: 'transparent',
          outline: 'none',
          border: '0px',
          width: '100%',
          cursor: cursor,
          borderBottom: borderBottom,

          '&:hover': {
            cursor: cursor,
          },
          '&:focus': {
            outline: `none`,
          },
        };
      },
      optionToolTip: {
        width: '100%',
      },
      optionContent: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      },
      minMaxInfo: {
        marginTop: 0,
      },
      editUserWrapper: {
        position: 'relative',
        display: 'flex',
        width: '100%',
      },
      messageBox: {
        bottom: '0px',
        left: 1,
        width: 348,
        borderTop: `1px solid ${theme['border-color-base']}`,
        position: 'relative',
      },
      messageBoxOnTop: {
        borderBottom: `1px solid ${theme['border-color-base']}`,
        borderTop: 'none !important',
        zIndex: -1,
      },
      emptyContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 18,
      },
    };
  }
);
