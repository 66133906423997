import React, { useCallback, useEffect, useState } from 'react';
import { TaskList } from './components/TaskList';
import { CreateTask } from './components/CreateTask';
import { ReassignTask } from './components/ReassignTask';
import { AddTaskAssignees } from './components/AddTaskAssignees';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { TasksPanelProvider } from './contexts/TasksPanelContext';
import { TasksPanelMode } from './types';
import { useTasksPanelStyles } from './TasksPanel.styles';
import { TaskOptionsProvider } from './contexts/TaskOptionsContext';

export const TasksPanel = () => {
  const styles = useTasksPanelStyles();

  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();

  const [mode, setMode] = useState<TasksPanelMode>(TasksPanelMode.List);

  const Panel = useCallback(() => {
    switch (mode) {
      case TasksPanelMode.Create: {
        return <CreateTask />;
      }
      case TasksPanelMode.Reassign: {
        return <ReassignTask />;
      }
      case TasksPanelMode.AddAssignees: {
        return <AddTaskAssignees />;
      }
      default:
        return <TaskList />;
    }
  }, [mode]);

  /**
   * Resets mode to initial after new record has been selected
   */
  useEffect(() => {
    setMode(TasksPanelMode.List);
  }, [recordId]);

  return (
    <div className={styles.container}>
      <TasksPanelProvider setMode={setMode}>
        <TaskOptionsProvider>
          <Panel />
        </TaskOptionsProvider>
      </TasksPanelProvider>
    </div>
  );
};
