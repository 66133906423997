import ChildClassLabel from 'components/lib/ChildClassComponent/components/ChildClassLabel';
import { useChildClassWidgetStyles } from 'components/lib/ChildClassComponent/styles';
import { CatalystTable } from 'components/CatalystTable';
import { RowsDisplay } from 'components/CatalystTable/types/rowsDisplay';
import React from 'react';
import { setNestedObjectRecordsSelectedRow } from 'store/actions/nestedObjectRecordsActions';
import { getNestedObjectRecordsSelectedRow } from 'store/selectors/nestedObjectRecordsSelctors';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { CHILD_CLASS_TABLE_WRAPPER } from 'utils/testIds';
import { ChildClassMultiplicity } from 'utils/types/api/objectClassModels.types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import ActionMenuItems from '../ActionMenuItems';
import { useChildClassTableActions } from '../ActionMenuItems/hooks';
import { childClassTablePaginationWithHidden } from '../ChildClassPagination';
import { childClassEmptyTableWithCustomMessage } from './components/ChildClassEmptyTable';
import useChildClassTable, { useChildClassTableUIConfig } from './hooks';
import { ChildClassTableProps } from './types';
import { childClassPageSizes, childClassUICOmponentStates } from './enums';
import { CHILD_CLASS_TABLE_ID_PREFIX } from './consts';

const childClassTablePageSizes = ['5'];
const preventFromHideColumns = ['ID'];

const ChildClassTable = ({
  classId,
  recordId,
  readOnly,
  identifier,
  config,
  label,
  multiplicity,
  hasCreateEditViewEnabled,
}: ChildClassTableProps) => {
  const {
    fetchMethod,
    dataSelector,
    clearSelectedForFiltering,
    getColumnsConfiguration,
    resetColumns,
    resetData,
    selectedForFiltering,
    resetPaginationRef,
    handleSingleClick,
    customDataGroups,
    tableHeaderResults,
    childClassTableAddtionalHeader,
    refetchMethodRef,
    limitExceededNotification: renderAdditionalHeader,
  } = useChildClassTable({
    classId,
    recordId,
    config,
    readOnly,
    multiplicity,
    label,
    hasCreateEditViewEnabled,
  });

  const {
    selectedAction,
    onActionMenuOptionClick,
    onActionCellClick,
    modalData,
    onOpenClick,
    confirmationData,
    setModalData,
  } = useChildClassTableActions({
    identifier,
    recordId,
    classId,
    clearSelectedForFiltering,
    selectedForFiltering,
    hasCreateEditViewEnabled,
    label,
  });

  const permissionBasedConfig = useChildClassTableUIConfig({
    readOnly,
    childClassId: classId,
  });

  const widgetClasses = useChildClassWidgetStyles({});

  const tableId = `${CHILD_CLASS_TABLE_ID_PREFIX}${classId}`;

  return (
    <>
      <div
        className={widgetClasses.header}
        data-testid={CHILD_CLASS_TABLE_WRAPPER}
        id={tableId}
      >
        <ChildClassLabel
          label={label}
          overlayContainerClassName={widgetClasses.overlayContainer}
          inputLabelClassName={widgetClasses.inputLabel}
        />
        {tableHeaderResults}
      </div>
      <CatalystTable<ObjectRecordDetails>
        onDoubleClick={onOpenClick}
        renderEmptyTable={childClassEmptyTableWithCustomMessage(
          readOnly,
          permissionBasedConfig
        )}
        setSelectedRow={setNestedObjectRecordsSelectedRow}
        getSelectedRow={getNestedObjectRecordsSelectedRow}
        fetchMethod={fetchMethod}
        dataSelector={dataSelector()}
        getColumnsConfiguration={getColumnsConfiguration}
        resetData={resetData}
        preventFromHideColumns={preventFromHideColumns}
        columnLabelKey={CatalystTableType.NestedTable}
        resetColumns={resetColumns}
        withColumnConfiguration={false}
        withDraggableRows={false}
        additionalHeaderActions={childClassTableAddtionalHeader}
        withPagination={true}
        withoutSort={true}
        onSingleClick={handleSingleClick}
        withFiltersDefault={false}
        customDataGroups={customDataGroups}
        isPaginationUsingURL={false}
        isPageSizeDisabled={true}
        rowsDisplay={RowsDisplay.FIXED}
        hasListPermissions={true}
        pageSizes={childClassTablePageSizes}
        preferencesId={classId}
        preventInteractionWithColumns={true}
        preventOverflow={true}
        onActionCellClick={onActionCellClick}
        modalData={modalData}
        setModalData={setModalData}
        paginationRender={childClassTablePaginationWithHidden(
          multiplicity === ChildClassMultiplicity.ZERO_OR_ONE ||
            permissionBasedConfig.paging === childClassUICOmponentStates.HIDDEN
        )}
        confirmationData={confirmationData}
        withBordersAroundTable={true}
        fullWidth={true}
        refetchMethodRef={refetchMethodRef}
        pageSize={
          multiplicity === ChildClassMultiplicity.ZERO_OR_MORE
            ? childClassPageSizes.ZERO_OR_MORE
            : childClassPageSizes.ZERO_OR_ONE
        }
        renderHeaderLimit={renderAdditionalHeader}
        resetPaginationRef={resetPaginationRef}
        defaultSortColumn={recordId ? '-created_at' : undefined}
        alwaysDisplayPagination={true}
      />
      {selectedAction?.actionId && (
        <ActionMenuItems
          {...{
            hasCreateEditViewEnabled,
            onActionMenuOptionClick,
            ...selectedAction,
            isReadOnly: readOnly,
            tableId,
          }}
        />
      )}
    </>
  );
};

export default ChildClassTable;
