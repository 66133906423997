import { Select, SelectOption } from 'components/lib/Select';
import React from 'react';
import { useIntl } from 'react-intl';
import { FilterBooleanValueProps } from './FilterBooleanValue.types';
import { PredicateSet } from 'utils/types/predicates.types';

export const FilterBooleanValue = ({
  value,
  predicateSet,
  className,
  testId,
  onChange,
}: FilterBooleanValueProps) => {
  const intl = useIntl();

  const yesText =
    predicateSet === PredicateSet.ClassFields
      ? intl.formatMessage({
          id: 'bool.checked',
          defaultMessage: 'Checked',
        })
      : intl.formatMessage({
          id: 'bool.yes',
          defaultMessage: 'Yes',
        });
  const noText =
    predicateSet === PredicateSet.ClassFields
      ? intl.formatMessage({
          id: 'bool.unchecked',
          defaultMessage: 'Unchecked',
        })
      : intl.formatMessage({
          id: 'bool.no',
          defaultMessage: 'No',
        });

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <Select
      className={className}
      value={value}
      placeholder={intl.formatMessage({
        id: 'predicates.select',
        defaultMessage: 'Select',
      })}
      data-testid={testId}
      onChange={handleChange}
    >
      <SelectOption key='true' value='true'>
        {yesText}
      </SelectOption>
      <SelectOption key='false' value='false'>
        {noText}
      </SelectOption>
    </Select>
  );
};
