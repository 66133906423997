import AutocompleteSelect from 'components/AutocompleteSelect';
import React from 'react';
import { FilterAutocompleteSelectValueProps } from './FilterAutocompleteSelectValue.types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';

export const FilterAutocompleteSelectValue = ({
  value,
  autocompleteUrl,
  isMultiSelect,
  testId,
  onChange,
}: FilterAutocompleteSelectValueProps) => {
  const handleChange = (
    value: AutocompleteSelectValue | AutocompleteSelectValue[]
  ) => {
    onChange(value);
  };

  return (
    <AutocompleteSelect
      onChange={handleChange}
      value={value}
      autocompleteUrl={autocompleteUrl}
      selectMultiple={isMultiSelect}
      dataTestId={testId}
    />
  );
};
