import { createUseStyles } from 'react-jss';

export const useAddFilterButtonStyles = createUseStyles({
  tooltip: {
    width: 'fit-content',
  },
  addFilterBtn: {
    display: 'grid',
    placeContent: 'center',
  },
});
