import React, { useCallback } from 'react';
import { Popover as AntPopover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import { CONTENT_ID } from 'utils/elementsIds';

const Popover: React.FC<PopoverProps> = ({ getPopupContainer, ...rest }) => {
  const defaultGetPopupContainer = useCallback(() => {
    // This absolutely kills performance anytime Popover tries to open...
    return document.getElementById(CONTENT_ID) || document.body;
  }, []);

  return (
    <AntPopover
      transitionName=''
      getPopupContainer={getPopupContainer ?? defaultGetPopupContainer}
      {...rest}
    />
  );
};

export default Popover;
