import React from 'react';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import {
  DocumentStatus,
  DocumentTemplatePanel,
} from 'utils/types/api/documentTemplate.types';
import { useDocumentTemplateStatusStyles } from './DocumentTemplateStatus.styles';
import { DocumentTemplateDownload } from './components/DocumentTemplateDownload';
import { DocumentTemplateProcessing } from './components/DocumentTemplateProcessing';

export const DocumentTemplateStatus = ({
  id: documentTemplateId,
  generated_document,
}: DocumentTemplatePanel) => {
  const styles = useDocumentTemplateStatusStyles();
  const { selectedResource } = useSelectedResourceContext();

  const recordId = selectedResource?.record?.recordId;
  const { status } = generated_document ?? {};

  if (!status || !recordId) {
    return null;
  }

  return (
    <div className={styles.icons}>
      {status === DocumentStatus.Processing && (
        <DocumentTemplateProcessing {...{ recordId, documentTemplateId }} />
      )}
      {generated_document?.document && status !== DocumentStatus.Processing && (
        <DocumentTemplateDownload
          {...{ recordId, generated_document, documentTemplateId }}
        />
      )}
    </div>
  );
};
