import React from 'react';
import { InPlaceEditSingleSelectProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import useInPlaceEditSingleSelectStyles from './styles';
import { Radio } from 'components/lib/Radio';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Select } from 'components/lib/Select';
import useMessageBoxStyles from '../components/MessageBox/styles';
import clsx from 'clsx';
import { Dropdown } from 'components/lib/Dropdown';
import useCommonInPlaceEditStyles from '../components/commonStyles';
import { IN_PLACE_EDIT_SINGLE_SELECT } from 'utils/testIds';
import TooltipString from 'components/TooltipString';
import { CloseCircleFilled } from '@ant-design/icons';
import { InPlaceSingleSelectDropdownContent } from './components';

const InPlaceEditSingleSelect = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  areRadios,
  options = [],
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditSingleSelectProps<R>) => {
  const {
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOn,
    setErrorMessage,
    setIsSavingOn,
    inputWrapperRef,
    errorMessage,
    editWrapperRef,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });

  const styles = useInPlaceEditSingleSelectStyles({ errorMessage, size });
  const errorMessages = useErrorMessages();
  const classes = useMessageBoxStyles({ isError: !!errorMessage });
  const commonStyles = useCommonInPlaceEditStyles({
    isSaving,
    errorMessage,
  });

  const onViewClick = () => {
    if (disabled) return;

    setIsEditModeOn();
  };

  const onInputChange = (val: string | null) => {
    if (required && !val)
      return setErrorMessage(
        errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]
      );
    else setErrorMessage(undefined);

    setTempValue(val);
    setIsSavingOn();
  };

  return (
    <div className={styles.singleSelectWrapper}>
      <InPlaceEditWrapper
        {...{
          isEditMode,
          isSaving,
          label,
          required,
          disabled,
          inputWrapperRef,
          onViewClick,
          readOnly,
          size,
          withUnderline,
        }}
        actionButtonOnTop={isEditMode}
        maxWidth={350}
        editContent={
          <div data-testid={IN_PLACE_EDIT_SINGLE_SELECT}>
            {areRadios ? (
              <Dropdown
                {...{ getPopupContainer, remountChangeTarget }}
                visible
                overlayClassName={styles.dropdownOverlay}
                overlay={
                  <div className={styles.radiosList} ref={editWrapperRef}>
                    <div className={styles.radiosOptions}>
                      {options.map(option => (
                        <Radio
                          key={`option-${option}`}
                          value={option}
                          checked={option === tempValue}
                          className={styles.radioWrapper}
                          onChange={(e: RadioChangeEvent) =>
                            onInputChange(e.target.value)
                          }
                        >
                          {option}
                        </Radio>
                      ))}
                    </div>
                    {!isSaving && !!errorMessage && (
                      <div className={clsx(classes.box, styles.dropdownError)}>
                        {errorMessage}
                      </div>
                    )}
                  </div>
                }
              >
                <div className={commonStyles.dropdownAnchor}></div>
              </Dropdown>
            ) : (
              <div className={styles.selectContainer}>
                <Select
                  {...{ getPopupContainer }}
                  defaultOpen
                  autoFocus
                  value={tempValue}
                  onChange={onInputChange}
                  options={options.map(option => ({
                    value: option,
                    label: option,
                  }))}
                  className={styles.dropdownWrapper}
                  dropdownClassName={clsx(
                    styles.dropdownOptionsWrapper,
                    styles.dropdownOverlay,
                    {
                      [styles.dropdownOptionsWithError]:
                        !isSaving && !!errorMessage,
                    }
                  )}
                  dropdownRender={items => (
                    <InPlaceSingleSelectDropdownContent
                      items={items}
                      errorMessage={errorMessage}
                      isSaving={isSaving}
                    />
                  )}
                  open={errorMessage ? true : undefined}
                />

                {value ? (
                  <span
                    className={styles.selectClearIconWrapper}
                    onClick={() => onInputChange(null)}
                    role='button'
                    tabIndex={0}
                  >
                    <CloseCircleFilled />
                  </span>
                ) : null}
              </div>
            )}
          </div>
        }
        viewContent={
          !!value?.length ? (
            <TooltipString text={value} className={styles.singleLinePreview} />
          ) : (
            '-'
          )
        }
      />
    </div>
  );
};

export default InPlaceEditSingleSelect;
