import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { apiCall } from 'utils/api';
import { USERS_LIST_AUTOCOMPLETE_IN } from 'utils/endpoints';
import { UserMeta } from 'utils/types/api/objectClassesFields.types';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const getAvailableUsers = async (users: UserMeta[]) => {
  const ids = users?.map(({ id }) => id); //existing and deletetd user ids
  const url = `${USERS_LIST_AUTOCOMPLETE_IN}${ids.join(',')}`;

  try {
    const {
      data: { results }, //existing users
    } = await apiCall.get(url);

    return users?.filter(({ id }) =>
      results.some((u: UserMeta) => u.id === id)
    );
  } catch {
    return [];
  } finally {
    showErrorToast({
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage({
        id: 'errors.usersDeletetd',
        defaultMessage:
          'Users deleted from the system. Please review and update the user selection.',
      }),
    });
  }
};
