import {
  UserGroupUsageItem,
  UserGroupPermissionSetUsageItem,
} from 'utils/types/api/userGroups.types';

export const transformUserGroups = (userGroups: UserGroupUsageItem[]) => {
  return userGroups.reduce((acc, userGroup) => {
    return [...acc, ...userGroup.permission_sets];
  }, [] as UserGroupPermissionSetUsageItem[]);
};
