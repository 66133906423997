import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import AutocompleteSelect from 'components/AutocompleteSelect';
import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import { DateRangePickerInput } from 'components/Inputs/DatePicker';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import { Select } from 'components/lib/Select';
import { USERS_LIST_AUTOCOMPLETE } from 'utils/endpoints';
import {
  EVENT_FIELD_FILTER,
  EVENT_TYPE_FILTER,
  EVENT_DATE_FILTER,
} from 'utils/testIds';
import { EventFiltersProps, EventFiltersType } from './types';
import {
  useEventDateFilter,
  useEventDocumentGeneratedFilter,
  useEventFieldsFilter,
  useEventTypeFilter,
  useEventUsersFilter,
} from './hooks';
import useEventFiltersStyles from './styles';

const EventFilters = ({ queryParams, setQueryParams }: EventFiltersProps) => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement | null>(null);
  const classes = useEventFiltersStyles({ offset: ref?.current?.offsetWidth });

  const {
    ranges,
    disabledDate,
    disabledTime,
    handleEventDate,
  } = useEventDateFilter();
  const {
    checkOptionIsSelected,
    handleEventUserClear,
    handleEventUser,
  } = useEventUsersFilter();
  const { eventTypeOptions, handleEventType } = useEventTypeFilter();
  const {
    handleEventFields,
    isFieldValueUpdateSelected,
    autocompleteUrl,
  } = useEventFieldsFilter(queryParams);
  const {
    handleDocumentGeneratedSource,
    handleDocumentGeneratedSourceClear,
    isDocumentGeneratedSelected,
    documentSourceOptions,
  } = useEventDocumentGeneratedFilter(queryParams);

  return (
    <div className={classes.eventFiltersWrapper} ref={ref}>
      <DateRangePickerInput
        isExternal={false}
        withCustomPopupStyles
        showTime
        picker='date'
        size='small'
        onChange={handleEventDate(setQueryParams)}
        data-testid={EVENT_DATE_FILTER}
        {...{ ranges, disabledDate, disabledTime }}
      />

      <div className={classes.eventUsersFilterWrapper}>
        <AutocompleteUsersSelect
          value={queryParams.source_id__in}
          onChange={handleEventUser(setQueryParams, queryParams)}
          onClear={handleEventUserClear(setQueryParams)}
          allowClear
          autocompleteUrl={USERS_LIST_AUTOCOMPLETE}
          checkOptionIsDisabled={() => false}
          renderOption={(option, searchValue) => (
            <OptionAvatar
              disabledText={undefined}
              selected={checkOptionIsSelected(queryParams)(option)}
              {...{ option, searchValue }}
            />
          )}
        />
      </div>

      <Select
        options={eventTypeOptions}
        defaultValue={EventFiltersType.All}
        onChange={handleEventType(setQueryParams)}
        data-testid={EVENT_TYPE_FILTER}
      />

      {isDocumentGeneratedSelected && (
        <Select
          allowClear
          options={documentSourceOptions}
          onChange={handleDocumentGeneratedSource(setQueryParams)}
          onClear={handleDocumentGeneratedSourceClear(setQueryParams)}
          placeholder={intl.formatMessage({
            id: 'misc.selectSource',
            defaultMessage: 'Select source',
          })}
        />
      )}

      {isFieldValueUpdateSelected && (
        <AutocompleteSelect
          value={queryParams.field_id ? [queryParams.field_id] : undefined}
          onChange={handleEventFields(setQueryParams)}
          placeholder={intl.formatMessage({
            id: 'misc.searchForFields',
            defaultMessage: 'Search for fields',
          })}
          dataTestId={EVENT_FIELD_FILTER}
          addOptionData
          allowClear
          withBoldedSearchPhrase
          {...{ autocompleteUrl }}
        />
      )}
    </div>
  );
};

export default EventFilters;
