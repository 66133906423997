import { AxiosError } from 'axios';
import { ErrorCodes } from 'utils/types/errorResponse';

/**
 * Extracts an error code from the api call error.
 *
 * @param error Axios error returned from the API.
 *
 * @returns Either error code if it's in the error or null if not found.
 */
export const getErrorCodeFromError = (error: AxiosError): ErrorCodes | null => {
  if (error.response?.data.error_code) {
    return error.response?.data.error_code;
  }

  return null;
};
