import { UsersAndGroupsSelectionCountLimits } from 'utils/types/selectInput.types';
import { useTranslateError } from 'components/FormPreview2/translateError';
import { useCallback } from 'react';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { isNil } from 'lodash';
import { ErrorKeys } from 'alx-dynamic-form';

/**
 * Validates user_groups part of {@link UsersAndGroupsFormValue} against limits.
 */
export const useGroupsValidation = (
  fieldLabel: string,
  limits: UsersAndGroupsSelectionCountLimits | undefined
) => {
  const translateError = useTranslateError();

  const validateGroups = useCallback(
    (fieldValue: UsersAndGroupsFormValue | null) => {
      if (isNil(fieldValue) || isNil(limits)) {
        return [];
      }

      if (
        !isNil(limits.minGroups) &&
        fieldValue.user_groups.length < limits.minGroups
      ) {
        return [
          translateError(ErrorKeys.MIN_GROUPS, limits.minGroups, fieldLabel),
        ];
      }

      if (
        !isNil(limits.maxGroups) &&
        fieldValue.user_groups.length > limits.maxGroups
      ) {
        return [
          translateError(ErrorKeys.MAX_GROUPS, limits.maxGroups, fieldLabel),
        ];
      }

      return [];
    },
    [fieldLabel, limits, translateError]
  );

  return { validateGroups };
};
