import { ActionCellProps } from 'components/CatalystTable/types/actionCellProps';
import { ObjectRecordPermissions } from 'utils/types/api/objectRecords.types';

export enum RowActions {
  Download = 'download',
  Save = 'save',
  History = 'history',
}

export interface ActionsMenuProps extends ActionCellProps {
  onActionMenuOptionClick: (key: RowActions) => void;
  tableId: string;
  recordPermissions?: Partial<ObjectRecordPermissions>;
  hasDocumentFields: boolean;
}

export interface DocumentTemplatesPanelProps {
  recordId: string;
  permissions?: Partial<ObjectRecordPermissions>;
}

export interface SaveDocumentModalProps {
  selectedDocumentId: string;
  recordId: string;
  closeModal: () => void;
}

export interface DocumentTemplateFields {
  alias: string;
  label: string;
  max_num_of_files: number;
  is_available: boolean;
}
