import { CheckboxGroupCustom } from 'components/lib/Checkbox';
import React from 'react';
import { ClassFieldCheckboxListProps } from './ClassFieldCheckboxList.types';
import { ClassFieldCheckboxMemoized } from './components/ClassFieldCheckbox';

export const ClassFieldCheckboxList = ({
  classFields,
  onSelectClassFields,
  isColumnLimitReached,
  selectedClassFields,
  className,
}: ClassFieldCheckboxListProps) => (
  <CheckboxGroupCustom vertical value={selectedClassFields}>
    {classFields?.map(field => {
      const { label, id } = field;

      return (
        <ClassFieldCheckboxMemoized
          id={id.toString()}
          label={label}
          key={id}
          className={className}
          onSelectClassFields={onSelectClassFields}
          isColumnLimitReached={isColumnLimitReached}
          selectedClassFields={selectedClassFields ?? []}
          field={field}
        />
      );
    })}
  </CheckboxGroupCustom>
);

export const ClassFieldCheckboxListMemoized = React.memo(
  ClassFieldCheckboxList
);
