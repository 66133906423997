import React, { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from 'components/Icon';
import useCollapseWithHeaderStyles from './styles';
import { CollapseWithHeaderButtonProps } from './types';
import { COLLAPSE_CONTENT, COLLAPSE_WITH_HEADER_BUTTON } from 'utils/testIds';

const CollapseWithHeaderButton: React.FC<CollapseWithHeaderButtonProps> = ({
  headerButtonContent,
  children,
  initialOpen,
  onOpenChange,
}) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const styles = useCollapseWithHeaderStyles({ isOpen });

  const toggleOpen = () => {
    if (onOpenChange) onOpenChange(!isOpen);

    setIsOpen(prevState => {
      return !prevState;
    });
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.collapseButton}
        onClick={toggleOpen}
        role='switch'
        tabIndex={0}
        aria-checked={isOpen}
        data-testid={COLLAPSE_WITH_HEADER_BUTTON}
      >
        {headerButtonContent}
        {isOpen ? (
          <ArrowDownIcon size={12} className={styles.arrow} />
        ) : (
          <ArrowUpIcon size={12} className={styles.arrow} />
        )}
      </div>
      <div
        className={styles.collapseContentWrapper}
        data-testid={COLLAPSE_CONTENT}
      >
        <div className={styles.collapseContent}>{children}</div>
      </div>
    </div>
  );
};

export default CollapseWithHeaderButton;
