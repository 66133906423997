import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { SelectUserAndGroupOptionMap } from 'utils/types/selectInput.types';

/**
 * Converts {@link SelectUserAndGroupOptionMap} to {@link UsersAndGroupsFormValue} or null if nothing is selected.
 */
export const userAndGroupOptionMapToUserFormValue = (
  selection: SelectUserAndGroupOptionMap
): UsersAndGroupsFormValue | null => {
  const usersAsArray = Array.from(selection.users.values());
  const groupsAsArray = Array.from(selection.groups.values());

  const userIds = usersAsArray.map(user => user.id);
  const groupIds = groupsAsArray.map(group => group.id);

  return userIds.length + groupIds.length > 0
    ? {
        users: userIds,
        user_groups: groupIds,
      }
    : null;
};
