import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import ErrorComponent from 'components/ErrorComponent';
import {
  getUserGroupsRow,
  getUserGroupsSelectedRow,
} from 'store/selectors/userGroupsSelectors';
import { ResourceUsagePanelView } from 'components/ResourceUsagePanelView';
import { useUserGroupUsageDisplayConfig } from './hooks/useUserGroupUsageDisplayConfig';
import { useUserGroupUsageQuery } from './hooks/useUserGroupUsageQuery';

/**
 * User group usage panel content.
 */
export const UserGroupUsagePanel = () => {
  const intl = useIntl();

  const selectedRowId = useSelector(getUserGroupsSelectedRow);
  const { name, id } = useSelector(getUserGroupsRow(selectedRowId)) ?? {};
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.UserGroupsViewUsage
  );

  const { isLoading, error, usage } = useUserGroupUsageQuery(id);
  const { displayConfig } = useUserGroupUsageDisplayConfig(usage);

  useEffect(() => {
    if (selectedRowId) {
      return;
    }

    closeComponent(FlexLayoutWindows.UserGroupsViewUsage);
  }, [closeComponent, selectedRowId]);

  if (error) {
    return <ErrorComponent error={error?.status} />;
  }

  return (
    <ResourceUsagePanelView
      displayConfig={displayConfig}
      isExternal={isExternal}
      isLoading={isLoading}
      resourceName={name ?? ''}
      noUsageLabel={intl.formatMessage({
        id: 'userGroups.noUserGroupUsage',
        defaultMessage: 'This user group is not used anywhere in the system.',
      })}
      usageLabel={intl.formatMessage({
        id: 'userGroups.userGroupUsageAreas',
        defaultMessage:
          'This user group is used in the following system areas:',
      })}
    />
  );
};
