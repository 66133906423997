import clsx from 'clsx';
import { Menu } from 'components/lib/Menu';
import React, { useRef } from 'react';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { useLayoutContext } from 'components/lib/Layout/context';
import { SIDEBAR_COLLAPSED_WIDTH, SIDEBAR_EXPANDED_WIDTH } from 'utils/consts';
import { RECORDS_BREADCRUMBS_DROPDOWN_ID } from 'utils/elementsIds';
import { BreadcrumbsDropdownMenuProps } from './BreadcrumbsDropdownMenu.types';
import { useBreadcrumbsDropdownMenuStyles } from './BreadcrumbsDropdownMenu.styles';
import { BreadcrumbsDropdownMenuItem } from './components/BreadcrumbsDropdownMenuItem/BreadcrumbsDropdownMenuItem';

export const BreadcrumbsDropdownMenu = ({
  items,
  parentId,
  menuTriggerId,
  toggleMenuOff,
}: BreadcrumbsDropdownMenuProps) => {
  const menuRef = useRef<HTMLSpanElement>(null);
  useOutsideClick(
    menuRef,
    toggleMenuOff,
    {},
    `#${RECORDS_BREADCRUMBS_DROPDOWN_ID}`
  );
  const { isSidebarCollapsed } = useLayoutContext();

  const getBreadcrumbsPosition = () => {
    const { left: contentX = 0 } =
      document
        .getElementById(parentId)
        ?.offsetParent?.getBoundingClientRect() || {};

    const actionsButton = document.getElementById(menuTriggerId);
    const { left: buttonX = 0, width: buttonWidth = 0 } =
      actionsButton?.getBoundingClientRect() ?? {};

    const sidebarWidth = isSidebarCollapsed
      ? SIDEBAR_COLLAPSED_WIDTH
      : SIDEBAR_EXPANDED_WIDTH;

    return {
      x: buttonX - sidebarWidth - contentX + buttonWidth / 2,
    };
  };

  const styles = useBreadcrumbsDropdownMenuStyles(getBreadcrumbsPosition());

  return (
    <span ref={menuRef}>
      <Menu
        selectable={false}
        className={clsx(styles.list, {
          [styles.actionsWrapper]: true,
        })}
      >
        {items.map(({ title }, index) => (
          <BreadcrumbsDropdownMenuItem
            title={title}
            indexInStack={index}
            key={title}
          />
        ))}
      </Menu>
    </span>
  );
};
