import { ErrorKeys } from 'alx-dynamic-form';
import { useTranslateError } from 'components/FormPreview2/translateError';

export const useUserFieldMinErrorMessages = (
  fieldLabel: string,
  minUsers: number | undefined,
  minGroups: number | undefined
) => {
  const translateError = useTranslateError();

  // We want the below errors to be displayed in specific position so we use them in white / black lists
  // to steer the control correctly - as per 40820 requirement. Each error has to swap their respective MinMaxInfo.
  const minUsersError = translateError(
    ErrorKeys.MIN_USERS,
    minUsers ?? null,
    fieldLabel ?? 'Unknown name'
  );

  const minGroupsError = translateError(
    ErrorKeys.MIN_GROUPS,
    minGroups ?? null,
    fieldLabel ?? 'Unknown name'
  );

  return { minUsersError, minGroupsError };
};
