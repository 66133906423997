import { EventUser } from 'pages/Records/RecordsListing/ActivityLogPanel/types';
import { nameOrDefaultValue } from './nameOrDefaultValue';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

export const mapUserEvent = ({ id, name }: EventUser) => ({
  id,
  value: nameOrDefaultValue(
    name,
    GlobalIntlSingleton.intl.formatMessage(
      {
        id: 'activityLog.deletedUser',
        defaultMessage: 'Deleted user {id}',
      },
      { id }
    )
  ),
});
