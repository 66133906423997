import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UsersAndGroupsExpandablePickerStylesProps = {
  isError: boolean;
};

export const useUsersAndGroupsExpandablePickerStyles = createUseStyles(
  (theme: ThemeState) => ({
    optionList: {
      overflowY: 'scroll',
      maxHeight: '256px',
      width: '100%',
      height: 'auto',
      position: 'relative',
    },
    errorBorder: {
      boxSizing: 'border-box',
      border: (props: UsersAndGroupsExpandablePickerStylesProps) =>
        props?.isError
          ? `1px solid ${theme['error-color']}`
          : '1px solid transparent',
    },
    validationContainer: {
      borderTop: `1px solid ${theme['border-color-base']}`,
    },
    loadingContainer: {
      width: '100%',
      height: '35px',
      marginTop: '5px', // spinner is a bit off center, needs to be shifted from top a bit.
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    optionsViewport: {
      position: 'relative',
      width: '100%',
      boxShadow:
        '0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)',
    },
    savingOverlay: {
      position: 'absolute',
      inset: 0,
      backgroundColor: theme['white-color'],
      opacity: 0.65,
      pointerEvents: 'all',
    },
  })
);
