import React, { useMemo } from 'react';

import { ReactComponent as EditSvg } from 'img/icons/edit-icon.svg';
import { ReactComponent as DeleteSvg } from 'img/icons/delete-icon.svg';
import { ReactComponent as AddSvg } from 'img/icons/add-icon.svg';
import { ReactComponent as AddAsAdminSvg } from 'img/icons/add-as-admin-icon.svg';
import { ReactComponent as RemoveSvg } from 'img/icons/remove-icon.svg';
import { ReactComponent as EmailSvg } from 'img/icons/at-icon.svg';
import { ReactComponent as DesignSvg } from 'img/icons/design-icon.svg';
import { ReactComponent as DownloadSvg } from 'img/icons/download-icon.svg';
import { ReactComponent as ResumeSvg } from 'img/icons/resume-icon.svg';
import { ReactComponent as CheckboxSvg } from 'img/icons/checkbox-icon.svg';
import { ReactComponent as PauseSvg } from 'img/icons/pause-icon.svg';
import { ReactComponent as ViewSvg } from 'img/icons/view-icon.svg';
import { ReactComponent as DemoteSvg } from 'img/icons/demote-icon.svg';
import { ReactComponent as PromoteSvg } from 'img/icons/promote-icon.svg';
import { ReactComponent as ManageUsersSvg } from 'img/icons/manage-users-icon.svg';
import { ReactComponent as FilterIconSvg } from 'img/icons/filter.svg';
import { ReactComponent as ArrowRightIconSvg } from 'img/icons/arrow-right.svg';
import { ReactComponent as UserManagementIconSvg } from 'img/icons/user-management-icon.svg';
import { ReactComponent as UserManagementSettingsIconSvg } from 'img/icons/user-management-settings-icon.svg';
import { ReactComponent as UsersIconSvg } from 'img/icons/users-icon.svg';
import { ReactComponent as SyncIconSvg } from 'img/icons/sync-icon.svg';
import { ReactComponent as CompanyDetailsIconSvg } from 'img/icons/company-details-icon.svg';
import { ReactComponent as StandardAuthIconSvg } from 'img/icons/standard-auth-icon.svg';
import { ReactComponent as KeyIconSvg } from 'img/icons/key-icon.svg';
import { ReactComponent as CubeIconSvg } from 'img/icons/cube-icon.svg';
import { ReactComponent as NotesIconSvg } from 'img/icons/notes-icon.svg';
import { ReactComponent as AuthObjectIconSvg } from 'img/icons/auth-object-icon.svg';
import { ReactComponent as EnvelopeIconSvg } from 'img/icons/envelope-icon.svg';
import { ReactComponent as ArrowDownIconSvg } from 'img/icons/arrow-down-icon.svg';
import { ReactComponent as LogoutIconSvg } from 'img/icons/logout-icon.svg';
import { ReactComponent as AvatarIconSvg } from 'img/icons/avatar-icon.svg';
import { ReactComponent as SequencesIconSvg } from 'img/icons/processes-icon.svg';
import { ReactComponent as SystemConfigurationIconSvg } from 'img/icons/system-configuration-icon.svg';
import { ReactComponent as WorkManagementIconSvg } from 'img/icons/work-management-icon.svg';
import { ReactComponent as TaskConfigurationIconSvg } from 'img/icons/task-configuration-icon.svg';
import { ReactComponent as AdministrationIconSvg } from 'img/icons/administration-icon.svg';
import { ReactComponent as HorizontalLineIconSvg } from 'img/icons/horizontal-line-icon.svg';
import { ReactComponent as ArrowsLeftIconSvg } from 'img/icons/arrows-left-icon.svg';
import { ReactComponent as ArrowDownSmallSvg } from 'img/icons/arrow-down-small.svg';
import { ReactComponent as FirstPageSvg } from 'img/icons/first-page.svg';
import { ReactComponent as LastPageSvg } from 'img/icons/last-page.svg';
import { ReactComponent as PrevPageSvg } from 'img/icons/previous-page.svg';
import { ReactComponent as NextPageSvg } from 'img/icons/next-page.svg';
import { ReactComponent as ColumnConfigurationSvg } from 'img/icons/column-configuration-icon.svg';
import { ReactComponent as ThreeDotsSvg } from 'img/icons/three-dots-icon.svg';
import { ReactComponent as CheckSvg } from 'img/icons/check-icon.svg';
import { ReactComponent as RefreshSvg } from 'img/icons/refresh-icon.svg';
import { ReactComponent as CloseSvg } from 'img/icons/close-icon.svg';
import { ReactComponent as PlusSvg } from 'img/icons/plus-icon.svg';
import { ReactComponent as AddColumnSvg } from 'img/icons/add-column-icon.svg';
import { ReactComponent as ArrowRightWithTailSvg } from 'img/icons/arrow-right-with-tail-icon.svg';
import { ReactComponent as ArrowLeftWithTailSvg } from 'img/icons/arrow-long-left.svg';
import { ReactComponent as EyeSvg } from 'img/icons/eye.svg';
import { ReactComponent as CircleQuestionSvg } from 'img/icons/circle-question-icon.svg';
import { ReactComponent as SettingsSvg } from 'img/icons/settings-icon.svg';
import { ReactComponent as TrashSvg } from 'img/icons/trash-icon.svg';
import { ReactComponent as SettingsCheckedSvg } from 'img/icons/settings-checked.svg';
import { ReactComponent as DragIconSvg } from 'img/icons/drag-icon.svg';
import { ReactComponent as SettingsDotSvg } from 'img/icons/settings-red-dot.svg';
import { ReactComponent as SuccessMarkSvg } from 'img/icons/success-mark.svg';
import { ReactComponent as InfoMarkSvg } from 'img/icons/info-mark.svg';
import { ReactComponent as WarningExclamationMarkSvg } from 'img/icons/warning-exclamation-mark.svg';
import { ReactComponent as ExclamationMarkSvg } from 'img/icons/exclamation-mark.svg';
import { ReactComponent as ExclamationMarkSquareSvg } from 'img/icons/exclamation-mark-icon.svg';
import { ReactComponent as FormIconSvg } from 'img/icons/form-icon.svg';
import { ReactComponent as SingleDotSvg } from 'img/icons/single-dot.svg';
import { ReactComponent as PhoneSvg } from 'img/icons/phone-icon.svg';
import { ReactComponent as MobilePhoneSvg } from 'img/icons/mobile-phone-icon.svg';
import { ReactComponent as SectionEyeSvg } from 'img/icons/section-eye-icon.svg';
import { ReactComponent as EmptyFlagSvg } from 'img/icons/empty-flag.svg';
import { ReactComponent as MainWindowSvg } from 'img/icons/main-window.svg';
import { ReactComponent as EyeCrossedOutSvg } from 'img/icons/eye-crossed-out-icon.svg';
import { ReactComponent as EyeCrossedOutFullSvg } from 'img/icons/eye-crossed-out-full.svg';
import { ReactComponent as RevertSvg } from 'img/icons/revert-icon.svg';
import { ReactComponent as OpenIconSvg } from 'img/icons/open-icon.svg';
import { ReactComponent as AlxLogo } from 'img/alx-logo.svg';
import { ReactComponent as AlxLogoSidebar } from 'img/alx-logo-dark.svg';
import { ReactComponent as AlxLogoSmall } from 'img/alx-logo-small.svg';
import { ReactComponent as NotAllowed } from 'img/icons/not-allowed.svg';
import { ReactComponent as Lock } from 'img/icons/lock-icon.svg';
import { ReactComponent as CheckMark } from 'img/icons/check-mark.svg';
import { ReactComponent as FileUpload } from 'img/icons/file-upload.svg';
import { ReactComponent as ExportFile } from 'img/icons/export-file.svg';
import { ReactComponent as CardViewSvg } from 'img/icons/cards-view-icon.svg';
import { ReactComponent as ListView } from 'img/icons/list-view-icon.svg';
import { ReactComponent as NoMatchFound } from 'img/illustrations/no-matches-found.svg';
import { ReactComponent as SearchBold } from 'img/icons/search-icon-bold.svg';
import { ReactComponent as Search } from 'img/icons/search-icon.svg';
import { ReactComponent as PlayIconSvg } from 'img/icons/play-icon.svg';
import { ReactComponent as PlayFilledIconSvg } from 'img/icons/play-filled-icon.svg';
import { ReactComponent as PauseFilledIconSvg } from 'img/icons/pause-filled-icon.svg';
import { ReactComponent as GlobeIconSvg } from 'img/icons/globe.svg';
import { ReactComponent as RowsIconSvg } from 'img/icons/rows.svg';
import { ReactComponent as PlIconSvg } from 'img/icons/pl-icon.svg';
import { ReactComponent as EnIconSvg } from 'img/icons/en-icon.svg';
import { ReactComponent as UsIconSvg } from 'img/icons/us-icon.svg';
import { ReactComponent as EmptyFlagIconSvg } from 'img/icons/placeholder-flag-icon.svg';
import { ReactComponent as CopyIconSvg } from 'img/icons/copy-icon.svg';
import { ReactComponent as URLIconSVG } from 'img/icons/url-icon.svg';
import { ReactComponent as ExternalComponentSvg } from 'img/icons/external-component.svg';
import { ReactComponent as ParcelSvg } from 'img/icons/parcel_icon.svg';
import { useSelector } from 'react-redux';
import useLogoIconStyles from './styles';
import { customThemeSelector } from 'store/selectors/customThemeSelectors';
import {
  WrapperProps,
  LogoIconProps,
  FallbackLogoOptions,
  IconProps,
} from './types';
import { ReactComponent as EventCircleIconSvg } from 'img/icons/event-circle-icon.svg';
import { ReactComponent as EventExpandIconSvg } from 'img/icons/event-expand-icon.svg';
import { ReactComponent as FieldValueUpdatedIconSvg } from 'img/icons/field-value-updated-icon.svg';
import { ReactComponent as OwnerChangedIconSvg } from 'img/icons/owner-changed-icon.svg';
import { ReactComponent as PermissionChangedIconSvg } from 'img/icons/permission-changed-icon.svg';
import { ReactComponent as RecordCreatedIconSvg } from 'img/icons/record-created-icon.svg';
import { ReactComponent as MailOutlineIconSvg } from 'img/icons/mail-outline-icon.svg';
import { ReactComponent as RelationalModellerIconSvg } from 'img/icons/relational-modeller-icon.svg';
import { ReactComponent as CheckCircleSvg } from 'img/icons/check-circle-icon.svg';
import { ReactComponent as RecordsIconSvg } from 'img/icons/records-icon.svg';
import { ReactComponent as LandingPageIconSvg } from 'img/icons/landing-page-icon.svg';
import { ReactComponent as ThemeIconSvg } from 'img/icons/theme-icon.svg';
import { ReactComponent as OutboundMailIconSvg } from 'img/icons/outbound-mail-icon.svg';
import { ReactComponent as CustomComponentsIconSvg } from 'img/icons/custom-components.icon.svg';
import { ReactComponent as MenuIconSvg } from 'img/icons/menu-icon.svg';
import { ReactComponent as DocumentGeneratedIconSvg } from 'img/icons/document-generated-icon.svg';
import { ReactComponent as ErrorCircleIconSVG } from 'img/icons/error-circle-icon.svg';
import { ReactComponent as AccountsSuperAdminIconSvg } from 'img/icons/accounts-super-admin-icon.svg';
import { ReactComponent as Accounts1tcIconSvg } from 'img/icons/accounts-1tc-icon.svg';
import { ReactComponent as AccountsInternalIconSvg } from 'img/icons/accounts-internal-icon.svg';
import { ReactComponent as AccountsExternalIconSvg } from 'img/icons/accounts-external-icon.svg';
import { ReactComponent as AccountsConfigAdminIconSvg } from 'img/icons/accounts-config-admin.svg';
/*
All svg's imported here should have stroke/fill set to "currentColor" to be able to use css colors
*/

const SvgWrapper = ({
  Svg,
  size = 25,
  style,
  width,
  height,
  minWidth,
  onClick,
  ...rest
}: WrapperProps) => (
  <Svg
    width={width || size}
    height={height || size}
    style={{
      verticalAlign:
        (height || size) >= 8 ? -(height || size) / 2 + 5 : undefined,
      cursor: !!onClick ? 'pointer' : undefined,
      minWidth: minWidth ?? size ?? undefined,
      ...style,
    }}
    {...{ onClick, ...rest }}
  />
);

export const LogoIcon = ({
  fallbackLogo,
  useDefaultAlxLogo = false,
  ...rest
}: LogoIconProps) => {
  const customTheme = useSelector(customThemeSelector);
  const logoUrl = customTheme?.media?.mainLogo?.url;
  const isEnabled = customTheme.isEnabled;

  const styles = useLogoIconStyles({ height: rest.height });

  const fallbackLogoSVG = useMemo(() => {
    switch (fallbackLogo) {
      case FallbackLogoOptions.SMALL:
        return <AlxLogoSmall {...rest} />;
      case FallbackLogoOptions.SIDEBAR:
        return <AlxLogoSidebar {...rest} />;
      case FallbackLogoOptions.NORMAL:
      default:
        return <ALXLogo {...rest} />;
    }
  }, [fallbackLogo, rest]);

  if (!logoUrl || !isEnabled || useDefaultAlxLogo) {
    return <> {fallbackLogoSVG}</>;
  }

  const url = new URL(logoUrl);

  return (
    <div className={styles.container}>
      <img
        alt='logo'
        width={rest.width}
        src={url.pathname}
        className={styles.icon}
        style={rest.style}
      />
    </div>
  );
};

export const DemoteIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={DemoteSvg} />
);

export const PromoteIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PromoteSvg} />
);

export const ManageUsersIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ManageUsersSvg} />
);

export const SyncIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SyncIconSvg} />
);

export const RemoveIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RemoveSvg} />
);

export const AddIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AddSvg} />
);

export const AddAsAdminIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AddAsAdminSvg} />
);

export const ViewIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ViewSvg} />
);

export const CheckboxIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CheckboxSvg} />
);

export const DesignIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={DesignSvg} />
);

export const DownloadIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={DownloadSvg} />
);

export const PauseIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PauseSvg} />
);

export const ResumeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ResumeSvg} />
);

export const EditIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EditSvg} />
);

export const FilterIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={FilterIconSvg} />
);

export const ArrowRightIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ArrowRightIconSvg} />
);

export const ArrowDownIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ArrowDownIconSvg} />
);

export const ArrowUpIcon = ({ style, ...rest }: IconProps) => (
  <SvgWrapper
    {...rest}
    Svg={ArrowDownIconSvg}
    style={{ transform: 'rotate(180deg)', ...style }}
  />
);

export const ArrowRightExpandIcon = ({ style, ...rest }: IconProps) => (
  <SvgWrapper
    {...rest}
    Svg={ArrowDownIconSvg}
    style={{
      transform: 'rotate(-90deg) translateY(-50%)',
      transformOrigin: 'top',
      ...style,
    }}
  />
);

export const UserManagementIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={UserManagementIconSvg} />
);

export const UserManagementSettingsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={UserManagementSettingsIconSvg} />
);

export const UsersIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={UsersIconSvg} />
);

export const CompanyDetailsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CompanyDetailsIconSvg} />
);

export const StandardAuthIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={StandardAuthIconSvg} />
);

export const KeyIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={KeyIconSvg} />
);

export const CubeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CubeIconSvg} />
);

export const NotesIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={NotesIconSvg} />
);

export const AuthObjectIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AuthObjectIconSvg} />
);

export const EnvelopeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EnvelopeIconSvg} />
);

export const LogoutIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={LogoutIconSvg} />
);

export const AvatarIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AvatarIconSvg} />
);

export const SequencesIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SequencesIconSvg} />
);

export const SystemConfigurationIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SystemConfigurationIconSvg} />
);

export const WorkManagementIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={WorkManagementIconSvg} />
);

export const TaskConfigurationIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={TaskConfigurationIconSvg} />
);

export const AdministrationIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AdministrationIconSvg} />
);

export const HorizontalLineIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={HorizontalLineIconSvg} />
);

export const ArrowsLeftIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ArrowsLeftIconSvg} />
);

export const ArrowSmallDown = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ArrowDownSmallSvg} />
);

export const ArrowSmallUp = ({ style, ...rest }: IconProps) => (
  <SvgWrapper
    {...rest}
    Svg={ArrowDownSmallSvg}
    style={{ transform: 'rotate(180deg)', ...style }}
  />
);

export const FirstPageIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={FirstPageSvg} />
);

export const LastPageIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={LastPageSvg} />
);

export const PreviousPageIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PrevPageSvg} />
);

export const NextPageIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={NextPageSvg} />
);

export const ColumnConfigurationIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ColumnConfigurationSvg} />
);

export const ThreeDotsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ThreeDotsSvg} />
);

export const CheckIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CheckSvg} />
);

export const RefreshIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RefreshSvg} />
);

export const CloseIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CloseSvg} />
);

export const PlusIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PlusSvg} />
);

export const AddColumnIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AddColumnSvg} />
);

export const ArrowRightWithTailIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ArrowRightWithTailSvg} />
);

export const ArrowLeftWithTailIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ArrowLeftWithTailSvg} />
);

export const EyeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EyeSvg} />
);

export const CircleQuestionIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CircleQuestionSvg} />
);

export const SettingsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SettingsSvg} />
);

export const TrashIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={TrashSvg} />
);

export const SettingsCheckedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SettingsCheckedSvg} />
);

export const DragIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={DragIconSvg} />
);

export const SettingsRedDotIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SettingsDotSvg} />
);

export const SuccessMark = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SuccessMarkSvg} />
);

export const InfoMark = (props: IconProps) => (
  <SvgWrapper {...props} Svg={InfoMarkSvg} />
);

export const WarningExclamationMark = (props: IconProps) => (
  <SvgWrapper {...props} Svg={WarningExclamationMarkSvg} />
);

export const ExclamationMark = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ExclamationMarkSvg} />
);

export const ExclamationMarkSquare = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ExclamationMarkSquareSvg} />
);

export const ExternalComponentIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ExternalComponentSvg} />
);
export const ParcelIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ParcelSvg} />
);

export const FormIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={FormIconSvg} />
);

export const DotIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SingleDotSvg} />
);

export const PhoneIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PhoneSvg} />
);

export const MobilePhoneIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={MobilePhoneSvg} />
);

export const EmailIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EmailSvg} />
);

export const SectionEyeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SectionEyeSvg} />
);

export const EmptyFlagIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EmptyFlagSvg} />
);

export const EyeCrossedOut = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EyeCrossedOutSvg} />
);

export const EyeCrossedOutFull = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EyeCrossedOutFullSvg} />
);

export const DeleteIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={DeleteSvg} />
);

export const RevertIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RevertSvg} />
);

export const OpenIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={OpenIconSvg} />
);

export const ALXLogo = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AlxLogo} />
);

export const ALXLogoSidebar = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AlxLogoSidebar} />
);

export const ALXLogoSmall = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AlxLogoSmall} />
);

export const NotAllowedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={NotAllowed} />
);

export const LockIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={Lock} />
);
export const CheckMarkIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CheckMark} />
);
export const FileUploadIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={FileUpload} />
);
export const ExportFileIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ExportFile} />
);

export const CardsViewIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CardViewSvg} />
);

export const ListViewIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ListView} />
);

export const NoMatchFoundIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={NoMatchFound} />
);

export const SearchBoldIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={SearchBold} />
);

export const SearchIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={Search} />
);

export const MainWindow = (props: IconProps) => (
  <SvgWrapper {...props} Svg={MainWindowSvg} />
);

export const PlayIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PlayIconSvg} />
);

export const PlayFilledIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PlayFilledIconSvg} />
);

export const PauseFilledIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PauseFilledIconSvg} />
);

export const GlobeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={GlobeIconSvg} />
);

export const RowsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RowsIconSvg} />
);

export const EnIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EnIconSvg} />
);

export const PlIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PlIconSvg} />
);

export const UsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={UsIconSvg} />
);

export const GreyFlagIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EmptyFlagIconSvg} />
);

export const CopyIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CopyIconSvg} />
);

export const EventCircleIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EventCircleIconSvg} />
);

export const EventExpandIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={EventExpandIconSvg} />
);

export const URLIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={URLIconSVG} />
);

export const FieldValueUpdatedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={FieldValueUpdatedIconSvg} />
);

export const OwnerChangedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={OwnerChangedIconSvg} />
);

export const PermissionChangedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={PermissionChangedIconSvg} />
);

export const RecordCreatedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RecordCreatedIconSvg} />
);

export const MailOutlineIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={MailOutlineIconSvg} />
);

export const RelationalModeller = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RelationalModellerIconSvg} />
);

export const CheckCircle = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CheckCircleSvg} />
);

export const RecordsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={RecordsIconSvg} />
);

export const LandingPageIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={LandingPageIconSvg} />
);

export const ThemeIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ThemeIconSvg} />
);

export const OutboundMailIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={OutboundMailIconSvg} />
);

export const CustomComponentsIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={CustomComponentsIconSvg} />
);

export const MenuIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={MenuIconSvg} />
);

export const DocumentGeneratedIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={DocumentGeneratedIconSvg} />
);

export const ErrorCircleIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={ErrorCircleIconSVG} />
);

export const AccountsSuperAdminIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AccountsSuperAdminIconSvg} />
);

export const AccountsConfigAdminIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AccountsConfigAdminIconSvg} />
);

export const AccountsInternalIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AccountsInternalIconSvg} />
);

export const AccountsExternalIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={AccountsExternalIconSvg} />
);

export const Accounts1tcIcon = (props: IconProps) => (
  <SvgWrapper {...props} Svg={Accounts1tcIconSvg} />
);
