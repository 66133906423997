import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useUsersGroupsListItemDeselectOnlyStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      deselectButton: {
        outline: 'none',
        background: 'transparent',
        border: 'none',
        width: 'auto',
        height: 'auto',
        padding: 0,
        color: theme['secondary-color'],

        '&:focus': {
          outline: 'none',
        },
      },
      optionItem: {
        position: 'relative',
        minHeight: 47,
        lineHeight: '19px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'default',
        padding: '3px 6px',
        gap: '6px',
        background: 'transparent',
        width: '100%',
        textAlign: 'start',
        '& .ant-avatar': {
          background: theme['border-color-base'],
        },
        '& div:not(:first-child)': {
          color: `${theme['disabled-switch-gray']} !important`,
          '& mark': {
            color: theme['disabled-switch-gray'],
          },
        },
        '& mark': {
          padding: 0,
          backgroundColor: 'inherit',
        },
        '&:focus': {
          outline: `none`,
        },
        '&:hover': {
          backgroundColor: theme['dropdown-hover-gray'],
        },
      },
    };
  }
);
