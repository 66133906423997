import Tooltip from 'components/lib/Tooltip';
import React, { useRef } from 'react';
import { useTooltipVisibility } from 'hooks/useTooltipVisibility';
import { UsersGroupsListItemDeselectOnlyTooltipProps } from './UsersGroupsListItemDeselectOnlyTooltip.types';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { FormattedMessage } from 'react-intl';

export const UsersGroupsListItemDeselectOnlyTooltip = ({
  children,
  item,
}: UsersGroupsListItemDeselectOnlyTooltipProps) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const { isTooltipVisible, setIsTooltipVisible } = useTooltipVisibility(
    elementRef
  );

  if (item.type === UserOrGroupSelectOptionType.Group) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      align={{ offset: [0, 3] }}
      trigger={'hover'}
      visible={isTooltipVisible}
      mouseLeaveDelay={0}
      onVisibleChange={setIsTooltipVisible}
      overlay={
        item.is_deleted ? (
          <FormattedMessage
            id='misc.userWillNotBeAvailableForReselection'
            defaultMessage='This user will no longer be available for re-selection if removed from the field.'
          />
        ) : (
          <FormattedMessage
            id='misc.userMightNotBeAvailableForReselection'
            defaultMessage='This user may no longer be available for re-selection.'
          />
        )
      }
    >
      <div ref={elementRef}>{children}</div>
    </Tooltip>
  );
};
