import {
  RecordCreatedIcon,
  OwnerChangedIcon,
  FieldValueUpdatedIcon,
  DocumentGeneratedIcon,
  PermissionChangedIcon,
  ErrorCircleIcon,
  EventCircleIcon,
} from 'components/Icon';
import { IconProps } from 'components/Icon/types';
import { useIntl } from 'react-intl';
import { EventType } from '../../../../../types';

export const useEventIcon = (
  eventType: EventType
): { EventIcon: (props: IconProps) => JSX.Element; title: string } => {
  const intl = useIntl();

  switch (eventType) {
    case EventType.RecordCreated:
      return {
        EventIcon: RecordCreatedIcon,
        title: intl.formatMessage({
          id: 'activityLog.created',
          defaultMessage: 'Record created',
        }),
      };
    case EventType.OwnerInitialized:
      return {
        EventIcon: OwnerChangedIcon,
        title: intl.formatMessage({
          id: 'activityLog.ownerInitialized',
          defaultMessage: 'Record owner initialized',
        }),
      };
    case EventType.FieldValuesChanged:
      return {
        EventIcon: FieldValueUpdatedIcon,
        title: intl.formatMessage({
          id: 'activityLog.fieldValuesChanged',
          defaultMessage: 'Field value updated',
        }),
      };
    case EventType.DocumentGenerated:
      return {
        EventIcon: DocumentGeneratedIcon,
        title: intl.formatMessage({
          id: 'activityLog.documentGenerated',
          defaultMessage: 'Document generated',
        }),
      };
    case EventType.AssigneesAdded:
    case EventType.AssigneesRemoved:
    case EventType.OwnersAdded:
    case EventType.OwnersRemoved:
      return {
        EventIcon: PermissionChangedIcon,
        title: intl.formatMessage({
          id: 'activityLog.accessUpdate',
          defaultMessage: 'Record access updated',
        }),
      };
    case EventType.Error:
      return {
        EventIcon: ErrorCircleIcon,
        title: intl.formatMessage({
          id: 'activityLog.error',
          defaultMessage: 'Invalid data',
        }),
      };
    default:
      return {
        EventIcon: EventCircleIcon,
        title: '',
      };
  }
};
