import React from 'react';
import {
  AutocompleteSelectValue,
  SelectUserAndGroupOption,
} from 'utils/types/selectInput.types';
import { FILTER_INPUT_TESTID } from 'utils/testIds';
import { PredicatesTestIdType } from '../../types';
import { PredicateInputProps } from './PredicateInput.types';
import { PredicateSet } from 'utils/types/predicates.types';
import { usePredicateInputStyles } from './PredicateInput.styles';
import { FilterTextValue } from './components/FilterTextValue';
import { FilterNumberValue } from './components/FilterNumberValue';
import { FilterDateValue } from './components/FilterDateValue';
import { FilterDateRangeValue } from './components/FilterDateRangeValue';
import { FilterBooleanValue } from './components/FilterBooleanValue';
import { FilterSingleSelectValue } from './components/FilterSingleSelectValue';
import { FilterMultiSelectValue } from './components/FilterMultiSelectValue';
import { FilterAutocompleteSelectValue } from './components/FilterAutocompleteSelectValue';
import { FilterUserValue } from './components/FilterUserValue';
import { FilterUserAndGroupValue } from './components/FilterUserAndGroupValue';

export const PredicateInput = ({
  type,
  value,
  onChange,
  selectOptions = [],
  selectMultiple = false,
  autocompleteUrl,
  testIdType = PredicatesTestIdType.Default,
  filterKey,
  predicateSet = PredicateSet.RecordProperties,
}: PredicateInputProps) => {
  const styles = usePredicateInputStyles();

  const testId = `${FILTER_INPUT_TESTID}-${type}${testIdType}`;

  switch (type) {
    case 'string':
    case 'phone':
    case 'email':
    case 'url': {
      return (
        <FilterTextValue
          value={value as string}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'int':
    case 'float': {
      return (
        <FilterNumberValue
          value={value as number}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'datetime':
    case 'date': {
      return (
        <FilterDateValue
          className={styles.fullWidth}
          value={value as string}
          filterKey={filterKey}
          inputType={type}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'dateRange':
    case 'dateTimeRange': {
      return (
        <FilterDateRangeValue
          className={styles.fullWidth}
          value={value}
          filterKey={filterKey}
          inputType={type}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'user': {
      /**
       * No autocomplete URL means that it's a user type field filter.
       */
      if (!autocompleteUrl) {
        return (
          <FilterUserAndGroupValue
            value={value as SelectUserAndGroupOption | undefined}
            testId={testId}
            onChange={onChange}
          />
        );
      }

      return (
        <FilterUserValue
          value={value}
          autocompleteUrl={autocompleteUrl}
          isMultiSelect={selectMultiple}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'user_and_group': {
      return (
        <FilterUserAndGroupValue
          value={value as SelectUserAndGroupOption | undefined}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'enum':
    case 'set': {
      if (!!autocompleteUrl) {
        return (
          <FilterAutocompleteSelectValue
            value={value as AutocompleteSelectValue[]}
            autocompleteUrl={autocompleteUrl}
            isMultiSelect={selectMultiple}
            testId={testId}
            onChange={onChange}
          />
        );
      }

      if (selectMultiple) {
        return (
          <FilterMultiSelectValue
            value={value}
            options={selectOptions}
            predicateSet={predicateSet}
            testId={testId}
            onChange={onChange}
          />
        );
      }

      return (
        <FilterSingleSelectValue
          className={styles.fullWidth}
          value={value}
          options={selectOptions}
          predicateSet={predicateSet}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    case 'bool': {
      return (
        <FilterBooleanValue
          className={styles.fullWidth}
          value={value}
          predicateSet={predicateSet}
          testId={testId}
          onChange={onChange}
        />
      );
    }
    default: {
      return null;
    }
  }
};
