import { convertUsersAndGroupsFormValueToUserAndGroupOptions } from 'components/FormPreview2/utils/convertUsersAndGroupsFormValueToUserAndGroupOptions';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { isNil } from 'lodash';
import { ObjectRecordDetailsMeta } from 'utils/types/api/objectRecords.types';
import {
  SelectUserAndGroupOption,
  SelectUserAndGroupOptionMap,
} from 'utils/types/selectInput.types';

export const usersAndGroupsFormValueToSelectionMap = (
  value: UsersAndGroupsFormValue | null | undefined,
  options: SelectUserAndGroupOption,
  meta: ObjectRecordDetailsMeta | undefined
): SelectUserAndGroupOptionMap => {
  if (isNil(value)) {
    return {
      users: new Map(),
      groups: new Map(),
    };
  }

  const formValueOptions = convertUsersAndGroupsFormValueToUserAndGroupOptions(
    value,
    options,
    meta
  );

  return {
    users: new Map(formValueOptions.users.map(user => [user.id, user])),
    groups: new Map(formValueOptions.groups.map(group => [group.id, group])),
  };
};
