import React from 'react';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import CustomPrompt from 'components/CustomPrompt';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { useClassFieldModals } from './hooks';
import { ClassFieldChangesConfirmationModalsProps } from './types';

export const ClassFieldChangesConfirmationModals: React.FC<ClassFieldChangesConfirmationModalsProps> = ({
  isCancelModalVisible,
  toggleCancelModal,
  onSubmit,
  closePanel,
  ...props
}) => {
  const {
    shouldDisplaySaveChangesPrompt,
    cancelSaveChangesPrompt,
    showSaveChangesModal,
    onPromptSave,
    handleConfirmCancelConfiguration,
    handleCancelCancelConfiguration,
  } = useClassFieldModals({
    onSubmit,
    closePanel,
    toggleCancelModal,
    isCancelModalVisible,
    ...props,
  });

  return (
    <>
      <CancelConfigurationModal
        visible={isCancelModalVisible || showSaveChangesModal}
        onCancel={handleCancelCancelConfiguration}
        onConfirm={handleConfirmCancelConfiguration}
      />
      <CustomPrompt
        when={shouldDisplaySaveChangesPrompt && !isCancelModalVisible}
        onCancel={cancelSaveChangesPrompt}
        onConfirm={onPromptSave}
        type={CustomPromptType.CANCEL}
      />
    </>
  );
};
