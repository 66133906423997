import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useErrorDetailsModalStyles = createUseStyles(
  (theme: ThemeState) => ({
    wrapper: {
      zIndex: 10010,

      '& .ant-modal-content': {
        borderRadius: '4px',
      },

      '& .ant-modal-body': {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',

        width: '520px',
      },

      '& p': {
        margin: 0,
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',

      '& svg': {
        flexShrink: 0,
      },
    },
    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',

      minWidth: 0,

      fontSize: '0.75rem',
    },
    title: {
      marginBottom: 0,

      fontSize: '1.25rem',
      fontWeight: 'bold',
      lineHeight: '1.5',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    location: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    versions: {
      color: theme['secondary-color'],
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',

      marginLeft: '102px',
    },
    paramLabel: {
      marginBottom: 0,

      fontSize: '0.75rem',
      fontWeight: 'bold',
      lineHeight: '1.5',
      color: theme['secondary-color'],
    },
    paramContent: {
      maxHeight: '88px', // 4 lines
      fontSize: '0.875rem',
      overflow: 'auto',
    },
    footer: {
      display: 'flex',
      gap: '12px',

      marginLeft: 'auto',
    },
  })
);
