import React from 'react';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';
import { VIEW_USAGE_LIST_ITEM_TESTID } from 'utils/testIds';
import { TreeUsageView } from './components/TreeUsageView';
import { ListUsageView } from './components/ListUsageView';
import { UsageItemContentProps } from './UsageItemContent.types';
import { useUsageItemContentStyles } from './UsageItemContent.styles';

export const UsageItemContent = ({ usageConfig }: UsageItemContentProps) => {
  const styles = useUsageItemContentStyles();

  const getProperUsageView = () => {
    switch (usageConfig.type) {
      case UsageViewType.Tree: {
        return <TreeUsageView usage={usageConfig.usage} />;
      }
      case UsageViewType.List: {
        return <ListUsageView usage={usageConfig.usage} />;
      }
      case UsageViewType.Custom: {
        return usageConfig.content;
      }
    }
  };

  return (
    <div
      className={styles.mainWrapper}
      data-testid={VIEW_USAGE_LIST_ITEM_TESTID}
    >
      {usageConfig.headline && <p>{usageConfig.headline}</p>}

      {getProperUsageView()}
    </div>
  );
};
