import { ErrorKeys } from 'alx-dynamic-form';
import { useIntl } from 'react-intl';

export const useTranslateError = () => {
  const intl = useIntl();

  return (errorKey: ErrorKeys, value: number | null, fieldLabel: string) => {
    switch (errorKey) {
      case ErrorKeys.REQUIRED:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.required',
            defaultMessage: '{label} is a required property',
          },
          {
            label: fieldLabel,
          }
        );
      case ErrorKeys.MIN_VALUE:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.min_value',
            defaultMessage: '{label} must be greater than {value}',
          },
          {
            label: fieldLabel,
            value,
          }
        );
      case ErrorKeys.MAX_VALUE:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.max_value',
            defaultMessage: '{label} must be lower than {value}',
          },
          {
            label: fieldLabel,
            value,
          }
        );
      case ErrorKeys.MIN_VALUES:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.min_values',
            defaultMessage:
              'At least {value, plural, one {# item} other {# items}} must be selected',
          },
          {
            value,
          }
        );
      case ErrorKeys.MAX_VALUES:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.max_values',
            defaultMessage:
              'At most {value, plural, one {# item} other {# items}} can be selected',
          },
          {
            value,
          }
        );
      case ErrorKeys.MAX_LENGTH:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.max_length',
            defaultMessage: '{label} can have at most {value} characters',
          },
          {
            label: fieldLabel,
            value,
          }
        );
      case ErrorKeys.CUSTOM_FORMAT:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.custom_format',
            defaultMessage: '{label} has invalid format',
          },
          {
            label: fieldLabel,
          }
        );
      case ErrorKeys.MIN_USERS:
        return intl.formatMessage(
          {
            id: 'errors.minSelectedUsers',
            defaultMessage:
              'At least {minSelected, plural, one {# user} other {# users}} must be selected',
          },
          { minSelected: value }
        );
      case ErrorKeys.MAX_USERS:
        return intl.formatMessage(
          {
            id: 'errors.maxSelectedUsers',
            defaultMessage:
              'Maximum {maxSelected, plural, one {# user} other {# users}} can be selected',
          },
          { maxSelected: value }
        );
      case ErrorKeys.MIN_GROUPS:
        return intl.formatMessage(
          {
            id: 'errors.minSelectedGroups',
            defaultMessage:
              'At least {minSelected, plural, one {# group} other {# groups}} must be selected',
          },
          { minSelected: value }
        );
      case ErrorKeys.MAX_GROUPS:
        return intl.formatMessage(
          {
            id: 'errors.maxSelectedGroups',
            defaultMessage:
              'Maximum {maxSelected, plural, one {# groups} other {# groups}} can be selected',
          },
          { maxSelected: value }
        );

      default:
        return intl.formatMessage(
          {
            id: 'formPreview2.validation.invalidValue',
            defaultMessage: '{label} has invalid value',
          },
          {
            label: fieldLabel,
          }
        );
    }
  };
};
