import React from 'react';
import { TOAST_TESTID } from 'utils/testIds';
import { ToastIcon } from '../../components/ToastIcon';
import { useStandardToastContentStyles } from './StandardToastContent.styles';
import { StandardToastContentProps } from './StandardToastContent.types';

/**
 * Content of the standard toast.
 */
export const StandardToastContent = ({
  title,
  type,
  subtitle,
}: StandardToastContentProps) => {
  const styles = useStandardToastContentStyles();

  return (
    <article className={styles.contentWrapper} data-testid={TOAST_TESTID[type]}>
      <ToastIcon type={type} />

      <section>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subtitle}>{subtitle}</h3>
      </section>
    </article>
  );
};
