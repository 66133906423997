import { TPredicateValue } from 'utils/types/predicates.types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';

export const parseFilterValue = (predicateValue: TPredicateValue) => {
  /**
   * predicateValue can be an array in case when multiselect is used,
   * also it can be an AutocompleteSelectValue or array of this type
   * we parse the array to string separated with commas
   */
  if (Array.isArray(predicateValue)) {
    if (
      typeof predicateValue[0] === 'string' ||
      typeof predicateValue[0] === 'number'
    ) {
      //predicate value is array of strings or numbers
      return (predicateValue as Array<string>).join(',');
    }

    //predicate value is array of AutocompleteSelectValue
    return (predicateValue as Array<AutocompleteSelectValue>)
      .map(autocompleteValue => autocompleteValue.value)
      .join(',');
  }

  if (
    typeof predicateValue === 'string' ||
    typeof predicateValue === 'number'
  ) {
    return predicateValue.toString();
  }

  //predicate value is AutocompleteSelectValue
  if ('value' in predicateValue) {
    return predicateValue.value;
  }
};
