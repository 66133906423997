import { AvatarItem } from 'components/lib/Avatar/types';
import { UserGroupInfoBase } from 'utils/types/api/UserGroupInfoBase';

export const mapBaseUserGroupInfoToAvatarItem = ({
  id,
  name,
}: UserGroupInfoBase): AvatarItem => {
  return {
    id: id,
    userGroup: name,
  };
};
