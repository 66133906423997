import { Select, SelectOption } from 'components/lib/Select';
import NoMatchesFound from 'components/NoMatchesFound';
import React from 'react';
import { useIntl } from 'react-intl';
import { FilterSingleSelectValueProps } from './FilterSingleSelectValue.types';
import { getOptionText } from '../../utils';

export const FilterSingleSelectValue = ({
  value,
  options,
  predicateSet,
  className,
  testId,
  onChange,
}: FilterSingleSelectValueProps) => {
  const intl = useIntl();

  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <Select
      className={className}
      value={value}
      placeholder={intl.formatMessage({
        id: 'predicates.select',
        defaultMessage: 'Select',
      })}
      notFoundContent={<NoMatchesFound />}
      data-testid={testId}
      onChange={handleChange}
    >
      {options.map(option => (
        <SelectOption key={option.value} value={option.value}>
          {getOptionText(option, predicateSet)}
        </SelectOption>
      ))}
    </Select>
  );
};
