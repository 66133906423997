import { Protocol } from 'components/FormPreview2/widgets/standard/CustomURLWidget/enums';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useInplaceEditUrl = ({
  value,
  onChange,
  reValidateField,
  setIsEditModeOff,
}: {
  value: string | undefined;
  onChange: (val: string | undefined) => void;
  reValidateField: (protocol?: string, url?: string) => void;
  setIsEditModeOff: VoidFunction;
}) => {
  const [protocol, setProtocol] = useState<string>(Protocol.HTTPS);
  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    if (value) {
      const protocol = value.match(/^http(s?):\/\//i)?.[0] ?? Protocol.HTTPS;
      const newUrl = value.replace(/^http(s?):\/\//i, '');
      setProtocol(protocol ?? Protocol.HTTPS);
      setUrl(newUrl);
    }
  }, [value]);

  const onChangeWithValues = useCallback(() => {
    reValidateField(protocol, url);

    if (url === '') {
      onChange('');

      return;
    }
    onChange(`${protocol}${url}`);
  }, [onChange, protocol, reValidateField, url]);

  const onCancel = useCallback(() => {
    const urlValue = (value as string) ?? '';
    const protocol = urlValue.match(/^http(s?):\/\//i)?.[0] ?? Protocol.HTTPS;
    const newUrl = urlValue.replace(/^http(s?):\/\//i, '');
    setProtocol(protocol ?? Protocol.HTTPS);
    setUrl(newUrl);
    setIsEditModeOff();
  }, [value, setIsEditModeOff]);

  useEffect(() => {
    reValidateField(protocol, url);
    onChange(`${protocol ?? ''}${url ?? ''}`);
  }, [onChange, protocol, reValidateField, url]);

  const handleSetProtocol = useCallback((protocol: string) => {
    setProtocol(protocol);
  }, []);

  const handleChangeURL = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newUrl = e.target.value;

      if (newUrl.includes(Protocol.HTTP)) {
        newUrl = newUrl.replace(Protocol.HTTP, '');
        setProtocol(Protocol.HTTP);
      }

      if (newUrl.includes(Protocol.HTTPS)) {
        newUrl = newUrl.replace(Protocol.HTTPS, '');
        setProtocol(Protocol.HTTPS);
      }

      setUrl(newUrl);
    },
    []
  );

  const options = useMemo(() => [Protocol.HTTP, Protocol.HTTPS], []);

  return {
    handleChangeURL,
    protocol,
    onChangeWithValues,
    url,
    handleSetProtocol,
    onCancel,
    options,
  };
};
