import { AvatarItem } from 'components/lib/Avatar/types';
import { UserInfoBase } from 'utils/types/api/UserInfoBase.types';

export const mapBaseUserInfoToAvatarItem = ({
  id,
  company_name,
  first_name,
  last_name,
  username,
  is_deleted,
  account_type,
}: UserInfoBase): AvatarItem => ({
  id,
  company: company_name,
  firstName: first_name,
  lastName: last_name,
  email: username,
  accountType: account_type,
  isDeleted: is_deleted,
});
