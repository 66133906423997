import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getIdentifier } from 'styles/panelsStyles';

export const useAddAssigneesPanelViewHeaderStyles = createUseStyles(
  (theme: ThemeState) => ({
    header: {
      display: 'grid',
      gap: '4px',
    },
    title: {
      ...getIdentifier(theme),

      minWidth: 0,
      marginBottom: 0,

      fontSize: '1rem',
    },
    secondaryTextsWrapper: {
      display: 'flex',
      gap: '8px',
      justifyContent: 'space-between',
    },
    secondaryText: {
      marginBottom: 0,

      fontSize: '0.75rem',
      color: theme['secondary-color'],
    },
  })
);
