import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  panelWrapper,
  headerWrapper,
  getIdentifier,
  rightActionButton,
  getResultsInfo,
} from 'styles/panelsStyles';

type UseTaskTaskListItemStylesProps = {
  canCreateTask?: boolean;
};

export const useTaskListStyles = createUseStyles((theme: ThemeState) => ({
  panelWrapper,
  headerWrapper,
  identifier: {
    ...getIdentifier(theme),
    width: ({ canCreateTask }: UseTaskTaskListItemStylesProps = {}) =>
      canCreateTask ? 'calc(100% - 124px)' : '100%', // panel width - CREATE button
  },
  button: rightActionButton,
  resultsInfo: getResultsInfo(theme),
  infiniteListWrapper: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  listElementWrapper: {
    padding: 0,
  },
}));
