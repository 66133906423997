import { useFormikContext } from 'formik';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { useCallback } from 'react';
import { ClassFieldForm } from '../../../../../types';

export const useSelectUsersMaxMinValidation = () => {
  const {
    setFieldValue,
    validateField,
    values: {
      min_users_values: minUsersValues,
      max_users_values: maxUsersValues,
    },
  } = useFormikContext<ClassFieldForm>();

  return useCallback(
    (name: string, value?: number) => {
      const isMinValueChange = name === ClassFieldFormFields.MinUsersValues;

      if (
        value !== undefined &&
        (isMinValueChange
          ? maxUsersValues !== undefined && value > maxUsersValues
          : minUsersValues !== undefined && value < minUsersValues)
      ) {
        const valueKeyToChange = isMinValueChange
          ? ClassFieldFormFields.MaxUsersValues
          : ClassFieldFormFields.MinUsersValues;
        const valueToSet = Math.floor(value);

        setFieldValue(valueKeyToChange, valueToSet);
        validateField(valueKeyToChange);
      }
    },
    [maxUsersValues, minUsersValues, validateField, setFieldValue]
  );
};
