import { composeAllHiddenColumns } from 'components/CatalystTable/utils/composeAllHiddenColumns';
import { useState, useMemo } from 'react';
import { TableState } from 'react-table';
import { FIELD_PREFIX } from 'utils/consts';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import {
  PreferencesTypes,
  ExtendedTableState,
} from 'utils/types/api/preferences.types';
import { generateAdditionalQueryParams } from '../../utils/generateAdditionalQueryParams';
import { UseCurrentTableHiddenColumnsAndQueryParams } from './useCurrentTableHiddenColumnsAndQuery.types';
import { useInitialState } from '../useTableData/hooks/useInitialState';

export const useCurrentTableHiddenColumnsAndQuery = ({
  currentTableName,
  currentColumns,
  preferences,
  preferencesId,
  customDataGroups,
  customTableName,
}: UseCurrentTableHiddenColumnsAndQueryParams) => {
  const initialState = useInitialState(preferencesId, customTableName);
  const [
    columnsConfigurationLoading,
    setColumnsConfigurationLoading,
  ] = useState(false);
  const hiddenColumns = useMemo(() => {
    if (!currentTableName || !currentColumns) return [];
    if (currentTableName === CatalystTableType.NestedTable) return [];

    const tablePreferences =
      preferences?.[PreferencesTypes.TablePreferences].tables[currentTableName];

    const {
      hiddenColumns: preferencesHiddenColumns,
      knownColumns: preferencesKnownColumns,
    } =
      (preferencesId
        ? (tablePreferences as ExtendedTableState)?.[preferencesId]
        : (tablePreferences as Partial<TableState<object>>)) || {};

    const hiddenColumns = composeAllHiddenColumns(
      preferencesHiddenColumns,
      preferencesKnownColumns,
      currentColumns
    );

    return (hiddenColumns ?? initialState?.hiddenColumns)?.filter(
      x => !x.startsWith(FIELD_PREFIX)
    ) as string[];
  }, [
    currentTableName,
    preferences,
    preferencesId,
    currentColumns,
    initialState,
  ]);

  const additionalQueryParams = useMemo(
    () =>
      generateAdditionalQueryParams(
        currentColumns,
        hiddenColumns,
        customDataGroups
      ),
    [currentColumns, customDataGroups, hiddenColumns]
  );

  return {
    additionalQueryParams,
    columnsConfigurationLoading,
    hiddenColumns,
    setColumnsConfigurationLoading,
  };
};
