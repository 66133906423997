import { EmptyTableRenderProp } from 'components/CatalystTable/contexts/TableContext/types/emptyTableRenderProp';
import React from 'react';
import { useIntl } from 'react-intl';
import useChildClassEmptyTable from './styles';
import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import { useSelector } from 'react-redux';
import { getTableDensity } from 'store/selectors/preferencesSelectors';
import { getBodyRowHeight } from 'pages/MyAccount/components/MyAccountSections/components/PreferencesTab/components/RowSettings/components/styles';
import { ChildClassEmptTableProps } from './types';
import { ChildClassTableUIConfig } from '../../types';
import { childClassUICOmponentStates } from '../../enums';

const ChildClassEmptyTable = ({
  tableWidth,
  readOnly,
  permissionsUICOnfig,
}: ChildClassEmptTableProps) => {
  const tableDensity = useSelector(getTableDensity) ?? TableDensity.Default;
  const rowHeight = getBodyRowHeight(tableDensity);
  const classes = useChildClassEmptyTable({ tableWidth, rowHeight });
  const intl = useIntl();

  const readOnlyMessage = intl.formatMessage({
    id: 'misc.childClassNoDataReadOnly',
    defaultMessage: 'No records available.',
  });

  const createOnlyMessage = intl.formatMessage({
    id: 'misc.childClassNoDataCreateOnly',
    defaultMessage: 'No records available. Create new records to add them. ',
  });

  const listOnlyMessage = intl.formatMessage({
    id: 'misc.childClassNoDataListOnly',
    defaultMessage:
      'No records available. Search for existing records to add them. ',
  });

  const fullPermsMessage = intl.formatMessage({
    id: 'misc.childClassNoData',
    defaultMessage:
      'No records available. Search for existing records to add them or create new records.',
  });

  let message = '';
  if (readOnly) {
    message = readOnlyMessage;
  } else if (
    permissionsUICOnfig.createButton !== childClassUICOmponentStates.ENABLED
  ) {
    message = listOnlyMessage;
  } else if (
    permissionsUICOnfig.recordLookup !== childClassUICOmponentStates.ENABLED
  ) {
    message = createOnlyMessage;
  } else {
    message = fullPermsMessage;
  }

  return <div className={classes.emptyTable}>{message}</div>;
};

export const childClassEmptyTableWithCustomMessage = (
  readOnly: boolean,
  permissionsUICOnfig: ChildClassTableUIConfig
) => {
  const component: EmptyTableRenderProp = ({ tableWidth }) => {
    return (
      <ChildClassEmptyTable
        tableWidth={tableWidth}
        readOnly={readOnly}
        permissionsUICOnfig={permissionsUICOnfig}
      />
    );
  };
  return component;
};

export default ChildClassEmptyTable;
