import { useRedirectFromTableWithParams } from 'hooks/useRedirectFromTableWithParams';
import { useLocation } from 'react-router-dom';
import { ObjectClassFieldLocationState } from '../types';

export const useObjectClassLocationState = () => {
  const redirect = useRedirectFromTableWithParams(true);
  const { pathname, search, state = {} } = useLocation<
    ObjectClassFieldLocationState
  >();

  const { id: _, ...reducedState } = state;

  return (id?: number | string, label?: string, className?: string) => {
    if (id) {
      if (label && className)
        redirect(`${pathname}${search}`, {
          ...reducedState,
          id,
          label,
          className,
        });
      else redirect(`${pathname}${search}`, { ...reducedState, id });
    } else redirect(`${pathname}${search}`, { ...reducedState });
  };
};
