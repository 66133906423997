import axios, { CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef } from 'react';

/**
 * This hook handles cancelling potentially ongoing Axios calls on unmount. It's also a
 * simple logic aggregator that reduces needed boilerplate to implement cancel tokens.
 *
 * @returns
 * createCancelToken - generates the cancel token sent in the Axios request.
 * cancelOngoingRequests - cancels the ongoing Axios requests.
 */
export const useCancelToken = () => {
  const source = useRef<CancelTokenSource | null>(null);

  const createCancelToken = useCallback(() => {
    source.current = axios.CancelToken.source();

    return source.current.token;
  }, []);

  const cancelOngoingRequests = useCallback(() => {
    if (source.current === null) {
      return;
    }

    source.current.cancel();
  }, []);

  useEffect(() => {
    return () => {
      cancelOngoingRequests();
    };
  }, [cancelOngoingRequests]);

  return { cancelOngoingRequests, createCancelToken };
};
