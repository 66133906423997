import { createUseStyles } from 'react-jss';

export const useDraggableRowStyles = createUseStyles({
  draggableRow: {
    transition: 'all 0.2s ease-out',
  },
  activeElem: {
    opacity: 0.2,
  },
});
