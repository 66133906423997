import { CommaSeparatedString } from 'components/Text/CommaSeparatedString';
import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import React from 'react';
import { CatalystTableCellType } from 'components/CatalystTable/types/catalystTableCellType';

export const TaskGroupsCell = ({ row }: CatalystTableCellProps) => {
  const { task_group_templates = [] } = row;

  return (
    <CommaSeparatedString
      values={task_group_templates}
      highlightedColumnKey={CatalystTableCellType.TaskGroups}
    />
  );
};
