import React from 'react';
import { FieldRecordUsageViewProps } from './FieldRecordUsageView.types';
import useFormatNumber from 'hooks/useFormatNumber';
import { BoldTextPartsFallback } from 'utils/intl/fallbacks/BoldTextParts';
import { FormattedMessage } from 'react-intl';

export const FieldRecordUsageView = ({
  numOfAllRecords,
  numOfRelatedRecords,
}: FieldRecordUsageViewProps) => {
  const formatNumberWithSeparators = useFormatNumber();

  return (
    <span>
      <FormattedMessage
        id='fieldUsage.numberOfTotal'
        defaultMessage='<b>{value}</b> of <b>{total}</b> records have values in the field.'
        values={{
          b: BoldTextPartsFallback,
          value: formatNumberWithSeparators(numOfRelatedRecords),
          total: formatNumberWithSeparators(numOfAllRecords),
        }}
      />
    </span>
  );
};
