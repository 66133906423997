import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useManageClassPermissionGroupsStyles = createUseStyles(
  (theme: ThemeState) => ({
    loaderWrapper: {
      display: 'grid',
      placeContent: 'center',

      height: '25rem',
      maxHeight: '100%',
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto auto 1fr',
      gap: '0.5rem',

      height: '100%',
      padding: '1rem 1rem 0',

      overflow: 'hidden',
    },
    disabledBtn: {
      borderColor: `${theme['border-color-base']} !important`,
      background: `${theme['border-color-base']} !important`,

      '&:hover': {
        borderColor: `${theme['border-color-base']} !important`,
        background: `${theme['border-color-base']} !important`,
      },
    },
    groupsList: {
      overflow: 'auto',
    },
  })
);
