import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTableColumnMetadata } from 'store/selectors/generalSelectors';
import TagContainer from 'components/TagContainer';
import { SelectOption } from 'utils/types/selectInput.types';
import { StateProps } from './State.types';
import { STATES_TO_TAG_MAP_MAPPING } from './State.consts';

export const State = ({ stateId, column }: StateProps) => {
  const { values = [] } = useSelector(getCurrentTableColumnMetadata)(column);

  const { text = '' } =
    values.find((item: SelectOption) => item.value === stateId) || {};

  return (
    <TagContainer
      type={STATES_TO_TAG_MAP_MAPPING[stateId]}
      name={stateId || text}
    />
  );
};
