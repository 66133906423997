import { createUseStyles } from 'react-jss';

const useActivateAccountPageStyles = createUseStyles({
  wrapper: {
    height: '100vh',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
});

export default useActivateAccountPageStyles;
