import { useCallback, useState } from 'react';
import { useUserValidationMessages } from './hooks';
import { FulfillmentValidationParameters } from './types';
import { isNil } from 'lodash';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';

/**
 * Validates if user type field is possible to fulfill based on it's constraints like min/max values etc. based on received
 * users and groups.
 */
export const useValidateFulfillment = (
  fieldLabel: string,
  isEditMode: boolean,
  minUsersError: string,
  fulfillmentFormErroredFields?: Set<string>
) => {
  const [tooltip, setTooltip] = useState('');
  const [fulfillmentErrors, setFulfillmentErrors] = useState<string[]>([]);
  const [isDisabledByFulfillment, setIsDisabledByFulfillment] = useState(false);

  const {
    notEnoughOptionsToSelectTooltip,
    notEnoughOptionsToSelectError,
  } = useUserValidationMessages();

  const getCanPickAnyOption = useCallback(
    ({ groups, users, limits }: FulfillmentValidationParameters) => {
      const canPickSyncedGroup =
        groups.length > 0 && limits.isAllowedToSyncGroupMembers;

      const canPickIndividualUser = users.length > 0;
      const canPickGroupMember =
        limits.isAllowedToSelectIndividualMembers &&
        groups.some(group => group.num_of_members > 0);

      return canPickIndividualUser || canPickSyncedGroup || canPickGroupMember;
    },
    []
  );

  const evaluateIsMinUsersFulfilledApiFlag = useCallback(
    (
      currentValue: UsersAndGroupsFormValue | undefined | null,
      validationData: FulfillmentValidationParameters,
      isMinUsersFulfilled: boolean
    ) => {
      if (validationData.isRequired) {
        return isMinUsersFulfilled;
      }

      // if field is not required we have to check if there is a possibility of changing the value from something to empty.
      if (isMinUsersFulfilled) {
        return true;
      }

      return (
        currentValue &&
        currentValue.users.length + currentValue.user_groups.length > 0
      );
    },
    []
  );

  const setTooltipAndDisableField = useCallback(() => {
    setTooltip(notEnoughOptionsToSelectTooltip);
    setIsDisabledByFulfillment(true);
  }, [notEnoughOptionsToSelectTooltip]);

  const setError = useCallback(() => {
    const error = notEnoughOptionsToSelectError;
    setFulfillmentErrors([isEditMode ? `${minUsersError}. ${error}` : error]);
  }, [isEditMode, minUsersError, notEnoughOptionsToSelectError]);

  const validateCollection = useCallback(
    (
      currentValue: UsersAndGroupsFormValue | undefined | null,
      validationData: FulfillmentValidationParameters,
      isMinUsersFulfilled: boolean
    ) => {
      const canPickAnyOption = getCanPickAnyOption(validationData);
      const isApiMinUsersFlagFulfilled = evaluateIsMinUsersFulfilledApiFlag(
        currentValue,
        validationData,
        isMinUsersFulfilled
      );

      if (isApiMinUsersFlagFulfilled && canPickAnyOption) {
        return;
      }

      setTooltipAndDisableField();

      const { isRequired } = validationData;

      if (!isRequired && !isEditMode) {
        return;
      }

      setError();
    },
    [
      getCanPickAnyOption,
      evaluateIsMinUsersFulfilledApiFlag,
      setTooltipAndDisableField,
      isEditMode,
      setError,
    ]
  );

  const getIsPartOfFormFulfillmentError = useCallback(() => {
    if (isNil(fulfillmentFormErroredFields)) {
      return false;
    }

    return fulfillmentFormErroredFields.has(fieldLabel);
  }, [fieldLabel, fulfillmentFormErroredFields]);

  const evaluateFieldAgainstFormFulfillmentValidation = useCallback(() => {
    const isPartOfFormFulfillmentError = getIsPartOfFormFulfillmentError();

    if (!isPartOfFormFulfillmentError) {
      return;
    }

    setTooltipAndDisableField();
    setError();
  }, [getIsPartOfFormFulfillmentError, setError, setTooltipAndDisableField]);

  return {
    validateFulfillment: validateCollection,
    evaluateFieldAgainstFormFulfillmentValidation,
    tooltip,
    fulfillmentErrors,
    isDisabledByFulfillment,
  };
};
