import { ColumnsMetadata } from 'utils/types/api/table.types';
import { TasksQuickFilterDatesTypes } from 'components/CatalystTable/components/Table/components/PaginationWrapper/components/TasksQuickFilters/components/TasksQuickFilterDates/hooks/useTasksQuickFiltersDates/types/tasksQuickFilterDatesTypes';
import { TasksQuickFilterDates } from 'components/CatalystTable/components/Table/components/PaginationWrapper/components/TasksQuickFilters/components/TasksQuickFilterDates/types/tasksQuickFilterDates';
import { DATE_PREDICATE_LABELS } from '../../../consts/datePredicateLabels';

const { DueDate } = TasksQuickFilterDatesTypes;

export const getDatePredicate = (
  columns: MappedObject<ColumnsMetadata, string>,
  value: TasksQuickFilterDates
) => {
  return [
    {
      column: columns[DueDate].alias,
      type: columns[DueDate].type,
      predicates: columns[DueDate].predicates || [],
      label: DATE_PREDICATE_LABELS[value],
      value: {
        predicateKey: value,
        predicateValues: {
          value: value,
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};
