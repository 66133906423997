import usePreviousState from 'hooks/usePreviousState';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentTable } from 'store/actions/filtersActions';
import { selectStack } from 'store/selectors/nestedObjectRecordsSelctors';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { getColumnSpecificDefaultWidth } from '../../utils/getColumnSpecificDefaultWidth';
import { getTablePreferencesState } from '../../utils/getTablePreferencesState';
import { UseCurrentTableColumnsWithDataParams } from './useCurrentTableColumnsWithData.types';
import { useColumnsTranslations } from './hooks/useColumnsTranslations';

export const useCurrentTableColumnsWithData = <T>({
  data,
  currentColumns,
  preferencesId,
  sort,
  currentTableName,
  getColumnsConfiguration,
  setColumnsConfigurationLoading,
  resetData,
  editModeEnabled,
  setEditModeEnabled,
  preferences,
  defaultColumnWidthsOverrides,
  columnLabelKey,
}: UseCurrentTableColumnsWithDataParams<T>) => {
  const dispatch = useDispatch();
  const dataWithAdditionalColumns = useMemo(
    () =>
      (data ?? []).map(row => {
        const { _meta, ...additionalColumns } =
          // @ts-ignore
          row?.configuration?.plugin_config ?? {};

        return { ...row, ...additionalColumns };
      }),
    [data]
  );

  const [shouldReloadColumns, setShouldReloadColumns] = useState(false);
  const { getTranslation } = useColumnsTranslations(currentColumns);
  const prevSort = usePreviousState(sort);
  const nestedRecordStack = useSelector(selectStack);

  const toggleReload = useCallback(() => {
    setShouldReloadColumns(!shouldReloadColumns);
  }, [shouldReloadColumns, setShouldReloadColumns]);

  useEffect(() => {
    return () => {
      if (
        nestedRecordStack.length < 1 &&
        currentTableName === CatalystTableType.NestedTable
      ) {
        dispatch(setCurrentTable(undefined));
      }
    };
  }, [dispatch, nestedRecordStack.length, currentTableName]);

  useEffect(() => {
    if (getColumnsConfiguration) {
      setColumnsConfigurationLoading(true);

      ((dispatch(getColumnsConfiguration(preferencesId)) as unknown) as Promise<
        void
      >).finally(() => {
        setColumnsConfigurationLoading(false);
      });
    }
  }, [
    dispatch,
    getColumnsConfiguration,
    preferencesId,
    setColumnsConfigurationLoading,
  ]);

  useEffect(() => {
    if (prevSort === undefined) {
      if (resetData) dispatch(resetData());
    }
  }, [dispatch, prevSort, resetData]);

  const toggleEditMode = useCallback(() => {
    setEditModeEnabled(!editModeEnabled);
  }, [editModeEnabled, setEditModeEnabled]);

  const columns = useMemo(
    () =>
      Object.keys(currentColumns).map((column: string) => {
        const {
          [column]: {
            alias,
            sort_ok: sortOk,
            search_key: searchKey,
            label,
            width,
          },
        } = currentColumns;
        const storedWidth =
          width ??
          (currentTableName &&
            getTablePreferencesState(
              preferences,
              currentTableName,
              preferencesId
            )?.columnResizing?.columnWidths[alias]);

        return {
          id: alias,
          Header: label
            ? label
            : GlobalIntlSingleton.dynamicFormatMessage({
                id: `${columnLabelKey}.${alias}`,
              }),
          Cell: ({ value, row }: MappedObject<unknown>) =>
            getTranslation(alias, value, row),
          accessor: alias,
          disableSortBy: !sortOk,
          minWidth: 75,
          searchKey,
          width:
            storedWidth ||
            getColumnSpecificDefaultWidth(alias, defaultColumnWidthsOverrides),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnLabelKey, currentColumns, shouldReloadColumns]
  );

  return {
    columns,
    dataWithAdditionalColumns,
    toggleReload,
    toggleEditMode,
  };
};
