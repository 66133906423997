import { useTaskOptionsContext } from 'components/SidePanels/TasksPanel/contexts/TaskOptionsContext';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { useIntl } from 'react-intl';
import { DEFAULT_GROUP_ASSIGNEES_LIMIT } from 'utils/consts';

/**
 * Handles the logic for limiting the number of user groups that can be assigned to a task.
 */
export const useTaskGroupAssigneesLimit = () => {
  const intl = useIntl();

  const { taskAssignees } = useTasksPanelContext();
  const { taskGroupAssigneeOptions } = useTaskOptionsContext();

  const limit = taskGroupAssigneeOptions?.restrictions?.limit_items;

  const isGroupsLimitReached = limit
    ? taskAssignees.userGroups.length >= limit
    : taskAssignees.userGroups.length >= DEFAULT_GROUP_ASSIGNEES_LIMIT;

  const disabledBtnTooltipText = intl.formatMessage({
    id: 'misc.maxGroupsAssignedTooltip',
    defaultMessage:
      'The maximum number of assigned user groups has been reached. Unassign user groups to assign new ones.',
  });

  return {
    disabledBtnTooltipText,
    groupAssigneesLimit: limit,
    isGroupsLimitReached,
  };
};
