import { createUseStyles } from 'react-jss';

export const useCustomDragLayerStyles = createUseStyles({
  customDragLayerWrapper: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
});
