import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import Editor from 'components/Inputs/Editor';
import { BASIC_TOOLBAR } from '../../../../../../../../HTMLEditorWrapper/consts';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { TaskFormType } from '../../types';
import { useTaskFormInstructionsSectionStyles } from './TaskFormInstructionsSection.styles';

export const TaskFormInstructionsSection = () => {
  const intl = useIntl();
  const styles = useTaskFormInstructionsSectionStyles();

  const { values } = useFormikContext<TaskFormType>();

  return (
    <FormCategory
      className={styles.sectionWrapper}
      noMarginTop
      collapsible
      collapsibleContent={
        <Editor
          data={values[TaskFormField.Instructions] ?? ''}
          name={TaskFormField.Instructions}
          placeholder={intl.formatMessage({
            id: 'tasksPanel.form.instructionsPlaceholder',
            defaultMessage: 'Add a comment for the completer',
          })}
          customToolbar={BASIC_TOOLBAR}
          minHeight={100}
        />
      }
    >
      <FormattedMessage
        id='tasksPanel.form.instructions'
        defaultMessage='Instructions'
      />
    </FormCategory>
  );
};
