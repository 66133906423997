import React from 'react';
import Select from 'components/Inputs/Select';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { FormattedMessage } from 'react-intl';
import { FieldTaskTypeProps } from './TaskFormTaskTypeField.types';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { SelectOption } from 'components/lib/Select';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { useFormikContext } from 'formik';
import { TaskFormType } from '../../types';
import { kebabCase } from 'lodash';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useCheckIfTaskTypeHasTemplates } from './hooks';
import { TaskTypes } from 'pages/TaskTemplates/enums';

export const TaskFormTaskTypeField = ({
  options,
  isRequired,
  taskTypeSpecificOptions,
  className,
}: FieldTaskTypeProps) => {
  const {
    selectedResource: {
      record: { permissions: { edit: editRecordPermission = false } = {} } = {},
    } = {},
  } = useSelectedResourceContext();
  const { setFieldValue, setFieldTouched } = useFormikContext<TaskFormType>();

  const { hasTemplates, isLoading } = useCheckIfTaskTypeHasTemplates(
    taskTypeSpecificOptions?.[TaskTypes.RecordUpdate].autocomplete
  );

  const handleChange = () => {
    setFieldValue(TaskFormField.TaskTemplate, '');
    setFieldTouched(TaskFormField.TaskTemplate, false);
  };

  const getShouldBeTaskTypeDisabled = (taskType: string) => {
    if (taskType === TaskTypes.RecordUpdate) {
      return !hasTemplates || !editRecordPermission;
    }

    return false;
  };

  return (
    <div className={className}>
      <FormLabel bolded={false} required={isRequired}>
        <FormattedMessage
          id='tasksPanel.form.taskType'
          defaultMessage='Task type'
        />
      </FormLabel>

      <Select
        name={TaskFormField.TaskType}
        loading={isLoading}
        disabled={isLoading}
        onChange={handleChange}
      >
        {options.map(option => (
          <SelectOption
            key={option.value}
            value={option.value}
            disabled={getShouldBeTaskTypeDisabled(option.value)}
            data-testid={`${kebabCase(option.value)}-option`}
          >
            {GlobalIntlSingleton.dynamicFormatMessage({
              id: `tasksPanel.${option.value}`,
            })}
          </SelectOption>
        ))}
      </Select>
    </div>
  );
};
