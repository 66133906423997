import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { transformUserGroups } from './utils/transformUserGroups';
import { transformObjectClassFieldUsages } from './utils/transformObjectClassFields';
import { wrapObjectClassNamesForUsageTree } from './utils/wrapObjectClassFieldName';
import { UserGroupUsage } from 'utils/types/api/userGroups.types';
import { UsageViewConfig } from 'components/ResourceUsagePanelView/types/usageViewConfig';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';
import { GroupClassPemrmissionSetUsageView } from './components/GroupClassPemrmissionSetUsageView';
import { GroupTaskAssignmentUsageView } from './components/GroupTaskAssignmentUsageView';

/**
 * Generates the display configuration for the user group usage panel.
 *
 * @param usage User group usage data.
 */
export const useUserGroupUsageDisplayConfig = (
  usage: UserGroupUsage | undefined
) => {
  const intl = useIntl();

  const displayConfig = useMemo<UsageViewConfig[]>(() => {
    if (!usage) {
      return [];
    }

    return [
      {
        usage: usage.sequences,
        title: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
        type: UsageViewType.List,
      },
      {
        usage: transformUserGroups(usage.user_groups),
        title: intl.formatMessage({
          id: 'misc.userGroupPermissionSets',
          defaultMessage: 'User group permission sets',
        }),
        type: UsageViewType.List,
      },
      {
        usage: wrapObjectClassNamesForUsageTree(
          transformObjectClassFieldUsages(usage.object_classes_fields),
          intl
        ),
        title: intl.formatMessage({
          id: 'misc.objectClassUserTypeFields',
          defaultMessage: 'Object class user type fields',
        }),
        headline: intl.formatMessage({
          id: 'userGroups.classPermissionSetsUsage',
          defaultMessage:
            'This user group has been used in the following object class fields:',
        }),
        type: UsageViewType.Tree,
      },
      {
        title: intl.formatMessage({
          id: 'misc.objectClassPermissionSets',
          defaultMessage: 'Object class permission sets',
        }),
        type: UsageViewType.Custom,
        shouldBeVisible: usage.num_of_object_classes_permission_sets > 0,
        content: (
          <GroupClassPemrmissionSetUsageView
            usage={usage.object_classes_permission_sets}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'misc.taskAssignments',
          defaultMessage: 'Task assignments',
        }),
        type: UsageViewType.Custom,
        shouldBeVisible: usage.num_of_tasks > 0,
        content: (
          <GroupTaskAssignmentUsageView
            numOfTaskAssignments={usage.num_of_tasks}
          />
        ),
      },
    ];
  }, [usage, intl]);

  return { displayConfig };
};
