import { Multiselect } from 'components/lib/Multiselect';
import React from 'react';
import { useIntl } from 'react-intl';
import { FilterMultiSelectValueProps } from './FilterMultiSelectValue.types';
import NoMatchesFound from 'components/NoMatchesFound';
import { SelectOption } from 'components/lib/Select';
import { getOptionText } from '../../utils';
import { SearchBoldIcon } from 'components/Icon';
import { useFilterMultiSelectValueStyles } from './FilterMultiSelectValue.styles';

export const FilterMultiSelectValue = ({
  value,
  options,
  predicateSet,
  testId,
  onChange,
}: FilterMultiSelectValueProps) => {
  const intl = useIntl();
  const styles = useFilterMultiSelectValueStyles();

  const handleChange = (value: string[]) => {
    onChange(value);
  };

  return (
    <div className={styles.multiSelectWrapper}>
      <Multiselect
        value={value}
        placeholder={intl.formatMessage({
          id: 'predicates.select',
          defaultMessage: 'Select',
        })}
        icon={<SearchBoldIcon className={styles.searchIcon} size={12} />}
        notFoundContent={<NoMatchesFound />}
        showArrow
        data-testid={testId}
        onChange={handleChange}
      >
        {options.map(option => (
          <SelectOption key={option.value} value={option.value}>
            {getOptionText(option, predicateSet)}
          </SelectOption>
        ))}
      </Multiselect>
    </div>
  );
};
