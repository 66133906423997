import clsx from 'clsx';
import ErrorLabel from 'components/ErrorLabel';
import React from 'react';
import useInPlaceEditLabel from './styles';
import { InPlaceEditLabelProps } from './types';
import TooltipString from '../../../TooltipString';
import { createTestId } from 'utils/functions/createTestId';
import { FORM_LABEL_TESTID } from 'utils/testIds';

const InPlaceEditLabel = ({
  label,
  required,
  isEditMode,
}: InPlaceEditLabelProps) => {
  const classes = useInPlaceEditLabel({ size: 'small' });

  return (
    /*
    "inPlaceEditLabel" class is used in form preview to add additional margin based on
    spacing within the fields setting
    */
    <div className={clsx(classes.label, 'inPlaceEditLabel')}>
      <span data-testid={createTestId(FORM_LABEL_TESTID, label ?? 'undefined')}>
        <TooltipString text={label ?? ''}>{label}</TooltipString>
      </span>
      {!!isEditMode && !!required && <ErrorLabel />}
    </div>
  );
};

export default InPlaceEditLabel;
