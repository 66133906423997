import { UsageViewConfig } from '../types/usageViewConfig';
import { UsageViewType } from '../types/usageViewType';

/**
 * Checks if resource has any usage to display.
 *
 * @param displayConfig List of usage view configurations.
 *
 * @returns True if resource has any usage to display, false otherwise.
 */
export const hasResourceAnyUsage = (displayConfig: UsageViewConfig[]) => {
  return displayConfig.some(section => {
    if (section.type === UsageViewType.Custom) {
      return section.shouldBeVisible;
    }

    return section.usage.length > 0;
  });
};
