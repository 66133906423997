import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import FileLink from 'components/FileLink';
import React from 'react';

export const TemplateFileCell = ({ row }: CatalystTableCellProps) => {
  const { template_file_name, id } = row ?? {};
  return (
    <FileLink
      label={template_file_name}
      downloadedFileName={`${template_file_name}.docx`}
      documentTemplateID={id}
    />
  );
};
