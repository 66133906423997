import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { InvalidFilterData } from '../types/InvalidFilterData';
import { showWarningToast } from 'features/toasts/utils/showWarningToast';

/**
 * Displays warning toasts about filters being changed due to invalid values.
 * @param filterErrors Information about changes made in the filters to inform the user about.
 */
export const displayWarningToastsForCorrectedFilters = (
  filterErrors: InvalidFilterData[]
) => {
  filterErrors.forEach(error => {
    error.invalidValues.forEach(invalidValue =>
      showWarningToast({
        title: GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.filtersChangedToastTitle',
          defaultMessage: 'Your filter settings have been changed.',
        }),
        subtitle: GlobalIntlSingleton.intl.formatMessage(
          {
            id: 'misc.filtersChangedToastMessage',
            defaultMessage:
              '{optionName} is not available as an option in {fieldLabel} field. It has been removed from the filters.',
          },
          { fieldLabel: error.fieldKey, optionName: invalidValue }
        ),
      })
    );
  });
};
