import clsx from 'clsx';
import { useTableRowContext } from 'components/CatalystTable/contexts/TableRowContext';
import React from 'react';
import { TABLE_ROW } from 'utils/elementsIds';
import { TABLE_ROW_ITEM_ID_TESTID } from 'utils/testIds';
import { useEmptyTableRowStyles } from './EmptyTableRow.styles';

export const EmptyTableRow = ({ index }: { index: number }) => {
  const { density } = useTableRowContext();
  const styles = useEmptyTableRowStyles({ density, index });
  return (
    <div
      key={`empty-${index}`}
      className={clsx(styles.tr)}
      data-testid={`${TABLE_ROW_ITEM_ID_TESTID}${index}`}
      id={TABLE_ROW.replace('{0}', `empty-${index.toString()}`)}
    ></div>
  );
};
