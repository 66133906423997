import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const usePeopleListElementStyles = createUseStyles((theme: ThemeState) => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    userData: {
      maxWidth: 'calc(100% - 45px)', // 32px - avatar width; ~13px - action button
    },
    category: {
      fontSize: 12,
      lineHeight: 1.3,
      color: theme['secondary-color'],
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 16px)',
      gap: '9px',
    },
    truncatedName: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    name: {
      marginBottom: 0,
      fontSize: 14,
      lineHeight: '19px',
      color: theme['heading-color'],
      wordBreak: 'break-word',
    },
    bottomText: {
      fontSize: 12,
      lineHeight: '16px',
      color: theme['secondary-color'],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tag: {
      borderColor: theme['primary-color'],
      backgroundColor: theme['info-bg'],
      color: theme['primary-color'],
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 12,
      padding: [3, 6],
      '& svg': {
        fontSize: 12,
      },
    },
    buttonTransparent: {
      color: theme['primary-color'],
      fontSize: 12,
    },
    permissionSetList: {
      minWidth: 130,
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25) !important',
      padding: '0.25rem 0',
      maxHeight: 330,
      overflow: 'auto',
    },
    permissionSetListItem: {
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'default',
    },
  };
});

export default usePeopleListElementStyles;
