import { useFormikContext } from 'formik';
import { TaskFormType } from '../../../types';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { AccountTypeOptions } from 'pages/Users/enums';
import {
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';

export const useTaskAssigments = () => {
  const { values, setFieldValue } = useFormikContext<TaskFormType>();

  const selectedUsers = values[TaskFormField.Assignees].users;
  const selectedGroups = values[TaskFormField.Assignees].groups;
  const isOneTCUserSelected = selectedUsers.some(
    user => user.account_type === AccountTypeOptions.OneTimeCompletion
  );

  const checkShouldOptionBeDisabled = (
    option: SelectUserOption | SelectUserGroupOption
  ) => {
    if (!selectedGroups.length && !selectedUsers.length) {
      return false;
    }

    if (isOneTCUserSelected) {
      return true;
    }

    if ('account_type' in option) {
      return option.account_type === AccountTypeOptions.OneTimeCompletion;
    }

    return false;
  };

  const handleUserDelete = (filteredUsers: SelectUserOption[]) => {
    setFieldValue(TaskFormField.Assignees, {
      users: filteredUsers,
      groups: selectedGroups,
    });
  };

  const handleGroupDelete = (filteredGroups: SelectUserGroupOption[]) => {
    setFieldValue(TaskFormField.Assignees, {
      users: selectedUsers,
      groups: filteredGroups,
    });
  };

  return {
    isOneTCUserSelected,
    checkShouldOptionBeDisabled,
    handleUserDelete,
    handleGroupDelete,
  };
};
