import { createContext, useContext } from 'react';
import { ClassFieldFormContextType } from './ClassFieldFormContext.types';
import { noop } from 'lodash';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { FormMode } from 'utils/Enums/FormMode';

export const ClassFieldFormContext = createContext<ClassFieldFormContextType>({
  mode: FormMode.View,
  readOnly: false,
  options: undefined,
  validators: undefined,
  panelId: FlexLayoutWindows.ObjectClassAddField,
  deletedUsers: [],
  setInitialValues: noop,
});

export const ClassFieldFormContextProvider = ClassFieldFormContext.Provider;

export const useClassFieldFormContext = () => useContext(ClassFieldFormContext);
