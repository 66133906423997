import { AutocompleteObjectClassFieldFilterValue } from 'components/SidePanels/FiltersPanel/types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';

export const isAutoCompleteValueString = (
  value:
    | AutocompleteSelectValue
    | AutocompleteObjectClassFieldFilterValue
    | string
): value is AutocompleteObjectClassFieldFilterValue => {
  return 'field_type' in (value as AutocompleteObjectClassFieldFilterValue);
};
