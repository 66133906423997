import { RecordsApiErrorResponse } from '../../../../components/RecordsCards/types';
import { InvalidFilterData } from '../../types/InvalidFilterData';
import { extractFilterValueFromError } from '../correctFilterList/utils/extractFilterValueFromError';

/**
 * Checks the error response from /api/object-records call to identify if error is related to filters.
 * @param responseData Axios error api call response data.
 * @returns List of fields and their filter values that are invalid based on the API response.
 */
export const checkResponseForFilterErrors = (
  responseData: RecordsApiErrorResponse
): InvalidFilterData[] => {
  const errors = Object.entries(responseData);

  return errors.reduce<InvalidFilterData[]>((acc, [key, errorValue]) => {
    if (!Array.isArray(errorValue)) {
      return acc;
    }

    const validErrors = errorValue.reduce<string[]>((acc, error) => {
      if (error.includes('is not a valid choice.')) {
        return [...acc, extractFilterValueFromError(error)];
      }

      return acc;
    }, []);

    if (validErrors.length > 0) {
      return [...acc, { fieldKey: key, invalidValues: validErrors }];
    }

    return acc;
  }, []);
};
