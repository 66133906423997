import React from 'react';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import clsx from 'clsx';
import { CheckMarkIcon } from 'components/Icon';
import { OptionGroupAvatar } from 'components/OptionAvatars/OptionGroupAvatar';
import { UsersGroupsListItemProps } from '../../UsersGroupsSelector/types';
import { useUsersGroupsListItemSelectAndDeselectStyles } from './UsersGroupsListItemSelectAndDeselect.styles';
import { useUserGroupsSelectorStyles } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/UsersGroupsSelector.styles';
import { USERS_AND_GROUPS_SELECT_ITEM_SELECT_AND_DESELECT_TYPE_TESTID } from 'utils/testIds';

// Avoid turning these into 'everything components', build more if needed, they are cheap to build and if kept simple, easy to maintain

export const UsersGroupsListItemSelectAndDeselect = ({
  id,
  item,
  handleClick,
  selected,
  isDisabled,
  searchText,
  limitReached,
  additionalText,
}: UsersGroupsListItemProps) => {
  const styles = useUsersGroupsListItemSelectAndDeselectStyles();
  const sharedStyles = useUserGroupsSelectorStyles();

  const finalDisableState = (limitReached && !selected) || isDisabled;

  const handleOnClick = () => {
    if (finalDisableState) {
      return;
    }

    handleClick(item);
  };

  return (
    <button
      type='button'
      id={id}
      className={clsx(sharedStyles.optionItem, {
        [sharedStyles.optionDisabled]: finalDisableState,
      })}
      onClick={handleOnClick}
      aria-pressed={selected}
      data-testid={`${USERS_AND_GROUPS_SELECT_ITEM_SELECT_AND_DESELECT_TYPE_TESTID}-${item.type}-${item.id}`}
    >
      {item.type === UserOrGroupSelectOptionType.User ? (
        <OptionAvatar
          disabledText={undefined}
          option={item}
          searchValue={searchText ?? ''}
          disabled={finalDisableState}
          selected={selected}
          additionalText={additionalText}
          disableDefaultSelectionStyle
        />
      ) : (
        <OptionGroupAvatar
          disabledText={undefined}
          option={item}
          searchValue={searchText ?? ''}
          disabled={finalDisableState}
          selected={selected}
          disableDefaultSelectionStyle
        />
      )}
      {selected && <CheckMarkIcon className={styles.checkmarkIcon} size={14} />}
    </button>
  );
};
