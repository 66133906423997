import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';
import { Select } from 'components/lib/Select';
import { TASK_QUICK_FILTER_DATES } from 'utils/testIds';
import { useTasksQuickFiltersStyle } from '../../TasksQuickFilters.styles';
import { useTasksQuickFiltersDates } from './hooks/useTasksQuickFiltersDates';

export const TasksQuickFiltersDates = () => {
  const styles = useTasksQuickFiltersStyle();
  const ref = useRef(null);

  const {
    datePeriod,
    datesOptions,
    handleChangeDatePeriod,
  } = useTasksQuickFiltersDates();

  return (
    <Space direction='vertical' className={styles.datesWrapper}>
      <FormattedMessage id='tasks.dates' defaultMessage='Dates' />
      <div ref={ref} data-testid={TASK_QUICK_FILTER_DATES}>
        <Select
          className={styles.quickFilterSelect}
          dropdownClassName={styles.datesSelect}
          options={datesOptions}
          value={datePeriod}
          onChange={handleChangeDatePeriod}
          getPopupContainer={() => ref.current || document.body}
          placeholder={
            <FormattedMessage id='misc.custom' defaultMessage='Custom' />
          }
        />
      </div>
    </Space>
  );
};
