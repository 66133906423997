import { useFormikContext } from 'formik';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts/ClassFieldFormContext';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { maxUsersCount } from '../../../consts';
import { ClassFieldForm } from '../../../../../types';

export const useUsersValidation = () => {
  const intl = useIntl();
  const { deletedUsers } = useClassFieldFormContext();
  const {
    values: {
      user_groups: userGroups,
      users = [],
      min_users_values: rawMinValues,
      max_users_values: maxValues,
    },
  } = useFormikContext<ClassFieldForm>();

  const minValues = userGroups?.length ? 0 : rawMinValues ?? 1;

  const [alerts, setAlerts] = useState({
    limitUsersError: '',
    deletedUsersInfo: '',
    deletedUsersError: '',
  });

  const clearInfoMessage = () => {
    setAlerts(prev => ({ ...prev, deletedUsersInfo: '' }));
  };

  const reachedMin = users.length >= minValues;
  const reachedMax = maxValues ? users.length >= maxValues : true;

  useEffect(() => {
    if (deletedUsers.length) {
      if (reachedMax && reachedMin) {
        setAlerts(prev => ({
          ...prev,
          deletedUsersInfo: intl.formatMessage(
            {
              id: 'misc.numberOfUsersHaveBeenDeleted',
              defaultMessage:
                '{deletedUsers} of the previously selected users have been deleted from the system.',
            },
            { deletedUsers: deletedUsers.length }
          ),
        }));
      } else {
        setAlerts(prev => ({
          ...prev,
          deletedUsersError: intl.formatMessage(
            {
              id: 'misc.usersHaveBeenDeleted',
              defaultMessage:
                '{users, plural, =0 {All} other {Some}} of the previously selected users have been deleted from the system. At least {value} {value, plural, =1 {user} other {users}} must be selected.',
            },
            { value: maxValues ?? minValues, users: users.length }
          ),
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users.length >= maxUsersCount)
      setAlerts(prev => ({
        ...prev,
        limitUsersError: intl.formatMessage(
          {
            id: 'misc.usersLimitReached',
            defaultMessage:
              'The maximum number of users ({ itemsLimit }) have been added.',
          },
          { itemsLimit: maxUsersCount }
        ),
      }));
    if (users.length < maxUsersCount && alerts.limitUsersError) {
      setAlerts(prev => ({
        ...prev,
        limitUsersError: '',
      }));
    }
    if (reachedMax && reachedMin)
      setAlerts(prev => ({ ...prev, deletedUsersError: '' }));
  }, [intl, minValues, reachedMax, reachedMin, users, alerts.limitUsersError]);

  return { alerts, clearInfoMessage };
};
