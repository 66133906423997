import React, { forwardRef, useCallback } from 'react';
import { Select as AntSelect } from 'antd';
import { SelectProps as AntSelectProps, LabeledValue } from 'antd/lib/select';
import useSelectStyles from './styles';
import { ArrowDownIcon } from 'components/Icon';
import { Select as FSelect, SelectProps as FSelectProps } from 'formik-antd';
import clsx from 'clsx';
import usePopupContainer from 'hooks/usePopupContainer';
import useGlobalStyle from 'hooks/useGlobalStyle';
import { ANT_SELECT_TESTID } from 'utils/testIds';
import { Keys } from 'utils/Enums/keys';

type SelectProps = Omit<AntSelectProps<any>, 'mode'>;

export interface FormikSelectProps
  extends FSelectProps<
    string | string[] | number | number[] | LabeledValue | LabeledValue[]
  > {
  readOnly?: boolean;
}

const { Option } = AntSelect;

const listItemHeight = 32;

export type ExtendedSelect = SelectProps & {
  icon?: React.ReactNode;
  onBlur?: VoidFunction;
};

export type AlignPoints = string[];

export type Overflow = {
  adjustX: boolean;
  adjustY: boolean;
};

export type DropdownAlign = {
  points: AlignPoints;
  offset?: number[];
  overflow?: Overflow;
};

export const Select = forwardRef<AntSelect, ExtendedSelect>(
  (
    {
      children,
      className,
      disabled,
      getPopupContainer,
      icon,
      loading,
      ...rest
    },
    ref
  ) => {
    const classes = useSelectStyles({});
    const globalClasses = useGlobalStyle();
    const popupContainer = usePopupContainer();

    const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === Keys.ESCAPE && rest.onBlur) {
          rest.onBlur();
          (e.target as HTMLSelectElement).blur();
        }
      },
      [rest]
    );

    return (
      <>
        <AntSelect
          /*
       listItemHeight = 32 fixes small range of heights where instead of ant scroll you get native one
      32 is default value but when it is not set explicitly sometimes native scroll appears instead
      of ant scroll
      */
          className={clsx([
            classes.multiselect,
            className,
            {
              [globalClasses.disabledInput]: disabled,
              [classes.withIcon]: !!icon,
            },
          ])}
          // "loading" prop does not work when "suffixIcon" is defined so we conditionally manage it
          suffixIcon={
            loading ? undefined : (
              <ArrowDownIcon size={7} className={classes.arrowDown} />
            )
          }
          onKeyDown={onKeyDown}
          dropdownClassName={classes.dropdown}
          getPopupContainer={getPopupContainer || popupContainer}
          {...{ ...rest, listItemHeight, disabled, ref, loading }}
          // @ts-ignore
          data-testid={rest['data-testid'] || ANT_SELECT_TESTID}
        >
          {children}
        </AntSelect>
        {icon}
      </>
    );
  }
);

export const FormikSelect: React.FC<FormikSelectProps> = ({
  children,
  className,
  disabled,
  loading,
  ...rest
}) => {
  const classes = useSelectStyles({});
  const globalClasses = useGlobalStyle();
  const getPopupContainer = usePopupContainer();

  return (
    <FSelect
      className={clsx([
        classes.multiselect,
        className,
        {
          [globalClasses.disabledInput]: disabled,
        },
      ])}
      data-testid={ANT_SELECT_TESTID}
      dropdownClassName={classes.dropdown}
      // "loading" prop does not work when "suffixIcon" is defined so we conditionally manage it
      suffixIcon={
        loading ? undefined : (
          <ArrowDownIcon size={7} className={classes.arrowDown} />
        )
      }
      {...{ ...rest, listItemHeight, getPopupContainer, disabled, loading }}
    >
      {children}
    </FSelect>
  );
};

export const SelectOption = Option;
