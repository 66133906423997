import { TaskOptions } from 'components/SidePanels/TasksPanel/types';
import useData from 'hooks/useData';
import { TASKS_LIST } from 'utils/endpoints';

export const useTaskOptions = () => {
  const [
    options,
    { loading: areOptionsLoading, error: optionsError },
  ] = useData<TaskOptions>(TASKS_LIST, {
    requestType: 'options',
  });

  return { options, areOptionsLoading, optionsError };
};
