import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useRedirectFromTableWithParams = (withReplace?: boolean) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { currentPage, pageSize, isInfinite, sort } = useTableContext();

  return useCallback(
    (
      generatedUrl: string,
      additionalParams?: MappedObject<string | number | boolean>
    ) => {
      const pushState = isInfinite
        ? { url: pathname, isInfinite: true, sort }
        : {
            url: pathname,
            currentPage,
            pageSize,
            sort,
          };
      const params = additionalParams
        ? { ...pushState, ...additionalParams }
        : pushState;

      if (withReplace) return history.replace(generatedUrl, params);

      history.push(generatedUrl, params);
    },
    [currentPage, history, isInfinite, pageSize, pathname, sort, withReplace]
  );
};
