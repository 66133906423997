import { FormikErrors, FormikHelpers } from 'formik';
import { usePostWithToasts } from 'hooks/usePostWithToasts';
import { useToggle } from 'hooks/useToggle';
import { partition, omit, isNil } from 'lodash';
import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  SetStateAction,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  useLocation,
  useParams,
  useHistory,
  generatePath,
} from 'react-router-dom';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import {
  addField,
  updateField,
  setObjectClassesFieldsSelectedRow,
  removeField,
} from 'store/actions/objectClassesFieldsActions';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { FormMode } from 'utils/Enums/FormMode';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { isNotFound, isBadRequest } from 'utils/apiUtils';
import {
  OBJECT_CLASS_FIELD_DETAILS,
  OBJECT_CLASS_DETAILS_FIELDS,
} from 'utils/endpoints';
import objectRemoveKeysWithValue from 'utils/functions/objectRemoveKeysWithValue';
import {
  ObjectClassFieldTypes,
  ObjectClassFieldUpdated,
  UserGroupMeta,
  UserMeta,
} from 'utils/types/api/objectClassesFields.types';
import { aliasNameErrorRegexp } from '../../consts';
import { getAvailableUsers, getAvailableGroups } from '../../utils';
import { useClassFieldData } from '../useClassFieldData';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ClassFieldForm } from '../../components/ClassFieldForm/types';
import { ClassFieldDetailLocationState } from '../../types';
import { AxiosError } from 'axios';
import {
  getValueIfAnyElementOnArray,
  tryGetRemovedUsersOrGroupsErrorResponseData,
} from './utils';

export const useClassFieldForm = (
  mode: FormMode,
  panelId: FlexLayoutWindows
) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { state: { id } = {} } = useLocation<ClassFieldDetailLocationState>();
  const { id: classId } = useParams<{ id: string }>();
  const { getFieldData, data, ...rest } = useClassFieldData();
  const [
    isOpenNoExistsFieldModal,
    { toggleOn: openNoExistsFieldModal, toggleOff: closeNoExistsFieldModal },
  ] = useToggle(false);
  const history = useHistory();

  useEffect(() => {
    if (id === undefined || classId === undefined) return;

    getFieldData(classId, id);
  }, [getFieldData, classId, id]);

  const initialData = useMemo(() => {
    if (mode === FormMode.Create) return undefined;

    return data;
  }, [data, mode]);

  const {
    alias = '',
    label = '',
    type = ObjectClassFieldTypes.String,
    _meta: { users = [], user_groups: userGroups = [] } = {},
    ...defaultValues
  } = initialData || {};

  const transformGroups = (groups: UserGroupMeta[]) => {
    return groups.map(group => {
      return { ...group, text: group.name ?? '' };
    });
  };

  const partitionUsersByStatus = (users: UserMeta[]) => {
    const [activeUsers, deletedUsers] = partition(
      users,
      ({ is_deleted: isDeleted }) => !isDeleted
    );

    return {
      activeUsers,
      deletedUsers,
    };
  };

  const { activeUsers, deletedUsers } = partitionUsersByStatus(users);

  const [initialValues, setInitialValues] = useState<ClassFieldForm>({
    [ClassFieldFormFields.Alias]: alias,
    [ClassFieldFormFields.Label]: label,
    [ClassFieldFormFields.Type]: type,
    [ClassFieldFormFields.Unique]: undefined,
    [ClassFieldFormFields.Identifier]: undefined,
    [ClassFieldFormFields.Users]: activeUsers,
    [ClassFieldFormFields.Groups]: transformGroups(userGroups),
    ...objectRemoveKeysWithValue(defaultValues, undefined),
  });

  useEffect(() => {
    setInitialValues({
      [ClassFieldFormFields.Alias]: alias,
      [ClassFieldFormFields.Label]: label,
      [ClassFieldFormFields.Type]: type,
      [ClassFieldFormFields.Unique]: undefined,
      [ClassFieldFormFields.Identifier]: undefined,
      [ClassFieldFormFields.Users]: activeUsers,
      [ClassFieldFormFields.Groups]: transformGroups(userGroups),
      ...objectRemoveKeysWithValue(defaultValues, undefined),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alias]);

  const { sendData, isLimitExceeded } = usePostWithToasts<
    ClassFieldForm,
    ObjectClassFieldUpdated
  >(mode, CatalystTableType.ObjectClassesFields);

  const parseFormDataToApi = useCallback(
    (formData: ClassFieldForm) => {
      const { users = [], user_groups = [], ...values } = formData;
      const isUserType = values.type === ObjectClassFieldTypes.User;

      return {
        ...values,
        ...(isUserType && {
          options: {
            user_groups: user_groups.map(({ id }) => id),
            users: users.map(({ id }) => id),
          },
        }),
        default_value: getDefaultValue(formData),
        ...(mode === FormMode.Create && { order: 0 }),
      };
    },
    [mode]
  );

  const getDefaultValue = (formData: ClassFieldForm) => {
    const { default_value: defaultValue, type } = formData;

    if (type === ObjectClassFieldTypes.Bool) return !!defaultValue;

    return defaultValue === '' ? null : defaultValue;
  };

  const parseAndSetErrors = useCallback(
    (setErrors: (errors: FormikErrors<ClassFieldForm>) => void) => (
      errors: FormikErrors<ClassFieldForm>
    ) => {
      if (errors.alias && aliasNameErrorRegexp.test(errors.alias)) {
        errors.alias = intl.formatMessage({
          id: 'objectClassesFields.errors.invalidAlias',
          defaultMessage: 'Invalid alias',
        });
      }

      setErrors(errors);
    },
    [intl]
  );

  const handlePriorRemovedUsersOrGroupsError = useCallback(
    async (
      error: AxiosError,
      formData: ClassFieldForm,
      setValues: (
        values: SetStateAction<ClassFieldForm>,
        shouldValidate?: boolean
      ) => void
    ) => {
      const errorResponseData = tryGetRemovedUsersOrGroupsErrorResponseData(
        error
      );

      if (isNil(errorResponseData)) {
        return;
      }

      const selectedUsers = formData.users ?? [];
      const selectedGroups = formData.user_groups ?? [];

      // both users and groups could be deleted prior to save
      const isUsersError = !!errorResponseData.options.users;
      const isGroupsError = !!errorResponseData.options.user_groups;

      const [availableUsers, availableGroups] = await Promise.all([
        isUsersError ? getAvailableUsers(selectedUsers) : selectedUsers,
        isGroupsError ? getAvailableGroups(selectedGroups) : selectedGroups,
      ]);

      const availableUsersAndGroups = [...availableGroups, ...availableUsers];

      setValues(
        {
          ...formData,
          [ClassFieldFormFields.Users]: availableUsers,
          [ClassFieldFormFields.Groups]: availableGroups,
          [ClassFieldFormFields.MinUsersValues]: getValueIfAnyElementOnArray(
            availableUsersAndGroups,
            formData.min_users_values
          ),
          [ClassFieldFormFields.MaxUsersValues]: getValueIfAnyElementOnArray(
            availableUsersAndGroups,
            formData.max_users_values
          ),
          [ClassFieldFormFields.MinGroupsValues]: getValueIfAnyElementOnArray(
            availableGroups,
            formData.min_groups_values
          ),
          [ClassFieldFormFields.MaxGroupsValues]: getValueIfAnyElementOnArray(
            availableGroups,
            formData.max_groups_values
          ),
          [ClassFieldFormFields.AllowSync]: getValueIfAnyElementOnArray(
            availableGroups,
            formData.allow_sync
          ),
          [ClassFieldFormFields.AllowMembersSelection]: getValueIfAnyElementOnArray(
            availableGroups,
            formData.allow_members_selection
          ),
        },
        true
      );
    },
    []
  );

  const handleSubmitError = useCallback(
    async (
      error: unknown,
      formData: ClassFieldForm,
      setValues: (
        values: SetStateAction<ClassFieldForm>,
        shouldValidate?: boolean
      ) => void
    ) => {
      if (isNotFound(error) && mode === FormMode.Edit) {
        openNoExistsFieldModal();
      }

      if (isBadRequest(error) && formData.type === ObjectClassFieldTypes.User) {
        await handlePriorRemovedUsersOrGroupsError(error, formData, setValues);
      }
    },
    [handlePriorRemovedUsersOrGroupsError, mode, openNoExistsFieldModal]
  );

  const getSuccessMessage = useCallback(() => {
    return mode === FormMode.Create
      ? {
          title: intl.formatMessage({
            id: 'misc.success',
            defaultMessage: 'Success!',
          }),
          subtitle: intl.formatMessage({
            id: 'objectClasses.fields.fieldHasBeenCreated',
            defaultMessage: 'Field has been created.',
          }),
        }
      : undefined;
  }, [intl, mode]);

  const submitFormData = useCallback(
    async (
      url: string,
      formData: ClassFieldForm,
      { setErrors, resetForm }: FormikHelpers<ClassFieldForm>
    ) => {
      const initialData = omit(omit(initialValues, 'users'), 'user_groups');
      const responseData = await sendData({
        url,
        data: parseFormDataToApi(formData) as ClassFieldForm,
        fields: ClassFieldFormFields,
        setErrors: parseAndSetErrors(setErrors),
        initialData: initialData,
        successMessage: getSuccessMessage(),
      });

      if (!responseData) {
        return;
      }

      const { order, ...apiField } = responseData;

      dispatch(setSidebarData(panelId, {}));

      if (mode === FormMode.Create) {
        dispatch(addField({ ...apiField, order }));
        resetForm({});
        return;
      }

      dispatch(updateField(apiField));

      setInitialValues({
        ...(Object.fromEntries(
          Object.entries(responseData).map(([key, value]) => [
            key,
            value ?? undefined,
          ])
        ) as ClassFieldForm),
        users: responseData._meta?.users
          ? partitionUsersByStatus(responseData._meta?.users).activeUsers
          : undefined,
        user_groups: responseData._meta?.user_groups
          ? transformGroups(responseData._meta?.user_groups)
          : undefined,
      });
    },
    [
      dispatch,
      getSuccessMessage,
      initialValues,
      mode,
      panelId,
      parseAndSetErrors,
      parseFormDataToApi,
      sendData,
    ]
  );

  const onSubmit = useCallback(
    async (
      formData: ClassFieldForm,
      formikHelpers: FormikHelpers<ClassFieldForm>
    ) => {
      const url =
        mode === FormMode.Edit
          ? generatePath(OBJECT_CLASS_FIELD_DETAILS, {
              id: classId,
              fieldId: id,
            })
          : generatePath(OBJECT_CLASS_DETAILS_FIELDS, {
              id: classId,
            });

      try {
        await submitFormData(url, formData, formikHelpers);
      } catch (error) {
        await handleSubmitError(error, formData, formikHelpers.setValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, id, initialValues, mode, sendData]
  );

  const onConfirmNoExistsField = useCallback(() => {
    if (id) {
      dispatch(setObjectClassesFieldsSelectedRow(undefined));
      history.replace(history.location.pathname, {}); // field's ID is cleaned up from state

      dispatch(removeField(id));
      closeNoExistsFieldModal();
    }
  }, [closeNoExistsFieldModal, dispatch, history, id]);

  return {
    onSubmit,
    initialValues,
    setInitialValues,
    data,
    isLimitExceeded,
    isOpenNoExistsFieldModal,
    onConfirmNoExistsField,
    deletedUsers,
    ...rest,
  };
};
