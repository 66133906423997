import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useUserValidationMessages = () => {
  const intl = useIntl();

  const notEnoughOptionsToSelectTooltip = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.notEnoughOptionsToSelect',
        defaultMessage: 'Not enough options to select.',
      }),
    [intl]
  );

  const notEnoughOptionsToSelectError = useMemo(
    () =>
      intl.formatMessage({
        id: 'errors.notEnoughOptionsToSelectError',
        defaultMessage:
          'Not enough options to select. Please contact System Administrator.',
      }),
    [intl]
  );

  return {
    notEnoughOptionsToSelectTooltip,
    notEnoughOptionsToSelectError,
  };
};
