import React from 'react';
import { Form as AntForm } from 'antd';
import { FormProps } from 'antd/lib/form';
import { ExtendedFormItemProps } from './types';
import useFormStyles from './styles';
import { Form as FForm, FormItemProps as FFormItemProps } from 'formik-antd';

const { Item: AntFormItem } = AntForm;
const { Item: FFormItem } = FForm;

export const Form: React.FC<FormProps> = ({ ...rest }) => {
  return <AntForm {...rest} />;
};

export const FormikForm: React.FC<FormProps> = ({ ...rest }) => {
  return <FForm {...rest} />;
};

export const FormItem: React.FC<ExtendedFormItemProps> = ({
  dynamicSpacing,
  customSpacing,
  className,
  ...rest
}) => {
  const classes = useFormStyles({});

  return (
    <AntFormItem
      {...rest}
      className={`${classes.formItem} ${className}`}
      style={{
        marginBottom: dynamicSpacing
          ? customSpacing ?? 'var(--spacingAround)'
          : undefined,
      }}
    />
  );
};

export const FormikFormItem: React.FC<FFormItemProps> = ({
  className,
  ...rest
}) => {
  const classes = useFormStyles();

  return <FFormItem {...rest} className={`${classes.formItem} ${className}`} />;
};

export const useForm = AntForm.useForm;
