import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { ApiActorCell } from './components/ApiActorCell';
import { ClassFormEnabledCell } from './components/ClassFormEnabledCell';
import { MembershipCell } from './components/MembershipCell/MembershipCell';
import { ObjectClassFieldLabelCell } from './components/ObjectClassFieldLabelCell/ObjectClassFieldLabelCell';
import { ObjectClassNameCell } from './components/ObjectClassNameCell';
import { ObjectClassOwnersCell } from './components/ObjectClassOwnersCell';
import { ObjectClassRelationsCell } from './components/ObjectClassRelationsCell';
import { ObjectNameCell } from './components/ObjectNameCell';
import { ObjectRecordCell } from './components/ObjectRecordCell';
import { ObjectRecordGroupsCell } from './components/ObjectRecordGroupsCell';
import { PublisherCell } from './components/PublisherCell';
import { StateCell } from './components/StateCell/StateCell';
import { TaskDueDateCell } from './components/TaskDueDateCell';
import { TaskGroupsCell } from './components/TaskGroupsCell';
import { TaskStatusCell } from './components/TaskStatusCell';
import { TaskTemplateStatusCell } from './components/TaskTemplateStatusCell';
import { TemplateFileCell } from './components/TemplateFileCell';
import { UserCustomCell } from './components/UserCustomCell';
import { UserRolesCell } from './components/UserRolesCell';
import { UuidCell } from './components/UuidCell';
import { TableCellMapping } from './types/tableCellMapping';
import { CheckIconCell } from './components/CheckIconCell';
import { TemplateStatusCell } from './components/TemplateStatusCell';
import { CatalystTableCellType } from 'components/CatalystTable/types/catalystTableCellType';

// Creates a mapping of table and column combination to the destination cell component.
export const TABLE_CELL_COMPONENT_MAPPING: TableCellMapping[] = [
  {
    tables: [CatalystTableType.Members],
    columns: [CatalystTableCellType.Membership],
    component: MembershipCell,
  },
  {
    tables: [CatalystTableType.TaskTemplates],
    columns: [CatalystTableCellType.Status],
    component: TaskTemplateStatusCell,
  },
  {
    tables: [CatalystTableType.Tasks],
    columns: [CatalystTableCellType.Status],
    component: TaskStatusCell,
  },
  {
    tables: [CatalystTableType.Tasks],
    columns: [CatalystTableCellType.DueDate],
    component: TaskDueDateCell,
  },
  {
    tables: [CatalystTableType.ObjectClassesFields],
    columns: [CatalystTableCellType.Label],
    component: ObjectClassFieldLabelCell,
  },
  {
    tables: [
      CatalystTableType.ObjectClassesFields,
      CatalystTableType.SingleSignOn,
      CatalystTableType.CustomComponents,
      CatalystTableType.DocumentTemplates,
    ],
    columns: [
      CatalystTableCellType.Required,
      CatalystTableCellType.Unique,
      CatalystTableCellType.AutoCreateUse,
      CatalystTableCellType.Enabled,
      CatalystTableCellType.AddInEnabled,
      CatalystTableCellType.ManualDocumentGeneration,
    ],
    component: CheckIconCell,
  },
  {
    tables: [CatalystTableType.TaskTemplates, CatalystTableType.Sequences],
    columns: [CatalystTableCellType.TaskGroups],
    component: TaskGroupsCell,
  },
  {
    tables: [
      CatalystTableType.TaskGroups,
      CatalystTableType.Roles,
      CatalystTableType.EmailCollectors,
    ],
    columns: [CatalystTableCellType.ObjectRecordGroups],
    component: ObjectRecordGroupsCell,
  },
  {
    tables: [CatalystTableType.Sequences],
    columns: [CatalystTableCellType.State],
    component: StateCell,
  },
  {
    tables: [CatalystTableType.Sequences],
    columns: [CatalystTableCellType.APIActorsNames],
    component: ApiActorCell,
  },
  {
    tables: [CatalystTableType.ObjectClasses],
    columns: [CatalystTableCellType.Relations],
    component: ObjectClassRelationsCell,
  },
  {
    tables: [CatalystTableType.ObjectClasses],
    columns: [CatalystTableCellType.Owners],
    component: ObjectClassOwnersCell,
  },
  {
    tables: [CatalystTableType.Users],
    columns: [CatalystTableCellType.Roles],
    component: UserRolesCell,
  },
  {
    tables: [CatalystTableType.Members],
    columns: [CatalystTableCellType.Name],
    component: UserCustomCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.ObjectClass],
    component: ObjectClassNameCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.ObjectRecord],
    component: ObjectRecordCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.ObjectName],
    component: ObjectNameCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.Uuid],
    component: UuidCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.TemplateFileName],
    component: TemplateFileCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.GeneratedDocument],
    component: TemplateStatusCell,
  },
  {
    tables: [CatalystTableType.ClassForms],
    columns: [CatalystTableCellType.Enabled],
    component: ClassFormEnabledCell,
  },
  {
    tables: [],
    columns: [CatalystTableCellType.Publisher],
    component: PublisherCell,
  },
];
