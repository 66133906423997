import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import moment, { Moment } from 'moment';
import range from 'lodash/range';
import { DisabledOptionParams } from 'components/AutocompleteUsersSelect/types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { OBJECT_CLASS_FIELDS_AUTOCOMPLETE_NO_FILTER } from 'utils/endpoints';
import { SelectUserOption } from 'utils/types/selectInput.types';
import {
  DocumentSourceType,
  EventQueryPredicates,
  EventType,
} from '../../types';
import {
  EventDateFilterChange,
  EventDateFilterDisabledTime,
  EventDateFilterRanges,
  EventFiltersOptionsType,
  EventFiltersProps,
  EventFiltersType,
  FieldFilterAutocomplete,
} from './types';

export const useEventDateFilter = () => {
  const intl = useIntl();

  const ranges: EventDateFilterRanges = {
    [intl.formatMessage({
      id: 'forms.last3Hours',
      defaultMessage: 'Last 3 hours',
    })]: [moment().subtract(3, 'hours'), moment()],
    [intl.formatMessage({
      id: 'forms.last24Hours',
      defaultMessage: 'Last 24 hours',
    })]: [moment().subtract(24, 'hours'), moment()],
    [intl.formatMessage({
      id: 'forms.last3Days',
      defaultMessage: 'Last 3 days',
    })]: [moment().subtract(3, 'days'), moment()],
    [intl.formatMessage({
      id: 'forms.thisWeek',
      defaultMessage: 'This week',
    })]: [moment().startOf('isoWeek'), moment()],
    [intl.formatMessage({
      id: 'forms.thisMonth',
      defaultMessage: 'This month',
    })]: [moment().startOf('month'), moment()],
  };

  const disabledDate = (date: Moment) => !!date && date >= moment();

  const disabledTime: EventDateFilterDisabledTime = date => {
    return date?.date() === moment().date()
      ? {
          disabledHours: () => range(moment().hours() + 1, 60),
          disabledMinutes: () => range(moment().minute() + 1, 60),
        }
      : {};
  };

  const handleEventDate = useCallback(
    (
      callback: EventFiltersProps['setQueryParams']
    ): EventDateFilterChange => value => {
      const [start, end] = value || [];

      if (!start || !end) {
        callback(prev => ({ ...prev, event_datetime__range: undefined }));
        return;
      }

      callback(prev => ({
        ...prev,
        event_datetime__range: [
          start.set('s', 0),
          end.set('s', 59).set('ms', 999),
        ],
      }));
    },
    []
  );

  return { ranges, disabledDate, disabledTime, handleEventDate };
};

export const useEventUsersFilter = () => {
  const checkOptionIsSelected = useCallback(
    (query: EventQueryPredicates) => (value: DisabledOptionParams) => {
      const selected = (query.source_id__in || []).map(el => el.value);
      return selected.includes(value.id.toString());
    },
    []
  );

  const handleEventUserClear = useCallback(
    (callback: EventFiltersProps['setQueryParams']) => () => {
      callback(prev => ({ ...prev, source_id__in: undefined }));
    },
    []
  );

  const handleEventUser = useCallback(
    (
      callback: EventFiltersProps['setQueryParams'],
      query: EventQueryPredicates
    ) => (value: SelectUserOption | undefined) => {
      if (!value) {
        callback(prev => ({ ...prev, source_id__in: undefined }));
        return;
      }
      const user = {
        value: value?.id.toString() || '',
        label: value?.first_name + ' ' + value?.last_name || '',
      };
      if (query.source_id__in?.some(item => item.value === user.value)) return;

      callback(prev => ({
        ...prev,
        source_id__in: [user],
      }));
    },
    []
  );

  return { checkOptionIsSelected, handleEventUserClear, handleEventUser };
};

export const useEventTypeFilter = () => {
  const intl = useIntl();

  const eventTypeOptions = useMemo<
    {
      label: string;
      value: EventFiltersOptionsType;
    }[]
  >(
    () => [
      {
        label: intl.formatMessage({
          id: 'activityLog.allActivityType',
          defaultMessage: 'All activity types',
        }),
        value: EventFiltersType.All,
      },
      {
        label: intl.formatMessage({
          id: 'activityLog.created',
          defaultMessage: 'Record created',
        }),
        value: EventType.RecordCreated,
      },
      {
        label: intl.formatMessage({
          id: 'activityLog.fieldValuesChanged',
          defaultMessage: 'Field value updated',
        }),
        value: EventType.FieldValuesChanged,
      },
      {
        label: intl.formatMessage({
          id: 'activityLog.ownerInitialized',
          defaultMessage: 'Record owner initialised',
        }),
        value: EventType.OwnerInitialized,
      },
      {
        label: intl.formatMessage({
          id: 'activityLog.accessUpdate',
          defaultMessage: 'Record access updated',
        }),
        value: EventFiltersType.Update,
      },
      {
        label: intl.formatMessage({
          id: 'activityLog.documentGenerated',
          defaultMessage: 'Document generated',
        }),
        value: EventType.DocumentGenerated,
      },
    ],
    [intl]
  );

  const handleEventType = useCallback(
    (callback: EventFiltersProps['setQueryParams']) => (
      eventType: EventFiltersOptionsType
    ) => {
      if (eventType === EventFiltersType.Update) {
        callback(prev => ({
          ...prev,
          event_type__in: [
            EventType.AssigneesAdded,
            EventType.AssigneesRemoved,
            EventType.OwnersAdded,
            EventType.OwnersRemoved,
          ],
        }));
        return;
      }
      callback(prev => ({
        ...prev,
        field_id: undefined,
        event_type__in: eventType ? [eventType] : undefined,
      }));
    },
    []
  );

  return { eventTypeOptions, handleEventType };
};

export const useEventFieldsFilter = (query: EventQueryPredicates) => {
  const {
    selectedResource: { objectClassId = '' } = {},
  } = useSelectedResourceContext();

  const isFieldValueUpdateSelected = useMemo(
    () => query?.event_type__in?.includes(EventType.FieldValuesChanged),
    [query]
  );

  const autocompleteUrl = useMemo(
    () =>
      generatePath(OBJECT_CLASS_FIELDS_AUTOCOMPLETE_NO_FILTER, {
        id: objectClassId,
      }),
    [objectClassId]
  );

  const handleEventFields = useCallback(
    (callback: EventFiltersProps['setQueryParams']) => (
      field: FieldFilterAutocomplete | FieldFilterAutocomplete[]
    ) => {
      if (Array.isArray(field) || !('id' in field)) {
        callback(prev => ({ ...prev, field_id: undefined }));
        return;
      }

      callback(prev => ({
        ...prev,
        field_id: field,
      }));
    },
    []
  );

  return { handleEventFields, isFieldValueUpdateSelected, autocompleteUrl };
};

export const useEventDocumentGeneratedFilter = (
  query: EventQueryPredicates
) => {
  const intl = useIntl();
  const isDocumentGeneratedSelected = useMemo(
    () => query?.event_type__in?.includes(EventType.DocumentGenerated),
    [query]
  );

  const documentSourceOptions = useMemo(
    () => [
      {
        value: DocumentSourceType.All,
        label: intl.formatMessage({
          id: 'activityLog.allSources',
          defaultMessage: 'All sources',
        }),
      },
      {
        value: DocumentSourceType.User,
        label: intl.formatMessage({
          id: 'activityLog.userGenerated',
          defaultMessage: 'User generated',
        }),
      },
      {
        value: DocumentSourceType.Sequence,
        label: intl.formatMessage({
          id: 'activityLog.sequenceGenerated',
          defaultMessage: 'Sequence generated',
        }),
      },
    ],
    [intl]
  );

  const handleDocumentGeneratedSource = useCallback(
    (callback: EventFiltersProps['setQueryParams']) => (
      field: DocumentSourceType
    ) => {
      callback(prev => ({
        ...prev,
        source_type__in: field || undefined,
      }));
    },
    []
  );

  const handleDocumentGeneratedSourceClear = useCallback(
    (callback: EventFiltersProps['setQueryParams']) => () => {
      callback(prev => ({ ...prev, source_type__in: undefined }));
    },
    []
  );

  return {
    handleDocumentGeneratedSource,
    handleDocumentGeneratedSourceClear,
    documentSourceOptions,
    isDocumentGeneratedSelected,
  };
};
