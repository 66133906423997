import {
  SET_IS_ABOUT_MODAL_VISIBLE,
  SET_API_VERSION,
  SHOW_ERROR_DETAILS_MODAL,
  CLOSE_ERROR_DETAILS_MODAL,
} from 'store/constants/misc.consts';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiCall } from 'utils/api';
import { API_VERSION } from 'utils/endpoints';
import { RootState, RootAction } from 'store/reducers';
import { StatusCodes } from 'http-status-codes';
import {
  ApiVersionPayload,
  ApiHistoryType,
} from 'store/reducers/types/misc.types';

export interface SetApiVersion extends Action<typeof SET_API_VERSION> {
  payload: ApiVersionPayload;
}

export interface SetIsAboutModalVisible
  extends Action<typeof SET_IS_ABOUT_MODAL_VISIBLE> {
  payload: boolean;
}

export interface ShowErrorDetailsModal
  extends Action<typeof SHOW_ERROR_DETAILS_MODAL> {
  payload: unknown;
}

export type CloseErrorDetailsModal = Action<typeof CLOSE_ERROR_DETAILS_MODAL>;

export type MiscAction =
  | SetApiVersion
  | SetIsAboutModalVisible
  | ShowErrorDetailsModal
  | CloseErrorDetailsModal;

export const setApiVersion = (payload: ApiVersionPayload): SetApiVersion => ({
  type: SET_API_VERSION,
  payload,
});

export const setIsAboutModalVisible = (
  payload: boolean
): SetIsAboutModalVisible => ({
  type: SET_IS_ABOUT_MODAL_VISIBLE,
  payload,
});

export const showErrorDetailsModal = (
  payload: unknown
): ShowErrorDetailsModal => ({
  type: SHOW_ERROR_DETAILS_MODAL,
  payload,
});

export const closeErrorDetailsModal = (): CloseErrorDetailsModal => ({
  type: CLOSE_ERROR_DETAILS_MODAL,
});

export const fetchApiVersion = (): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  try {
    const {
      status,
      data: { version, version_history },
    } = await apiCall.get(API_VERSION);

    if (status === StatusCodes.OK) {
      dispatch(
        setApiVersion({
          version,
          historyVersion: version_history || ApiHistoryType.NA,
        })
      );
    }
  } catch {}
};
