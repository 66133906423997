import React from 'react';
import { InPlaceSingleSelectDropdownContentProps } from './InPlaceSingleSelectDropdownContent.types';
import clsx from 'clsx';
import useInPlaceEditSingleSelectStyles from '../styles';
import useMessageBoxStyles from 'components/InPlaceEditControls/components/MessageBox/styles';

export const InPlaceSingleSelectDropdownContent = ({
  items,
  errorMessage,
  isSaving,
}: InPlaceSingleSelectDropdownContentProps) => {
  const styles = useInPlaceEditSingleSelectStyles();
  const sharedStyles = useMessageBoxStyles({ isError: !!errorMessage });

  return (
    <>
      {items}
      {!isSaving && !!errorMessage && (
        <div className={clsx(sharedStyles.box, styles.dropdownError)}>
          {errorMessage}
        </div>
      )}
    </>
  );
};
