import React from 'react';
import { DateRangePickerInput } from 'components/Inputs/DatePicker';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { FilterDateRangeValueProps } from './FilterDateRangeValue.types';
import { getDateFormat, getShouldShowTime } from '../../utils';
import moment, { Moment } from 'moment';

export const FilterDateRangeValue = ({
  value,
  filterKey,
  inputType,
  className,
  testId,
  onChange,
}: FilterDateRangeValueProps) => {
  const { isExternal } = useFlexLayoutWindow(FlexLayoutWindows.FilterEditor);

  const showTime = getShouldShowTime(filterKey, inputType);
  const dateFormat = getDateFormat(showTime);

  const formatValue = (value: unknown): [Moment, Moment] | undefined => {
    if (!value || !Array.isArray(value)) {
      return undefined;
    }

    if (!value[0] || !value[1]) {
      return undefined;
    }

    return [moment(value[0]), moment(value[1])];
  };

  const handleChange = (value: [Moment | null, Moment | null] | null) => {
    onChange([
      value?.[0]?.format(dateFormat) ?? '',
      value?.[1]?.format(dateFormat) ?? '',
    ]);
  };

  return (
    <DateRangePickerInput
      className={className}
      value={formatValue(value)}
      isExternal={isExternal}
      showTime={showTime}
      withCustomPopupStyles
      data-testid={testId}
      onChange={handleChange}
    />
  );
};
