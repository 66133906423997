import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Predicate } from 'utils/types/predicates.types';

/**
 * Get all common predicates. Is declared as a hook so that it can support the dynamic language
 * translation of predicate labels.
 *
 * @returns Common filter predicates.
 */
export const useCommonPredicates = () => {
  const intl = useIntl();

  const commonPredicates: Record<string, Predicate> = useMemo(
    () => ({
      containsall: {
        label: intl.formatMessage({
          id: 'predicates.containsall',
          defaultMessage: 'Contains all',
        }),
        value: 'containsall',
        query: '__containsall={value}',
        args: ['value'],
        selectMultiple: true,
      },
      containssome: {
        label: intl.formatMessage({
          id: 'predicates.containssome',
          defaultMessage: 'Contains any',
        }),
        value: 'containssome',
        query: '__containssome={value}',
        args: ['value'],
        selectMultiple: true,
      },
      not_containssome: {
        label: intl.formatMessage({
          id: 'predicates.doesNotContainAnyOf',
          defaultMessage: 'does not contain',
        }),
        value: 'not_containssome',
        query: '__containssome!={value}',
        args: ['value'],
        selectMultiple: true,
      },
      isnull: {
        label: intl.formatMessage({
          id: 'predicates.unset',
          defaultMessage: 'is unset',
        }),
        value: 'isnull',
        query: '__isnull=true',
        args: [],
      },
      not_isnull: {
        label: intl.formatMessage({
          id: 'predicates.isAny',
          defaultMessage: 'is any',
        }),
        value: 'not_isnull',
        query: '__isnull=false',
        args: [],
      },
      isempty: {
        label: intl.formatMessage({
          id: 'predicates.unset',
          defaultMessage: 'is unset',
        }),
        value: 'isempty',
        query: '__isempty=true',
        args: [],
      },
      not_isempty: {
        label: intl.formatMessage({
          id: 'predicates.isAny',
          defaultMessage: 'is any',
        }),
        value: 'not_isempty',
        query: '__isempty=false',
        args: [],
      },
    }),
    [intl]
  );

  return { commonPredicates };
};
