import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { getDynamicPathParts } from 'utils/functions/getDynamicPathParts';
import routes from 'utils/routingPaths';
import { ObjectClassFieldUsageLocationState } from '../ObjectClassFieldViewUsage.types';
import { useObjectClassFieldUsageData } from './useObjectClassFieldUsageData';
import { UsageViewConfig } from 'components/ResourceUsagePanelView/types/usageViewConfig';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';
import { FieldRecordUsageView } from './components/FieldRecordUsageView';

export const useViewUsage = (className?: string) => {
  const intl = useIntl();
  const { state: { id } = {}, pathname } = useLocation<
    ObjectClassFieldUsageLocationState
  >();
  const objectClassId = getDynamicPathParts(
    routes.OBJECT_CLASSES_EDIT,
    pathname
  )?.id;

  const {
    fetchData: fetchUsage,
    loading,
    data: {
      num_of_records: numOfRecords = 0,
      num_of_records_with_values: numOfRecordsWithValues = 0,
      sequences = [],
      email_collectors: emailCollectors = [],
      truth_tables: truthTables = [],
      object_record_imports: objectRecordImports = [],
      dynamic_forms: dynamicForms = [],
      message_templates: messageTemplates = [],
      document_templates: documentTemplates = [],
      task_template_forms: taskTemplateForms = [],
    } = {},
  } = useObjectClassFieldUsageData(parseInt(objectClassId), id);

  const displayConfig = useMemo<UsageViewConfig[]>(() => {
    return [
      {
        title: intl.formatMessage({
          id: 'fieldUsage.objectRecords',
          defaultMessage: 'Object records',
        }),
        type: UsageViewType.Custom,
        shouldBeVisible: !!numOfRecordsWithValues,
        content: (
          <FieldRecordUsageView
            numOfAllRecords={numOfRecords}
            numOfRelatedRecords={numOfRecordsWithValues}
          />
        ),
      },
      {
        usage: sequences,
        title: intl.formatMessage({
          id: 'fieldUsage.sequences',
          defaultMessage: 'Sequences',
        }),
        type: UsageViewType.List,
      },
      {
        usage: truthTables,
        title: intl.formatMessage({
          id: 'fieldUsage.truthTables',
          defaultMessage: 'Truth tables',
        }),
        type: UsageViewType.List,
      },
      {
        usage: taskTemplateForms,
        title: intl.formatMessage({
          id: 'fieldUsage.taskTemplateForms',
          defaultMessage: 'Task template forms',
        }),
        type: UsageViewType.List,
      },
      {
        usage: objectRecordImports,
        title: intl.formatMessage({
          id: 'fieldUsage.objectRecordImports',
          defaultMessage: 'Object record imports',
        }),
        type: UsageViewType.List,
      },
      {
        usage: dynamicForms,
        title: intl.formatMessage({
          id: 'fieldUsage.dynamicForms',
          defaultMessage: 'Dynamic forms',
        }),
        type: UsageViewType.List,
      },
      {
        usage: emailCollectors,
        title: intl.formatMessage({
          id: 'fieldUsage.emailCollectors',
          defaultMessage: 'Email collectors',
        }),
        type: UsageViewType.List,
      },
      {
        usage: messageTemplates,
        title: intl.formatMessage({
          id: 'fieldUsage.messageTemplates',
          defaultMessage: 'Message templates',
        }),
        type: UsageViewType.List,
      },
      {
        usage: documentTemplates,
        title: intl.formatMessage({
          id: 'fieldUsage.documentTemplates',
          defaultMessage: 'Document templates',
        }),
        type: UsageViewType.List,
      },
    ];
  }, [
    intl,
    numOfRecords,
    numOfRecordsWithValues,
    sequences,
    truthTables,
    taskTemplateForms,
    objectRecordImports,
    dynamicForms,
    emailCollectors,
    messageTemplates,
    documentTemplates,
  ]);

  const boldValue = {
    b: (...chunks: string[]) => <b>{chunks} </b>,
    name: className,
  };

  const usageLabel = intl.formatMessage(
    {
      id: 'fieldUsage.areasExplanationClassScope',
      defaultMessage:
        'This field is used in the following system areas in the scope of <b>{name}</b>',
    },
    { ...boldValue }
  );

  const noUsageLabel = intl.formatMessage(
    {
      id: 'fieldUsage.noUsageClassScope',
      defaultMessage:
        'This field is not used anywhere in the system in the scope of <b>{name}</b>',
    },
    { ...boldValue }
  );

  return {
    displayConfig,
    fetchUsage,
    usageLabel,
    noUsageLabel,
    loading,
  };
};
