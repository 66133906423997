import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { getCurrentTableRestrictions } from 'store/selectors/generalSelectors';
import useFormatNumber from 'hooks/useFormatNumber';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';

export const useMaxItemsMessage = () => {
  const { total, preferencesId } = useTableContext();

  const { limit_items: limitItems } = useSelector(
    getCurrentTableRestrictions(preferencesId?.toString())
  ) ?? { limit_items: 0 };
  const formatNumber = useFormatNumber();
  const tableType = useSelector(getCurrentTable);
  const intl = useIntl();

  const messages: MappedObject<CatalystTableType | string> = useMemo(
    () => ({
      [CatalystTableType.TaskTemplates]: intl.formatMessage({
        id: 'taskTemplates.maximumNumberOfTaskTemplatesExceeded',
        defaultMessage: 'Maximum number of task templates has been reached.',
      }),
      [CatalystTableType.TaskGroups]: intl.formatMessage({
        id:
          'taskGroupTemplate.form.maximumNumberOfTaskGroupTemplatesHasBeenReached',
        defaultMessage:
          'Maximum number of task group templates has been reached',
      }),
      [CatalystTableType.Users]: intl.formatMessage({
        id: 'users.maximumNumberOfUsersHasBeenReached',
        defaultMessage:
          'The maximum number of all account types has been reached.',
      }),
      [CatalystTableType.Roles]: intl.formatMessage({
        id: 'roles.maximumNumberExceeded',
        defaultMessage: 'The maximum number of roles has been reached.',
      }),
      [CatalystTableType.SingleSignOn]: intl.formatMessage({
        id: 'singleSignOn.maximumNumberExceeded',
        defaultMessage: 'The maximum number of configurations has been reached',
      }),
      [CatalystTableType.CustomComponents]: intl.formatMessage({
        id: 'customComponents.maximumNumberExceeded',
        defaultMessage:
          'The maximum number of custom components has been reached',
      }),
      [CatalystTableType.AuthenticationObjects]: intl.formatMessage({
        id: 'authenticationObjects.maximumNumberExceeded',
        defaultMessage:
          'The maximum number of authentication objects has been reached',
      }),
      [CatalystTableType.ObjectClasses]: intl.formatMessage({
        id: 'objectClasses.maximumNumberExceeded',
        defaultMessage: 'The maximum number of object classes has been reached',
      }),
      [CatalystTableType.ObjectClassesFields]: intl.formatMessage({
        id: 'objectClassesFields.maximumNumberExceeded',
        defaultMessage: 'The maximum number of fields has been reached.',
      }),
      [CatalystTableType.Sequences]: intl.formatMessage({
        id: 'sequences.maximumNumberExceeded',
        defaultMessage: 'The maximum number of sequences has been reached',
      }),
      [CatalystTableType.ClassForms]: intl.formatMessage({
        id: 'classForms.maximumNumberExceeded',
        defaultMessage:
          'The maximum number of forms for this class has been reached.',
      }),
      [CatalystTableType.DocumentTemplates]: intl.formatMessage(
        {
          id: 'document.maximumNumberExceeded',
          defaultMessage: `The maximum number of document templates ({limitItems}) has been reached.`,
        },
        { limitItems: formatNumber(limitItems) }
      ),
    }),
    [intl, limitItems, formatNumber]
  );

  if (!tableType)
    return {
      limitItems: total + 1,
      maxItemsMessage: '',
    };

  return {
    limitItems,
    maxItemsMessage: messages[tableType] ? messages[tableType] : '',
  };
};
