import clsx from 'clsx';
import UserAvatar from 'components/UserAvatar';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUserDetails } from 'store/selectors/authSelectors';
import {
  TASK_OWNER_ASSIGN_BUTTON,
  TASK_OWNER_AVATAR,
  TASK_PROPERTY_OWNER,
} from 'utils/testIds';
import { NextTaskAction } from 'utils/types/api/tasks.types';
import useTaskOwnership from './hooks';
import useTaskOwnerStyles from './styles';
import { TaskOwnershipProps } from './types';

const TaskOwnership = ({
  owner,
  nextActions,
  taskId,
  stageId,
}: TaskOwnershipProps) => {
  const user = useSelector(getUserDetails);
  const styles = useTaskOwnerStyles();
  const {
    onClickTakeOwnership,
    onGiveUpOwnership,
    onClickAssignOwnership,
  } = useTaskOwnership({
    taskId,
    stageId,
    owner,
  });

  const {
    id,
    first_name: firstName,
    last_name: lastName,
    is_deleted: isDeleted,
  } = owner || {};

  const ownerReturn = (
    <b className={styles.avatarWrapper} data-testid={TASK_PROPERTY_OWNER}>
      {!!owner ? (
        <UserAvatar
          {...{ id, firstName, lastName, isDeleted }}
          size='small'
          data-testid={TASK_OWNER_AVATAR}
        />
      ) : (
        <FormattedMessage id='misc.none' defaultMessage='None' />
      )}
    </b>
  );

  if (nextActions.includes(NextTaskAction.ChangeOwnership) && !owner) {
    return (
      <>
        {ownerReturn}{' '}
        <button
          type='button'
          aria-pressed='false'
          className={styles.ownershipAction}
          onClick={onClickAssignOwnership}
          data-testid={TASK_OWNER_ASSIGN_BUTTON}
        >
          <FormattedMessage
            defaultMessage='Assign owner'
            id='tasks.AssignOwner'
          />
        </button>
      </>
    );
  }

  if (nextActions.includes(NextTaskAction.ChangeOwnership) && !!owner) {
    return (
      <>
        {ownerReturn}{' '}
        <button
          type='button'
          aria-pressed='false'
          className={styles.ownershipAction}
          onClick={onClickAssignOwnership}
          data-testid={TASK_OWNER_ASSIGN_BUTTON}
        >
          <FormattedMessage
            defaultMessage='Reassign ownership'
            id='tasks.ReassignOwnership'
          />
        </button>
      </>
    );
  }

  if (
    nextActions.includes(NextTaskAction.TakeOwnership) &&
    user?.id !== owner?.id //indicates that actual user hasn't taken ownership yet
  ) {
    return (
      <>
        {ownerReturn}
        <button
          type='button'
          aria-pressed='false'
          className={clsx([styles.ownershipAction, styles.takeOwnership])}
          onClick={onClickTakeOwnership}
        >
          <FormattedMessage
            id='tasks.TakeOwnership'
            defaultMessage='Take ownership'
          />
        </button>
      </>
    );
  }

  if (
    nextActions.includes(NextTaskAction.GiveUpOwnership) &&
    owner?.id === user?.id
  ) {
    return (
      <>
        {ownerReturn}
        <button
          type='button'
          className={styles.ownershipAction}
          onClick={onGiveUpOwnership}
          aria-pressed='false'
        >
          <FormattedMessage
            id='tasks.GiveUpOwnership'
            defaultMessage='Give up ownership'
          />
        </button>
      </>
    );
  }

  return <>{ownerReturn}</>;
};

export default TaskOwnership;
