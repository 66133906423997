import { CommaSeparatedString } from 'components/Text/CommaSeparatedString';
import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import React from 'react';
import { CatalystTableCellType } from 'components/CatalystTable/types/catalystTableCellType';

export const ObjectRecordGroupsCell = ({ row }: CatalystTableCellProps) => {
  const { object_classes } = row;

  return (
    <CommaSeparatedString
      values={object_classes}
      highlightedColumnKey={CatalystTableCellType.ObjectRecordGroups}
    />
  );
};
