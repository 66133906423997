import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseCatalystTableStylesProps = {
  ACTIONS_COLUMN_WIDTH?: number;
  isInfinite?: boolean;
  emptyTable?: {
    sidebarWidth: number;
    totalColumnsWidth: number;
    ignoreTotalColumnsWidth: boolean;
  };
};

export const useCatalystTableStyles = createUseStyles((theme: ThemeState) => {
  return {
    loaderWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 1,
    },
    moveableColum: {
      maxWidth: '30px',
    },
    filtersBar: {
      width: '100%',
      padding: '0 0 10px 15px',
    },
    maxTooltip: {
      paddingRight: 30,
      paddingBottom: 0,
      backgroundColor: '#202134',
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: 12,
      borderRadius: 2,
      '& .ant-tooltip-inner': {
        backgroundColor: 'inherit',
        padding: '7px 6px',
      },
    },
    actionsFilters: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: 300, // should be updated if new items will appear
    },
    tableContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    tableCell: ({
      ACTIONS_COLUMN_WIDTH,
    }: UseCatalystTableStylesProps = {}) => ({
      width: ACTIONS_COLUMN_WIDTH || 100,
    }),
    flexAlignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    emptyTableWrapper: {
      marginTop: 90,
      position: 'sticky',
      left: 0,
      display: 'block',
      width: '100%',
      maxWidth: ({ emptyTable }: UseCatalystTableStylesProps = {}) => {
        if (!emptyTable || emptyTable.ignoreTotalColumnsWidth) {
          return '100%';
        }

        if (
          window.document.body.offsetWidth - emptyTable.sidebarWidth <
          emptyTable.totalColumnsWidth
        ) {
          return '100%';
        }

        return emptyTable.totalColumnsWidth;
      },
    },
    infinityTable: { overflow: 'visible' },
    paginationWrapper: {
      color: theme['secondary-color'],
      marginLeft: 17,
      alignItems: 'flex-end',
    },
    spaceWrapper: {
      marginLeft: 10,
      marginRight: 10,
    },
    leftSpaceWrapper: {
      marginLeft: 10,
    },
    buttonsWrapper: {
      '& > .ant-space-item:not(:last-child)': {
        marginRight: '5px !important',
      },
    },
    headerSpace: {
      alignItems: 'flex-end',
      '& > .ant-space-item:not(:last-child)': {
        marginRight: '10px !important',
      },
    },
    input: { width: 65 },
    lastPageWrapper: { display: 'block', whiteSpace: 'nowrap' },
    perPageWrapper: { minWidth: 60, display: 'block', whiteSpace: 'nowrap' },
    resizer: {
      cursor: 'col-resize',
      padding: '0 2px',
      '&:after': {
        display: 'block',
        content: '""',
        width: 2,
        height: '100%',
        position: 'absolute',
        left: '50%',
        top: 0,
        background: '#ced5da',
        transform: 'translateX(-50%)',
      },
      '&.disabled': {
        cursor: 'default!important',
      },
    },
    sorterWrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10,
    },
    upperArrow: {
      marginBottom: 3,
    },
    active: {
      color: theme['primary-color'],
    },
    buttonNoPointerEvents: {
      cursor: 'not-allowed',
      '& button': {
        pointerEvents: 'none',
      },
    },
    quickFilterSelect: {
      width: 200,
      '& .ant-select-selector': {
        height: '35px !important',
      },
    },
  };
});
