import { useSaveChangesModal } from 'components/FormHeader/hooks';
import { useConfirmationModalContext } from 'contexts/ConfirmationModalContext';
import { useEffect, useRef, useState } from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { Location } from 'history';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';

/**
 * Handles the logic for cancelling the panel's actions.
 */
export const useAddAssigneesCancellation = (
  panelKey: FlexLayoutWindows,
  changePanelView: () => void
) => {
  const {
    setShouldBeDisplayed,
    setStoredModalFunctions,
  } = useConfirmationModalContext();
  const { availableComponents, globalMethods } = useFlexLayoutContext();
  const { navigateTo, handleLocationChange } = useSaveChangesModal();
  const { togglePanelsPreferences } = usePanels();

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  const isFromCancelButton = useRef(false);

  const { closeComponent: closePanel } = globalMethods;

  /**
   * Unsaved changes modal
   */
  const handleUnsavedChangesModalConfirmBtn = () => {
    setShowUnsavedChangesModal(false);

    if (isFromCancelButton.current) {
      changePanelView();

      return;
    }

    closePanel(panelKey);
    togglePanelsPreferences(panelKey, true);

    const onPanelClose = availableComponents[panelKey]?.onClose;

    if (!onPanelClose) {
      return;
    }

    onPanelClose();
  };

  const handleUnsavedChangesModalCancelBtn = () => {
    isFromCancelButton.current = false;
    setShowUnsavedChangesModal(false);
  };

  /**
   * Custom prompt
   */
  const handleCustomPromptCancelBtn = (location?: Location) => {
    if (!location) {
      return;
    }

    handleLocationChange(`${location.pathname}${location.search}`);
  };

  /**
   * Panel's cancel button
   */
  const handlePanelCancelBtn = (hasSelectedItems: boolean) => {
    if (hasSelectedItems) {
      isFromCancelButton.current = true;
      setShowUnsavedChangesModal(true);

      return;
    }

    changePanelView();
  };

  /**
   * Sets the tab panel close button to display a modal of unsaved changes when clicked when the
   * user has selected some items, i.e. form is dirty
   */
  const updatePanelCloseBtnBehavior = (hasSelectedItems: boolean) => {
    if (!hasSelectedItems) {
      setStoredModalFunctions(panelKey, undefined);

      return;
    }

    setStoredModalFunctions(panelKey, {
      callback: () => setShowUnsavedChangesModal(true),
    });
  };

  /**
   * Sets to "true" because panel actions are controlled by methods in the "storedModalFunctions"
   * variable
   */
  useEffect(() => {
    setShouldBeDisplayed(panelKey, true);

    return () => {
      setShouldBeDisplayed(panelKey, false);
    };
  }, [panelKey, setShouldBeDisplayed]);

  return {
    showUnsavedChangesModal,
    navigateTo,
    handlePanelCancelBtn,
    handleCustomPromptCancelBtn,
    handleUnsavedChangesModalConfirmBtn,
    handleUnsavedChangesModalCancelBtn,
    updatePanelCloseBtnBehavior,
  };
};
