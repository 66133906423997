import {
  CONTENT_ID,
  SIDE_PANEL,
  SCROLLABLE_CONTAINER,
  DRAWER_CONTENT_WRAPPER,
} from 'utils/elementsIds';

/**
 * List of known scrollable containers in ALX UI in which elements might be contained.
 */
export const ScrollableContainers = [
  CONTENT_ID,
  SIDE_PANEL,
  SCROLLABLE_CONTAINER,
  DRAWER_CONTENT_WRAPPER,
];
