import { useTaskOptionsContext } from 'components/SidePanels/TasksPanel/contexts/TaskOptionsContext';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { AccountTypeOptions } from 'pages/Users/enums';
import { useIntl } from 'react-intl';
import { DEFAULT_USER_ASSIGNEES_LIMIT } from 'utils/consts';

export const useTaskUserAssigneesLimit = () => {
  const intl = useIntl();

  const { taskAssignees } = useTasksPanelContext();
  const { taskUserAssigneeOptions } = useTaskOptionsContext();

  const isOneTCUserAssigned = taskAssignees.users.some(
    user => user.account_type === AccountTypeOptions.OneTimeCompletion
  );

  const limit = isOneTCUserAssigned
    ? taskUserAssigneeOptions?.restrictions.limit_assignees_one_time_completion
    : taskUserAssigneeOptions?.restrictions.limit_assignees_standard;

  const isUsersLimitReached = limit
    ? taskAssignees.users.length >= limit
    : taskAssignees.users.length >= DEFAULT_USER_ASSIGNEES_LIMIT;

  const disabledBtnTooltipText = isOneTCUserAssigned
    ? intl.formatMessage({
        id: 'misc.oneTCUserAssignedNoFurtherUserCanBeAssigned',
        defaultMessage:
          'A one-time completion user has been assigned, therefore no further user can be assigned.',
      })
    : intl.formatMessage({
        id: 'misc.maxUsersAssignedTooltip',
        defaultMessage:
          'The maximum number of assigned users has been reached. Unassign users to assign new ones.',
      });

  return {
    disabledBtnTooltipText,
    isOneTCUserAssigned,
    isUsersLimitReached,
    userAssigneesLimit: limit,
  };
};
