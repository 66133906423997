import React from 'react';
import { ListUsageViewProps } from './ListUsageView.types';
import { useListUsageViewStyles } from './ListUsageView.styles';

export const ListUsageView = ({ usage }: ListUsageViewProps) => {
  const styles = useListUsageViewStyles();

  return (
    <ul className={styles.list}>
      {usage.map(item => (
        <li key={item.id}>{item.name}</li>
      ))}
    </ul>
  );
};
