import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import useDateFormat from 'hooks/useDateFormat';
import moment from 'moment';
import React from 'react';

export const TaskDueDateCell = ({ row }: CatalystTableCellProps) => {
  const { dateFormat } = useDateFormat();

  const { due_date: dueDate } = row;
  const formattedDate = dueDate ? moment(dueDate).format(dateFormat) : null;

  return <>{formattedDate}</>;
};
