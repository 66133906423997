import { useFormikContext } from 'formik';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { useUniquenessValidation } from './hooks/useUniquenessValidation';
import { ClassFieldFormContentProps } from './types';
import { FieldSelectionOptions } from './components/FieldSelectionOptions/FieldSelectionOptions';
import { FieldValidation } from './components/FieldValidation/FieldValidation';
import { FieldProperties } from './components/FieldProperties';
import { FieldUserOptions } from './components/FieldUserOptions';
import { typesWithConstraints } from './consts/typesWithConstraints';
import { FieldFormCategories } from 'pages/ObjectClasses/components/ClassFieldFormWrapper/enums';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ClassFieldForm } from '../../types';

export const ClassFieldFormContent: React.FC<ClassFieldFormContentProps> = ({
  shouldBeDisabled = false,
  additionalControls,
  parsedTypes,
}) => {
  const { values } = useFormikContext<ClassFieldForm>();

  const { uniquenessAllowed } = useUniquenessValidation(
    values[ClassFieldFormFields.Type]
  );

  const filteredTypesWithConstraints = useMemo(() => {
    return typesWithConstraints.filter(
      type => type !== ObjectClassFieldTypes.User
    );
  }, []);

  return (
    <>
      <FormCategory
        collapsible={[
          ObjectClassFieldTypes.Enum,
          ObjectClassFieldTypes.Set,
          ObjectClassFieldTypes.String,
          ObjectClassFieldTypes.Int,
          ObjectClassFieldTypes.Float,
          ObjectClassFieldTypes.Document,
        ].includes(values[ClassFieldFormFields.Type])}
        noMarginTop
        collapsibleContent={
          <FieldProperties {...{ shouldBeDisabled, parsedTypes }}>
            {!!additionalControls &&
              additionalControls[FieldFormCategories.FieldProperties]}
          </FieldProperties>
        }
      >
        <FormattedMessage
          id='objectClasses.fields.fieldProperties'
          defaultMessage='Field properties'
        />
      </FormCategory>
      {[ObjectClassFieldTypes.Enum, ObjectClassFieldTypes.Set].includes(
        values[ClassFieldFormFields.Type]
      ) && (
        <FormCategory
          collapsible
          collapsibleContent={
            <FieldSelectionOptions {...{ shouldBeDisabled, parsedTypes }} />
          }
        >
          <FormattedMessage
            id='objectClasses.fields.selectionOptions'
            defaultMessage='Selection options'
          />
        </FormCategory>
      )}
      {values[ClassFieldFormFields.Type] === ObjectClassFieldTypes.User && (
        <FormCategory collapsible collapsibleContent={<FieldUserOptions />}>
          <FormattedMessage
            id='objectClasses.fields.selectionOptions'
            defaultMessage='Selection options'
          />
        </FormCategory>
      )}
      {(filteredTypesWithConstraints.includes(
        values[ClassFieldFormFields.Type]
      ) ||
        (values[ClassFieldFormFields.Type] === ObjectClassFieldTypes.User &&
          (!!values[ClassFieldFormFields?.Users]?.length ||
            !!values[ClassFieldFormFields?.Groups]?.length)) ||
        (additionalControls && uniquenessAllowed)) && (
        <FormCategory
          collapsible
          collapsibleContent={
            <FieldValidation {...{ shouldBeDisabled, parsedTypes }}>
              {!!additionalControls &&
                additionalControls[FieldFormCategories.Validation]}
            </FieldValidation>
          }
        >
          <FormattedMessage
            id='objectClasses.fields.validation'
            defaultMessage='Validation'
          />
        </FormCategory>
      )}
    </>
  );
};
