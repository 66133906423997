import { isNil } from 'lodash';
import { ObjectRecordDetailsMeta } from 'utils/types/api/objectRecords.types';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import { UsersAndGroupsFormValue } from '../widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { convertIdsToUserAndGroupOptions } from './convertIdsToUserAndGroupOptions';

/**
 * Picks the user and groups data from the form value id values.
 */
export const convertUsersAndGroupsFormValueToUserAndGroupOptions = (
  formValue: UsersAndGroupsFormValue | undefined | null,
  options: SelectUserAndGroupOption,
  objectRecordMetadata: ObjectRecordDetailsMeta | undefined
) => {
  if (isNil(formValue)) {
    return {
      users: [],
      groups: [],
    };
  }

  return convertIdsToUserAndGroupOptions(
    formValue.users,
    formValue.user_groups,
    options,
    objectRecordMetadata
  );
};
