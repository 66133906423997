import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';

export const typesWithConstraints = [
  ObjectClassFieldTypes.String,
  ObjectClassFieldTypes.Int,
  ObjectClassFieldTypes.Float,
  ObjectClassFieldTypes.Set,
  ObjectClassFieldTypes.Document,
  ObjectClassFieldTypes.User,
];
