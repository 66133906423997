import Tooltip from 'components/lib/Tooltip';
import { UserGroupTypes } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/enums';
import { TabPane, Tabs } from 'components/lib/Tabs';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { GroupList } from './components/GroupList';
import { UserList } from './components/UserList';
import { useTabListStyles } from './TabbedUsersAndGroupsList.styles';
import { TabbedUsersAndGroupsListProps } from './types';

export const TabbedUsersAndGroupsList = (
  props: TabbedUsersAndGroupsListProps
) => {
  const styles = useTabListStyles();
  const intl = useIntl();

  const [currentTab, setCurrentTab] = useState<string>(UserGroupTypes.USERS);
  const {
    groups,
    maxSelectedGroups,
    users,
    maxSelectedUsers,
    disableMaxedOutTabs,
    clearSearchField,
    testId,
    usersAutocompleteUrl,
    groupsAutocompleteUrl,
  } = props;
  const usersTabDisabled =
    !!disableMaxedOutTabs &&
    !!maxSelectedUsers &&
    users.length >= maxSelectedUsers;
  const groupsTabDisabled =
    !!disableMaxedOutTabs &&
    !!maxSelectedGroups &&
    groups.length >= maxSelectedGroups;

  const maxOptionsAddedText = intl.formatMessage({
    id: 'misc.maxOptionsAdded',
    defaultMessage: 'Maximum options have been added',
  });

  useEffect(() => {
    if (!groupsTabDisabled && !usersTabDisabled) return;
    if (groupsTabDisabled && usersTabDisabled) return;
    if (groupsTabDisabled) {
      setCurrentTab(UserGroupTypes.USERS);
      return;
    }
    if (usersTabDisabled) setCurrentTab(UserGroupTypes.GROUPS);
  }, [groupsTabDisabled, usersTabDisabled]);

  return (
    <div data-testid={testId}>
      <Tabs
        activeKey={currentTab}
        onTabClick={tab => {
          if (tab === currentTab) {
            return;
          }

          setCurrentTab(tab);
          clearSearchField();
        }}
        destroyInactiveTabPane
        className={styles.tabs}
      >
        <TabPane
          key={UserGroupTypes.USERS}
          tab={
            <Tooltip
              shouldBeRendered={usersTabDisabled}
              title={maxOptionsAddedText}
            >
              <FormattedMessage id='misc.users' defaultMessage='Users' />
            </Tooltip>
          }
          disabled={usersTabDisabled}
        >
          <UserList autocompleteUrl={usersAutocompleteUrl} {...props} />
        </TabPane>
        <TabPane
          key={UserGroupTypes.GROUPS}
          tab={
            <Tooltip
              shouldBeRendered={groupsTabDisabled}
              title={maxOptionsAddedText}
            >
              <FormattedMessage
                id='misc.userGroups'
                defaultMessage='User groups'
              />
            </Tooltip>
          }
          disabled={groupsTabDisabled}
        >
          <GroupList autocompleteUrl={groupsAutocompleteUrl} {...props} />
        </TabPane>
      </Tabs>
    </div>
  );
};
