import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useCancelToken } from 'hooks/useCancelToken';
import { useEffect, useRef, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';

export const useCheckIfTaskTypeHasTemplates = (
  autocompleteUrlBase: string | undefined
) => {
  const {
    selectedResource: { objectClassId } = {},
  } = useSelectedResourceContext();

  const hasFetched = useRef(false);
  const [hasTemplates, setHasTemplates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const autocompleteUrl = autocompleteUrlBase?.replace(
    '{object_id}',
    objectClassId ?? '0'
  );

  useEffect(() => {
    const fetch = async () => {
      if (isLoading) {
        return;
      }

      if (!autocompleteUrl) {
        setHasTemplates(false);
        return;
      }

      try {
        setIsLoading(true);
        cancelOngoingRequests();

        const cancelToken = createCancelToken();

        const { data } = await apiCall.get(
          generatePath(autocompleteUrl, {
            limit: -1,
          }),
          { cancelToken }
        );

        setHasTemplates(!!data.filtered_count);
      } catch {
        setHasTemplates(false);
      } finally {
        hasFetched.current = true;
        setIsLoading(false);
      }
    };

    if (hasFetched.current) {
      return;
    }

    fetch();
  }, [autocompleteUrl, isLoading, cancelOngoingRequests, createCancelToken]);

  return { hasTemplates, isLoading };
};
