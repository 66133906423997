import { DEFAULT_COLUMNS_WIDTHS } from 'components/CatalystTable/CatalystTable.consts';
import { SPECIFIC_COLUMN_DEFAULT_WIDTHS } from '../useCurrentTable.consts';

export const getColumnSpecificDefaultWidth = (
  columnAlias: string,
  defaultColumnWidthsOverrides?: MappedObject<number>
) => {
  return (
    SPECIFIC_COLUMN_DEFAULT_WIDTHS[columnAlias] ||
    defaultColumnWidthsOverrides?.[columnAlias] ||
    DEFAULT_COLUMNS_WIDTHS.width
  );
};
