import {
  SelectUserAndGroupOption,
  SelectUserAndGroupOptionMap,
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';

export const EMPTY_OPTIONS: SelectUserAndGroupOption = {
  users: [],
  groups: [],
};

export const EMPTY_SELECTION: SelectUserAndGroupOptionMap = {
  users: new Map<number, SelectUserOption>(),
  groups: new Map<number, SelectUserGroupOption>(),
};
