import { Logger, LogLevel } from 'utils/logger';
import { AllowedFilterValue, FilterValue } from 'store/actions/filtersActions';

/**
 * Tries to correct available values in the filter by removing a value that is provided as wrong.
 * @param wrongValue Value that should be removed.
 * @param filter Filter to be corrected.
 */
export const correctFilterAvailableValues = (
  wrongValue: string,
  filter: FilterValue
) => {
  Logger.log(
    `Correcting filter available values.`,
    LogLevel.Verbose,
    filter.values
  );

  // single select field
  if (filter.type === 'enum') {
    filter.values = (filter.values as string[]).filter(
      value => value !== wrongValue
    );
  }

  // multi select field.
  if (filter.type === 'set') {
    filter.values = (filter.values as AllowedFilterValue[]).filter(
      valueEntry => valueEntry.value !== wrongValue
    );
  }

  Logger.log(
    `Filter available values after correction.`,
    LogLevel.Verbose,
    filter.values
  );
};
