import axios from 'axios';
import { AvatarItem } from 'components/lib/Avatar/types';
import { useState } from 'react';
import { apiCall } from 'utils/api';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { isObjectNotExistsError } from 'utils/functions/isObjectNotExistsError';
import { showSomeUsersHaveBeenDeletedToast } from './utils/showSomeUsersHaveBeenDeletedToast';
import { showUsersSuccessfulyAssignedToast } from './utils/showUsersSuccessfulyAssignedToast';
import { showUserAssigneesLimitExceededToast } from './utils/showUserAssigneesLimitExceededToast';
import { isLimitExceededError } from 'utils/functions/isLimitExceededError';

/**
 * Handles the logic for adding users to a given resource.
 */
export const useAddUserAssignees = (
  selectedUsers: AvatarItem[],
  postEndpoint: string,
  resourceName: string,
  usersLimit: number | undefined,
  changePanelView: () => void,
  refetchUserAssignees: () => Promise<void>
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const selectedUserIds = selectedUsers.map(({ id }) => {
        return id;
      });

      await apiCall.post<unknown[]>(postEndpoint, selectedUserIds);

      await refetchUserAssignees();

      changePanelView();
      showUsersSuccessfulyAssignedToast(resourceName, selectedUserIds.length);
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      // Checks whether some users have been deleted while adding assignees.
      if (isObjectNotExistsError(error)) {
        changePanelView();
        showSomeUsersHaveBeenDeletedToast(resourceName);
        return;
      }

      // Checks whether user assignees limit has been exceeded.
      if (isLimitExceededError(error)) {
        await refetchUserAssignees();

        changePanelView();
        showUserAssigneesLimitExceededToast(usersLimit);
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, handleSubmit };
};
