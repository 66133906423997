import {
  ExtendedJsonSchema,
  SchemaComponentProperty,
} from 'components/FormPreview/types';
import DEFAULT_FORM_INPUTS from '../formBuilder/defaults/defaultFormInputs';
import { FormBuilderField } from '../formBuilder/FormBuilderContext/types';
import { getComponentTypeFromFieldType } from '../formBuilder/InputsContainer/utils';
import {
  DynamicExtendedSchema,
  DynamicSection,
  InitialComponentProps,
} from '../formBuilder/types';
import { InputTypes } from '../formBuilder/FormBuilder/enums';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { propertiesIds } from '../formBuilder/InputsContainer/consts';
import { FIELD_PREFIX } from 'utils/consts';
import get from 'lodash/get';

export interface DynamicFieldInfo {
  alias: string;
  id: number;
}

// map api structure of field to jsonSchema property
// "dataOptions" are used in defined field
// "baseProps" are used in section the field is added to
export const mapFieldPropsToComponentProps = ({
  alias,
  label,
  id,
  extras,
  type,
}: FormBuilderField): InitialComponentProps => {
  const filteredExtras = Object.fromEntries(
    Object.entries(extras).filter(([, value]) => value != null)
  );
  const extraProps =
    extras === ''
      ? {}
      : {
          default: filteredExtras.default_value,
          maximum: filteredExtras.max_value ?? extras.max_values ?? undefined,
          minimum: filteredExtras.min_value ?? extras.min_values ?? undefined,
          minItems: filteredExtras.min_values,
          maxItems: filteredExtras.max_num_of_files,
          maxLength: filteredExtras.max_length,
          minUsers: filteredExtras.min_users_values,
          maxUsers: filteredExtras.max_users_values,
          minGroups: filteredExtras.min_groups_values,
          maxGroups: filteredExtras.max_groups_values,
          allowGroupSync: filteredExtras.allow_sync,
          allowGroupMemberSelection: filteredExtras.allow_members_selection,
          ...(type === 'enum' ? { enum: filteredExtras.options } : {}),
          ...(type === 'set'
            ? {
                items: { enum: filteredExtras.options },
                uniqueItems: true,
                multiSelectNaturalNumbers: true,
              }
            : {}),
          ...(type === 'document'
            ? {
                items: { type: 'string', format: 'data-url' },
                type: 'array',
              }
            : {}),
          databaseType: type,
        };

  return {
    baseProps: {
      name: alias,
    },
    dataOptions: {
      fieldId: id,
      title: label,
      isProperty: propertiesIds.includes(alias),
      ...(type === ObjectClassFieldTypes.Date
        ? { format: InputTypes.Date }
        : {}),
      ...(type === ObjectClassFieldTypes.Datetime
        ? { format: InputTypes.DateTime }
        : {}),
      ...(type === ObjectClassFieldTypes.Time
        ? { format: InputTypes.Time }
        : {}),
      ...(type === ObjectClassFieldTypes.Url ? { format: InputTypes.URL } : {}),
      ...(type === ObjectClassFieldTypes.Json
        ? { format: InputTypes.JSON }
        : {}),
      ...(type === 'int' ? { multipleOf: 1 } : {}),
      ...extraProps,
    },
  };
};

// convert api fields to jsonSchema properties and add all default
// properties based on field type
export const convertFormBuilderFieldToSchemaProperty = (
  field: FormBuilderField
): SchemaComponentProperty => {
  const componentType = getComponentTypeFromFieldType(field.type);
  const defaultComponentProps = DEFAULT_FORM_INPUTS[componentType];

  if (!defaultComponentProps)
    throw new Error(`Component for type ${field.type} not found`);

  return {
    type: defaultComponentProps?.type,
    ...defaultComponentProps?.defaultDataSchema,
    ...mapFieldPropsToComponentProps(field)?.dataOptions,
  };
};

export const extractDynamicFieldsFromDynamicSchema = (
  dynamicSchema: DynamicExtendedSchema
): DynamicFieldInfo[] => {
  const sectionsDictionary = dynamicSchema?.properties;

  if (sectionsDictionary === undefined) return [];

  return Object.values(sectionsDictionary).flatMap(dynamicSection =>
    Object.entries(dynamicSection.properties || {}).reduce<DynamicFieldInfo[]>(
      (allFieldIds, [componentAlias, componentProperties]) =>
        typeof componentProperties === 'number'
          ? [...allFieldIds, { alias: componentAlias, id: componentProperties }]
          : allFieldIds,
      []
    )
  );
};

export const schemaHasDynamicFieldIds = (
  schema: ExtendedJsonSchema
): boolean => {
  if (schema?.properties === undefined) return false;
  const schemaSectionsDictionary = schema.properties;
  return Object.values(schemaSectionsDictionary).some(section =>
    Object.values(section.properties ?? {}).some(
      component => component?.fieldId !== undefined
    )
  );
};

// replace all fields that have fieldId property (thus are dynamic)
// with field ids and remove them from their section "required" property
// returns DynamicExtendedSchema & array of dynamic fields ids used in it
export const convertSchemaToDynamicSchema = (
  schema: ExtendedJsonSchema
): { dynamicSchema: DynamicExtendedSchema; dynamicFieldsIds: number[] } => {
  const dynamicFieldsIds: number[] = [];

  if (schema?.properties === undefined)
    return { dynamicSchema: schema as DynamicExtendedSchema, dynamicFieldsIds };

  const schemaSectionsDictionary = schema.properties;

  const dynamicSchemaSections = Object.fromEntries(
    Object.entries(schemaSectionsDictionary).map(([sectionName, section]) => {
      const idSectionProperties = Object.fromEntries(
        Object.entries(section.properties || {}).map(
          ([componentName, component]) => {
            if (component?.fieldId !== undefined)
              dynamicFieldsIds.push(component.fieldId);

            return [
              componentName,
              component?.fieldId !== undefined ? component.fieldId : component,
            ];
          }
        )
      );

      const sectionRequiredFields = section.required?.map(componentName => {
        const component = idSectionProperties[componentName];

        if (typeof component !== 'number') return componentName;

        return component;
      });

      const sectionReadOnlyFields = section.read_only?.map(componentName => {
        const component = idSectionProperties[componentName];

        if (typeof component !== 'number') return componentName;

        return component;
      });

      return [
        sectionName,
        {
          ...section,
          properties: idSectionProperties,
          required: sectionRequiredFields,
          read_only: sectionReadOnlyFields,
        },
      ];
    })
  );

  return {
    dynamicSchema: { ...schema, properties: dynamicSchemaSections },
    dynamicFieldsIds,
  };
};

const createPropDictionary = (
  formFields: FormBuilderField[],
  property: 'id' | 'alias'
) => {
  return formFields.reduce<MappedObject<FormBuilderField, string | number>>(
    (dictionary, formBuilderField) => ({
      ...dictionary,
      [formBuilderField[property]]: formBuilderField,
    }),
    {}
  );
};

const convertToSchemaSections = (
  schemaSectionsDictionary: {
    [key: string]: DynamicSection;
  },
  formFieldsDictionary: MappedObject<FormBuilderField, string | number>
) =>
  Object.fromEntries(
    Object.entries(schemaSectionsDictionary).map(([sectionName, section]) => {
      const idSectionProperties = Object.fromEntries(
        Object.entries(section.properties || {})
          .filter(([, component]) => {
            if (typeof component !== 'number') return true;

            return !!formFieldsDictionary[component];
          })
          .map(([componentName, component]) => {
            if (typeof component !== 'number')
              return [componentName, component];

            const formField = formFieldsDictionary[component];

            return [
              componentName,
              convertFormBuilderFieldToSchemaProperty(formField),
            ];
          })
      );

      const sectionRequiredFields = section.required
        ?.filter(requiredElement => {
          if (typeof requiredElement !== 'number') return true;

          if (!formFieldsDictionary?.[requiredElement]) return false;

          return true;
        })
        .map(requiredElement => {
          if (typeof requiredElement !== 'number') return requiredElement;

          return formFieldsDictionary[requiredElement].alias;
        });

      const sectionReadOnlyFields = section.read_only
        ?.filter(readOnlyElement => {
          if (typeof readOnlyElement !== 'number') return true;

          if (!formFieldsDictionary?.[readOnlyElement]) return false;

          return true;
        })
        .map(readOnlyElement => {
          if (typeof readOnlyElement !== 'number') return readOnlyElement;

          return formFieldsDictionary[readOnlyElement].alias;
        });

      return [
        sectionName,
        {
          ...section,
          properties: idSectionProperties,
          required: sectionRequiredFields,
          read_only: sectionReadOnlyFields,
        },
      ];
    })
  );

// replace all fieldIds in schema with actual fields and add required
// fields to their section "required" property
export const convertDynamicSchemaToSchema = (
  dynamicSchema: DynamicExtendedSchema,
  formFields: FormBuilderField[]
): ExtendedJsonSchema => {
  if (dynamicSchema?.properties === undefined)
    return dynamicSchema as ExtendedJsonSchema;

  const schemaSectionsDictionary = dynamicSchema.properties;
  const schemaDeps = dynamicSchema.deps;
  const formFieldsDictionary = createPropDictionary(
    addFieldPrefixToFormBuilderFields(formFields),
    'id'
  );

  const schemaSections = convertToSchemaSections(
    schemaSectionsDictionary,
    formFieldsDictionary
  );

  const deps = schemaDeps
    ? Object.fromEntries(
        Object.entries(schemaDeps).map(([sectionName, sectionDeps]) => {
          const filteredDeps = sectionDeps.filter(dep => {
            if (
              dep &&
              Object.keys(dep).length !== 0 &&
              dep?.path?.length === 2
            ) {
              const [sectionPathName, fieldPathName] = dep.path;

              if (!sectionPathName || !fieldPathName) return false;

              return schemaSections[sectionPathName].properties[fieldPathName];
            } else return false;
          });

          return [sectionName, filteredDeps];
        })
      )
    : schemaDeps;

  return {
    ...dynamicSchema,
    deps,
    properties: schemaSections,
  };
};

export const addFieldPrefixToFormBuilderFields = (fields: FormBuilderField[]) =>
  fields.map(field => ({
    ...field,
    alias: propertiesIds?.includes(field.alias)
      ? field.alias
      : `${FIELD_PREFIX}${field.alias}`,
  }));

export const extractCustomComponentIdsFromSchema = (
  schema?: DynamicExtendedSchema
) => {
  const sections = Object.values(schema?.properties ?? {});
  const componentIds: number[] = [];

  sections.forEach(section => {
    const fields = Object.values(section.properties ?? {});

    fields
      .filter(
        e =>
          typeof e !== 'number' &&
          (e.type as InputTypes) === InputTypes.ExternalComponent
      )
      .forEach(external => {
        const componentId: string = get(
          external,
          'passThrough.externalComponentParams.componentId',
          ''
        );
        if (componentId) componentIds.push(Number(componentId));
      });
  });

  return componentIds;
};
