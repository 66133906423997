import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorComponent from 'components/ErrorComponent';
import { CatalystTable } from 'components/CatalystTable';
import { useSelectedRow } from 'components/ActionMenu/ActionMenuList/hooks';
import {
  getDocumentTemplatesPanelSelectedRow,
  selectDocumentTemplatesPanel,
} from 'store/selectors/documentTemplatesPanelSelectors';
import { setDocumentTemplatesPanelSelectedRow } from 'store/actions/documentTemplatesPanelActions';
import { DOCUMENTS_TABLE } from 'utils/elementsIds';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { DocumentTemplatePanel } from 'utils/types/api/documentTemplate.types';
import {
  DocumentTemplateFields,
  DocumentTemplatesPanelProps,
  RowActions,
} from '../types';
import useDocumentTemplatesPanel from '../hooks';
import ActionMenuItems from './ActionMenuItems';
import SaveDocumentModal from './SaveDocumentModal';
import { apiCall } from 'utils/api';
import { GetResponse } from 'utils/types';
import { generatePath } from 'react-router-dom';
import { DOCUMENT_TEMPLATE_PANEL_FIELDS } from 'utils/endpoints';
import { ActionCellProps } from 'components/CatalystTable/types/actionCellProps';
import { CatalystTableCellType } from 'components/CatalystTable/types/catalystTableCellType';

const PREVENT_FROM_HIDE_COLUMNS = ['name', 'generated_document'];

const DocumentTemplatesPanelTable = ({
  recordId,
  permissions,
}: DocumentTemplatesPanelProps) => {
  const {
    total,
    error,
    getColumnsConfiguration,
    fetchDocumentTemplatesPanel,
    downloadDocumentTemplate,
    viewDocumentHistory,
    selectedDocumentId,
    closeModal,
    saveDocumentTemplate,
  } = useDocumentTemplatesPanel(recordId);
  const [hasDocumentFields, setHasDocumentFields] = useState(false);

  useEffect(() => {
    apiCall
      .get<GetResponse<DocumentTemplateFields>>(
        generatePath(DOCUMENT_TEMPLATE_PANEL_FIELDS, { recordId })
      )
      .then(({ data }) => setHasDocumentFields((data?.total_count ?? 0) > 0));
  }, [recordId]);

  const { selectedAction, onActionMenuOptionClick, ...rest } = useSelectedRow<
    RowActions,
    ActionCellProps
  >({
    [RowActions.Download]: downloadDocumentTemplate,
    [RowActions.Save]: saveDocumentTemplate,
    [RowActions.History]: viewDocumentHistory,
  });

  if (error) return <ErrorComponent error={error.status} />;

  return (
    <div id={DOCUMENTS_TABLE}>
      <SaveDocumentModal {...{ selectedDocumentId, recordId, closeModal }} />
      <FormattedMessage
        id='nestedObjectRecords.results'
        defaultMessage='{total, plural, =0 {# results} =1 {# result} other {# results}}'
        values={{ total }}
      />
      <CatalystTable<DocumentTemplatePanel>
        dataSelector={selectDocumentTemplatesPanel}
        getSelectedRow={getDocumentTemplatesPanelSelectedRow}
        setSelectedRow={setDocumentTemplatesPanelSelectedRow}
        getColumnsConfiguration={getColumnsConfiguration}
        fetchMethod={fetchDocumentTemplatesPanel}
        preventFromHideColumns={PREVENT_FROM_HIDE_COLUMNS}
        columnLabelKey={CatalystTableType.DocumentTemplatesPanel}
        customTableName={CatalystTableType.DocumentTemplatesPanel}
        customTableId={DOCUMENTS_TABLE}
        customCells={[CatalystTableCellType.GeneratedDocument]}
        withColumnConfiguration={false}
        withPagination={false}
        isPaginationUsingURL={false}
        defaultSortColumn='-id'
        {...rest}
      />
      {selectedAction?.actionId && (
        <ActionMenuItems
          {...{
            onActionMenuOptionClick,
            ...selectedAction,
            tableId: DOCUMENTS_TABLE,
            recordPermissions: permissions,
            hasDocumentFields,
          }}
        />
      )}
    </div>
  );
};

export default DocumentTemplatesPanelTable;
