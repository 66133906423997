import { FilterValue } from 'store/actions/filtersActions';
import { isUsersAndGroupsFilter } from './isUsersAndGroupsFilter/isUsersAndGroupsFilter';

/**
 * Parses provided filter params to base query. May contain placeholders for query arguments that
 * should later be replaced with corresponding filter values.
 *
 * @param filter - Filter information.
 * @param predicateQuery - Selected filter predicate query e.g. "__istartswith={value}".
 *
 * @returns Base query for the provided filter.
 */
export const getFilterQueryBase = (
  filter: FilterValue,
  predicateQuery: string
) => {
  const { column: filterKey } = filter;

  if (isUsersAndGroupsFilter(filter)) {
    return `${filterKey}__{prefix}${predicateQuery}`;
  }

  return `${filterKey}${predicateQuery}`;
};
