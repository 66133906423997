export enum CatalystTableType {
  TaskTemplates = 'taskTemplates',
  Sequences = 'sequences',
  ObjectRecords = 'objectRecords',
  TaskGroups = 'taskGroups',
  ObjectClasses = 'objectClasses',
  ObjectClassesFields = 'objectClassesFields',
  Users = 'users',
  UserGroups = 'userGroups',
  Roles = 'roles',
  Forms = 'forms',
  MessageTemplates = 'messageTemplates',
  EmailCollectors = 'emailCollectors',
  SingleSignOn = 'singleSignOn',
  AuthenticationObjects = 'authenticationObjects',
  Members = 'members',
  Tasks = 'tasks',
  ClassForms = 'classForms',
  DocumentTemplates = 'documentTemplates',
  DocumentTemplatesPanel = 'documentTemplatesPanel',
  NestedTable = 'nestedObjectRecords',
  CustomComponents = 'customComponents',
}
