import { getBodyRowHeight } from 'pages/MyAccount/components/MyAccountSections/components/PreferencesTab/components/RowSettings/components/styles';
import { getCheckboxStyles } from '../Table/utils/getCheckboxStyles';
import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseTableRowStylesProps = {
  density?: TableDensity;
};

export const useTableRowStyles = createUseStyles((theme: ThemeState) => {
  return {
    tr: {
      border: `1px solid transparent`,
      cursor: 'pointer',
      backgroundColor: theme['table-row-color'],
      '&:focus': {
        outline: 'none',
        border: `1px dotted ${theme['table-row-selected']}`,
      },
      '&:hover': {
        border: `1px solid ${theme['table-row-selected']}`,
      },
      width: 'var(--table-row-width) !important',
      height: (props: UseTableRowStylesProps = {}) =>
        `${getBodyRowHeight(props?.density)}px`,
      lineHeight: (props: UseTableRowStylesProps = {}) =>
        `${getBodyRowHeight(props?.density)}px`,
      '& .ant-spin-container': {
        lineHeight: '25px',
      },
    },
    fullRowWidth: {
      minWidth: '100% !important',
    },
    alternateRow: {
      backgroundColor: theme['table-row-color-alternate'],
    },
    tbodyTd: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
    },
    moveableIcon: {
      marginRight: '3px',
    },
    moveableCell: {
      padding: '0 10px',
      fontSize: 13,
      height: '100%',
    },
    tableCell: {
      padding: '0 15px',
      color: '#2E3F57',
      fontSize: 13,
      height: '100%',
    },
    cellWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    tableCellCentered: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    numericColumns: {
      justifyContent: 'flex-end',
    },
    selected: {
      backgroundColor: `${theme['primary-color-light']}`,
      alignItems: 'center',
    },
    checkbox: {
      ...getCheckboxStyles(theme),
      '& .ant-checkbox-inner::after': {
        left: '22%',
        top: '45%',
        borderWidth: 1,
      },
    },
  };
});
