import { UserGroupAvatarData } from 'hooks/avatars/types';
import { orderBy } from 'lodash';

/**
 * Sorts user groups by group name.
 *
 * @param groups User groups to sort.
 *
 * @returns Alphatecially sorted list of user groups.
 */
export const sortUserGroupAvatars = (groups: UserGroupAvatarData[]) => {
  return orderBy(groups, group => {
    return group.name.toLowerCase();
  });
};
