import React from 'react';
import useCustomRadioWidgetStyles from './styles';
import { RadioGroup } from 'components/lib/Radio';
import { RadioChangeEvent } from 'antd/lib/radio';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { Widget } from 'alx-dynamic-form';
import clsx from 'clsx';

const CustomRadioWidget: Widget = ({
  description,
  value,
  onChange,
  options,
  errors,
  required,
  label,
  disabled,
  reValidateField,
  fieldAlias,
}) => {
  const classes = useCustomRadioWidgetStyles({});
  const widgetClasses = useCustomWidgetsStyles({});

  const handleChange = (e: RadioChangeEvent) => {
    onChange(e.target.value);
    reValidateField();
  };

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div
            className={classes.radioStyles}
            data-testid={`single-select-radio-${fieldAlias}`}
          >
            <RadioGroup
              onChange={handleChange}
              {...{ disabled, options, value }}
            />
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomRadioWidget;
