import React from 'react';
import { fixMultipleWhiteSpaces } from 'components/Inputs/hooks';
import { PhoneIcon } from 'components/Icon';
import useInputStyles from 'components/lib/Input/styles';
import clsx from 'clsx';
import { Input } from 'components/lib/Input';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { Widget } from 'alx-dynamic-form';
import useCustomPhoneInputWidgetStyles from './styles';

export const PHONE_INPUT_MAX_LENGTH = 20;

const CustomPhoneNumberWidget: Widget = ({
  onChange,
  value,
  description,
  errors,
  required,
  label,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const inputClasses = useInputStyles({});
  const widgetClasses = useCustomWidgetsStyles({});
  const phoneInputClasses = useCustomPhoneInputWidgetStyles({});

  const customOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (newValue === undefined || newValue === '') return onChange(null);

    const trimmedValue = fixMultipleWhiteSpaces(newValue);

    return onChange(trimmedValue);
  };

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div
            className={clsx(inputClasses.customInput, {
              [inputClasses.error]: !!errors?.length,
            })}
          >
            <Input
              data-testid={`phone-input-${fieldAlias}`}
              value={value as string}
              onChange={customOnChange}
              onBlur={reValidateField}
              maxLength={PHONE_INPUT_MAX_LENGTH}
              {...{ disabled }}
            />
            <div
              className={clsx(
                inputClasses.inputIconWrapper,
                phoneInputClasses.phoneIconWrapper
              )}
            >
              <PhoneIcon className={inputClasses.icon} size={16} />
            </div>
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomPhoneNumberWidget;
