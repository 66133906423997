import { ColumnsMetadata } from 'utils/types/api/table.types';
import { CustomTableGroupKeys } from 'components/CatalystTable/contexts/TableContext/types/customTableGroupKeys';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';

export const getFieldsWithLabels = (
  fields: string[],
  columns: MappedObject<ColumnsMetadata>,
  tableName?: string
) => {
  return fields.map(key => {
    const column = columns[key];

    if (column.groupKey && column.groupKey !== CustomTableGroupKeys.Default) {
      return {
        ...column,
        label: column?.label ?? '',
      };
    }

    return {
      ...column,
      label:
        GlobalIntlSingleton.dynamicFormatMessage({
          id: `${tableName}.${column.alias}`,
        }) ?? column.alias,
    };
  });
};
