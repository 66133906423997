import { TreeUsageItem } from 'components/ResourceUsagePanelView/types/treeUsageItem';
import { IntlShape } from 'react-intl';

/**
 * Takes a tree of ObjectClasses and ObjectClassFields showing where groups are used and formats the name of parent ObjectClasses
 * for better UI readability.
 *
 * @param usageTrees Tree representing the structure to be displayed in the UI.
 * @param intl International package from useIntl().
 *
 * @returns Same tree with formatted ObjectClasses name properties.
 */
export const wrapObjectClassNamesForUsageTree = (
  usageTrees: TreeUsageItem[],
  intl: IntlShape
) => {
  const suffix = intl
    .formatMessage({
      id: 'misc.objectClassFieldPlural',
      defaultMessage: 'Object class fields',
    })
    .toLowerCase();

  return usageTrees.map(objectClass => {
    return {
      ...objectClass,
      name: `${objectClass.name} - ${suffix}:`,
    };
  });
};
