import { FilterValue } from 'react-table';

/**
 * Tests the provided filter to determine if it should be removed from the list of
 * active filters when its value is wrong (due to no longer being available as an opion).
 * @param wrongValue Value of the filter that is no longer valid for filtering application.
 * @param filter Filter to be tested for removal.
 * @returns True if the filter should be removed, false otherwise.
 */
export const shouldRemoveFilter = (wrongValue: string, filter: FilterValue) => {
  const filterValue = filter.value.predicateValues.value;

  // single select field
  if (filter.type === 'enum' && filterValue === wrongValue) {
    return true;
  }

  // multi select field
  if (filter.type === 'set') {
    const valuesAfterRemoval = (filterValue as string[]).filter(
      value => value !== wrongValue
    );

    return valuesAfterRemoval.length === 0;
  }

  return false;
};
