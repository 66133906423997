import {
  TaskFormField,
  TaskOptions,
  FormattedTaskSchema,
  TaskUserAssigneeOptions,
  TaskGroupAssigneeOptions,
} from 'components/SidePanels/TasksPanel/types';
import { mapTaskOptionsToDictionary } from 'pages/TaskTemplates/utils';
import { TaskFormSchema } from '../types';
import { TaskTypes } from 'pages/TaskTemplates/enums';
import { UniqueFieldType } from 'hooks/useValidationSchemaBuilder';

export const createTaskFormSchema = (
  taskOptions: TaskOptions | undefined,
  taskUserAssigneeOptions: TaskUserAssigneeOptions | undefined,
  taskGroupAssigneeOptions: TaskGroupAssigneeOptions | undefined
): TaskFormSchema | undefined => {
  if (!taskOptions || !taskUserAssigneeOptions || !taskGroupAssigneeOptions) {
    return;
  }

  const taskSchema: FormattedTaskSchema = mapTaskOptionsToDictionary(
    taskOptions.details.schema
  );

  return {
    [TaskFormField.Name]: taskSchema[TaskFormField.Name],
    [TaskFormField.DueDate]: taskSchema[TaskFormField.DueDate],
    [TaskFormField.TaskTemplate]: taskSchema[TaskFormField.TaskTemplate],
    [TaskFormField.TaskType]: {
      ...taskSchema[TaskFormField.TaskType],
      taskTypeSpecificOptions: {
        [TaskTypes.Form]: {
          autocomplete: taskSchema[TaskTypes.Form].autocomplete,
        },
        [TaskTypes.RecordUpdate]: {
          autocomplete: taskSchema[TaskTypes.RecordUpdate].autocomplete,
        },
      },
    },
    [TaskFormField.Assignees]: {
      type: UniqueFieldType.UsersAndGroups,
      required:
        taskUserAssigneeOptions.batch.required ||
        taskGroupAssigneeOptions.batch.required,

      usersAutocompleteUrl: taskUserAssigneeOptions.batch.autocomplete,
      limitUsersStandard:
        taskUserAssigneeOptions.restrictions.limit_assignees_standard,
      limitUsers1TC:
        taskUserAssigneeOptions.restrictions
          .limit_assignees_one_time_completion,
      limitUsersInBatch:
        taskUserAssigneeOptions.restrictions.limit_items_in_batch,

      groupsAutocompleteUrl: taskGroupAssigneeOptions.batch.autocomplete,
      limitGroups: taskGroupAssigneeOptions.restrictions.limit_items,
      limitGroupsInBatch:
        taskGroupAssigneeOptions.restrictions.limit_items_in_batch,
    },
  };
};
