import React from 'react';
import useFormPreviewStyles from 'components/FormPreview2/styles';
import { StandardFieldWrapperProps } from './StandardFieldWrapper.types';
import { ErrorsComponent } from 'components/ErrorsComponent';
import { Provider } from 'react-redux';
import { store } from 'store/store';

export const StandardFieldWrapper: React.FC<StandardFieldWrapperProps> = ({
  children,
  description,
  errors,
  valueLength,
  maxLength,
  errorLimits,
}) => {
  const styles = useFormPreviewStyles();

  return (
    <Provider {...{ store }}>
      <div>
        {children}

        <div className={styles.counterAndErrorContainer}>
          <ErrorsComponent
            rawErrors={errors}
            whitelist={errorLimits?.whitelist}
            blacklist={errorLimits?.blacklist}
          />

          {maxLength && (
            <div>
              <span
                className={styles.counter}
              >{`${valueLength} / ${maxLength}`}</span>
            </div>
          )}
        </div>

        {description && (
          <span className={styles.description}>{description}</span>
        )}
      </div>
    </Provider>
  );
};
