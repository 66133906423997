import axios from 'axios';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_HISTORY_FILES } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { showSuccessToast } from 'features/toasts/utils/showSuccessToast';

export const useGeneratedDocumentDownload = (
  eventId: string,
  refreshData: () => void
) => {
  const intl = useIntl();
  const { selectedResource } = useSelectedResourceContext();
  const knownErrorCodes = new Set([401, 403, 404]);

  return useCallback(
    (fileName?: string) => async () => {
      const recordId = selectedResource?.record?.recordId;
      if (recordId) {
        try {
          const url = generatePath(OBJECT_RECORD_HISTORY_FILES, {
            recordId,
            eventId,
          });

          const { data } = await apiCall.get(url, { responseType: 'blob' });

          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = URL.createObjectURL(data);
          link.download = fileName ?? 'file.docx';

          link.click();
          URL.revokeObjectURL(link.href);
          showSuccessToast({
            title: intl.formatMessage({
              id: 'misc.success',
              defaultMessage: 'Success!',
            }),
            subtitle: intl.formatMessage({
              id: 'activityLog.theDocumentHasBeenDownloaded',
              defaultMessage: 'The document has been downloaded.',
            }),
          });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (knownErrorCodes.has(error.response?.status as number)) {
              refreshData();
              return;
            }
          }
          showUnhandledErrorToast(error);
        }
      }
    },
    [eventId, selectedResource, intl, knownErrorCodes, refreshData]
  );
};
