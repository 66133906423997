import { createUseStyles } from 'react-jss';

export const useTasksPanelStyles = createUseStyles({
  container: {
    height: '100%',
    '& .ant-spin-nested-loading, .ant-spin-container': {
      height: '100%',
    },
  },
});
