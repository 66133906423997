import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_OBJECT_RECORDS,
  SET_OBJECT_RECORDS_FETCHING,
  APPEND_OBJECT_RECORDS,
  RESET_OBJECT_RECORDS,
  SET_OBJECT_RECORDS_COLUMNS,
  RESET_OBJECT_RECORDS_COLUMNS,
  SET_OBJECT_RECORDS_SELECTED_ROW,
  SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK,
  SET_OBJECT_RECORDS_SELECTED_COLUMNS,
  SET_OBJECT_RECORDS_RESTRICTIONS,
  SET_OBJECT_RECORDS_ERROR,
} from 'store/constants/objects.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { ObjectRecord } from 'utils/types/api/objectRecords.types';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';
import { FormBuilderField } from 'components/formBuilder/formBuilder/FormBuilderContext/types';

interface CustomStateProperties {
  removeOwnerCallback?: () => void;
  selectedColumns?: MappedObject<FormBuilderField[]>;
  initialColumns?: MappedObject<FormBuilderField[]>;
}

export type ObjectRecordsState = CommonTableState<
  ObjectRecord,
  CustomStateProperties
>;

const initialState = {
  ...cloneDeep(INITIAL_TABLE_STATE),
  removeOwnerCallback: undefined,
  selectedColumns: undefined,
  error: undefined,
};

const objectRecordsReducer: Reducer<ObjectRecordsState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_OBJECT_RECORDS: {
      return setTableContent<ObjectRecord, typeof SET_OBJECT_RECORDS>(
        state,
        action
      );
    }
    case APPEND_OBJECT_RECORDS: {
      return appendTableContent<ObjectRecord, typeof APPEND_OBJECT_RECORDS>(
        state,
        action
      );
    }
    case SET_OBJECT_RECORDS_FETCHING: {
      return setTableLoading<ObjectRecord, typeof SET_OBJECT_RECORDS_FETCHING>(
        state,
        action
      );
    }
    case SET_OBJECT_RECORDS_SELECTED_COLUMNS: {
      const restOfSelectedColumns = state.selectedColumns;

      return {
        ...state,
        selectedColumns: {
          ...restOfSelectedColumns,
          [action.payload.selectedClassId]: action.payload.selectedColumns,
        },
        ...(action.payload?.initialColumns && {
          initialColumns: {
            [action.payload.selectedClassId]: action.payload.initialColumns,
          },
        }),
      };
    }
    case RESET_OBJECT_RECORDS: {
      return resetTable<ObjectRecord>(state, initialState);
    }
    case RESET_OBJECT_RECORDS_COLUMNS: {
      return resetTableColumns<ObjectRecord>(state, initialState);
    }
    case SET_OBJECT_RECORDS_COLUMNS: {
      return setTableColumns<ObjectRecord, typeof SET_OBJECT_RECORDS_COLUMNS>(
        state,
        action
      );
    }
    case SET_OBJECT_RECORDS_SELECTED_ROW: {
      return setSelectedRow<
        ObjectRecord,
        typeof SET_OBJECT_RECORDS_SELECTED_ROW
      >(state, action);
    }
    case SET_OBJECT_RECORD_REMOVE_OWNER_CALLBACK: {
      return {
        ...state,
        removeOwnerCallback: action.payload,
      };
    }
    case SET_OBJECT_RECORDS_RESTRICTIONS: {
      return {
        ...state,
        restrictions: action.payload,
      };
    }
    case SET_OBJECT_RECORDS_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default objectRecordsReducer;
