import {
  ChildClassPassThroughParams,
  ExternalComponentPassThroughParams,
} from 'components/formBuilder/formBuilder/types';

export enum FormSchemaElementType {
  ChildClass = 'child_class',
  String = 'string',
  ExternalContent = 'external_content',
  Boolean = 'boolean',
  Number = 'number',
  Array = 'array',
  Object = 'object',
}

export enum FormSchemaFieldDatabaseType {
  DateTime = 'datetime',
  String = 'string',
  Bool = 'bool',
  Date = 'date',
  Float = 'float',
  Document = 'document',
  Set = 'set',
  Int = 'int',
  Json = 'json',
  User = 'user',
  Phone = 'phone',
  Enum = 'enum',
  Time = 'time',
  Array = 'array',
  Email = 'email',
  Url = 'url',
}

/**
 * Custom component form field
 */
export type FormFieldSchemaFieldExternalContent = {
  title: string;
  type: FormSchemaElementType.ExternalContent;
  passThrough: {
    externalComponentParams: ExternalComponentPassThroughParams;
  };
};

/**
 * Child class form field
 */
export type FormFieldSchemaFieldChildClass = {
  type: FormSchemaElementType.ChildClass;
  format: 'child_class';
  title: string;
  passThrough: {
    childClassParams: ChildClassPassThroughParams;
  };
};

/**
 * Instruction form field
 */
export type FormFieldSchemaFieldInstruction = {
  type: FormSchemaElementType.String;
  title: string;
  description: string;
};

/**
 * Base for all property type form fields
 */
type FormFieldSchemaPropertyFieldBase = {
  isProperty: true;
};

/**
 * ALX ID form field
 */
export type FormFieldSchemaFieldId = FormFieldSchemaPropertyFieldBase & {
  title: 'ALX ID';
  type: FormSchemaElementType.Number;
  databaseType: FormSchemaFieldDatabaseType.Int;
  fieldId: -2;
  multipleOf: 1;
};

/**
 * Created at form field
 */
export type FormFieldSchemaFieldCreatedAt = FormFieldSchemaPropertyFieldBase & {
  title: 'Created date/time';
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.DateTime;
  fieldId: -3;
  format: 'date-time';
};

/**
 * Updated at form field
 */
export type FormFieldSchemaFieldUpdatedAt = FormFieldSchemaPropertyFieldBase & {
  title: 'Updated date/time';
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.DateTime;
  fieldId: -4;
  format: 'date-time';
};

/**
 * Owners type field
 */
export type FormFieldSchemaFieldOwners = FormFieldSchemaPropertyFieldBase & {
  title: 'Owners';
  type: FormSchemaElementType.Array;
  databaseType: FormSchemaFieldDatabaseType.Array;
  items: {
    format: 'data-url';
    type: string;
  };
  fieldId: -1;
};

/**
 * Base for regular form fields.
 */
type FormFieldSchemaFieldBase = {
  fieldId: number;
  title: string;
  isProperty: false;
};

/**
 * Base for text form fields
 */
type FormFieldSchemaFieldTextBase = {
  maxLength?: number;
};

/**
 * Regular text type field
 */
export type FormFieldSchemaFieldText = FormFieldSchemaFieldBase &
  FormFieldSchemaFieldTextBase & {
    type: FormSchemaElementType.String;
    databaseType: FormSchemaFieldDatabaseType.String;
  };

/**
 * Email type field
 */
export type FormFieldSchemaFieldEmail = FormFieldSchemaFieldBase &
  FormFieldSchemaFieldTextBase & {
    type: FormSchemaElementType.String;
    databaseType: FormSchemaFieldDatabaseType.Email;
    format: 'email';
  };

/**
 * Phone type field
 */
export type FormFieldSchemaFieldPhone = FormFieldSchemaFieldBase &
  FormFieldSchemaFieldTextBase & {
    type: FormSchemaElementType.String;
    databaseType: FormSchemaFieldDatabaseType.Phone;
  };

/**
 * Url type field
 */
export type FormFieldSchemaFieldUrl = FormFieldSchemaFieldBase &
  FormFieldSchemaFieldTextBase & {
    type: FormSchemaElementType.String;
    databaseType: FormSchemaFieldDatabaseType.Url;
    format: 'url';
  };

/**
 * Json form field
 */
export type FormFieldSchemaJson = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.Json;
  format: 'json';
};

/**
 * User type field
 */
export type FormFieldSchemaFieldUser = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.Array;
  databaseType: FormSchemaFieldDatabaseType.User;
  allowGroupMemberSelection: boolean;
  allowGroupSync: boolean;
  items: {
    type: 'string';
    users: []; // schema seems to always return an empty array here
  };
  minUsers?: number;
  maxUsers?: number;
  minGroups?: number;
  maxGroups?: number;
  uniqueItems: boolean;
};

/**
 * Base for numeric form fields
 */
export type FormFieldSchemaFieldNumberBase = {
  minimum?: number;
  maximum?: number;
  default?: number;
};

/**
 * Integer form field
 */
export type FormFieldSchemaFieldInteger = FormFieldSchemaFieldBase &
  FormFieldSchemaFieldNumberBase & {
    type: FormSchemaElementType.Number;
    databaseType: FormSchemaFieldDatabaseType.Int;
    multipleOf: number;
  };

/**
 * Decimal form field
 */
export type FormFieldSchemaFieldDecimal = FormFieldSchemaFieldBase &
  FormFieldSchemaFieldNumberBase & {
    type: FormSchemaElementType.Number;
    databaseType: FormSchemaFieldDatabaseType.Float;
  };

/**
 * Multi select form field
 */
export type FormFieldSchemaFieldMultiSelect = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.Array;
  databaseType: FormSchemaFieldDatabaseType.Set;
  items: {
    enum: string[];
  };
  multiSelectNaturalNumbers: boolean;
  uniqueItems: boolean;
  minimum?: number;
  maximum?: number;
  minItems?: number;
  maxItems?: number;
};

/**
 * Single select form field
 */
export type FormFieldSchemaFieldSingleSelect = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.Enum;
  enum: string[];
  default?: string;
};

/**
 * Checkbox form field
 */
export type FormFieldSchemaFieldCheckbox = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.Boolean;
  databaseType: FormSchemaFieldDatabaseType.Bool;
};

/**
 * Date form field
 */
export type FormFieldSchemaFieldDate = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.Date;
  format: 'date';
};

/**
 * Date time form field
 */
export type FormFieldSchemaFieldDateTime = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.DateTime;
  format: 'date-time';
};

/**
 * Time form field
 */
export type FormFieldSchemaFieldTime = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.String;
  databaseType: FormSchemaFieldDatabaseType.Time;
  format: 'time';
};

/**
 * Focument form field
 */
export type FormFieldSchemaFieldDocument = FormFieldSchemaFieldBase & {
  type: FormSchemaElementType.Array;
  databaseType: FormSchemaFieldDatabaseType.Document;
  maxItems: number;
  items: {
    format: 'data-url';
    type: 'string';
  };
};

/**
 * Schema of a form section
 */
export type FormSchemaSection = {
  /**
   * @deprecated This is the original rjsf dependencies property which is never used by Catalyst.
   */
  dependencies: {};
  properties: {
    id?: FormFieldSchemaFieldId;
    created_at?: FormFieldSchemaFieldCreatedAt;
    modified_at?: FormFieldSchemaFieldUpdatedAt;
    owners?: FormFieldSchemaFieldOwners;
  } & Record<
    string, // 44636 - after upgrading to Typescript 4.1 - field_ keys
    | FormFieldSchemaFieldUser
    | FormFieldSchemaFieldDecimal
    | FormFieldSchemaFieldMultiSelect
    | FormFieldSchemaFieldText
    | FormFieldSchemaFieldEmail
    | FormFieldSchemaFieldPhone
    | FormFieldSchemaFieldUrl
    | FormFieldSchemaJson
    | FormFieldSchemaFieldInteger
    | FormFieldSchemaFieldSingleSelect
    | FormFieldSchemaFieldCheckbox
    | FormFieldSchemaFieldDate
    | FormFieldSchemaFieldDateTime
    | FormFieldSchemaFieldTime
    | FormFieldSchemaFieldDocument
    | FormFieldSchemaFieldExternalContent // 44636 - after upgrading to Typescript 4.1 - external_content_ keys
    | FormFieldSchemaFieldChildClass // 44636 - after upgrading to Typescript 4.1 - child_class_ keys
    | FormFieldSchemaFieldInstruction // 44636 - after upgrading to Typescript 4.1 - new_input_ keys
  >;
  /* There are two read onlys because there is a missmatch between useDynamicForm type (readonly) and printed section schema in the logs (read_only). 
  They need to be merged after migration of alx-dynamic-form project - it is probable that the useDynamicForm type is wrong. */
  read_only: string[];
  readonly: string[];
  required: string[];
  title: string;
  type: 'object';
};

/**
 * Schema of the whole form.
 */
export type FormSchema = {
  /**
   * @deprecated This is the original rjsf dependencies property which is never used by Catalyst.
   */
  dependencies: {};
  deps: MappedObject<any[]>; // 44639
  description: string;
  enabled: boolean;
  properties: MappedObject<FormSchemaSection>;
  read_only: string[];
  /* There are two read onlys because there is a missmatch between useDynamicForm type (readonly) and printed form schema in the logs (read_only). 
  They need to be merged after migration of alx-dynamic-form project - it is probable that the useDynamicForm type is wrong. */
  readonly: string[];
  required: string[];
  schemaVersion: number;
  title: string;
  type: 'object';
};
