import React from 'react';
import { useTaskFormFooterStyles } from './TaskFormFooter.styles';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import { BUTTON_CANCEL_TESTID, BUTTON_SUBMIT_TESTID } from 'utils/testIds';
import { PlusIcon } from 'components/Icon';
import { FormattedMessage } from 'react-intl';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import { useFormikContext } from 'formik';
import { TaskFormType } from '../../types';
import { TaskFormFooterProps } from './TaskFormFooter.types';

export const TaskFormFooter = ({
  shouldDueDateHaveValue,
  onCancelWhenFormDirty,
}: TaskFormFooterProps) => {
  const styles = useTaskFormFooterStyles();

  const { setMode } = useTasksPanelContext();
  const {
    dirty,
    errors,
    isSubmitting,
    values,
    handleSubmit,
  } = useFormikContext<TaskFormType>();

  const getShouldDisableSubmit = () => {
    if (!dirty || isSubmitting) {
      return true;
    }

    if (Object.keys(errors).length > 0) {
      return true;
    }

    if (shouldDueDateHaveValue && !values.due_date) {
      return true;
    }

    return false;
  };

  const changePanelToList = () => {
    if (dirty) {
      onCancelWhenFormDirty();
      return;
    }

    setMode(TasksPanelMode.List);
  };

  return (
    <div className={styles.buttonsWrapper}>
      <ButtonSecondaryOutlined
        data-testid={BUTTON_CANCEL_TESTID}
        onClick={changePanelToList}
      >
        <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
      </ButtonSecondaryOutlined>

      <ButtonTertiary
        icon={<PlusIcon size={9} />}
        loading={isSubmitting}
        disabled={getShouldDisableSubmit()}
        data-testid={BUTTON_SUBMIT_TESTID}
        onClick={() => handleSubmit()}
      >
        <FormattedMessage id='misc.add' defaultMessage='Add' />
      </ButtonTertiary>
    </div>
  );
};
