import { createUseStyles } from 'react-jss';
import { panelWrapper } from 'styles/panelsStyles';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFiltersPanelStyles = createUseStyles((theme: ThemeState) => ({
  panelWrapper: {
    ...panelWrapper,
    gap: '12px',
  },
  filtersWrapper: {
    position: 'relative',
    overflow: 'auto',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '8px',
  },
  singleFilter: {
    padding: '12px',
    marginBottom: '16px',
    border: `1px solid ${theme['placeholder-border-color']}`,
    borderRadius: '3px',
  },
}));
