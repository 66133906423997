import { createContext, useContext } from 'react';
import { UsersAndGroupsExpandablePickerContextType } from './UsersAndGroupsExpandablePickerContext.types';

export const UsersAndGroupsExpandablePickerContext = createContext<UsersAndGroupsExpandablePickerContextType | null>(
  null
);

export const useUsersAndGroupsExpandablePickerContext = () => {
  const context = useContext(UsersAndGroupsExpandablePickerContext);

  if (!context) {
    throw new Error(
      'useUsersAndGroupsExpandablePickerContext has null context. Did you wrap your component in UsersAndGroupsExpandablePickerContextProvider?'
    );
  }

  return context;
};
