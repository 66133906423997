import React from 'react';
import { TaskGroupAssigneesLimitAlertProps } from './TaskGroupAssigneesLimitAlert.types';
import { useIntl } from 'react-intl';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { LimitAlert } from 'components/LimitAlert';

export const TaskGroupAssigneesLimitAlert = ({
  groupsLimit,
}: TaskGroupAssigneesLimitAlertProps) => {
  const intl = useIntl();

  const { taskAssignees } = useTasksPanelContext();

  const errorMessage = intl.formatMessage(
    {
      id: 'misc.groupAssigneesLimitError',
      defaultMessage:
        'Maximum of {limit} assigned user {limit, plural, one {group} other {groups}} has been reached.',
    },
    { limit: groupsLimit }
  );
  const warningMessage = intl.formatMessage(
    {
      id: 'tasksPanel.groupAssignessLimitWarning',
      defaultMessage:
        'A task can have up to {limit} user {limit, plural, one {group} other {groups}} assigned. This one currently has {count}.',
    },
    { limit: groupsLimit, count: taskAssignees.userGroups.length }
  );

  return (
    <LimitAlert
      itemsCount={taskAssignees.userGroups.length}
      itemsLimit={groupsLimit}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
    />
  );
};
