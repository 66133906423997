import React from 'react';
import { DatePickerInput } from 'components/Inputs/DatePicker';
import moment, { Moment } from 'moment';
import { useIntl } from 'react-intl';
import { FilterDateValueProps } from './FilterDateValue.types';
import { getDateFormat, getShouldShowTime } from '../../utils';

export const FilterDateValue = ({
  value,
  filterKey,
  inputType,
  className,
  testId,
  onChange,
}: FilterDateValueProps) => {
  const intl = useIntl();

  const showTime = getShouldShowTime(filterKey, inputType);
  const dateFormat = getDateFormat(showTime);

  const handleChange = (date: Moment | null) => {
    onChange(date?.format(dateFormat) ?? '');
  };

  return (
    <DatePickerInput
      className={className}
      value={value ? moment(value) : undefined}
      placeholder={intl.formatMessage({
        id: 'placeholders.enterDate',
        defaultMessage: 'Select date',
      })}
      showTime={showTime}
      data-testid={testId}
      onChange={handleChange}
    />
  );
};
