import React, { useRef, useState } from 'react';
import { InPlaceEditFileUploadProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import useInPlaceEditFileUploadStyles from './styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { FILES_PREVIEW } from 'utils/testIds';
import { FormValue } from 'alx-dynamic-form';
import { CustomFileItem } from 'components/lib/FileUpload/types';
import { useInPlaceFilesSavingContext } from 'contexts/InPlaceFilesSavingContext';
import ConfirmRemoveFileModal from './components/ConfirmRemoveFileModal';
import ErrorComponent from 'components/ErrorComponent';
import InPlaceEditFileContent from './components/InPlaceEditFileContent';
import { useInPlaceEditUploadFile } from './hooks';
import Tooltip from 'components/lib/Tooltip';
import noop from 'lodash/noop';

const InPlaceEditFileUpload = <R extends Object>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onChange: widgetOnChange,
  onSaveSuccess,
  readOnly,
  size,
  remountChangeTarget,
  maxItems = 100,
  recordId,
  identifier,
  withUnderline,
  skipUploading = false,
  shouldDownloadFileOnClick,
}: InPlaceEditFileUploadProps<R>) => {
  const styles = useInPlaceEditFileUploadStyles({});
  const [fileIdToDelete, setFileIdToDelete] = useState<number | undefined>();
  const {
    inputWrapperRef,
    isSaving,
    tempValue,
    setTempValue,
    isEditMode,
    setIsEditModeOn,
    editWrapperRef,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
    preventPatch: true,
    withOutsideClick: !fileIdToDelete,
  });
  const { updateSavingFiles } = useInPlaceFilesSavingContext();
  const endpointUrl = `${patchUrl}field-files/${encodeURIComponent(
    propertyName.replace('field_', '')
  )}/`;

  const {
    updateRecord,
    onUploadCompleted,
    error,
    loading,
  } = useInPlaceEditUploadFile({
    recordId,
    identifier,
    endpointUrl,
    propertyName,
    setTempValue,
    isEditMode,
    skipUploading,
    onChange: widgetOnChange,
    onSaveSuccess,
  });

  const intl = useIntl();
  const inPlaceEditWrapperRef = useRef<HTMLDivElement>(null);

  const getPopupContainer = () => {
    return inPlaceEditWrapperRef.current ?? document.body;
  };

  const onChange = (data: FormValue) => setTempValue(data as CustomFileItem[]);

  if (error?.status) return <ErrorComponent error={error.status} />;
  const readOnlyMessage = intl.formatMessage({
    id: 'forms.readOnlyField',
    defaultMessage: 'This field is read-only',
  });

  return (
    <>
      <InPlaceEditWrapper
        ref={inPlaceEditWrapperRef}
        allowClickWhenDisabled={!skipUploading}
        {...{
          isSaving,
          label,
          required,
          inputWrapperRef,
          size,
          isEditMode,
          readOnly: disabled || readOnly,
          withUnderline,
          disabled: skipUploading,
        }}
        previewClassName={styles.previewClass}
        onViewClick={
          (!readOnly && !disabled) || value?.length ? setIsEditModeOn : noop
        }
        editContent={
          recordId || skipUploading ? (
            <InPlaceEditFileContent
              value={tempValue as CustomFileItem[]}
              recordIdentifier={identifier}
              fieldLabel={label}
              {...{
                editWrapperRef,
                remountChangeTarget,
                maxItems,
                onChange,
                onUploadCompleted,
                loading,
              }}
              customOnRemove={(id: number) => setFileIdToDelete(id)}
              getPopupContainer={getPopupContainer}
              onUpload={updateSavingFiles}
              // disabled is returned on card view, readOnly in other places
              readOnly={disabled || readOnly}
              shouldDownloadFileOnClick={shouldDownloadFileOnClick}
            />
          ) : (
            <p className={styles.readoOnlyEmptyContent}>-</p>
          )
        }
        viewContent={
          <div>
            <Tooltip overlay={!readOnly && disabled ? readOnlyMessage : ''}>
              {value?.length ? (
                <span className={styles.fileLink} data-testid={FILES_PREVIEW}>
                  <FormattedMessage
                    id='misc.documentsNumber'
                    defaultMessage='{count, plural, one {# document} few {# documents} other {# documents}}'
                    values={{ count: value.length }}
                  />
                </span>
              ) : (
                <span className={styles.readoOnlyEmptyContent}>-</span>
              )}
            </Tooltip>
          </div>
        }
        rowAdditionalClassName={styles.fileUpload}
      />
      <ConfirmRemoveFileModal
        {...{
          fileIdToDelete,
          setFileIdToDelete,
          endpointUrl,
          updateRecord,
        }}
      />
    </>
  );
};

export default InPlaceEditFileUpload;
