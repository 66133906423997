import React, { useCallback, useMemo, useState } from 'react';
import { TasksPanelContext } from './TasksPanelContext';
import { TasksPanelProviderProps } from './TasksPanelContext.types';
import { useTaskDetails } from './hooks/useTaskDetails';
import { StageNames, Task } from 'utils/types/api/tasks.types';
import { TaskAssignees } from '../../types/taskAssignees';
import { UsersOrGroupsTabKey } from 'utils/Enums/UsersOrGroupsTabKey';

export const TasksPanelProvider = ({
  children,
  setMode,
}: TasksPanelProviderProps) => {
  const [task, setTask] = useState<Task>();
  const [activeTab, setActiveTab] = useState<UsersOrGroupsTabKey>(
    UsersOrGroupsTabKey.Users
  );

  const {
    taskDetails,
    isLoading,
    errorStatus,
    clearTaskDetails,
    fetchTaskDetails,
  } = useTaskDetails();

  const taskAssignees: TaskAssignees = useMemo(() => {
    const stageOneDetails = taskDetails?.stages.find(
      stage => stage.name === StageNames.Stage1
    );

    return {
      users: stageOneDetails?.assignees.users ?? [],
      userGroups: stageOneDetails?.assignees.user_groups ?? [],
    };
  }, [taskDetails]);

  const handleTaskSelect = useCallback(
    (task: Task) => {
      setActiveTab(UsersOrGroupsTabKey.Users);
      clearTaskDetails();

      setTask(task);
      fetchTaskDetails(task.id);
    },
    [clearTaskDetails, fetchTaskDetails]
  );

  const refetchTaskDetails = useCallback(() => {
    return fetchTaskDetails(task?.id ?? '');
  }, [fetchTaskDetails, task]);

  const contextValue = useMemo(() => {
    return {
      activeTab,
      task,
      taskDetails,
      taskAssignees,
      areTaskDetailsLoading: isLoading,
      taskDetailsErrorStatus: errorStatus,
      setActiveTab,
      setMode,
      setTask: handleTaskSelect,
      refetchTaskDetails,
    };
  }, [
    activeTab,
    task,
    taskDetails,
    taskAssignees,
    isLoading,
    errorStatus,
    setMode,
    handleTaskSelect,
    refetchTaskDetails,
  ]);

  return (
    <TasksPanelContext.Provider value={contextValue}>
      {children}
    </TasksPanelContext.Provider>
  );
};
