import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useEventDataExpandStyles = createUseStyles(
  (theme: ThemeState) => ({
    eventDataExpandWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
      margin: [2, 0, 2, 45],
      maxHeight: (isExpanded: boolean) => (isExpanded ? 178 : 0),
      overflowY: 'auto',
      padding: (isExpanded: boolean) => (isExpanded ? [8, 0] : 0),
      transition: 'max-height 200ms ease',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    eventDataExpandItem: {
      fontSize: 12,
      fontWeight: 700,
    },
    eventDataGray: {
      fontSize: 12,
      fontWeight: 700,
      color: theme['disabled-switch-gray'],
    },
    eventDataDocumentDownload: {
      fontSize: 12,
      fontWeight: 700,
      color: theme['primary-color'],
      cursor: 'pointer',
      width: 'fit-content',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);
