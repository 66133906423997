import { isNil } from 'lodash';

/**
 * Checks if the limit of users that can be assigned to the resource is reached.
 *
 * @param usersLimit The limit of users that can be assigned to the resource.
 * @param selectedUsersCount The count of users that are selected to be assigned.
 * @param assignedUsersCount The count of users that are already assigned.
 *
 * @returns True if the limit is reached, false otherwise.
 */
export const getIsUserAssigneesLimitReached = (
  usersLimit: number | undefined,
  selectedUsersCount: number,
  assignedUsersCount: number
) => {
  const usersTotalCount = selectedUsersCount + assignedUsersCount;

  return !isNil(usersLimit) && usersTotalCount >= usersLimit;
};
