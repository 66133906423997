import {
  BULK_SELECTION_COLUMN_WIDTH,
  ACTIONS_COLUMN_WIDTH,
} from 'components/CatalystTable/CatalystTable.consts';

export const calculateAdditionalColumnWidth = (
  isVisibleBulkSelectionColumn: boolean,
  hasActionCell: boolean
) => {
  return (
    (isVisibleBulkSelectionColumn ? BULK_SELECTION_COLUMN_WIDTH : 0) +
    (hasActionCell ? 0 : -ACTIONS_COLUMN_WIDTH)
  );
};
