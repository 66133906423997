import React, { useEffect, useRef } from 'react';
import UserAvatar from 'components/UserAvatar';
import { BoldedPartOfText } from 'components/Text/BoldedPartOfText';
import useOptionAvatarStyles from '../OptionAvatar/OptionAvatar.styles';
import { OptionAvatarGroupProps } from './OptionGroupAvatar.types';
import {
  OPTION_AVATAR_BOTTOM_TEXT,
  OPTION_AVATAR_DISABLED_TEXT,
  OPTION_AVATAR_TEXT_WRAPPER,
} from 'utils/testIds';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

export const OptionGroupAvatar = ({
  option: { id, text, num_of_members },
  searchValue,
  disabledText,
  disabled,
  selected,
  showUserCount,
  disableDefaultSelectionStyle,
}: OptionAvatarGroupProps) => {
  const styles = useOptionAvatarStyles();
  const optionTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // optionTextRef.current.parentElement.parentElement === .ant-select-item-option
    if (optionTextRef.current?.parentElement?.parentElement?.removeAttribute)
      optionTextRef.current.parentElement.parentElement.removeAttribute(
        'title'
      );
  }, []);

  return (
    <>
      <UserAvatar
        {...{ id, disabled, num_of_members }}
        userGroup={text}
        size='large'
        disablePopover
      />
      <div
        ref={optionTextRef}
        className={clsx(styles.textWrapper, {
          [styles.highlightText]: selected && !disableDefaultSelectionStyle,
        })}
        data-testid={OPTION_AVATAR_TEXT_WRAPPER}
      >
        <BoldedPartOfText value={text} boldPhrase={searchValue} />
        <div
          className={clsx(styles.bottomText, {
            [styles.disabledBottomText]: disabled,
            [styles.highlightText]: selected && !disableDefaultSelectionStyle,
          })}
          data-testid={OPTION_AVATAR_BOTTOM_TEXT}
        >
          {!!showUserCount && (
            <span>
              <FormattedMessage
                id={'members.membersCount'}
                defaultMessage={
                  '{membersCount, plural, one {# member} other {# members}}'
                }
                values={{ membersCount: num_of_members }}
              />
            </span>
          )}
          {!!disabledText && (
            <span
              className={styles.disabledText}
              data-testid={OPTION_AVATAR_DISABLED_TEXT}
            >
              {disabledText}
            </span>
          )}
        </div>
      </div>
    </>
  );
};
