import React from 'react';
import { ToastContainer as ToastifyToastContainer } from 'react-toastify';
import { useToastsContainerStyles } from './ToastsContainer.styles';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Container in which toasts are displayed. Also acts as a configuration provider for toasts.
 */
export const ToastsContainer = () => {
  const styles = useToastsContainerStyles();

  return (
    <ToastifyToastContainer
      className={styles.toastify}
      autoClose={3000}
      closeOnClick={false}
      draggable={false}
      icon={false}
      newestOnTop
      position='bottom-right'
      theme='colored'
    />
  );
};
