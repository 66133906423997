import React from 'react';
import { useAddAssigneesPanelViewHeaderStyles } from './AddAssigneesPanelViewHeader.styles';
import {
  ADDITIONAL_CONTEXT_TEXT_TESTID,
  COUNTER_TESTID,
  TITLE_TESTID,
} from 'utils/testIds';
import TooltipString from 'components/TooltipString';
import { AddAssigneesPanelViewHeaderProps } from './AddAssigneesPanelViewHeader.types';

/**
 * Header for the add assignees panel view.
 */
export const AddAssigneesPanelViewHeader = ({
  counterText,
  title,
  additionalContextText,
}: AddAssigneesPanelViewHeaderProps) => {
  const styles = useAddAssigneesPanelViewHeaderStyles();

  return (
    <header className={styles.header}>
      <h3 className={styles.title} data-testid={TITLE_TESTID}>
        <TooltipString text={title} />
      </h3>

      <div className={styles.secondaryTextsWrapper}>
        <p className={styles.secondaryText} data-testid={COUNTER_TESTID}>
          {counterText}
        </p>

        {additionalContextText && (
          <p
            className={styles.secondaryText}
            data-testid={ADDITIONAL_CONTEXT_TEXT_TESTID}
          >
            {additionalContextText}
          </p>
        )}
      </div>
    </header>
  );
};
