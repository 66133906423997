import { useSelector } from 'react-redux';
import useTableCount from 'hooks/useTableCount';
import {
  getCurrentTable,
  getCurrentTableFetching,
} from 'store/selectors/filtersSelectors';
import {
  getAllPreferences,
  getTableDensity,
} from 'store/selectors/preferencesSelectors';
import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import { DEFAULT_TABLE_PAGE_SIZES } from 'utils/consts';
import { useCurrentTableColumnsWithData } from './hooks/useCurrentTableColumnsWithData';
import { useCurrentTableHiddenColumnsAndQuery } from './hooks/useCurrentTableHiddenColumnsAndQuery';
import { UseCurrentTableParams } from './types/useCurrentTableParams';
import { UseCurrentTableReturnType } from './types/useCurrentTableReturnType';
import { useTableData } from './hooks/useTableData';
import { useCurrentTableSupportedColumns } from '../useCurrentTableSupportedColumns';

export const useCurrentTable = <T>({
  fetchMethod,
  dataSelector,
  getColumnsConfiguration,
  resetData,
  columnLabelKey,
  resetColumns,
  withPagination,
  handleSearch,
  defaultSearchValue,
  defaultIsVisibleBulkSelectionColumn,
  withoutSort,
  withFiltersDefault,
  preferencesId,
  customDataGroups,
  defaultSortColumn,
  loading,
  resetPaginationRef,
  isPaginationUsingURL = true,
  pageSizes = DEFAULT_TABLE_PAGE_SIZES,
  customTableName,
  defaultColumnWidthsOverrides,
}: UseCurrentTableParams<T>): UseCurrentTableReturnType<T> => {
  const data = useSelector(dataSelector);
  const { total, filtered: filteredCount } = useTableCount(
    preferencesId?.toString()
  );
  const currentColumns = useCurrentTableSupportedColumns(
    preferencesId?.toString(),
    customTableName
  );
  const isFetching = useSelector(
    getCurrentTableFetching(preferencesId?.toString(), customTableName)
  );
  const preferences = useSelector(getAllPreferences);
  const density = useSelector(getTableDensity) ?? TableDensity.Default;
  const currentTableNameStore = useSelector(getCurrentTable);
  const currentTableName = customTableName ?? currentTableNameStore;

  const {
    additionalQueryParams,
    columnsConfigurationLoading,
    setColumnsConfigurationLoading,
  } = useCurrentTableHiddenColumnsAndQuery({
    currentTableName,
    customTableName,
    currentColumns,
    customDataGroups,
    preferencesId,
    preferences,
  });

  const {
    isInfinite,
    editModeEnabled,
    setEditModeEnabled,
    sort,
    searchValue,
    ...table
  } = useTableData({
    reduxAction: fetchMethod,
    resetColumns,
    withPagination,
    isCustomSearch: !!handleSearch,
    defaultSearchValue,
    defaultIsVisibleBulkSelectionColumn: !!defaultIsVisibleBulkSelectionColumn,
    withoutSort: !!withoutSort,
    withFiltersDefault: !!withFiltersDefault,
    preferencesId,
    additionalQueryParams,
    defaultSortColumn,
    loading: columnsConfigurationLoading || loading,
    isPaginationUsingURL,
    pageSizes,
    resetPaginationRef,
    customTableName,
  });

  const {
    dataWithAdditionalColumns,
    columns,
    toggleEditMode,
    toggleReload,
  } = useCurrentTableColumnsWithData<T>({
    data,
    currentColumns,
    preferencesId,
    sort,
    currentTableName,
    getColumnsConfiguration,
    setColumnsConfigurationLoading,
    resetData,
    editModeEnabled,
    setEditModeEnabled,
    preferences,
    columnLabelKey,
    defaultColumnWidthsOverrides,
  });

  return {
    columns,
    data: !!handleSearch
      ? handleSearch(dataWithAdditionalColumns, searchValue)
      : dataWithAdditionalColumns,
    isFetching,
    total,
    filteredCount,
    isInfinite,
    currentColumns,
    editModeEnabled,
    toggleEditMode,
    toggleReload,
    density,
    sort,
    searchValue,
    ...table,
    columnsConfigurationLoading,
  };
};
