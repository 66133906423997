import React from 'react';
import MinMaxInfo from 'components/MinMaxInfo';
import useCheckboxesWidgetStyles from './styles';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { Widget } from 'alx-dynamic-form';
import clsx from 'clsx';

const CustomCheckboxesWidget: Widget = ({
  onChange,
  value,
  minValues,
  maxValues,
  options,
  errors,
  description,
  required,
  label,
  disabled,
  reValidateField,
  fieldAlias,
}) => {
  const classes = useCheckboxesWidgetStyles({});
  const widgetClasses = useCustomWidgetsStyles({});

  const onCheckboxesChange = (value: any) => {
    const outOfMaxRange = maxValues && value.length > maxValues;

    if (!outOfMaxRange || outOfMaxRange === undefined) {
      onChange(value);
      reValidateField();
    }
  };

  const checkboxOptions = options?.map(option => ({
    label: option,
    value: option,
  }));

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div className={classes.checkboxWrapper}>
            <CheckboxGroup
              data-testid={`multiselect-${fieldAlias}`}
              className={classes.checkboxGroup}
              options={checkboxOptions}
              onChange={onCheckboxesChange}
              value={value as string[]}
              {...{ disabled }}
            />
          </div>
          <MinMaxInfo
            maximum={maxValues ?? undefined}
            minimum={minValues ?? undefined}
            checkboxField
          />
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomCheckboxesWidget;
