import { TPredicateInputTypes } from 'utils/types/predicates.types';

export const getShouldShowTime = (
  filterKey: string,
  filterValueInputType: TPredicateInputTypes
) => {
  // created_at and modified_at columns return datetime but BE also takes date (then it looks for the whole day). Currently, these columns are meant to be the exception (act as date).
  if (['created_at', 'modified_at', 'added_at'].includes(filterKey)) {
    return false;
  }

  if (!['datetime', 'dateTimeRange'].includes(filterValueInputType)) {
    return false;
  }

  return true;
};
