import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { SelectUserOption } from 'utils/types/selectInput.types';

/**
 * Filters out user selection options from fetched items that are not eligible for display in user pickers.
 * @param fetchedOptions User options received from the API
 * @param value Current form value.
 * @returns Options eligible for display.
 */
export const getEligibleUserOptions = (
  fetchedOptions: SelectUserOption[],
  value: UsersAndGroupsFormValue | null | undefined
): SelectUserOption[] => {
  return fetchedOptions.filter(
    user =>
      // deleted users should be considered an available option when they are selected
      !user.is_deleted || value?.users.some(userId => userId === user.id)
  );
};
