import React from 'react';
import { ToastIconProps } from './ToastIcon.types';
import {
  ExclamationMark,
  SuccessMark,
  WarningExclamationMark,
  InfoMark,
} from 'components/Icon';
import { ToastType } from 'features/toasts/types/toastType';
import { useToastIconStyles } from './ToastIcon.styles';

/**
 * Renders an icon based on the type of toast.
 */
export const ToastIcon = ({ type }: ToastIconProps) => {
  const styles = useToastIconStyles();

  const Icon = (() => {
    switch (type) {
      case ToastType.Info:
        return InfoMark;
      case ToastType.Success:
        return SuccessMark;
      case ToastType.Warning:
        return WarningExclamationMark;
      case ToastType.Error:
        return ExclamationMark;
    }
  })();

  return <Icon className={styles.icon} minWidth={0} />;
};
