import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import {
  getObjectClassSelectedPermissionSet,
  getObjectClassPermissionSetAssigneesData,
} from 'store/selectors/objectClassPermissionsSelectors';
import { OBJECT_CLASS_PERMISSION_SET_ASSIGNEES } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import AddUsersPanel from '../AddUsersPanel';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';
import {
  AddUsersPanelType,
  CustomErrorMessage,
  OwnersMode,
} from '../AddUsersPanel/types';
import { getObjectClassPermissionSetAssignees } from 'store/actions/objectClassPermissionsActions';
import { useIntl } from 'react-intl';
import { AppDispatch } from 'store/store';
import { ErrorCodes } from 'utils/types/errorResponse';
import { useObjectClassContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts';

export const AddPermissionSetUsersPanel = () => {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const { id: objectClassId } = useParams<{ id: string }>();
  const { activePermissionSetTab } = useObjectClassContext();

  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const { avatars, totalCount: usersCount, options } = useSelector(
    getObjectClassPermissionSetAssigneesData(permissionSetId)
  );

  const itemsLimit = options?.restrictions?.limit_items ?? undefined;
  const autocompleteUrl = options?.batch?.autocomplete ?? '';
  const postApiEndpoint = generatePath(OBJECT_CLASS_PERMISSION_SET_ASSIGNEES, {
    objectClassId,
    permissionSetId,
  });

  const panelTitle = intl.formatMessage(
    {
      id: 'misc.assignUsersTo',
      defaultMessage: 'Assign users to {name}',
    },
    { name: selectedPermissionSet?.name }
  );
  const limitMessage = intl.formatMessage(
    {
      id: 'objectClasses.permissionSets.selectedUsersLimitReached',
      defaultMessage:
        'Maximum of {limit} new user {limit, plural, one {assignee} other {assignees}} can be selected ({usersCount} already assigned).',
    },
    { limit: itemsLimit && itemsLimit - usersCount, usersCount }
  );
  const alreadyAssignedMessage = intl.formatMessage({
    id: 'misc.alreadyAssigned',
    defaultMessage: 'Already assigned',
  });

  const getCustomErrorMessage = ({
    count,
    itemsLimit,
    errorCode,
  }: CustomErrorMessage) => {
    if (errorCode !== ErrorCodes.ERR_LIMIT_EXCEEDED || itemsLimit) {
      return;
    }

    return {
      title: intl.formatMessage(
        {
          id: 'owners.countNotAddedAssignees',
          defaultMessage: `{count, plural, one {# assignee} other {# assignees}} not added`,
        },
        { count }
      ),
      subtitle: intl.formatMessage(
        {
          id: 'owners.assigneesLimitReached',
          defaultMessage:
            'Maximum of {itemsLimit} {itemsLimit, plural, one {assignee} other {assignees}} has been reached.',
        },
        { itemsLimit }
      ),
    };
  };

  const getCustomSuccessMessage = (count: number) => {
    return intl.formatMessage(
      {
        id: 'misc.successfulyAssigedUsersTo',
        defaultMessage: `{count, plural, one {# user has} other {# users have}} been assigned to {name}.`,
      },
      { count, name: selectedPermissionSet?.name }
    );
  };

  const fetchUsers = () => {
    return dispatch(
      getObjectClassPermissionSetAssignees(
        objectClassId,
        permissionSetId,
        activePermissionSetTab
      )
    );
  };

  return (
    <AddUsersPanel<ObjectClassOwner>
      panelTitle={panelTitle}
      panelKey={FlexLayoutWindows.ClassPermissionsOwners}
      panelType={AddUsersPanelType.PermissionSetAssignees}
      defaultMode={OwnersMode.Manage}
      avatars={avatars}
      usersCount={usersCount}
      itemsLimit={itemsLimit}
      autocompleteUrl={autocompleteUrl}
      postApiEndpoint={postApiEndpoint}
      intlLimitMessage={limitMessage}
      alreadyAddedDisabledMessage={alreadyAssignedMessage}
      shouldFetchOnSuccess
      customErrorMessage={getCustomErrorMessage}
      customSuccessMessage={getCustomSuccessMessage}
      fetchOwners={fetchUsers}
    />
  );
};
