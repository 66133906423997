export const COLUMN_SIZES: {
  [key: string]: number[];
} = {
  '100': [24],

  '50:50': [12, 12],
  '33:67': [8, 16],
  '67:33': [16, 8],
  '25:75': [6, 18],
  '75:25': [18, 6],
  '12.5:87.5': [3, 21],
  '87.5:12.5': [21, 3],

  '33:33:33': [8, 8, 8],
  '50:25:25': [12, 6, 6],
  '25:50:25': [6, 12, 6],
  '25:25:50': [6, 6, 12],
  '12.5:12.5:75': [3, 3, 18],
  '12.5:75:12.5': [3, 18, 3],
  '75:12.5:12.5': [18, 3, 3],
};
