import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getInPlaceEditFontSize } from '../commonStyles';

type UsePreviewComponentStylesProps = {
  size?: 'small' | 'medium';
  disabled?: boolean;
  actionButtonOnTop?: boolean;
  readOnly?: boolean;
  withUnderline?: boolean;
};

const usePreviewComponentStyles = createUseStyles((theme: ThemeState) => {
  return {
    valueWrapper: ({
      size,
      disabled,
      actionButtonOnTop,
      readOnly,
      withUnderline,
    }: UsePreviewComponentStylesProps = {}) => {
      const styles: MappedObject<any> = {
        borderRadius: 0,
        minHeight: '25px',
        transition: 'box-shadow 0.2s ease-in-out',
        padding: '0px',
        fontSize: getInPlaceEditFontSize(size),
        borderBottom: `1px solid ${
          readOnly || !withUnderline ? 'transparent' : '#DBDFE8'
        }`,
        cursor: disabled ? 'not-allowed' : 'text',
        marginTop: 1,
        display: 'flex',
        flex: 1,
        alignItems: actionButtonOnTop ? 'flex-start' : 'center',
        justifyContent: 'space-between',
        fontWeight: 'normal',
        '& > svg': {
          color: theme['layout-trigger-color'],
          transition: 'opacity 0.2s ease-in-out',
          opacity: 0,
        },
        '& textarea': {
          cursor: disabled ? 'not-allowed' : 'text',
        },
      };

      if (!readOnly) {
        styles['&:hover'] = {
          boxShadow: `0px 0px 0px 1px ${theme['border-color-base']}`,
          '& > svg': {
            opacity: 1,
          },
          borderRadius: 3,
          borderColor: 'transparent',
        };
      }

      return styles;
    },
  };
});

export default usePreviewComponentStyles;
