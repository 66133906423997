import { ExtendedJsonSchema } from 'components/FormPreview/types';
import { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { OBJECT_CLASS_DETAILS_FIELDS } from 'utils/endpoints';
import {
  convertDynamicSchemaToSchema,
  DynamicFieldInfo,
  extractDynamicFieldsFromDynamicSchema,
} from '../utils/dynamicSchemaUtils';
import { DynamicExtendedSchema } from '../formBuilder/types';
import { ExtendedUiSchema } from '../../FormPreview/customFields/ObjectField/types';
import { apiCall } from 'utils/api';
import { ParseState } from './types';
import { usePropertyFields } from '../formBuilder/InputsContainer/hooks';
import chunk from 'lodash/chunk';
import { FIELD_PREFIX } from 'utils/consts';
import { convertUiSchema } from './utils/convertUiSchema';

const API_MAX_FILTER_FIELDS = 49;

const useDynamicSchema = (
  dynamicSchema: DynamicExtendedSchema | undefined,
  dynamicUiSchema: ExtendedUiSchema | undefined,
  classId: string | number | undefined,
  customFieldsUrl?: string
) => {
  const owners = usePropertyFields();
  const [parseState, setParseState] = useState<ParseState>({
    schema: undefined,
    uiSchema: undefined,
    loading: true,
    dynamicFieldsAliases: undefined,
    classId: undefined,
  });

  const cancelParsing = () =>
    setParseState({
      schema: undefined,
      uiSchema: undefined,
      loading: false,
      dynamicFieldsAliases: undefined,
      classId,
    });

  const fetchClassFields = async (fieldIds: number[]) => {
    const fieldInfo100ElementGroups = chunk(
      fieldIds.filter(field => field > 0), //local fields have negative ids and we don't have to fetch them
      API_MAX_FILTER_FIELDS
    );

    const fetchUrl =
      customFieldsUrl ||
      generatePath(OBJECT_CLASS_DETAILS_FIELDS, { id: classId || 0 });

    const requests = fieldInfo100ElementGroups.map(fieldsGroup => {
      const queryString =
        '?id__in=' +
        fieldsGroup.reduce<string>(
          (wholeString, currentValue) => wholeString + `${currentValue},`,
          ''
        );

      return apiCall.get(fetchUrl + queryString);
    });

    const result = await Promise.all(requests);

    return result.map(response => response.data.results).flat();
  };

  const prepareSchemas = async () => {
    if (!dynamicSchema || !dynamicUiSchema || !classId) {
      cancelParsing();

      return;
    }

    setParseState(prevState => ({ ...prevState, loading: true }));

    const dynamicFieldsInfo = extractDynamicFieldsFromDynamicSchema(
      dynamicSchema
    );

    // if we dont have any dynamic fields we have basically normal schema
    if (dynamicFieldsInfo.length === 0) {
      setParseState({
        schema: dynamicSchema as ExtendedJsonSchema,
        uiSchema: dynamicUiSchema,
        loading: false,
        dynamicFieldsAliases: undefined,
        classId,
      });

      return;
    }

    try {
      const classFields = await fetchClassFields(
        dynamicFieldsInfo.map(fieldInfo => fieldInfo.id)
      );

      const fields = [...owners, ...(classFields || [])];
      const newDataSchema = convertDynamicSchemaToSchema(dynamicSchema, fields);

      const { schemaVersion } = newDataSchema;

      // v5 schema moved field prefix directly into schema so it does not have to be additionally added
      // in older versions prefix must be added to alias
      const mapFieldsInfoToAliasesArray =
        schemaVersion !== undefined && schemaVersion < 5
          ? (field: DynamicFieldInfo) => `${FIELD_PREFIX}${field.alias}`
          : (field: DynamicFieldInfo) => field.alias;

      setParseState({
        schema: newDataSchema,
        uiSchema: convertUiSchema(newDataSchema, dynamicUiSchema),
        loading: false,
        dynamicFieldsAliases: dynamicFieldsInfo.map(
          mapFieldsInfoToAliasesArray
        ),
        classId,
      });
    } catch {
      cancelParsing();
    }
  };

  useEffect(() => {
    prepareSchemas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicSchema, dynamicUiSchema, classId]);

  return parseState;
};

export default useDynamicSchema;
