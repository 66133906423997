import ConfirmationModal from 'components/ConfirmationModal';
import React from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import { UnassignClassPermissionGroupModalProps } from './UnassignClassPermissionGroupModal.types';

export const UnassignClassPermissionGroupModal = ({
  groupToUnassign,
  isLoading,
  onConfirm,
  onCancel,
}: UnassignClassPermissionGroupModalProps) => {
  const intl = useIntl();

  const title = intl.formatMessage(
    {
      id: 'misc.sureToUnassign',
      defaultMessage: 'Are you sure you want to unassign {name}?',
    },
    {
      name: groupToUnassign?.name,
    }
  );
  const subtitle = intl.formatMessage({
    id: 'objectClass.groupMayLoseAccessToObjectClass',
    defaultMessage:
      'Members of the group may lose access to this object class.',
  });
  const confirmBtnText = intl.formatMessage({
    id: 'misc.yesUnassign',
    defaultMessage: 'Yes, unassign',
  });

  return (
    <ConfirmationModal
      visible={!!groupToUnassign}
      isLoading={isLoading}
      title={title}
      subtitle={subtitle}
      confirmationButtonLabel={confirmBtnText}
      image={WarningIllustration}
      withCancel
      onClose={onCancel}
      onConfirm={onConfirm}
    />
  );
};
