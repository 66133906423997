import { IntlShape } from 'react-intl';
import { ErrorMessages } from 'utils/types/errorResponse';

export const transformTaskFormErrors = (
  errors: ErrorMessages,
  intl: IntlShape
) => {
  const newErrors = { ...errors };

  if (errors.task_template?.startsWith('errors.invalid_pk')) {
    newErrors.task_template = intl.formatMessage({
      id: 'errors.task_template_does_not_exist',
      defaultMessage: 'Task template does not exist',
    });
  }

  return newErrors;
};
