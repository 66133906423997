import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useCheckIconCellStyles = createUseStyles((theme: ThemeState) => {
  return {
    booleanCheck: {
      color: theme['chips-color'],
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
});
