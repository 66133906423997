import React from 'react';
import TimeWidget from './TimeWidget';
import moment from 'moment';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { Widget } from 'alx-dynamic-form';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import clsx from 'clsx';
import { PropertyField } from 'components/formBuilder/formBuilder/FBGeneralInput/components/FBFields/FBPropertyField/components/PropertyField';

const CustomTimePickerWidget: Widget = ({
  value,
  isProperty,
  description,
  errors,
  disabled,
  onChange,
  reValidateField,
  required,
  label,
  fieldAlias,
}) => {
  const classes = useCustomWidgetsStyles({});

  if (isProperty) {
    return (
      <PropertyField
        value={!!value ? moment(value as string).format('LLL') : undefined}
      />
    );
  }

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div className={classes.limitedMaxSizeInput}>
            <TimeWidget
              {...{ disabled, onChange, value, reValidateField, fieldAlias }}
            />
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomTimePickerWidget;
