import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetGroupsCount,
  getObjectClassPermissionSetGroupsLimit,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';

export const useClassPermissionGroupsLimit = () => {
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const groupAssigneesLimit = useSelector(
    getObjectClassPermissionSetGroupsLimit(permissionSetId)
  );
  const groupAssigneesCount =
    useSelector(getObjectClassPermissionSetGroupsCount(permissionSetId)) ?? 0;

  const isGroupsLimitExceeded = groupAssigneesLimit
    ? groupAssigneesCount >= groupAssigneesLimit
    : false;

  return {
    isGroupsLimitExceeded,
    groupAssigneesCount,
    groupAssigneesLimit,
  };
};
