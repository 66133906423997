import { omit } from 'lodash';
import React, { forwardRef } from 'react';
import { TableBodyPropGetter, TableBodyProps } from 'react-table';
import { ListOnScrollProps, ListProps } from 'react-window';
import { TABLE_BODY_ID } from 'utils/elementsIds';

/**
 * Higher order function to extract table outer definition from table component to not define it in the body of Table.
 * This is an absolute mess and I don't know how to untangle this.
 */
export const getTableOuter = (
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object>
  ) => TableBodyProps,
  preventOverflow: boolean | undefined,
  tableBody: string,
  customTableId: string,
  onTableBodyScroll: (
    event: React.UIEvent<HTMLDivElement, UIEvent>,
    virtualizedScrollMethod: ((props: ListOnScrollProps) => any) | undefined
  ) => void
) => {
  return forwardRef<HTMLDivElement, ListProps>((props, ref) => {
    const { onScroll, style } = props;
    const restStyles = omit(style || {}, ['width', 'height']);

    return (
      <div
        {...{ ref, ...props }}
        {...getTableBodyProps({
          style: {
            ...restStyles,
            overflow: preventOverflow ? 'none' : restStyles.overflow,
          },
        })}
        className={tableBody}
        id={TABLE_BODY_ID + customTableId}
        onScroll={event => onTableBodyScroll(event, onScroll)}
      />
    );
  });
};
