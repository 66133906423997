import React from 'react';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import { useIntl } from 'react-intl';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import { ButtonPrimary } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import { ADD_USER_ASSIGNEES_BTN_TESTID } from 'utils/testIds';
import { useManageClassPermissionUsersStyles } from './ManageClassPermissionUsers.styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { PanelMode } from 'utils/Enums/PanelMode';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { ClassPermissionUsersLimitAlert } from './components/ClassPermissionUsersLimitAlert';
import {
  useClassPermissionUserAvatars,
  useClassPermissionUsersLimit,
  useManageClassPermissionUsersLoader,
  useUnassignClassPermissionUser,
} from './hooks';
import { UnassignClassPermissionUserModal } from './components/UnassignClassPermissionUserModal';
import { Loader } from 'components/lib/Loader';

export const ManageClassPermissionUsers = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const styles = useManageClassPermissionUsersStyles();

  const {
    [FlexLayoutWindows.ClassPermissionsOwners]: sidebarData,
  } = useSelector(getSidebarData);

  const { showLoader } = useManageClassPermissionUsersLoader();
  const { userAvatars } = useClassPermissionUserAvatars();
  const {
    isUsersLimitExceeded,
    userAssigneesCount,
    userAssigneesLimit,
  } = useClassPermissionUsersLimit();
  const {
    userToUnassign,
    isUnassigningHimself,
    isUnassigning,
    cancelUnassigning,
    setUserToUnassign,
    unassignUserFromClassPermission,
  } = useUnassignClassPermissionUser();

  const disabledBtnTooltipMessage = intl.formatMessage({
    id: 'misc.maxUsersAssignedTooltip',
    defaultMessage:
      'The maximum number of assigned users has been reached. Unassign users to assign new ones.',
  });
  const noUsersAssignedMessage = intl.formatMessage({
    id: 'misc.noUsersHaveBeenAssigned',
    defaultMessage: 'No users have been assigned',
  });

  const openAddUsersView = () => {
    dispatch(
      setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {
        ...sidebarData,
        mode: PanelMode.Adding,
      })
    );
  };

  const handleUserItemDelete = (user: AvatarItem) => () => {
    const { id, firstName, lastName } = user;

    setUserToUnassign({ id, name: `${firstName} ${lastName}` });
  };

  if (showLoader) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader size='large' />
      </div>
    );
  }

  return (
    <div className={styles.contentWrapper}>
      <DisabledButtonTooltip
        title={disabledBtnTooltipMessage}
        showTooltip={isUsersLimitExceeded}
      >
        <ButtonPrimary
          className={clsx({ [styles.disabledBtn]: isUsersLimitExceeded })}
          icon={<PlusIcon size={10} />}
          disabled={isUsersLimitExceeded}
          data-testid={ADD_USER_ASSIGNEES_BTN_TESTID}
          onClick={openAddUsersView}
        />
      </DisabledButtonTooltip>

      {userAssigneesLimit && (
        <ClassPermissionUsersLimitAlert
          usersCount={userAssigneesCount}
          usersLimit={userAssigneesLimit}
        />
      )}

      <List<AvatarItem>
        className={styles.usersList}
        items={userAvatars}
        emptyDataDescription={noUsersAssignedMessage}
        renderItem={item => (
          <PeopleListElement {...item} onDelete={handleUserItemDelete(item)} />
        )}
      />

      <UnassignClassPermissionUserModal
        userToUnassign={userToUnassign}
        isUnassigningHimself={isUnassigningHimself}
        isLoading={isUnassigning}
        onConfirm={unassignUserFromClassPermission}
        onCancel={cancelUnassigning}
      />
    </div>
  );
};
