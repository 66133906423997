import React from 'react';
import { useTasksQuickFiltersStyle } from './TasksQuickFilters.styles';
import { TasksQuickFiltersOwnership } from './components/TasksQuickFilterOwnership';
import { TasksQuickFiltersDates } from './components/TasksQuickFilterDates';

export const TasksQuickFilters = () => {
  const styles = useTasksQuickFiltersStyle();

  return (
    <div className={styles.quickFiltersWrapper}>
      <TasksQuickFiltersOwnership />
      <TasksQuickFiltersDates />
    </div>
  );
};
