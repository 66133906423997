import { FormattedMessage } from 'react-intl';
import { useEventDataLabelStyles } from '../../../EventsList.styles';
import React from 'react';

export const tagsMapping = (
  styles: ReturnType<typeof useEventDataLabelStyles>
) => ({
  b: (...chunks: string[]) => <strong>{chunks}</strong>,
  g: (...chunks: string[]) => (
    <strong className={styles.eventDataLabelGreen}>{chunks}</strong>
  ),
  r: (...chunks: string[]) => (
    <strong className={styles.eventDataLabelRed}>{chunks}</strong>
  ),
  v: (chunks: string[]) => {
    if (chunks[0] === '')
      return (
        <strong className={styles.eventDataLabelGray}>
          <FormattedMessage id='activityLog.empty' defaultMessage='(empty)' />
        </strong>
      );
    if (chunks[0] === '\0')
      return (
        <strong className={styles.eventDataLabelRed}>
          <FormattedMessage id='activityLog.null' defaultMessage='NULL' />
        </strong>
      );
    return <strong>{chunks}</strong>;
  },
  a: (chunks: string[]) => {
    if (chunks[0] === '')
      return (
        <strong className={styles.eventDataLabelGray}>
          <FormattedMessage id='activityLog.empty' defaultMessage='(empty)' />
        </strong>
      );
    if (chunks[0] === '\0')
      return (
        <strong className={styles.eventDataLabelRed}>
          <FormattedMessage id='activityLog.null' defaultMessage='NULL' />
        </strong>
      );
    return (
      <a
        className={styles.eventDataLabelAnchor}
        href={chunks[0]}
        target='_blank'
        rel='noopener noreferrer'
      >
        {chunks}
      </a>
    );
  },
});
