import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { TooltipProps } from './types';
import useTooltipStyles from './styles';
import clsx from 'clsx';

const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltipMaxWidth = 260,
  shouldBeRendered = true,
  addOverlay,
  className,
  mouseEnterDelay = 1,
  zIndex,
  ...rest
}) => {
  const classes = useTooltipStyles({});

  if (shouldBeRendered)
    return (
      <AntTooltip
        transitionName=''
        overlayStyle={{ maxWidth: tooltipMaxWidth }}
        className={clsx([classes.wrapper, className])}
        mouseEnterDelay={mouseEnterDelay}
        {...(zIndex && { zIndex })}
        {...rest}
      >
        {children}
        {/* overlay helps with tooltip not dissapearing when mouse leaves element with "mouse-events: none" set */}
        {addOverlay && <div className={classes.overlay}></div>}
      </AntTooltip>
    );

  return <>{children}</>;
};

export default Tooltip;
