import { createUseStyles } from 'react-jss';

export const useUrlCellStyles = createUseStyles({
  url: {
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});
