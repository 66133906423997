import useData from '../../hooks/useData';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { RESET_PASSWORD, RESET_PASSWORD_TOKEN } from '../../utils/endpoints';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { usePostWithToasts } from '../../hooks/usePostWithToasts';
import { AuthState } from '../../utils/types/api/auth.types';
import { FormMode } from '../../utils/Enums/FormMode';
import { useEffect, useState } from 'react';
import useValidationSchemaBuilder from '../../hooks/useValidationSchemaBuilder';
import { OptionSelect } from '../../utils/types/selectInput.types';
import { setTokens } from '../../store/actions/authActions';
import routes, { nonProtectedRoutes } from '../../utils/routingPaths';
import { FormikHelpers } from 'formik';
import { ResetPasswordFields } from './enums';
import { ResetPasswordValidation } from './consts';
import { ResetPasswordFormValues, ResetPasswordParams } from './types';
import {
  ActivateAccountData,
  ActivateAccountParams,
} from '../ActivateAccount/types';
import { getAccessToken } from '../../store/selectors/authSelectors';
import { StatusCodes } from 'http-status-codes';

export const useResetPasswordData = () => {
  const { token } = useParams<ActivateAccountParams>();
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);
  const [activateAccountData, { loading, error }] = useData<
    ActivateAccountData
  >(generatePath(RESET_PASSWORD_TOKEN, { token: token || !token }), {
    fetchOnLoad: !!token,
  });

  useEffect(() => {
    if (!!accessToken) return history.replace(routes.HOMEPAGE);
    if (error?.status === StatusCodes.FORBIDDEN)
      return history.replace(nonProtectedRoutes.LOGIN);
  }, [history, accessToken, error]);

  return {
    activateAccountData,
    loadingActivateAccountData: loading,
    error,
  };
};

export const useResetPasswordForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams<ResetPasswordParams>();
  const { sendData } = usePostWithToasts<AuthState, AuthState>(FormMode.Create);
  const [isPasswordFulfilled, setIsPasswordFulfilled] = useState(false);
  const validationBuilder = useValidationSchemaBuilder<
    OptionSelect | undefined
  >(ResetPasswordValidation);
  const [initialValues] = useState({
    [ResetPasswordFields.Password]: '',
    [ResetPasswordFields.ConfirmPassword]: '',
  });

  const callback = (data?: AuthState) => {
    if (data) dispatch(setTokens(data));

    history.push(routes.HOMEPAGE);
  };

  const onSubmit = async (
    values: ResetPasswordFormValues,
    {
      setErrors,
      setFieldError,
      setSubmitting,
    }: FormikHelpers<ResetPasswordFormValues>
  ) => {
    if (
      values[ResetPasswordFields.Password] !==
      values[ResetPasswordFields.ConfirmPassword]
    ) {
      return setFieldError(
        ResetPasswordFields.ConfirmPassword,
        intl.formatMessage({
          defaultMessage: 'Passwords do not match',
          id: 'errors.noMatchPassword',
        })
      );
    }

    await sendData({
      url: generatePath(RESET_PASSWORD, { token }),
      data: { password: values[ResetPasswordFields.Password] },
      fields: ResetPasswordFields,
      callback,
      setErrors,
      setSubmitting,
    });
  };

  return {
    initialValues,
    onSubmit,
    setIsPasswordFulfilled,
    isPasswordFulfilled,
    ...validationBuilder,
  };
};
