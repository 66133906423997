import { useIntl } from 'react-intl';
import { showWarningToast } from 'features/toasts/utils/showWarningToast';

const useDefaultValueResetToast = () => {
  const intl = useIntl();

  return () => {
    showWarningToast({
      title: intl.formatMessage({
        id: 'misc.warning',
        defaultMessage: 'Warning!',
      }),
      subtitle: intl.formatMessage({
        id: 'formBuilder.defaultValueReset',
        defaultMessage:
          'Default value has been unset because it does not match the min/max range.',
      }),
    });
  };
};

export default useDefaultValueResetToast;
