import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import ColumnDataTypes from 'utils/Enums/ColumnDataTypes';
import { parseToIntlKey } from 'utils/functions/parseToIntlKey';
import { SelectOption } from 'utils/types/selectInput.types';
import { ColumnsMetadata } from 'utils/types/api/table.types';
import { CustomTableGroupKeys } from 'components/CatalystTable/contexts/TableContext/types/customTableGroupKeys';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import moment from 'moment';
import useFormatNumber from '../../../../../../../../hooks/useFormatNumber';
import useTimezone from '../../../../../../../../hooks/useTimezone';
import React, { useCallback } from 'react';
import useDateFormat from '../../../../../../../../hooks/useDateFormat';
import { Checkbox } from 'antd';
import { CUSTOM_DATE_FORMAT } from 'pages/TaskTemplates/components/TaskForm/consts';
import { isNil } from 'lodash';
import { CustomValueDisplayDocuments } from './components/CustomValueDisplayDocuments';
import { CustomValueDisplayEmail } from './components/CustomValueDisplayEmail';
import { CustomValueDisplayMultiselect } from './components/CustomValueDisplayMultiselect';

export const useColumnsTranslations = (
  columns: MappedObject<ColumnsMetadata>
) => {
  const { dateFormat, getTimeFormat } = useDateFormat();
  const { timezone } = useTimezone();
  const formatNumberWithSeparators = useFormatNumber();

  const getObjectClassFieldsColumnTranslation = useCallback(
    (
      value: object | string | number | undefined,
      row: any | undefined,
      type: ObjectClassFieldTypes
    ) => {
      if (isNil(value)) {
        return [
          ObjectClassFieldTypes.Datetime,
          ObjectClassFieldTypes.Date,
          ObjectClassFieldTypes.Time,
        ].includes(type)
          ? null
          : '-';
      }

      switch (type) {
        case ObjectClassFieldTypes.Set: {
          return (
            Array.isArray(value) && (
              <CustomValueDisplayMultiselect values={value} />
            )
          );
        }
        case ObjectClassFieldTypes.Boolean:
        case ObjectClassFieldTypes.Bool: {
          return <Checkbox checked={!!value} />;
        }
        case ObjectClassFieldTypes.Email: {
          return <CustomValueDisplayEmail value={value as string} />;
        }
        case ObjectClassFieldTypes.Document: {
          return (
            Array.isArray(value) && (
              <CustomValueDisplayDocuments
                value={value}
                recordId={row?.values?.id}
              />
            )
          );
        }
        case ObjectClassFieldTypes.Datetime: {
          return moment(value)
            .tz(timezone)
            .format(`${dateFormat}, ${getTimeFormat()}`);
        }
        case ObjectClassFieldTypes.Date: {
          return moment(value, CUSTOM_DATE_FORMAT).format(dateFormat);
        }
        case ObjectClassFieldTypes.Time: {
          return moment(value, 'HH:mm:ss').format('HH:mm');
        }
        case ObjectClassFieldTypes.Int: {
          return formatNumberWithSeparators(value as number);
        }
        case ObjectClassFieldTypes.Float: {
          return formatNumberWithSeparators(value as number);
        }
        default:
          return Array.isArray(value) ? value.join(', ') : value || '-';
      }
    },
    [dateFormat, formatNumberWithSeparators, getTimeFormat, timezone]
  );

  const evaluateEnumColumnTranslation = useCallback(
    (
      value: any,
      values: SelectOption[] | undefined,
      autocomplete: string | undefined
    ) => {
      if (values) {
        const valueText = values?.find(
          ({ value: selectValue }: SelectOption) => {
            return selectValue === value;
          }
        )?.text;

        if (!valueText) {
          return '';
        }

        return GlobalIntlSingleton.dynamicFormatMessage({
          id: `enums.${parseToIntlKey(
            typeof value === 'string' ? value : valueText
          )}`,
          defaultMessage: valueText,
        });
      }

      if (typeof value === 'string' && !autocomplete) {
        return GlobalIntlSingleton.dynamicFormatMessage({
          id: `enums.${parseToIntlKey(value)}`,
          defaultMessage: value,
        });
      }

      return value;
    },
    []
  );

  const getDefaultColumnTranslation = useCallback(
    (
      value: object | string | number | undefined,
      type: string,
      values: SelectOption[] | undefined,
      autocomplete: string | undefined,
      alias: string
    ) => {
      if (isNil(value)) {
        return '';
      }

      switch (type) {
        case ColumnDataTypes.Boolean:
          return GlobalIntlSingleton.dynamicFormatMessage({
            id: `enums.${value ? 'yes' : 'no'}`,
            defaultMessage: value ? 'Yes' : 'No',
          });
        case ColumnDataTypes.DateTime:
          return moment(value)
            .tz(timezone)
            .format(`${dateFormat}, ${getTimeFormat()}`);
        case ColumnDataTypes.Enum:
          return evaluateEnumColumnTranslation(value, values, autocomplete);
      }

      if (alias !== 'id' && typeof value === 'number') {
        return formatNumberWithSeparators(value);
      }

      return value;
    },
    [
      dateFormat,
      evaluateEnumColumnTranslation,
      formatNumberWithSeparators,
      getTimeFormat,
      timezone,
    ]
  );

  const getTranslation = useCallback(
    (key: string, value: any, row?: any) => {
      if (!columns) {
        return '';
      }

      const { type, values, autocomplete, groupKey, alias } =
        columns?.[key] ?? {};

      if (groupKey === CustomTableGroupKeys.ObjectClassFields) {
        return getObjectClassFieldsColumnTranslation(
          value,
          row,
          type as ObjectClassFieldTypes
        );
      }

      return getDefaultColumnTranslation(
        value,
        type,
        values,
        autocomplete,
        alias
      );
    },
    [
      columns,
      getDefaultColumnTranslation,
      getObjectClassFieldsColumnTranslation,
    ]
  );

  return {
    getTranslation,
  };
};
