import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useInPlaceEditUserStyles = createUseStyles((theme: ThemeState) => {
  return {
    userFieldWrapper: {
      marginBottom: 10,
    },
    editContentContainer: {
      width: 'auto',
      left: 0,
      right: '15px',
    },
  };
});
