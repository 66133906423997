import clsx from 'clsx';
import { ArrowDownIcon, ArrowUpIcon } from 'components/Icon';
import React from 'react';
import {
  ARROW_UP_ID,
  ARROW_DOWN_ID,
  ARROWS_WRAPPER_ID,
} from 'utils/elementsIds';
import useInputNumberStyles from './styles';
import { ArrowsWrapperProps } from './types';

const ArrowsWrapper = ({
  onArrowUpPress,
  onArrowDownPress,
}: ArrowsWrapperProps) => {
  const inputNumberStyles = useInputNumberStyles();

  return (
    <div className={inputNumberStyles.arrowsWrapper} id={ARROWS_WRAPPER_ID}>
      <button
        type='button'
        className={clsx(
          inputNumberStyles.arrowWrapper,
          inputNumberStyles.topWrapper
        )}
        onClick={onArrowUpPress}
        id={ARROW_UP_ID}
      >
        <ArrowUpIcon size={7} />
      </button>
      <button
        type='button'
        id={ARROW_DOWN_ID}
        className={clsx(
          inputNumberStyles.arrowWrapper,
          inputNumberStyles.bottomWrapper
        )}
        onClick={onArrowDownPress}
      >
        <ArrowDownIcon size={7} />
      </button>
    </div>
  );
};

export default ArrowsWrapper;
