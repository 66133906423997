import React, { useMemo } from 'react';
import { Heading } from 'components/lib/Heading';
import { FormattedMessage } from 'react-intl';
import { Space } from 'components/lib/Space';
import { Checkbox, CheckboxGroupCustom } from 'components/lib/Checkbox';
import { ButtonPrimary, ButtonOutlined } from 'components/lib/Button';
import { CloseIcon, PlusIcon } from 'components/Icon';
import { ColumnGroup } from '../../hooks/useAddColumn/types/columnGroup';
import { AddColumnListProps } from './AddColumnList.types';
import {
  ADD_COLUMNS_POPOVER_TESTID,
  ADD_COLUMNS_POPOVER_TITLE_TESTID,
  ADD_COLUMNS_TESTID,
  CANCEL_ADDING_COLUMNS_TESTID,
  COLUMN_TESTID,
} from 'utils/testIds';
import { CustomTableGroupKeys } from 'components/CatalystTable/contexts/TableContext/types/customTableGroupKeys';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClassFieldCheckboxListMemoized } from './components/ClassFieldCheckboxList';
import { useAddColumnListStyles } from './AddColumnList.styles';
import { useAddColumn } from '../../hooks/useAddColumn';

export const AddColumnList: React.FC<AddColumnListProps> = ({
  hiddenColumns,
  onCancel,
  onAddColumns,
}) => {
  const {
    currentTableColumns,
    options,
    fetchNextPage,
    hasMorePages,
    onSelectClassFields,
    classFields,
    selectedClassFields,
    onSelect,
    onClickCancel,
    onConfirmColumns,
    columnsToShow,
    isColumnLimitReached,
  } = useAddColumn(onCancel, onAddColumns, hiddenColumns);
  const styles = useAddColumnListStyles();

  const checkedColumns = useMemo(
    () => Object.fromEntries(columnsToShow.map(column => [column, true])),
    [columnsToShow]
  );

  return (
    <div className={styles.wrapper} data-testid={ADD_COLUMNS_POPOVER_TESTID}>
      <div className={styles.scroll} id='add-columns-list-wrapper'>
        <CheckboxGroupCustom vertical onChange={onSelect} value={columnsToShow}>
          {options.map(({ id, sectionLabel, fields, groupKey }) => (
            <div key={id}>
              <Heading
                level={5}
                className={styles.heading}
                data-testid={`${ADD_COLUMNS_POPOVER_TITLE_TESTID}-${groupKey}`}
              >
                {sectionLabel}
              </Heading>
              {groupKey === CustomTableGroupKeys.Default &&
                (fields as ColumnGroup[])?.map(({ label, value }) => {
                  const column = currentTableColumns[value];
                  const isDisabled =
                    !(value in checkedColumns) && isColumnLimitReached;

                  if (column) {
                    return (
                      <Checkbox
                        data-testid={`${COLUMN_TESTID}${value}`}
                        key={value}
                        className={styles.checkbox}
                        {...{ value }}
                        disabled={isDisabled}
                      >
                        <div className={styles.classNameLabel}>{label}</div>
                      </Checkbox>
                    );
                  }

                  return null;
                })}
            </div>
          ))}
          <InfiniteScroll
            loader={
              <h6>
                <FormattedMessage
                  id='misc.Loading'
                  defaultMessage='Loading..'
                />
              </h6>
            }
            next={fetchNextPage}
            hasMore={hasMorePages}
            scrollableTarget='add-columns-list-wrapper'
            dataLength={classFields?.length || 0}
          >
            <ClassFieldCheckboxListMemoized
              classFields={classFields ?? []}
              onSelectClassFields={onSelectClassFields}
              className={styles.classNameLabel}
              isColumnLimitReached={isColumnLimitReached}
              selectedClassFields={selectedClassFields ?? []}
            />
          </InfiniteScroll>
        </CheckboxGroupCustom>
      </div>
      <div className={styles.buttonsWrapper}>
        <Space>
          <ButtonOutlined
            icon={<CloseIcon size={14} />}
            onClick={onClickCancel}
            data-testid={CANCEL_ADDING_COLUMNS_TESTID}
          >
            <FormattedMessage id='misc.cancel' />
          </ButtonOutlined>
          <ButtonPrimary
            icon={<PlusIcon size={9} />}
            onClick={onConfirmColumns}
            data-testid={ADD_COLUMNS_TESTID}
          >
            <FormattedMessage
              id='misc.addColumns'
              defaultMessage='Add columns'
            />
          </ButtonPrimary>
        </Space>
      </div>
    </div>
  );
};
