import { LogLevel, Logger } from 'utils/logger';
import { AlertType } from '../types/alertType';

export const getLimitAlertMessage = (
  alertType: AlertType,
  erorrMessage: string,
  warningMessage: string
) => {
  switch (alertType) {
    case AlertType.Error:
      return erorrMessage;
    case AlertType.Warning:
    case AlertType.Info:
      return warningMessage;
    default:
      Logger.log(
        `Tried to get a limit alert message for the "${alertType}" alert type which is not handled.`,
        LogLevel.Warning
      );
  }
};
