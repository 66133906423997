import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDocumentTemplateUsageData } from './useDocumentTemplateUsageData';
import { UsageViewConfig } from 'components/ResourceUsagePanelView/types/usageViewConfig';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';

export const useDocumentTemplateUsage = (
  id?: string,
  objectClassID?: string
) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: { sequences = [] } = {},
  } = useDocumentTemplateUsageData(id, objectClassID);

  const displayConfig = useMemo<UsageViewConfig[]>(
    () => [
      {
        usage: sequences,
        title: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
        type: UsageViewType.List,
      },
    ],
    [intl, sequences]
  );

  return { loading, error, displayConfig };
};
