import { createUseStyles } from 'react-jss';

export const useDraggableTablePaginationStyles = createUseStyles({
  tablePagination: {
    marginLeft: 15,
  },
  toRightWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonNoPointerEvents: {
    cursor: 'not-allowed',
    '& button': {
      pointerEvents: 'none',
    },
  },
});
