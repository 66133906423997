import { StatusCodes } from 'http-status-codes';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  APPEND_NESTED_OBJECT_RECORDS,
  RESET_NESTED_OBJECT_RECORDS,
  SET_NESTED_OBJECT_RECORDS_FETCHING,
  SET_NESTED_OBJECT_RECORDS,
  SET_NESTED_OBJECT_RECORDS_COLUMNS,
  RESET_NESTED_OBJECT_RECORDS_COLUMNS,
  SET_NESTED_OBJECT_RECORDS_SELECTED_ROW,
  SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS,
  SET_NESTED_OBJECT_RECORDS_RESTRICTIONS,
  SET_NESTED_OBJECT_RECORDS_ERROR,
  CLEAR_NESTED_OBJECT_RECORDS,
  PUSH_STACK,
  POP_STACK,
  CLEAR_STACK,
  POP_MANY_STACK,
  ADD_TREE_CHILD,
  REMOVE_TREE_CHILD,
  REPLACE_TREE_NODE,
  REMOVE_TREE_NODE,
  CLEAR_TREE,
  UPDATE_STACK_ELEMENT,
  UPDATE_NESTED_RECORDS_STACK_MODAL,
} from 'store/constants/nestedObjectRecords.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import {
  OBJECT_CLASS_DETAILS_FIELDS,
  OBJECT_CLASS_FIELDS_AUTOCOMPLETE,
  OBJECT_RECORD_CHILDREN,
  OBJECT_RECORD_LIST,
} from 'utils/endpoints';
import DataFetchType from 'utils/Enums/DataFetchType';
import { OptionsResponse } from 'utils/types';
import { createTableUrl } from 'utils/functions/createTableUrl';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import {
  SetTableAction,
  ActionObject,
  ColumnsMetadata,
  Restrictions,
} from 'utils/types/api/table.types';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { generatePath } from 'react-router-dom';
import { CustomTableGroupKeys } from 'components/CatalystTable/contexts/TableContext/types/customTableGroupKeys';
import { FIELD_PREFIX } from 'utils/consts';
import { getPermissions } from './permissionsActions';
import { AxiosError } from 'axios';
import { ResponseError } from '../../utils/types/errorResponse';
import { isStatusOk } from './objectRecordsActions';
import { UserCreatedBy } from 'utils/types/api/users.types';
import { DEFAULT_OBJECT_MODEL_ID } from 'components/CatalystTable/CatalystTable.consts';
import { ChildClassColumns } from 'components/formBuilder/formBuilder/types';
import { DefaultPermissions } from 'utils/types/api/permissions.types';
import {
  AddChildParams,
  NestedRecordData,
  RemoveChildParams,
  RemoveNodeParams,
  ReplaceNodeParams,
} from 'hooks/useNestedRecordsTree/types';
import {
  NOT_CREATED_NODE_PREFIX,
  findByPath,
  parseStackToPath,
} from 'hooks/useNestedRecordsTree/utils';
import {
  NestedObjectRecordsModalTypes,
  NestedObjectRecordsStackElement,
  NestedRecordsStackModalData,
} from 'store/reducers/types/nestedObjectRecords.types';
import { AppDispatch } from 'store/store';
import { verifyNestedRecordsUnsavedChanges } from 'utils/functions/nestedRecordsStackModalHelpers';
import { FormBuilderField } from 'components/formBuilder/formBuilder/FormBuilderContext/types';

export type SetNestedObjectsAction = SetTableAction<
  Pick<
    ActionObject<ObjectRecordDetails>,
    'list' | 'total' | 'filtered' | 'classID'
  >,
  typeof SET_NESTED_OBJECT_RECORDS
>;

export type AppendNestedObjectsAction = SetTableAction<
  Pick<
    ActionObject<ObjectRecordDetails>,
    'list' | 'total' | 'filtered' | 'classID'
  >,
  typeof APPEND_NESTED_OBJECT_RECORDS
>;

export type SetNestedObjectsFetchingAction = SetTableAction<
  Pick<ActionObject<ObjectRecordDetails>, 'fetching' | 'classID'>,
  typeof SET_NESTED_OBJECT_RECORDS_FETCHING
>;

export type SetObjectsSelectedColumnsAction = SetTableAction<
  { classID: string; columns: FormBuilderField[] },
  typeof SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS
>;

export type SetNestedObjectsColumnsAction = SetTableAction<
  Pick<ActionObject<ObjectRecordDetails>, 'payload' | 'classID'>,
  typeof SET_NESTED_OBJECT_RECORDS_COLUMNS
>;

export type ResetObjectRecordsColumnsAction = Action<
  typeof RESET_NESTED_OBJECT_RECORDS_COLUMNS
> & {
  classID: string;
};

export type ResetNestedObjectsAction = Action<
  typeof RESET_NESTED_OBJECT_RECORDS
> & {
  classID: string;
};

export type ClearNestedObjectsAction = Action<
  typeof CLEAR_NESTED_OBJECT_RECORDS
> & {
  classID: string;
};

export type GetNestedObjectRecordsParams = {
  classId: string;
  parentRecordId: string | number;
  queryParams?: string;
  fetchType?: DataFetchType;
  objectModelId?: number | string;
};

export type GetSelectedObjectRecordsParams = {
  classId: string;
  queryParams?: string;
  fetchType?: DataFetchType;
  ids: number[] | undefined;
  overrideTotalCount?: number;
};

export type ObjectRecordChildAPI = {
  created_at: string;
  created_by: UserCreatedBy;
  object_record: ObjectRecordDetails;
  _meta: {
    permissions: DefaultPermissions;
  };
};

export type ObjectRecordChildrenRepsonse = {
  total_count: number;
  filtered_count: number;
  offset?: number;
  next?: string;
  previous?: string;
  limit: number;
  results: ObjectRecordChildAPI[];
};

export type ObjectRecordDetailsResponse = {
  total_count: number;
  filtered_count: number;
  offset?: number;
  next?: string;
  previous?: string;
  limit: number;
  results: ObjectRecordDetails[];
};

export type SetSelectedRowAction = SetTableAction<
  Pick<ActionObject<ObjectRecordDetails>, 'selectedRow'>,
  typeof SET_NESTED_OBJECT_RECORDS_SELECTED_ROW
>;

export type SetNestedObjectRecordsRestrictionsAction = SetTableAction<
  { payload: Restrictions } & { classID: string },
  typeof SET_NESTED_OBJECT_RECORDS_RESTRICTIONS
>;

export interface SetNestedObjectRecordsErrorAction
  extends Action<typeof SET_NESTED_OBJECT_RECORDS_ERROR> {
  payload: ResponseError | undefined;
  classID: string;
}

export type PushStackAction = Action<typeof PUSH_STACK> & {
  element: NestedObjectRecordsStackElement;
};
export type UpdateStackElement = Action<typeof UPDATE_STACK_ELEMENT> & {
  newData: Partial<NestedObjectRecordsStackElement>;
  classId: string;
};
export type PopStackAction = Action<typeof POP_STACK>;
export type PopManyStackAction = Action<typeof POP_MANY_STACK> & {
  index: number;
};
export type ClearStackAction = Action<typeof CLEAR_STACK>;

// tree

export type AddTreeChildAction = Action<typeof ADD_TREE_CHILD> & AddChildParams;
export type RemoveNodeAction = Action<typeof REMOVE_TREE_NODE> &
  RemoveNodeParams;
export type RemoveTreeChildAction = Action<typeof REMOVE_TREE_CHILD> &
  RemoveChildParams;
export type ReplaceTreeNodeAction = Action<typeof REPLACE_TREE_NODE> &
  ReplaceNodeParams;

export type ClearTreeAction = Action<typeof CLEAR_TREE>;

export type UpdateNestedRecordsStackModalAction = Action<
  typeof UPDATE_NESTED_RECORDS_STACK_MODAL
> & {
  data: Partial<NestedRecordsStackModalData>;
  modalId: NestedObjectRecordsModalTypes;
};

export type NestedObjectsAction =
  | SetNestedObjectsAction
  | SetNestedObjectsFetchingAction
  | AppendNestedObjectsAction
  | ResetNestedObjectsAction
  | SetNestedObjectsColumnsAction
  | ResetObjectRecordsColumnsAction
  | ClearNestedObjectsAction
  | SetObjectsSelectedColumnsAction
  | SetNestedObjectRecordsRestrictionsAction
  | SetSelectedRowAction
  | SetNestedObjectRecordsErrorAction
  | PushStackAction
  | PopStackAction
  | PopManyStackAction
  | ClearStackAction
  | UpdateStackElement
  | RemoveNodeAction
  | RemoveTreeChildAction
  | ReplaceTreeNodeAction
  | AddTreeChildAction
  | ClearTreeAction
  | UpdateNestedRecordsStackModalAction;

export const setNestedObjectRecords = (
  list: ObjectRecordDetails[],
  total: number,
  filtered: number,
  classID: string
): SetNestedObjectsAction => {
  return {
    type: SET_NESTED_OBJECT_RECORDS,
    list,
    total,
    filtered,
    classID,
  };
};

export const appendNestedObjectRecords = (
  list: ObjectRecordDetails[],
  total: number,
  filtered: number,
  classID: string
): AppendNestedObjectsAction => {
  return {
    type: APPEND_NESTED_OBJECT_RECORDS,
    list,
    total,
    filtered,
    classID,
  };
};

export const setFetchingNestedObjectRecords = (
  fetching: boolean,
  classID: string
): SetNestedObjectsFetchingAction => {
  return {
    type: SET_NESTED_OBJECT_RECORDS_FETCHING,
    fetching,
    classID,
  };
};

export const resetNestedObjectRecords = (
  classID: string
): ResetNestedObjectsAction => {
  return {
    type: RESET_NESTED_OBJECT_RECORDS,
    classID,
  };
};

export const clearNestedObjectRecords = (classID: string) => {
  return {
    type: CLEAR_NESTED_OBJECT_RECORDS,
    classID,
  };
};

export const resetNestedObjectRecordsColumns = (
  classID: string
): ResetObjectRecordsColumnsAction => {
  return {
    type: RESET_NESTED_OBJECT_RECORDS_COLUMNS,
    classID,
  };
};

export const setNestedObjectRecordsRestrictions = (
  restrictions: Restrictions,
  classID: string
): SetNestedObjectRecordsRestrictionsAction => ({
  type: SET_NESTED_OBJECT_RECORDS_RESTRICTIONS,
  payload: restrictions,
  classID,
});

export const setNestedObjectRecordError = (
  payload: ResponseError,
  classID: string
): SetNestedObjectRecordsErrorAction => ({
  type: SET_NESTED_OBJECT_RECORDS_ERROR,
  payload,
  classID,
});

export const getNestedObjectRecords = ({
  classId,
  queryParams,
  fetchType = DataFetchType.Overwrite,
  parentRecordId,
  objectModelId = DEFAULT_OBJECT_MODEL_ID,
}: GetNestedObjectRecordsParams): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  dispatch(setFetchingNestedObjectRecords(true, classId));

  try {
    const { status, data } = await apiCall.get<ObjectRecordChildrenRepsonse>(
      createTableUrl(
        generatePath(OBJECT_RECORD_CHILDREN, {
          id: objectModelId,
          parentRecordId,
        }),
        `${
          !!queryParams ? queryParams + '&' : ''
        }object_record__object_class=${classId}`
      )
    );

    const {
      status: totalCountStatus,
      data: totalCountData,
    } = await apiCall.get(
      createTableUrl(
        generatePath(OBJECT_RECORD_CHILDREN, {
          id: objectModelId,
          parentRecordId,
        }),
        `object_record__object_class=${classId}&limit=0`
      )
    );

    const results = data.results.map(result => ({
      ...result.object_record,
      _meta: { ...result.object_record._meta, ...result._meta },
    })) as ObjectRecordDetails[];

    if (status === StatusCodes.OK && totalCountStatus === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setNestedObjectRecords(
            results,
            totalCountData.filtered_count,
            data.filtered_count,
            classId
          )
        );
      } else {
        dispatch(
          appendNestedObjectRecords(
            results,
            totalCountData.filtered_count,
            data.filtered_count,
            classId
          )
        );
      }
    }
  } catch (error) {
    const { response: { status = undefined } = {} } = error as AxiosError;
    if (status === StatusCodes.FORBIDDEN) {
      dispatch(getPermissions());
    }
    if (status === StatusCodes.NOT_FOUND) {
      dispatch(setNestedObjectRecords([], 0, 0, classId));
      return;
    } else {
      showUnhandledErrorToast(error);
    }
  } finally {
    dispatch(setFetchingNestedObjectRecords(false, classId));
  }
};

export const getSelectedObjectRecords = ({
  classId,
  queryParams,
  fetchType = DataFetchType.Overwrite,
  ids,
  overrideTotalCount,
}: GetSelectedObjectRecordsParams): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  dispatch(setFetchingNestedObjectRecords(true, classId));

  if (!ids?.length) {
    dispatch(setNestedObjectRecords([], 0, 0, classId));
    dispatch(setFetchingNestedObjectRecords(false, classId));
    return;
  }

  const reversedIds = [...ids].reverse();

  try {
    const { status, data } = await apiCall.get<ObjectRecordDetailsResponse>(
      createTableUrl(
        OBJECT_RECORD_LIST,
        `${
          !!queryParams ? queryParams + '&' : ''
        }object_class=${classId}&id__in=${reversedIds.join(',')}`
      )
    );

    const results = data.results
      .map(result => ({
        ...result,
        permissions: result._meta.permissions,
      }))
      .sort(
        (a, b) =>
          reversedIds.indexOf(Number(a.id)) - reversedIds.indexOf(Number(b.id))
      );

    if (status === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setNestedObjectRecords(
            results,
            overrideTotalCount ?? ids.length,
            data.filtered_count,
            classId
          )
        );
      } else {
        dispatch(
          appendNestedObjectRecords(
            results,
            overrideTotalCount ?? data.results.length,
            data.filtered_count,
            classId
          )
        );
      }
    }
  } catch (error) {
    const { response: { status = undefined } = {} } = error as AxiosError;
    if (status === StatusCodes.FORBIDDEN) {
      dispatch(getPermissions());
    }
    if (status === StatusCodes.NOT_FOUND) {
      dispatch(setNestedObjectRecords([], 0, 0, classId));
      return;
    } else {
      showUnhandledErrorToast(error);
    }
  } finally {
    dispatch(setFetchingNestedObjectRecords(false, classId));
  }
};

export const setNestedObjectRecordsColumns = (
  columns: ColumnsMetadata[],
  classID: string
): SetNestedObjectsColumnsAction => {
  return {
    type: SET_NESTED_OBJECT_RECORDS_COLUMNS,
    payload: columns,
    classID,
  };
};

export const setNestedObjectRecordsSelectedColumns = (
  columns: FormBuilderField[],
  classID: string
): SetObjectsSelectedColumnsAction => {
  return {
    type: SET_NESTED_OBJECT_RECORDS_SELECTED_COLUMNS,
    classID,
    columns,
  };
};

export const getNestedObjectRecordsColumnConfiguration = (
  classID: string,
  columnConfiguration?: ChildClassColumns
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const classFields = columnConfiguration
      ?.filter(({ id }) => id.includes(FIELD_PREFIX))
      .map(({ id }) => id.replace(FIELD_PREFIX, ''));

    const classFieldsRequest = classFields?.length
      ? apiCall.get(
          generatePath(`${OBJECT_CLASS_DETAILS_FIELDS}`, {
            id: classID,
          }),
          {
            params: {
              [`id__in`]: classFields?.join(','),
            },
          }
        )
      : undefined;

    const [
      recordOptionsResponse,
      fieldsDetailsResponse,
      predicatesResponse,
    ] = await Promise.all([
      apiCall.options<OptionsResponse>(OBJECT_RECORD_LIST),
      classFieldsRequest,
      apiCall.get(
        generatePath(OBJECT_CLASS_FIELDS_AUTOCOMPLETE, {
          id: classID,
        })
      ),
    ]);

    const predicates = Object.fromEntries(
      (predicatesResponse.data.results ?? [])?.map(
        ({
          value,
          values,
          predicates,
        }: {
          value: string;
          values: {
            value: string;
            text: string;
          }[];
          predicates: string[] | null;
        }) => [value, { values, predicates }]
      )
    );

    const {
      status,
      data: {
        list: { columns: recordColumns },
        restrictions,
      },
    } = recordOptionsResponse;

    const filteredRecordColumns = recordColumns.reduce(
      (acc: ColumnsMetadata[], col) => {
        const recordConfiguration = columnConfiguration?.find(
          ({ id }) => id === col.alias
        );

        if (recordConfiguration) {
          return [
            ...acc,
            {
              ...col,
              width: recordConfiguration.width,
              order: columnConfiguration?.indexOf(recordConfiguration),
            },
          ];
        }

        return acc;
      },
      []
    );

    const { status: fieldsStatus, data } = fieldsDetailsResponse || {};

    if (restrictions)
      dispatch(setNestedObjectRecordsRestrictions(restrictions, classID));

    const fieldsColumns = (data?.results || [])?.map(
      ({ alias, label, type, id: fieldId }: FormBuilderField) => {
        const configuration = columnConfiguration?.find(
          ({ id }) => id.replace(FIELD_PREFIX, '') === fieldId.toString()
        );

        return {
          alias: `${FIELD_PREFIX}${alias}`,
          label,
          type,
          order: configuration
            ? columnConfiguration?.indexOf(configuration)
            : 0,
          width: configuration?.width,
          predicates: predicates[`${FIELD_PREFIX}${alias}`]?.predicates ?? [],
          values: predicates[`${FIELD_PREFIX}${alias}`]?.values ?? [],
          sort_ok: false,
          groupKey: CustomTableGroupKeys.ObjectClassFields,
        };
      }
    );

    dispatch(setNestedObjectRecordsSelectedColumns(fieldsColumns, classID));

    const columns = [...filteredRecordColumns, ...fieldsColumns];

    if (isStatusOk(classID, status, fieldsStatus ?? 200)) {
      dispatch(setNestedObjectRecordsColumns(columns, classID));
    }
  } catch (error) {
    dispatch(setNestedObjectRecordError(error?.response, classID));
  }
};

export const setNestedObjectRecordsSelectedRow = (
  data: string | undefined
): SetSelectedRowAction => ({
  type: SET_NESTED_OBJECT_RECORDS_SELECTED_ROW,
  selectedRow: data,
});

export const pushStack = (
  element: NestedObjectRecordsStackElement
): PushStackAction => ({
  type: PUSH_STACK,
  element,
});

export const pushStackWithTreeUpdate = (
  element: NestedObjectRecordsStackElement
) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const path = parseStackToPath(getState().nestedObjectRecords.stack);

    const root = getState().nestedObjectRecords.tree;
    const node: NestedRecordData = {
      nodeId:
        element.recordId?.toString() ??
        `${NOT_CREATED_NODE_PREFIX}${element.classId}`,
      classId: element.classId,
      isCreated: !!element.recordId,
      isRelationshipCreated: false,
      children: {},
      recordId: element.recordId?.toString(),
    };

    dispatch(pushStack(element));
    dispatch(addChildAction({ path, root, node }));
  };
};

export const popStackWithTreeUpdate = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    verifyNestedRecordsUnsavedChanges(
      dispatch,
      getState().nestedObjectRecords.stack,
      NestedObjectRecordsModalTypes.CANCEL_CONFIG_MODAL,
      () => {
        dispatchPopStack(dispatch, getState);
      }
    );
  };
};

export const popStackWithTreeUpdateNoPopup = () => {
  return (dispatch: AppDispatch, getState: () => RootState) =>
    dispatchPopStack(dispatch, getState);
};

const dispatchPopStack = (dispatch: AppDispatch, getState: () => RootState) => {
  const path = parseStackToPath(getState().nestedObjectRecords.stack);
  const root = getState().nestedObjectRecords.tree;
  const topNode = findByPath({ root, path });

  if (!topNode?.isCreated) {
    dispatch(removeNodeAction({ path, root }));
  }
  dispatch(popStack());
};

export const addChildToTopOfStack = (nodeData: {
  classId: AddChildParams['node']['classId'];
  recordId: AddChildParams['node']['recordId'];
}) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const path = parseStackToPath(getState().nestedObjectRecords.stack);
    const root = getState().nestedObjectRecords.tree;
    const isCreated = !!nodeData.recordId;

    const node = {
      nodeId: isCreated
        ? nodeData.recordId ?? ''
        : `${NOT_CREATED_NODE_PREFIX}${nodeData.classId}`,
      classId: nodeData.classId,
      isCreated,
      isRelationshipCreated: false,
      recordId: nodeData.recordId,
      children: {},
    };

    dispatch(addChildAction({ path, root, node }));
  };
};

export const removeChildFromTopOfStack = (nodeId: string) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const path = parseStackToPath(getState().nestedObjectRecords.stack);
    const root = getState().nestedObjectRecords.tree;

    dispatch(removeChildAction({ path, root, childId: nodeId }));
  };
};

export const replaceTreeNodeWithPop = (data: NestedRecordData) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const stack = getState().nestedObjectRecords.stack;
    const path = parseStackToPath(stack);
    const root = getState().nestedObjectRecords.tree;
    dispatch(replaceTreeNodeAction({ path, root, data }));

    if (stack.length > 1) {
      dispatch(popStack());
    }
  };
};

export const updateStackElement = (
  newData: Partial<NestedObjectRecordsStackElement>,
  classId: string
): UpdateStackElement => ({
  type: UPDATE_STACK_ELEMENT,
  newData,
  classId,
});

export const popStack = (): PopStackAction => ({
  type: POP_STACK,
});

export const clearStack = (): ClearStackAction => ({
  type: CLEAR_STACK,
});

export const popManyAction = (index: number): PopManyStackAction => ({
  type: POP_MANY_STACK,
  index,
});
export const popManyStack = (index: number) => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  verifyNestedRecordsUnsavedChanges(
    dispatch,
    getState().nestedObjectRecords.stack,
    NestedObjectRecordsModalTypes.CANCEL_CONFIG_MODAL,
    () => {
      dispatch(popManyAction(index));
    }
  );
};

export const addChildAction = (params: AddChildParams): AddTreeChildAction => ({
  type: ADD_TREE_CHILD,
  ...params,
});

export const removeNodeAction = (
  params: RemoveNodeParams
): RemoveNodeAction => ({
  type: REMOVE_TREE_NODE,
  ...params,
});

export const removeChildAction = (
  params: RemoveChildParams
): RemoveTreeChildAction => ({
  type: REMOVE_TREE_CHILD,
  ...params,
});

export const replaceTreeNodeAction = (
  params: ReplaceNodeParams
): ReplaceTreeNodeAction => ({
  type: REPLACE_TREE_NODE,
  ...params,
});

export const clearTree = (): ClearTreeAction => ({
  type: CLEAR_TREE,
});

export const updateNestedRecordsStackModal = (
  data: Partial<NestedRecordsStackModalData>,
  modalId: NestedObjectRecordsModalTypes
): UpdateNestedRecordsStackModalAction => ({
  type: UPDATE_NESTED_RECORDS_STACK_MODAL,
  data,
  modalId,
});
