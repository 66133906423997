import React, { useCallback } from 'react';
import { Loader } from 'components/lib/Loader';
import AddUsersPanel from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/AddUsersPanel';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { AvatarItem } from 'components/lib/Avatar/types';

import {
  AddDefaultAssigneesProps,
  SequenceTaskDefaultAssigneesPanelModes,
} from '../types';
import { useSequenceDefaultTaskAssigneesOptions } from '../../SequenceForm/hooks';
import ErrorComponent from 'components/ErrorComponent';
import { StageNames } from 'utils/types/api/tasks.types';
import { selectLimitForUserGroup } from 'components/SidePanels/TasksPanel/components/ReassignTask/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSequenceDefaultTaskAssignees } from 'store/selectors/sequencesSelectors';
import { DefaultSequenceTaskAssignees } from 'utils/types/api/sequences.types';
import { setDefaultTaskAssignees } from 'store/actions/sequencesActions';
import useDefaultAssigneesStyles from './styles';
import { useIntl } from 'react-intl';
import { SEQUENCE_ASSIGNEES, SEQUENCE_STAGE_ASSIGNEES } from 'utils/endpoints';
import { generatePath, useParams } from 'react-router-dom';
import useData from 'hooks/useData';
import { StageDefaultAssignees } from '../../SequenceForm/types';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';

const AddDefaultAssignees: React.FC<AddDefaultAssigneesProps> = ({
  setMode,
}) => {
  const classes = useDefaultAssigneesStyles({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: sequenceId } = useParams<{ id: string }>();
  const {
    defaultTaskAssigneesOptions: {
      [StageNames.Stage1]: {
        autocomplete = '',
        limit_assignees_one_time_completion: oneTCLimit = undefined,
        limit_assignees_standard: standardLimit = undefined,
      } = {},
    } = {},
    loading,
    error,
  } = useSequenceDefaultTaskAssigneesOptions();

  const [, { fetchData }] = useData<StageDefaultAssignees[]>(
    sequenceId
      ? generatePath(SEQUENCE_ASSIGNEES, {
          id: sequenceId,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  const { results = [], loading: assigneesLoading } =
    useSelector(getSequenceDefaultTaskAssignees) ||
    ({} as DefaultSequenceTaskAssignees);

  const itemsLimit =
    selectLimitForUserGroup(results, oneTCLimit, standardLimit) ?? 100;

  const handleSubmit = useCallback(
    (selectedUsers: AvatarItem[]) => {
      dispatch(setDefaultTaskAssignees(selectedUsers));
    },
    [dispatch]
  );
  const fetchOwners = useCallback(async () => {
    try {
      const { data } = await fetchData();
      handleSubmit([
        ...(data
          ?.find(stage => stage.name === StageNames.Stage1)
          ?.assignees.users.map(
            (userAvatar): AvatarItem => ({
              firstName: userAvatar.first_name,
              lastName: userAvatar.last_name,
              email: userAvatar.username,
              ...userAvatar,
            })
          ) ?? []),
      ]);
    } catch (error) {
      showUnhandledErrorToast(error);
    }
  }, [fetchData, handleSubmit]);

  if (error?.status) return <ErrorComponent error={error.status} />;

  return (
    <div className={classes.addDefaultAssigneesPanel}>
      <Loader spinning={loading || assigneesLoading}>
        <AddUsersPanel<{ users: AvatarItem[] }>
          {...{ itemsLimit, fetchOwners }}
          postApiEndpoint={
            sequenceId
              ? generatePath(SEQUENCE_STAGE_ASSIGNEES, {
                  id: sequenceId,
                  stageId: StageNames.Stage1,
                })
              : ''
          }
          avatars={results}
          usersCount={results.length}
          postDataKey={'users'}
          shouldFetchOnSuccess={true}
          panelKey={FlexLayoutWindows.SequenceDefaultAssignees}
          autocompleteUrl={autocomplete}
          onCancelClick={() =>
            setMode(SequenceTaskDefaultAssigneesPanelModes.List)
          }
          // without immediate API call - temporary state of newly added users
          onSubmit={
            sequenceId
              ? undefined
              : selectedUsers => handleSubmit([...results, ...selectedUsers])
          }
          defaultMode={SequenceTaskDefaultAssigneesPanelModes.List}
          alreadyAddedDisabledMessage={intl.formatMessage({
            id: 'misc.alreadyAdded',
            defaultMessage: 'Already added',
          })}
        />
      </Loader>
    </div>
  );
};

export default AddDefaultAssignees;
