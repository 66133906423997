import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import {
  COMFORTABLE_DENSITY_HEADER,
  COMPACT_DENSITY_HEADER,
  DEFAULT_DENSITY_HEADER,
} from 'components/CatalystTable/CatalystTable.consts';

export const getHeaderCellHeight = (density?: TableDensity) => {
  switch (density) {
    case TableDensity.Comfortable:
      return COMFORTABLE_DENSITY_HEADER;
    case TableDensity.Compact:
      return COMPACT_DENSITY_HEADER;
    default:
      return DEFAULT_DENSITY_HEADER;
  }
};
