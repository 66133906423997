import { FilterValue } from 'store/actions/filtersActions';
import { Logger, LogLevel } from 'utils/logger';

/**
 * Tries to remove invalid filter value entries from the filter selected value if applicable.
 * For example, removing one of the wrong selected values in multi select filter.
 * @param wrongValue Value that should be removed.
 * @param filter Filter to be corrected.
 */
export const correctFilterSelectedValues = (
  wrongValue: string,
  filter: FilterValue
) => {
  const filterValue = filter.value.predicateValues.value;

  Logger.log(
    `Correcting filter selected values.`,
    LogLevel.Verbose,
    filterValue
  );

  // multi select field.
  if (filter.type === 'set') {
    filter.value.predicateValues.value = (filterValue as string[]).filter(
      value => value !== wrongValue
    );

    Logger.log(
      `Found invalid selected value entry. Removing value: ${wrongValue}.`,
      LogLevel.Verbose,
      filter.value.predicateValues.value
    );
  }
};
