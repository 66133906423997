import React from 'react';
import { ALERT_TYPE_TESTID } from 'utils/testIds';
import useLimitMessage from './hooks';
import { useLimitMessageStyles } from './styles';
import { LimitMessageProps } from './types';
import { Alert } from 'components/lib/Alert';

const LimitMessage = (props: LimitMessageProps) => {
  const { message, notificationType } = useLimitMessage(props);
  const styles = useLimitMessageStyles();

  if (!notificationType) {
    return null;
  }

  return (
    <div
      className={styles.wrapper}
      data-testid={`${ALERT_TYPE_TESTID}${notificationType}`}
    >
      <Alert type='error' message={message} className={styles.alert} />
    </div>
  );
};

export default LimitMessage;
