import { ActionCellProps } from 'components/CatalystTable/types/actionCellProps';
import { useCallback, useEffect, useState } from 'react';
import { TABLE_BODY_ID } from 'utils/elementsIds';

export const useSelectedRow = <T, U extends ActionCellProps>(
  actions?: MappedObject<(data: U) => void, T>
) => {
  const [selectedAction, setSelectedAction] = useState<U | undefined>();

  const onActionCellClick = useCallback((data: U) => {
    setSelectedAction(data);
  }, []);

  const closeActionsMenu = useCallback(() => {
    if (selectedAction !== undefined) setSelectedAction(undefined);
  }, [selectedAction]);

  const onActionMenuOptionClick = (key: T) => {
    if (actions && actions[key] && selectedAction) {
      actions[key](selectedAction);
    }

    closeActionsMenu();
  };

  const handleClosingMenu = useCallback(
    (e: MouseEvent) => {
      const elem = e.target as HTMLElement;

      const isActionMenuElement =
        elem.getAttribute('data-actions-item') ||
        elem.parentElement?.getAttribute('data-actions-item');

      if (!isActionMenuElement) {
        closeActionsMenu();
      }
    },
    [closeActionsMenu]
  );

  useEffect(() => {
    window.document.addEventListener('mousedown', handleClosingMenu);
    const tableBody = window.document.getElementById(TABLE_BODY_ID);

    if (tableBody) tableBody.addEventListener('scroll', closeActionsMenu);

    return () => {
      window.document.removeEventListener('mousedown', handleClosingMenu);

      if (tableBody) tableBody.removeEventListener('scroll', closeActionsMenu);
    };
  }, [closeActionsMenu, handleClosingMenu]);

  return {
    selectedAction,
    onActionCellClick,
    onActionMenuOptionClick,
  };
};
