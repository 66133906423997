import clsx from 'clsx';
import useFormPreviewStyles from 'components/FormPreview/styles';
import React from 'react';
import { MinMaxInfoLabelType, MinMaxInfoProps } from './types';
import { useMinMaxInfoMessage } from './hooks';

const MinMaxInfo: React.FC<MinMaxInfoProps> = ({
  minimum,
  maximum,
  checkboxField,
  className,
  labelType = MinMaxInfoLabelType.Default,
}) => {
  const styles = useFormPreviewStyles();

  const isMinNumberSet = !isNaN(Number(minimum));
  const isMaxNumberSet = !isNaN(Number(maximum));

  const { minMessage, maxMessage } = useMinMaxInfoMessage(
    minimum,
    maximum,
    checkboxField ?? false,
    labelType
  );

  if (isMaxNumberSet || isMinNumberSet) {
    return (
      <div className={clsx(styles.description, className)}>
        {isMinNumberSet && !isMaxNumberSet && minMessage}
        {isMaxNumberSet && !isMinNumberSet && maxMessage}
        {isMinNumberSet && isMaxNumberSet && (
          <div className={styles.maxMinWrapper}>
            <div>{minMessage},</div>
            <div>{maxMessage}</div>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default MinMaxInfo;
