import axios from 'axios';
import { useCancelToken } from 'hooks/useCancelToken';
import { useCallback, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { TASKS_DETAILS } from 'utils/endpoints';
import { TaskDetails } from 'utils/types/api/tasks.types';

export const useTaskDetails = () => {
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const [taskDetails, setTaskDetails] = useState<TaskDetails>();
  const [errorStatus, setErrorStatus] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  const clearTaskDetails = useCallback(() => {
    setTaskDetails(undefined);
  }, []);

  const fetchTaskDetails = useCallback(
    async (taskId: string) => {
      try {
        setIsLoading(true);
        setErrorStatus(undefined);
        cancelOngoingRequests();

        const cancelToken = createCancelToken();
        const endpoint = generatePath(TASKS_DETAILS, { id: taskId });

        const { data } = await apiCall.get<TaskDetails>(endpoint, {
          cancelToken,
        });

        setTaskDetails(data);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          return;
        }

        if (axios.isAxiosError(error)) {
          setErrorStatus(error.response?.status);
        }

        setIsLoading(false);
      }
    },
    [cancelOngoingRequests, createCancelToken]
  );

  return {
    taskDetails,
    isLoading,
    errorStatus,
    clearTaskDetails,
    fetchTaskDetails,
  };
};
