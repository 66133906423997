import { useIntl } from 'react-intl';
import {
  EventData,
  EventFieldType,
  EventType,
  Items,
} from '../../../../../types';
import {
  isUserFieldValueData,
  isDocumentData,
  isOwnersData,
  isAssigneesData,
  isFieldValueData,
} from '../../../../../utils';
import {
  mapUserEvent,
  nameOrDefaultValue,
  prepareUserFieldValueData,
} from '../utils';

export const useEventItems = (eventData: EventData, eventType: EventType) => {
  const intl = useIntl();

  if (isUserFieldValueData(eventData, eventType)) {
    return prepareUserFieldValueData(eventData);
  }

  if (isDocumentData(eventData)) {
    return {
      items: eventData.map(e => ({
        id: e.document_template_id,
        value: e.file_name,
        fileId: e.file_id,
      })),
      value: '',
    };
  }

  if (isOwnersData(eventData)) {
    const items = eventData.map(mapUserEvent);

    return { items, value: '' };
  }

  if (isAssigneesData(eventData)) {
    const items = eventData.assignees.map(mapUserEvent);
    const { id, name } = eventData.permission_set;
    const value = nameOrDefaultValue(
      name,
      intl.formatMessage(
        {
          id: 'activityLog.deletedPermission',
          defaultMessage: 'Deleted permission set {id}',
        },
        { id }
      )
    );

    return { items, value };
  }

  if (isFieldValueData(eventData) && eventData.length > 0) {
    const { id, value, name, type, value_labels: valueLabels } = eventData[0];
    let items: Items[] = [{ id, value }];
    const fieldValue = nameOrDefaultValue(
      name,
      intl.formatMessage(
        {
          id: 'activityLog.deletedField',
          defaultMessage: 'Deleted field {id}',
        },
        { id }
      )
    );

    if (type === EventFieldType.set && typeof value === 'string') {
      const values: string[] = JSON.parse(value);
      items = values.map(item => ({ id: item, value: item }));
    }

    if (
      type === EventFieldType.document &&
      Array.isArray(valueLabels) &&
      Array.isArray(value)
    ) {
      items = value.map((id, i) => ({ id: id, value: valueLabels[i] }));
    }

    return { items, value: fieldValue, fieldType: type };
  }

  return { items: [], value: '', fieldType: undefined };
};
