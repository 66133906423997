export enum TasksQuickFilterDates {
  Default = '',
  NoDueDate = 'isnull',
  Today = 'today',
  ThisWeek = 'this_week',
  WithinAWeek = 'within_week',
  Within30Days = 'within_30_days',
  Yesterday = 'yesterday',
  WithinTheLastWeek = 'within_last_week',
  WithinTheLast30Days = 'within_last_30_days',
  ExpiredTasks = 'expired_tasks',
}
