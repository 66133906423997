// For some reason EsLint with JSDoc plugin does not recognize the usage of this import in the @link tag and provides
// a warning in the console. That's why I'm adding disable next line here.
// eslint-disable-next-line
import { UsersAndGroupsExpandableSelect } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect';

/**
 * Defines the absolute maximum amount of how many users can be chosen in the {@link UsersAndGroupsExpandableSelect} at most if no
 * other limits are set.
 */
export const USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION = 100;
