import React, { useEffect } from 'react';
import { CustomValueDisplayDocumentsProps } from './CustomValueDisplayDocuments.types';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'components/lib/Dropdown';
import { FILES_PREVIEW } from 'utils/testIds';
import { TABLE_BODY_ID } from 'utils/elementsIds';
import { useToggle } from 'hooks/useToggle';
import { useCustomValueDisplayDocumentsStyles } from './CustomValueDisplayDocuments.styles';
import { DocumentList } from './components/DocumentList';

export const CustomValueDisplayDocuments = ({
  value,
  recordId,
}: CustomValueDisplayDocumentsProps) => {
  const styles = useCustomValueDisplayDocumentsStyles();
  const [
    visible,
    { toggleOff: hideDropdown, toggle: toggleDropdown },
  ] = useToggle(false);

  useEffect(() => {
    const tableBody = window.document.getElementById(TABLE_BODY_ID);

    if (visible && tableBody)
      tableBody.addEventListener('scroll', hideDropdown);

    return () => {
      if (visible && tableBody)
        tableBody.removeEventListener('scroll', hideDropdown);
    };
  }, [visible, hideDropdown]);

  if (value.length === 0) {
    return <>-</>;
  }

  return (
    <Dropdown
      {...{ visible }}
      trigger={['click']}
      overlay={<DocumentList {...{ value, recordId }} />}
      onVisibleChange={toggleDropdown}
    >
      <span
        className={styles.fileLink}
        onClick={event => event.stopPropagation()}
        data-testid={FILES_PREVIEW}
        id={`dropdown-${recordId}`}
      >
        <FormattedMessage
          id='misc.documentsNumber'
          defaultMessage='{count, plural, one {# document} few {# documents} other {# documents}}'
          values={{ count: value?.length }}
        />
      </span>
    </Dropdown>
  );
};
