import { FilterValue } from 'store/actions/filtersActions';
import { Predicate } from 'utils/types/predicates.types';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import { getFilterQueryBase } from './utils/getFilterQueryBase';
import { isUsersAndGroupsFilter } from './utils/isUsersAndGroupsFilter';
import { parseFilterValue } from './utils/parseFilterValue';
import { parseUserAndGroupFilterType } from './utils/parseUserAndGroupFilterType';
import { predicateValuesMapFunction } from './utils/predicateValuesMapFunction';

/**
 * Parses provided filter condition to query param that can be later on added to query string.
 *
 * @param filter - Filter condition to parse.
 * @param predicate - Selected filter predicate.
 *
 * @returns Valid query param based on provided filter condition.
 */
export const parseFilterToQueryParam = (
  filter: FilterValue,
  predicate: Predicate
) => {
  const filterValues = filter.value.predicateValues;
  const queryArgs = predicate.args;

  const queryBase = getFilterQueryBase(filter, predicate.query);

  if (isUsersAndGroupsFilter(filter)) {
    return parseUserAndGroupFilterType(
      filterValues['value'] as SelectUserAndGroupOption | undefined,
      queryBase,
      queryArgs
    );
  }

  return queryArgs.reduce((query, arg) => {
    let escapedValues;

    if (Array.isArray(filterValues[arg])) {
      escapedValues = (filterValues[arg] as []).map(predicateValuesMapFunction);
    } else {
      escapedValues = filterValues[arg];
    }

    const predicateValue = parseFilterValue(escapedValues);

    return query.replace(
      `{${arg}}`,
      encodeURIComponent(predicateValue as string)
    );
  }, queryBase);
};
