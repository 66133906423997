import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseFileUploadStylesProps = {
  small?: boolean;
  limit?: boolean;
  readonly?: boolean;
};

const useFileUploadStyles = createUseStyles((theme: ThemeState) => {
  return {
    dnd: ({ small, limit, readonly }: UseFileUploadStylesProps = {}) => ({
      '& .ant-upload': {
        fontSize: 12,
      },
      '&.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover': {
        borderColor: theme['primary-color'],
      },
      '&.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon': {
        color: theme['primary-color'],
      },
      '&.ant-upload.ant-upload-drag': {
        display: limit || readonly ? 'none' : 'block',
        backgroundColor: 'white',
      },
      '& > span': {
        padding: small ? '9px 18px !important' : '10px 0px !important',
      },
    }),
    listItem: ({ small }: UseFileUploadStylesProps = {}) => ({
      border: `1px solid ${theme['placeholder-border-color']}`,
      width: '100%',
      borderRadius: '5px',
      marginTop: small ? 5 : '4px',
      padding: small ? '4px 4px 3px 9px' : '4px 6px 4px 12px',
      fontSize: '0.75rem',
      display: 'grid',
      gap: '8px',
      alignItems: 'center',
      justifyItems: 'center',
      gridTemplateColumns: '1fr min-content',
      backgroundColor: '#FFFFFF',
    }),
    itemTextWrapper: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    item: {
      display: 'block',
      overflow: 'hidden',
      wordBreak: 'unset',
      fontWeight: 600,
      textOverflow: 'ellipsis',
    },
    '@global [data-small-column="true"] [data-grid-item]>*': {
      gridColumn: 'span 2',
    },
    contentWrapper: ({ small }: UseFileUploadStylesProps = {}) => ({
      display: 'flex',
      justifyContent: small ? 'flex-start' : 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingLeft: 10,
      paddingRight: 10,
      padding: small ? '0px 0px !important' : '0px 10px',
      gridGap: 10,
    }),
    textWrapper: {
      fontWeight: 600,
      textAlign: 'left',
    },
    paragraphSecondary: {
      display: 'inline',
      color: theme['link-color'],
    },
    paragraphDescription: {
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '12px',
      color: theme['text-color-secondary'],
    },
  };
});

export default useFileUploadStyles;
