import { FC, useCallback, useRef } from 'react';
import { DEFAULT_COLUMNS_WIDTHS } from '../../CatalystTable.consts';
import { getWidth } from './utils/getWidth';
import { ResizeProviderProps } from './ResizeProvider.types';
import { getColumnVarName } from 'components/CatalystTable/utils/getColumnVarName';

export const ResizeProvider: FC<ResizeProviderProps> = ({
  children,
  columnKey,
  wrapperRef,
  onColumnResize,
}) => {
  const startX = useRef(0);
  const startWidth = useRef(0);
  const currentWidth = useRef(0);
  const cellRef = useRef<HTMLDivElement>(null);

  const setColumnWidth = useCallback(
    (columnKey: string, width: number) => {
      if (wrapperRef.current)
        wrapperRef.current.style.setProperty(
          getColumnVarName(columnKey),
          `${width}px`
        );

      currentWidth.current = width;
    },
    [wrapperRef]
  );

  const drag = useCallback(
    e => {
      const width = startWidth.current + e.clientX - startX.current;

      setColumnWidth(
        columnKey,
        width > DEFAULT_COLUMNS_WIDTHS.minWidth
          ? width
          : DEFAULT_COLUMNS_WIDTHS.minWidth
      );
    },
    [columnKey, setColumnWidth]
  );

  const stopDrag = useCallback(() => {
    document.body.removeEventListener('mousemove', drag, false);
    document.body.removeEventListener('mouseup', stopDrag, false);
    document.body.style.setProperty('cursor', `initial`);

    onColumnResize(columnKey, currentWidth.current);
  }, [drag, onColumnResize, columnKey]);

  const initDrag = useCallback(
    e => {
      startX.current = e.clientX;
      startWidth.current = getWidth(cellRef.current);

      document.body.addEventListener('mousemove', drag, false);
      document.body.addEventListener('mouseup', stopDrag, false);
      document.body.style.setProperty('cursor', `col-resize`);
    },
    [drag, stopDrag]
  );

  return children ? children({ cellRef, initDrag }) : null;
};
