import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFilterUserValueStyles = createUseStyles(
  (theme: ThemeState) => ({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '10px',
      position: 'relative',
    },
    checkmarkIcon: {
      color: theme['primary-color'],
    },
    optionContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',

      '& > div:first-child': {
        marginRight: 6,
      },
    },
  })
);
