import React from 'react';
import { GroupTaskAssignmentUsageViewProps } from './GroupTaskAssignmentUsageView.types';
import { FormattedMessage } from 'react-intl';
import { BoldTextPartsFallback } from 'utils/intl/fallbacks/BoldTextParts';
import useFormatNumber from 'hooks/useFormatNumber';

export const GroupTaskAssignmentUsageView = ({
  numOfTaskAssignments,
}: GroupTaskAssignmentUsageViewProps) => {
  const formatNumberWithSeparators = useFormatNumber();

  return (
    <span>
      <FormattedMessage
        id='userGroups.taskAssignmentsUsage'
        defaultMessage='This user group is assigned to <b>{formattedCount}</b> {count, plural, one {task} other {tasks}}.'
        values={{
          b: BoldTextPartsFallback,
          count: numOfTaskAssignments,
          formattedCount: formatNumberWithSeparators(numOfTaskAssignments),
        }}
      />
    </span>
  );
};
