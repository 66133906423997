export const SET_SINGLE_SIGN_ON = 'SET_SINGLE_SIGN_ON';
export const APPEND_SINGLE_SIGN_ON = 'APPEND_SINGLE_SIGN_ON';
export const SET_SINGLE_SIGN_ON_FETCHING = 'SET_SINGLE_SIGN_ON_FETCHING';
export const SET_TOTAL_SINGLE_SIGN_ON = 'SET_TOTAL_SINGLE_SIGN_ON';
export const RESET_SINGLE_SIGN_ON = 'RESET_SINGLE_SIGN_ON';
export const SET_SINGLE_SIGN_ON_COLUMNS = 'SET_SINGLE_SIGN_ON_COLUMNS';
export const RESET_SINGLE_SIGN_ON_COLUMNS = 'RESET_SINGLE_SIGN_ON_COLUMNS';
export const SET_SINGLE_SIGN_ON_SELECTED_ROW =
  'SET_SINGLE_SIGN_ON_SELECTED_ROW';
export const SET_SINGLE_SIGN_ON_RESTRICTIONS =
  'SET_SINGLE_SIGN_ON_RESTRICTIONS';
