import { SheetsRegistry } from 'react-jss';

type StyleSheet = SheetsRegistry['registry'][number];

class SubscribableSheetsRegistry extends SheetsRegistry {
  subscribers: (() => void)[];

  constructor() {
    super();

    this.subscribers = [];
  }

  add(props: StyleSheet) {
    super.add(props);

    this.subscribers.forEach(subscriber => subscriber());
  }

  reset() {
    super.reset();

    this.subscribers.forEach(subscriber => subscriber());
  }

  remove(props: StyleSheet) {
    super.remove(props);

    this.subscribers.forEach(subscriber => subscriber());
  }

  subscribe(callback: () => void) {
    this.subscribers.push(callback);

    return () => {
      this.subscribers = this.subscribers.filter(
        subscriber => subscriber !== callback
      );
    };
  }
}

export default SubscribableSheetsRegistry;
