import TooltipString from 'components/TooltipString';
import React from 'react';
import { UrlCellProps } from './UrlCell.types';
import { useUrlCellStyles } from './UrlCell.styles';

export const UrlCell = ({ value, label }: UrlCellProps) => {
  const styles = useUrlCellStyles();

  if (!value) {
    return <>-</>;
  }

  return (
    <TooltipString
      text={label ?? value}
      href={value}
      tagName='a'
      target='_blank'
      className={styles.url}
    />
  );
};
