export enum MinMaxInfoLabelType {
  Default = 'default',
  Users = 'users',
  Groups = 'groups',
}

export interface MinMaxInfoProps {
  minimum?: number;
  maximum?: number;
  checkboxField?: boolean;
  className?: string;
  /**
   * Because NPM extract-messages command requires all intl descriptors to be statically resolvable, instead
   * of allowing for any overrides we define an enum that defines which intl labels should be used by MinMaxInfo component.
   */
  labelType?: MinMaxInfoLabelType;
}
