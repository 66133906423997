import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from 'react-router-dom';
import unprotectedRoutes from 'utils/routingUnprotected';
import { useUserData } from 'hooks/useUserData';
import routes, { nonProtectedRoutes } from 'utils/routingPaths';
import LoggedInGlobalActionsWrapper from 'components/wrappers/LoggedInGlobalActionsWrapper';
import LoggedInRouting from './LoggedInRouting';
import useInitTelemetry from 'hooks/useInitTelemetry';
import ClassFormsRouting from './ClassFormsRouting';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from 'utils/api';
import { REFRESH_TOKEN } from 'utils/endpoints';
import { getRefreshToken } from 'store/selectors/authSelectors';
import { setTokens } from 'store/actions/authActions';
import moment from 'moment';

const MainRouting = () => {
  const { isLoggedIn, tokenData } = useUserData();
  const { pathname, search: queryParams = '' } = useLocation();
  const refresh = useSelector(getRefreshToken);
  const dispatch = useDispatch();
  const { exp } = tokenData || {};

  useEffect(() => {
    if (!exp) return;
    // sequence design is a specific case as the sequencer code maintains it's own token
    if (matchPath(pathname, { path: routes.SEQUENCE_DESIGN })) return;
    const expirationDate = moment(exp * 1000);

    const timeoutId = setTimeout(() => {
      apiCall
        .post(REFRESH_TOKEN, {
          refresh,
        })
        .then(res => {
          dispatch(setTokens({ access: res.data?.access }));
        });
    }, expirationDate.diff(moment.now()) - 1000 * 30);
    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refresh, exp]);

  useInitTelemetry();

  return (
    <Switch>
      {unprotectedRoutes.map(({ path, Component }, key) => (
        <Route
          exact
          path={path}
          key={path}
          render={props => {
            if (Component) return <Component {...props} />;

            return null;
          }}
        />
      ))}
      {isLoggedIn && (
        <LoggedInGlobalActionsWrapper>
          <Switch>
            <Route path={routes.CLASS_FORMS}>
              <ClassFormsRouting />
            </Route>
            <Route path='/'>
              <LoggedInRouting />
            </Route>
          </Switch>
        </LoggedInGlobalActionsWrapper>
      )}
      <Route path='*'>
        <Redirect
          to={`${nonProtectedRoutes.LOGIN}${
            !matchPath(nonProtectedRoutes.LOGIN, { path: pathname })
              ? `?path=${encodeURIComponent(pathname + queryParams)}`
              : ''
          }`}
        />
      </Route>
    </Switch>
  );
};

export default MainRouting;
