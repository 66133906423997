import React from 'react';
import { toast } from 'react-toastify';

import { StandardToastContent } from '../components/ToastContent/views/StandardToastContent';
import { ToastText } from '../types/toastText';
import { ToastType } from '../types/toastType';

/**
 * Shows a success toast.
 *
 * @param text The text to display in the toast.
 */
export const showSuccessToast = (text: ToastText) => {
  toast.success(
    <StandardToastContent
      title={text.title}
      subtitle={text.subtitle}
      type={ToastType.Success}
    />
  );
};
