import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';
import ConfirmationModal from 'components/ConfirmationModal';
import { CloseIcon } from 'components/Icon';
import { RemovePermissionSetModalProps } from './types';
import useTokenDecode from 'hooks/useTokenDecode';
import { OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import useSuccessToast from 'hooks/useSuccessToast';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { apiCall } from 'utils/api';
import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { RefetchResources } from 'contexts/types';

const RemovePermissionSetModal = ({
  recordId,
  userId,
  userName,
  permissionSetToDelete,
  closeList,
  setPermissionSetToDelete,
  getUsers,
}: RemovePermissionSetModalProps) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const displayToast = useSuccessToast();
  const { refetchData } = useRefetchResourceContext();

  const { user_id: loggedUserId } = useTokenDecode() || {};

  const isRemovingHimself = loggedUserId === userId;

  const title = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage(
            {
              id: 'misc.removeYourselfFrom',
              defaultMessage: 'Remove yourself from {permissionSetName}?',
            },
            {
              permissionSetName: permissionSetToDelete?.name,
            }
          )
        : intl.formatMessage(
            {
              id: 'misc.removeAssigneeFrom',
              defaultMessage: 'Remove {userName} from {permissionSetName}?',
            },
            {
              userName,
              permissionSetName: permissionSetToDelete?.name,
            }
          ),
    [intl, isRemovingHimself, permissionSetToDelete, userName]
  );

  const subtitle = useMemo(
    () =>
      isRemovingHimself
        ? intl.formatMessage({
            id: 'misc.thisMayRemoveYourAccessToObjectRecord',
            defaultMessage: 'This may remove your access to this record.',
          })
        : intl.formatMessage({
            id: 'misc.theyMayLoseAccessToObjectRecord',
            defaultMessage: 'This may remove their access to this record.',
          }),
    [intl, isRemovingHimself]
  );

  const onClose = () => {
    setPermissionSetToDelete(undefined);
  };

  const onConfirm = async () => {
    if (!userId || !permissionSetToDelete) return;

    setIsLoading(true);

    try {
      const { status } = await apiCall.request({
        method: 'delete',
        url: generatePath(OBJECT_CLASS_RECORD_PERMISSION_SET_ASSIGNEES, {
          recordId,
          permissionSetId: permissionSetToDelete.id,
        }),
        data: [userId],
      });

      if (status === StatusCodes.NO_CONTENT) {
        displayToast();
        refetchData(RefetchResources.RecordAccess);

        if (getUsers) getUsers();
      }
    } catch (error) {
      showUnhandledErrorToast(error);
    } finally {
      setIsLoading(false);
      setPermissionSetToDelete(undefined);
      closeList();
    }
  };

  return (
    <ConfirmationModal
      {...{ isLoading, onClose, onConfirm, title, subtitle }}
      visible={!!permissionSetToDelete}
      confirmationButtonLabel={intl.formatMessage({
        id: 'misc.yesRemove',
        defaultMessage: 'Yes, remove',
      })}
      withCancel
      image={WarningIllustration}
      cancelButtonIcon={<CloseIcon size={18} />}
    />
  );
};

export default RemovePermissionSetModal;
