import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import TooltipString from 'components/TooltipString';
import React from 'react';

export const ObjectClassNameCell = ({ row }: CatalystTableCellProps) => {
  const objectClassName = row?._meta.labels?.object_class;

  return (
    <TooltipString text={objectClassName}>{objectClassName}</TooltipString>
  );
};
