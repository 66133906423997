import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const showGroupAssigneesLimitExceededToast = (
  groupsLimit: number | undefined
) => {
  showErrorToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.error',
      defaultMessage: 'Error!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage(
      {
        id: 'misc.maxGroupAssigneesExceeded',
        defaultMessage:
          'Maximum of {limit} user group {limit, plural, one {assignee} other {assignees}} has been exceeded.',
      },
      { limit: groupsLimit }
    ),
  });
};
