import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import FormField from 'pages/TaskTemplates/components/FormField';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { Col } from 'components/lib/Grid';
import { Select, SelectOption as Option } from 'components/lib/Select';
import { FormikDatePicker } from 'components/lib/DatePicker';
import useDateFormat from 'hooks/useDateFormat';
import moment, { Moment } from 'moment';
import { CREATE_TASK_DUE_DATE } from 'utils/testIds';
import { TaskFormDueDateFieldProps } from './TaskFormDueDateField.types';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { TaskFormType, DueDateOption } from '../../types';
import { useTaskFormDueDateFieldStyles } from './TaskFormDueDateField.styles';

export const TaskFormDueDateField = ({
  rawValidationSchema,
  dueDateOption,
  setDueDateOption,
}: TaskFormDueDateFieldProps) => {
  const intl = useIntl();
  const styles = useTaskFormDueDateFieldStyles();
  const { dateFormat } = useDateFormat();

  const {
    values: { [TaskFormField.DueDate]: dueDate },
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext<TaskFormType>();

  useEffect(() => {
    if (dueDate) {
      return;
    }

    setFieldValue(TaskFormField.DueDate, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDateOption]);

  useEffect(() => {
    if (dueDateOption !== DueDateOption.DueDate || dueDate) {
      return;
    }

    setFieldTouched(TaskFormField.DueDate, true);
    setFieldError(
      TaskFormField.DueDate,
      intl.formatMessage({
        id: 'errors.this_field_may_not_be_blank',
        defaultMessage: 'This field may not be blank',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDateOption, dueDate]);

  return (
    <FormField>
      <div className={styles.dueDateSection}>
        <Col span={12}>
          <FormLabel
            bolded={false}
            required={rawValidationSchema[TaskFormField.DueDate]?.required}
          >
            <FormattedMessage
              id='tasksPanel.form.dueDate'
              defaultMessage='Due date'
            />
          </FormLabel>
          <Select
            defaultValue={DueDateOption.NoDueDate}
            onChange={setDueDateOption}
          >
            <Option
              key={DueDateOption.NoDueDate}
              value={DueDateOption.NoDueDate}
              title={intl.formatMessage({
                id: 'tasksPanel.form.noDueDate',
                defaultMessage: 'No due date',
              })}
            >
              <FormattedMessage
                id='tasksPanel.form.noDueDate'
                defaultMessage='No due date'
              />
            </Option>
            <Option
              key={DueDateOption.DueDate}
              value={DueDateOption.DueDate}
              title={intl.formatMessage({
                id: 'tasksPanel.form.date',
                defaultMessage: 'Date',
              })}
            >
              <FormattedMessage
                id='tasksPanel.form.date'
                defaultMessage='Date'
              />
            </Option>
          </Select>
        </Col>
        {dueDateOption === DueDateOption.DueDate && (
          <Col span={12}>
            <FormikDatePicker
              name={TaskFormField.DueDate}
              className={styles.dueDatePicker}
              format={dateFormat}
              disabledDate={(currentDate: Moment) =>
                moment(currentDate).isBefore(moment(), 'D')
              }
              data-testid={CREATE_TASK_DUE_DATE}
            />
          </Col>
        )}
      </div>
    </FormField>
  );
};
