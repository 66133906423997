import { AxiosError } from 'axios';

type RemovedUsersOrGroupsResponseType = {
  options: {
    users?: number[];
    user_groups?: number[];
  };
};

export const isRemovedUsersOrGroupsError = (
  error: AxiosError
): error is AxiosError<RemovedUsersOrGroupsResponseType> => {
  return (
    error.response?.data?.options?.users ||
    error.response?.data?.options?.user_groups
  );
};
