import { createUseStyles } from 'react-jss';

export const useAddColumnListStyles = createUseStyles({
  wrapper: {
    width: 330,
  },
  heading: { fontWeight: 'bold', marginBottom: 25 },
  checkbox: {
    marginBottom: 20,
    display: 'flex !important',
    height: 20,
    'ant-checkbox + span': {
      overflow: 'hidden',
    },
  },
  buttonsWrapper: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  scroll: {
    overflow: 'auto',
    maxHeight: 400,
  },
  classNameLabel: {
    overflow: 'hidden',
    height: '30px',
    whiteSpace: 'nowrap',
    width: '267px',
    textOverflow: 'ellipsis',
    fontWeight: 600,
  },
});
