import { useMemo } from 'react';
import { Predicate } from 'utils/types/predicates.types';
import {
  useBooleanPredicates,
  useDateTimePredicates,
  useEnumPredicates,
  useNumberPredicates,
  useSetPredicates,
  useStringPredicates,
  useUserAndGroupPredicates,
  useUserPredicates,
} from './hooks';

/**
 * Get all filter predicates for all supported types. Is declared as a hook so that it can support
 * the dynamic language translation of predicate labels.
 *
 * @returns Filter predicates.
 */
export const usePredicates = () => {
  const { booleanPredicates } = useBooleanPredicates();
  const { dateTimePredicates } = useDateTimePredicates();
  const { enumPredicates } = useEnumPredicates();
  const { numberPredicates } = useNumberPredicates();
  const { setPredicates } = useSetPredicates();
  const { stringPredicates } = useStringPredicates();
  const { userPredicates } = useUserPredicates();
  const { userAndGroupPredicates } = useUserAndGroupPredicates();

  const predicates: Record<string, Record<string, Predicate>> = useMemo(
    () => ({
      string: stringPredicates,
      int: numberPredicates,
      datetime: dateTimePredicates,
      date: dateTimePredicates,
      enum: enumPredicates,
      set: setPredicates,
      bool: booleanPredicates,
      user: userPredicates,
      user_and_group: userAndGroupPredicates,
      url: stringPredicates,
      phone: stringPredicates,
      email: stringPredicates,
      float: numberPredicates,
      json: {},
      owners: {},
      uuid: {},
    }),
    [
      booleanPredicates,
      dateTimePredicates,
      enumPredicates,
      numberPredicates,
      setPredicates,
      stringPredicates,
      userPredicates,
      userAndGroupPredicates,
    ]
  );

  return { predicates };
};
