import { memo } from 'react';
import { UsersGroupsListItemSelectAndDeselect } from './UsersGroupsListItemSelectAndDeselect';

export const MemoizedUsersGroupsListItemSelectAndDeselect = memo(
  UsersGroupsListItemSelectAndDeselect,
  (prev, next) => {
    return (
      prev.isDisabled === next.isDisabled &&
      prev.additionalText === next.additionalText &&
      prev.item.id === next.item.id &&
      prev.limitReached === next.limitReached &&
      prev.searchText === next.searchText &&
      prev.selected === next.selected
    );
  }
);
