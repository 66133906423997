import clsx from 'clsx';
import { BoldedPartOfText } from 'components/Text/BoldedPartOfText';
import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import CustomTag from 'components/CustomTag';
import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObjectClassFieldLabelCellStyles } from './ObjectClassFieldLabelCell.styles';

export const ObjectClassFieldLabelCell = ({ row }: CatalystTableCellProps) => {
  const { searchValue } = useTableContext();
  const styles = useObjectClassFieldLabelCellStyles();

  const { label = '', is_identifier: isIdentifier = false } = row;

  return (
    <>
      <BoldedPartOfText value={label} boldPhrase={searchValue?.value} />
      {isIdentifier && (
        <CustomTag className={clsx([styles.tag, styles.identifier])}>
          <FormattedMessage id='enums.identifier' defaultMessage='Identifier' />
        </CustomTag>
      )}
    </>
  );
};
