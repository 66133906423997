import { AxiosError } from 'axios';
import { getErrorCodeFromError } from './getErrorCodeFromError';
import { ErrorCodes } from 'utils/types/errorResponse';

/**
 * Checks whether the error is a limit exceeded error.
 *
 * @param error Axios error returned from the API.
 *
 * @returns True if the error is a limit exceeded error, false otherwise.
 */
export const isLimitExceededError = (error: AxiosError) => {
  return getErrorCodeFromError(error) === ErrorCodes.ERR_LIMIT_EXCEEDED;
};
