import { TableState } from 'react-table';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import {
  PreferencesDataState,
  PreferencesTypes,
  ExtendedTableState,
} from 'utils/types/api/preferences.types';

export const getTablePreferencesState = (
  preferences: PreferencesDataState,
  currentTableName: CatalystTableType,
  preferencesId?: string | number
) =>
  preferencesId
    ? (preferences?.[PreferencesTypes.TablePreferences].tables?.[
        currentTableName
      ] as ExtendedTableState)?.[preferencesId]
    : (preferences?.[PreferencesTypes.TablePreferences].tables?.[
        currentTableName
      ] as Partial<TableState<object>>);
