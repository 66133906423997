import { createUseStyles } from 'react-jss';

export const useSelectedUsersAndGroupsEditorStyles = createUseStyles({
  mainWrapper: {
    display: 'flex',
    gap: '8px',
  },
  popoverOverlay: {
    padding: 0,

    /**
     * Explicit child selectors are used to avoid applying these styles to nested popovers.
     */
    '& > .ant-popover-content': {
      '& > .ant-popover-inner': {
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',

        '& > .ant-popover-inner-content': {
          width: '250px',
          maxHeight: '270px',
          padding: '8px',

          overflowY: 'auto',
        },
      },

      /**
       * We want to hide the popover arrow, because it's not rendering properly when the popover
       * doesn't fit a given space and it's rendered with some offset from the trigger element.
       */
      '& > .ant-popover-arrow': {
        display: 'none',
      },
    },
  },
});
