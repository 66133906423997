export const createUsersAndGroupsSelectionCountLimits = (
  minUsers: number | undefined,
  maxUsers: number | undefined,
  minGroups: number | undefined,
  maxGroups: number | undefined
) => {
  const hasLimits = [minUsers, maxUsers, minGroups, maxGroups].some(
    limit => limit !== undefined && limit !== null
  );

  return hasLimits ? { minUsers, maxUsers, minGroups, maxGroups } : undefined;
};
