import React from 'react';
import { InputNumber } from 'components/lib/InputNumber';
import { FilterNumberValueProps } from './FilterNumberValue.types';
import { useIntl } from 'react-intl';

export const FilterNumberValue = ({
  value,
  testId,
  onChange,
}: FilterNumberValueProps) => {
  const intl = useIntl();

  const handleChange = (value: string | number | undefined) => {
    onChange(value ?? '');
  };

  return (
    <InputNumber
      type='number'
      value={value}
      onChange={handleChange}
      placeholder={intl.formatMessage({
        id: 'placeholders.enterNumber',
        defaultMessage: 'Enter number',
      })}
      data-testid={testId}
    />
  );
};
