import { createUiSectionContentSchema } from './utils/createUiSectionContentSchema';

export const createUiSectionSchema = (
  sectionContent: any,
  sectionAlias: string,
  fieldsInDataSchema: {
    [key: string]: string[];
  }
) => {
  const sectionSchemaEntries = Object.entries(sectionContent)
    .filter(
      ([alias]) =>
        alias.startsWith('ui:') ||
        fieldsInDataSchema[sectionAlias]?.includes(alias)
    )
    .map(([sectionContentKey, sectionContentValue]) =>
      createUiSectionContentSchema(
        sectionAlias,
        sectionContentKey,
        sectionContentValue,
        fieldsInDataSchema
      )
    );

  return Object.fromEntries(sectionSchemaEntries);
};
