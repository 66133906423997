import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown as AntDropdown } from 'antd';
import debounce from 'lodash/debounce';
import { DropdownProps } from './Dropdown.types';

export const Dropdown = ({
  children,
  visible,
  remountChangeTarget,
  ...rest
}: DropdownProps) => {
  const isMounted = useRef<boolean>();
  const [isContainerResizing, setIsContainerResizing] = useState(false);

  const debouncedFun = useCallback(
    debounce(() => {
      if (!isMounted.current) {
        return;
      }

      setIsContainerResizing(false);
    }, 100),
    []
  );

  useEffect(() => {
    isMounted.current = true;

    if (!remountChangeTarget || !visible) {
      return;
    }

    if (!window.ResizeObserver) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setIsContainerResizing(true);
      debouncedFun();
    });

    resizeObserver.observe(remountChangeTarget());

    return () => {
      isMounted.current = false;

      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (isContainerResizing) {
    return <>{children}</>;
  }

  return (
    <AntDropdown {...(visible !== undefined ? { visible } : {})} {...rest}>
      {children}
    </AntDropdown>
  );
};
