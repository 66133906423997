import React from 'react';
import { CloseIcon } from 'components/Icon';
import { Loader } from 'components/lib/Loader';
import useInPlaceInputAfterStyles from './styles';
import { InPlaceInputAfterProps } from './types';
import clsx from 'clsx';
import Tooltip from 'components/lib/Tooltip';

const InPlaceInputAfter = ({
  isLoading,
  onCloseClick,
  className,
  tooltip,
}: InPlaceInputAfterProps) => {
  const classes = useInPlaceInputAfterStyles({});

  if (isLoading)
    return <Loader size='small' className={clsx(classes.loader, className)} />;

  return onCloseClick ? (
    <Tooltip overlay={tooltip} className={clsx(classes.closeIcon, className)}>
      <CloseIcon size={16} onClick={onCloseClick} />
    </Tooltip>
  ) : null;
};

export default InPlaceInputAfter;
