import { AvatarItem } from 'components/lib/Avatar/types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SelectUserGroupOption } from 'utils/types/selectInput.types';

/**
 * Handles the logic for selecting and removing user groups from the list of assignees.
 */
export const useGroupAssigneesSelection = (
  assignedGroupIds: number[],
  updatePanelCloseBtnBehavior: (hasSelectedGroups: boolean) => void
) => {
  const intl = useIntl();

  const [selectedGroups, setSelectedGroups] = useState<AvatarItem[]>([]);

  const hasSelectedGroups = selectedGroups.length > 0;

  const getIsGroupAlreadySelected = (groupId: number) => {
    return selectedGroups.some(({ id }) => {
      return groupId === id;
    });
  };

  const getIsGroupAlreadyAssigned = (groupId: number) => {
    return assignedGroupIds.some(id => {
      return groupId === id;
    });
  };

  const getIsGroupDisabled = (groupId: number) => {
    return (
      getIsGroupAlreadySelected(groupId) || getIsGroupAlreadyAssigned(groupId)
    );
  };

  const getDisabledGroupText = (groupId: number) => {
    if (getIsGroupAlreadyAssigned(groupId)) {
      return intl.formatMessage({
        id: 'misc.alreadyAssigned',
        defaultMessage: 'Already assigned',
      });
    }

    if (getIsGroupAlreadySelected(groupId)) {
      return intl.formatMessage({
        id: 'misc.alreadySelected',
        defaultMessage: 'Already selected',
      });
    }

    return undefined;
  };

  const handleGroupSelect = (group: SelectUserGroupOption | undefined) => {
    if (!group) {
      return;
    }

    const { id, text } = group;

    setSelectedGroups(prev => {
      const updatedSelectedGroups = [...prev, { id, userGroup: text }];

      // Update the close button behavior only when no groups were selected before
      if (prev.length === 0) {
        updatePanelCloseBtnBehavior(true);
      }

      return updatedSelectedGroups;
    });
  };

  const handleGroupRemove = async (groupId: string | number) => {
    setSelectedGroups(prev => {
      const updatedSelectedGroups = prev.filter(({ id }) => groupId !== id);

      // Update the close button behavior only when no more groups are selected
      if (updatedSelectedGroups.length === 0) {
        updatePanelCloseBtnBehavior(false);
      }

      return updatedSelectedGroups;
    });
  };

  return {
    selectedGroups,
    hasSelectedGroups,
    getIsGroupDisabled,
    getDisabledGroupText,
    handleGroupSelect,
    handleGroupRemove,
  };
};
