import {
  ComponentsDictionary,
  ExternalComponent,
} from 'components/lib/FlexLayout/types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import FlexLayoutReact, { Model } from 'flexlayout-react';
import jsonModel from './default.json';
import useFlexLayout from './flexLayoutHooks';
import { useIntl } from 'react-intl';
import { FiltersPanel } from 'components/SidePanels/FiltersPanel';
import useExternalWindows from './externalWindowHooks';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { setAllFilters } from 'store/actions/filtersActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  flexLayoutJSONModelSelector,
  preferenceFiltersSelector,
} from 'store/selectors/preferencesSelectors';
import {
  setSidebarData,
  setSidebarWidth,
  toggleSidebar,
} from 'store/actions/flexLayoutActions';
import routes from 'utils/routingPaths';
import { ObjectClassAddField } from 'pages/ObjectClasses/components/ObjectClassAddField/ObjectClassAddField';
import { ObjectClassModifyField } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassModifyField';
import { useObjectClassLocationState } from 'pages/ObjectClasses/components/ObjectClassForm/hooks/useObjectClassLocationState';
import usePanels from 'hooks/usePanels';
import RecordAccessPanel from 'pages/Records/RecordsListing/RecordAccessPanel';
import HelpPanel from 'components/HelpPanel';
import { TasksPanel } from 'components/SidePanels/TasksPanel';
import OwnersPanel from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/ClassPermissions/OwnersPanel';
import noop from 'lodash/noop';
import { setObjectClassSelectedPermissionSet } from 'store/actions/objectClassPermissionsActions';
import SequenceTaskDefaultAssignees from 'pages/Sequences/components/SequenceTaskDefaultAssignees';
import TaskGroupTemplatesViewUsagePanel from 'pages/TaskGroups/components/TaskGroupTemplatesViewUsagePanel';
import TaskTemplateViewUsagePanel from 'pages/TaskTemplates/components/TaskTemplatesViewUsagePanel';
import ActivityLogPanel from 'pages/Records/RecordsListing/ActivityLogPanel';
import ChildRecordSummaryPanel from 'pages/Records/RecordsListing/ChildRecordSummaryPanel';
import SummaryPanelWrapper from 'pages/Records/RecordsListing/SummaryPanelWrapper';
import Documents from 'pages/Records/RecordsListing/DocumentTemplatesPanel';
import CustomComponentsViewUsagePanel from 'pages/CustomComponents/components/CustomComponentsTable/CustomComponentsViewUsagePanel';
import { setUserGroupSelectedPermissionSet } from 'store/actions/userGroupPermissionsActions';
import UserGroupPermissionSetAssignees from 'pages/UserGroups/components/Permissions/OwnersPanel';
import { ViewerPanel } from 'components/ViewerPanel';
import { ObjectClassViewUsagePanel } from 'pages/ObjectClasses/components/ObjectClassViewUsagePanel';
import { DocumentTemplateViewUsagePanel } from 'pages/ObjectClasses/components/DocumentTemplateViewUsagePanel';
import { ObjectClassFieldViewUsage } from 'pages/ObjectClasses/components/ObjectClassFieldViewUsage';
import { UserGroupUsagePanel } from 'components/SidePanels/UserGroupUsagePanel';

interface FlexLayoutContextType {
  availableComponents: ComponentsDictionary;
  model: Model;
  flexLayoutMethods: ReturnType<typeof useFlexLayout>;
  externalWindowsMethods: ReturnType<typeof useExternalWindows>;
  globalMethods: {
    closeComponent: (componentKey: FlexLayoutWindows) => void;
    focusComponent: (componentKey: FlexLayoutWindows) => void;
  };
  isComponentOpen: (flexWindow: FlexLayoutWindows) => boolean;
  closePanelIfOpen: (flexWindow: FlexLayoutWindows) => void;
}

export const FlexLayoutContext = React.createContext<FlexLayoutContextType>({
  availableComponents: {},
  model: {} as Model,
  flexLayoutMethods: {} as ReturnType<typeof useFlexLayout>,
  externalWindowsMethods: {} as ReturnType<typeof useExternalWindows>,
  globalMethods: {
    closeComponent: Function,
    focusComponent: Function,
  },
  isComponentOpen: () => false,
  closePanelIfOpen: noop,
});

export const FlexLayoutContextProvider: React.FC = ({ children }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const preferenceFilters = useSelector(preferenceFiltersSelector);
  const flexLayoutJSONModel = useSelector(flexLayoutJSONModelSelector);
  const redirect = useObjectClassLocationState();
  const { togglePanelsPreferences } = usePanels();

  const AVAILABLE_COMPONENTS: ComponentsDictionary = {
    [FlexLayoutWindows.FilterEditor]: {
      name: intl.formatMessage({
        id: 'misc.filters',
        defaultMessage: 'Filters',
      }),
      Component: FiltersPanel,
      //set last applied filters saved in preferences
      onClose: () => {
        dispatch(toggleSidebar(false));
        dispatch(setSidebarWidth(0));
        dispatch(setAllFilters(preferenceFilters));
      },
      availablePaths: [
        routes.OBJECT_CLASSES,
        routes.RECORDS,
        routes.TASK_TEMPLATES,
        routes.TASKS,
        routes.TASK_GROUPS,
        routes.SEQUENCES,
        routes.USER_GROUPS,
        routes.USERS,
        routes.ROLES,
        routes.SINGLE_SIGN_ONS,
        routes.AUTHENTICATION_OBJECTS,
        routes.USER_GROUP_EDIT,
        routes.CUSTOM_COMPONENTS,
      ],
    },
    [FlexLayoutWindows.ObjectClassAddField]: {
      name: intl.formatMessage({
        id: 'objectClasses.form.addField',
        defaultMessage: 'Add field',
      }),
      Component: ObjectClassAddField,
      availablePaths: [
        routes.OBJECT_CLASSES_CREATE,
        routes.OBJECT_CLASSES_EDIT,
      ],
      onClose: () =>
        dispatch(setSidebarData(FlexLayoutWindows.ObjectClassAddField, {})),
    },
    [FlexLayoutWindows.ObjectClassEditField]: {
      name: intl.formatMessage({
        id: 'objectClasses.form.editField',
        defaultMessage: 'Edit field',
      }),
      Component: ObjectClassModifyField,
      availablePaths: [
        routes.OBJECT_CLASSES_CREATE,
        routes.OBJECT_CLASSES_EDIT,
      ],
      onClose: () => {
        redirect();
        dispatch(setSidebarData(FlexLayoutWindows.ObjectClassEditField, {}));
      },
    },
    [FlexLayoutWindows.ObjectClassFieldViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: ObjectClassFieldViewUsage,
      availablePaths: [routes.OBJECT_CLASSES_EDIT],
      onClose: () =>
        dispatch(
          setSidebarData(FlexLayoutWindows.ObjectClassFieldViewUsage, {})
        ),
    },
    [FlexLayoutWindows.ObjectClassViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: ObjectClassViewUsagePanel,
      availablePaths: [routes.OBJECT_CLASSES],
      onClose: () =>
        dispatch(setSidebarData(FlexLayoutWindows.ObjectClassViewUsage, {})),
    },
    [FlexLayoutWindows.TaskTemplateViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: TaskTemplateViewUsagePanel,
      availablePaths: [routes.TASK_TEMPLATES],
      onClose: () =>
        dispatch(setSidebarData(FlexLayoutWindows.TaskTemplateViewUsage, {})),
    },
    [FlexLayoutWindows.TaskGroupViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: TaskGroupTemplatesViewUsagePanel,
      availablePaths: [routes.TASK_GROUPS],
      onClose: () =>
        dispatch(setSidebarData(FlexLayoutWindows.TaskGroupViewUsage, {})),
    },
    [FlexLayoutWindows.DocumentTemplateViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: DocumentTemplateViewUsagePanel,
      availablePaths: [routes.OBJECT_CLASSES_EDIT],
      onClose: () =>
        dispatch(
          setSidebarData(FlexLayoutWindows.DocumentTemplateViewUsage, {})
        ),
    },
    [FlexLayoutWindows.CustomComponentsViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: CustomComponentsViewUsagePanel,
      availablePaths: [routes.CUSTOM_COMPONENTS],
      onClose: () =>
        dispatch(
          setSidebarData(FlexLayoutWindows.CustomComponentsViewUsage, {})
        ),
    },
    [FlexLayoutWindows.UserGroupsViewUsage]: {
      name: intl.formatMessage({
        id: 'misc.viewUsage',
        defaultMessage: 'View usage',
      }),
      Component: UserGroupUsagePanel,
      availablePaths: [routes.USER_GROUPS],
      onClose: () =>
        dispatch(setSidebarData(FlexLayoutWindows.UserGroupsViewUsage, {})),
    },
    [FlexLayoutWindows.RecordAccess]: {
      name: intl.formatMessage({
        id: 'misc.recordAccess',
        defaultMessage: 'Record access',
      }),
      Component: RecordAccessPanel,
      availablePaths: [
        routes.WORKSPACE,
        routes.RECORDS,
        routes.CREATE_RECORD,
        routes.RECORD,
      ],
      onClose: () =>
        togglePanelsPreferences(FlexLayoutWindows.RecordAccess, true),
    },
    [FlexLayoutWindows.Summary]: {
      name: intl.formatMessage({
        id: 'misc.summary',
        defaultMessage: 'Summary',
      }),
      Component: SummaryPanelWrapper,
      availablePaths: [
        routes.RECORDS,
        routes.CREATE_RECORD,
        routes.RECORD,
        routes.TASKS,
        routes.TASK_COMPLETE,
        routes.TASK_VIEW,
      ],
      onClose: () => togglePanelsPreferences(FlexLayoutWindows.Summary, true),
    },
    [FlexLayoutWindows.ChildRecordSummary]: {
      name: intl.formatMessage({
        id: 'misc.childRecordSummary',
        defaultMessage: 'Child record summary',
      }),
      Component: ChildRecordSummaryPanel,
      availablePaths: [routes.CREATE_RECORD, routes.RECORD],
      onClose: () =>
        togglePanelsPreferences(FlexLayoutWindows.ChildRecordSummary, true),
    },
    [FlexLayoutWindows.HelpPanel]: {
      name: intl.formatMessage({
        id: 'misc.help',
        defaultMessage: 'Help',
      }),
      Component: HelpPanel,
      availablePaths: Object.values(routes),
    },
    [FlexLayoutWindows.ClassPermissionsOwners]: {
      name: intl.formatMessage({
        id: 'misc.manageOwners',
        defaultMessage: 'Manage owners',
      }),
      Component: OwnersPanel,
      availablePaths: [routes.OBJECT_CLASSES_EDIT],
      onClose: () => {
        dispatch(setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {}));
        dispatch(setObjectClassSelectedPermissionSet(undefined));
      },
    },
    [FlexLayoutWindows.UserGroupPermissionSetAssignees]: {
      name: intl.formatMessage({
        id: 'misc.manageAssignees',
        defaultMessage: 'Manage assignees',
      }),
      Component: UserGroupPermissionSetAssignees,
      availablePaths: [routes.USER_GROUP_EDIT],
      onClose: () => {
        dispatch(
          setSidebarData(FlexLayoutWindows.UserGroupPermissionSetAssignees, {})
        );
        dispatch(setUserGroupSelectedPermissionSet(undefined));
      },
    },
    [FlexLayoutWindows.Tasks]: {
      name: intl.formatMessage({
        id: 'misc.tasks',
        defaultMessage: 'Tasks',
      }),
      Component: TasksPanel,
      availablePaths: [
        routes.WORKSPACE,
        routes.RECORDS,
        routes.CREATE_RECORD,
        routes.RECORD,
      ],
      onClose: () => togglePanelsPreferences(FlexLayoutWindows.Tasks, true),
    },
    [FlexLayoutWindows.SequenceDefaultAssignees]: {
      name: intl.formatMessage({
        id: 'misc.manageDefaultTaskAssignees',
        defaultMessage: 'Manage default task assignees',
      }),
      Component: SequenceTaskDefaultAssignees,
      availablePaths: [routes.SEQUENCE_CREATE, routes.SEQUENCE_EDIT],
    },
    [FlexLayoutWindows.ActivityLog]: {
      name: intl.formatMessage({
        id: 'misc.activityLog',
        defaultMessage: 'Activity log',
      }),
      Component: ActivityLogPanel,
      availablePaths: [routes.RECORDS, routes.CREATE_RECORD, routes.RECORD],
      onClose: () =>
        togglePanelsPreferences(FlexLayoutWindows.ActivityLog, true),
    },
    [FlexLayoutWindows.Documents]: {
      name: intl.formatMessage({
        id: 'misc.templates',
        defaultMessage: 'Templates',
      }),
      Component: Documents,
      availablePaths: [routes.RECORDS, routes.RECORD],
      onClose: () => togglePanelsPreferences(FlexLayoutWindows.Documents, true),
    },
    [FlexLayoutWindows.Viewer]: {
      name: intl.formatMessage({
        id: 'misc.viewer',
        defaultMessage: 'Viewer',
      }),
      Component: ViewerPanel,
      availablePaths: [
        routes.RECORDS,
        routes.RECORD,
        routes.TASKS,
        routes.TASK_COMPLETE,
        routes.TASK_VIEW,
      ],
      disableUndockButton: true,
      onClose: () => {
        togglePanelsPreferences(FlexLayoutWindows.Viewer, true);
        dispatch(setSidebarData(FlexLayoutWindows.Viewer, {}));
      },
    },
  };

  const [externalComponents, setExternalComponents] = useState<
    ExternalComponent[]
  >([]);

  const model = useMemo(
    () => FlexLayoutReact.Model.fromJson(flexLayoutJSONModel || jsonModel),
    [flexLayoutJSONModel]
  );
  const flexLayoutMethods = useFlexLayout(
    model,
    AVAILABLE_COMPONENTS,
    externalComponents
  );
  const {
    onAddTab,
    onRemoveTab,
    onFocusTab,
    checkIfComponentExists,
  } = flexLayoutMethods;

  const externalWindowsMethods = useExternalWindows(
    model,
    onAddTab,
    externalComponents,
    setExternalComponents
  );
  const {
    closeExternalComponent,
    checkIfExternalWindowExists,
  } = externalWindowsMethods;

  const focusComponent = useCallback(
    componentKey => {
      onFocusTab(componentKey);

      if (componentKey !== FlexLayoutWindows.MainWindow) {
        onFocusTab(FlexLayoutWindows.MainWindow);
      }
    },
    [onFocusTab]
  );

  const closeComponent = useCallback(
    (componentKey: FlexLayoutWindows) => {
      onRemoveTab(componentKey);
      closeExternalComponent(componentKey);
    },
    [onRemoveTab, closeExternalComponent]
  );

  const isComponentOpen = useCallback(
    (flexWindow: FlexLayoutWindows) =>
      checkIfComponentExists(flexWindow) ||
      checkIfExternalWindowExists(flexWindow),
    [checkIfComponentExists, checkIfExternalWindowExists]
  );

  const closePanelIfOpen = useCallback(
    (flexWindow: FlexLayoutWindows) => {
      if (isComponentOpen(flexWindow)) {
        closeComponent(flexWindow);
      }
    },
    [closeComponent, isComponentOpen]
  );

  const contextValue = useMemo(
    () => ({
      availableComponents: AVAILABLE_COMPONENTS,
      model,
      flexLayoutMethods,
      externalWindowsMethods,
      globalMethods: {
        closeComponent,
        focusComponent,
      },
      isComponentOpen,
      closePanelIfOpen,
    }),
    [
      AVAILABLE_COMPONENTS,
      closeComponent,
      closePanelIfOpen,
      externalWindowsMethods,
      flexLayoutMethods,
      focusComponent,
      isComponentOpen,
      model,
    ]
  );

  return (
    <FlexLayoutContext.Provider value={contextValue}>
      {children}
    </FlexLayoutContext.Provider>
  );
};

export const useFlexLayoutContext = () => useContext(FlexLayoutContext);
