import { createUiColumnSchema } from './createUiColumnSchema';

export const createUiSectionContentSchema = (
  sectionAlias: string,
  sectionContentKey: string,
  sectionContentValue: unknown,
  fieldsInDataSchema: {
    [key: string]: string[];
  }
) => {
  if (sectionContentKey.startsWith('ui:columns')) {
    return createUiColumnSchema(
      sectionAlias,
      sectionContentKey,
      sectionContentValue,
      fieldsInDataSchema
    );
  }

  return [sectionContentKey, sectionContentValue];
};
