import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  buildPasswordRulesObject,
  isDuplicatesCountLessOrSameThanMax,
} from './utils';
import { PasswordRuleRegex } from './consts';
import {
  PasswordRulesFields,
  PasswordRulesOptions,
  PasswordRulesProps,
} from './types';
import { PasswordRules as PwdRules } from './enums';
import { useFormikContext } from 'formik';

export const usePasswordRulesLabels = (
  options: PasswordRulesOptions | undefined
) => {
  const intl = useIntl();
  const passwordRuleOptions:
    | MappedObject<string, PwdRules>
    | undefined = useMemo(() => {
    if (!options) return undefined;

    return {
      [PwdRules.MaxRepeatingChars]: intl.formatMessage(
        {
          id: 'activateAccount.max_repeating_chars',
          defaultMessage:
            'Max {charactersAmount} consecutive repeating characters',
        },
        { charactersAmount: options[PwdRules.MaxRepeatingChars] }
      ),
      [PwdRules.MinLetters]: intl.formatMessage(
        {
          id: 'activateAccount.min_letters',
          defaultMessage:
            '{charactersAmount, plural, one {# letter} other {# letters}}',
        },
        { charactersAmount: options[PwdRules.MinLetters] }
      ),
      [PwdRules.MinNumbers]: intl.formatMessage(
        {
          id: 'activateAccount.min_numbers',
          defaultMessage:
            '{charactersAmount, plural, one {# number} other {# numbers}}',
        },
        { charactersAmount: options[PwdRules.MinNumbers] }
      ),
      [PwdRules.MinSymbols]: intl.formatMessage(
        {
          id: 'activateAccount.min_symbols',
          defaultMessage:
            '{charactersAmount, plural, one {# symbol} other {# symbols}}',
        },
        { charactersAmount: options[PwdRules.MinSymbols] }
      ),
      [PwdRules.MinLowerCase]: intl.formatMessage(
        {
          id: 'activateAccount.min_lower_case',
          defaultMessage:
            '{charactersAmount, plural, one {# lower case letter} other {# lower case letters}}',
        },
        { charactersAmount: options[PwdRules.MinLowerCase] }
      ),
      [PwdRules.MinUpperCase]: intl.formatMessage(
        {
          id: 'activateAccount.min_upper_case',
          defaultMessage:
            '{charactersAmount, plural, one {# capital letter} other {# capital letters}}',
        },
        { charactersAmount: options[PwdRules.MinUpperCase] }
      ),
      [PwdRules.MinLength]: intl.formatMessage(
        {
          id: 'activateAccount.min_length',
          defaultMessage:
            '{charactersAmount, plural, one {# character} other {# characters}}',
        },
        { charactersAmount: options[PwdRules.MinLength] }
      ),
    };
  }, [intl, options]);

  return passwordRuleOptions;
};

export const usePasswordRules = <T>({
  options,
  setIsFulfilled,
  passwordKey,
}: PasswordRulesProps<T>) => {
  const { values } = useFormikContext<T>();
  const [passwordRules, setPasswordRules] = useState<
    Partial<PasswordRulesFields>
  >({});

  useEffect(() => setPasswordRules(buildPasswordRulesObject(options)), [
    options,
  ]);

  const validatePassword = useCallback(
    (value): void | undefined => {
      if (options) {
        let isPasswordComplexityCorrect = true;
        const passwordComplexityPassedRules = Object.keys(passwordRules).reduce(
          (rules, key) => {
            const regexp = PasswordRuleRegex[key as PwdRules];

            if (key === PwdRules.MinLength) {
              const match = value.length >= options[key];
              isPasswordComplexityCorrect =
                isPasswordComplexityCorrect && match;

              return { ...rules, [key]: match };
            }

            if (key === PwdRules.MinLetters) {
              const nonAlphaCharacters = value.replace(regexp, '');
              const alphaCharactersCount =
                value.length - nonAlphaCharacters.length;
              const match = alphaCharactersCount >= options[key];
              isPasswordComplexityCorrect =
                isPasswordComplexityCorrect && match;

              return { ...rules, [key]: match };
            }

            if (key === PwdRules.MaxRepeatingChars) {
              const match =
                isDuplicatesCountLessOrSameThanMax(
                  value,
                  options[PwdRules.MaxRepeatingChars]
                ) && value.length > 0;
              isPasswordComplexityCorrect =
                isPasswordComplexityCorrect && match;

              return {
                ...rules,
                [key]: match,
              };
            }

            const match = value.match(regexp);

            const matched = !!(
              match && match?.length >= options[key as PwdRules]
            );

            isPasswordComplexityCorrect =
              isPasswordComplexityCorrect && matched;

            return { ...rules, [key]: matched };
          },
          {} as PasswordRulesFields
        );

        setIsFulfilled(isPasswordComplexityCorrect);
        setPasswordRules(prevState => ({
          ...prevState,
          ...passwordComplexityPassedRules,
        }));
      }
    },
    [options, setIsFulfilled, passwordRules]
  );

  useEffect(() => {
    validatePassword(values[passwordKey]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[passwordKey]]);

  return {
    passwordRules,
  };
};
