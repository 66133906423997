import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getObjectClassFieldsDetails } from 'store/selectors/objectClassesFieldsSelectors';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';

export const useUniquenessValidation = (type: ObjectClassFieldTypes) => {
  const [uniqueSchemaTypes, setUniqueSchemaTypes] = useState<string[]>([]);
  const details = useSelector(getObjectClassFieldsDetails);

  const { uniquenessAllowed, schemaType } = useMemo(() => {
    const schemaType = details?.schema.find(
      ({ alias }) => alias === ClassFieldFormFields.Type
    );
    const uniquenessAllowed = uniqueSchemaTypes.includes(type);

    return { uniquenessAllowed, schemaType };
  }, [details, type, uniqueSchemaTypes]);

  useEffect(() => {
    if (schemaType) {
      setUniqueSchemaTypes(
        schemaType?.values
          ?.filter(({ is_unique: isUnique }) => !!isUnique)
          .map(({ value }) => value) || []
      );
    }
  }, [schemaType]);

  return { uniquenessAllowed };
};
