import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import {
  useUserFieldMinErrorMessages,
  useValidateFulfillment,
} from 'components/UsersAndGroupsSelection/hooks';
import { useCallback, useEffect } from 'react';
import {
  SelectUserAndGroupOption,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';

export const useCustomUserWidgetValidation = (
  formValue: UsersAndGroupsFormValue | null | undefined,
  fieldLabel: string,
  errors: string[],
  limits: UsersAndGroupsSelectLimits,
  isEditMode: boolean,
  fulfillmentFormErroredFields?: Set<string>
) => {
  const { minGroupsError, minUsersError } = useUserFieldMinErrorMessages(
    fieldLabel,
    limits.selectionCountLimits?.minUsers,
    limits.selectionCountLimits?.minGroups
  );

  const {
    tooltip,
    fulfillmentErrors,
    isDisabledByFulfillment,
    validateFulfillment,
    evaluateFieldAgainstFormFulfillmentValidation,
  } = useValidateFulfillment(
    fieldLabel,
    isEditMode,
    minUsersError,
    fulfillmentFormErroredFields
  );

  const validateFulfillmentPossibility = useCallback(
    (options: SelectUserAndGroupOption, isMinUsersFulfilled: boolean) =>
      validateFulfillment(
        formValue,
        {
          users: options.users,
          groups: options.groups,
          isRequired: limits.isFieldRequired,
          limits,
        },
        isMinUsersFulfilled
      ),
    [formValue, limits, validateFulfillment]
  );

  useEffect(() => {
    evaluateFieldAgainstFormFulfillmentValidation();
  }, [evaluateFieldAgainstFormFulfillmentValidation]);

  return {
    validateFulfillmentPossibility,
    minUsersError,
    minGroupsError,
    tooltip,
    errors: [...fulfillmentErrors, ...errors],
    isDisabledByFulfillment,
    shouldDisableDueToValidation: isDisabledByFulfillment,
  };
};
