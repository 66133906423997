import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { parseToIntlKey } from 'utils/functions/parseToIntlKey';
import { PredicateSet } from 'utils/types/predicates.types';
import { SelectOption } from 'utils/types/selectInput.types';

export const getOptionText = (
  option: SelectOption,
  predicateSet: PredicateSet
) => {
  if (predicateSet === PredicateSet.ClassFields) {
    return option.text;
  }

  const intlKey = parseToIntlKey(
    typeof option.value === 'string' ? option.value : option.text
  );

  return GlobalIntlSingleton.dynamicFormatMessage({
    id: `enums.${intlKey}`,
    defaultMessage: option.text,
  });
};
