import clsx from 'clsx';
import React, { forwardRef } from 'react';
import InPlaceEditLabel from '../InPlaceEditLabel';
import InPlaceInputAfter from '../InPlaceInputAfter';
import PreviewComponent from '../PreviewComponent';
import useInPlaceEditTextInputStyles from './styles';
import { InPlaceEditWrapperProps } from './types';

const InPlaceEditWrapper = forwardRef<HTMLDivElement, InPlaceEditWrapperProps>(
  (
    {
      isEditMode,
      isSaving,
      label,
      required,
      onCloseClick,
      disabled,
      inputWrapperRef,
      onViewClick,
      viewContent,
      editContent,
      actionButtonOnTop = false,
      viewContentAfter,
      rowAdditionalClassName,
      noAfter,
      maxWidth,
      readOnly,
      previewClassName,
      size,
      allowClickWhenDisabled,
      withUnderline,
      tooltipText,
      closeIconClassName,
    },
    ref
  ) => {
    const classes = useInPlaceEditTextInputStyles({
      actionButtonOnTop,
      isEditMode,
      maxWidth,
    });

    return (
      <div ref={ref} className={classes.fieldWrapper}>
        <InPlaceEditLabel
          label={label}
          required={required}
          isEditMode={readOnly ? false : isEditMode}
        />
        {isEditMode && (
          <div
            className={clsx(classes.row, rowAdditionalClassName)}
            ref={inputWrapperRef}
          >
            {editContent}
            {!noAfter && (
              <InPlaceInputAfter
                isLoading={isSaving}
                {...{ onCloseClick, className: closeIconClassName }}
              />
            )}
          </div>
        )}
        <div className={classes.previewWrapper}>
          <PreviewComponent
            disabled={disabled}
            actionButtonOnTop={actionButtonOnTop}
            readOnly={readOnly}
            size={size}
            withUnderline={withUnderline}
            tooltipText={tooltipText}
            onClick={
              readOnly && !allowClickWhenDisabled ? undefined : onViewClick
            }
            className={previewClassName}
          >
            {viewContent}
          </PreviewComponent>
          {viewContentAfter}
        </div>
      </div>
    );
  }
);

export default InPlaceEditWrapper;
