import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTableColumnMetadata } from 'store/selectors/generalSelectors';
import TagContainer from 'components/TagContainer';
import { TagTypes } from 'components/TagContainer/enums';
import { SelectOption } from 'utils/types/selectInput.types';
import { StatusProps } from './Status.types';

export const Status = ({ status, column }: StatusProps) => {
  const { values } = useSelector(getCurrentTableColumnMetadata)(column);

  const { text } =
    values.find((item: SelectOption) => item.value === status) || {};

  return (
    <TagContainer
      type={status === 1 ? TagTypes.Primary : TagTypes.Secondary}
      name={typeof status === 'string' ? status : text}
    />
  );
};
