import { createUseStyles } from 'react-jss';

export const useEditSelectedUsersAndGroupsStyles = createUseStyles({
  userAndGroupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    margin: 0,
    padding: 0,

    listStyleType: 'none',
  },
});
