import React from 'react';
import { CatalystTableCellProps } from './CatalystTableCell.types';
import { TABLE_CELL_COMPONENT_MAPPING } from './CatalystTableCell.consts';
import { CatalystTableCellType } from 'components/CatalystTable/types/catalystTableCellType';

export const CatalystTableCell = ({
  table,
  column,
  row,
}: CatalystTableCellProps) => {
  for (const mapping of TABLE_CELL_COMPONENT_MAPPING) {
    const tableMatches =
      mapping.tables.length === 0 || mapping.tables.includes(table);
    const columnMatches = mapping.columns.includes(
      column as CatalystTableCellType
    );

    if (!tableMatches || !columnMatches) {
      continue;
    }

    const ValidCellComponent = mapping.component;
    return <ValidCellComponent table={table} column={column} row={row} />;
  }

  return null;
};
