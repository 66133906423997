import clsx from 'clsx';
import {
  VIEW_USAGE_DESC_TESTID,
  VIEW_USAGE_DESC_TO_USAGE_TESTID,
  VIEW_USAGE_TITLE_TESTID,
} from '../../utils/testIds';
import { useIntl } from 'react-intl';
import React from 'react';
import { Loader } from '../lib/Loader';
import { ResourceUsagePanelViewProps } from './ResourceUsagePanelView.types';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { BoldTextPartsFallback } from 'utils/intl/fallbacks/BoldTextParts';
import { UsageList } from './components/UsageList';
import { hasResourceAnyUsage } from './utils/hasResourceAnyUsage';

export const ResourceUsagePanelView = ({
  displayConfig,
  resourceName,
  isExternal,
  usageLabel,
  noUsageLabel,
  isLoading,
}: ResourceUsagePanelViewProps) => {
  const intl = useIntl();
  const sharedStyles = useClassFieldPropertiesStyles({ isLight: true });

  const title = intl.formatMessage(
    {
      id: 'fieldUsage.boldNameLabel',
      defaultMessage: 'Usage for <b>{label}</b>',
    },
    { b: BoldTextPartsFallback, label: resourceName }
  );

  if (isLoading) {
    return (
      <div className={sharedStyles.loaderWrapper}>
        <Loader spinning={isLoading} />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        sharedStyles.contentWrapper,
        sharedStyles.viewUsageWrapper,
        {
          [sharedStyles.externalWrapper]: isExternal,
        }
      )}
    >
      <p
        className={sharedStyles.viewUsageTitle}
        data-testid={VIEW_USAGE_TITLE_TESTID}
      >
        {title}
      </p>

      {hasResourceAnyUsage(displayConfig) ? (
        <>
          <div
            className={sharedStyles.secondaryTextContainer}
            data-testid={VIEW_USAGE_DESC_TESTID}
          >
            {usageLabel}
          </div>

          <UsageList displayConfig={displayConfig} />
        </>
      ) : (
        <div
          className={sharedStyles.secondaryTextContainer}
          data-testid={VIEW_USAGE_DESC_TO_USAGE_TESTID}
        >
          {noUsageLabel}
        </div>
      )}
    </div>
  );
};
