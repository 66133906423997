import React from 'react';
import Resizer from '../Resizer';
import { ResizeProvider } from 'components/CatalystTable/components/ResizeProvider';
import { DraggingStyle } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { useChildClassTableStyles } from '../../styles';
import { DEFAULT_COLUMNS_WIDTHS } from 'components/CatalystTable/CatalystTable.consts';
import { getColumnVarName } from 'components/CatalystTable/utils/getColumnVarName';
import { getItemStyle } from '../../utils';
import { generateDraggableTransform } from 'utils/functions/generateDraggableTransform';
import TooltipString from 'components/TooltipString';
import AddColumn from '../ColumnManager';
import { ButtonTransparent } from 'components/lib/Button';
import { DisabledSorter } from '../../../ChildClassPreviewComponent/components/ChildClassTable/components/DisabledSorter';
import useButtonStyles from 'components/lib/Button/styles';
import { CloseIcon } from 'components/Icon';
import { REMOVE_COLUMN_TESTID } from 'utils/testIds';
import { HeaderColumnProps } from './types';
import { getTableDensity } from 'store/selectors/preferencesSelectors';
import { useSelector } from 'react-redux';
import { TableDensity } from 'components/CatalystTable/types/tableDensity';

const HeaderColumn = ({
  headerWrapperRef,
  column,
  handleColumResize,
  provided,
  snapshot,
  tableHeadRowTopOffset,
  tableHeadRowLeftOffset,
  columnIndex,
  label,
  showResizer,
  showRemoveColumn,
  showSorter,
  showAddColumn,
  childClassID,
  onRemoveColumn,
  setCurrentAddColsIndex,
  onAddNewColumns,
  columns,
  objectRecrdPropFields,
}: HeaderColumnProps) => {
  const density = useSelector(getTableDensity) ?? TableDensity.Default;
  const styles = useChildClassTableStyles({ density });
  const buttonStyles = useButtonStyles({});
  return (
    <ResizeProvider
      columnKey={column.id}
      onColumnResize={handleColumResize}
      wrapperRef={headerWrapperRef}
    >
      {({ initDrag, cellRef }) => (
        <div
          ref={cellRef}
          {...provided.draggableProps}
          className={clsx([styles.tableHeadCellOuter])}
          style={{
            minWidth: DEFAULT_COLUMNS_WIDTHS.minWidth,
            width: `var(${getColumnVarName(column.id)})`,

            ...getItemStyle(snapshot, provided.draggableProps),
            ...(snapshot.isDragging
              ? {
                  ...generateDraggableTransform(
                    snapshot.isDragging,
                    provided.draggableProps.style as DraggingStyle,
                    tableHeadRowTopOffset,
                    tableHeadRowLeftOffset,
                    0
                  ),
                  ...{ borderLeft: '2px solid #ced5da' },
                }
              : {}),
          }}
          key={columnIndex}
        >
          <div className={styles.tableHeadCell} ref={provided.innerRef}>
            <div
              className={styles.tableHeadCellInner}
              {...provided.dragHandleProps}
              style={{}}
            >
              <TooltipString text={label ?? ''}>{label}</TooltipString>
              {showSorter && <DisabledSorter />}
              {showRemoveColumn && (
                <ButtonTransparent
                  onClick={onRemoveColumn}
                  className={`${styles.removeColumnButton} ${buttonStyles.buttonTransparent}`}
                  data-testid={REMOVE_COLUMN_TESTID}
                  id={column.id}
                >
                  <CloseIcon size={18} />
                </ButtonTransparent>
              )}
            </div>
            <AddColumn
              onTogglePopover={setCurrentAddColsIndex}
              onAddNewColumns={onAddNewColumns}
              sourceIndex={columnIndex}
              visible={showAddColumn}
              indexToAdd={columnIndex}
              classID={childClassID}
              selectedCols={columns}
              objectRecrdPropFields={objectRecrdPropFields}
            />
            <Resizer onMouseDownCallback={initDrag} disabled={showResizer} />
          </div>
        </div>
      )}
    </ResizeProvider>
  );
};

export default HeaderColumn;
