import { ObjectRecordUserGroupMetadata } from 'utils/types/api/objectRecords.types';
import {
  UserOrGroupSelectOptionType,
  SelectUserGroupOption,
} from 'utils/types/selectInput.types';

export const prepareUserGroupOptions = (
  apiGroups: ObjectRecordUserGroupMetadata[]
) => {
  return apiGroups.map<SelectUserGroupOption>(apiGroup => ({
    type: UserOrGroupSelectOptionType.Group,
    id: apiGroup.id,
    num_of_members: apiGroup.num_of_members,
    text: apiGroup.name,
    value: apiGroup.id,
  }));
};
