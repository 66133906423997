import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { store, persist, persistConfig } from 'store/store';
import MainRouting from './MainRouting';
import { PersistGate } from 'redux-persist/integration/react';
import IntlProviderWrapper from 'providers/IntlProviderWrapper';
import useCursor from 'hooks/useCursor';
import useGlobalStyle from 'hooks/useGlobalStyle';
import clsx from 'clsx';
import { appContainer } from 'utils/elementsIds';
import GlobalIntlSingleton from '../providers/IntlProviderWrapper/globalIntlSingleton';
import { crossBrowserListener } from 'utils/functions/reduxPersistListener';
import { JssProvider } from 'react-jss';
import SubscribableSheetsRegistry from 'components/lib/FlexLayout/SubscribableSheetsRegistry';
import { HelmentContextWrapper } from 'contexts/HelmetContext';
import { InPlaceFilesSavingContextWrapper } from 'contexts/InPlaceFilesSavingContext';
import { RefetchResourceContextProvider } from 'contexts/RefetchResourceContext';
import PreLoggedInGlobalActionsWrapper from './wrappers/PreLoggedInGlobalActionsWrapper';
import ThemeWrapper from './wrappers/ThemeWrapper';
import { logoutUser } from 'store/actions/authActions';
import { ToastsContainer } from 'features/toasts/components/ToastsContainer';
import { ErrorDetailsModal } from './ErrorDetailsModal';

export const sheets = new SubscribableSheetsRegistry();

const isAuthenticated = () => {
  if (
    localStorage.getItem('authenticated') === 'false' &&
    store.getState().auth?.access
  ) {
    store.dispatch(logoutUser());
  }
};

const App = () => {
  const { handleResizeCursor } = useCursor();
  const classes = useGlobalStyle();

  useEffect(() => {
    window.addEventListener(
      'storage',
      crossBrowserListener(store, persistConfig)
    );
    window.addEventListener('storage', isAuthenticated);

    return () => {
      window.removeEventListener(
        'storage',
        crossBrowserListener(store, persistConfig)
      );
      window.removeEventListener('storage', isAuthenticated);
    };
  }, []);

  // Added because of GlobalIntlSingleton should have actual language after refreshing page (taken from store from action PERSIST).
  useEffect(() => GlobalIntlSingleton.recreateInstance(), []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persist}>
        <JssProvider registry={sheets}>
          <IntlProviderWrapper>
            <PreLoggedInGlobalActionsWrapper />
            <HelmentContextWrapper>
              <ThemeWrapper>
                <InPlaceFilesSavingContextWrapper>
                  <RefetchResourceContextProvider>
                    <div
                      id={appContainer}
                      className={clsx(['App', classes.app])}
                      onMouseUp={handleResizeCursor}
                    >
                      <Router basename={process.env.PUBLIC_URL}>
                        <MainRouting />
                      </Router>
                    </div>
                    <ToastsContainer />
                    <ErrorDetailsModal />
                  </RefetchResourceContextProvider>
                </InPlaceFilesSavingContextWrapper>
              </ThemeWrapper>
            </HelmentContextWrapper>
          </IntlProviderWrapper>
        </JssProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
