import {
  SET_CURRENT_TABLE,
  APPLY_FILTERS,
  SET_ALL_FILTERS,
  SET_FILTERS_APPLIED_FOR_TABLE,
  SET_FILTER_PREDICATE_VALUE,
  SET_FILTER_PREDICATE,
  REMOVE_FILTER_COLUMN,
  SET_FILTER_COLUMN,
  SET_FILTER_PREDICATE_SET,
  SET_FILTER_SOURCE_ID,
  SET_FILTERS,
  RESET_FILTERS,
} from '../constants/filters.consts';
import { Action } from 'redux';
import { RootState, RootAction } from 'store/reducers';
import { ThunkAction } from 'redux-thunk';
import { updatePreferences } from './preferencesActions';
import cloneDeep from 'lodash/cloneDeep';
import {
  PredicateSet,
  SelectedPredicate,
  TPredicateTypes,
  TPredicateValue,
} from 'utils/types/predicates.types';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import {
  getCurrentTablePreferenceFilter,
  preferencesSelector,
} from 'store/selectors/preferencesSelectors';
import { PreferencesTypes } from 'utils/types/api/preferences.types';
import { isEqual } from 'lodash';
import {
  getCurrentTable,
  getCurrentTableFilters,
} from 'store/selectors/filtersSelectors';

export interface TableFiltersParams {
  id?: string;
  value: boolean;
}

export interface FilterValue {
  column: string;
  label: string;
  type: TPredicateTypes | '';
  predicateSet?: PredicateSet;
  predicates: string[];
  values?: FilterAllowedValues;
  value: {
    predicateKey: string;
    predicateValues: MappedObject<TPredicateValue>;
    predicateArgs: string[];
    isMultipleSelection?: boolean;
  };
}

export type AllowedFilterValue = { text: string; value: string };

export type FilterAllowedValues = string[] | AllowedFilterValue[];

export type Filter = FilterValue[];

export type FilterMap = MappedObject<Filter | MappedObject<Filter>>;

export type QuickFilterMap = {
  [CatalystTableType.Tasks]: { ownership: string | null; dates: string | null };
} & { [key in CatalystTableType]?: Object };

export interface SetAllFiltersAction extends Action<typeof SET_ALL_FILTERS> {
  payload: FilterMap;
}

export interface SetFiltersAction extends Action<typeof SET_FILTERS> {
  name: string;
  values: Filter;
}

export interface SetApplyFilterAction extends Action<typeof APPLY_FILTERS> {
  applyFilter: boolean;
  options?: { sendToApi?: boolean; isQuickFilter?: boolean };
}

export interface SetAreFiltersAppliedAction
  extends Action<typeof SET_FILTERS_APPLIED_FOR_TABLE> {
  payload: TableFiltersParams;
}

export interface SetCurrentTableAction
  extends Action<typeof SET_CURRENT_TABLE> {
  payload: CatalystTableType | undefined;
}

export interface SetFilterPredicateValueAction
  extends Action<typeof SET_FILTER_PREDICATE_VALUE> {
  payload: {
    tableName: string;
    columnName: string;
    value: MappedObject<TPredicateValue>;
  };
}

export interface SetFilterPredicateAction
  extends Action<typeof SET_FILTER_PREDICATE> {
  payload: {
    tableName: string;
    columnName: string;
    predicate: string;
    selectedPredicate: SelectedPredicate;
  };
}

export interface RemoveFilterColumnAction
  extends Action<typeof REMOVE_FILTER_COLUMN> {
  payload: {
    tableName: string;
    columnName: string;
  };
}

export interface SetFilterColumnAction
  extends Action<typeof SET_FILTER_COLUMN> {
  payload: {
    tableName: string;
    columnName: string;
    newColumnName: string;
    newColumnType: TPredicateTypes;
    predicates: string[];
    label: string;
    values?: FilterAllowedValues;
  };
}

export interface SetFilterPredicateSet
  extends Action<typeof SET_FILTER_PREDICATE_SET> {
  payload: {
    tableName: string;
    columnName: string;
    predicateSet: PredicateSet | undefined;
  };
}

export interface SetFilterSourceId extends Action<typeof SET_FILTER_SOURCE_ID> {
  payload: string | undefined;
}
export type ResetFilters = Action<typeof RESET_FILTERS>;

export type FiltersAction =
  | SetAllFiltersAction
  | SetFiltersAction
  | SetCurrentTableAction
  | SetApplyFilterAction
  | SetAreFiltersAppliedAction
  | SetFilterPredicateValueAction
  | SetFilterPredicateAction
  | RemoveFilterColumnAction
  | SetFilterColumnAction
  | SetFilterPredicateSet
  | SetFilterSourceId
  | ResetFilters;

export const setAllFilters = (tableFilters: FilterMap): SetAllFiltersAction => {
  return {
    type: SET_ALL_FILTERS,
    payload: cloneDeep(tableFilters),
  };
};

export const setFilters = (name: string, values: Filter): SetFiltersAction => {
  return {
    type: SET_FILTERS,
    name,
    values,
  };
};

export const setCurrentTable = (
  table: CatalystTableType | undefined
): SetCurrentTableAction => {
  return {
    type: SET_CURRENT_TABLE,
    payload: table,
  };
};

const getCustomQuickFilter = (
  currentTable: CatalystTableType,
  quickFilters: QuickFilterMap,
  setEmpty: boolean
): QuickFilterMap => {
  const currentQuickFilter = quickFilters[currentTable];
  if (!currentQuickFilter) return quickFilters;

  const customQuickFilters = Object.keys(currentQuickFilter).reduce(
    (acc, key) => ({ ...acc, [key]: setEmpty ? '' : null }),
    currentQuickFilter
  );

  return { ...quickFilters, [currentTable]: customQuickFilters };
};

export const setApplyFilter = (
  applyFilter: boolean,
  options: SetApplyFilterAction['options'] = {
    sendToApi: false,
    isQuickFilter: false,
  }
): ThunkAction<void, RootState, undefined, RootAction> => async (
  dispatch,
  getState
) => {
  if (options.sendToApi) {
    const { tables: filters, currentTable } = getState().filters;
    const { quickFilters } = preferencesSelector(
      getState(),
      PreferencesTypes.FilterPreferences
    );
    const { tablesState } = preferencesSelector(
      getState(),
      PreferencesTypes.TableLayoutPreferences
    );

    await Promise.all([
      dispatch(
        updatePreferences(PreferencesTypes.FilterPreferences, {
          filters: cloneDeep(filters),
          quickFilters:
            currentTable && !options?.isQuickFilter
              ? getCustomQuickFilter(
                  currentTable,
                  quickFilters,
                  !filters[currentTable].length
                )
              : quickFilters,
        })
      ),
      dispatch(
        updatePreferences(PreferencesTypes.TableLayoutPreferences, {
          tablesState: {
            ...tablesState,
            ...(currentTable
              ? {
                  [currentTable]: {
                    ...tablesState[currentTable],
                    currentPage: 1,
                  },
                }
              : {}),
          },
        })
      ),
    ]);
  }

  dispatch({
    type: APPLY_FILTERS,
    applyFilter,
  });
};

export const setAppliedFiltersForTable = (
  params: TableFiltersParams
): ThunkAction<void, RootState, undefined, RootAction> => dispatch => {
  dispatch({
    type: SET_FILTERS_APPLIED_FOR_TABLE,
    payload: params,
  });
};

export const clearFilters = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => (dispatch, getState) => {
  const { currentTable } = getState().filters;

  if (currentTable) {
    dispatch(setFilters(currentTable, []));
  }
};

export const resetCurrentFilters = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async (dispatch, getState) => {
  const { currentTable } = getState().filters;
  if (currentTable) {
    dispatch(setFilters(currentTable, []));
    dispatch(setApplyFilter(true, { sendToApi: true }));
  }
};

/**
 * Restores the current table filters from the preferences. If the filters are the same as in the
 * preferences or current table is not set, does nothing.
 */
export const restoreCurrentTableFilters = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => (dispatch, getState) => {
  const currentTable = getCurrentTable(getState());
  const filters = getCurrentTableFilters(getState());
  const preferenceFilters = getCurrentTablePreferenceFilter(getState());

  if (!currentTable) {
    return;
  }

  if (isEqual(filters, preferenceFilters)) {
    return;
  }

  dispatch(setFilters(currentTable, preferenceFilters));
};

export const setFilterPredicateValue = (
  tableName: string,
  columnName: string,
  value: MappedObject<TPredicateValue>
): SetFilterPredicateValueAction => {
  return {
    type: SET_FILTER_PREDICATE_VALUE,
    payload: {
      tableName,
      columnName,
      value,
    },
  };
};

export const setFilterPredicate = (
  tableName: string,
  columnName: string,
  predicate: string,
  selectedPredicate: SelectedPredicate
): SetFilterPredicateAction => {
  return {
    type: SET_FILTER_PREDICATE,
    payload: {
      tableName,
      columnName,
      predicate,
      selectedPredicate,
    },
  };
};

export const removeFilterColumn = (
  tableName: string,
  columnName: string
): RemoveFilterColumnAction => {
  return {
    type: REMOVE_FILTER_COLUMN,
    payload: {
      tableName,
      columnName,
    },
  };
};

export const setFilterColumn = (
  tableName: string,
  columnName: string,
  newColumnName: string,
  newColumnType: TPredicateTypes,
  label: string,
  predicates: string[],
  values?: FilterAllowedValues
): SetFilterColumnAction => {
  return {
    type: SET_FILTER_COLUMN,
    payload: {
      tableName,
      columnName,
      newColumnName,
      newColumnType,
      label,
      predicates,
      values,
    },
  };
};

export const setFilterPredicateSet = (
  tableName: string,
  columnName: string,
  predicateSet: PredicateSet | undefined
): SetFilterPredicateSet => {
  return {
    type: SET_FILTER_PREDICATE_SET,
    payload: {
      tableName,
      columnName,
      predicateSet,
    },
  };
};

export const setFilterSourceId = (
  payload: string | undefined
): SetFilterSourceId => {
  return {
    type: SET_FILTER_SOURCE_ID,
    payload,
  };
};

export const resetFilters = (): ResetFilters => {
  return {
    type: RESET_FILTERS,
  };
};
