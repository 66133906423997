import { FormMode } from 'utils/Enums/FormMode';
import { FlatValidatorsObject } from 'utils/types';
import { Sequence } from 'utils/types/api/sequences.types';
import { Stage, StageNames, StagesOptions } from 'utils/types/api/tasks.types';
import {
  OptionAutocomplete,
  OptionSelect,
} from 'utils/types/selectInput.types';

export enum SequencesFormFields {
  Name = 'name',
  Description = 'description',
  ObjectClass = 'object_class',
}

export interface SequencesForm {
  [SequencesFormFields.Name]: string;
  [SequencesFormFields.Description]?: string;
  [SequencesFormFields.ObjectClass]?: string;
}

export interface SequencesFormHeaderProps {
  isLimitExceeded?: boolean;
  validators: FlatValidatorsObject | undefined;
  mode: FormMode;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setInitialValues: React.Dispatch<React.SetStateAction<SequencesForm>>;
  id?: string;
}

export interface SequenceFormProps {
  data?: Sequence;
  mode: FormMode;
}

export interface SequenceDefaultAssigneesStagesOptions {
  batch: Pick<StagesOptions, 'required' | 'autocomplete' | 'type'>;
  restrictions: Omit<StagesOptions, 'required' | 'autocomplete' | 'type'>;
}

export interface SequenceDefaultTaskAssigneesStagesSchema {
  [StageNames.Stage1]: StagesOptions;
}

export interface SequenceFormSchema {
  [SequencesFormFields.Name]: Omit<OptionSelect, 'values'>;
  [SequencesFormFields.Description]: Omit<OptionSelect, 'values'>;
  [SequencesFormFields.ObjectClass]: Omit<OptionSelect, 'values'> &
    OptionAutocomplete;
}

export interface SequenceObjectClassFieldProps {
  mode: FormMode;
  rawValidationSchema: FlatValidatorsObject | undefined;
  objectClassLabel?: string;
}

export type StageDefaultAssignees = Omit<Stage, 'status'>;
