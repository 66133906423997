import { StatusCodes } from 'http-status-codes';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  APPEND_OBJECT_CLASSES,
  RESET_OBJECT_CLASSES,
  SET_OBJECT_CLASSES_FETCHING,
  SET_OBJECT_CLASSES,
  SET_OBJECT_CLASSES_COLUMNS,
  RESET_OBJECT_CLASSES_COLUMNS,
  SET_OBJECT_CLASSES_SELECTED_ROW,
  SET_OBJECT_CLASSES_RESTRICTIONS,
  SET_OBJECT_CLASS_FIELDS_RESTRICTIONS,
  SET_OBJECT_CLASSES_RESPONSE,
} from 'store/constants/objectClasses.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import { OBJECT_CLASSES_LIST } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import DataFetchType from 'utils/Enums/DataFetchType';
import { OptionsResponse } from 'utils/types';
import { createTableUrl } from 'utils/functions/createTableUrl';
import {
  ObjectClass,
  ObjectClassesResponse,
} from 'utils/types/api/objectClasses.types';
import {
  SetTableAction,
  ActionObject,
  ColumnsMetadata,
  Restrictions,
} from 'utils/types/api/table.types';

export type SetObjectClassesAction = SetTableAction<
  Pick<ActionObject<ObjectClass>, 'list' | 'total' | 'filtered'>,
  typeof SET_OBJECT_CLASSES
>;

export type AppendObjectClassesAction = SetTableAction<
  Pick<ActionObject<ObjectClass>, 'list' | 'total' | 'filtered'>,
  typeof APPEND_OBJECT_CLASSES
>;

export type SetObjectClassesFetchingAction = SetTableAction<
  Pick<ActionObject<ObjectClass>, 'fetching'>,
  typeof SET_OBJECT_CLASSES_FETCHING
>;

export type SetObjectClassesColumnsAction = SetTableAction<
  Pick<ActionObject<ObjectClass>, 'payload'>,
  typeof SET_OBJECT_CLASSES_COLUMNS
>;

export type ResetObjectClassColumnsAction = Action<
  typeof RESET_OBJECT_CLASSES_COLUMNS
>;

export type ResetObjectClassesAction = Action<typeof RESET_OBJECT_CLASSES>;

export type SetSelectedRowAction = SetTableAction<
  Pick<ActionObject<ObjectClass>, 'selectedRow'>,
  typeof SET_OBJECT_CLASSES_SELECTED_ROW
>;

export type SetObjectClassesRestrictionsAction = SetTableAction<
  { restrictions: Restrictions },
  typeof SET_OBJECT_CLASSES_RESTRICTIONS
>;

export type SetObjectClassFieldsRestrictionsAction = SetTableAction<
  { restrictions: Restrictions },
  typeof SET_OBJECT_CLASS_FIELDS_RESTRICTIONS
>;

export type SetObjectClassesResponse = Action<
  typeof SET_OBJECT_CLASSES_RESPONSE
> & {
  payload: ObjectClassesResponse;
};

export type ObjectClassesAction =
  | SetObjectClassesAction
  | SetObjectClassesFetchingAction
  | AppendObjectClassesAction
  | ResetObjectClassesAction
  | SetObjectClassesColumnsAction
  | ResetObjectClassColumnsAction
  | SetSelectedRowAction
  | SetObjectClassesRestrictionsAction
  | SetObjectClassesResponse;

export const setObjectClasses = (
  list: ObjectClass[],
  total: number,
  filtered: number
): SetObjectClassesAction => {
  return {
    type: SET_OBJECT_CLASSES,
    list,
    total,
    filtered,
  };
};

export const appendObjectClasses = (
  list: ObjectClass[],
  total: number,
  filtered: number
): AppendObjectClassesAction => {
  return {
    type: APPEND_OBJECT_CLASSES,
    list,
    total,
    filtered,
  };
};

export const setFetchingObjectClasses = (
  fetching: boolean
): SetObjectClassesFetchingAction => {
  return {
    type: SET_OBJECT_CLASSES_FETCHING,
    fetching,
  };
};

export const resetObjectClasses = (): ResetObjectClassesAction => {
  return {
    type: RESET_OBJECT_CLASSES,
  };
};

export const resetObjectClassesColumns = (): ResetObjectClassColumnsAction => {
  return {
    type: RESET_OBJECT_CLASSES_COLUMNS,
  };
};

export const getObjectClasses = (
  queryParams?: string,
  fetchType:
    | DataFetchType.Append
    | DataFetchType.Overwrite = DataFetchType.Overwrite
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(setFetchingObjectClasses(true));
  try {
    const { status, data } = await apiCall.get(
      createTableUrl(OBJECT_CLASSES_LIST, queryParams)
    );

    if (status === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setObjectClasses(data.results, data.total_count, data.filtered_count)
        );
      } else {
        dispatch(
          appendObjectClasses(
            data.results,
            data.total_count,
            data.filtered_count
          )
        );
      }
    }
  } catch (error) {
    dispatch(setFetchingObjectClasses(false));
    showUnhandledErrorToast(error);
  }
};

export const setObjectClassesColumns = (
  columns: ColumnsMetadata[]
): SetObjectClassesColumnsAction => {
  return {
    type: SET_OBJECT_CLASSES_COLUMNS,
    payload: columns,
  };
};

export const getObjectClassColumnConfiguration = (): ThunkAction<
  void,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  try {
    const {
      status,
      data: {
        list: { columns },
        restrictions,
      },
    } = await apiCall.options<OptionsResponse>(OBJECT_CLASSES_LIST);

    if (status === StatusCodes.OK) {
      dispatch(setObjectClassesColumns(columns));

      if (restrictions !== undefined) {
        dispatch(setObjectClassesRestrictions(restrictions));
      }
    }
  } catch {}
};

export const setObjectClassesSelectedRow = (
  data: string | undefined
): SetSelectedRowAction => ({
  type: SET_OBJECT_CLASSES_SELECTED_ROW,
  selectedRow: data,
});

export const setObjectClassesRestrictions = (
  restrictions: Restrictions
): SetObjectClassesRestrictionsAction => ({
  type: SET_OBJECT_CLASSES_RESTRICTIONS,
  restrictions,
});

export const setObjectClassFieldsRestrictions = (
  restrictions: Restrictions
): SetObjectClassFieldsRestrictionsAction => ({
  type: SET_OBJECT_CLASS_FIELDS_RESTRICTIONS,
  restrictions,
});

export const setObjectClassesResponse = (payload: ObjectClassesResponse) => ({
  type: SET_OBJECT_CLASSES_RESPONSE,
  payload,
});
