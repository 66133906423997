import React, { ChangeEvent } from 'react';
import { Input } from 'components/lib/Input';
import { useIntl } from 'react-intl';
import { FilterTextValueProps } from './FilterTextValue.types';

export const FilterTextValue = ({
  value,
  testId,
  onChange,
}: FilterTextValueProps) => {
  const intl = useIntl();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Input
      value={value}
      onChange={handleChange}
      placeholder={intl.formatMessage({
        id: 'placeholders.enterText',
        defaultMessage: 'Enter text',
      })}
      data-testid={testId}
    />
  );
};
