export enum ScrollableParentSeekMode {
  /**
   * Will search the tree up to find the closest parent container that is scrollable.
   */
  AnyClosest,
  /**
   * Will search the tree up to find the closest parent container from the predefined scrollable list in ScrollableContainers const or provided in customScrollableParentIds
   */
  PredefinedList,
}
