import React from 'react';
import { ObjClassRecordUsageViewProps } from './ObjClassRecordUsageView.types';
import { FormattedMessage } from 'react-intl';
import { BoldTextPartsFallback } from 'utils/intl/fallbacks/BoldTextParts';
import useFormatNumber from 'hooks/useFormatNumber';

export const ObjClassRecordUsageView = ({
  numOfRecords,
}: ObjClassRecordUsageViewProps) => {
  const formatNumberWithSeparators = useFormatNumber();

  return (
    <span>
      <FormattedMessage
        id='fieldUsage.countRecords'
        defaultMessage='The class has <b>{formattedCount}</b> {count, plural, one {record} other {records}}.'
        values={{
          b: BoldTextPartsFallback,
          count: numOfRecords,
          formattedCount: formatNumberWithSeparators(numOfRecords),
        }}
      />
    </span>
  );
};
