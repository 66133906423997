import { createUseStyles } from 'react-jss';

const COMMON_PADDING = '16px';

export const useReassignTaskStyles = createUseStyles({
  panelWrapper: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',

    height: '100%',
  },
  header: {
    padding: COMMON_PADDING,
  },
  tabs: {
    '& .ant-tabs-nav': {
      margin: 0,
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-tab': {
      paddingLeft: COMMON_PADDING,
      paddingRight: COMMON_PADDING,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',

    padding: COMMON_PADDING,
  },
});
