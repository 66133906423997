import { AvatarItem } from 'components/lib/Avatar/types';
import { UserGroupAssignee } from 'utils/types/api/userGroups.types';

/**
 * Transforms group list into group avatars and then sorts them ascending (a-z) by name.
 *
 * @param groups Group list returned from the api.
 *
 * @returns Alphabetically sorted group avatars.
 */
export const getSortedGroupAvatars = (
  groups: UserGroupAssignee[]
): AvatarItem[] => {
  const groupAvatars = groups.map(group => ({
    id: 'user_group' in group ? group.user_group.id : group.id,
    userGroup: 'user_group' in group ? group.user_group.name : group.name,
  }));

  return groupAvatars.sort(
    ({ userGroup: firstGroupName }, { userGroup: secondGroupName }) => {
      return firstGroupName.localeCompare(secondGroupName);
    }
  );
};
