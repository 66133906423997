import React, { useCallback } from 'react';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { ScrollableContainers } from './consts';
import { ScrollableParentSeekMode } from './enums';
import { getHtmlElementEvalFunction } from './utils';

/**
 * Searches the virtual DOM for a scrollable container (from a predefined known list of scrollable containers in ALX app) starting from the
 * provided child node up the tree until reaching the document body.
 * @param elementRef Ref to child element from which the search should start.
 * @param customScrollableParentIds More scrollable containers can be provided for lookup in the form of array of their IDs if the seek mode is set to PredefinedList
 * @param seekMode Mode on how should the scrollable parent be searched for.
 */
export const useScrollableParent = (
  elementRef: React.RefObject<HTMLElement>,
  customScrollableParentIds?: string[],
  seekMode: ScrollableParentSeekMode = ScrollableParentSeekMode.PredefinedList
) => {
  const { windowRef } = useWindowContext();

  const getScrollableParent = useCallback(() => {
    let checkedElement = elementRef.current?.parentElement;

    const scrollableElements = [
      ...ScrollableContainers,
      ...(customScrollableParentIds ?? []),
    ];

    const elementEvalFunction = getHtmlElementEvalFunction(
      seekMode,
      scrollableElements
    );

    while (checkedElement) {
      if (elementEvalFunction(checkedElement)) {
        return checkedElement;
      }

      checkedElement = checkedElement.parentElement;
    }

    return windowRef?.document?.body ?? document.body;
  }, [elementRef, customScrollableParentIds, windowRef, seekMode]);

  return { getScrollableParent };
};
