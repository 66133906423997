import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { isNil, isNumber } from 'lodash';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ClassFieldForm } from '../../../../../types';

export const useHandleMaxMinValidation = () => {
  const {
    setFieldValue,
    values: {
      default_value: defaultVal,
      min_value: minValue,
      max_value: maxValue,
      type,
    },
  } = useFormikContext<ClassFieldForm>();

  const isDefaultValueInvalid = useCallback(
    (newValue: number, newValueKey: string) => {
      if (isNil(defaultVal)) {
        return false;
      }

      const defaultValue = isNumber(defaultVal)
        ? defaultVal
        : parseFloat(defaultVal);

      switch (newValueKey) {
        case ClassFieldFormFields.MinValue:
          return defaultValue < newValue;
        case ClassFieldFormFields.MaxValue:
          return defaultValue > newValue;
        default:
          return false;
      }
    },
    [defaultVal]
  );

  const isMinValueExceedingMax = useCallback(
    (newValue: number, newValueKey: string) => {
      if (newValueKey !== ClassFieldFormFields.MinValue) {
        return false;
      }

      return maxValue && newValue > maxValue;
    },
    [maxValue]
  );

  const isMaxValueExceedingMin = useCallback(
    (newValue: number, newValueKey: string) => {
      if (newValueKey !== ClassFieldFormFields.MaxValue) {
        return false;
      }

      return minValue && newValue < minValue;
    },
    [minValue]
  );

  return useCallback(
    (newValueKey: string, newValue?: number) => {
      if (isNil(newValue)) {
        return;
      }

      if (isDefaultValueInvalid(newValue, newValueKey)) {
        setFieldValue(ClassFieldFormFields.DefaultValue, undefined);
      }

      const valueToSet = type === 'int' ? Math.floor(newValue) : newValue;

      if (isMinValueExceedingMax(newValue, newValueKey)) {
        setFieldValue(ClassFieldFormFields.MaxValue, valueToSet);
      }

      if (isMaxValueExceedingMin(newValue, newValueKey)) {
        setFieldValue(ClassFieldFormFields.MinValue, valueToSet);
      }
    },
    [
      isDefaultValueInvalid,
      isMinValueExceedingMax,
      isMaxValueExceedingMin,
      type,
      setFieldValue,
    ]
  );
};
