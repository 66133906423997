// eslint-disable-next-line
import { UsersAndGroupsExpandableSelect } from '../UsersAndGroupsExpandableSelect';

/**
 * Controls how {@link UsersAndGroupsExpandableSelect} search bar behaves when the component is unfocused.
 */
export enum SearchBarDisplay {
  /**
   * Always shows the search bar. Not displaying any selected avatars.
   */
  Always = 'always',
  /**
   * Never shows the search bar. Only displays selected avatars and '-' when nothing is selected.
   */
  Never = 'never',
  /**
   * Default behavior. Shows the search bar when there is no selection. Otherwise, displays selected avatars.
   */
  Default = 'default',
}
