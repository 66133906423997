import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { showSuccessToast } from 'features/toasts/utils/showSuccessToast';

export const showUsersSuccessfulyAssignedToast = (
  resourceName: string,
  assignedUsersCount: number
) => {
  showSuccessToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.success',
      defaultMessage: 'Success!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage(
      {
        id: 'misc.successfulyAssigedUsersTo',
        defaultMessage:
          '{count, plural, one {# user has} other {# users have}} been assigned to {name}.',
      },
      { count: assignedUsersCount, name: resourceName }
    ),
  });
};
