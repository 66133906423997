import { useTranslateError } from 'components/FormPreview2/translateError';
import { ErrorKeys, FormValue } from 'alx-dynamic-form';
import { useCallback } from 'react';
import { isNil } from 'lodash';

/**
 * Provides local validation for FormField value for the required constraint.
 * @param fieldLabel Visual name of the form field to show in the error message as <field name> is required.
 * @param isRequired Is required constraint.
 */
export const useFieldRequiredValidation = (
  fieldLabel: string,
  isRequired: boolean | undefined
) => {
  const translateError = useTranslateError();

  const validateRequired = useCallback(
    (fieldValue: FormValue | null) => {
      if (isRequired && isNil(fieldValue)) {
        return [translateError(ErrorKeys.REQUIRED, fieldValue, fieldLabel)];
      }

      return [];
    },
    [fieldLabel, isRequired, translateError]
  );

  return { validateRequired };
};
