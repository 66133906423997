import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonSecondaryOutlined } from 'components/lib/Button';
import { BUTTON_CLOSE_TESTID } from 'utils/testIds';
import { TasksPanelMode } from '../../types';
import { Loader } from 'components/lib/Loader';
import ErrorComponent from 'components/ErrorComponent';
import { useReassignTaskTabs } from './hooks/useReassignTaskTabs';
import { useTasksPanelContext } from '../../contexts/TasksPanelContext';
import { ReassignTaskHeader } from '../ReassignTaskHeader';
import { useReassignTaskStyles } from './ReassignTask.styles';
import { TabPane, Tabs } from 'components/lib/Tabs';
import { ManageTaskUserAssignees } from './components/ManageTaskUserAssignees';
import { ManageTaskGroupAssignees } from './components/ManageTaskGroupAssignees';

export const ReassignTask = () => {
  const styles = useReassignTaskStyles();

  const {
    taskDetails,
    taskAssignees,
    areTaskDetailsLoading,
    taskDetailsErrorStatus,
    setMode,
  } = useTasksPanelContext();

  const {
    activeTab,
    usersTab,
    groupsTab,
    handleTabChange,
  } = useReassignTaskTabs(
    taskAssignees.users.length,
    taskAssignees.userGroups.length
  );

  if (taskDetailsErrorStatus) {
    return <ErrorComponent error={taskDetailsErrorStatus} />;
  }

  return (
    <Loader spinning={areTaskDetailsLoading}>
      <div className={styles.panelWrapper}>
        <ReassignTaskHeader
          taskName={taskDetails?.name ?? '-'}
          recordId={taskDetails?.object_record}
        />

        <Tabs
          className={styles.tabs}
          activeKey={activeTab}
          destroyInactiveTabPane
          onChange={handleTabChange}
        >
          <TabPane key={usersTab.key} tab={usersTab.label}>
            <ManageTaskUserAssignees />
          </TabPane>
          <TabPane
            key={groupsTab.key}
            tab={groupsTab.label}
            disabled={groupsTab.isDisabled}
          >
            <ManageTaskGroupAssignees />
          </TabPane>
        </Tabs>

        <footer className={styles.footer}>
          <ButtonSecondaryOutlined
            onClick={() => setMode(TasksPanelMode.List)}
            data-testid={BUTTON_CLOSE_TESTID}
          >
            <FormattedMessage id='misc.close' defaultMessage='Close' />
          </ButtonSecondaryOutlined>
        </footer>
      </div>
    </Loader>
  );
};
