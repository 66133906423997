import React from 'react';
import { TaskTemplateTaskUsageViewProps } from './TaskTemplateTaskUsageView.types';
import { FormattedMessage } from 'react-intl';

export const TaskTemplateTaskUsageView = ({
  numOfTasksCreated,
}: TaskTemplateTaskUsageViewProps) => {
  return (
    <FormattedMessage
      id='taskTemplates.createdWithThisTemplate'
      defaultMessage='{tasksAmmount} tasks have been created with the template.'
      values={{ tasksAmmount: numOfTasksCreated }}
    />
  );
};
