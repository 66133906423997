import React from 'react';
import { toast } from 'react-toastify';

import { StandardToastContent } from '../components/ToastContent/views/StandardToastContent';
import { ToastText } from '../types/toastText';
import { ToastType } from '../types/toastType';

/**
 * Shows an error toast.
 *
 * @param text The text to display in the toast.
 */
export const showErrorToast = (text: ToastText) => {
  toast.error(
    <StandardToastContent
      title={text.title}
      subtitle={text.subtitle}
      type={ToastType.Error}
    />
  );
};
