import { AvatarItem } from 'components/lib/Avatar/types';
import { StatusCodes } from 'http-status-codes';
import { generatePath } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  SET_USER_GROUP_PERMISSIONS_OWNERS,
  SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING,
  SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS,
  USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF,
  SET_USER_GROUP_SELECTED_PERMISSION_SET,
  CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS,
  SET_USER_GROUP_PERMISSIONS_USER_GROUP,
  SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS,
  SET_USER_GROUP_PERMISSIONS_PERMISSION_SET,
  SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING,
  RESET_USER_GROUP_PERMISSIONS_STATE,
  REMOVE_USER_GROUP_PERMISSION_SET,
  CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING,
  SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS,
  SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS,
} from 'store/constants/userGroupPermissions.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import {
  USER_GROUP_USERS,
  USER_GROUP_PERMISSION_SET_ASSIGNEES,
  USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS,
  USER_GROUP,
  USER_GROUP_PERMISSION_SETS,
} from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { GetResponse, OptionsResponse } from 'utils/types';
import {
  ObjectClassPermissionsOwnersOptions,
  SelectedPermissionSetColumn,
} from 'store/reducers/types/objectClassPermissions';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';
import { UserGroup, UserGroupAssignee } from 'utils/types/api/userGroups.types';
import {
  PermissionSetFromAPI,
  PermissionSetSchema,
  PermissionSetsFromAPI,
} from 'pages/UserGroups/components/Permissions/types';
import { UserGroupPermissionSetOptions } from 'store/reducers/types/userGroupPermissions.types';
import { mapTaskOptionsToDictionary } from 'pages/TaskTemplates/utils';
import { getSortedGroupAvatars } from 'utils/functions/getSortedGroupAvatars';

interface SetUserGrupsPermissionsOwnersFetchingAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING> {
  payload: boolean;
}

interface SetUserGroupPermissionsOwnersAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_OWNERS> {
  payload: {
    list: ObjectClassOwner[];
    totalCount: number;
    avatars: AvatarItem[];
  };
}

interface SetUserGroupPermissionSetAssigneesAction
  extends Action<typeof SET_USER_GROUP_PERMISSION_SET_ASSIGNEES> {
  payload: {
    permissionSetId: string;
    list: ObjectClassOwner[];
    totalCount: number;
    avatars: AvatarItem[];
  };
}
interface SetGroupPermissionSetAssigneesOptionsAction
  extends Action<typeof SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS> {
  payload: {
    permissionSetId: string;
    options: ObjectClassPermissionsOwnersOptions;
  };
}
interface SetUserGroupPermissionsOwnersOptionsAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS> {
  payload: ObjectClassPermissionsOwnersOptions;
}
interface SetUserGroupPermissionSetAssigneesFetchingAction
  extends Action<typeof SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING> {
  payload: { permissionSetId: string; fetching: boolean };
}

interface SetUserGroupPermissionsGroupOwnersIsRemovingHimselfAction
  extends Action<typeof USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF> {
  payload: boolean;
}

interface SetUserGroupSelectedPermissionSetAction
  extends Action<typeof SET_USER_GROUP_SELECTED_PERMISSION_SET> {
  payload: SelectedPermissionSetColumn | undefined;
}

interface SetUserGroupPermissionsUserGroupAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_USER_GROUP> {
  payload: UserGroup | undefined;
}

interface SetUserGroupPermissionsPermissionSetsAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS> {
  payload: PermissionSetFromAPI[];
}

interface SetUserGroupPermissionsPermissionSetAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_PERMISSION_SET> {
  payload: PermissionSetFromAPI;
}

interface SetUserGroupPermissionPermissionSetsFetchingAction
  extends Action<typeof SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING> {
  payload: boolean;
}

type ClearUserGroupPermissionSetsAssignees = Action<
  typeof CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES
>;

type ResetUsergroupPermissionsStateAction = Action<
  typeof RESET_USER_GROUP_PERMISSIONS_STATE
>;

interface RemoveUserGroupPermissionsPermissionSetAction
  extends Action<typeof REMOVE_USER_GROUP_PERMISSION_SET> {
  payload: number;
}

interface SetUserGroupPermissionSetAssigneesGroupsAction
  extends Action<typeof SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS> {
  payload: {
    permissionSetId: string;
    list: UserGroupAssignee[];
    totalCount: number;
    avatars: AvatarItem[];
  };
}
interface SetGroupPermissionSetAssigneesGroupsOptionsAction
  extends Action<
    typeof SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS
  > {
  payload: {
    permissionSetId: string;
    options: ObjectClassPermissionsOwnersOptions;
  };
}
interface SetUserGroupPermissionSetAssigneesGroupsFetchingAction
  extends Action<
    typeof SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING
  > {
  payload: { permissionSetId: string; fetching: boolean };
}

type ClearUserGroupPermissionSetsAssigneesGroups = Action<
  typeof CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS
>;

interface SetUserGroupPermissionSetsOptionsAction
  extends Action<
    typeof SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS
  > {
  payload: UserGroupPermissionSetOptions;
}
export type UserGroupPermissionsActions =
  | SetUserGrupsPermissionsOwnersFetchingAction
  | SetUserGroupPermissionsOwnersAction
  | SetUserGroupPermissionSetAssigneesAction
  | SetUserGroupPermissionsOwnersOptionsAction
  | SetGroupPermissionSetAssigneesOptionsAction
  | SetUserGroupPermissionSetAssigneesFetchingAction
  | SetUserGroupPermissionsGroupOwnersIsRemovingHimselfAction
  | SetUserGroupSelectedPermissionSetAction
  | ClearUserGroupPermissionSetsAssignees
  | SetUserGroupPermissionsUserGroupAction
  | SetUserGroupPermissionsPermissionSetsAction
  | SetUserGroupPermissionsPermissionSetAction
  | SetUserGroupPermissionPermissionSetsFetchingAction
  | RemoveUserGroupPermissionsPermissionSetAction
  | ResetUsergroupPermissionsStateAction
  | SetUserGroupPermissionSetAssigneesGroupsAction
  | SetGroupPermissionSetAssigneesGroupsOptionsAction
  | SetUserGroupPermissionSetAssigneesGroupsFetchingAction
  | ClearUserGroupPermissionSetsAssigneesGroups
  | SetUserGroupPermissionSetsOptionsAction;

export const setUserGrupsPermissionsOwnersFetching = (
  payload: boolean
): SetUserGrupsPermissionsOwnersFetchingAction => ({
  type: SET_USER_GROUP_PERMISSIONS_OWNERS_FETCHING,
  payload,
});

export const setUserGroupPermissionsOwners = (
  list: ObjectClassOwner[],
  totalCount: number,
  avatars: AvatarItem[]
): SetUserGroupPermissionsOwnersAction => ({
  type: SET_USER_GROUP_PERMISSIONS_OWNERS,
  payload: { list, totalCount, avatars },
});

export const setUserGroupPermissionsOwnersOptions = (
  payload: ObjectClassPermissionsOwnersOptions
): SetUserGroupPermissionsOwnersOptionsAction => ({
  type: SET_USER_GROUP_PERMISSIONS_OWNERS_OPTIONS,
  payload,
});

export const setUserGroupPermissionsAsigneesOptions = (
  permissionSetId: string,
  options: ObjectClassPermissionsOwnersOptions
): SetGroupPermissionSetAssigneesOptionsAction => ({
  type: SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_OPTIONS,
  payload: {
    permissionSetId,
    options,
  },
});

export const setUserGroupPermissionSetAssignees = (
  permissionSetId: string,
  list: ObjectClassOwner[],
  totalCount: number,
  avatars: AvatarItem[]
): SetUserGroupPermissionSetAssigneesAction => ({
  type: SET_USER_GROUP_PERMISSION_SET_ASSIGNEES,
  payload: { permissionSetId, list, totalCount, avatars },
});

export const setUserGroupPermissionSetAssigneesFetching = (
  permissionSetId: string,
  fetching: boolean
): SetUserGroupPermissionSetAssigneesFetchingAction => ({
  type: SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_FETCHING,
  payload: { permissionSetId, fetching },
});

export const setUserGroupPermissionsGroupOwnersIsRemovingHimself = (
  payload: boolean
): SetUserGroupPermissionsGroupOwnersIsRemovingHimselfAction => ({
  type: USER_GROUP_PERMISSIONS_IS_REMOVING_HIMSELF,
  payload,
});

export const setUserGroupPermissionsUserGroup = (
  payload: UserGroup
): SetUserGroupPermissionsUserGroupAction => ({
  type: SET_USER_GROUP_PERMISSIONS_USER_GROUP,
  payload,
});

export const setUserGroupPermissionsPermissionSets = (
  payload: PermissionSetFromAPI[]
): SetUserGroupPermissionsPermissionSetsAction => ({
  type: SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS,
  payload,
});

export const setUserGroupPermissionsPermissionSet = (
  payload: PermissionSetFromAPI
): SetUserGroupPermissionsPermissionSetAction => ({
  type: SET_USER_GROUP_PERMISSIONS_PERMISSION_SET,
  payload,
});

export const setUserGroupPermissionsPermissionSetsFetching = (
  payload: boolean
): SetUserGroupPermissionPermissionSetsFetchingAction => ({
  type: SET_USER_GROUP_PERMISSIONS_PERMISSION_SETS_FETCHING,
  payload,
});

export const resetUsergroupPermissionsState = (): ResetUsergroupPermissionsStateAction => {
  return { type: RESET_USER_GROUP_PERMISSIONS_STATE };
};

export const removeUserGroupPermissionsPermissionSet = (
  payload: number
): RemoveUserGroupPermissionsPermissionSetAction => ({
  type: REMOVE_USER_GROUP_PERMISSION_SET,
  payload,
});

export const setUserGroupPermissionsAsigneesGroupOptions = (
  permissionSetId: string,
  options: ObjectClassPermissionsOwnersOptions
): SetGroupPermissionSetAssigneesGroupsOptionsAction => ({
  type: SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_OPTIONS,
  payload: {
    permissionSetId,
    options,
  },
});

export const setUserGroupPermissionSetAssigneesGroups = (
  permissionSetId: string,
  list: UserGroupAssignee[],
  totalCount: number,
  avatars: AvatarItem[]
): SetUserGroupPermissionSetAssigneesGroupsAction => ({
  type: SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS,
  payload: { permissionSetId, list, totalCount, avatars },
});

export const setUserGroupPermissionSetAssigneesGroupsFetching = (
  permissionSetId: string,
  fetching: boolean
): SetUserGroupPermissionSetAssigneesGroupsFetchingAction => ({
  type: SET_USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS_FETCHING,
  payload: { permissionSetId, fetching },
});

export const setUserGroupPermissionSetsOptions = (
  payload: UserGroupPermissionSetOptions
): SetUserGroupPermissionSetsOptionsAction => ({
  type: SET_USER_GROUP_PERMISSIONS_USER_GROUP_PERMISSION_SET_OPTIONS,
  payload,
});

export const clearUserGroupPermissionSetsAssigneesGroups = (): ClearUserGroupPermissionSetsAssigneesGroups => ({
  type: CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS,
});

const getSortedUserAvatars = (results: ObjectClassOwner[]) =>
  results
    .map(
      ({
        id: ownershipId,
        user: {
          id,
          first_name: firstName,
          last_name: lastName,
          company_name: company,
          username: email,
        },
      }) => ({
        id,
        ownershipId,
        firstName,
        lastName,
        company,
        email,
      })
    )
    // sort alphabetically by firstName, next by lastName [a-z]
    .sort(
      (
        { firstName, lastName },
        { firstName: secondUserFirstName, lastName: secondUserLastName }
      ) => {
        const compareFirstNames = firstName.localeCompare(secondUserFirstName);

        return compareFirstNames !== 0
          ? compareFirstNames
          : lastName.localeCompare(secondUserLastName);
      }
    );

const getAllAssignees = async <t>(
  path: string,
  resultsAcc: t[]
): Promise<{
  results: t[];
  totalCount: number;
}> => {
  const {
    status,
    data: { results, total_count, next },
  } = await apiCall.get<GetResponse<any>>(path);
  if (status !== StatusCodes.OK) {
    return {
      results,
      totalCount: results?.length ?? 0,
    };
  }
  if (!next) {
    return { results: [...resultsAcc, ...results], totalCount: total_count };
  }
  const { pathname, searchParams } = new URL(next);
  return await getAllAssignees(`${pathname}?${searchParams}`, [
    ...resultsAcc,
    ...results,
  ]);
};

const transformOwnersData = (result: any) => {
  return {
    id: result.id,
    user: result,
  };
};

export const getUserGroupPermissionsGroupOwners = (
  userGroupId: string
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  dispatch(setUserGrupsPermissionsOwnersFetching(true));

  try {
    const { results, totalCount } = await getAllAssignees(
      generatePath(`${USER_GROUP_USERS}?membership=owner`, {
        id: userGroupId,
      }),
      []
    );

    const transformedResults = results.map(result =>
      transformOwnersData(result)
    ) as ObjectClassOwner[];

    dispatch(
      setUserGroupPermissionsOwners(
        transformedResults,
        totalCount,
        getSortedUserAvatars(transformedResults)
      )
    );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(setUserGrupsPermissionsOwnersFetching(false));
  }
};

export const getUserGroupPermissionsOwnersConfiguration = (
  userGroupId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(setUserGrupsPermissionsOwnersFetching(true));

  try {
    const {
      status,
      data: { restrictions, batch },
    } = await apiCall.options<OptionsResponse>(
      generatePath(USER_GROUP_USERS, {
        id: userGroupId,
      })
    );

    if (
      status === StatusCodes.OK &&
      restrictions !== undefined &&
      batch !== undefined
    )
      dispatch(setUserGroupPermissionsOwnersOptions({ restrictions, batch }));
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(setUserGrupsPermissionsOwnersFetching(false));
  }
};

export const setUserGroupSelectedPermissionSet = (
  payload: SelectedPermissionSetColumn | undefined
): SetUserGroupSelectedPermissionSetAction => ({
  type: SET_USER_GROUP_SELECTED_PERMISSION_SET,
  payload,
});

export const clearUserGroupPermissionSetsAssignees = (): ClearUserGroupPermissionSetsAssignees => ({
  type: CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES,
});

export const getUserGroupPermissionSetAssignees = (
  userGroupId: string,
  permissionSetId: string
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  dispatch(setUserGroupPermissionSetAssigneesFetching(permissionSetId, true));
  try {
    const { results, totalCount } = await getAllAssignees(
      generatePath(USER_GROUP_PERMISSION_SET_ASSIGNEES, {
        groupId: userGroupId,
        permissionSetId,
      }),
      []
    );

    dispatch(
      setUserGroupPermissionSetAssignees(
        permissionSetId,
        results,
        totalCount,
        getSortedUserAvatars(results)
      )
    );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(
      setUserGroupPermissionSetAssigneesFetching(permissionSetId, false)
    );
  }
};

export const getUserGroupPermissionSetAssigneesConfiguration = (
  groupId: string,
  permissionSetId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const {
      status,
      data: { restrictions, batch },
    } = await apiCall.options<OptionsResponse>(
      generatePath(USER_GROUP_PERMISSION_SET_ASSIGNEES, {
        groupId,
        permissionSetId,
      })
    );

    if (
      status === StatusCodes.OK &&
      restrictions !== undefined &&
      batch !== undefined
    )
      dispatch(
        setUserGroupPermissionsAsigneesOptions(permissionSetId, {
          restrictions,
          batch,
        })
      );
  } catch (error) {
    showUnhandledErrorToast(error);
  }
};

export const getUserGroupPermissionsUserGroup = (
  userGroupId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const { status, data } = await apiCall.get<UserGroup>(
      generatePath(USER_GROUP, {
        id: userGroupId,
      })
    );

    if (status === StatusCodes.OK)
      dispatch(setUserGroupPermissionsUserGroup(data));
  } catch (error) {
    showUnhandledErrorToast(error);
  }
};

export const getUserGroupPermissionsPermissionSets = (
  userGroupId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    dispatch(setUserGroupPermissionsPermissionSetsFetching(true));
    const { status, data } = await apiCall.get<PermissionSetsFromAPI>(
      generatePath(USER_GROUP_PERMISSION_SETS, {
        id: userGroupId,
      })
    );

    if (status === StatusCodes.OK)
      dispatch(setUserGroupPermissionsPermissionSets(data.results));
    dispatch(setUserGroupPermissionsPermissionSetsFetching(false));
  } catch (error) {
    showUnhandledErrorToast(error);
  }
};

export const clearUserGroupPermissionSetsGroupAssignees = (): ClearUserGroupPermissionSetsAssigneesGroups => ({
  type: CLEAR_USER_GROUP_PERMISSION_SETS_ASSIGNEES_GROUPS,
});

export const getUserGroupPermissionSetAssigneesGroups = (
  userGroupId: string,
  permissionSetId: string
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  dispatch(
    setUserGroupPermissionSetAssigneesGroupsFetching(permissionSetId, true)
  );
  try {
    const { results, totalCount } = await getAllAssignees(
      generatePath(USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS, {
        groupId: userGroupId,
        permissionSetId,
      }),
      []
    );

    dispatch(
      setUserGroupPermissionSetAssigneesGroups(
        permissionSetId,
        results,
        totalCount,
        getSortedGroupAvatars(results)
      )
    );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(
      setUserGroupPermissionSetAssigneesGroupsFetching(permissionSetId, false)
    );
  }
};

export const getUserGroupPermissionSetAssigneesGroupsConfiguration = (
  groupId: string,
  permissionSetId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const {
      status,
      data: { restrictions, batch },
    } = await apiCall.options<OptionsResponse>(
      generatePath(USER_GROUP_PERMISSION_SET_ASSIGNEES_GROUPS, {
        groupId,
        permissionSetId,
      })
    );

    if (
      status === StatusCodes.OK &&
      restrictions !== undefined &&
      batch !== undefined
    )
      dispatch(
        setUserGroupPermissionsAsigneesGroupOptions(permissionSetId, {
          restrictions,
          batch,
        })
      );
  } catch (error) {
    showUnhandledErrorToast(error);
  }
};

export const getUserGroupPermissionSetsOptions = (
  userGroupId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const { status, data } = await apiCall.options<OptionsResponse>(
      generatePath(USER_GROUP_PERMISSION_SETS, {
        id: userGroupId,
      })
    );

    if (status === StatusCodes.OK) {
      const allRestrictions = (data.details.schema as any)
        .find((el: any) => el.alias === 'permissions')
        ?.schema?.[0].restrictions?.reduce((acc: any, el: any) => {
          return {
            ...acc,
            [el.type]: el,
          };
        }, {});
      const permissionSetTypeMeta = data.details.schema
        .find(el => el.alias === 'type')
        ?.values?.reduce((acc, val) => {
          return {
            ...acc,
            [val.value]: val,
          };
        }, {});
      const meta = {
        restrictions: data.restrictions,
        rawSchema: data.details.schema,
        schema: mapTaskOptionsToDictionary<PermissionSetSchema>(
          data.details.schema
        ),
        allRestrictions,
        permissionSetTypeMeta,
      } as UserGroupPermissionSetOptions;
      dispatch(setUserGroupPermissionSetsOptions(meta));
    }
  } catch (error) {
    showUnhandledErrorToast(error);
  }
};
