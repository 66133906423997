import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { maxGroupsCount } from 'pages/ObjectClasses/components/ClassFieldFormWrapper/consts';
import { GroupsAlerts } from '../enums/GroupsAlerts';
import { ClassFieldForm } from '../../../../../types';

export const useGroupsValidation = () => {
  const intl = useIntl();
  const {
    values: { user_groups: userGroups = [] },
  } = useFormikContext<ClassFieldForm>();

  const [alerts, setAlerts] = useState({
    limitGroupsError: '',
  });

  const clearInfoMessage = () => {
    setAlerts(prev => ({ ...prev }));
  };

  useEffect(() => {
    if (userGroups.length >= maxGroupsCount) {
      setAlerts(prev => ({
        ...prev,
        limitGroupsError: intl.formatMessage(
          {
            id: 'misc.groupsLimitReached',
            defaultMessage:
              'The maximum number of groups ({ itemsLimit }) have been added.',
          },
          { itemsLimit: maxGroupsCount }
        ),
      }));
    }
    if (
      userGroups.length < maxGroupsCount &&
      alerts[GroupsAlerts.LimitGroupsError]
    ) {
      setAlerts(prev => ({
        ...prev,
        [GroupsAlerts.LimitGroupsError]: '',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, userGroups]);

  return { alerts, clearInfoMessage };
};
