import { ObjectRecordUserMetadata } from 'utils/types/api/objectRecords.types';
import {
  UserOrGroupSelectOptionType,
  SelectUserOption,
} from 'utils/types/selectInput.types';

export const prepareUserOptions = (apiUsers: ObjectRecordUserMetadata[]) => {
  return apiUsers.map<SelectUserOption>(user => ({
    type: UserOrGroupSelectOptionType.User,
    text: `${user.first_name} ${user.last_name}}`,
    value: user.id,
    company: user.company_name,
    ...user,
  }));
};
