import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DebouncedSearchState } from './types/DebouncedSearchState';

/**
 * Provides functions and properties to implement a debounced search functionality.
 *
 * Usage: Provide onSearchTextChange to your input field onChange event. Use search object data to react to
 * debounced searches and other data.
 */
export const useDebouncedSearch = () => {
  const [search, setSearch] = useState<DebouncedSearchState>({
    isDebouncingSearch: false,
    debouncedSearchText: '',
    visibleSearchText: '',
  });

  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      setSearch(prevState => ({
        ...prevState,
        debouncedSearchText: searchText,
        isDebouncingSearch: false,
      }));
    }, 250),
    []
  );

  const onSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchText = event.target.value;

      setSearch(prevState => ({
        ...prevState,
        visibleSearchText: newSearchText,
        isDebouncingSearch: true,
      }));
      debouncedSearch(newSearchText);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return { onSearchTextChange, search };
};
