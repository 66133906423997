import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import { getHeaderCellHeight } from 'components/CatalystTable/components/Table/utils/getHeaderCellHeight';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getCheckboxStyles } from './utils/getCheckboxStyles';

type UseTableStylesProps = {
  preventOverflow?: boolean;
  emptyTable?: boolean;
  withBordersAroundTable?: boolean;
  hasScrollY?: boolean;
  fullWidth?: boolean;
  density?: TableDensity;
  isInitialLoading?: boolean;
};

export const useTableStyles = createUseStyles((theme: ThemeState) => {
  return {
    isEmpty: {
      display: 'none',
    },
    tableContainer: {
      overflow: ({ preventOverflow }: UseTableStylesProps) =>
        preventOverflow ? 'auto' : 'initial',
    },
    filledTable: {
      display: 'flex',
      visibility: ({ emptyTable }: UseTableStylesProps) =>
        emptyTable ? 'hidden' : 'initial',
    },
    // styles below were made to create a border for tables inside formbuilder (childClasses/nestedTables) - withBordersAroundTable property
    tableWithHeaderWrapper: { marginLeft: 15, display: 'flex', marginTop: 8 },
    tableWithHeaderContainer: {
      overflow: 'auto',
      overflowY: 'hidden',
      borderTop: '1px solid #D2D9E7',
      borderBottom: '1px solid #D2D9E7',
      width: '100%',
    },
    leftBorder: { borderLeft: '1px solid #D2D9E7' },
    rightBorder: { borderLeft: '1px solid #D2D9E7' },
    // styles above were made to create a border for tables inside formbuilder (childClasses/nestedTables) - withBordersAroundTable property
    table: {
      margin: ({ withBordersAroundTable }: UseTableStylesProps) =>
        withBordersAroundTable ? '0 0 0 0' : '0 0 0 15px',
      maxHeight: '75vh',
      borderTop: 10,
      '& .resizer': {
        display: 'inline-block',
        height: '100%',
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        touchAction: 'none',
        cursor: 'col-resize',
      },
    },
    tableHeader: {
      borderRadius: 3,
      position: 'sticky',
      top: 0,
      zIndex: 2,
      display: 'block',
      paddingTop: ({ withBordersAroundTable }: UseTableStylesProps) =>
        withBordersAroundTable ? 0 : 8,
      paddingRight: ({ hasScrollY }: UseTableStylesProps) =>
        hasScrollY ? 10 : 0,
      backgroundColor: 'white',
      touchAction: 'none',
    },
    tableHeaderWrapper: {
      overflow: ({ preventOverflow }: UseTableStylesProps) =>
        preventOverflow ? 'initial' : 'auto',
    },
    tableHead: ({ fullWidth }: UseTableStylesProps) => ({
      textTransform: 'uppercase',
      borderRadius: 3,
      fontWeight: 700,

      width: fullWidth
        ? 'max(var(--table-row-width), 100%) !important'
        : 'var(--table-row-width) !important',
      minWidth: fullWidth ? '100%' : 'initial',
      backgroundColor: theme['dropdown-active-gray'],
    }),
    tableBulkSelectionHead: {
      justifyContent: 'space-between',
      fontWeight: 700,
      backgroundColor: theme['border-color-light-blue'],
      '& > div > div, & > div > div > div:first-child': {
        backgroundColor: theme['border-color-light-blue'],
      },
    },
    tableCell: {
      padding: '0 15px',
      color: '#2E3F57',
      fontSize: 13,
    },
    tableCellCentered: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    tableHeadCell: {
      backgroundColor: '#EAEDEF',
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      top: 0,
      color: '#2E3F57',
      fontSize: 13,
      '& > div:first-child': {
        padding: '0 15px',
        height: ({ density }: UseTableStylesProps) =>
          getHeaderCellHeight(density),
        backgroundColor: '#EAEDEF',
      },
      '&:first-of-type': {
        borderRadius: '3px 0 0 3px',
      },
      '&:last-of-type': {
        borderRadius: '0 3px 3px 0',
      },
    },
    tableHeadCellDragging: {
      backgroundColor: theme['primary-color'],
    },
    tablePagination: {
      width: '100%',
    },
    sortableTableHeader: {
      cursor: 'pointer',
      '&.disabled': {
        cursor: 'default',
      },
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    removeColumnButton: {
      transition: 'background-color 0.2s ease-in-out',
      borderRadius: 3,
      height: 22,
      width: 22,
      '&:hover': {
        backgroundColor: '#D5DADE',
      },
    },
    noScrollbar: {
      '&::-webkit-scrollbar': {
        display: 'none',
        width: 0,
        background: 'transparent',
      },
      'scrollbar-width': 'none',
      '8-ms-overflow-style': 'none',
    },
    searchWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '& input': {
        maxWidth: 256,
        margin: 2,
      },
    },
    labelWithSearch: {
      display: 'flex',
      overflow: 'initial',
      marginRight: 20,
    },
    checkbox: {
      ...getCheckboxStyles(theme),
      '& .ant-checkbox-indeterminate .ant-checkbox-inner': {
        backgroundColor: theme['primary-color'],
        borderColor: theme['primary-color'],
        '&::after': {
          top: '50%',
          backgroundColor: 'white',
          left: '50%',
          height: 2,
        },
      },
    },
    draggableTablePaginationFiller: {
      height: '32px',
    },
    loader: {
      minHeight: (props: UseTableStylesProps) =>
        props?.isInitialLoading ? '400px' : 'auto',
    },
  };
});
