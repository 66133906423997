import { ScrollableParentSeekMode } from '../enums';
import { checkIfPredefinedContainer } from './checkIfPredefinedContainer';
import { checkIfScrollable } from './checkIfScrollable';

/**
 * Returns the function that will be used to evaluate the HTML element for scrollability. Eval function depends on the mode and other parameters.
 */
export const getHtmlElementEvalFunction = (
  seekMode: ScrollableParentSeekMode,
  scrollableElements: string[]
) => {
  switch (seekMode) {
    case ScrollableParentSeekMode.PredefinedList:
      return (element: HTMLElement) =>
        checkIfPredefinedContainer(element, scrollableElements);
    case ScrollableParentSeekMode.AnyClosest:
      return checkIfScrollable;
    default:
      throw new Error('Argument out of range exception.');
  }
};
