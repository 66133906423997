import { AddGroupAssigneesPanelView } from 'components/AddAssigneesPanelView/views/AddGroupAssigneesPanelView';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { getObjectClassPermissionSetGroups } from 'store/actions/objectClassPermissionsActions';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import {
  getObjectClassPermissionSetGroupsData,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';
import { AppDispatch } from 'store/store';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { PanelMode } from 'utils/Enums/PanelMode';
import { OBJECT_CLASS_PERMISSION_SET_GROUPS } from 'utils/endpoints';

export const AddPermissionSetGroupsPanel = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { id: objectClassId } = useParams<{ id: string }>();

  const {
    [FlexLayoutWindows.ClassPermissionsOwners]: sidebarData,
  } = useSelector(getSidebarData);
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const { list: assignedGroups, options } = useSelector(
    getObjectClassPermissionSetGroupsData(permissionSetId)
  );

  const groupsLimit = options?.restrictions?.limit_items ?? undefined;
  const autocompleteEndpoint = options?.batch?.autocomplete ?? '';
  const postEndpoint = generatePath(OBJECT_CLASS_PERMISSION_SET_GROUPS, {
    objectClassId,
    permissionSetId,
  });
  const assignedGroupIds = assignedGroups.map(assignement => {
    return 'name' in assignement ? assignement.id : assignement.user_group.id;
  });

  const changePanelView = () => {
    dispatch(
      setSidebarData(FlexLayoutWindows.ClassPermissionsOwners, {
        ...sidebarData,
        mode: PanelMode.Manage,
      })
    );
  };

  const refetchGroupAssignees = () => {
    return dispatch(
      getObjectClassPermissionSetGroups(objectClassId, permissionSetId)
    );
  };

  return (
    <AddGroupAssigneesPanelView
      resourceName={selectedPermissionSet?.name ?? ''}
      panelKey={FlexLayoutWindows.ClassPermissionsOwners}
      assignedGroupIds={assignedGroupIds}
      autocompleteEndpoint={autocompleteEndpoint}
      postEndpoint={postEndpoint}
      groupsLimit={groupsLimit}
      changePanelView={changePanelView}
      refetchGroupAssignees={refetchGroupAssignees}
    />
  );
};
