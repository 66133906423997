import { CopyText } from 'components/Text/CopyText';
import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import { generateFormUrl } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassForms/List/components/ActionMenuItems/utils';
import React from 'react';
import { useIntl } from 'react-intl';

export const UuidCell = ({ row }: CatalystTableCellProps) => {
  const intl = useIntl();
  const formUrl = generateFormUrl(row.uuid);

  return (
    <CopyText
      textToCopy={formUrl}
      message={intl.formatMessage({
        id: 'misc.copyUrl',
        defaultMessage: 'Copy URL',
      })}
      successMessage={intl.formatMessage({
        id: 'misc.urlCopied',
        defaultMessage: 'URL copied!',
      })}
    />
  );
};
