import {
  AddChildParams,
  CreateRootParams,
  FindByPathParams,
  NestedRecordData,
  NestedRecordNode,
  ProduceWrapperParams,
  RemoveChildParams,
  RemoveChildrenParams,
  RemoveNodeParams,
  ReplaceNodeParams,
  UpdateNodeParams,
} from './types';
import get from 'lodash/get';
import set from 'lodash/set';
import { produce } from 'immer';
import unset from 'lodash/unset';
import { NestedObjectRecordsStack } from 'store/reducers/types/nestedObjectRecords.types';

export const NOT_CREATED_NODE_PREFIX = 'create-';

export enum TreeOperationType {
  ADD_NODE = 'ADD_NODE',
  UPDATE_NODE = 'UPDATE_NODE',
  REMOVE_NODE = 'REMOVE_NODE',
  REPLACE_NODE = 'REPLACE_NODE',
}

const produceWrapper = ({
  root,
  path,
  data,
  operationType,
}: ProduceWrapperParams) => {
  return produce(root, draft => {
    if (operationType === TreeOperationType.ADD_NODE) {
      set(draft, `${path}.${data?.nodeId}`, data);
    }
    if (operationType === TreeOperationType.REMOVE_NODE) {
      unset(draft, path);
    }
    if (operationType === TreeOperationType.UPDATE_NODE) {
      const oldData = get(draft, path);
      set(draft, `${path}`, { ...oldData, ...data });
    }
    if (operationType === TreeOperationType.REPLACE_NODE) {
      const newPath = `${path.split('.').slice(0, -1).join('.')}.${
        data?.nodeId
      }`;
      unset(draft, path);
      set(draft, newPath, data);
    }
  });
};

export function findByPath({
  root,
  path,
}: FindByPathParams): NestedRecordData | undefined {
  if (path.length === 0) {
    return undefined;
  }
  const node = get(root, parsePath(path));
  return node;
}

export function parsePath(path: string[]): string {
  return path.reduce((prev, current, index) => {
    if (index === 0) {
      return `${current}`;
    }
    return `${prev}.children.${current}`;
  }, '');
}

export function createRoot({ data }: CreateRootParams) {
  const newMap: NestedRecordNode = { [data.nodeId]: data };
  return newMap;
}

export function addNode({
  root,
  path,
  node,
}: AddChildParams): NestedRecordNode {
  if (path.length === 0 || Object.keys(root).length === 0) {
    return createRoot({ data: node });
  }
  const newRoot = produceWrapper({
    root,
    path: `${parsePath(path)}.children`,
    data: node,
    operationType: TreeOperationType.ADD_NODE,
  });
  return newRoot;
}

export function updateNode({
  root,
  path,
  data,
}: UpdateNodeParams): NestedRecordNode {
  const newRoot = produceWrapper({
    root,
    path: parsePath(path),
    data,
    operationType: TreeOperationType.UPDATE_NODE,
  });
  return newRoot;
}

export function removeNode({ root, path }: RemoveNodeParams) {
  const newRoot = produceWrapper({
    root,
    path: parsePath(path),
    operationType: TreeOperationType.REMOVE_NODE,
  });
  return newRoot;
}

export function removeChild({ root, path, childId }: RemoveChildParams) {
  const newRoot = produceWrapper({
    root,
    path: `${parsePath(path)}.children.${childId}`,
    operationType: TreeOperationType.REMOVE_NODE,
  });
  return newRoot;
}

export function removeChildren({ root, path }: RemoveChildrenParams) {
  const newRoot = produceWrapper({
    root,
    path: `${parsePath(path)}.children`,
    operationType: TreeOperationType.REMOVE_NODE,
  });
  return newRoot;
}

export function replaceNode({ root, path, data }: ReplaceNodeParams) {
  const newRoot = produceWrapper({
    root,
    path: parsePath(path),
    data,
    operationType: TreeOperationType.REPLACE_NODE,
  });
  return newRoot;
}

export function parseStackToPath(stack: NestedObjectRecordsStack) {
  const path = stack.map(element => {
    if (element.recordId) {
      return element.recordId.toString();
    }
    return `${NOT_CREATED_NODE_PREFIX}${element.classId}`;
  });
  return path;
}
