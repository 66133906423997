import React, { useCallback } from 'react';
import CustomPrompt from 'components/CustomPrompt';
import { useIntl } from 'react-intl';
import CancelConfigurationModal from '../../../../../CancelConfigurationModal';
import ResetChangesIllustration from 'img/illustrations/reset-changes.svg';
import { RefreshIcon } from 'components/Icon';
import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import ConfirmationModal from 'components/ConfirmationModal';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { makeKnownColumns } from '../../utils/makeKnownColumns';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedColumns } from 'store/actions/preferencesActions';
import { getSelectedObjectClassId } from 'store/selectors/preferencesSelectors';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { setObjectRecordsSelectedColumns } from 'store/actions/objectRecordsActions';
import { FIELD_PREFIX } from 'utils/consts';
import { TableModalsProps } from './TableModals.types';

export const TableModals: React.FC<TableModalsProps> = ({
  state,
  setColumnOrder,
  defaultTableColumns,
  setHiddenColumns,
  onConfirmLeaveConfigMode,
  shouldShowConfirmation,
  hasChanges,
  cancelConfigModalVisible,
  setShouldShowConfirmation,
  setCancelConfigModalVisible,
  resetResizing,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedClassID = useSelector(getSelectedObjectClassId);
  const currentTableName = useSelector(getCurrentTable);
  const {
    columns,
    editModeEnabled: editMode,
    onRejectSaveChanges: onRejectChanges,
    toggleReload,
    onSaveTableConfiguration,
  } = useTableContext();

  const handlePreferencesReset = useCallback(async () => {
    if (
      selectedClassID &&
      currentTableName === CatalystTableType.ObjectRecords
    ) {
      dispatch(updateSelectedColumns(selectedClassID));
      dispatch(
        setObjectRecordsSelectedColumns({
          selectedClassId: selectedClassID,
          selectedColumns: [],
        })
      );
    }

    if (!defaultTableColumns) {
      setShouldShowConfirmation(false);

      return;
    }

    const hiddenColumns = columns.reduce<string[]>((result, { id }) => {
      if (id && !defaultTableColumns.includes(id)) {
        return [...result, id];
      }

      return result;
    }, []);

    setHiddenColumns(
      hiddenColumns.filter(col => !col.startsWith(FIELD_PREFIX))
    );
    setColumnOrder(defaultTableColumns);
    resetResizing();

    await onSaveTableConfiguration({
      ...state,
      columnOrder: defaultTableColumns,
      hiddenColumns,
      knownColumns: makeKnownColumns(columns),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      columnResizing: {
        columnWidths: {},
      },
    });

    toggleReload();
    setShouldShowConfirmation(false);
  }, [
    columns,
    currentTableName,
    defaultTableColumns,
    dispatch,
    onSaveTableConfiguration,
    resetResizing,
    selectedClassID,
    setColumnOrder,
    setHiddenColumns,
    setShouldShowConfirmation,
    state,
    toggleReload,
  ]);

  return (
    <>
      <ConfirmationModal
        onConfirm={handlePreferencesReset}
        onCancel={() => setShouldShowConfirmation(false)}
        visible={shouldShowConfirmation}
        title={intl.formatMessage({
          id: 'misc.resetToDefaultQuestion',
          defaultMessage: 'Reset to default?',
        })}
        subtitle={intl.formatMessage({
          id: 'misc.thisWillResetTheListToItsDefaultSettings',
          defaultMessage: 'This will reset the list to its default settings.',
        })}
        image={ResetChangesIllustration}
        confirmationButtonIcon={<RefreshIcon size={12} />}
        confirmationButtonLabel={intl.formatMessage({
          id: 'misc.reset',
          defaultMessage: 'Reset',
        })}
        cancelButtonLabel={intl.formatMessage({
          id: 'misc.cancel',
          defaultMessage: 'Cancel',
        })}
      />
      <CancelConfigurationModal
        onConfirm={onConfirmLeaveConfigMode}
        onCancel={() => setCancelConfigModalVisible(false)}
        visible={cancelConfigModalVisible}
      />
      <CustomPrompt
        when={editMode && hasChanges}
        onClose={onConfirmLeaveConfigMode}
        onCancel={onRejectChanges}
        type={CustomPromptType.CANCEL}
      />
    </>
  );
};
