import { createUseStyles } from 'react-jss';

export const useLimitMessageStyles = createUseStyles(() => {
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    alert: {
      marginBottom: 10,
    },
  };
});
