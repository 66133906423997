import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';

export const useEmptyTableMessage = (
  currentTable: CatalystTableType | undefined
) => {
  const intl = useIntl();

  const messages: MappedObject<string, CatalystTableType> = useMemo(
    () => ({
      [CatalystTableType.TaskTemplates]: intl.formatMessage({
        id: 'misc.noTaskTemplatesFound',
        defaultMessage: 'No task templates found',
      }),
      [CatalystTableType.ObjectClasses]: intl.formatMessage({
        id: 'misc.noObjectClassesFound',
        defaultMessage: 'No object classes found',
      }),
      [CatalystTableType.ObjectClassesFields]: intl.formatMessage({
        id: 'misc.noObjectClassesFieldsFound',
        defaultMessage: 'No object classes fields found',
      }),
      [CatalystTableType.ObjectRecords]: intl.formatMessage({
        id: 'misc.noObjectRecordsFound',
        defaultMessage: 'No object records found',
      }),
      [CatalystTableType.Tasks]: intl.formatMessage({
        id: 'misc.noTasksFound',
        defaultMessage: 'No tasks found',
      }),
      [CatalystTableType.Sequences]: intl.formatMessage({
        id: 'misc.noSequencesFound',
        defaultMessage: 'No sequences found',
      }),
      [CatalystTableType.TaskGroups]: intl.formatMessage({
        id: 'misc.noTaskGroupTemplatesFound',
        defaultMessage: 'No task group templates found',
      }),
      [CatalystTableType.UserGroups]: intl.formatMessage({
        id: 'misc.noUserGroupsFound',
        defaultMessage: 'No user groups found',
      }),
      [CatalystTableType.Users]: intl.formatMessage({
        id: 'misc.noUsersFound',
        defaultMessage: 'No users found',
      }),
      [CatalystTableType.Roles]: intl.formatMessage({
        id: 'misc.noRolesFound',
        defaultMessage: 'No roles found',
      }),
      [CatalystTableType.Forms]: intl.formatMessage({
        id: 'misc.noFormsFound',
        defaultMessage: 'No forms found',
      }),
      [CatalystTableType.MessageTemplates]: intl.formatMessage({
        id: 'misc.noMessageTemplatesFound',
        defaultMessage: 'No message templates found',
      }),
      [CatalystTableType.EmailCollectors]: intl.formatMessage({
        id: 'misc.noEmailCollectorsFound',
        defaultMessage: 'No email collectors found',
      }),
      [CatalystTableType.SingleSignOn]: intl.formatMessage({
        id: 'misc.noSingleSignOnFound',
        defaultMessage: 'No single sign-on found',
      }),
      [CatalystTableType.AuthenticationObjects]: intl.formatMessage({
        id: 'misc.noAuthenticationObjectsFound',
        defaultMessage: 'No authentication objects found',
      }),
      [CatalystTableType.Members]: intl.formatMessage({
        id: 'misc.noResults',
        defaultMessage: 'No results',
      }),
      [CatalystTableType.ClassForms]: intl.formatMessage({
        id: 'misc.noFormsFound',
        defaultMessage: 'No forms found',
      }),
      [CatalystTableType.DocumentTemplates]: intl.formatMessage({
        id: 'misc.noDocumentTemplatesFound',
        defaultMessage: 'No document templates found',
      }),
      [CatalystTableType.DocumentTemplatesPanel]: intl.formatMessage({
        id: 'misc.noDocumentTemplatesFound',
        defaultMessage: 'No document templates found',
      }),
      [CatalystTableType.NestedTable]: intl.formatMessage({
        id: 'misc.noObjectRecordsFound',
        defaultMessage: 'No object records found',
      }),
      [CatalystTableType.CustomComponents]: intl.formatMessage({
        id: 'misc.noCustomComponentsFound',
        defaultMessage: 'No elements found',
      }),
    }),
    [intl]
  );

  const commonMessage = intl.formatMessage({
    id: 'misc.noItemsFound',
    defaultMessage: 'No items found',
  });

  if (currentTable) {
    return messages[currentTable] || commonMessage;
  }

  return commonMessage;
};
