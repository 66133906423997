import flatMapDeep from 'lodash/flatMapDeep';

export const getKeysOfFieldsWithUsers = (uischema: string) =>
  flatMapDeep(JSON.parse(uischema)).reduce<MappedObject<string>>(
    (result, item) => {
      const fieldsWithFiles = {} as MappedObject<string>;

      if (item && !Array.isArray(item) && typeof item === 'object') {
        Object.entries(item).forEach(([key, value]) => {
          if (value?.['ui:widget'] === 'user') {
            fieldsWithFiles[key] = key;
          }
        });
      }

      return { ...result, ...fieldsWithFiles };
    },
    {}
  );
