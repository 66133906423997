import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_OBJECT_RECORD_USERS,
  SET_OBJECT_RECORD_USERS_ERROR,
  SET_OBJECT_RECORD_USERS_FETCHING,
  SET_CLASS_PERMISSION_SETS,
} from 'store/constants/objectRecord.consts';
import { RecordPeopleWithCount } from 'pages/Records/types';
import { ResponseError } from 'utils/types/errorResponse';
import { PermissionSetFromAPI } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/PermissionsTable/types';

export interface ObjectRecordState {
  usersError: ResponseError | undefined;
  usersFetching: boolean;
  users: MappedObject<RecordPeopleWithCount>;
  classPerissionSets: PermissionSetFromAPI[];
}

const initialState: ObjectRecordState = {
  usersError: undefined,
  usersFetching: false,
  users: {},
  classPerissionSets: [],
};

const objectRecordReducer: Reducer<ObjectRecordState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_OBJECT_RECORD_USERS_ERROR:
      return {
        ...state,
        usersError: action.payload,
      };
    case SET_CLASS_PERMISSION_SETS:
      return {
        ...state,
        classPerissionSets: action.payload,
      };

    case SET_OBJECT_RECORD_USERS_FETCHING:
      return {
        ...state,
        usersFetching: action.payload,
      };

    case SET_OBJECT_RECORD_USERS:
      return {
        ...state,
        users: {
          // not adding ""...state.owners" is intentional (only storing data needed for synchronization)
          [action.payload.recordId]: action.payload.data,
        },
      };

    default:
      return state;
  }
};

export default objectRecordReducer;
