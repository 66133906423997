import {
  SelectUserOption,
  SelectUserGroupOption,
} from 'utils/types/selectInput.types';

/**
 * Creates {@link SelectUserAndGroupOptionMap} from users and groups array.
 */
export const createUsersAndGroupsSelectionMap = (
  users: SelectUserOption[],
  groups: SelectUserGroupOption[]
) => {
  return {
    users: new Map(users.map(user => [user.id, user])),
    groups: new Map(groups.map(group => [group.id, group])),
  };
};
