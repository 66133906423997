import { FlatTokenDataProps } from '../FormBuilderModal/types';
import {
  ExtendedJsonSchema,
  FormDataValue,
  SchemaSectionProperty,
} from './types';
import { ExtendedUiSchema } from './customFields/ObjectField/types';
import { getExampleValue } from 'components/formBuilder/ListingView/utils';
import { FormValue } from 'alx-dynamic-form';

export const addFieldPrefixes = (
  data: MappedObject<FormDataValue> | FlatTokenDataProps
) =>
  Object.fromEntries(
    Object.entries(data).map(([key, value]) => [`field_${key}`, value])
  );

export const generateSampleValues = (schema: string, uiSchema: string) => {
  const parsedSchema = JSON.parse(schema || '{}') as ExtendedJsonSchema;
  const parsedUiSchema = JSON.parse(uiSchema || '{}') as ExtendedUiSchema;

  const sections = Object.keys(parsedSchema?.properties || {});

  return sections?.reduce((tmpSampleValues, sectionKey) => {
    const { properties } = parsedSchema?.properties?.[
      sectionKey
    ] as SchemaSectionProperty;

    Object.keys(properties || {}).forEach(fieldKey => {
      const field = properties?.[fieldKey];

      const value = field
        ? getExampleValue(
            field,
            parsedUiSchema?.[sectionKey]?.[fieldKey] || {},
            Math.round(Math.random() * 3)
          )
        : undefined;

      tmpSampleValues[fieldKey] = value as FormValue;
    });

    return tmpSampleValues;
  }, {} as MappedObject<FormValue>);
};
