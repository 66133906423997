import { useCatalystTableStyles } from 'components/CatalystTable/CatalystTable.styles';
import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import { useTableRowContext } from 'components/CatalystTable/contexts/TableRowContext';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TABLE_BULK_SELECTION_COUNT_MESSAGE_TESTID } from 'utils/testIds';
import { useTableStyles } from '../../../../Table.styles';

export const BulkSelectionCountMessage = () => {
  const { bulkSelectionList } = useTableContext();
  const { density } = useTableRowContext();
  const tableStyles = useCatalystTableStyles();
  const styles = useTableStyles({ density });

  return (
    <div
      className={styles.tableHeadCell}
      data-testid={TABLE_BULK_SELECTION_COUNT_MESSAGE_TESTID}
    >
      <div className={tableStyles.flexAlignCenter}>
        <FormattedMessage
          id={'misc.countRowsSelected'}
          defaultMessage={
            '{count, plural, one {# row selected} other {# rows selected}}'
          }
          values={{ count: bulkSelectionList.length }}
        />
      </div>
    </div>
  );
};
