import { useCallback, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { apiCall } from 'utils/api';
import {
  SelectOption,
  AutocompleteSelectValue,
  OptionData,
  AutocompleteResponse,
} from 'utils/types/selectInput.types';
import { SEARCH_DEBOUNCE_INTERVAL } from 'components/SearchInput/consts';

const USERS_URL_PART = 'users';

const useAutocompleteSelect = (
  autocompleteUrl: string,
  onChange: (
    value: AutocompleteSelectValue | AutocompleteSelectValue[]
  ) => void,
  handleBlur?: () => void,
  filterResults?: (data: SelectOption[]) => SelectOption[],
  addOptionData?: boolean
) => {
  const [fetching, setFetching] = useState(false);
  const [totalCount, setTotalCount] = useState<number | undefined>(undefined);
  const [data, setData] = useState<SelectOption[]>([]);
  const lastFetchId = useRef(0);

  //this is used for formatting labels (bold name and surname)
  const isAutocompleteForUsers = useMemo(
    () => autocompleteUrl.includes(USERS_URL_PART),
    [autocompleteUrl]
  );

  const fetchData = useCallback(
    async (value: string) => {
      lastFetchId.current = lastFetchId.current + 1;
      const fetchId = lastFetchId.current;

      setData([]);
      setFetching(true);

      try {
        const {
          data: { results, total_count },
        } = await apiCall.get<AutocompleteResponse>(
          encodeURI(`${autocompleteUrl}${value}`)
        );

        if (fetchId !== lastFetchId.current) {
          // skip previous fetches if there are any
          return;
        }

        if (!results) {
          setData([]);

          return;
        }

        setTotalCount(total_count);
        setData(filterResults ? filterResults(results) : results);
      } catch {
      } finally {
        setFetching(false);
      }
    },
    [autocompleteUrl, filterResults]
  );

  const debouncedFetchData = useCallback(
    debounce(fetchData, SEARCH_DEBOUNCE_INTERVAL),
    [fetchData]
  );

  const handleChange = (
    value: AutocompleteSelectValue | AutocompleteSelectValue[] = {
      label: '',
      value: '',
    },
    //we use any here because optionData types arent exported anywhere from rc-select
    optionData: any = { title: '' }
  ) => {
    const parsedValue = Array.isArray(value)
      ? value.map(({ value, label }, index) => {
          const option = (optionData as OptionData[])[index];

          return {
            value: option?.value?.toString() || value,
            label: option.title || label,
          };
        })
      : {
          ...value,
          label: (optionData as OptionData)?.title,
        };

    if (addOptionData) {
      //data which might be required to construct a filter which is not yet in redux.
      //this got added because of filtering by classfields which aren't fetched yet #35917
      const parsedValueWithAddOptionData = {
        ...parsedValue,
        ...optionData.additionalData,
      };
      onChange(parsedValueWithAddOptionData);

      return;
    }
    onChange(parsedValue);
  };

  const handleFocus = () => {
    if (data.length === 0) fetchData('');
  };

  const handleSelectBlur = () => {
    setData([]);

    if (handleBlur) handleBlur();
  };

  const handleDropdownVisibleChange = (
    setSearchValue: React.Dispatch<React.SetStateAction<string>>
  ) => (isOpen: boolean) => {
    // prevents blinking "no data" message on dropdown when opening select with no search value
    if (isOpen) setFetching(true);
    else setSearchValue('');
  };

  return {
    fetching,
    data,
    handleChange,
    fetchData: debouncedFetchData,
    isAutocompleteForUsers,
    handleFocus,
    handleSelectBlur,
    handleDropdownVisibleChange,
    totalCount,
    setData,
  };
};

export default useAutocompleteSelect;
