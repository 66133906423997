import React from 'react';
import { useCustomValueDisplayEmailStyles } from './CustomValueDisplayEmail.styles';
import { CustomValueDisplayEmailProps } from './CustomValueDisplayEmail.types';

export const CustomValueDisplayEmail = ({
  value,
}: CustomValueDisplayEmailProps) => {
  const styles = useCustomValueDisplayEmailStyles();

  return (
    <a
      href={`mailto:${value}`}
      target='_blank'
      rel='noopener noreferrer'
      className={styles.link}
    >
      {value}
    </a>
  );
};
