import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import { useCallback, useMemo } from 'react';
import removeArrItem from 'lodash/remove';
import union from 'lodash/union';
import { BulkSelectionHeaderCheckboxProps } from './BulkSelectionHeaderCheckbox.types';

export const useBulkSelectionHeaderCheckbox = ({
  currentRowsIds,
}: BulkSelectionHeaderCheckboxProps) => {
  const { setBulkSelectionList, bulkSelectionList } = useTableContext();
  const { isSelectedAnyRow, isSelectedAllRows } = useMemo(() => {
    if (currentRowsIds.length === 0) return {};

    const everyRowsAreSelected = currentRowsIds.every(id =>
      bulkSelectionList.includes(id)
    );
    const someRowIsSelected = currentRowsIds.some(id =>
      bulkSelectionList.includes(id)
    );

    return {
      isSelectedAnyRow: someRowIsSelected && !everyRowsAreSelected,
      isSelectedAllRows: everyRowsAreSelected,
    };
  }, [bulkSelectionList, currentRowsIds]);

  const onChangeHeaderCheckbox = useCallback(
    () =>
      setBulkSelectionList(prev => {
        if (isSelectedAllRows || isSelectedAnyRow) {
          return removeArrItem(
            prev,
            bulkSelectionId => !currentRowsIds.includes(bulkSelectionId)
          );
        }

        return [...prev, ...union(currentRowsIds)];
      }),
    [currentRowsIds, isSelectedAllRows, isSelectedAnyRow, setBulkSelectionList]
  );

  return {
    isSelectedAnyRow,
    isSelectedAllRows,
    onChangeHeaderCheckbox,
  };
};
