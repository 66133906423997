import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import Tooltip from 'components/lib/Tooltip';
import { CheckCircle, DownloadIcon } from 'components/Icon';
import useDateFormat from 'hooks/useDateFormat';
import { downloadGeneratedDocumentTemplate } from 'store/actions/documentTemplatesPanelActions';
import {
  DOCUMENT_TEMPLATES_PANEL_CHECK_ICON,
  DOCUMENT_TEMPLATES_PANEL_DOWNLOAD_ICON,
} from 'utils/testIds';
import { useDocumentTemplateStatusStyles } from '../../DocumentTemplateStatus.styles';
import { DocumentTemplateDownloadProps } from './DocumentTemplateDownload.types';

export const DocumentTemplateDownload = ({
  recordId,
  generated_document,
}: DocumentTemplateDownloadProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const styles = useDocumentTemplateStatusStyles();
  const { dateFormat, getTimeFormat } = useDateFormat();

  const {
    generated_at,
    generated_by: { first_name, last_name },
  } = generated_document;
  const user = `${first_name} ${last_name}`;
  const date = moment(generated_at).format(`${dateFormat} ${getTimeFormat()}`);

  const downloadDocumentFile = useCallback(() => {
    const fileUrl = generated_document?._meta?.labels?.document?.url;
    const fileName = generated_document?._meta?.labels?.document?.name;

    if (recordId && fileUrl) {
      dispatch(downloadGeneratedDocumentTemplate(fileUrl, fileName));
    }
  }, [recordId, generated_document, dispatch]);

  return (
    <>
      <Tooltip
        mouseEnterDelay={0}
        overlay={intl.formatMessage(
          {
            id: 'documentTemplatesPanel.generatedBy',
            defaultMessage: 'Generated {date} by {user}',
          },
          {
            user,
            date,
          }
        )}
        className={styles.greenIcon}
      >
        <CheckCircle
          size={18}
          data-testid={DOCUMENT_TEMPLATES_PANEL_CHECK_ICON}
        />
      </Tooltip>
      <Tooltip
        mouseEnterDelay={0}
        overlay={intl.formatMessage({
          id: 'documentTemplatesPanel.downloadDocument',
          defaultMessage: 'Download document',
        })}
        className={styles.grayIcon}
      >
        <DownloadIcon
          onClick={downloadDocumentFile}
          size={18}
          data-testid={DOCUMENT_TEMPLATES_PANEL_DOWNLOAD_ICON}
        />
      </Tooltip>
    </>
  );
};
