/**
 * Standard functionality for a onKeyDown event that can be applied to any element that acts as
 * a button (has role='button') and should allow 'click' events on this element using standard
 * accessibility devices input (proper key presses).
 *
 * @param onClickCallback Callback function that should happen when element is 'clicked' using an accessibility device. Usually the same callback that goes to the onClick event.
 */
export const accessibilityStandardKeyPress = (onClickCallback: () => void) => (
  event: React.KeyboardEvent
) => {
  if (event.key !== 'Enter' && event.key !== ' ') {
    return;
  }

  onClickCallback();
};
