import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetGroupOptions,
  getObjectClassPermissionSetGroupOptionsFetching,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';

export const useManageClassPermissionGroupsLoader = () => {
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const selectedPermissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const options = useSelector(
    getObjectClassPermissionSetGroupOptions(selectedPermissionSetId)
  );
  const areOptionsLoading = useSelector(
    getObjectClassPermissionSetGroupOptionsFetching(selectedPermissionSetId)
  );

  const showLoader = areOptionsLoading && !options;

  return { showLoader };
};
