import { useCallback, useState } from 'react';

/**
 * Allows you to add is touched functionality to any element if the form solution
 * you are using doesn't provide it.
 * Simply use {@link onFieldBlur} on the onBlur event of any element to set the field as touched.
 */
export const useFieldTouched = () => {
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);

  const onFieldBlur = useCallback(() => {
    setIsFieldTouched(true);
  }, []);

  return { onFieldBlur, isFieldTouched };
};
