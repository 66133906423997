import clsx from 'clsx';
import { ArrowRightIcon, ThreeDotsIcon } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectStack } from 'store/selectors/nestedObjectRecordsSelctors';
import { BREADCRUBMS_ITEM_TESTID } from 'utils/testIds';
import { NestedRecordsBreadcrumbsProps } from './NestedRecordsBreadcrumbs.types';
import { useToggle } from 'hooks/useToggle';
import { popManyStack } from 'store/actions/nestedObjectRecordsActions';
import { RECORDS_BREADCRUMBS_DROPDOWN_ID } from 'utils/elementsIds';
import { NestedObjectRecordsStackElement } from 'store/reducers/types/nestedObjectRecords.types';
import { useNestedRecordsBreadcrumbsStyles } from './NestedRecordsBreadcrumbs.styles';
import { BreadcrumbsDropdownMenu } from './components/BreadcrumbsDropdownMenu/BreadcrumbsDropdownMenu';

export const NestedRecordsBreadcrumbs = ({
  isTooltipVisible,
}: NestedRecordsBreadcrumbsProps) => {
  const styles = useNestedRecordsBreadcrumbsStyles();
  const intl = useIntl();
  const nestedRecordsStack = useSelector(selectStack);
  const [isDropdownVisible, { toggle, toggleOff }] = useToggle(false);
  const dispatch = useDispatch();

  useEffect(() => {
    toggleOff();
  }, [nestedRecordsStack, toggleOff]);

  const isLastInStack = (index: number) =>
    index === nestedRecordsStack.length - 1;
  const lastInStack = nestedRecordsStack[nestedRecordsStack.length - 1];
  const isCreateRecord = (item: NestedObjectRecordsStackElement) =>
    !item.recordIdentifier;

  const onRecordLinkClick = (index: number) => {
    dispatch(popManyStack(index + 1));
  };

  const recordMessage = (item: NestedObjectRecordsStackElement) => {
    if (isCreateRecord(item)) {
      return intl.formatMessage(
        {
          id: 'misc.createRecordBreadcrumb',
          defaultMessage: 'Create {className} record',
        },
        { className: item.className }
      );
    }
    return intl.formatMessage(
      {
        id: 'misc.recordBreadcrumb',
        defaultMessage: '{className} {recordIdentifier}',
      },
      {
        className: item.className,
        recordIdentifier: item.recordIdentifier ?? item.recordId,
      }
    );
  };

  if (isTooltipVisible && nestedRecordsStack.length > 1) {
    return (
      <>
        <ArrowRightIcon
          size={12}
          className={clsx(styles.separator, {
            [styles.textColor]: true,
          })}
        />
        <button
          type='button'
          id={RECORDS_BREADCRUMBS_DROPDOWN_ID}
          className={styles.dropdownButton}
          onClick={toggle}
        >
          <ThreeDotsIcon className={styles.rotatedDots} size={18} />
        </button>
        <ArrowRightIcon
          size={12}
          className={clsx(styles.separator, {
            [styles.textColor]: false,
            [styles.lastArrow]: true,
          })}
        />
        {isDropdownVisible && (
          <BreadcrumbsDropdownMenu
            menuTriggerId={RECORDS_BREADCRUMBS_DROPDOWN_ID}
            toggleMenuOff={toggleOff}
            parentId='breadcrumbs-list'
            items={nestedRecordsStack.slice(0, -1).map(record => ({
              title: recordMessage(record),
            }))}
          />
        )}
        <Tooltip
          placement='topLeft'
          shouldBeRendered={isTooltipVisible}
          overlay={recordMessage(lastInStack)}
          className={clsx([
            styles.breadcrumbText,
            styles.tooltip,
            { [styles.bold]: true },
          ])}
        >
          <span
            className={clsx([{ [styles.bold]: true }])}
            data-testid={BREADCRUBMS_ITEM_TESTID}
          >
            {recordMessage(lastInStack)}
          </span>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {nestedRecordsStack.map((item, index) => {
        const isItemLast = isLastInStack(index);
        return (
          <Fragment key={item.classId}>
            <ArrowRightIcon
              size={12}
              className={clsx(styles.separator, {
                [styles.textColor]: !isItemLast,
                [styles.lastArrow]: isItemLast,
              })}
            />
            <Tooltip
              placement='topLeft'
              shouldBeRendered={isTooltipVisible}
              overlay={recordMessage(item)}
              className={clsx([
                styles.breadcrumbText,
                styles.tooltip,
                { [styles.bold]: isItemLast },
              ])}
            >
              {!isItemLast ? (
                <span
                  data-testid={BREADCRUBMS_ITEM_TESTID}
                  onClick={() => onRecordLinkClick(index)}
                  role='button'
                  tabIndex={0}
                  className={styles.recordLink}
                >
                  {recordMessage(item)}
                </span>
              ) : (
                <span //if tooltip isn't rendered then we need to apply styles to span
                  className={clsx([
                    styles.breadcrumbText,
                    { [styles.bold]: isItemLast },
                  ])}
                  data-testid={BREADCRUBMS_ITEM_TESTID}
                >
                  {recordMessage(item)}
                </span>
              )}
            </Tooltip>
          </Fragment>
        );
      })}
    </>
  );
};
