import { ThemeState } from 'store/reducers/types/theme.types';

export const getCheckboxStyles = (theme: ThemeState) => ({
  '& .ant-checkbox input, .ant-checkbox-inner': {
    width: 16,
    height: 16,
    borderColor: theme['checkbox-border-color'],
    borderWidth: '0.09375rem',
    backgroundColor: 'transparent',
  },
  '& .ant-checkbox-inner::after': {
    left: '22%',
    top: '40%',
    borderWidth: 1,
  },
  '&:hover .ant-checkbox::after, & .ant-checkbox:hover::after': {
    visibility: 'hidden',
  },
});
