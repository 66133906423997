import { createUseStyles } from 'react-jss';

export const useStandardToastContentStyles = createUseStyles({
  contentWrapper: {
    display: 'flex',
    gap: '12px',

    fontSize: '0.75rem',
  },
  title: {
    marginBottom: 0,

    fontSize: 'inherit',
    fontWeight: 'bold',
    color: 'inherit',
    lineHeight: '1.5',
    wordBreak: 'break-word',
  },
  subtitle: {
    marginBottom: 0,

    fontSize: 'inherit',
    color: 'inherit',
    lineHeight: '1.5',
    wordBreak: 'break-word',
  },
});
