import React from 'react';
import { Input } from 'components/lib/Input';
import useInPlaceEditPhoneInputStyles from './styles';
import { InPlaceEditPhoneProps } from './types';
import useInPlaceEdit from '../useInPlaceEdit';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { ReactComponent as PhoneImage } from 'img/icons/phone-icon.svg';
import { useErrorMessages } from '../../../utils/useErrorMessages';
import { InPlaceEditError } from '../utils';
import { Dropdown } from 'components/lib/Dropdown';
import { IN_PLACE_EDIT_PHONE } from 'utils/testIds';
import FieldValidationMessage from '../components/FieldValidationMessage';
import { PHONE_INPUT_MAX_LENGTH } from 'components/FormPreview2/widgets/standard/CustomPhoneNumberWidget';
import Tooltip from 'components/lib/Tooltip';
import checkOsIs, { Os } from 'utils/functions/checkOsIs';
import { useIntl } from 'react-intl';

function InPlaceEditPhoneInput<R>({
  label,
  required,
  value,
  disabled,
  propertyName,
  patchUrl,
  onSaveSuccess,
  getPopupContainer,
  remountChangeTarget,
  readOnly,
  size,
  withUnderline,
}: InPlaceEditPhoneProps<R>) {
  const intl = useIntl();

  const {
    inputWrapperRef,
    isEditMode,
    setTempValue,
    tempValue,
    isSaving,
    setIsEditModeOff,
    setIsEditModeOn,
    errorMessage,
    setErrorMessage,
  } = useInPlaceEdit({
    initialValue: value,
    patchUrl,
    propertyName,
    onSaveSuccess,
  });

  const classes = useInPlaceEditPhoneInputStyles({
    isSaving,
    errorMessage,
    size,
  });
  const errorMessages = useErrorMessages();

  const message = `${tempValue?.length || 0}/${PHONE_INPUT_MAX_LENGTH}`;

  const onViewClick = () => {
    if (disabled) return;
    setIsEditModeOn();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    if (required && value.trim().length === 0)
      setErrorMessage(errorMessages[InPlaceEditError.FIELD_IS_REQUIRED_ERROR]);
    else setErrorMessage(undefined);

    setTempValue(e.currentTarget.value);
  };

  const onTelClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    if (!event.metaKey && !event.ctrlKey) return;

    event.stopPropagation();
    window.location.href = `tel:${value}`;
  };

  const tooltipMessage = checkOsIs(Os.Macos)
    ? intl.formatMessage({
        id: 'misc.cmdClickToDial',
        defaultMessage: 'Cmd + click to dial.',
      })
    : intl.formatMessage({
        id: 'misc.ctrlClickToDial',
        defaultMessage: 'Ctrl + click to dial.',
      });

  return (
    <InPlaceEditWrapper
      {...{
        isEditMode,
        isSaving,
        label,
        required,
        disabled,
        onViewClick,
        inputWrapperRef,
        readOnly,
        size,
        withUnderline,
      }}
      onCloseClick={setIsEditModeOff}
      editContent={
        <Dropdown
          {...{ getPopupContainer, remountChangeTarget }}
          visible={!isSaving && (!!errorMessage || !!message)}
          overlay={
            <FieldValidationMessage message={message} error={errorMessage} />
          }
        >
          <div className={classes.inputWrapper}>
            <Input
              value={tempValue ?? ''}
              onChange={onInputChange}
              className={classes.input}
              autoFocus
              onKeyDown={event => event.key === 'Escape' && setIsEditModeOff()}
              data-testid={IN_PLACE_EDIT_PHONE}
              maxLength={PHONE_INPUT_MAX_LENGTH}
              withCounter={false}
            />
            <PhoneImage width={16} height={16} />
          </div>
        </Dropdown>
      }
      viewContent={
        !!value?.length ? (
          <Tooltip title={tooltipMessage} placement='topRight'>
            <span
              className={classes.inPlaceDialInputStyles}
              onClick={onTelClick}
              role='link'
              tabIndex={0}
            >
              {value}
            </span>
          </Tooltip>
        ) : (
          '-'
        )
      }
    />
  );
}

export default InPlaceEditPhoneInput;
