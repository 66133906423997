import * as yup from 'yup';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  Validators,
  OptionSelect,
  ItemsValidator,
  TypeValidator,
  SizeValidator,
} from 'utils/types/selectInput.types';
import { FlatValidatorsObject } from 'utils/types';
import { URL_REGEX, EMAIL_REGEX } from 'utils/consts';
import isPlainObject from 'lodash/isPlainObject';
import { isNil } from 'lodash';

type CommonValues<T> = valueof<T> & OptionSelect;
type Options<T> = Record<keyof T, CommonValues<T>>;
type StringSchema = yup.StringSchema<string | null | undefined>;

export enum UniqueFieldType {
  Int = 'int',
  Email = 'email',
  Date = 'date',
  Url = 'url',
  Json = 'json',
  JsonObject = 'json_object',
  UsersAndGroups = 'users_and_groups',
}

const useValidationSchemaBuilder = <T>(
  options: Options<T> | undefined,
  additionalValidation?: object
) => {
  const intl = useIntl();

  const fieldsWithValidation = options
    ? Object.entries<CommonValues<T>>(options).reduce<Options<T> | {}>(
        (result, [key, value]) => {
          if (!!value.required || !!value.validators) {
            return {
              ...result,
              [key]: value,
            };
          }

          return result;
        },
        {}
      )
    : {};

  const rawValidationSchema = Object.entries<CommonValues<T>>(
    fieldsWithValidation
  ).reduce<FlatValidatorsObject>((all, [key, { validators, ...rest }]) => {
    all[key] = rest;

    if (!!validators) {
      validators.forEach((validator: Validators) => {
        if (validator.type && typeof validator.type === 'string') {
          (all as Record<string, any>)[key][validator.type] =
            validator.length ||
            ((validator as unknown) as ItemsValidator).items ||
            ((validator as unknown) as TypeValidator)?.extensions ||
            ((validator as unknown) as SizeValidator)?.size;
        }

        return all[key];
      });
    }

    return all;
  }, {});

  const getTypeSpecificBaseSchema = useCallback((type: string | undefined) => {
    const Yup = yup as Record<string, any>;

    if (!type) {
      return yup.mixed();
    }

    switch (type) {
      case UniqueFieldType.Int:
        return yup.number();
      case UniqueFieldType.Email:
      case UniqueFieldType.Url:
      case UniqueFieldType.Json:
      case UniqueFieldType.JsonObject:
        return yup.string();
      case UniqueFieldType.UsersAndGroups:
        return yup.object().shape({
          users: yup.array(),
          groups: yup.array(),
        });
      default:
        return !!Yup[type] ? Yup[type]() : yup.mixed();
    }
  }, []);

  const addIsRequiredValidationToSchema = useCallback(
    (
      builtSchema: Record<string, StringSchema>,
      fieldKey: string,
      type: string | undefined
    ) => {
      if (type !== UniqueFieldType.UsersAndGroups) {
        builtSchema[fieldKey] = builtSchema[fieldKey].required(
          intl.formatMessage({
            id: 'errors.fieldIsRequired',
            defaultMessage: 'Field is required',
          })
        );

        return;
      }

      builtSchema[fieldKey] = builtSchema[fieldKey].test(
        'usersAndGroupsRequired',
        intl.formatMessage({
          id: 'errors.fieldIsRequired',
          defaultMessage: 'Field is required',
        }),
        value => {
          /**
           * Schema type is typed wrongly, but to resolve that it would require almost complete
           * refactor of this hook. It should be resolved in near future, but unfortunately for now,
           * this has to be patched by explicit cast as "trust me bro"
           */
          if (isNil(value)) {
            return false;
          }

          const usersAndGroupsValue = value as {
            users?: number[];
            groups?: number[];
          };

          const hasUsers =
            !!usersAndGroupsValue.users && usersAndGroupsValue.users.length > 0;

          const hasGroups =
            !!usersAndGroupsValue.groups &&
            usersAndGroupsValue.groups.length > 0;

          return hasUsers || hasGroups;
        }
      );
    },
    [intl]
  );

  const addEmailValidationToSchema = useCallback(
    (builtSchema: Record<string, StringSchema>, fieldKey: string) => {
      // use fb email regex to unify as per URL
      builtSchema[fieldKey] = builtSchema[fieldKey].matches(
        EMAIL_REGEX,
        intl.formatMessage({
          id: 'errors.mustBeAValidEmail',
          defaultMessage: 'Enter a valid email address',
        })
      );
    },
    [intl]
  );

  const addUrlValidationToSchema = useCallback(
    (builtSchema: Record<string, StringSchema>, fieldKey: string) => {
      builtSchema[fieldKey] = builtSchema[fieldKey].matches(
        URL_REGEX,
        intl.formatMessage({
          id: 'errors.enter_a_valid_url',
          defaultMessage: 'Enter a valid URL',
        })
      );
    },
    [intl]
  );

  const addDateValidationToSchema = useCallback(
    (builtSchema: Record<string, StringSchema>, fieldKey: string) => {
      builtSchema[fieldKey] = builtSchema[fieldKey].nullable();
    },
    []
  );

  const addJsonValidationToSchema = useCallback(
    (builtSchema: Record<string, StringSchema>, fieldKey: string) => {
      builtSchema[fieldKey] = builtSchema[fieldKey].test(
        'isValidJsonFormat',
        intl.formatMessage({
          id: 'errors.enter_a_valid_json',
          defaultMessage: 'Enter a valid JSON',
        }),
        value => {
          if (typeof value !== 'string') {
            return true;
          }

          try {
            JSON.parse(value);
            return true;
          } catch {
            return false;
          }
        }
      );
    },
    [intl]
  );

  const addJsonObjectValidationToSchema = useCallback(
    (builtSchema: Record<string, StringSchema>, fieldKey: string) => {
      builtSchema[fieldKey] = builtSchema[fieldKey].test(
        'isValidJsonObjectFormat',
        intl.formatMessage({
          id: 'errors.enter_a_valid_json_object',
          defaultMessage: 'Enter a valid JSON object',
        }),
        value => {
          if (typeof value !== 'string') {
            return true;
          }

          try {
            const parsedValue = JSON.parse(value);

            return isPlainObject(parsedValue);
          } catch {
            return false;
          }
        }
      );
    },
    [intl]
  );

  const addValidatorToSchema = useCallback(
    (
      type: string | string[],
      length: string | number | string[],
      builtSchema: Record<string, StringSchema>,
      fieldKey: string
    ) => {
      if (type === 'min_length' && typeof length === 'number' && length > 0) {
        builtSchema[fieldKey] = builtSchema[fieldKey].test(
          `Min length: ${length}`,
          intl.formatMessage(
            {
              id: 'errors.minStringLength',
              defaultMessage:
                'Field requires minimum {length} {length, plural, one {character} other {characters}}',
            },
            {
              length,
            }
          ),
          value => {
            if (value === undefined || value === null) {
              return false;
            }

            return value.length >= length;
          }
        );
      }

      if (type === 'email') {
        builtSchema[fieldKey] = builtSchema[fieldKey].email(
          intl.formatMessage({
            id: 'errors.mustBeAValidEmail',
            defaultMessage: 'Enter a valid email address',
          })
        );
      }
    },
    [intl]
  );

  const addValueValidatorsToSchema = useCallback(
    (
      validators: Validators[],
      builtSchema: Record<string, StringSchema>,
      fieldKey: string
    ) => {
      validators.forEach(({ type, length }: Validators) =>
        addValidatorToSchema(type, length, builtSchema, fieldKey)
      );
    },
    [addValidatorToSchema]
  );

  const buildValidationSchema = useCallback(() => {
    if (options) {
      const validationSchema = Object.entries<CommonValues<T>>(
        fieldsWithValidation
      ).reduce<Record<string, StringSchema>>(
        (builtSchema, [fieldKey, value]) => {
          const { type, required, validators } = value;

          builtSchema[fieldKey] = getTypeSpecificBaseSchema(type);

          if (!!required) {
            addIsRequiredValidationToSchema(builtSchema, fieldKey, type);
          }

          switch (type) {
            case UniqueFieldType.Email:
              addEmailValidationToSchema(builtSchema, fieldKey);
              break;
            case UniqueFieldType.Url:
              addUrlValidationToSchema(builtSchema, fieldKey);
              break;
            case UniqueFieldType.Date:
              addDateValidationToSchema(builtSchema, fieldKey);
              break;
            case UniqueFieldType.Json:
              addJsonValidationToSchema(builtSchema, fieldKey);
              break;
            case UniqueFieldType.JsonObject:
              addJsonObjectValidationToSchema(builtSchema, fieldKey);
              break;
          }

          if (validators) {
            addValueValidatorsToSchema(validators, builtSchema, fieldKey);
          }

          return builtSchema;
        },
        {}
      );

      return yup.object().shape({
        ...validationSchema,
        ...(additionalValidation ? additionalValidation : {}),
      });
    }

    return yup.object().shape({});
  }, [
    options,
    fieldsWithValidation,
    additionalValidation,
    getTypeSpecificBaseSchema,
    addIsRequiredValidationToSchema,
    addEmailValidationToSchema,
    addUrlValidationToSchema,
    addDateValidationToSchema,
    addJsonValidationToSchema,
    addJsonObjectValidationToSchema,
    addValueValidatorsToSchema,
  ]);

  return {
    buildValidationSchema,
    rawValidationSchema,
  };
};

export default useValidationSchemaBuilder;
