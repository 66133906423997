import clsx from 'clsx';
import DynamicFormattedMessage from 'components/DynamicFormattedMessage';
import { FilterIndicator } from 'components/FilterIndicator';
import { ArrowRightIcon } from 'components/Icon';
import LinkComponent from 'components/lib/Breadcrumbs/LinkComponent';
import React, { Fragment } from 'react';
import { isLast } from 'utils/functions/isLast';
import {
  BREADCRUBMS_LIST_TESTID,
  BREADCRUBMS_ITEM_TESTID,
} from 'utils/testIds';
import { NestedRecordsBreadcrumbs } from '../NestedRecordsBreadcrumbs';
import { useBreadcrumbsSkeletonStyles } from './BreadcrumbsSkeleton.styles';
import { BreadcrumbsSkeletonProps } from './BreadcrumbsSkeleton.types';

export const BreadcrumbsSkeleton = ({
  filteredRoutes,
  appliedFilters,
  nestedRecordsStack,
  generateBackPath,
  isTooltipVisible,
  params,
  url,
}: BreadcrumbsSkeletonProps) => {
  const styles = useBreadcrumbsSkeletonStyles();

  return (
    <div
      data-testid={BREADCRUBMS_LIST_TESTID}
      className={clsx(styles.list, {
        [styles.wrapper]: !appliedFilters,
      })}
    >
      {filteredRoutes.map(
        ({ path, breadcrumbName, Component, paramsKey }, index) => {
          const isItemLast =
            nestedRecordsStack.length <= 1 && isLast(index, filteredRoutes);
          const paramValue = paramsKey ? params[paramsKey] : undefined;
          if (!breadcrumbName) return <Fragment key={path} />;
          return (
            <Fragment key={path}>
              {index !== 0 && (
                <ArrowRightIcon
                  size={12}
                  className={clsx(styles.separator, {
                    [styles.textColor]: !isItemLast,
                    [styles.lastArrow]: isItemLast,
                  })}
                />
              )}
              <span data-testid={BREADCRUBMS_ITEM_TESTID}>
                {!isItemLast && Component ? (
                  <LinkComponent
                    className={styles.link}
                    to={path === url ? generateBackPath(path) : path}
                  >
                    {breadcrumbName && (
                      <DynamicFormattedMessage
                        id={breadcrumbName}
                        values={
                          paramValue && paramsKey
                            ? { [paramsKey]: paramValue }
                            : undefined
                        }
                      />
                    )}
                  </LinkComponent>
                ) : (
                  <span
                    className={clsx([
                      styles.breadcrumbText,
                      { [styles.bold]: isItemLast },
                    ])}
                  >
                    {breadcrumbName && (
                      <DynamicFormattedMessage
                        id={breadcrumbName}
                        values={
                          paramValue && paramsKey
                            ? {
                                [paramsKey]: paramValue,
                              }
                            : undefined
                        }
                      />
                    )}
                  </span>
                )}
              </span>
            </Fragment>
          );
        }
      )}
      <NestedRecordsBreadcrumbs isTooltipVisible={isTooltipVisible} />
      {appliedFilters && <FilterIndicator />}
    </div>
  );
};
