import { JSONSchema7Type } from 'json-schema';

export enum TextInputTypes {
  MultiLine = 'textarea',
  SingleLine = 'input',
}

export interface PropertiesListProps {
  details: MappedObject<string | undefined>;
  defaultValue?: JSONSchema7Type;
  options?: JSONSchema7Type[];
  fieldId: number;
  /**
   * Usually ObjectClassId is retrieved from the URL but in some contexts it might be unavailable.
   * By passing the custom object class id here, it will be used instead of the URL one.
   */
  relatedObjectClassId?: number;
}

export interface SelectFieldOptionsProps {
  options: string[];
}

export enum DatabaseTypesWithSelectOptions {
  Enum = 'enum',
  Set = 'set',
}

export interface DefaultValueProps {
  defaultValue: JSONSchema7Type | undefined;
}
