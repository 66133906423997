import { useCancelToken } from 'hooks/useCancelToken';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getObjectClassPermissionSetAssigneesConfiguration,
  getObjectClassPermissionSetGroupOptions,
} from 'store/actions/objectClassPermissionsActions';
import { getObjectClassSelectedPermissionSet } from 'store/selectors/objectClassPermissionsSelectors';
import { AppDispatch } from 'store/store';

export const useClassPermissionAssigneeOptions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id: objectClassId } = useParams<{ id: string }>();
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  useEffect(() => {
    if (!objectClassId || !permissionSetId) {
      return;
    }

    cancelOngoingRequests();

    const cancelToken = createCancelToken();

    dispatch(
      getObjectClassPermissionSetAssigneesConfiguration(
        objectClassId,
        permissionSetId,
        cancelToken
      )
    );
    dispatch(
      getObjectClassPermissionSetGroupOptions(
        objectClassId,
        permissionSetId,
        cancelToken
      )
    );
  }, [
    objectClassId,
    permissionSetId,
    dispatch,
    cancelOngoingRequests,
    createCancelToken,
  ]);
};
