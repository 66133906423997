import useDateFormat from 'hooks/useDateFormat';
import moment from 'moment';

export const useEventDateTime = (eventDateTime: string): string => {
  const { dateFormat, getTimeWithSecondsFormat } = useDateFormat();

  return moment(eventDateTime).format(
    `${dateFormat} ${getTimeWithSecondsFormat()}`
  );
};
