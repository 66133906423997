import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';

/**
 * Parses the provided filter of type "user_and_group" to query param that can be later on added
 * to query string. This type of filter is quite specific and needs to be handled differently than
 * other filter types as it has to be parsed to two separate query params - one for users and one
 * for user groups.
 *
 * @param fitlerValue - Filter value i.e. selected users and groups.
 * @param queryBase - Filter query base that may contain placeholders for query arguments.
 * @param queryArgs - Arguments that need to be replaced in the query base.
 *
 * @returns Valid query param based on provided filter condition.
 */
export const parseUserAndGroupFilterType = (
  fitlerValue: SelectUserAndGroupOption | undefined,
  queryBase: string,
  queryArgs: string[]
) => {
  const usersQuery = queryBase.replace('{prefix}', 'users');
  const groupsQuery = queryBase.replace('{prefix}', 'user_groups');

  // If a predicate has no query arguments, no values replacement is needed.
  if (queryArgs.length === 0) {
    return `${usersQuery}&${groupsQuery}`;
  }

  const queryParts = [];

  if (fitlerValue?.users.length) {
    const userIds = fitlerValue.users.map(user => user.id).join(',');
    const filledQuery = usersQuery.replace(
      '{value}',
      encodeURIComponent(userIds)
    );

    queryParts.push(filledQuery);
  }

  if (fitlerValue?.groups.length) {
    const groupIds = fitlerValue.groups.map(group => group.id).join(',');
    const filledQuery = groupsQuery.replace(
      '{value}',
      encodeURIComponent(groupIds)
    );

    queryParts.push(filledQuery);
  }

  return queryParts.join('&');
};
