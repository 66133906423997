import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import React from 'react';
import { UrlCell } from './UrlCell';

export const PublisherCell = ({ row }: CatalystTableCellProps) => {
  const { publisher, publisher_url } = row ?? {};
  if (publisher_url) {
    return <UrlCell value={publisher_url} label={publisher} />;
  }

  return <>{publisher}</>;
};
