import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import FormField from 'pages/TaskTemplates/components/FormField';
import { Col } from 'components/lib/Grid';
import { FormikInputNumber } from 'components/lib/InputNumber';

import {
  useHandleMaxMinValidation,
  useMaxLengthValidators,
  useSelectMaxMinValidation,
  useSelectUsersMaxMinValidation,
  useSelectGroupsMaxMinValidation,
  useUserMinMaxDisplay,
  useGroupMinMaxDisplay,
} from './hooks';
import { useFormikContext } from 'formik';
import {
  OBJECT_CLASS_FIELD_MAX_FILES,
  OBJECT_CLASS_FIELD_MAX_LENGTH,
  OBJECT_CLASS_FIELD_MAX_OPTIONS,
  OBJECT_CLASS_FIELD_MAX_VALUE,
  OBJECT_CLASS_FIELD_MIN_OPTIONS,
  OBJECT_CLASS_FIELD_MIN_VALUE,
  OBJECT_CLASS_FIELD_MIN_USERS_OPTIONS,
  OBJECT_CLASS_FIELD_MAX_USERS_OPTIONS,
  OBJECT_CLASS_FIELD_MIN_GROUPS_OPTIONS,
  OBJECT_CLASS_FIELD_MAX_GROUPS_OPTIONS,
} from 'utils/testIds';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import MinMaxInfo from 'components/MinMaxInfo';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts/ClassFieldFormContext';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { typesWithConstraints } from '../../consts/typesWithConstraints';
import { maxUsersCount } from '../../consts';
import { maxGroupsCount } from 'pages/ObjectClasses/components/ClassFieldFormWrapper/consts';
import { FormContentProps } from '../../types';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ClassFieldForm } from '../../../../types';

export const FieldValidation: React.FC<FormContentProps> = ({
  shouldBeDisabled,
  children,
  parsedTypes,
}) => {
  const styles = useClassFieldPropertiesStyles();
  const { max_value: maxNumber, min_value: minNumber } = useMaxLengthValidators(
    parsedTypes
  );

  const {
    values: { type, max_length: maxLength },
  } = useFormikContext<ClassFieldForm>();
  const { readOnly } = useClassFieldFormContext();

  const handleMaxMinValidation = useHandleMaxMinValidation();
  const handleSelectMaxMinValidation = useSelectMaxMinValidation();
  const handleSelectUsersMaxMinValidation = useSelectUsersMaxMinValidation();
  const handleSelectGroupsMaxMinValidation = useSelectGroupsMaxMinValidation();
  const showUsersMinMax = useUserMinMaxDisplay();
  const showGroupsMinMax = useGroupMinMaxDisplay();
  return (
    <div className={styles.fieldPropertiesWrapper}>
      {typesWithConstraints.includes(type) && (
        <FormField gutter={0}>
          {type === ObjectClassFieldTypes.String && (
            <Col>
              <FormLabel>
                <FormattedMessage
                  id='objectClasses.fields.maxLength'
                  defaultMessage='Max length'
                />
              </FormLabel>
              <div>
                <FormikInputNumber<ClassFieldForm>
                  name={ClassFieldFormFields.MaxLength}
                  min={maxLength || maxLength === 0 ? minNumber : undefined}
                  max={maxNumber}
                  isDecimal={false}
                  disabled={readOnly || shouldBeDisabled}
                  data-testid={OBJECT_CLASS_FIELD_MAX_LENGTH}
                />
              </div>
            </Col>
          )}

          {[ObjectClassFieldTypes.Int, ObjectClassFieldTypes.Float].includes(
            type
          ) && (
            <>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.minValue'
                    defaultMessage='Min value'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MinValue}
                    isDecimal={type === ObjectClassFieldTypes.Float}
                    onBlur={handleMaxMinValidation}
                    onStep={(value: number) =>
                      handleMaxMinValidation(
                        ClassFieldFormFields.MinValue,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MIN_VALUE}
                  />
                </div>
              </Col>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.maxValue'
                    defaultMessage='Max value'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MaxValue}
                    isDecimal={type === ObjectClassFieldTypes.Float}
                    onBlur={handleMaxMinValidation}
                    onStep={(value: number) =>
                      handleMaxMinValidation(
                        ClassFieldFormFields.MaxValue,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MAX_VALUE}
                  />
                </div>
              </Col>
            </>
          )}
          {type === ObjectClassFieldTypes.Set && (
            <>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.minSelections'
                    defaultMessage='Min selections'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MinValues}
                    min={0}
                    isDecimal={false}
                    onBlur={handleSelectMaxMinValidation}
                    onStep={(value: number) =>
                      handleSelectMaxMinValidation(
                        ClassFieldFormFields.MinValues,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MIN_OPTIONS}
                  />
                </div>
              </Col>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.maxSelections'
                    defaultMessage='Max selections'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MaxValues}
                    min={0}
                    isDecimal={false}
                    onBlur={handleSelectMaxMinValidation}
                    onStep={(value: number) =>
                      handleSelectMaxMinValidation(
                        ClassFieldFormFields.MaxValues,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MAX_OPTIONS}
                  />
                </div>
              </Col>
            </>
          )}
          {type === ObjectClassFieldTypes.User && showUsersMinMax && (
            <>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.minUsersSelections'
                    defaultMessage='Min users selection'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MinUsersValues}
                    min={0}
                    maximum={maxUsersCount}
                    isDecimal={false}
                    onBlur={handleSelectUsersMaxMinValidation}
                    onStep={(value: number) =>
                      handleSelectUsersMaxMinValidation(
                        ClassFieldFormFields.MinUsersValues,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MIN_USERS_OPTIONS}
                  />
                </div>
              </Col>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.maxUsersSelections'
                    defaultMessage='Max users selection'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MaxUsersValues}
                    min={0}
                    maximum={maxUsersCount}
                    isDecimal={false}
                    onBlur={handleSelectUsersMaxMinValidation}
                    onStep={(value: number) =>
                      handleSelectUsersMaxMinValidation(
                        ClassFieldFormFields.MaxUsersValues,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MAX_USERS_OPTIONS}
                  />
                </div>
              </Col>
            </>
          )}
          {type === ObjectClassFieldTypes.User && showGroupsMinMax && (
            <>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.minGroupsSelections'
                    defaultMessage='Min groups selection'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MinGroupsValues}
                    min={0}
                    maximum={maxGroupsCount}
                    isDecimal={false}
                    onBlur={handleSelectGroupsMaxMinValidation}
                    onStep={(value: number) =>
                      handleSelectGroupsMaxMinValidation(
                        ClassFieldFormFields.MinGroupsValues,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MIN_GROUPS_OPTIONS}
                  />
                </div>
              </Col>
              <Col>
                <FormLabel>
                  <FormattedMessage
                    id='objectClasses.fields.maxGroupsSelections'
                    defaultMessage='Max groups selection'
                  />
                </FormLabel>
                <div>
                  <FormikInputNumber<ClassFieldForm>
                    name={ClassFieldFormFields.MaxGroupsValues}
                    min={0}
                    maximum={maxGroupsCount}
                    isDecimal={false}
                    onBlur={handleSelectGroupsMaxMinValidation}
                    onStep={(value: number) =>
                      handleSelectGroupsMaxMinValidation(
                        ClassFieldFormFields.MaxGroupsValues,
                        value
                      )
                    }
                    disabled={readOnly || shouldBeDisabled}
                    data-testid={OBJECT_CLASS_FIELD_MAX_GROUPS_OPTIONS}
                  />
                </div>
              </Col>
            </>
          )}
          {type === ObjectClassFieldTypes.Document && (
            <Col>
              <FormLabel required>
                <FormattedMessage
                  id='objectClasses.fields.maxFiles'
                  defaultMessage='Max number of files'
                />
              </FormLabel>
              <div>
                <FormikInputNumber<ClassFieldForm>
                  name={ClassFieldFormFields.MaxFiles}
                  min={minNumber}
                  max={maxNumber}
                  isDecimal={false}
                  disabled={readOnly || shouldBeDisabled}
                  data-testid={OBJECT_CLASS_FIELD_MAX_FILES}
                />
              </div>
              <MinMaxInfo minimum={minNumber} maximum={maxNumber} />
            </Col>
          )}
        </FormField>
      )}
      {children}
    </div>
  );
};
