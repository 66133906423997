import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCommonPredicates } from './useCommonPredicates';

/**
 * Get all number predicates. Is declared as a hook so that it can support the dynamic language
 * translation of predicate labels.
 *
 * @returns Number filter predicates.
 */
export const useNumberPredicates = () => {
  const intl = useIntl();

  const { commonPredicates } = useCommonPredicates();

  const numberPredicates = useMemo(
    () => ({
      exact: {
        label: intl.formatMessage({
          id: 'predicates.isEqual',
          defaultMessage: 'is equal to',
        }),
        value: 'exact',
        query: '={value}',
        args: ['value'],
      },
      not_exact: {
        label: intl.formatMessage({
          id: 'predicates.isNotEqual',
          defaultMessage: 'is not equal to',
        }),
        value: 'not_exact',
        query: '!={value}',
        args: ['value'],
      },
      lt: {
        label: intl.formatMessage({
          id: 'predicates.isLessThan',
          defaultMessage: 'is less than',
        }),
        value: 'lt',
        query: '__lt={value}',
        args: ['value'],
      },
      lte: {
        label: intl.formatMessage({
          id: 'predicates.isLessOrEqual',
          defaultMessage: 'is less than or equal to',
        }),
        value: 'lte',
        query: '__lte={value}',
        args: ['value'],
      },
      gt: {
        label: intl.formatMessage({
          id: 'predicates.isGreaterThan',
          defaultMessage: 'is greater than',
        }),
        value: 'gt',
        query: '__gt={value}',
        args: ['value'],
      },
      gte: {
        label: intl.formatMessage({
          id: 'predicates.isGreaterOrEqual',
          defaultMessage: 'is greater than or equal to',
        }),
        value: 'gte',
        query: '__gte={value}',
        args: ['value'],
      },
      range: {
        label: intl.formatMessage({
          id: 'predicates.range',
          defaultMessage: 'is between',
        }),
        value: 'range',
        query: '__range={value1},{value2}',
        args: ['value1', 'value2'],
      },
      not_range: {
        label: intl.formatMessage({
          id: 'predicates.notInRange',
          defaultMessage: 'is not between',
        }),
        value: 'not_range',
        query: '__range!={value1},{value2}',
        args: ['value1', 'value2'],
      },
      ...commonPredicates,
    }),
    [commonPredicates, intl]
  );

  return { numberPredicates };
};
