import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAddUserAssigneesPanelViewStyles = createUseStyles(
  (theme: ThemeState) => ({
    mainWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      gap: '16px',

      height: '100%',
      padding: '16px',
    },
    contentWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gap: '8px',

      overflow: 'auto',
    },
    selectWrapper: {
      position: 'relative',
    },
    itemList: {
      overflow: 'auto',

      '& .ant-empty-description': {
        color: theme['secondary-color'],
      },
    },
  })
);
