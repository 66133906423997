import { User, UserCreatedBy } from './users.types';
import { DefaultPermissions } from './permissions.types';

export interface UserGroupPermissions extends DefaultPermissions {
  edit_members: boolean;
  edit_owners: boolean;
  edit_perm_sets: boolean;
}

export interface UserGroup {
  id: string;
  created_at: Date;
  created_by: User;
  description: string;
  modified_at: Date;
  modified_by: User;
  name: string;
  num_of_members: number;
  num_of_owners: number;
  _meta: {
    permissions: UserGroupPermissions;
  };
}

export interface UserGroupSequenceUsageItem {
  id: number;
  name: string;
}

export interface UserGroupPermissionSetUsageItem {
  id: number;
  name: string;
}

export interface UserGroupUsageItem {
  id: number;
  name: string;
  permission_sets: UserGroupPermissionSetUsageItem[];
}

export interface UserGroupObjectClassFieldUsageItem {
  id: number;
  name: string;
  object_class_id: number;
  object_class_name: string;
}

export type UserGroupObjClassPermissionSetUsageItem = {
  id: number;
  name: string;
  object_class_id: number;
  object_class_name: string;
};

export interface UserGroupUsage {
  sequences: UserGroupSequenceUsageItem[];
  num_of_sequences: number;
  user_groups: UserGroupUsageItem[];
  num_of_user_groups: number;
  object_classes_fields: UserGroupObjectClassFieldUsageItem[];
  num_of_object_classes_fields: number;
  object_classes_permission_sets: UserGroupObjClassPermissionSetUsageItem[];
  num_of_object_classes_permission_sets: number;
  object_record_permission_sets: unknown[];
  num_of_object_record_permission_sets: number;
  num_of_tasks: number;
}

export enum UserGroupMembership {
  Owner = 'owner',
  NonMember = 'non_member',
  Member = 'member',
}

/**
 * A user entity in response to GET of users belonging to user group (both members and owners).
 */
export type GroupMember = {
  company_name: string;
  first_name: string;
  id: number;
  last_name: string;
  membership: UserGroupMembership;
  username: string;
};

/**
 * If there is no `user_group` prop then `id` indicates group id and `name` indicates group name.
 * But when `user_group` prop is present then `id` indicates assignement id.
 */
export type UserGroupAssignee =
  | {
      id: number;
      name: string;
      created_by?: UserCreatedBy;
      created_at?: Date;
    }
  | {
      id: number;
      user_group: {
        id: number;
        name: string;
      };
      created_by: UserCreatedBy;
      created_at: Date;
    };
