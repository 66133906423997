import React, { useMemo } from 'react';
import { TableRowContext } from './TableRowContext';
import { TableRowContextProviderProps } from './TableRowContextProvider.types';

export const TableRowContextProvider = (
  props: TableRowContextProviderProps
) => {
  const contextValue: TableRowContextProviderProps = useMemo(
    () => ({
      ...props,
    }),
    [props]
  );

  const { children } = props;

  return (
    <TableRowContext.Provider value={contextValue}>
      {children}
    </TableRowContext.Provider>
  );
};
