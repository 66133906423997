import { AddGroupAssigneesPanelView } from 'components/AddAssigneesPanelView/views/AddGroupAssigneesPanelView';
import { useTaskOptionsContext } from 'components/SidePanels/TasksPanel/contexts/TaskOptionsContext';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import useFormatNumber from 'hooks/useFormatNumber';
import React from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { TASK_GROUP_ASSIGNEES } from 'utils/endpoints';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

export const AddTaskGroupAssignees = () => {
  const inlt = useIntl();

  const formatNumberWithSeparators = useFormatNumber();
  const {
    task,
    taskAssignees,
    setMode,
    refetchTaskDetails,
  } = useTasksPanelContext();
  const { taskGroupAssigneeOptions } = useTaskOptionsContext();

  const autocompleteEndpoint =
    taskGroupAssigneeOptions?.batch?.autocomplete ?? '';
  const postEndpoint = generatePath(TASK_GROUP_ASSIGNEES, {
    id: task?.id,
  });
  const groupsLimit = taskGroupAssigneeOptions?.restrictions?.limit_items;
  const assignedGroupIds = taskAssignees.userGroups.map(assignee => {
    return assignee.id;
  });
  const additionalContextText = inlt.formatMessage(
    {
      id: 'tasksPanel.recordId',
      defaultMessage: 'Record ID: {recordId}',
    },
    {
      recordId: formatNumberWithSeparators(Number(task?.object_record)),
    }
  );

  const changePanelView = () => {
    setMode(TasksPanelMode.Reassign);
  };

  return (
    <AddGroupAssigneesPanelView
      resourceName={task?.name ?? ''}
      panelKey={FlexLayoutWindows.Tasks}
      assignedGroupIds={assignedGroupIds}
      autocompleteEndpoint={autocompleteEndpoint}
      postEndpoint={postEndpoint}
      additionalContextText={additionalContextText}
      groupsLimit={groupsLimit}
      changePanelView={changePanelView}
      refetchGroupAssignees={refetchTaskDetails}
    />
  );
};
