import React from 'react';
import { UsageItemProps } from './UsageItem.types';
import { useUsageItemStyles } from './UsageItem.styles';
import FormCategory from 'pages/TaskTemplates/components/FormCategory';
import { VIEW_USAGE_SECTION_TESTID } from 'utils/testIds';
import { createTestId } from 'utils/functions/createTestId';
import { UsageItemContent } from './components/UsageItemContent';

export const UsageItem = ({ usageConfig }: UsageItemProps) => {
  const styles = useUsageItemStyles();

  return (
    <FormCategory
      className={styles.section}
      noMarginTop
      collapsible
      isLight
      testId={createTestId(VIEW_USAGE_SECTION_TESTID, usageConfig.title)}
      collapsibleContent={<UsageItemContent usageConfig={usageConfig} />}
    >
      {usageConfig.title}
    </FormCategory>
  );
};
