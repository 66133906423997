import Tooltip from 'components/lib/Tooltip';
import React from 'react';
import { useDisabledButtonTooltipStyles } from './styles';
import { DisabledButtonTooltipProps } from './types';

// this component allows to create tooltip over disabled buttons
// it adds an overlay over button which allows tooltip to pick mouseEnter/mouseLeave events
// which are blocked by disabled button by mouseEvents: none

const DisabledButtonTooltip = ({
  title,
  children,
  showTooltip,
}: DisabledButtonTooltipProps) => {
  const styles = useDisabledButtonTooltipStyles();

  if (!showTooltip) {
    return children;
  }

  return (
    <Tooltip className={styles.tooltip} title={title}>
      <div className={styles.buttonWrapper}>
        {children}
        <div className={styles.overlay} />
      </div>
    </Tooltip>
  );
};

export default DisabledButtonTooltip;
