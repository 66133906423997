import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useState } from 'react';
import { patchUserFieldUsersAndGroups } from '../utils';
import axios from 'axios';
import { isNil } from 'lodash';

export const usePatchObjectRecordUserField = (
  patchUrl: string | undefined,
  objectClassId: number | string | undefined,
  objectClassFieldAlias: string
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  /**
   * Will try to patch the field with the provided data and modify pending and error states.
   * @param patchData Payload of the patch intent.
   * @throws Will throw an error if the field could not be patched and it errored out with
   * other error than known validation error response.
   */
  const patchUserField = async (
    patchData: UsersAndGroupsFormValue | null | undefined
  ) => {
    if (!patchUrl || !objectClassId) {
      throw new Error(
        'patchUserField: Undefined patchUrl or objectClassId. Unable to make the field patch call.'
      );
    }

    try {
      setIsSaving(true);
      setErrors([]);

      const result = await patchUserFieldUsersAndGroups(
        patchUrl,
        objectClassId,
        objectClassFieldAlias,
        patchData ?? null
      );

      setIsSaving(false);
      return result.data;
    } catch (error) {
      setIsSaving(false);

      if (!axios.isAxiosError(error)) {
        throw error;
      }

      // If we can identify that the API error response is related to fields validation
      // we put the error message in the errors state and return undefined data instead of rethrowing.
      const fieldAliasError = error.response?.data[objectClassFieldAlias];

      if (isNil(fieldAliasError)) {
        throw error;
      }

      setErrors([fieldAliasError]);
    }
  };

  return { isSaving, errors, patchUserField };
};
