import { CustomFieldResetFunction, FormValue } from 'alx-dynamic-form';
import { FormBuilderChildObjectClass } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import { FormDataValue } from 'components/FormPreview/types';
import { InPlaceEditInputSize } from 'components/InPlaceEditControls/types';
import {
  CustomFileItem,
  FileDataFromApi,
} from 'components/lib/FileUpload/types';
import { SearchBarDisplay } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types/searchBarDisplay';
import React, { Dispatch, SetStateAction } from 'react';
import { ObjectRecordDetailsMeta } from 'utils/types/api/objectRecords.types';

export interface FormState {
  hasErrors: boolean;
  isFormTouched: boolean;
  isUploadingInProgress?: boolean;
}

export interface FormPreview2Props {
  schema?: string;
  uischema?: string;
  onSubmit?: (
    formData: MappedObject<FormValue>,
    tokenData: MappedObject<CustomFileItem[]>
  ) => Promise<boolean>;
  onCancel?: () => void;
  saving?: boolean;
  withFormHeader?: boolean;
  title?: string;
  submitButtonContent?: React.ReactNode | string;
  submitButtonIcon?: React.ReactNode;
  initialValues?: MappedObject<FormValue>;
  errors?: MappedObject<string[]>;
  inPlaceEditMode?: boolean;
  inPlaceEditUrl?: string;
  readOnly?: boolean;
  onInPlaceSaveSuccess?: (saveResult: any) => void;
  inPlaceEditSize?: InPlaceEditInputSize;
  isCreateMode?: boolean;
  omitDefaultValues?: boolean;
  widgetsPopupContainer?: () => HTMLElement;
  disabled?: boolean;
  onFormStateChange?: (formState: FormState) => void;
  setFormData?: Dispatch<SetStateAction<MappedObject<FormValue> | undefined>>;
  preventModalAppearing?: boolean;
  showShemaError?: boolean;
  onSchemaErrorGoBackClick?: () => void;
  hideOwners?: boolean;
  skipUploading?: boolean;
  additionalFieldProps?: {
    /**
     * Contains information which fields failed fulfillment validation. Set contains IDs of the failed field.
     */
    failedFulfillmentValidationFieldsMap?: Set<number>;
    /**
     * ObjectClass ID to which the form relates.
     */
    classId?: string;
    childClasses?: FormBuilderChildObjectClass[];
    recordIdentifier?: string | number;
    meta?:
      | ObjectRecordDetailsMeta
      | {
          labels: {
            state: string;
            files: MappedObject<FileDataFromApi>;
          };
        };

    customUploadUrl?: string;
    shouldDownloadFileOnClick?: boolean;
    identifier?: string;
    /**
     * Record ID to which the form relates.
     */
    recordId?: string;
    /**
     * Optional set that can contain information which fields errored out during form fulfillment validation. Set contains labels of the failed field.
     */
    formFulfillmentErroredFields?: Set<string>;
    /**
     * Optional information passed to user type fields.
     */
    userTypeFields?: {
      /**
       * Information to all user type fields on how those types of fields search bar should behave on this form.
       */
      searchBarBehavior?: SearchBarDisplay;
      /**
       * Tells the user type fields contained within the form on how to operate. Not providing the value will be treated as {@link UserTypeFieldsOperationMode.Create}.
       */
      operationMode: UserTypeFieldsOperationMode;
    };
  };
  testId?: string;
  customFieldResetFunction?: CustomFieldResetFunction;
  wrapperId?: string;
  objectClassId?: number;
}

/**
 * Mode of operations for user type fields.
 */
export enum UserTypeFieldsOperationMode {
  /**
   * Works as create mode with no preselected values. Less validation checks and less interactivity.
   * @default
   */
  Create,
  /**
   * Works as edit mode with preselected values. More validation checks and more interactivity. Additional reject changes functionality available.
   */
  Edit,
}

export interface FormPreview2ContentProps
  extends Omit<FormPreview2Props, 'schema' | 'uischema'> {
  schema: string;
  uischema: string;
}

export interface FormPreview2RefProps {
  resetForm: (withoutConfirmation?: boolean) => void;
  submitForm: () => void;
  setFormUntouched: () => void;
}

export interface ApplyFilesMetaToFormFieldsParams {
  uiSchema?: string;
  formData: MappedObject<FormDataValue>;
  filesMeta?: MappedObject<FileDataFromApi> | null;
  defaultKeysOfFieldsWithFiles?: MappedObject<string>;
}
