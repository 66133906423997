import { CustomAvatarGroup } from 'components/lib/Avatar';
import Popover from 'components/lib/Popover';
import React, { MouseEvent, useState } from 'react';
import { SelectedUsersAndGroupsEditorProps } from './SelectedUsersAndGroupsEditor.types';
import { useSelectedUsersAndGroupsEditorStyles } from './SelectedUsersAndGroupsEditor.styles';
import { ButtonLink } from 'components/lib/Button';
import { FormattedMessage } from 'react-intl';
import { EditSelectedUsersAndGroups } from './components/EditSelectedUsersAndGroups';
import { EDIT_SELECTED_USERS_AND_GROUPS_BTN_TESTID } from 'utils/testIds';
import { useSelectOptionAvatars } from 'hooks/avatars/useSelectOptionAvatars';

/**
 * Displays selected user and group avatars and allows to edit the selection.
 */
export const SelectedUsersAndGroupsEditor = ({
  selectedUsers,
  selectedGroups,
  onUserDelete,
  onGroupDelete,
}: SelectedUsersAndGroupsEditorProps) => {
  const styles = useSelectedUsersAndGroupsEditorStyles();

  const { avatars } = useSelectOptionAvatars(selectedUsers, selectedGroups);
  const [isOpen, setIsOpen] = useState(false);

  const closePopover = () => setIsOpen(false);

  const handleUserDelete = (id: number) => {
    const filteredUsers = selectedUsers.filter(user => user.id !== id);

    onUserDelete(filteredUsers);

    if (filteredUsers.length !== 0 || selectedGroups.length !== 0) {
      return;
    }

    closePopover();
  };

  const handleGroupDelete = (id: number) => {
    const filteredGroups = selectedGroups.filter(group => group.id !== id);

    onGroupDelete(filteredGroups);

    if (filteredGroups.length !== 0 || selectedUsers.length !== 0) {
      return;
    }

    closePopover();
  };

  const handleEditBtnClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsOpen(prev => !prev);
  };

  const getPopupContainer = (triggerNode: HTMLElement) => {
    return triggerNode.parentElement ?? document.body;
  };

  return (
    <Popover
      overlayClassName={styles.popoverOverlay}
      visible={isOpen}
      trigger='contextMenu'
      placement='topLeft'
      onVisibleChange={setIsOpen}
      getPopupContainer={getPopupContainer}
      content={
        <EditSelectedUsersAndGroups
          selectedUsers={selectedUsers}
          selectedGroups={selectedGroups}
          onUserDelete={handleUserDelete}
          onGroupDelete={handleGroupDelete}
        />
      }
    >
      <div className={styles.mainWrapper} onClick={closePopover}>
        <CustomAvatarGroup items={avatars} disablePopover={isOpen} />

        {!!avatars.length && (
          <ButtonLink
            onClick={handleEditBtnClick}
            data-testid={EDIT_SELECTED_USERS_AND_GROUPS_BTN_TESTID}
          >
            <FormattedMessage id='misc.edit' defaultMessage='Edit' />
          </ButtonLink>
        )}
      </div>
    </Popover>
  );
};
