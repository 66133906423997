import React from 'react';
import clsx from 'clsx';
import { DangerouslyRenderCkeditorContentProps } from './types';
import { ckeditorContentClassName } from 'components/formBuilder/formBuilder/FBGeneralInput/components/LandingPageHTMLEditor/consts';
import { useDangerouslyRenderCkeditorContentStyles } from './styles';

// !!! Upon the informed request of the client, this component is a potential critical threat to the application security. Use only when necessary in areas with very restrictive access

const DangerouslyRenderCkeditorContent = ({
  value,
  testId,
}: DangerouslyRenderCkeditorContentProps) => {
  const styles = useDangerouslyRenderCkeditorContentStyles();

  return (
    <div
      dangerouslySetInnerHTML={{ __html: value }}
      className={clsx(styles.valueWrapper, ckeditorContentClassName)}
      data-testid={testId}
    />
  );
};

export default DangerouslyRenderCkeditorContent;
