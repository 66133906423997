import React from 'react';
import clsx from 'clsx';
import removeArrItem from 'lodash/remove';
import { Checkbox } from 'components/lib/Checkbox';
import { BULK_SELECTION_COLUMN_WIDTH } from 'components/CatalystTable/CatalystTable.consts';
import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import {
  TABLE_BULK_SELECTION_ROW_CHECKBOX_TESTID,
  TABLE_BULK_SELECTION_ROW_CHECKBOX_WRAPPER_TESTID,
} from 'utils/testIds';
import { BulkSelectionRowCheckboxProps } from './BulkSelectionRowCheckbox.types';
import { useTableRowStyles } from '../../TableRow.styles';

export const BulkSelectionRowCheckbox = ({
  rowId,
}: BulkSelectionRowCheckboxProps) => {
  const styles = useTableRowStyles();
  const { setBulkSelectionList, bulkSelectionList } = useTableContext();

  const handleOnChangeRowCheckbox = () =>
    setBulkSelectionList(prev => {
      const isSelectedIdInBulkSelectionList = prev.includes(rowId);

      return isSelectedIdInBulkSelectionList
        ? removeArrItem(prev, bulkSelectionId => bulkSelectionId !== rowId)
        : [...prev, rowId];
    });

  return (
    <div
      className={clsx([
        styles.tbodyTd,
        styles.tableCell,
        styles.tableCellCentered,
      ])}
      style={{ width: BULK_SELECTION_COLUMN_WIDTH }}
      data-testid={TABLE_BULK_SELECTION_ROW_CHECKBOX_WRAPPER_TESTID}
    >
      <Checkbox
        onChange={handleOnChangeRowCheckbox}
        checked={bulkSelectionList.includes(rowId)}
        className={styles.checkbox}
        data-testid={TABLE_BULK_SELECTION_ROW_CHECKBOX_TESTID}
      />
    </div>
  );
};
