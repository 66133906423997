import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EventItem } from './components/EventItem';
import { useEventsListStyles } from './EventsList.styles';
import { EventListProps } from './EventsList.types';
import { EventItemErrorFallback } from './components/EventItemErrorFallback/EventItemErrorFallback';

export const EventsList = ({ events, refreshData }: EventListProps) => {
  const styles = useEventsListStyles();
  return (
    <div className={styles.eventsListWrapper}>
      {events.map(e => (
        <ErrorBoundary
          FallbackComponent={EventItemErrorFallback}
          key={e?.key || e.event_id}
        >
          <EventItem event={e} refreshData={refreshData} />
        </ErrorBoundary>
      ))}
    </div>
  );
};
