import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorComponent from 'components/ErrorComponent';
import { Loader } from 'components/lib/Loader';
import List from 'components/List';
import PeopleListElement from '../PeopleListElement';
import useOwnersPanelStyles from './styles';
import SearchInput from 'components/SearchInput';
import { SCROLLABLE_CONTAINER } from 'utils/elementsIds';
import { ButtonPrimary } from 'components/lib/Button';
import useRecordOwnersPanel from './hooks';
import { PlusIcon } from 'components/Icon';
import {
  MANAGE_OWNERS_PANEL_BUTTON_ADD_OWNERS,
  RECORD_ACCESS_IDENTIFIER,
  RECORD_ACCESS_PERMISSION_SET_SELECT,
  RECORD_ACCESS_SEARCH,
} from 'utils/testIds';
import clsx from 'clsx';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import LimitMessage from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/LimitMessage';
import RemoveOwnerModal from './RemoveOwnerModal';
import { Select } from 'components/lib/Select';
import { PermissionSetSelectOptions } from './types';
import { PeopleListElementProps } from '../PeopleListElement/types';
import TooltipString from 'components/TooltipString';

const RecordOwners = () => {
  const intl = useIntl();
  const classes = useOwnersPanelStyles({});

  const {
    openAddOwnersPanel,
    onDeleteFabric,
    isRemovingHimself,
    ownerToDelete,
    setOwnerToDelete,
    removeObjectRecordOwner,
    isLimitExceeded,
    loading,
    error,
    identifier,
    searchValue,
    data,
    total,
    filteredCount,
    searchData,
    searchInputRef,
    objectRecordId,
    permissionSetSelectOptions,
    selectedPermissionSet,
    handleSelectPermissionSet,
    ref,
    permissionSetNames,
    getUsers,
    limitErrorMessage,
    limitWarningMessage,
    itemsLimit,
    giveAccessTooltipMessage,
  } = useRecordOwnersPanel();

  if (error) return <ErrorComponent error={error?.status} />;

  return (
    <div className={classes.container} ref={ref}>
      <Loader spinning={loading}>
        <div className={classes.panelWrapper}>
          <div>
            <div className={classes.headerWrapper}>
              <TooltipString
                text={identifier}
                className={classes.identifier}
                testId={RECORD_ACCESS_IDENTIFIER}
              >
                {identifier}
              </TooltipString>

              <DisabledButtonTooltip
                title={giveAccessTooltipMessage}
                showTooltip={isLimitExceeded}
              >
                <ButtonPrimary
                  onClick={openAddOwnersPanel}
                  icon={<PlusIcon size={9} />}
                  data-testid={MANAGE_OWNERS_PANEL_BUTTON_ADD_OWNERS}
                  disabled={isLimitExceeded}
                  className={clsx(classes.buttonAddOwners, {
                    [classes.buttonDisabled]: isLimitExceeded,
                  })}
                >
                  <FormattedMessage
                    id='misc.giveAccess'
                    defaultMessage='Give access'
                  />
                </ButtonPrimary>
              </DisabledButtonTooltip>
            </div>
            <div className={classes.resultsInfo}>
              {!!searchValue ? (
                <FormattedMessage
                  id='misc.searchedResultsCount'
                  defaultMessage='{filteredCount} of {total, plural, other {# results} one {# result}}'
                  values={{ total, filteredCount }}
                />
              ) : (
                <FormattedMessage
                  id='misc.resultsCount'
                  defaultMessage='{count, plural, other {{count} results} one {{count} result}}'
                  values={{ count: total }}
                />
              )}
            </div>
            {selectedPermissionSet !== PermissionSetSelectOptions.All && (
              <div className={classes.limitWrapper}>
                <LimitMessage
                  {...{ itemsLimit }}
                  customErrorMessage={limitErrorMessage}
                  customWarningMessage={limitWarningMessage}
                  count={total}
                />
              </div>
            )}
            <div className={classes.inputsWrapper}>
              <SearchInput
                className={classes.ownersSearch}
                searchKey='name__icontains'
                handleSearchChange={searchData}
                placeholder={intl.formatMessage({
                  id: 'misc.searchForUsersAndUserGroups',
                  defaultMessage: 'Search for users and user groups',
                })}
                disabled={data.length < 1 && !searchValue}
                ref={searchInputRef}
                data-testid={RECORD_ACCESS_SEARCH}
              />

              <Select
                value={loading ? undefined : selectedPermissionSet}
                className={classes.permissionSelect}
                options={permissionSetSelectOptions}
                onChange={handleSelectPermissionSet}
                data-testid={RECORD_ACCESS_PERMISSION_SET_SELECT}
                virtual={false}
                getPopupContainer={() => ref?.current ?? document.body}
              />
            </div>
          </div>
          {total !== undefined && (
            <div
              className={classes.scrollableContainer}
              id={SCROLLABLE_CONTAINER}
            >
              <List<PeopleListElementProps>
                emptyDataDescription={
                  !!searchInputRef?.current?.state?.value
                    ? intl.formatMessage({
                        id: 'misc.pleaseCheckFilters',
                        defaultMessage: 'Please check your filters.',
                      })
                    : intl.formatMessage({
                        id: 'owners.noOneHasGivenAccess',
                        defaultMessage: 'No one has been given access.',
                      })
                }
                shouldShowEmptyDataTitle
                items={data}
                renderItem={item => (
                  <PeopleListElement
                    {...item}
                    {...{ permissionSetNames, getUsers }}
                    onDelete={onDeleteFabric(item)}
                  />
                )}
              />
            </div>
          )}
        </div>
      </Loader>
      <RemoveOwnerModal
        {...{
          objectRecordId,
          ownerToDelete,
          isLoading: loading,
          isRemovingHimself,
          setOwnerToDelete,
          removeObjectRecordOwner,
        }}
      />
    </div>
  );
};

export default RecordOwners;
