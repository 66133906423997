import { createUseStyles } from 'react-jss';

export const useUsageItemStyles = createUseStyles({
  section: {
    '& .ant-collapse-header': {
      marginBottom: '4px !important',
      paddingLeft: '8px !important',
      paddingRight: '8px !important',
    },

    '& .ant-collapse-content-box': {
      paddingBottom: '0 !important',
    },
  },
});
