import moment from 'moment';

const YEAR_FORMAT = 'YYYY-MM-DD';

export const TODAY = moment().format(YEAR_FORMAT);
export const YESTERDAY = moment().subtract(1, 'days').format(YEAR_FORMAT);
export const THIS_WEEK_START = moment().startOf('isoWeek').format(YEAR_FORMAT);
export const THIS_WEEK_END = moment().endOf('isoWeek').format(YEAR_FORMAT);
export const WEEK_FUTURE_END = moment().add(6, 'days').format(YEAR_FORMAT);
export const WEEK_PAST_START = moment().subtract(7, 'days').format(YEAR_FORMAT);
export const TODAY_PLUS_29_DAYS = moment().add(29, 'days').format(YEAR_FORMAT);
export const TODAY_MINUS_30_DAYS = moment()
  .subtract(30, 'days')
  .format(YEAR_FORMAT);
