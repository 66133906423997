import { DynamicExtendedSchema } from 'components/formBuilder/formBuilder/types';
import { ExtendedUiSchema } from 'components/FormPreview/customFields/ObjectField/types';
import { createUiSectionSchema, getFieldsInSchema } from './utils';

export const convertUiSchema = (
  dynamicSchema: DynamicExtendedSchema,
  uiSchema: ExtendedUiSchema
): ExtendedUiSchema => {
  const fieldsInDataSchema = dynamicSchema?.properties
    ? getFieldsInSchema(dynamicSchema.properties)
    : {};

  const schemaEntries = Object.entries(uiSchema).map(
    ([sectionAlias, sectionContent]) => {
      if (sectionAlias.startsWith('ui:')) {
        return [sectionAlias, sectionContent];
      }

      const sectionSchema = createUiSectionSchema(
        sectionContent,
        sectionAlias,
        fieldsInDataSchema
      );

      return [sectionAlias, sectionSchema];
    }
  );

  return Object.fromEntries(schemaEntries) as ExtendedUiSchema;
};
