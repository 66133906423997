import React from 'react';
// eslint-disable-next-line
import { FormattedMessage } from 'react-intl';

/**
 * Intl callback for italic parts of text. Provide the fallback inside the value object provided to {@link FormattedMessage}
 * to convert any tag into text wrapped in <i></i> tags.
 *
 * Example:
 * value: {
 *     i: ItalicTextPartsFallback, // where 'i' is the desired tag within the text to be converted to <i></i> tags
 * }
 */
export const ItalicTextPartsFallback = (...chunks: string[]) => <i>{chunks}</i>;
