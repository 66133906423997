import { usePredicates } from './usePredicates';
import { useCallback } from 'react';
import { FilterValue } from 'store/actions/filtersActions';

/**
 * Allows to validate provided filters.
 *
 * @param filters Filters to be validated.
 * @param allowEmptyFilters If true, empty filters are allowed.
 *
 * @returns Function that allows to validate provided filters.
 */
export const useValidateFilters = (
  filters: FilterValue[],
  allowEmptyFilters = false
) => {
  const { predicates } = usePredicates();

  const areFiltersValid = useCallback(() => {
    const allFilters = Object.values(filters);

    const hasErrors = allFilters.some(filter => {
      if (filter.column === '' || filter.value.predicateKey === '') {
        if (allowEmptyFilters && filter.column === '') return false;

        return true;
      }

      const predicate = predicates[filter.type][filter.value.predicateKey];
      const filterPredicateValues = Object.values(filter.value.predicateValues);

      if (filterPredicateValues.length < predicate?.args?.length) {
        return true;
      }

      return filterPredicateValues.some(predicateValue => {
        if (typeof predicateValue === 'string') {
          return predicateValue.trim() === '';
        }

        if (typeof predicateValue === 'number') {
          return false;
        }

        if (Array.isArray(predicateValue)) {
          return predicateValue.length === 0;
        }

        // Special check for filter value of type "user_and_group"
        if ('users' in predicateValue && 'groups' in predicateValue) {
          return (
            predicateValue.users.length === 0 &&
            predicateValue.groups.length === 0
          );
        }

        return false;
      });
    });

    return !hasErrors;
  }, [allowEmptyFilters, filters, predicates]);

  return {
    areFiltersValid,
  };
};
