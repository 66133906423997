import { createUseStyles } from 'react-jss';

export const useSyncGroupToggleStyles = createUseStyles(() => ({
  tooltipStyle: {
    zIndex: '1600',

    '& .ant-tooltip-arrow': {
      display: 'none',
    },
  },
}));
