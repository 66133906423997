import { CustomFileItem } from 'components/lib/FileUpload/types';
import { ApplyFilesMetaToFormFieldsParams } from '../types';
import { getKeysOfFieldsWithFiles } from './getKeysOfFieldsWithFiles';

export const applyFilesMetaToFormFields = ({
  uiSchema,
  formData,
  filesMeta,
  defaultKeysOfFieldsWithFiles,
}: ApplyFilesMetaToFormFieldsParams) => {
  if (!filesMeta) return formData;

  const keysOfFieldsWithFiles = uiSchema
    ? getKeysOfFieldsWithFiles(uiSchema)
    : defaultKeysOfFieldsWithFiles || {};

  return Object.fromEntries(
    Object.entries(formData).map(([key, value]) => {
      if (key in keysOfFieldsWithFiles) {
        // value is number when it contains only file id, when it is object it contains all file
        // data ant it does not have to be merged with meta
        const fieldFiles = value as (number | object)[] | null;

        const parsedField =
          fieldFiles?.reduce<CustomFileItem[]>((result, id) => {
            if (typeof id !== 'number') {
              return [...result, id as CustomFileItem];
            }

            /**
             * Filter out files that don't have associated data in files meta
             */
            if (!filesMeta[id]) {
              return result;
            }

            return [
              ...result,
              {
                ...filesMeta[id],
                id,
                status: 'done',
              },
            ];
          }, []) ?? [];

        return [key, parsedField];
      }

      return [key, value];
    })
  );
};
