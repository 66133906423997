import React from 'react';
import TooltipString from 'components/TooltipString';
import { BoldedPartOfTextProps } from './BoldedPartOfText.types';
import Highlighter from 'react-highlight-words';
import { useBoldedPartOfTextStyles } from './BoldedPartOfText.styles';

export const BoldedPartOfText = ({
  value,
  noBoldedValue = '',
  boldPhrase = '',
  hideArrow,
}: BoldedPartOfTextProps) => {
  const styles = useBoldedPartOfTextStyles();

  return boldPhrase ? (
    <TooltipString
      text={`${value}${noBoldedValue}`}
      modifiedText={
        <>
          <Highlighter
            highlightClassName={styles.bolded}
            searchWords={[boldPhrase.trim()]}
            textToHighlight={value}
            autoEscape
          />
          {noBoldedValue && <span>{noBoldedValue}</span>}
        </>
      }
      {...{ hideArrow }}
    />
  ) : (
    <TooltipString text={`${value}${noBoldedValue}`} {...{ hideArrow }} />
  );
};
