import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import React from 'react';
import { UserCell } from './UserCell';

export const UserCustomCell = ({ row }: CatalystTableCellProps) => {
  const { first_name, last_name, id } = row;

  return (
    <UserCell
      user={{
        id,
        first_name,
        last_name,
      }}
    />
  );
};
