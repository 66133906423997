import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { TasksQuickFilterOwnership } from '../types/tasksQuickFilterOwnership';

const { intl } = GlobalIntlSingleton;

export const OWNERSHIP_PREDICATE_LABELS = {
  [TasksQuickFilterOwnership.Default]: '------',
  [TasksQuickFilterOwnership.Assignees]: intl.formatMessage({
    id: 'tasks.current_assignees',
  }),
  [TasksQuickFilterOwnership.Owners]: intl.formatMessage({
    id: 'tasks.current_owner',
  }),
};
