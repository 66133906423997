import React from 'react';
import { AddUserAssigneesPanelViewProps } from './AddUserAssigneesPanelView.types';
import { useIntl } from 'react-intl';
import { useBeforeunload } from 'react-beforeunload';
import { useAddUserAssigneesPanelViewStyles } from './AddUserAssigneesPanelView.styles';
import AutocompleteUsersSelect from 'components/AutocompleteUsersSelect';
import { OptionAvatar } from 'components/OptionAvatars/OptionAvatar';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import { ADD_USER_ASSIGNEES_TESTID } from 'utils/testIds';
import { useAddUserAssignees } from './hooks/useAddUserAssignees';
import { useUserAssigneesSelection } from './hooks/useUserAssigneesSelection';
import CancelConfigurationModal from 'components/CancelConfigurationModal';
import CustomPrompt from 'components/CustomPrompt';
import { CustomPromptType } from 'components/CustomPrompt/types';
import { useDisabledUserAssigneeOption } from './hooks/useDisabledUserAssigneeOption';
import { useAddAssigneesCancellation } from 'components/AddAssigneesPanelView/hooks/useAddAssigneesCancellation';
import { AddAssigneesPanelViewHeader } from 'components/AddAssigneesPanelView/components/AddAssigneesPanelViewHeader';
import { AddAssigneesPanelViewFooter } from 'components/AddAssigneesPanelView/components/AddAssigneesPanelViewFooter';
import { getIsUserAssigneesLimitReached } from './utils/getIsUserAssigneesLimitReached';
import { AddUserAssigneesAlert } from './components/AddUserAssigneesAlert';

/**
 * It's a component dedicated to use in panels that allows assiging users for a given resource
 * e.g. task.
 */
export const AddUserAssigneesPanelView = ({
  panelKey,
  resourceName,
  assignedUserIds,
  autocompleteEndpoint,
  postEndpoint,
  additionalContextText,
  hasAnyAssignees,
  usersLimit,
  changePanelView,
  refetchUserAssignees,
}: AddUserAssigneesPanelViewProps) => {
  const intl = useIntl();
  const styles = useAddUserAssigneesPanelViewStyles();

  const {
    showUnsavedChangesModal,
    navigateTo,
    handleCustomPromptCancelBtn,
    handlePanelCancelBtn,
    handleUnsavedChangesModalConfirmBtn,
    handleUnsavedChangesModalCancelBtn,
    updatePanelCloseBtnBehavior,
  } = useAddAssigneesCancellation(panelKey, changePanelView);
  const {
    hasSelectedUsers,
    isOneTCUserSelected,
    selectedUsers,
    handleUserSelect,
    handleUserRemove,
  } = useUserAssigneesSelection(updatePanelCloseBtnBehavior);
  const {
    getDisabledUserText,
    getIsUserDisabled,
  } = useDisabledUserAssigneeOption(
    assignedUserIds,
    hasAnyAssignees,
    hasSelectedUsers,
    selectedUsers
  );
  const { isSubmitting, handleSubmit } = useAddUserAssignees(
    selectedUsers,
    postEndpoint,
    resourceName,
    usersLimit,
    changePanelView,
    refetchUserAssignees
  );

  const isLimitReached = getIsUserAssigneesLimitReached(
    usersLimit,
    selectedUsers.length,
    assignedUserIds.length
  );

  const title = intl.formatMessage(
    {
      id: 'misc.assignUsersTo',
      defaultMessage: 'Assign users to {name}',
    },
    { name: resourceName }
  );
  const counterText = intl.formatMessage(
    {
      id: 'misc.usersSelected',
      defaultMessage:
        '{count, plural, one {# user selected} other {# users selected}}',
    },
    { count: selectedUsers.length }
  );
  const emptyDataDescription = intl.formatMessage({
    id: 'misc.noUsersSelected',
    defaultMessage: 'No users selected',
  });

  // Prevent e.g. refreshing or closing the page when user has selected some users, i.e. the form is dirty
  useBeforeunload(event => hasSelectedUsers && event.preventDefault());

  return (
    <>
      <div
        className={styles.mainWrapper}
        data-testid={ADD_USER_ASSIGNEES_TESTID}
      >
        <AddAssigneesPanelViewHeader
          counterText={counterText}
          title={title}
          additionalContextText={additionalContextText}
        />

        <div className={styles.contentWrapper}>
          {isOneTCUserSelected || isLimitReached ? (
            <AddUserAssigneesAlert
              assignedUsersCount={assignedUserIds.length}
              isLimitReached={isLimitReached}
              selectedUsersCount={selectedUsers.length}
            />
          ) : (
            <div className={styles.selectWrapper}>
              <AutocompleteUsersSelect
                autocompleteUrl={autocompleteEndpoint}
                selectMultiple
                onChange={handleUserSelect}
                checkOptionIsDisabled={getIsUserDisabled}
                renderOption={(option, searchValue) => (
                  <OptionAvatar
                    option={option}
                    searchValue={searchValue}
                    disabledText={getDisabledUserText(option.id)}
                  />
                )}
              />
            </div>
          )}

          <List<AvatarItem>
            className={styles.itemList}
            items={selectedUsers}
            emptyDataDescription={emptyDataDescription}
            renderItem={item => (
              <PeopleListElement
                {...item}
                onDelete={() => handleUserRemove(item)}
              />
            )}
          />
        </div>

        <AddAssigneesPanelViewFooter
          hasSelectedItems={hasSelectedUsers}
          isSubmitting={isSubmitting}
          onAddBtnClick={handleSubmit}
          onCancelBtnClick={() => handlePanelCancelBtn(hasSelectedUsers)}
        />
      </div>

      <CancelConfigurationModal
        visible={showUnsavedChangesModal}
        onCancel={handleUnsavedChangesModalCancelBtn}
        onConfirm={handleUnsavedChangesModalConfirmBtn}
      />
      <CustomPrompt
        when={!navigateTo && hasSelectedUsers}
        type={CustomPromptType.CANCEL}
        onCancel={handleCustomPromptCancelBtn}
      />
    </>
  );
};
