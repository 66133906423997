import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { TasksQuickFilterDates } from '../types/tasksQuickFilterDates';

const { intl } = GlobalIntlSingleton;

export const DATE_PREDICATE_LABELS = {
  [TasksQuickFilterDates.Default]: '------',
  [TasksQuickFilterDates.NoDueDate]: intl.formatMessage({
    id: 'predicates.isNull',
  }),
  [TasksQuickFilterDates.Today]: intl.formatMessage({ id: 'predicates.today' }),
  [TasksQuickFilterDates.ThisWeek]: intl.formatMessage({
    id: 'predicates.thisWeek',
  }),
  [TasksQuickFilterDates.WithinAWeek]: intl.formatMessage({
    id: 'predicates.withinWeek',
  }),
  [TasksQuickFilterDates.Within30Days]: intl.formatMessage({
    id: 'predicates.within30Days',
  }),
  [TasksQuickFilterDates.Yesterday]: intl.formatMessage({
    id: 'predicates.yesterday',
  }),
  [TasksQuickFilterDates.WithinTheLastWeek]: intl.formatMessage({
    id: 'predicates.withinLastWeek',
  }),
  [TasksQuickFilterDates.WithinTheLast30Days]: intl.formatMessage({
    id: 'predicates.withinLast30Days',
  }),
  [TasksQuickFilterDates.ExpiredTasks]: intl.formatMessage({
    id: `predicates.expiredTasks`,
  }),
};
