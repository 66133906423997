import { useLayoutEffect } from 'react';
import {
  TABLE_BODY_ID,
  TABLE_HEAD_ROW_ID,
  TABLE_PAGINATION_ID,
} from 'utils/elementsIds';

export const useDynamicTablePaginationWidth = () => {
  useLayoutEffect(() => {
    if (!window.ResizeObserver) {
      return;
    }

    const tableHeadRowElement = document.getElementById(TABLE_HEAD_ROW_ID);
    const paginationElement = document.getElementById(TABLE_PAGINATION_ID);
    const tableBodyElement = document.getElementById(TABLE_BODY_ID);

    if (!tableHeadRowElement || !tableBodyElement || !paginationElement) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const tableHeadRowWidth = tableHeadRowElement.getBoundingClientRect()
        .width;

      if (tableHeadRowWidth <= tableBodyElement.getBoundingClientRect().width) {
        paginationElement.style.width = `${tableHeadRowWidth.toString()}px`;
      } else {
        paginationElement.style.width = 'calc(100% - 15px)';
      }

      // do not show button until table is fully initialized (it has really small width while its not)
      if (tableBodyElement.getBoundingClientRect().width < 100) {
        return;
      }

      paginationElement.classList.remove('hide-buttons');
    });

    resizeObserver.observe(tableHeadRowElement);
    resizeObserver.observe(tableBodyElement);

    return () => {
      if (!resizeObserver) {
        return;
      }

      resizeObserver.disconnect();
    };
  }, []);
};
