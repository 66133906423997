import { FormValue } from 'alx-dynamic-form';
import { useCallback, useEffect, useRef } from 'react';

/**
 * Due to lack of control of asynchronous state change for form field value using setValue function. This hook schedules a validation
 * the next time a provided value changes.
 * @param value Widget value that should trigger revalidation when the time comes.
 * @param revalidate Revalidation function that should run after the value is truly updated.
 **/
export const useRevalidate = (value: FormValue, revalidate: () => void) => {
  const shouldValidate = useRef(false);

  /**
   * Will schedule revalidation to be performed, next time the value provided to the hook changes.
   */
  const scheduleRevalidation = useCallback(() => {
    shouldValidate.current = true;
  }, []);

  useEffect(() => {
    if (!shouldValidate.current) {
      return;
    }

    revalidate();
    shouldValidate.current = false;
  }, [revalidate, value]);

  return { scheduleRevalidation };
};
