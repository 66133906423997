import React from 'react';
import { ArrowSmallUp, ArrowSmallDown } from 'components/Icon';
import clsx from 'clsx';
import OrderType from 'utils/Enums/OrderType';
import { SORT_ASC_TESTID, SORT_DESC_TESTID } from 'utils/testIds';
import { useCatalystTableStyles } from 'components/CatalystTable/CatalystTable.styles';
import { SorterProps } from './Sorter.types';

export const Sorter = ({ isVisible, isSorted, isSortedDesc }: SorterProps) => {
  const styles = useCatalystTableStyles();

  if (!isVisible) {
    return null;
  }

  const sortDirection = isSortedDesc
    ? OrderType.Descending
    : OrderType.Ascending;

  const order = isSorted ? sortDirection : undefined;

  return (
    <div className={styles.sorterWrapper}>
      <ArrowSmallUp
        size={6}
        className={clsx([
          styles.upperArrow,
          { [styles.active]: order === OrderType.Ascending },
        ])}
        data-testid={SORT_ASC_TESTID}
      />
      <ArrowSmallDown
        size={6}
        className={clsx({
          [styles.active]: order === OrderType.Descending,
        })}
        data-testid={SORT_DESC_TESTID}
      />
    </div>
  );
};
