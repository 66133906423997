import React, { useCallback, useMemo } from 'react';
import { Collapse } from 'antd';
import clsx from 'clsx';
import { ArrowRightExpandIcon } from 'components/Icon';
import useFormCategoryStyles from './styles';
import { FormCategoryProps } from './types';

const COLLAPSIBLE_KEY = 'form-category-collapsible-content';

const FormCategory: React.FC<FormCategoryProps> = ({
  noMarginTop,
  noMarginBottom,
  noHorizontalMargin,
  children,
  collapsibleContent,
  collapsible,
  className,
  hideHeader,
  isLight,
  testId,
}) => {
  const classes = useFormCategoryStyles(
    useMemo(
      () => ({
        noMarginTop,
        noMarginBottom,
        noHorizontalMargin,
        collapsible,
        hideHeader,
      }),
      [noMarginTop, noMarginBottom, noHorizontalMargin, collapsible, hideHeader]
    )
  );

  const { Panel } = Collapse;
  const collapseProps = !collapsible
    ? { activeKey: [COLLAPSIBLE_KEY] }
    : undefined;

  const ExpandIcon = useCallback(
    ({ isActive }: { isActive?: boolean }) => (
      <ArrowRightExpandIcon
        size={7}
        className={clsx([
          classes.suffixIcon,
          {
            [classes.suffixIconActive]: isActive,
          },
        ])}
      />
    ),
    [classes.suffixIcon, classes.suffixIconActive]
  );

  return (
    <div data-testid={testId}>
      <Collapse
        defaultActiveKey={[COLLAPSIBLE_KEY]}
        ghost
        destroyInactivePanel
        expandIconPosition='right'
        className={clsx(
          {
            [classes.collapse]: !isLight,
            [classes.collapseLight]: isLight,
          },
          className
        )}
        expandIcon={ExpandIcon}
        {...collapseProps}
      >
        <Panel
          header={children}
          key={COLLAPSIBLE_KEY}
          showArrow={!!collapsible}
        >
          {collapsibleContent}
        </Panel>
      </Collapse>
    </div>
  );
};

export default FormCategory;
