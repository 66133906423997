import { createUseStyles } from 'react-jss';

export const useObjectClassOptionsLimitReachedMessageStyles = createUseStyles({
  wrapper: {
    marginTop: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  alert: {
    width: '100%',
  },
});
