import { RootAction } from 'store/reducers';
import { Reducer } from 'redux';
import {
  SET_IS_ABOUT_MODAL_VISIBLE,
  SET_API_VERSION,
  SHOW_ERROR_DETAILS_MODAL,
  CLOSE_ERROR_DETAILS_MODAL,
} from 'store/constants/misc.consts';
import { ApiHistoryType, MiscState } from './types/misc.types';

const initialState: MiscState = {
  apiVersion: undefined,
  historyVersion: ApiHistoryType.NA,
  isAboutModalVisible: false,
  isErrorDetailsModalVisible: false,
  errorDetailsModalPayload: undefined,
};

const miscReducer: Reducer<MiscState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_API_VERSION: {
      return {
        ...state,
        apiVersion: action.payload.version,
        historyVersion: action.payload.historyVersion,
      };
    }
    case SET_IS_ABOUT_MODAL_VISIBLE: {
      return {
        ...state,
        isAboutModalVisible: action.payload,
      };
    }
    case SHOW_ERROR_DETAILS_MODAL: {
      return {
        ...state,
        isErrorDetailsModalVisible: true,
        errorDetailsModalPayload: action.payload,
      };
    }
    case CLOSE_ERROR_DETAILS_MODAL: {
      return {
        ...state,
        isErrorDetailsModalVisible: false,
        errorDetailsModalPayload: undefined,
      };
    }
    default:
      return state;
  }
};

export default miscReducer;
