import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getBodyRowHeight } from 'pages/MyAccount/components/MyAccountSections/components/PreferencesTab/components/RowSettings/components/styles';

type UseEmptyTableRowStylesProps = {
  index: number;
  density?: TableDensity;
};

export const useEmptyTableRowStyles = createUseStyles((theme: ThemeState) => ({
  tr: {
    border: `1px solid transparent`,

    position: 'absolute',
    top: (props: UseEmptyTableRowStylesProps) =>
      `${props.index * getBodyRowHeight(props?.density)}px`,
    width: 'var(--table-row-width) !important',
    height: (props: UseEmptyTableRowStylesProps) =>
      `${getBodyRowHeight(props?.density)}px`,
    lineHeight: (props: UseEmptyTableRowStylesProps) =>
      `${getBodyRowHeight(props?.density)}px`,
    '& .ant-spin-container': {
      lineHeight: '25px',
    },
  },
  alternateRow: {
    backgroundColor: theme['dropdown-hover-gray'],
  },
}));
