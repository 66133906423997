import { cloneDeep } from 'lodash';
import { FilterValue } from 'react-table';
import { Logger, LogLevel } from 'utils/logger';
import { correctFiltersForInvalidEntry } from './utils/correctFiltersForInvalidEntry';
// eslint-disable-next-line
import { checkResponseForFilterErrors } from '../checkResponseForFilterErrors';
import { InvalidFilterData } from '../../types/InvalidFilterData';

/**
 * Removes wrong filter options and fixes filters that can have their values adjusted based on the provided error
 * response and returns the corrected list.
 * @param invalidFilterEntries invalid filter entries identified by {@link checkResponseForFilterErrors}.
 * @param filters Filters to be corrected.
 * @returns Corrected filters list.
 */
export const correctFilterList = (
  filters: FilterValue[],
  invalidFilterEntries: InvalidFilterData[]
): FilterValue[] => {
  Logger.log(
    'Scanning filters for fitting error values.',
    LogLevel.Verbose,
    filters
  );

  const newFilters = cloneDeep(filters);

  invalidFilterEntries.forEach(errorEntry =>
    correctFiltersForInvalidEntry(errorEntry, newFilters)
  );

  Logger.log('Filters after correction.', LogLevel.Verbose, newFilters);
  return newFilters;
};
