import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useCopyButtonStyles = createUseStyles((theme: ThemeState) => ({
  button: {
    width: '2rem',
    height: '2rem',
    padding: '4px',

    '&:hover, &:focus': {
      opacity: 0.8,
      color: theme['primary-color'],
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '100%',
    height: '100%',
    margin: '0 !important',
  },
}));
