import { useMemo } from 'react';
import { useIntl } from 'react-intl';

/**
 * Get all boolean predicates. Is declared as a hook so that it can support the dynamic language
 * translation of predicate labels.
 *
 * @returns Boolean filter predicates.
 */
export const useBooleanPredicates = () => {
  const intl = useIntl();

  const booleanPredicates = useMemo(
    () => ({
      exact: {
        label: intl.formatMessage({
          id: 'predicates.isEqual',
          defaultMessage: 'is equal to',
        }),
        value: 'exact',
        query: '={value}',
        args: ['value'],
      },
    }),
    [intl]
  );

  return { booleanPredicates };
};
