import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CustomUserWidgetTooltipProps } from './CustomUserWidgetTooltip.types';
import Tooltip from 'components/lib/Tooltip';
import { useGlobalMousePosition } from 'hooks/useGlobalMousePosition';

export const CustomUserWidgetTooltip = ({
  children,
  tooltipText,
}: CustomUserWidgetTooltipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const { getMousePosition } = useGlobalMousePosition();

  const isMouseOverTrigger = useCallback(() => {
    if (!triggerRef.current) {
      return false;
    }

    const rect = triggerRef.current.getBoundingClientRect();
    const mousePosition = getMousePosition();
    return (
      mousePosition.x >= rect.left &&
      mousePosition.x <= rect.right &&
      mousePosition.y >= rect.top &&
      mousePosition.y <= rect.bottom
    );
  }, [getMousePosition]);

  /* When CustomUserWidget fullment validation happens, user mouse might be above the trigger component. This causes
  the tooltip to mount without mouseEnter event so the tooltip won't trigger. This is a workaround
  to open it when the tooltipText is set and the mouse is above the trigger. 
  
  Lifting state up is impractical here as it would defeat the purpose of the separate tooltip component and would
  cause a lot of code colocation issues.
  
  This tooltip is not expandable to further DOM tree so I believe it shouldn't pose issues further down the line. */
  useEffect(() => {
    if (!tooltipText || !isMouseOverTrigger()) {
      return;
    }

    setIsOpen(true);
  }, [isMouseOverTrigger, tooltipText]);

  return (
    <Tooltip
      title={tooltipText}
      visible={!!tooltipText && isOpen}
      onVisibleChange={setIsOpen}
      trigger={['hover', 'click']}
      shouldBeRendered={!!tooltipText}
      getPopupContainer={element => element.parentElement ?? document.body}
    >
      <div ref={triggerRef}>{children}</div>
    </Tooltip>
  );
};
