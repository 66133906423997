import React from 'react';
import { AddUserAssigneesAlertProps } from './AddUserAssigneesAlert.types';
import { Alert } from 'components/lib/Alert';
import { useIntl } from 'react-intl';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AddUserAssigneesPanelView } from '../../AddUserAssigneesPanelView';

/**
 * Dedicated alert component for {@link AddUserAssigneesPanelView}.
 */
export const AddUserAssigneesAlert = ({
  assignedUsersCount,
  isLimitReached,
  selectedUsersCount,
}: AddUserAssigneesAlertProps) => {
  const intl = useIntl();

  const message = isLimitReached
    ? intl.formatMessage(
        {
          id: 'misc.selectedUserAssigneesLimitReached',
          defaultMessage:
            'Maximum of {selectedUsersCount} new user {selectedUsersCount, plural, one {assignee} other {assignees}} can be selected ({assignedUsersCount} already assigned).',
        },
        {
          selectedUsersCount,
          assignedUsersCount,
        }
      )
    : intl.formatMessage({
        id: 'misc.oneTCUserSelectedNoFurtherUserBeSelected',
        defaultMessage:
          'A one-time completion user has been selected, therefore no further user can be selected.',
      });

  return <Alert message={message} type='info' />;
};
