import { createUseStyles } from 'react-jss';

export const useGroupClassPemrmissionSetUsageViewStyles = createUseStyles({
  list: {
    padding: 0,
    margin: 0,

    listStylePosition: 'inside',
  },
});
