import React from 'react';
import { FilterIcon } from 'components/Icon';
import ToolButton from 'components/ToolButton';
import Tooltip from 'components/lib/Tooltip';
import { FormattedMessage } from 'react-intl';
import { OPEN_FILTER_EDITOR_TESTID } from 'utils/testIds';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { FilterEditorButtonProps } from './types';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useIsCurrentTableFilterable } from 'hooks/useIsCurrentTableFilterable';

export const FilterEditorButton = ({
  alwaysEnabled,
}: FilterEditorButtonProps) => {
  const { onOpenTab: showFiltersPanel } = useFlexLayoutWindow(
    FlexLayoutWindows.FilterEditor
  );
  const { isFilterable } = useIsCurrentTableFilterable();

  const handleClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.OpenFilterEditor);

    showFiltersPanel();
  };

  return (
    <Tooltip
      title={
        <FormattedMessage id='misc.openFilter' defaultMessage='Open filter' />
      }
    >
      <ToolButton
        disabled={!isFilterable && !alwaysEnabled}
        icon={<FilterIcon size={15} />}
        data-testid={OPEN_FILTER_EDITOR_TESTID}
        onClick={handleClick}
      />
    </Tooltip>
  );
};
