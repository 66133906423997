import React from 'react';
import { Col, Row } from '../../components/lib/Grid';
import { LogoIcon } from '../../components/Icon';
import { FormattedMessage } from 'react-intl';
import { Text } from '../../components/lib/Text';
import AutologyxInformation from 'components/AutologyxInformation';
import useExpiredPasswordStyles from './styles';
import LoaderOverlay from '../../components/LoaderOverlay';
import { useExpiredPasswordData, useExpiredPasswordForm } from './hooks';
import { Checkbox } from '../../components/lib/Checkbox';
import PasswordRules from '../../components/PasswordRules';
import { Formik } from 'formik';
import FormField from '../TaskTemplates/components/FormField';
import FormLabel from '../TaskTemplates/components/FormLabel';
import PasswordInput from '../../components/Inputs/PasswordInput';
import { ButtonPrimary } from '../../components/lib/Button';
import { ExpiredPasswordFields } from './enums';
import { useToggle } from '../../hooks/useToggle';
import ErrorComponent from '../../components/ErrorComponent';
import { StatusCodes } from 'http-status-codes';
import { useParams } from 'react-router-dom';
import { ExpiredPasswordFormValues, ExpiredPasswordParams } from './types';
import { usePasswordRulesData } from 'hooks/usePasswordRulesData';
import { FallbackLogoOptions } from 'components/Icon/types';

const ExpiredPassword = () => {
  const { token } = useParams<ExpiredPasswordParams>();
  const classes = useExpiredPasswordStyles({});
  const {
    loadingPasswordRulesData,
    passwordComplexityData,
  } = usePasswordRulesData({ fetchOnLoad: !!token });
  const {
    activateAccountData,
    loadingActivateAccountData,
    error,
  } = useExpiredPasswordData();
  const {
    setIsPasswordFulfilled,
    onSubmit,
    buildValidationSchema,
    initialValues,
    isPasswordFulfilled,
  } = useExpiredPasswordForm();
  const [toggled, { toggle }] = useToggle(false);

  if (
    loadingActivateAccountData &&
    loadingPasswordRulesData &&
    !passwordComplexityData &&
    (!activateAccountData || !error)
  )
    return <LoaderOverlay />;

  if (!token)
    return (
      <div className={classes.notFoundWrapper}>
        <ErrorComponent error={StatusCodes.NOT_FOUND} />
      </div>
    );

  return (
    <div className={classes.wrapper}>
      <Row justify='center' className='w-100'>
        <Col span={6} xxl={15} xl={16} lg={18} md={14} sm={18} xs={22}>
          <div className={classes.card}>
            <LogoIcon
              width={150}
              size={150}
              fallbackLogo={FallbackLogoOptions.NORMAL}
              className={classes.logo}
            />
            <p className={classes.title}>
              <FormattedMessage
                id='expiredPassword.title'
                defaultMessage='Reset expired password'
              />
            </p>
            <p className={classes.subtitle}>
              <FormattedMessage
                id='expiredPassword.provideNewPassword'
                defaultMessage='Your password has expired. Please provide new password.'
              />
            </p>
            <Formik
              {...{ initialValues, onSubmit }}
              validationSchema={buildValidationSchema()}
              enableReinitialize
            >
              {({ handleSubmit, errors, dirty, isSubmitting }) => (
                <>
                  <FormField>
                    <Col span={12} className={classes.input}>
                      <FormLabel required>
                        <FormattedMessage
                          id='expiredPassword.password'
                          defaultMessage='New password'
                        />
                      </FormLabel>
                      <PasswordInput
                        visibilityToggle={false}
                        isPasswordVisible={toggled}
                        name={ExpiredPasswordFields.Password}
                      />
                    </Col>
                    <Col span={12} className={classes.input}>
                      <FormLabel required>
                        <FormattedMessage
                          id='expiredPassword.confirmPassword'
                          defaultMessage='Confirm new password'
                        />
                      </FormLabel>
                      <PasswordInput
                        visibilityToggle={false}
                        isPasswordVisible={toggled}
                        name={ExpiredPasswordFields.ConfirmPassword}
                      />
                    </Col>
                  </FormField>
                  <Checkbox checked={toggled} onChange={toggle}>
                    <FormattedMessage
                      id='expiredPassword.showPassword'
                      defaultMessage='Show password'
                    />
                  </Checkbox>
                  <PasswordRules<ExpiredPasswordFormValues>
                    setIsFulfilled={setIsPasswordFulfilled}
                    options={passwordComplexityData}
                    passwordKey={ExpiredPasswordFields.Password}
                  />
                  <div className={classes.buttonWrapper}>
                    <ButtonPrimary
                      disabled={
                        Object.keys(errors).length > 0 ||
                        isSubmitting ||
                        !dirty ||
                        !isPasswordFulfilled
                      }
                      onClick={() => handleSubmit()}
                    >
                      <FormattedMessage
                        id='expiredPassword.reset'
                        defaultMessage='Reset password'
                      />
                    </ButtonPrimary>
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Col>
        <div className={classes.footer}>
          <Text className={classes.footerText}>
            <FormattedMessage
              id='login.footer'
              defaultMessage='{copy} {year} autologyx.com All rights reserved.'
              values={{
                copy: <span>&copy;</span>,
                year: new Date().getFullYear(),
                privacy: (
                  <u>
                    <FormattedMessage
                      id='login.privacy'
                      defaultMessage='Privacy'
                    />
                  </u>
                ),
              }}
            />
          </Text>
        </div>
      </Row>
      <div className={classes.informationWrapper}>
        <AutologyxInformation />
      </div>
    </div>
  );
};

export default ExpiredPassword;
