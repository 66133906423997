import moment from 'moment';
import { TaskFormType } from '../../../types';
import { TaskCreateData } from '../types';
import { CUSTOM_DATE_FORMAT } from 'pages/TaskTemplates/components/TaskForm/consts';

export const prepareTaskCreateData = (
  formData: TaskFormType,
  objectRecordId: string | undefined
): TaskCreateData => {
  return {
    object_record: objectRecordId ?? '',
    name: formData.name,
    task_type: formData.task_type,
    task_template: Number(formData.task_template),
    due_date: formData.due_date
      ? moment(formData.due_date).format(CUSTOM_DATE_FORMAT)
      : null,
    instructions: formData.instructions,
  };
};
