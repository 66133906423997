import CancelConfigurationModal from 'components/CancelConfigurationModal';
import ContentDrawer from 'components/ContentDrawer';
import PreventRedirects from 'components/PreventRedirects';
import CreateRecordForm from 'pages/Records/components/CreateRecordForm';
import EditRecordForm from 'pages/Records/components/EditRecordForm';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearStack,
  popStackWithTreeUpdate,
  updateNestedRecordsStackModal,
} from 'store/actions/nestedObjectRecordsActions';
import { NestedObjectRecordsModalTypes } from 'store/reducers/types/nestedObjectRecords.types';
import {
  selectNestedObjectRecordsModal,
  selectStack,
} from 'store/selectors/nestedObjectRecordsSelctors';
import { CHILD_FORM_PREVIEW_WRAPPER } from '../../../utils/elementsIds';

const RecordDisplayDrawer = () => {
  const nestedRecordsStack = useSelector(selectStack);

  const { isVisible, onCancel, onConfirm } =
    useSelector(
      selectNestedObjectRecordsModal(
        NestedObjectRecordsModalTypes.CANCEL_CONFIG_MODAL
      )
    )?.data ?? {};

  const dispatch = useDispatch();

  const hideModal = useCallback(() => {
    dispatch(
      updateNestedRecordsStackModal(
        { isVisible },
        NestedObjectRecordsModalTypes.CANCEL_CONFIG_MODAL
      )
    );
  }, [dispatch, isVisible]);

  const lastStackElement = nestedRecordsStack[nestedRecordsStack.length - 1];

  const isAnyInStackUnsaved = useMemo(
    () =>
      nestedRecordsStack.some(
        element =>
          !element?.recordId &&
          element.formData &&
          Object.values(element?.formData).some(value => !!value)
      ),
    [nestedRecordsStack]
  );

  const onCancelClick = () => {
    dispatch(popStackWithTreeUpdate());
  };

  const isCreate = lastStackElement?.recordId === undefined;

  return (
    <>
      <ContentDrawer
        isOpen={nestedRecordsStack.length > 1}
        onCloseClick={onCancelClick}
      >
        {lastStackElement && isCreate && (
          <CreateRecordForm
            isDrawer={true}
            onCancelClick={onCancelClick}
            classId={lastStackElement.classId}
            key={lastStackElement.classId}
            wrapperId={CHILD_FORM_PREVIEW_WRAPPER}
          />
        )}
        {lastStackElement && !isCreate && (
          // recordId is defined because isCreate verifies that
          <EditRecordForm
            isDrawer={true}
            key={lastStackElement.classId}
            recordId={lastStackElement.recordId?.toString() ?? ''}
            wrapperId={CHILD_FORM_PREVIEW_WRAPPER}
          />
        )}
      </ContentDrawer>
      <CancelConfigurationModal
        visible={!!isVisible}
        onCancel={onCancel ?? hideModal}
        onConfirm={onConfirm ?? hideModal}
      />
      <PreventRedirects
        when={isAnyInStackUnsaved || !!isVisible}
        onCancel={onCancel}
        onDontSave={() => dispatch(clearStack())}
      />
    </>
  );
};

export default RecordDisplayDrawer;
