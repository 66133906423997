import { ThemeState } from 'store/reducers/types/theme.types';
import Membership from 'utils/Enums/Membership';

export const getTagColorsByMembership = (
  membership: Membership,
  theme: ThemeState
) => {
  switch (membership) {
    case Membership.Member:
      return {
        color: theme['chips-color'],
        backgroundColor: theme['chips-success-background-dark-opacity'],
      };
    case Membership.Owner:
      return {
        color: theme['primary-color'],
        backgroundColor: theme['info-bg'],
      };
    default:
      return {
        color: 'transparent',
        backgroundColor: 'transparent',
      };
  }
};
