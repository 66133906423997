import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import useTaskTemplatesViewUsage from './hooks';
import ErrorComponent from 'components/ErrorComponent';
import {
  getTaskTemplatesRow,
  getTaskTemplatesSelectedRow,
} from 'store/selectors/taskTemplatesSelectors';
import { ResourceUsagePanelView } from 'components/ResourceUsagePanelView';

const TaskTemplateViewUsagePanel = () => {
  const intl = useIntl();
  const { closeComponent, isExternal } = useFlexLayoutWindow(
    FlexLayoutWindows.TaskTemplateViewUsage
  );
  const selectedRowId = useSelector(getTaskTemplatesSelectedRow);
  const { name, id } = useSelector(getTaskTemplatesRow(selectedRowId));

  const { displayConfig, loading, error } = useTaskTemplatesViewUsage(id);

  useEffect(() => {
    if (!selectedRowId) closeComponent(FlexLayoutWindows.TaskTemplateViewUsage);
  }, [closeComponent, selectedRowId]);

  if (error) {
    return <ErrorComponent error={error?.status} />;
  }

  return (
    <ResourceUsagePanelView
      displayConfig={displayConfig}
      isExternal={isExternal}
      usageLabel={intl.formatMessage({
        id: 'taskTemplates.thisTemplateIsUsed',
        defaultMessage: 'This template is used in the following system areas:',
      })}
      noUsageLabel={intl.formatMessage({
        id: 'taskTemplates.thisTemplateIsNotUsed',
        defaultMessage: 'This template is not used anywhere in the system.',
      })}
      resourceName={name ?? ''}
      isLoading={loading}
    />
  );
};

export default TaskTemplateViewUsagePanel;
