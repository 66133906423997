import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_FILTERS,
  SET_CURRENT_TABLE,
  APPLY_FILTERS,
  SET_ALL_FILTERS,
  SET_FILTERS_APPLIED_FOR_TABLE,
  SET_FILTER_PREDICATE_VALUE,
  SET_FILTER_PREDICATE,
  REMOVE_FILTER_COLUMN,
  SET_FILTER_COLUMN,
  SET_FILTER_PREDICATE_SET,
  SET_FILTER_SOURCE_ID,
  RESET_FILTERS,
} from 'store/constants/filters.consts';
import { getCurrentPredicateValue } from 'utils/functions/getCurrentPredicateValue';
import { FiltersState } from './types/filters.types';
import { Filter } from 'store/actions/filtersActions';

const initialState: FiltersState = {
  currentTable: undefined,
  applyFilter: false,
  tables: {
    tasks: [],
    sequences: [],
    objectRecords: [],
    taskGroups: [],
    objectClasses: [],
    users: [],
    roles: [],
    forms: [],
    classForms: [],
    documentTemplates: [],
  },
  appliedFilters: [],
  sourceId: undefined,
};

const getTable = (table: Filter | MappedObject<Filter>, sourceId?: string) =>
  ((sourceId && typeof table === 'object'
    ? (table as MappedObject<Filter>)[sourceId]
    : table) ?? []) as Filter;

const setTableFilters = (
  state: FiltersState,
  tableName: string,
  filter: Filter,
  sourceId?: string
) => ({
  ...state,
  tables: {
    ...state.tables,
    [tableName]: sourceId
      ? {
          ...(state.tables?.[tableName] ?? {}),
          [sourceId]: [...filter],
        }
      : [...filter],
  },
});

const filtersReducer: Reducer<FiltersState, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_FILTER_SOURCE_ID: {
      return {
        ...state,
        sourceId: action.payload,
      };
    }
    case SET_FILTERS: {
      return setTableFilters(state, action.name, action.values, state.sourceId);
    }
    case SET_FILTER_COLUMN: {
      const editedTable = getTable(
        state.tables[action.payload.tableName],
        state.sourceId
      );

      const editedFilterIndex = editedTable.findIndex(
        filter => filter.column === action.payload.columnName
      );

      editedTable[editedFilterIndex] = {
        column: action.payload.newColumnName,
        label: action.payload.label,
        type: action.payload.newColumnType,
        predicateSet: editedTable[editedFilterIndex].predicateSet,
        predicates: action.payload.predicates,
        values: action.payload?.values,
        value: {
          predicateKey: '',
          predicateValues: {},
          predicateArgs: [],
        },
      };

      return setTableFilters(
        state,
        action.payload.tableName,
        editedTable,
        state.sourceId
      );
    }
    case SET_FILTER_PREDICATE_SET: {
      const table = getTable(
        state.tables[action.payload.tableName],
        state.sourceId
      );

      const filterId = table.findIndex(
        filter => filter.column === action.payload.columnName
      );

      table[filterId] = {
        predicateSet: action.payload.predicateSet,
        column: '',
        type: '',
        label: '',
        predicates: [],
        value: {
          predicateKey: '',
          predicateValues: {},
          predicateArgs: [],
        },
      };

      return setTableFilters(
        state,
        action.payload.tableName,
        table,
        state.sourceId
      );
    }
    case REMOVE_FILTER_COLUMN: {
      const tableToRemoveFilterColumn = getTable(
        state.tables[action.payload.tableName],
        state.sourceId
      );

      const tableWidthoutRemovedColumn = tableToRemoveFilterColumn.filter(
        filter => filter.column !== action.payload.columnName
      );

      return setTableFilters(
        state,
        action.payload.tableName,
        tableWidthoutRemovedColumn,
        state.sourceId
      );
    }
    case SET_FILTER_PREDICATE: {
      const {
        columnName,
        selectedPredicate,
        selectedPredicate: { args: predicateArgs, selectMultiple },
        predicate: predicateKey,
      } = action.payload;

      const tableToSetPredicate = getTable(
        state.tables[action.payload.tableName],
        state.sourceId
      );

      const predicateIndex = tableToSetPredicate.findIndex(
        filter => filter.column === columnName
      );
      const currentPredicate = tableToSetPredicate[predicateIndex];

      const predicateValues = getCurrentPredicateValue(
        currentPredicate,
        selectedPredicate
      );

      tableToSetPredicate[predicateIndex] = {
        ...currentPredicate,
        value: {
          predicateKey,
          predicateValues,
          predicateArgs,
          isMultipleSelection: !!selectMultiple,
        },
      };

      return setTableFilters(
        state,
        action.payload.tableName,
        tableToSetPredicate,
        state.sourceId
      );
    }
    case SET_FILTER_PREDICATE_VALUE: {
      const newFilters = getTable(
        state.tables[action.payload.tableName],
        state.sourceId
      );

      const filterIndex = newFilters.findIndex(
        filter => filter.column === action.payload.columnName
      );

      newFilters[filterIndex] = {
        ...newFilters[filterIndex],
        value: {
          ...newFilters[filterIndex].value,
          predicateValues: action.payload.value,
        },
      };

      return setTableFilters(
        state,
        action.payload.tableName,
        newFilters,
        state.sourceId
      );
    }
    case SET_ALL_FILTERS: {
      return {
        ...state,
        tables: { ...state.tables, ...action.payload },
      };
    }
    case SET_CURRENT_TABLE: {
      return {
        ...state,
        currentTable: action.payload,
      };
    }
    case RESET_FILTERS: {
      return initialState;
    }
    case APPLY_FILTERS: {
      return {
        ...state,
        applyFilter: action.applyFilter,
      };
    }
    case SET_FILTERS_APPLIED_FOR_TABLE: {
      const tableFilters = state.appliedFilters;

      if (tableFilters.find(({ id }) => id === action.payload.id)) {
        return {
          ...state,
          appliedFilters: tableFilters.map(item =>
            item.id === action.payload.id ? action.payload : item
          ),
        };
      }

      return {
        ...state,
        appliedFilters: [...state.appliedFilters, action.payload],
      };
    }

    default: {
      return state;
    }
  }
};

export default filtersReducer;
