import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getSidebarData } from 'store/selectors/flexLayoutSelectors';
import { getUserGroupPermissionsGetSelectedPermissionSet } from 'store/selectors/userGroupPermissionsSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import ManageAssigneesTabs from '../ManageAssigneesTabs';
import AddPermissionSetAssignee from '../AddPermissionSetAssignee';
import { AddPermissionSetAssigneeGroup } from '../AddPermissionSetAssigneeGroup';
import { TabsKeys } from '../ManageAssigneesTabs/types';
import { setUserGroupSelectedPermissionSet } from 'store/actions/userGroupPermissionsActions';
import { PanelMode } from 'utils/Enums/PanelMode';

const UserGroupPermissionSetAssignees = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<TabsKeys>(TabsKeys.Users);
  const {
    [FlexLayoutWindows.UserGroupPermissionSetAssignees]: {
      mode = PanelMode.Manage,
    } = {},
  } = useSelector(getSidebarData);
  const {
    availableComponents,
    flexLayoutMethods: { onRenameTab },
  } = useFlexLayoutContext();

  const selectedPermissionSet = useSelector(
    getUserGroupPermissionsGetSelectedPermissionSet
  );

  useEffect(() => {
    return () => {
      dispatch(setUserGroupSelectedPermissionSet(undefined));
    };
  }, [dispatch]);

  const Panel = useCallback(() => {
    if (mode === PanelMode.Adding) {
      switch (currentTab) {
        case TabsKeys.Users: {
          return <AddPermissionSetAssignee />;
        }
        case TabsKeys.Groups: {
          return <AddPermissionSetAssigneeGroup />;
        }
      }
    }
    return (
      <ManageAssigneesTabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    );
  }, [mode, currentTab]);

  const tabNames = useMemo(
    () => ({
      [PanelMode.Adding]: {
        owners: intl.formatMessage({
          id: 'misc.addOwners',
          defaultMessage: 'Add owners',
        }),
        assignees: intl.formatMessage({
          id: 'misc.addAssignees',
          defaultMessage: 'Add assignees',
        }),
      },
      [PanelMode.Manage]: {
        owners:
          availableComponents[FlexLayoutWindows.UserGroupPermissionSetAssignees]
            .name,
        assignees: intl.formatMessage({
          id: 'misc.manageAssignees',
          defaultMessage: 'Manage assignees',
        }),
      },
    }),
    [availableComponents, intl]
  );

  useEffect(() => {
    const tabName = selectedPermissionSet?.id
      ? tabNames[mode].assignees
      : tabNames[mode].owners;

    onRenameTab(FlexLayoutWindows.UserGroupPermissionSetAssignees, tabName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, selectedPermissionSet]);

  return <Panel />;
};

export default UserGroupPermissionSetAssignees;
