import { StatusCodes } from 'http-status-codes';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  APPEND_OBJECT_CLASSES_FIELDS,
  REMOVE_OBJECT_CLASSES_FIELDS,
  RESET_OBJECT_CLASSES_FIELDS,
  RESET_OBJECT_CLASSES_FIELDS_COLUMNS,
  SET_OBJECT_CLASSES_FIELDS,
  SET_OBJECT_CLASSES_FIELDS_COLUMNS,
  SET_OBJECT_CLASSES_FIELDS_DETAILS,
  SET_OBJECT_CLASSES_FIELDS_FETCHING,
  SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW,
  UPDATE_FIELD,
  SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES,
  REMOVE_FIELD,
  ADD_FIELD,
  REORDER_FIELDS,
  RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET,
} from 'store/constants/objectClassesFields.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import { OptionsResponse, OptionsResponseDetails } from 'utils/types';
import {
  ActionObject,
  ColumnsMetadata,
  SetTableAction,
} from 'utils/types/api/table.types';
import {
  ObjectClassField,
  ObjectClassFieldReorderData,
} from '../../utils/types/api/objectClassesFields.types';
import { OBJECT_CLASS_DETAILS_FIELDS } from '../../utils/endpoints';
import {
  setObjectClassFieldsRestrictions,
  SetObjectClassFieldsRestrictionsAction,
} from './objectClassesActions';
import { createTableUrl } from '../../utils/functions/createTableUrl';
import { generatePath } from 'react-router-dom';
import { FormMode } from '../../utils/Enums/FormMode';
import DataFetchType from 'utils/Enums/DataFetchType';
import routes from 'utils/routingPaths';
import { getDynamicPathParts } from 'utils/functions/getDynamicPathParts';
import { store } from 'store/store';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';

export interface ObjectClassFieldsParams {
  details?: OptionsResponseDetails;
  mode?: FormMode;
  id?: string;
  queryParams?: string;
}

export type SetObjectClassesFieldsAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'list' | 'total' | 'filtered'>,
  typeof SET_OBJECT_CLASSES_FIELDS
>;

export type SetObjectClassesFieldsDetailsAction = SetTableAction<
  { payload: OptionsResponseDetails },
  typeof SET_OBJECT_CLASSES_FIELDS_DETAILS
>;

export type AppendObjectClassesFieldsAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'list' | 'total' | 'filtered'>,
  typeof APPEND_OBJECT_CLASSES_FIELDS
>;

export type SetObjectClassesFieldsFetchingAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'fetching'>,
  typeof SET_OBJECT_CLASSES_FIELDS_FETCHING
>;

export type SetObjectClassesFieldsColumnsAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'payload'>,
  typeof SET_OBJECT_CLASSES_FIELDS_COLUMNS
>;

export type ResetObjectClassDetailsColumnsAction = Action<
  typeof RESET_OBJECT_CLASSES_FIELDS_COLUMNS
>;

export type ResetObjectClassesFieldsAction = Action<
  typeof RESET_OBJECT_CLASSES_FIELDS
>;

export type SetSelectedRowAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'selectedRow'>,
  typeof SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW
>;

export type RemoveObjectClassesFieldsAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'list' | 'total' | 'filtered'>,
  typeof REMOVE_OBJECT_CLASSES_FIELDS
>;

export type SetObjectClassFieldsInitialValuesAction = SetTableAction<
  Pick<ActionObject<ObjectClassField>, 'list'>,
  typeof SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES
>;

export type UpdateFieldAction = SetTableAction<
  { payload: Omit<ObjectClassField, 'order'> },
  typeof UPDATE_FIELD
>;

export type AddFieldAction = SetTableAction<
  { payload: ObjectClassField },
  typeof ADD_FIELD
>;

export type DeleteFieldAction = SetTableAction<
  { payload: ObjectClassField['id'] },
  typeof REMOVE_FIELD
>;

export type ReorderFieldsAction = SetTableAction<
  { payload: ObjectClassFieldReorderData },
  typeof REORDER_FIELDS
>;
export type ResetObjectClassesFieldsCustomOffsetAction = Action<
  typeof RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET
>;

export type ObjectClassesFieldsAction =
  | SetObjectClassesFieldsAction
  | SetObjectClassesFieldsFetchingAction
  | AppendObjectClassesFieldsAction
  | ResetObjectClassesFieldsAction
  | SetObjectClassesFieldsColumnsAction
  | ResetObjectClassDetailsColumnsAction
  | SetSelectedRowAction
  | SetObjectClassesFieldsDetailsAction
  | UpdateFieldAction
  | AddFieldAction
  | DeleteFieldAction
  | ReorderFieldsAction
  | ResetObjectClassesFieldsCustomOffsetAction
  | RemoveObjectClassesFieldsAction
  | SetObjectClassFieldsRestrictionsAction
  | SetObjectClassFieldsInitialValuesAction;

export const setObjectClassesFields = (
  list: ObjectClassField[],
  total: number,
  filtered: number
): SetObjectClassesFieldsAction => {
  return {
    type: SET_OBJECT_CLASSES_FIELDS,
    list,
    total,
    filtered,
  };
};

export const appendObjectClassesFields = (
  list: ObjectClassField[],
  total: number,
  filtered: number
): AppendObjectClassesFieldsAction => {
  return {
    type: APPEND_OBJECT_CLASSES_FIELDS,
    list,
    total,
    filtered,
  };
};

export const setFetchingObjectClassesFields = (
  fetching: boolean
): SetObjectClassesFieldsFetchingAction => {
  return {
    type: SET_OBJECT_CLASSES_FIELDS_FETCHING,
    fetching,
  };
};

export const resetObjectClassesFields = (): ResetObjectClassesFieldsAction => {
  return {
    type: RESET_OBJECT_CLASSES_FIELDS,
  };
};

export const resetObjectClassesFieldsColumns = (): ResetObjectClassDetailsColumnsAction => ({
  type: RESET_OBJECT_CLASSES_FIELDS_COLUMNS,
});

export const getObjectClassesFields = (
  queryParams?: string,
  fetchType:
    | DataFetchType.Append
    | DataFetchType.Overwrite = DataFetchType.Overwrite
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  const pathname = window.location.pathname;

  if (pathname.includes(routes.OBJECT_CLASSES_CREATE)) return;

  dispatch(setFetchingObjectClassesFields(true));

  try {
    const { id } =
      getDynamicPathParts(routes.OBJECT_CLASSES_EDIT, pathname) || {};

    const { status, data } = await apiCall.get(
      createTableUrl(
        generatePath(OBJECT_CLASS_DETAILS_FIELDS, { id }),
        queryParams
      )
    );

    if (status === StatusCodes.OK) {
      if (fetchType === DataFetchType.Overwrite) {
        dispatch(
          setObjectClassesFields(
            data.results,
            data.total_count,
            data.filtered_count
          )
        );
      } else {
        dispatch(
          appendObjectClassesFields(
            data.results,
            data.total_count,
            data.filtered_count
          )
        );
      }
    }
  } catch (error) {
    dispatch(setFetchingObjectClassesFields(false));

    const isRemovingHimself = await store.getState()?.objectClassPermissions
      ?.isRemovingHimself;

    if (
      !(isRemovingHimself && error?.response?.status === StatusCodes.FORBIDDEN)
    ) {
      showUnhandledErrorToast(error);
    }
  }
};

export const setObjectClassesFieldsColumns = (
  columns: ColumnsMetadata[]
): SetObjectClassesFieldsColumnsAction => {
  return {
    type: SET_OBJECT_CLASSES_FIELDS_COLUMNS,
    payload: columns,
  };
};

export const setObjectClassesFieldsDetails = (
  details: OptionsResponseDetails
): SetObjectClassesFieldsDetailsAction => {
  return {
    type: SET_OBJECT_CLASSES_FIELDS_DETAILS,
    payload: details,
  };
};

export const setObjectClassesFieldsSelectedRow = (
  data: string | undefined
): SetSelectedRowAction => ({
  type: SET_OBJECT_CLASSES_FIELDS_SELECTED_ROW,
  selectedRow: data,
});

export const removeObjectClassField = (
  list: ObjectClassField[],
  total: number,
  filtered: number
): RemoveObjectClassesFieldsAction => {
  return {
    type: REMOVE_OBJECT_CLASSES_FIELDS,
    list,
    total,
    filtered,
  };
};

export const setObjectClassesFieldsInitialValues = (
  list: ObjectClassField[]
): SetObjectClassFieldsInitialValuesAction => {
  return {
    type: SET_OBJECT_CLASS_FIELDS_INITIAL_VALUES,
    list,
  };
};

export const getObjectClassFieldsColumnConfiguration = (
  id: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  try {
    const {
      status,
      data: { restrictions, details, list: { columns = [] } = {} },
    } = await apiCall.options<OptionsResponse>(
      generatePath(OBJECT_CLASS_DETAILS_FIELDS, { id })
    );

    if (status === StatusCodes.OK) {
      dispatch(setObjectClassesFieldsColumns(columns));
      dispatch(setObjectClassesFieldsDetails(details));

      if (restrictions !== undefined) {
        dispatch(setObjectClassFieldsRestrictions(restrictions));
      }
    }
  } catch {}
};

export const updateField = (
  data: Omit<ObjectClassField, 'order'>
): UpdateFieldAction => {
  return {
    type: UPDATE_FIELD,
    payload: data,
  };
};

export const addField = (data: ObjectClassField): AddFieldAction => {
  return {
    type: ADD_FIELD,
    payload: data,
  };
};

export const removeField = (
  data: ObjectClassField['id']
): DeleteFieldAction => {
  return {
    type: REMOVE_FIELD,
    payload: data,
  };
};

export const reoderFields = (
  data: ObjectClassFieldReorderData
): ReorderFieldsAction => {
  return {
    type: REORDER_FIELDS,
    payload: data,
  };
};

export const resetCustomOffsetFields = (): ResetObjectClassesFieldsCustomOffsetAction => {
  return {
    type: RESET_OBJECT_CLASSES_FIELDS_CUSTOM_OFFSET,
  };
};
