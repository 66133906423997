import { useFormikContext } from 'formik';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { ClassFieldForm } from '../types';

export const useObjectFieldFormValidation = () => {
  const {
    values: {
      options = [],
      users = [],
      user_groups = [],
      type,
      min_values: optionsMinValues,
    },
  } = useFormikContext<ClassFieldForm>();

  const checkIfSelectHasAnyOption = () => {
    if (
      [ObjectClassFieldTypes.Enum, ObjectClassFieldTypes.Set].includes(type)
    ) {
      const optionsCount = options.filter(item => !!item.trim()).length;

      return !!optionsMinValues
        ? optionsCount >= optionsMinValues
        : !!optionsCount;
    }

    return true;
  };

  const checkIfUsersOrGroupsSelected = () => {
    if (type === ObjectClassFieldTypes.User) {
      return users.length > 0 || user_groups.length > 0;
    }

    return true;
  };

  return {
    isObjectFieldFormValid:
      checkIfSelectHasAnyOption() && checkIfUsersOrGroupsSelected(),
  };
};
