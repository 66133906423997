export enum TaskFormFields {
  TaskLabel = 'label',
  TaskName = 'name',
  Prompt = 'prompt',
  Description = 'description',
  Instructions = 'instructions',
  TaskType = 'task_type',
  ResponseDataType = 'data_type',
  UiOptions = 'ui_options',
  DefaultValue = 'default_value',
  CompleteBy = 'complete_by',
  CompleteByDate = 'complete_by_date',
  CompleteByTime = 'complete_by_time_value',
  CompleteByTimeUnit = 'complete_by_time_unit',
  Reminders = 'reminders',
  Expiry = 'expiry',
  PluginConfig = 'plugin_config',
  Configuration = 'configuration',
  ExpiryTimeUnit = 'expiry_time_unit',
  ExpiryTimeValue = 'expiry_time_value',
  ExpiryTimeRelTo = 'expiry_time_rel_to',
  ExpiryDate = 'expiry_date',
  ExpiryPeriodicDay = 'expiry_periodic_day',
  ExpiryPeriodicMonth = 'expiry_periodic_month',
  ExpiryDay = 'expiry_day',
  Renewals = 'renewals',
  RenewalTimeUnit = 'renewal_time_unit',
  RenewalTimeValue = 'renewal_time_value',
  TaskCreation = 'task_creation',
  ObjectClass = 'object_class',
}
