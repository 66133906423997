import React, { useEffect, useState } from 'react';
import { MenuItem } from 'components/lib/Menu';
import { ACTIONS_MENU_ITEM_TESTID } from 'utils/testIds';
import Tooltip from 'components/lib/Tooltip';
import { menuWidth } from '../../BreadcrumbsDropdownMenu.styles';
import { useDispatch } from 'react-redux';
import { popManyStack } from 'store/actions/nestedObjectRecordsActions';
import { BreadcrumbsDropdownMenuItemProps } from './BreadcrumbsDropdownMenuItem.types';
import { useBreadcrumbsDropdownMenuItemStyles } from './BreadcrumbsDropdownMenuItem.styles';

const menuItemIdPrefix = 'menu-item-';

export const BreadcrumbsDropdownMenuItem = ({
  title,
  indexInStack,
  ...rest
}: BreadcrumbsDropdownMenuItemProps) => {
  const styles = useBreadcrumbsDropdownMenuItemStyles();
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const element = document.getElementById(`${menuItemIdPrefix}${title}`);
    if (!element) {
      return;
    }

    setIsTooltipVisible(menuWidth < element.getBoundingClientRect().width);
  }, [title]);

  const onClick = () => {
    dispatch(popManyStack(indexInStack + 1));
  };

  return (
    <MenuItem
      {...rest}
      className={styles.listItem}
      onClick={onClick}
      data-testid={`${ACTIONS_MENU_ITEM_TESTID}-${title}`}
      data-actions-item={true}
    >
      <Tooltip
        popupVisible={isTooltipVisible}
        overlay={title}
        className={styles.tooltip}
        placement={'bottom'}
      >
        <span
          id={`${menuItemIdPrefix}${title}`}
          className={styles.title}
          data-actions-item={true}
          data-text={title}
        >
          {title}
        </span>
      </Tooltip>
    </MenuItem>
  );
};
