import React, { useState } from 'react';
import SelectWidget from '../CustomSelectWidget/select';
import useCustomSelectWidget from './styles';
import useSelectStyles from 'components/lib/Select/styles';
import clsx from 'clsx';
import useFormPreviewContainer from 'hooks/useFormPrevievContainer';
import { SelectOption } from 'components/lib/Select';
import { ReactComponent as CountryFlagIcon } from 'img/illustrations/country-flag-icon.svg';
import updatedFindCountryFlagByIso3 from 'utils/functions/updatedFindCountryFlagByIos3';
import { ReactComponent as CountryPickerIcon } from 'img/icons/new-country-picker-icon.svg';
import { countries } from 'country-flags-svg';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { FormValuePrimative, Widget } from 'alx-dynamic-form';

const CustomCountrySelectWidget: Widget = ({
  onChange,
  value,
  description,
  errors,
  required,
  label,
  additionalFieldProps,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const classes = useCustomSelectWidget();
  const selectClasses = useSelectStyles({});
  const widgetClasses = useCustomWidgetsStyles({});
  const getPopupContainer = useFormPreviewContainer();
  const [searchValue, setSearchValue] = useState('');

  // hides placeholder flag when value is changed
  const onValueChange = (value: FormValuePrimative) => {
    setSearchValue('');
    onChange(value || null);
    reValidateField();
  };

  // any because option type not exported from ant
  const filterOption = (value: string, option: any) =>
    option.key?.toLowerCase()?.includes(value?.toLowerCase());

  const selectOptions = countries?.map(({ name, iso3 }) => ({
    label: name,
    value: iso3,
    flag: updatedFindCountryFlagByIso3(iso3),
  }));

  const customOptions = selectOptions?.map(({ flag, label, value }) => (
    <SelectOption key={label} value={value}>
      <div className={classes.selectOption}>
        <img src={flag} className={classes.countryFlag} alt={label} />
        {label}
      </div>
    </SelectOption>
  ));

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([
          widgetClasses.inputLabel,
          widgetClasses.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div
            className={clsx([
              selectClasses.multiselect,
              classes.selectStyles,
              classes.limitedMaxSizeMid,
            ])}
          >
            <SelectWidget
              {...{
                fieldAlias,
                getPopupContainer,
                filterOption,
                customOptions,
                disabled,
                value,
              }}
              allowClear
              showSearch
              onSearch={setSearchValue}
              onChange={onValueChange}
              onBlur={reValidateField}
              suffixIcon={
                <CountryPickerIcon className={classes.customArrowIcon} />
              }
            />
            {/* show placeholder flag only when no value is selected or when we search */}
            {(!value || searchValue.length > 0) && (
              <CountryFlagIcon className={classes.countryFlagPlaceholder} />
            )}
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomCountrySelectWidget;
