import React, { Fragment, useEffect, useMemo } from 'react';
import { Select, SelectOption } from 'components/lib/Select';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterPredicate } from 'store/actions/filtersActions';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import { PredicateSelectProps } from './PredicateSelect.types';
import {
  FILTER_PREDICATE_SELECT_TESTID,
  SELECT_PREDICATE_TESTID,
} from 'utils/testIds';
import { ADDITIONAL_PREDICATE_MAP } from './constants';
import { usePredicateSelectStyles } from './PredicateSelect.styles';
import { usePredicates } from 'hooks/usePredicates';

export const PredicateSelect = ({
  filterKey,
  allowedPredicates,
  type,
  value,
  additionalClassName,
}: PredicateSelectProps) => {
  const intl = useIntl();
  const styles = usePredicateSelectStyles();
  const dispatch = useDispatch();
  const tableName = useSelector(getCurrentTable) ?? '';
  const { predicates } = usePredicates();
  const onChangePredicate = (predicateKey: string) => {
    const { value, args, selectMultiple = false } = predicates[type][
      predicateKey
    ];
    const selectedPredicate = {
      key: value,
      args,
      selectMultiple,
    };

    dispatch(
      setFilterPredicate(tableName, filterKey, predicateKey, selectedPredicate)
    );
  };

  useEffect(() => {
    if (allowedPredicates?.length === 1 && value !== allowedPredicates[0]) {
      onChangePredicate(allowedPredicates[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedPredicates]);

  const mergedPredicates = useMemo(
    () => [
      ...allowedPredicates,
      ...(ADDITIONAL_PREDICATE_MAP?.[tableName]?.[filterKey] ?? []),
    ],
    [tableName, filterKey, allowedPredicates]
  );

  return type !== 'bool' ? (
    <Select
      onChange={onChangePredicate}
      value={value === '' ? undefined : value}
      // extrenal window don't copy class styles if elem not rendered before
      style={{ width: '100%' }}
      className={clsx([styles.wrapper, additionalClassName])}
      placeholder={intl.formatMessage({
        id: 'misc.select',
        defaultMessage: 'Select',
      })}
      disabled={allowedPredicates?.length === 1}
      data-testid={FILTER_PREDICATE_SELECT_TESTID}
    >
      {mergedPredicates?.map((predicateKey, index) => {
        const predicate = predicates[type][predicateKey];
        const negatedPredicate = predicates[type][`not_${predicateKey}`];

        return (
          !!predicate && (
            <Fragment key={predicate.value}>
              <SelectOption
                value={predicate.value}
                data-testid={`${SELECT_PREDICATE_TESTID}${predicate.value}`}
              >
                {index === allowedPredicates.length && (
                  <div className={styles.divider}></div>
                )}
                {predicate.label}
              </SelectOption>
              {negatedPredicate && (
                <SelectOption
                  value={negatedPredicate.value}
                  data-testid={`${SELECT_PREDICATE_TESTID}${negatedPredicate.value}`}
                >
                  {negatedPredicate.label}
                </SelectOption>
              )}
            </Fragment>
          )
        );
      })}
    </Select>
  ) : null;
};
