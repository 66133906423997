import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetGroupAvatars,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';

export const useClassPermissionGroupAvatars = () => {
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const selectedPermissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const groupAvatars =
    useSelector(
      getObjectClassPermissionSetGroupAvatars(selectedPermissionSetId)
    ) ?? [];

  return { groupAvatars };
};
