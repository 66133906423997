import React from 'react';
import { Select, SelectOption } from 'components/lib/Select';
import { CustomSelectProps } from './types';
import useCustomSelectWidgetStyles from './styles';
import { SELECT_OPTION_TESTID } from 'utils/testIds';
import { CloseCircleFilled } from '@ant-design/icons';

const SelectWidget = ({
  disabled,
  onChange,
  options,
  value,
  getPopupContainer,
  allowClear,
  showSearch,
  customOptions,
  filterOption,
  onSearch,
  searchValue,
  suffixIcon,
  onBlur,
  reValidateField,
  fieldAlias,
}: CustomSelectProps) => {
  const classes = useCustomSelectWidgetStyles({});

  const handleChange = (value: any) => {
    if (!onChange) return;

    onChange(value);

    if (reValidateField) reValidateField();
  };

  return (
    <div className={classes.selectContainer}>
      <Select
        onChange={handleChange}
        className={classes.selectPreview}
        data-testid={`single-select-dropdown-${fieldAlias}`}
        {...{
          getPopupContainer,
          allowClear,
          showSearch,
          filterOption,
          onSearch,
          searchValue,
          suffixIcon,
          value,
          onBlur,
          disabled,
        }}
      >
        {!!customOptions
          ? customOptions
          : options?.map(option => (
              <SelectOption
                key={option}
                value={option}
                data-testid={SELECT_OPTION_TESTID}
              >
                {option}
              </SelectOption>
            ))}
      </Select>

      {value && !disabled ? (
        <span
          className={classes.selectClearIconWrapper}
          onClick={() => handleChange(null)}
          role='button'
          tabIndex={0}
        >
          <CloseCircleFilled />
        </span>
      ) : null}
    </div>
  );
};

export default SelectWidget;
