import { Mods } from '../types';

export interface FormBuilderProps {
  schema: string;
  uischema: string;
  formName: string;
  formDescription: string;

  /**
   * When the parameters of the form builder component require ObjectClassId, it is usually
   * taken from the URL. However, in some cases, it can be passed in here from a different context.
   * Notable example: Task Template response form.
   */
  relatedObjectClassId?: number;
  isRecordSummary?: boolean;
  mods?: Mods;
  className?: string;

  onChange: (schema: string, uiSchema: string) => void;
}

export enum FormTypes {
  RecordListing = 'recordListing',
  RecordView = 'recordView',
  RecordSummary = 'recordSummary',
  TaskTemplates = 'taskTemplates',
  TaskTemplateWithRecordFields = 'taskTemplateWithRecordFields',
  LandingPage = 'landingPage',
}
