import { getColumnRatiosDefaultValue } from '../../components/SectionPreferences/components/GeneralSectionParameters/components/GeneralSectionParametersContent/utils/getColumnRatiosDefaultValue';
import { COLUMN_SIZES } from './mapColumnRatiosToColumnSize.consts';

export const mapColumnRatiosToColumnSize = (columnRatios: string) => {
  const tmpColumnSize = COLUMN_SIZES[columnRatios];

  if (!tmpColumnSize) {
    const columnsAmount = (columnRatios.match(/:/g) || []).length + 1;
    const columnRatioDefaultValue = getColumnRatiosDefaultValue(columnsAmount);

    return COLUMN_SIZES[columnRatioDefaultValue];
  }

  return tmpColumnSize;
};
