import React from 'react';
import { useIntl } from 'react-intl';
import { useManageTaskGroupAssigneesStyles } from './ManageTaskGroupAssignees.styles';
import { ButtonPrimary } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import {
  ADD_GROUP_ASSIGNEES_BTN_TESTID,
  MANAGE_GROUP_ASSIGNEES_TESTID,
} from 'utils/testIds';
import List from 'components/List';
import { AvatarItem } from 'components/lib/Avatar/types';
import GroupListElement from 'pages/UserGroups/components/Permissions/GroupListElement';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { getSortedGroupAvatars } from 'utils/functions/getSortedGroupAvatars';
import { UnassignTaskGroupAssigneeModal } from './components/UnassignTaskGroupAssigneeModal';
import { useUnassignTaskGroupAssignee } from './hooks/useUnassignTaskGroupAssignee';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import { useTaskGroupAssigneesLimit } from './hooks/useTaskGroupAssigneesLimit';
import DisabledButtonTooltip from 'components/DisabledButtonTooltip';
import clsx from 'clsx';
import { TaskGroupAssigneesLimitAlert } from './components/TaskGroupAssigneesLimitAlert';

/**
 * View for managing user groups assigned to a task. Displays a list of user groups that are
 * already assigned and allows to unassign them or assign a new ones.
 */
export const ManageTaskGroupAssignees = () => {
  const intl = useIntl();
  const styles = useManageTaskGroupAssigneesStyles();

  const { taskAssignees, setMode } = useTasksPanelContext();
  const {
    disabledBtnTooltipText,
    groupAssigneesLimit,
    isGroupsLimitReached,
  } = useTaskGroupAssigneesLimit();
  const {
    groupToUnassign,
    isUnassigning,
    cancelUnassigning,
    setGroupToUnassign,
    unassignGroupFromTask,
  } = useUnassignTaskGroupAssignee();

  const noGroupsAssignedMessage = intl.formatMessage({
    id: 'misc.noGroupsHaveBeenAssigned',
    defaultMessage: 'No user groups have been assigned',
  });

  const handleGroupItemDelete = (group: AvatarItem) => {
    const { id, userGroup = '' } = group;

    setGroupToUnassign({ id, name: userGroup });
  };

  const openAddGroupsView = () => {
    setMode(TasksPanelMode.AddAssignees);
  };

  return (
    <div
      className={styles.contentWrapper}
      data-testid={MANAGE_GROUP_ASSIGNEES_TESTID}
    >
      <DisabledButtonTooltip
        title={disabledBtnTooltipText}
        showTooltip={isGroupsLimitReached}
      >
        <ButtonPrimary
          className={clsx({ [styles.disabledBtn]: isGroupsLimitReached })}
          icon={<PlusIcon size={10} />}
          disabled={isGroupsLimitReached}
          data-testid={ADD_GROUP_ASSIGNEES_BTN_TESTID}
          onClick={openAddGroupsView}
        />
      </DisabledButtonTooltip>

      {groupAssigneesLimit && (
        <TaskGroupAssigneesLimitAlert groupsLimit={groupAssigneesLimit} />
      )}

      <List<AvatarItem>
        className={styles.groupsList}
        items={getSortedGroupAvatars(taskAssignees.userGroups)}
        emptyDataDescription={noGroupsAssignedMessage}
        renderItem={item => (
          <GroupListElement
            {...item}
            truncateTooLongNames
            onDelete={() => handleGroupItemDelete(item)}
          />
        )}
      />

      <UnassignTaskGroupAssigneeModal
        groupToUnassign={groupToUnassign}
        isLoading={isUnassigning}
        onConfirm={unassignGroupFromTask}
        onCancel={cancelUnassigning}
      />
    </div>
  );
};
