import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import React from 'react';
import { ApiUserAvatar } from 'utils/types/api/users.types';
import { SingleUserAvatarWithCounter } from './components/SingleUserAvatarWithCounter';

export const ObjectClassOwnersCell = ({ row }: CatalystTableCellProps) => {
  const { owners: { total_number: total = 0, first = {} } = {} } = row;

  if (!first) {
    return null;
  }

  const {
    first_name: firstName,
    last_name: lastName,
    id,
  } = first as ApiUserAvatar;

  return (
    <SingleUserAvatarWithCounter {...{ total, firstName, lastName, id }} />
  );
};
