import { orderBy } from 'lodash';
import { SelectUserGroupOption } from 'utils/types/selectInput.types';

/**
 * Sorts groups array alphabetically by group name.
 *
 * @param groups Array of groups to be sorted.
 *
 * @returns Alphabetically sorted groups array.
 */
export const sortGroups = (groups: SelectUserGroupOption[]) => {
  return orderBy(groups, group => group.text.toLowerCase());
};
