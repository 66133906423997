import { createUseStyles } from 'react-jss';

export const useManageAssigneesTabsStyles = createUseStyles({
  name: {
    fontWeight: '700',
    fontSize: 16,
    marginTop: '16px',
    marginLeft: '16px',
    margin: '16px',
  },
  contentWrappper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-tabs': {
      flex: 1,
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
  },
  tabs: {
    '& .ant-tabs-nav': {
      margin: 0,
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    '& .ant-tabs-tab': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
});
