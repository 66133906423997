import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext';
import { ExpandablePickerUserItemProps } from './ExpandablePickerUserItem.types';
import { MaxUsersSelectedTooltip } from '../MaxUsersSelectedTooltip';
import { UsersGroupsListItemSelectAndDeselect } from 'components/UsersAndGroupsSelection/components/UsersGroupsListItemSelectAndDeselect';
import React from 'react';
import { UsersGroupsListItemDeselectOnly } from 'components/UsersAndGroupsSelection/components/UsersGroupsListItemDeselectOnly';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { UsersAndGroupsExpandablePicker } from '../../../../UsersAndGroupsExpandablePicker';

/**
 * A single item that represents a user on the left panel of {@link UsersAndGroupsExpandablePicker} with
 * the logic to select and deselect users under various conditions.
 */
export const ExpandablePickerUserItem = ({
  item,
}: ExpandablePickerUserItemProps) => {
  const {
    selectedItems,
    availableItems,
    toggleItemSelectionState,
    isMaxUsersLimitReached,
    searchText,
    newlySelectedGroupMembers,
    markGroupMembersAsNonReselectable,
  } = useUsersAndGroupsExpandablePickerContext();

  const isNewlySelectedGroupMember = newlySelectedGroupMembers.has(item.id);

  const isSelectedWithinAvailableItems = availableItems.users.some(
    availableItem => availableItem.id === item.id
  );

  const isValidForReselectionAsGroupMember =
    isSelectedWithinAvailableItems ||
    isNewlySelectedGroupMember ||
    !markGroupMembersAsNonReselectable;

  const isAvailableForReselection =
    !item.is_deleted && isValidForReselectionAsGroupMember;

  const isItemSelected = selectedItems.users.get(item.id) !== undefined;

  return (
    <MaxUsersSelectedTooltip isItemSelected={isItemSelected}>
      {isAvailableForReselection ? (
        <UsersGroupsListItemSelectAndDeselect
          item={item}
          handleClick={toggleItemSelectionState}
          limitReached={isMaxUsersLimitReached}
          selected={isItemSelected}
          searchText={searchText}
        />
      ) : (
        <UsersGroupsListItemDeselectOnly
          item={item}
          searchText={searchText}
          onDeselect={toggleItemSelectionState}
        />
      )}
    </MaxUsersSelectedTooltip>
  );
};
