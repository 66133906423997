import { AvatarProps } from 'antd/lib/avatar';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseUserAvatarStylesProps = {
  avatarBgColor: string;
  isUserGroup: boolean;
  disablePopover: boolean;
  isDeleted?: boolean;
  isOneTimeCompletionUser: boolean;
  isExtraLarge?: boolean;
  size?: AvatarProps['size'];
};

const useUserAvatarStyles = createUseStyles((theme: ThemeState) => {
  return {
    avatar: ({
      avatarBgColor,
      size,
      isUserGroup,
      disablePopover,
      isDeleted,
      isOneTimeCompletionUser,
      isExtraLarge,
    }: UseUserAvatarStylesProps) => ({
      ...(isUserGroup ? { borderRadius: '8px 3px' } : {}),
      ...(size === 'small'
        ? {
            fontSize: 12,
            lineHeight: !isOneTimeCompletionUser ? '25px' : '24px',
            width: 25,
            height: 25,
          }
        : {}),
      ...(size === 'large'
        ? {
            fontSize: 16,
            lineHeight: !isOneTimeCompletionUser ? '35px' : '34px',
            width: 35,
            height: 35,
          }
        : {}),

      ...(!isOneTimeCompletionUser || isExtraLarge
        ? {
            background: avatarBgColor,
            backgroundColor: avatarBgColor,
            fontWeight: 600,
          }
        : {
            background: '#fff',
            border: `1px dashed ${avatarBgColor}`,
            color: avatarBgColor,
          }),
      ...(isExtraLarge
        ? {
            fontSize: 22,
            lineHeight: '51px',
            width: 51,
            height: 51,
          }
        : {}),
      cursor: !disablePopover && !isDeleted ? 'pointer' : 'initial',
      '& .ant-avatar-string': {
        pointerEvents: 'none',
        transform: 'scale(1) translateX(-50%) !important',
        // it overwrite transform from  styles because on firefox at first render uncorrectly puts smaller scale than needed
      },
    }),
    popover: {
      '& .ant-popover-arrow': {
        display: 'none',
      },
      '& .ant-popover-inner': {
        borderRadius: 5,
      },
      '& .ant-popover-inner-content': {
        padding: 10,
      },
    },
    disabledOneTimeCompletionAvatar: {
      background: '#fff !important',
      border: `1px dashed ${theme['border-color-base']} !important`,
      color: `${theme['border-color-base']} !important`,
    },
    loader: {
      overflow: 'hidden',
    },
  };
});

export default useUserAvatarStyles;
