import React, { SyntheticEvent } from 'react';
import useFilterIndicatorStyles from './styles';
import { CloseIcon } from 'components/Icon';
import Tooltip from 'components/lib/Tooltip';
import { useIntl } from 'react-intl';
import {
  FILTER_INDICATOR_CLEAR_FILTERS_TESTID,
  FILTER_INDICATOR_TESTID,
} from 'utils/testIds';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';
import { AppDispatch } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetCurrentFilters,
  setAppliedFiltersForTable,
} from 'store/actions/filtersActions';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import useFlexLayoutWindow from 'hooks/useFlexLayoutWindow';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';

export const FilterIndicator = () => {
  const intl = useIntl();
  const styles = useFilterIndicatorStyles();
  const dispatch = useDispatch<AppDispatch>();

  const currentTable = useSelector(getCurrentTable);
  const { onOpenTab: showFiltersPanel } = useFlexLayoutWindow(
    FlexLayoutWindows.FilterEditor
  );

  const resetFilters = (event: SyntheticEvent) => {
    event.stopPropagation();

    dispatch(resetCurrentFilters());
    dispatch(setAppliedFiltersForTable({ id: currentTable, value: false }));
  };

  const handleIndicatorClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.FilteredResults);

    showFiltersPanel();
  };

  return (
    <div
      className={styles.filterIndicator}
      onClick={handleIndicatorClick}
      role='button'
      tabIndex={0}
      data-testid={FILTER_INDICATOR_TESTID}
    >
      <Tooltip
        placement='top'
        title={intl.formatMessage({
          id: 'misc.openFilter',
          defaultMessage: 'Open filter',
        })}
        destroyTooltipOnHide
      >
        <span className={styles.filterIndicatorText}>
          {intl.formatMessage({
            id: 'misc.filteredResults',
            defaultMessage: 'Filtered results',
          })}
        </span>
      </Tooltip>
      <Tooltip
        placement='top'
        title={intl.formatMessage({
          id: 'misc.clearFilter',
          defaultMessage: 'Clear filter',
        })}
      >
        <div
          className={styles.iconContainer}
          onClick={resetFilters}
          role='button'
          tabIndex={0}
          data-testid={FILTER_INDICATOR_CLEAR_FILTERS_TESTID}
        >
          <CloseIcon size={13} />
        </div>
      </Tooltip>
    </div>
  );
};
