export interface CreateRecordFormProps {
  classId: string;
  isDrawer: boolean;
  onCancelClick?: VoidFunction;
  wrapperId?: string;
}

export interface ErrorModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export enum CreateRecordFormState {
  Loading,
  NotFound,
  PermissionDenied,
  PageNotFound,
  ExceededMaximumNumberOfResources,
  Form,
}
