import { isNil } from 'lodash';

export const isValueOfUsersAndGroupsType = (value: any) => {
  if (isNil(value)) {
    return false;
  }

  return (
    typeof value === 'object' &&
    (!isNil(value.users) || !isNil(value.user_groups))
  );
};
