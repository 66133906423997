import React from 'react';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import useCustomCheckboxWidgetStyles from './styles';
import { Checkbox } from 'components/lib/Checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormItem } from 'components/lib/Form';
import { Widget } from 'alx-dynamic-form';

const CustomCheckboxWidget: Widget = ({
  description,
  value,
  onChange,
  required,
  label,
  errors,
  disabled,
  reValidateField,
  fieldAlias,
}) => {
  const classes = useCustomCheckboxWidgetStyles({});

  const handleChange = (e: CheckboxChangeEvent) => {
    onChange(e.target.checked);
    reValidateField();
  };

  return (
    <FormItem
      dynamicSpacing
      validateStatus={errors?.length ? 'error' : undefined}
    >
      <StandardFieldWrapper {...{ description }}>
        <div className={classes.checkboxWrapper}>
          <Checkbox
            data-testid={`checkbox-${fieldAlias}`}
            onChange={handleChange}
            checked={value as boolean}
            {...{ disabled }}
          />
          {(!!label || required) && (
            <span className={classes.checkboxLabel}>
              {label}
              <FormLabel inline {...{ required }} />
            </span>
          )}
        </div>
      </StandardFieldWrapper>
    </FormItem>
  );
};

export default CustomCheckboxWidget;
