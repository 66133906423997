import { AvatarItem } from 'components/lib/Avatar/types';
import { StatusCodes } from 'http-status-codes';
import { generatePath } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES,
  SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS,
  SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS,
  SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF,
  SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES,
  SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUPS,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUPS_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_USER_OPTIONS_FETCHING,
  SET_OBJECT_CLASS_SELECTED_PERMISSION_SET,
} from 'store/constants/objectClassPermissions.consts';
import { RootAction, RootState } from 'store/reducers';
import { apiCall } from 'utils/api';
import {
  OBJECT_CLASS_OWNERS,
  OBJECT_CLASS_PERMISSION_SET_ASSIGNEES,
  OBJECT_CLASS_PERMISSION_SET_GROUPS,
} from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { GetResponse, OptionsResponse } from 'utils/types';
import {
  ObjectClassPermissionsOwnersOptions,
  SelectedPermissionSetColumn,
} from 'store/reducers/types/objectClassPermissions';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';
import { ObjectClassPermissionsTabs } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/types';
import { getSortedGroupAvatars } from 'utils/functions/getSortedGroupAvatars';
import { UserGroupAssignee } from 'utils/types/api/userGroups.types';
import axios, { CancelToken } from 'axios';
interface SetObjectClassPermissionsOwnersFetchingAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING> {
  payload: boolean;
}

interface SetObjectClassPermissionsClassOwnersAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS> {
  payload: {
    list: ObjectClassOwner[];
    totalCount: number;
    avatars: AvatarItem[];
  };
}

interface SetObjectClassPermissionSetAssigneesAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES> {
  payload: {
    permissionSetId: string;
    list: ObjectClassOwner[];
    totalCount: number;
    avatars: AvatarItem[];
  };
}

interface SetObjectClassPermissionSetGroupsAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_GROUPS> {
  payload: {
    permissionSetId: string;
    list: UserGroupAssignee[];
    totalCount: number;
    avatars: AvatarItem[];
  };
}

interface SetObjectClassPermissionSetAssigneesOptionsAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS> {
  payload: {
    permissionSetId: string;
    options: ObjectClassPermissionsOwnersOptions;
  };
}

interface SetObjectClassPermissionSetGroupOptionsAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS> {
  payload: {
    permissionSetId: string;
    options: ObjectClassPermissionsOwnersOptions;
  };
}

interface SetObjectClassPermissionsClassOwnersOptionsAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS> {
  payload: ObjectClassPermissionsOwnersOptions;
}
interface SetObjectClassPermissionSetAssigneesFetchingAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING> {
  payload: { permissionSetId: string; fetching: boolean };
}

interface SetObjectClassPermissionSetGroupsFetchingAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_GROUPS_FETCHING> {
  payload: { permissionSetId: string; fetching: boolean };
}

interface SetObjectClassPermissionSetUserOptionsFetchingAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSION_SET_USER_OPTIONS_FETCHING> {
  payload: { permissionSetId: string; isFetching: boolean };
}

interface SetObjectClassPermissionSetGroupOptionsFetchingAction
  extends Action<
    typeof SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS_FETCHING
  > {
  payload: { permissionSetId: string; isFetching: boolean };
}

interface SetObjectClassPermissionsClassOwnersIsRemovingHimselfAction
  extends Action<typeof SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF> {
  payload: boolean;
}

interface SetObjectClassSelectedPermissionSet
  extends Action<typeof SET_OBJECT_CLASS_SELECTED_PERMISSION_SET> {
  payload: SelectedPermissionSetColumn | undefined;
}

type ClearObjectClassPermissionSetsAssignees = Action<
  typeof CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES
>;

export type ObjectClassPermissionsActions =
  | SetObjectClassSelectedPermissionSet
  | ClearObjectClassPermissionSetsAssignees
  | SetObjectClassPermissionsClassOwnersAction
  | SetObjectClassPermissionSetAssigneesAction
  | SetObjectClassPermissionSetGroupsAction
  | SetObjectClassPermissionsOwnersFetchingAction
  | SetObjectClassPermissionSetAssigneesOptionsAction
  | SetObjectClassPermissionSetGroupOptionsAction
  | SetObjectClassPermissionsClassOwnersOptionsAction
  | SetObjectClassPermissionSetAssigneesFetchingAction
  | SetObjectClassPermissionSetGroupsFetchingAction
  | SetObjectClassPermissionSetUserOptionsFetchingAction
  | SetObjectClassPermissionSetGroupOptionsFetchingAction
  | SetObjectClassPermissionsClassOwnersIsRemovingHimselfAction;

export const setObjectClassPermissionsOwnersFetching = (
  payload: boolean
): SetObjectClassPermissionsOwnersFetchingAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING,
  payload,
});

export const setObjectClassPermissionsClassOwners = (
  list: ObjectClassOwner[],
  totalCount: number,
  avatars: AvatarItem[]
): SetObjectClassPermissionsClassOwnersAction => ({
  type: SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS,
  payload: { list, totalCount, avatars },
});

export const setObjectClassPermissionsClassOwnersOptions = (
  payload: ObjectClassPermissionsOwnersOptions
): SetObjectClassPermissionsClassOwnersOptionsAction => ({
  type: SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS,
  payload,
});

export const setObjectClassPermissionSetAssignees = (
  permissionSetId: string,
  list: ObjectClassOwner[],
  totalCount: number,
  avatars: AvatarItem[]
): SetObjectClassPermissionSetAssigneesAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES,
  payload: { permissionSetId, list, totalCount, avatars },
});

export const setObjectClassPermissionSetGroups = (
  permissionSetId: string,
  list: UserGroupAssignee[],
  totalCount: number,
  avatars: AvatarItem[]
): SetObjectClassPermissionSetGroupsAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_GROUPS,
  payload: { permissionSetId, list, totalCount, avatars },
});

export const setObjectClassPermissionSetAssigneesFetching = (
  permissionSetId: string,
  fetching: boolean
): SetObjectClassPermissionSetAssigneesFetchingAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING,
  payload: { permissionSetId, fetching },
});

export const setObjectClassPermissionSetGroupsFetching = (
  permissionSetId: string,
  fetching: boolean
): SetObjectClassPermissionSetGroupsFetchingAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_GROUPS_FETCHING,
  payload: { permissionSetId, fetching },
});

export const setObjectClassPermissionSetUserOptionsFetching = (
  permissionSetId: string,
  isFetching: boolean
): SetObjectClassPermissionSetUserOptionsFetchingAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_USER_OPTIONS_FETCHING,
  payload: { permissionSetId, isFetching },
});

export const setObjectClassPermissionSetGroupOptionsFetching = (
  permissionSetId: string,
  isFetching: boolean
): SetObjectClassPermissionSetGroupOptionsFetchingAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS_FETCHING,
  payload: { permissionSetId, isFetching },
});

export const setObjectClassPermissionSetAssigneesOptions = (
  permissionSetId: string,
  options: ObjectClassPermissionsOwnersOptions
): SetObjectClassPermissionSetAssigneesOptionsAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS,
  payload: {
    permissionSetId,
    options,
  },
});

export const setObjectClassPermissionSetGroupOptions = (
  permissionSetId: string,
  options: ObjectClassPermissionsOwnersOptions
): SetObjectClassPermissionSetGroupOptionsAction => ({
  type: SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS,
  payload: {
    permissionSetId,
    options,
  },
});

export const setObjectClassPermissionsClassOwnersIsRemovingHimself = (
  payload: boolean
): SetObjectClassPermissionsClassOwnersIsRemovingHimselfAction => ({
  type: SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF,
  payload,
});

const getSortedUserAvatars = (results: ObjectClassOwner[]) =>
  results
    .map(
      ({
        id: ownershipId,
        user: {
          id,
          first_name: firstName,
          last_name: lastName,
          company_name: company,
          username: email,
        },
      }) => ({
        id,
        ownershipId,
        firstName,
        lastName,
        company,
        email,
      })
    )
    // sort alphabetically by firstName, next by lastName [a-z]
    .sort(
      (
        { firstName, lastName },
        { firstName: secondUserFirstName, lastName: secondUserLastName }
      ) => {
        const compareFirstNames = firstName.localeCompare(secondUserFirstName);

        return compareFirstNames !== 0
          ? compareFirstNames
          : lastName.localeCompare(secondUserLastName);
      }
    );

const getAllAssignees = async <t>(
  path: string,
  resultsAcc: t[]
): Promise<{
  results: t[];
  totalCount: number;
}> => {
  const {
    status,
    data: { results, total_count, next },
  } = await apiCall.get<GetResponse<any>>(path);

  if (status !== StatusCodes.OK) {
    return {
      results,
      totalCount: results?.length ?? 0,
    };
  }

  if (!next) {
    return { results: [...resultsAcc, ...results], totalCount: total_count };
  }

  const { pathname, searchParams } = new URL(next);

  return await getAllAssignees(`${pathname}?${searchParams}`, [
    ...resultsAcc,
    ...results,
  ]);
};

export const getObjectClassPermissionsClassOwners = (
  objectClassId: string
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  dispatch(setObjectClassPermissionsOwnersFetching(true));

  try {
    const { results, totalCount } = await getAllAssignees(
      generatePath(OBJECT_CLASS_OWNERS, {
        id: objectClassId,
      }),
      []
    );

    dispatch(
      setObjectClassPermissionsClassOwners(
        results,
        totalCount,
        getSortedUserAvatars(results)
      )
    );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(setObjectClassPermissionsOwnersFetching(false));
  }
};

export const getObjectClassPermissionsOwnersConfiguration = (
  objectClassId: string
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(setObjectClassPermissionsOwnersFetching(true));

  try {
    const {
      status,
      data: { restrictions, batch },
    } = await apiCall.options<OptionsResponse>(
      generatePath(OBJECT_CLASS_OWNERS, {
        id: objectClassId,
      })
    );

    if (
      status === StatusCodes.OK &&
      restrictions !== undefined &&
      batch !== undefined
    )
      dispatch(
        setObjectClassPermissionsClassOwnersOptions({ restrictions, batch })
      );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(setObjectClassPermissionsOwnersFetching(false));
  }
};

export const setObjectClassSelectedPermissionSet = (
  payload: SelectedPermissionSetColumn | undefined
): SetObjectClassSelectedPermissionSet => ({
  type: SET_OBJECT_CLASS_SELECTED_PERMISSION_SET,
  payload,
});

export const clearObjectClassPermissionSetsAssignees = (): ClearObjectClassPermissionSetsAssignees => ({
  type: CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES,
});

export const getObjectClassPermissionSetAssignees = (
  objectClassId: string,
  permissionSetId: string,
  activeTab = ObjectClassPermissionsTabs.ClassPermissions
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  if (activeTab !== ObjectClassPermissionsTabs.ClassPermissions) return;

  dispatch(setObjectClassPermissionSetAssigneesFetching(permissionSetId, true));

  try {
    const { results, totalCount } = await getAllAssignees(
      generatePath(OBJECT_CLASS_PERMISSION_SET_ASSIGNEES, {
        objectClassId,
        permissionSetId,
      }),
      []
    );

    dispatch(
      setObjectClassPermissionSetAssignees(
        permissionSetId,
        results,
        totalCount,
        getSortedUserAvatars(results)
      )
    );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(
      setObjectClassPermissionSetAssigneesFetching(permissionSetId, false)
    );
  }
};

export const getObjectClassPermissionSetGroups = (
  objectClassId: string,
  permissionSetId: string,
  activeTab = ObjectClassPermissionsTabs.ClassPermissions
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  RootAction
> => async dispatch => {
  if (activeTab !== ObjectClassPermissionsTabs.ClassPermissions) {
    return;
  }

  dispatch(setObjectClassPermissionSetGroupsFetching(permissionSetId, true));

  try {
    const { results, totalCount } = await getAllAssignees(
      generatePath(OBJECT_CLASS_PERMISSION_SET_GROUPS, {
        objectClassId,
        permissionSetId,
      }),
      []
    );

    dispatch(
      setObjectClassPermissionSetGroups(
        permissionSetId,
        results,
        totalCount,
        getSortedGroupAvatars(results)
      )
    );
  } catch (error) {
    showUnhandledErrorToast(error);
  } finally {
    dispatch(setObjectClassPermissionSetGroupsFetching(permissionSetId, false));
  }
};

export const getObjectClassPermissionSetAssigneesConfiguration = (
  objectClassId: string,
  permissionSetId: string,
  cancelToken?: CancelToken
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(
    setObjectClassPermissionSetUserOptionsFetching(permissionSetId, true)
  );

  try {
    const {
      status,
      data: { restrictions, batch },
    } = await apiCall.options<OptionsResponse>(
      generatePath(OBJECT_CLASS_PERMISSION_SET_ASSIGNEES, {
        objectClassId,
        permissionSetId,
      }),
      { cancelToken }
    );

    if (
      status === StatusCodes.OK &&
      restrictions !== undefined &&
      batch !== undefined
    )
      dispatch(
        setObjectClassPermissionSetAssigneesOptions(permissionSetId, {
          restrictions,
          batch,
        })
      );
  } catch (error) {
    if (axios.isCancel(error)) {
      return;
    }

    showUnhandledErrorToast(error);
  } finally {
    dispatch(
      setObjectClassPermissionSetUserOptionsFetching(permissionSetId, false)
    );
  }
};

export const getObjectClassPermissionSetGroupOptions = (
  objectClassId: string,
  permissionSetId: string,
  cancelToken?: CancelToken
): ThunkAction<void, RootState, undefined, RootAction> => async dispatch => {
  dispatch(
    setObjectClassPermissionSetGroupOptionsFetching(permissionSetId, true)
  );

  try {
    const endpoint = generatePath(OBJECT_CLASS_PERMISSION_SET_GROUPS, {
      objectClassId,
      permissionSetId,
    });

    const { status, data } = await apiCall.options<OptionsResponse>(endpoint, {
      cancelToken,
    });
    const { batch, restrictions } = data;

    if (status !== StatusCodes.OK || !restrictions || !batch) {
      return;
    }

    dispatch(
      setObjectClassPermissionSetGroupOptions(permissionSetId, {
        restrictions,
        batch,
      })
    );
  } catch (error) {
    if (axios.isCancel(error)) {
      return;
    }

    showUnhandledErrorToast(error);
  } finally {
    dispatch(
      setObjectClassPermissionSetGroupOptionsFetching(permissionSetId, false)
    );
  }
};
