import React from 'react';
import { toast } from 'react-toastify';

import { UnhandledErrorToastContent } from '../components/ToastContent/views/UnhandledErrorToastContent';

/**
 * Shows an unhandled error toast. Contains generic "something went wrong" message. It should be
 * used when an error occurs that is not handled in any way.
 */
export const showUnhandledErrorToast = (error: unknown) => {
  toast.error(
    props => (
      <UnhandledErrorToastContent error={error} toastProps={props.toastProps} />
    ),
    {
      autoClose: 5000,
    }
  );
};
