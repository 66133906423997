import { COLUMN_TO_DISPLAY_AS_FIRST } from '../../../AddColumn.consts';
import { ColumnGroup } from '../types/columnGroup';

export const sortColumnGroups = (a: ColumnGroup, b: ColumnGroup) => {
  if (a.value === COLUMN_TO_DISPLAY_AS_FIRST) return -1;

  return b.value === COLUMN_TO_DISPLAY_AS_FIRST
    ? 1
    : a.label.localeCompare(b.label);
};
