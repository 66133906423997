import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetUserOptions,
  getObjectClassPermissionSetUserOptionsFetching,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';

export const useManageClassPermissionUsersLoader = () => {
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const selectedPermissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const options = useSelector(
    getObjectClassPermissionSetUserOptions(selectedPermissionSetId)
  );
  const areOptionsLoading = useSelector(
    getObjectClassPermissionSetUserOptionsFetching(selectedPermissionSetId)
  );

  const showLoader = areOptionsLoading && !options;

  return { showLoader };
};
