import { RootState } from 'store/reducers';
import { AssigneesType } from 'store/reducers/types/objectClassPermissions';
import { ObjectClassOwner } from 'utils/types/api/objectClasses.types';

export const getObjectClassPermissionsIsRemovingHimself = (state: RootState) =>
  state.objectClassPermissions.isRemovingHimself;

export const getObjectClassPermissionsClassOwnersCount = (state: RootState) =>
  state.objectClassPermissions.classPermissions.owners.totalCount;

export const getObjectClassPermissionsClassOwnersAvatars = (state: RootState) =>
  state.objectClassPermissions.classPermissions.owners.avatars;

export const getObjectClassPermissionSetAssigneesData = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId] ??
  ({} as AssigneesType<ObjectClassOwner>);

export const getObjectClassPermissionSetGroupsData = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.groups[permissionSetId] ?? {};

export const getObjectClassPermissionSetAssigneesAvatars = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.avatars ?? [];

export const getObjectClassPermissionSetGroupAvatars = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.groups[permissionSetId]
    ?.avatars ?? [];

export const getObjectClassPermissionSetAssigneesFetching = (
  permissionSetId?: string
) => (state: RootState) =>
  !permissionSetId
    ? false
    : state.objectClassPermissions.classPermissions.assignees[permissionSetId]
        ?.fetching;

export const getObjectClassPermissionSetGroupsFetching = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.fetching;

export const getObjectClassPermissionSetUserOptionsFetching = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.areOptionsFetching ?? false;

export const getObjectClassPermissionSetGroupOptionsFetching = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.groups[permissionSetId]
    ?.areOptionsFetching ?? false;

export const getObjectClassPermissionSetUserOptions = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.options;

export const getObjectClassPermissionSetGroupOptions = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.groups[permissionSetId]
    ?.options;

export const getObjectClassPermissionsClassOwnersItemsLimit = (
  state: RootState
) =>
  state.objectClassPermissions.classPermissions.owners.options.restrictions
    .limit_items;

export const getObjectClassPermissionSetAssigneesItemsLimit = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.options?.restrictions?.limit_items;

export const getObjectClassPermissionSetGroupsLimit = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.groups[permissionSetId]?.options
    ?.restrictions?.limit_items;

export const getObjectClassPermissionSetAssignedUsersCount = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.assignees[permissionSetId]
    ?.totalCount;

export const getObjectClassPermissionSetGroupsCount = (
  permissionSetId: string
) => (state: RootState) =>
  state.objectClassPermissions.classPermissions.groups[permissionSetId]
    ?.totalCount;

export const getObjectClassPermissionsClassOwnersAutocompleteUrl = (
  state: RootState
) =>
  state.objectClassPermissions.classPermissions.owners.options.batch
    .autocomplete;

export const getObjectClassSelectedPermissionSet = (state: RootState) =>
  state.objectClassPermissions.selectedPermissionSet;

export const getObjectClassOwnersFetching = (state: RootState) =>
  state.objectClassPermissions.classPermissions.owners.fetching;
