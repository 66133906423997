import React from 'react';
import { LimitAlertProps } from './LimitAlert.types';
import { getLimitAlertType } from './utils/getLimitAlertType';
import { getLimitAlertMessage } from './utils/getLimitAlertMessage';
import { AlertType } from './types/alertType';
import { Alert } from 'components/lib/Alert';

export const LimitAlert = ({
  itemsCount,
  itemsLimit,
  warningMessage,
  errorMessage,
}: LimitAlertProps) => {
  const type = getLimitAlertType(itemsCount, itemsLimit);

  /**
   * Type can be None only if items count is not close enough to the limit. In this situation
   * alert shouldn't be shown.
   */
  if (type === AlertType.None) {
    return <></>;
  }

  return (
    <Alert
      type={type}
      message={getLimitAlertMessage(type, errorMessage, warningMessage)}
    />
  );
};
