import React from 'react';
import { TaskFormNameFieldProps } from './TaskFormNameField.types';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/Inputs/TextInput';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';

export const TaskFormNameField = ({
  isRequired,
  maxLength,
  minLength,
  className,
}: TaskFormNameFieldProps) => {
  return (
    <div className={className}>
      <FormLabel bolded={false} required={isRequired}>
        <FormattedMessage
          id='tasksPanel.form.taskName'
          defaultMessage='Task name'
        />
      </FormLabel>

      <TextInput
        name={TaskFormField.Name}
        minLength={minLength}
        maxLength={maxLength}
      />
    </div>
  );
};
