export const validationObject = {
  email: {
    type: 'email',
    validators: [
      {
        type: 'max_length',
        length: 100,
      },
    ],
  },
};
