import { ObjectRecordDetailsMeta } from 'utils/types/api/objectRecords.types';
import {
  SelectUserOption,
  SelectUserGroupOption,
  SelectUserAndGroupOption,
} from 'utils/types/selectInput.types';
import { buildOptionListFromOptionsAndRecordMetadata } from './buildOptionListFromOptionsAndRecordMetadata';

/**
 * InPlaceEdit has only information about selected users and groups IDs. To be able to provide information about users and groups
 * in the picker, we need to filter out potential options from the metadata or fetched options.
 */
export const convertIdsToUserAndGroupOptions = (
  userIds: number[],
  groupIds: number[],
  options: SelectUserAndGroupOption,
  objectRecordMetadata: ObjectRecordDetailsMeta | undefined
) => {
  const filterUsersById = (userIds: number[], userData: SelectUserOption[]) => {
    return userData.filter(user => userIds.includes(user.id));
  };

  const filterGroupsById = (
    groupIds: number[],
    groupData: SelectUserGroupOption[]
  ) => {
    return groupData.filter(group => groupIds.includes(group.id));
  };

  const {
    users: userOptionsToUse,
    groups: groupOptionsToUse,
  } = buildOptionListFromOptionsAndRecordMetadata(
    options,
    objectRecordMetadata
  );

  const users = filterUsersById(userIds ?? [], userOptionsToUse);
  const groups = filterGroupsById(groupIds ?? [], groupOptionsToUse);

  return { users, groups };
};
