import useData from 'hooks/useData';
import { TASK_USER_ASSIGNEES } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';
import { TaskUserAssigneeOptions } from 'components/SidePanels/TasksPanel/types';

export const useTaskUserAssigneeOptions = () => {
  const [
    options,
    { loading: areOptionsLoading, error: optionsError },
  ] = useData<TaskUserAssigneeOptions>(
    generatePath(TASK_USER_ASSIGNEES, { id: 0 }),
    {
      requestType: 'options',
    }
  );

  return { options, areOptionsLoading, optionsError };
};
