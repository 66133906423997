import {
  AutocompleteSelectValue,
  SelectUserAndGroupOption,
  SelectUserOption,
} from './selectInput.types';

export type TPredicateTypes =
  | 'string'
  | 'int'
  | 'datetime'
  | 'date'
  | 'enum'
  | 'bool'
  | 'set'
  | 'user'
  | 'phone'
  | 'url'
  | 'float'
  | 'email'
  | 'json'
  | 'user_and_group';

export type TPredicateInputTypes =
  | TPredicateTypes
  | 'dateRange'
  | 'dateTimeRange';

export type TPredicateValue =
  | string
  | number
  | string[]
  | number[]
  | AutocompleteSelectValue
  | AutocompleteSelectValue[]
  | SelectUserOption
  | SelectUserOption[]
  | SelectUserAndGroupOption;

export enum PredicateSet {
  ClassFields = 'classFields',
  RecordProperties = 'recordProperties',
}

export type Predicate = {
  label: string;
  value: string;
  query: string;
  args: string[];
  selectMultiple?: boolean;
};

export interface SelectedPredicate extends Pick<Predicate, 'args'> {
  key: string;
  selectMultiple?: boolean;
}
