import Tooltip from 'components/lib/Tooltip';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { MaxUsersSelectedTooltipProps } from './MaxUsersSelectedTooltip.types';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext';
import { useTooltipVisibility } from '../../../../../../../../../hooks/useTooltipVisibility';
import { USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContextProvider.consts';

export const MaxUsersSelectedTooltip = ({
  children,
  isItemSelected,
}: MaxUsersSelectedTooltipProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const {
    isMaxUsersLimitReached,
    limits,
  } = useUsersAndGroupsExpandablePickerContext();

  const shouldAllowTooltipDisplay = isMaxUsersLimitReached && !isItemSelected;

  const { isTooltipVisible, setIsTooltipVisible } = useTooltipVisibility(
    elementRef
  );

  return (
    <Tooltip
      align={{ offset: [0, 3] }}
      visible={isTooltipVisible}
      trigger={'hover'}
      mouseLeaveDelay={0}
      onVisibleChange={visible =>
        setIsTooltipVisible(visible && shouldAllowTooltipDisplay)
      }
      overlay={
        <FormattedMessage
          id='misc.usersLimitReached'
          defaultMessage='The maximum number of users ({ itemsLimit }) have been added.'
          values={{
            itemsLimit:
              limits.selectionCountLimits?.maxUsers ??
              USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION,
          }}
        />
      }
    >
      <div ref={elementRef}>{children}</div>
    </Tooltip>
  );
};
