import { AvatarItem } from 'components/lib/Avatar/types';
import { AccountTypeOptions } from 'pages/Users/enums';
import { ReactNode } from 'react';
import { RootState } from 'store/reducers';
import { SelectedPermissionSetColumn } from 'store/reducers/types/userGroupPermissions.types';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { ErrorCodes } from 'utils/types/errorResponse';

export enum OwnersMode {
  Manage = 'manage',
  Adding = 'adding',
}

interface ClassPermissionsOwnersPanelData {
  selectedUsers: AvatarItem[];
  mode: OwnersMode;
}

export type SidebarData = MappedObject<
  ClassPermissionsOwnersPanelData,
  FlexLayoutWindows
>;

export enum AddUsersPanelType {
  ObjectClassOwners = 'objectClassOwners',
  PermissionSetAssignees = 'permissionSetAssignees',
}

export interface CustomErrorMessage {
  errorCode: ErrorCodes;
  count: number;
  itemsLimit?: number;
}
export interface AddUsersPanelProps {
  panelKey: FlexLayoutWindows;
  postApiEndpoint: string;
  avatars: AvatarItem[];
  usersCount: number;
  itemsLimit: number | undefined;
  fetchOwners?: () => Promise<unknown>;
  shouldFetchOnSuccess?: boolean;
  headerTitle?: string;
  autocompleteUrl?: string;
  panelType?: AddUsersPanelType;
  panelTitle?: string;
  intlLimitMessage?: string;
  onCancelClick?: () => void;
  postDataKey?: string;
  checkCustomCondition?: (
    id: number,
    accountType: AccountTypeOptions,
    selectedUsers: AvatarItem[]
  ) => boolean;
  customSuccessMessage?: (count: number, users?: AvatarItem[]) => string;
  customErrorMessage?: (
    params: CustomErrorMessage
  ) => { title: string; subtitle?: string } | undefined;
  defaultMode: string;
  onSubmit?: (selected: AvatarItem[]) => void;
  alreadyAddedDisabledMessage?: string;
  getSelectedPermissionSet?: (
    state: RootState
  ) => SelectedPermissionSetColumn | undefined;
}

export type LimitExceededNotificationProps = {
  itemsCount: number;
  customMessage?: string | ReactNode;
  itemsLimit: number | undefined;
};
