import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFilterMultiSelectValueStyles = createUseStyles(
  (theme: ThemeState) => ({
    multiSelectWrapper: {
      position: 'relative',
    },
    searchIcon: {
      color: theme['primary-color'],
    },
  })
);
