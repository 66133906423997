import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

const useFlexLayoutStyles = createUseStyles((theme: ThemeState) => {
  return {
    flexLayoutWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
    },
    reattachButton: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      fontSize: 25,
      cursor: 'fixed',
    },
    addCircle: {
      position: 'fixed',
      bottom: 65,
      right: 25,
      fontSize: 40,
      cursor: 'pointer',
      color: theme['primary-color'],
      '&:hover': {
        color: theme['primary-color-medium'],
      },
    },
    childrenWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'initial',
    },
    wrapper: { position: 'relative' },
    plusIcon: { marginRight: 5 },
    tabNameWrapper: {
      padding: [14, 10],
      paddingRight: 0,
      fontFamily: 'Open Sans',
    },
    iconContainer: {
      width: 25,
      height: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
      transition: 'background 0.3s ease-in-out',
      '& svg': {
        color: theme['text-color'],
      },
      '& span': {
        transition: 'color 0.3s ease-in-out',
      },
      '&:hover': {
        backgroundColor: theme['primary-color-light'],
        color: theme['primary-color'],
        '& span': {
          color: theme['primary-color'],
        },
      },
      '&:focus': {
        outline: `1px dotted ${theme['primary-color-light']}`,
      },
    },
    closeWrapper: {
      width: 16,
      height: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      borderRadius: 2,
      marginTop: 1,
      '& svg': {
        color: theme['placeholder-text-color'],
      },
      '&:hover': {
        backgroundColor: theme['primary-color-light'],
        '& svg': {
          color: theme['primary-color'],
        },
      },
    },
    drawerButton: {
      borderColor: theme['placeholder-border-color'],
      color: theme['secondary-color'],
      '&:hover, &:active, &:focus': {
        borderColor: theme['placeholder-border-color'],
        color: theme['secondary-color'],
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      marginRight: 12,
    },
  };
});

export default useFlexLayoutStyles;
