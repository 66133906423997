import { TasksQuickFilterDatesTypes } from 'components/CatalystTable/components/Table/components/PaginationWrapper/components/TasksQuickFilters/components/TasksQuickFilterDates/hooks/useTasksQuickFiltersDates/types/tasksQuickFilterDatesTypes';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { ColumnsMetadata } from 'utils/types/api/table.types';

const { Status } = TasksQuickFilterDatesTypes;
const { intl } = GlobalIntlSingleton;

export const addStatusOverduePredicate = (
  columns: MappedObject<ColumnsMetadata, string>
) => {
  return [
    {
      column: columns[Status].alias,
      type: columns[Status].type,
      predicates: columns[Status].predicates || [],
      label: intl.formatMessage({
        id: 'tasksPanel.overdue',
      }),
      value: {
        predicateKey: 'exact',
        predicateValues: {
          value: 'overdue',
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};
