import { useRefetchResourceContext } from 'contexts/RefetchResourceContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { RefetchResources } from 'contexts/types';
import useCheckAccessToSelectedObjectClass from 'hooks/useCheckAccessToSelectedObjectClass';
import useCloseRecordPanels from 'hooks/useCloseRecordPanels';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { getPermissions } from 'store/actions/permissionsActions';
import { updatePreferences } from 'store/actions/preferencesActions';
import { AppDispatch } from 'store/store';
import routes from 'utils/routingPaths';
import { PreferencesTypes } from 'utils/types/api/preferences.types';

export const useRecordNotFoundOnTaskCreation = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const { refetchData } = useRefetchResourceContext();
  const { closeRecordPanels } = useCloseRecordPanels();
  const {
    checkAccessToSelectedObjectClassId,
  } = useCheckAccessToSelectedObjectClass();
  const {
    selectedResource: { record: { identifier = undefined } = {} } = {},
  } = useSelectedResourceContext();

  const modalTitle = intl.formatMessage(
    {
      id: 'misc.recordDeleted',
      defaultMessage: '{name} has been deleted.',
    },
    {
      name: identifier,
    }
  );
  const modalSubtitle = intl.formatMessage({
    id: 'tasksPanel.taskCanNoLongerBeCreated',
    defaultMessage: 'The task can no longer be created.',
  });

  const onModalClose = useCallback(async () => {
    const isRecordDetailsPage = !!matchPath(history.location.pathname, {
      exact: true,
      path: routes.RECORD,
    });

    await dispatch(
      getPermissions(async ({ object_records: objectRecords }) => {
        closeRecordPanels(!objectRecords?.list);

        if (!objectRecords?.list) {
          history.push(routes.WORKSPACE);
          return;
        }

        if (isRecordDetailsPage) {
          history.push(routes.RECORDS);
          return;
        }

        refetchData(RefetchResources.Records);

        const hasStillAccessToObjectClass = await checkAccessToSelectedObjectClassId();

        if (!hasStillAccessToObjectClass) {
          dispatch(
            updatePreferences(PreferencesTypes.GeneralPreferences, {
              selectedClassId: undefined,
            })
          );
        }
      })
    );
  }, [
    history,
    checkAccessToSelectedObjectClassId,
    closeRecordPanels,
    dispatch,
    refetchData,
  ]);

  return { modalTitle, modalSubtitle, onModalClose };
};
