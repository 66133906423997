import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetAssignedUsersCount,
  getObjectClassPermissionSetAssigneesItemsLimit,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';

export const useClassPermissionUsersLimit = () => {
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const permissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const userAssigneesLimit = useSelector(
    getObjectClassPermissionSetAssigneesItemsLimit(permissionSetId)
  );
  const userAssigneesCount =
    useSelector(
      getObjectClassPermissionSetAssignedUsersCount(permissionSetId)
    ) ?? 0;

  const isUsersLimitExceeded = userAssigneesLimit
    ? userAssigneesCount >= userAssigneesLimit
    : false;

  return {
    isUsersLimitExceeded,
    userAssigneesCount,
    userAssigneesLimit,
  };
};
