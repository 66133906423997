import React, { useCallback, useState } from 'react';
import { ButtonPrimary } from 'components/lib/Button';
import { useLoginForm } from '../../hooks';
import { FormattedMessage } from 'react-intl';
import useLoginFormStyles from './styles';
import FormLabel from '../../../TaskTemplates/components/FormLabel';
import { Row } from '../../../../components/lib/Grid';
import { Checkbox } from '../../../../components/lib/Checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  BUTTON_SUBMIT_TESTID,
  LOGIN_EMAIL_TESTID,
  LOGIN_GO_TO_RESET_TESTID,
  LOGIN_PASSWORD_TESTID,
  SHOW_PASSWORD_TESTID,
} from 'utils/testIds';
import { Select } from '../../../../components/lib/Select';
import clsx from 'clsx';
import { LoginFormProps } from './types';
import { LoginErrorType } from 'pages/Login/types';

import { Form, Formik } from 'formik';
import TextInput from 'components/Inputs/TextInput';
import PasswordInput from 'components/Inputs/PasswordInput';

const LoginForm: React.FC<LoginFormProps> = ({ providers }) => {
  const {
    onFinish,
    isLoading,
    navigateToReset,
    onChangeProvider,
    errorMessage,
    error,
    validationSchema,
  } = useLoginForm();
  const [showPassword, setShowPassword] = useState(false);
  const styles = useLoginFormStyles();

  const togglePassword = useCallback(
    ({ target: { checked } }: CheckboxChangeEvent) => {
      setShowPassword(checked);
    },
    [setShowPassword]
  );

  return (
    <Formik onSubmit={onFinish} initialValues={{}} {...{ validationSchema }}>
      {({ setTouched, touched, isValid }) => (
        <Form>
          <div className={styles.wrapper}>
            {error && <p className={styles.error}>{errorMessage}</p>}
            {!!providers?.length && (
              <>
                <div className={styles.selectWrapper}>
                  <FormLabel>
                    <FormattedMessage
                      id='user.singleSignOn'
                      defaultMessage='Single sign-on'
                    />
                  </FormLabel>
                  <Select
                    className={clsx({
                      [styles.errorSelect]: error === LoginErrorType.SSO,
                    })}
                    options={providers}
                    onChange={onChangeProvider}
                  />
                </div>
                <div className={styles.dividerWrapper}>
                  <span className={styles.divider} />
                  <p className={styles.dividerText}>
                    <FormattedMessage
                      id='user.orLogInWith'
                      defaultMessage='Or log in with'
                    />
                  </p>
                  <span className={styles.divider} />
                </div>
              </>
            )}
            <FormLabel>
              <FormattedMessage
                id='activateAccount.username'
                defaultMessage='Username'
              />
            </FormLabel>
            <TextInput
              name='username'
              disabled={isLoading}
              data-testid={LOGIN_EMAIL_TESTID}
              className={clsx({
                [styles.errorInput]: error === LoginErrorType.Credentials,
              })}
            />
            <span className={styles.description}>
              <FormattedMessage
                id='users.usernameHint'
                defaultMessage='This must be valid email'
              />
            </span>
            <FormLabel>
              <FormattedMessage id='user.password' defaultMessage='Password' />
            </FormLabel>
            {showPassword ? (
              <TextInput
                name='password'
                className={clsx({
                  [styles.errorInput]: error === LoginErrorType.Credentials,
                })}
                type='password'
                disabled={isLoading}
                data-testid={LOGIN_PASSWORD_TESTID}
              />
            ) : (
              <PasswordInput
                name='password'
                className={clsx({
                  [styles.errorInput]: error === LoginErrorType.Credentials,
                })}
                type='password'
                visibilityToggle={false}
                disabled={isLoading}
                data-testid={LOGIN_PASSWORD_TESTID}
              />
            )}
            <Checkbox
              checked={showPassword}
              onChange={togglePassword}
              data-testid={SHOW_PASSWORD_TESTID}
            >
              <FormattedMessage
                id='user.showPassword'
                defaultMessage='Show password'
              />
            </Checkbox>
            <Row justify='center' className={styles.buttonWrapper}>
              <button
                className={styles.link}
                type='button'
                onClick={navigateToReset}
                data-testid={LOGIN_GO_TO_RESET_TESTID}
              >
                <FormattedMessage
                  id='user.forgotPassword'
                  defaultMessage='Forgot your password?'
                />
              </button>
              <ButtonPrimary
                className='d-block m-l-a'
                htmlType='submit'
                onClick={() => {
                  // without setting touched state fields do not show errors
                  setTimeout(() =>
                    setTouched({ username: true, password: true })
                  );
                }}
                disabled={Object.keys(touched).length !== 0 && !isValid}
                loading={isLoading}
                data-testid={BUTTON_SUBMIT_TESTID}
              >
                <FormattedMessage id='misc.login' defaultMessage='Log in' />
              </ButtonPrimary>
            </Row>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
