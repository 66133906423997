import React from 'react';
import { useIntl } from 'react-intl';
import Tooltip from 'components/lib/Tooltip';
import ToolButton from 'components/ToolButton';
import { CardsViewIcon, ListViewIcon } from 'components/Icon';
import { useTableViewTypeContext } from 'contexts/TableViewTypeContext';
import { SWITCH_VIEW_BUTTON_TESTID } from 'utils/testIds';
import GTag from 'providers/IntlProviderWrapper/gTag';
import { GTagEventButtonName } from 'providers/IntlProviderWrapper/gTag/types';

export const ViewTypeButton = () => {
  const intl = useIntl();
  const { isCardsView, toggle } = useTableViewTypeContext();

  const onClick = () => {
    GTag.pushGtagButtonEvent(GTagEventButtonName.SwitchView);
    toggle();
  };

  return isCardsView !== undefined ? (
    <Tooltip
      title={
        isCardsView
          ? intl.formatMessage({
              id: 'misc.switchToListView',
              defaultMessage: 'Switch to list view',
            })
          : intl.formatMessage({
              id: 'misc.switchToCardView',
              defaultMessage: 'Switch to card view',
            })
      }
    >
      <ToolButton
        data-testid={SWITCH_VIEW_BUTTON_TESTID}
        icon={
          isCardsView ? <ListViewIcon size={16} /> : <CardsViewIcon size={16} />
        }
        {...{ onClick }}
      />
    </Tooltip>
  ) : null;
};
