import React from 'react';
import { useTasksPanelContext } from '../../contexts/TasksPanelContext';
import { UsersOrGroupsTabKey } from 'utils/Enums/UsersOrGroupsTabKey';
import { AddTaskUserAssignees } from './components/AddTaskUserAssignees';
import { AddTaskGroupAssignees } from './components/AddTaskGroupAssignees';

export const AddTaskAssignees = () => {
  const { activeTab } = useTasksPanelContext();

  switch (activeTab) {
    case UsersOrGroupsTabKey.Users:
      return <AddTaskUserAssignees />;
    case UsersOrGroupsTabKey.Groups:
      return <AddTaskGroupAssignees />;
    default:
      return null;
  }
};
