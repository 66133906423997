import React from 'react';
import InPlaceInputAfter from 'components/InPlaceEditControls/components/InPlaceInputAfter';
import { useUsersAndGroupsPickerRejectButtonStyles } from './UsersAndGroupsPickerRejectButton.styles';
import { UsersAndGroupsPickerRejectButtonProps } from './UsersAndGroupsPickerRejectButton.types';
import { useIntl } from 'react-intl';
import { USERS_AND_GROUPS_SELECT_EDIT_MODE_REJECT_BUTTON_TESTID } from 'utils/testIds';

export const UsersAndGroupsPickerRejectButton = ({
  onClick,
  isSaving,
}: UsersAndGroupsPickerRejectButtonProps) => {
  const styles = useUsersAndGroupsPickerRejectButtonStyles();
  const intl = useIntl();

  const tooltip = intl.formatMessage({
    id: 'misc.discardChanges',
    defaultMessage: 'Discard changes',
  });

  return (
    <div
      data-testid={USERS_AND_GROUPS_SELECT_EDIT_MODE_REJECT_BUTTON_TESTID}
      className={styles.editModeButton}
    >
      <InPlaceInputAfter
        isLoading={isSaving}
        onCloseClick={onClick}
        tooltip={tooltip}
      />
    </div>
  );
};
