import { CatalystTableType } from '../components/CatalystTable/types/catalystTableType';

export default {
  [CatalystTableType.TaskTemplates]: [
    'id',
    'name',
    'task_creation',
    'task_type',
    'description',
    'num_of_responses',
    'tags',
    'actions',
  ],
  [CatalystTableType.ObjectRecords]: ['id', 'created_at', 'actions'],
  [CatalystTableType.TaskGroups]: [
    'id',
    'name',
    'num_of_task_templates',
    'created_at',
  ],
  [CatalystTableType.Sequences]: [
    'id',
    'name',
    'object_class',
    'task_group_templates',
    'state',
    'actions',
  ],
  [CatalystTableType.ObjectClasses]: ['id', 'name', 'created_at'],
  [CatalystTableType.ClassForms]: ['name', 'uuid', 'is_enabled'],
  [CatalystTableType.ObjectClassesFields]: [
    'label',
    'type',
    'alias',
    'is_required',
    'is_unique',
    'actions',
  ],
  [CatalystTableType.UserGroups]: ['id', 'name', 'description', 'actions'],
  [CatalystTableType.SingleSignOn]: [
    'name',
    'provider',
    'is_enabled',
    'auto_create_user',
    'actions',
  ],
  [CatalystTableType.Users]: [
    'id',
    'username',
    'account_type',
    'roles',
    'is_available',
    'actions',
  ],
  [CatalystTableType.Roles]: [
    'id',
    'name',
    'description',
    'object_classes',
    'actions',
  ],
  [CatalystTableType.Tasks]: ['id', 'name', 'status', 'actions'],
  [CatalystTableType.Forms]: [
    'id',
    'name',
    'object_class',
    'created_by',
    'created_at',
  ],
  [CatalystTableType.MessageTemplates]: [
    'id',
    'name',
    'type',
    'description',
    'created_by',
    'created_at',
    'actions',
  ],
  [CatalystTableType.EmailCollectors]: [
    'id',
    'name',
    'object_classes',
    'is_active',
  ],
  [CatalystTableType.AuthenticationObjects]: [
    'id',
    'name',
    'provider',
    'description',
  ],
  [CatalystTableType.Members]: ['name', 'username', 'membership', 'added_at'],
  [CatalystTableType.DocumentTemplates]: ['id', 'name', 'template_file_name'],
  [CatalystTableType.DocumentTemplatesPanel]: [
    'id',
    'name',
    'generated_document',
  ],
  [CatalystTableType.NestedTable]: ['id', 'actions'],
  [CatalystTableType.CustomComponents]: [
    'id',
    'name',
    'publisher',
    'is_enabled',
    'status',
  ],
};
