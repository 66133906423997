import { ColumnResizingState } from './composeResizingColumnWidths.types';

export const composeResizingColumnWidths = (
  columnResizing: ColumnResizingState,
  initialColumnResizing: ColumnResizingState | undefined
) => ({
  ...columnResizing,
  columnWidths: {
    ...initialColumnResizing?.columnWidths,
    ...columnResizing.columnWidths,
  },
});
