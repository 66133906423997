import ConfirmationModal from 'components/ConfirmationModal';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ErrorIcon from 'img/illustrations/error.svg';
import { FulfillmentValidationErrorModalProps } from './FulfillmentValidationErrorModal.types';

export const FulfillmentValidationErrorModal = ({
  isOpen,
  closeModal,
}: FulfillmentValidationErrorModalProps) => {
  const intl = useIntl();

  const errorsConfirmationProps = useMemo(() => {
    return {
      title: intl.formatMessage({
        id: 'objectRecords.form.fulfillmentValidationFailedTitle',
        defaultMessage: 'Record cannot be created',
      }),
      subtitle: intl.formatMessage({
        id: 'objectRecords.form.fulfillmentValidationFailedContent',
        defaultMessage:
          'At least one user-type field does not have enough options available. Please contact the System Administrator to review field configurations.',
      }),
      confirmationButtonLabel: intl.formatMessage({
        id: 'misc.ok',
        defaultMessage: 'Ok',
      }),
      image: ErrorIcon,
    };
  }, [intl]);

  return (
    <ConfirmationModal
      visible={isOpen}
      onConfirm={closeModal}
      title={errorsConfirmationProps.title}
      subtitle={errorsConfirmationProps.subtitle}
      confirmationButtonLabel={errorsConfirmationProps.confirmationButtonLabel}
      image={errorsConfirmationProps.image}
    />
  );
};
