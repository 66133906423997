import { useIntl } from 'react-intl';
import { FORM_BUILDER_SUPPORTED_SCHEMA_VERSION } from 'utils/consts';
import { DEFAULT_SPACING_AROUND } from '../../consts/defaultSpacingAround';
import { DEFAULT_SPACING_WITHIN } from '../../consts/defaultSpacingWithin';

// default schemas containing one empty section
// schemas are created inside hook to properly get user language from preferences

const useDefaultSchema = () => {
  const intl = useIntl();

  const DEFAULT_INITIAL_SCHEMA = {
    // "deps" is not standard property of jsonSchema, this if for custom implementation of dependency system
    deps: {},
    // "dependencies" is for original dependency system but it is not used
    dependencies: {},
    description: '',
    title: '',
    enabled: false,
    properties: {
      new_section_1: {
        title: `${intl.formatMessage({
          id: 'formBuilder.newSection',
          defaultMessage: 'New section',
        })} 1`,
        dependencies: {},
        properties: {},
        required: [],
        read_only: [],
        type: 'object',
      },
    },
    required: [],
    schemaVersion: FORM_BUILDER_SUPPORTED_SCHEMA_VERSION,
    type: 'object',
  };

  const DEFAULT_INITIAL_UI_SCHEMA = {
    new_section_1: {
      'ui:order': ['*'],
      'ui:columns': [{ components: [] }],
      'ui:spacingAround': DEFAULT_SPACING_AROUND,
      'ui:spacingWithin': DEFAULT_SPACING_WITHIN,
    },
    'ui:order': ['*'],
    'ui:columns': [{ components: ['new_section_1'] }],
    'ui:spacingAround': DEFAULT_SPACING_AROUND,
    'ui:spacingWithin': DEFAULT_SPACING_WITHIN,
  };

  return { DEFAULT_INITIAL_SCHEMA, DEFAULT_INITIAL_UI_SCHEMA };
};

export default useDefaultSchema;
