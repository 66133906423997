import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader } from 'components/lib/Loader';
import { SIDE_PANEL } from 'utils/elementsIds';
import EmptyData from 'components/EmptyData';
import { ReactComponent as EmptyBox } from 'img/illustrations/empty-box.svg';
import EventFilters from './components/EventFilters';
import { EventsList } from './components/EventsList/EventsList';
import useActivityLogStyles from './styles';
import { useRecordHistory } from './hooks';
import TooltipString from 'components/TooltipString';
import ErrorComponent from 'components/ErrorComponent';

const ActivityLogPanel = () => {
  const classes = useActivityLogStyles({});
  const {
    identifier,
    events,
    loading,
    error,
    hasMorePages,
    fetchNextPage,
    queryParams,
    setQueryParams,
    refreshDataSync,
  } = useRecordHistory();

  if (error && events === undefined)
    return <ErrorComponent error={error.status} />;

  return (
    <Loader spinning={loading}>
      <div className={classes.panelWrapper}>
        <TooltipString text={identifier ?? ''} className={classes.identifier}>
          {identifier}
        </TooltipString>

        <EventFilters {...{ queryParams, setQueryParams }} />
        {error && events?.results?.length === 0 && (
          <EmptyData
            className={classes.emptyData}
            title={error.title}
            description={error.description}
            image={<EmptyBox />}
          />
        )}
        <InfiniteScroll
          next={fetchNextPage}
          hasMore={hasMorePages}
          loader={undefined}
          dataLength={events?.results.length || 0}
          scrollableTarget={SIDE_PANEL}
        >
          {!!events?.results?.length && (
            <EventsList events={events.results} refreshData={refreshDataSync} />
          )}
        </InfiniteScroll>
      </div>
    </Loader>
  );
};

export default ActivityLogPanel;
