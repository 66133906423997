import { Tag } from 'components/lib/Tag';
import React from 'react';
import { MEMBERSHIP_TAG } from 'utils/testIds';
import { useMembership } from './MembershipTag.hooks';
import { useMembershipTagStyles } from './MembershipTag.styles';
import { MembershipTagProps } from './MembershipTag.types';

export const MembershipTag = ({ membership }: MembershipTagProps) => {
  const styles = useMembershipTagStyles({ membership });
  const membershipText = useMembership(membership);

  return (
    <Tag className={styles.membershipTag} data-testid={MEMBERSHIP_TAG}>
      {membershipText}
    </Tag>
  );
};
