import { useFormikContext } from 'formik';
import { MAX_IDENTIFIER_LENGTH } from 'pages/ObjectClasses/components/ObjectClassForm/ObjectClassForm.consts';
import { useValidators } from '../../../hooks/useValidators';
import { ClassFieldForm } from '../../../../../types';
import { ParsedTypes } from 'pages/ObjectClasses/components/ClassFieldFormWrapper/types';

type LengthValidation = {
  min_value?: number;
  max_value?: number;
};

export const useMaxLengthValidators = (
  parsedTypes: ParsedTypes
): LengthValidation => {
  const {
    values: { type, is_identifier },
  } = useFormikContext<ClassFieldForm>();
  const {
    max_length: { validators = [] } = {},
    max_num_of_files: { validators: fileValidators = [] } = {},
  } = parsedTypes?.[type] || {};
  const restrictions = useValidators(validators);
  const fileRestrictions = useValidators(fileValidators);

  return {
    ...restrictions,
    ...fileRestrictions,
    ...(is_identifier && { max_value: MAX_IDENTIFIER_LENGTH }),
  };
};
