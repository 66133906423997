import React from 'react';
import DateWidget from './DateWidget';
import moment from 'moment';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import { FormItem } from 'components/lib/Form';
import { Widget } from 'alx-dynamic-form';
import clsx from 'clsx';
import { PropertyField } from 'components/formBuilder/formBuilder/FBGeneralInput/components/FBFields/FBPropertyField/components/PropertyField';

const CustomDatepickerWidget: Widget = ({
  value,
  onChange,
  isProperty,
  description,
  errors,
  required,
  label,
  dateGreaterThanToday,
  reValidateField,
  disabled,
  fieldAlias,
}) => {
  const classes = useCustomWidgetsStyles({});

  if (isProperty) {
    return (
      <PropertyField
        value={!!value ? moment(value as string).format('LLL') : undefined}
      />
    );
  }

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        {...{ required }}
        className={clsx([classes.inputLabel, classes.inputLabelEllipsis])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors?.length ? 'error' : undefined}
      >
        <StandardFieldWrapper {...{ description, errors }}>
          <div className={classes.limitedMaxSizeInput}>
            <DateWidget
              {...{
                value,
                onChange,
                dateGreaterThanToday,
                reValidateField,
                disabled,
                fieldAlias,
              }}
            />
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};

export default CustomDatepickerWidget;
