import { useSelector } from 'react-redux';
import {
  getCurrentTableFilteredCount,
  getCurrentTableTotalCount,
} from 'store/selectors/generalSelectors';

const useTableCount = (classID?: string) => {
  const total = useSelector(getCurrentTableTotalCount(classID?.toString()));
  const filtered = useSelector(
    getCurrentTableFilteredCount(classID?.toString())
  );

  return { total, filtered };
};

export default useTableCount;
