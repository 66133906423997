import { AxiosResponse } from 'axios';
import { FormBuilderChildObjectClass } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import { ObjectClassDetailsFromApi } from 'pages/ObjectClasses/components/ObjectClassForm/types';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { setChildClasses } from 'store/actions/childClasses.actions';
import { apiCall } from 'utils/api';
import {
  OBJECT_CLASS_DETAILS,
  OBJECT_CLASS_MODEL_CHILDREN,
} from 'utils/endpoints';
import { ObjectClassModelChildClass } from 'utils/types/api/objectClassModels.types';

export type UseChildClassesParams = {
  objectClass?: ObjectClassDetailsFromApi;
};

const useChildClasses = ({ objectClass }: UseChildClassesParams) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const clearSelectedChildClass = useCallback(() => {
    dispatch(setChildClasses([]));
  }, [dispatch]);

  const transformObjClass = useCallback(
    (
      objClass: ObjectClassModelChildClass & ObjectClassDetailsFromApi,
      parentClassId: number
    ): FormBuilderChildObjectClass => {
      return {
        alias: `child_class_${objClass.id}`,
        multiplicity: objClass.multiplicity,
        label: objClass.name
          ? `${objClass.name}`
          : intl.formatMessage(
              {
                id: 'formBuilder.objectClassWithId',
                defaultMessage: 'Object class {id}',
              },
              { id: objClass.id }
            ),
        hasCreateEditViewEnabled: !!objClass.display_configuration?.recordView
          ?.data_schema?.enabled,
        id: objClass.id,
        hasViewPermission: objClass._meta.permissions.view,
        hasListPermission: objClass._meta.permissions.object_records.list,
        hasCreatePermission: objClass._meta.permissions.object_records.create,
        parentId: parentClassId,
      };
    },
    [intl]
  );

  const setObjectClassChildren = useCallback(async () => {
    const objectModelId = objectClass?.object_models?.[0];

    if (!objectClass || !objectModelId) {
      return;
    }

    setLoading(true);

    try {
      const {
        data: childClasses,
      }: { data: ObjectClassModelChildClass[] } = await apiCall.get(
        generatePath(OBJECT_CLASS_MODEL_CHILDREN, {
          id: objectModelId,
          classId: objectClass.id,
        })
      );

      const childrenDetails = await Promise.allSettled(
        childClasses.map(childClass =>
          apiCall.get<ObjectClassDetailsFromApi>(
            generatePath(OBJECT_CLASS_DETAILS, { id: childClass.id })
          )
        )
      );
      const objectClasses: FormBuilderChildObjectClass[] =
        childrenDetails
          .filter(
            (
              promise
            ): promise is PromiseFulfilledResult<
              AxiosResponse<ObjectClassDetailsFromApi>
            > => promise.status === 'fulfilled'
          )
          ?.map(child =>
            transformObjClass(
              {
                ...child.value.data,
                ...childClasses.find(c => c.id === child.value.data.id),
              } as ObjectClassModelChildClass & ObjectClassDetailsFromApi,
              objectClass.id
            )
          )
          .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

      dispatch(setChildClasses(objectClasses));
    } catch {
    } finally {
      setLoading(false);
    }
  }, [dispatch, objectClass, transformObjClass]);

  return {
    childrenClassLoading: loading,
    transformObjClass,
    setObjectClassChildren,
    clearSelectedChildClass,
  };
};

export default useChildClasses;
