import { BULK_SELECTION_COLUMN_WIDTH } from 'components/CatalystTable/CatalystTable.consts';
import { useCatalystTableStyles } from 'components/CatalystTable/CatalystTable.styles';
import React from 'react';
import { TABLE_BULK_SELECTION_HEADER_CHECKBOX_TESTID } from 'utils/testIds';
import { Checkbox } from 'components/lib/Checkbox';
import { useTableRowContext } from 'components/CatalystTable/contexts/TableRowContext';
import { useBulkSelectionHeaderCheckbox } from './BulkSelectionHeaderCheckbox.hooks';
import { BulkSelectionHeaderCheckboxProps } from './BulkSelectionHeaderCheckbox.types';
import { useTableStyles } from '../../../../Table.styles';

export const BulkSelectionHeaderCheckbox = (
  props: BulkSelectionHeaderCheckboxProps
) => {
  const {
    isSelectedAnyRow,
    isSelectedAllRows,
    onChangeHeaderCheckbox,
  } = useBulkSelectionHeaderCheckbox(props);
  const { density } = useTableRowContext();
  const tableStyles = useCatalystTableStyles();
  const styles = useTableStyles({ density });

  return (
    <div
      className={styles.tableHeadCell}
      style={{
        width: BULK_SELECTION_COLUMN_WIDTH,
      }}
      data-testid={TABLE_BULK_SELECTION_HEADER_CHECKBOX_TESTID}
    >
      <div className={tableStyles.flexAlignCenter}>
        <Checkbox
          onChange={onChangeHeaderCheckbox}
          checked={isSelectedAllRows}
          indeterminate={isSelectedAnyRow}
          className={styles.checkbox}
        />
      </div>
    </div>
  );
};
