import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCommonPredicates } from './useCommonPredicates';

/**
 * Get all user and group predicates. Is declared as a hook so that it can support the dynamic
 * language translation of predicate labels.
 *
 * @returns User and group filter predicates.
 */
export const useUserAndGroupPredicates = () => {
  const intl = useIntl();

  const { commonPredicates } = useCommonPredicates();

  const userAndGroupPredicates = useMemo(
    () => ({
      exact: {
        label: intl.formatMessage({
          id: 'predicates.isEqual',
          defaultMessage: 'is equal to',
        }),
        value: 'exact',
        query: '={value}',
        args: ['value'],
      },
      not_exact: {
        label: intl.formatMessage({
          id: 'predicates.isNotEqual',
          defaultMessage: 'is not equal to',
        }),
        value: 'not_exact',
        query: '!={value}',
        args: ['value'],
      },
      in: {
        label: intl.formatMessage({
          id: 'predicates.isOneOf',
          defaultMessage: 'is one of',
        }),
        value: 'in',
        query: '__in={value}',
        args: ['value'],
        selectMultiple: true,
      },
      not_in: {
        label: intl.formatMessage({
          id: 'predicates.isNotOneOf',
          defaultMessage: 'is not one of',
        }),
        value: 'not_in',
        query: '__in!={value}',
        args: ['value'],
        selectMultiple: true,
      },
      ...commonPredicates,
    }),
    [commonPredicates, intl]
  );

  return { userAndGroupPredicates };
};
