import React from 'react';
import { UsageListProps } from './UsageList.types';
import { UsageItem } from './components/UsageItem';
import { UsageViewConfig } from 'components/ResourceUsagePanelView/types/usageViewConfig';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';
import { useUsageListStyles } from './UsageList.styles';

export const UsageList = ({ displayConfig }: UsageListProps) => {
  const styles = useUsageListStyles();

  const shouldUsageBeDisplayed = (usageConfig: UsageViewConfig) => {
    if (usageConfig.type === UsageViewType.Custom) {
      return usageConfig.shouldBeVisible;
    }

    return usageConfig.usage.length > 0;
  };

  return (
    <div className={styles.wrapper}>
      {displayConfig.map(usageConfig => {
        if (!shouldUsageBeDisplayed(usageConfig)) {
          return null;
        }

        return <UsageItem key={usageConfig.title} usageConfig={usageConfig} />;
      })}
    </div>
  );
};
