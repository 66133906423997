import { AxiosError } from 'axios';
import { isRemovedUsersOrGroupsError } from './isRemovedUsersOrGroupsError';

/**
 * Tries to determine if the axios error contains data about removed users or groups.
 * If it is, returns the data about removed entities. Otherwise, returns null.
 */
export const tryGetRemovedUsersOrGroupsErrorResponseData = (
  error: AxiosError
) => {
  if (!isRemovedUsersOrGroupsError(error)) {
    return null;
  }

  return error.response?.data ?? null;
};
