import { ColumnsMetadata } from 'utils/types/api/table.types';
import { UserMe } from 'utils/types/api/users.types';
import { TPredicateValue } from 'utils/types/predicates.types';
import { OWNERSHIP_PREDICATE_LABELS } from '../consts/ownershipPredicateLabels';
import { TasksQuickFilterOwnership } from '../types/tasksQuickFilterOwnership';

const { Owners } = TasksQuickFilterOwnership;

export const getOwnerPredicate = (
  columns: MappedObject<ColumnsMetadata, string>,
  user: UserMe,
  predicate?: 'exact' | 'not_exact'
) => {
  return [
    {
      column: columns[Owners].alias,
      type: columns[Owners].type,
      predicates: columns[Owners].predicates || [],
      label: OWNERSHIP_PREDICATE_LABELS[Owners],
      value: {
        predicateKey: predicate || 'exact',
        predicateValues: {
          value: ({
            ...user,
            value: user.id,
            text: `${user.first_name} ${user.last_name} (${user.username})`,
          } as unknown) as TPredicateValue,
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
  ];
};
