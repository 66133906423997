import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useEventDataJsonStyles = createUseStyles((theme: ThemeState) => ({
  pre: {
    fontFamily: theme['font-family-consolas'],
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '14px',
    counterReset: 'line-numbering',
    color: theme['text-color'],
  },
  line: {
    lineHeight: '14px',
    display: 'block',
    '&::before': {
      content: 'counter(line-numbering)',
      counterIncrement: 'line-numbering',
      paddingRight: '1.5em',
      textAlign: 'right',
      lineHeight: '14px',
      display: 'inline-block',
      color: theme['layout-trigger-color'],
    },
  },
}));
