import React, { useCallback } from 'react';
import { ExpandedAvatarItemsProps } from './ExpandedAvatarItems.types';
import UserAvatar from 'components/UserAvatar';
import { AVATARS_GROUP_REST_ITEMS } from 'utils/testIds';
import { AvatarItem } from '../../types';
import useAvatarStyles from '../../styles';
import { useIntl } from 'react-intl';

export const ExpandedAvatarItems = ({
  items,
  showAllInMore,
  disablePopover,
  groupNoPermissionText,
  alreadyDisplayedItemIds,
}: ExpandedAvatarItemsProps) => {
  const intl = useIntl();
  const styles = useAvatarStyles({ isInteractive: !disablePopover });

  const moreItems = showAllInMore
    ? items
    : items.filter(({ id }) => !alreadyDisplayedItemIds.includes(id));

  const evaluateDisplayName = useCallback(
    ({ id, userGroup, isDeleted, firstName, lastName }: AvatarItem) => {
      if (userGroup) {
        return isDeleted
          ? intl.formatMessage(
              {
                id: 'misc.deletedUserGroup',
                defaultMessage: 'Deleted user group {id}',
              },
              { id }
            )
          : userGroup;
      }

      return isDeleted
        ? intl.formatMessage(
            {
              id: 'misc.deletedUser',
              defaultMessage: 'Deleted user {id}',
            },
            { id }
          )
        : `${firstName} ${lastName}`;
    },
    [intl]
  );

  return (
    <>
      {moreItems.map(item => {
        const {
          userGroup,
          id,
          firstName,
          lastName,
          accountType,
          isDeleted,
        } = item;

        return item.id ? (
          <div
            key={userGroup ? `group-${id}` : `user-${id}`}
            className={styles.listItem}
          >
            <UserAvatar
              id={id}
              userGroup={userGroup}
              firstName={firstName}
              lastName={lastName}
              disablePopover={disablePopover}
              accountType={accountType}
              isDeleted={isDeleted}
              groupNoPermissionText={groupNoPermissionText}
              className={styles.avatar}
              size='small'
              closeOnElementScroll={AVATARS_GROUP_REST_ITEMS}
            />
            <p>{evaluateDisplayName(item)}</p>
          </div>
        ) : null;
      })}
    </>
  );
};
