import {
  FormFieldSchemaFieldUser,
  FormSchema,
  FormSchemaElementType,
  FormSchemaFieldDatabaseType,
} from 'components/FormPreview2/formSchema.types';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { OBJECT_CLASS_FIELD_VALIDATION } from 'utils/endpoints';
import { ObjectClassFieldsValidateResponse } from 'utils/types/api/objectClasses.types';
import { StatusCodes } from 'http-status-codes';

/**
 * Performs a verification of the form fulfillment possibility.
 * @param formSchema schema of the validated form.
 */
export const useFormFulfillmentValidation = (
  objectClassId: string | undefined,
  formSchema: string | undefined,
  onValidationCompleted: (hasErrors: boolean) => void
) => {
  const [isValidatingFulfillment, setIsValidatingFulfillment] = useState(true);
  const [fulfillmentErrors, setFulfillmentErrors] = useState<Set<string>>(
    new Set()
  );

  const extractRequiredUserTypeFields = useCallback((schema: FormSchema) => {
    const userFields: FormFieldSchemaFieldUser[] = [];

    Object.values(schema.properties).forEach(section => {
      section.required.forEach(requiredFieldKey => {
        const field = section.properties[requiredFieldKey];

        if (field.type !== FormSchemaElementType.Array) {
          return;
        }

        if (field.databaseType !== FormSchemaFieldDatabaseType.User) {
          return;
        }

        userFields.push(field);
      });
    });

    return userFields;
  }, []);

  const buildValidationErrorsMapFromResponse = useCallback(
    (data: ObjectClassFieldsValidateResponse) => {
      const regex = /"([^"]+)"/g;
      const errorsSet = new Set<string>();
      data.error.forEach((msg: string) => {
        const match = msg.match(regex);

        if (isNil(match)) {
          return;
        }

        const fieldLabel = match[0].slice(1, -1); // slice to remove quotes from extracted string.
        errorsSet.add(fieldLabel);
      });

      return errorsSet;
    },
    []
  );

  useEffect(() => {
    (async () => {
      if (isNil(formSchema) || isNil(objectClassId)) {
        return;
      }

      try {
        const schema: FormSchema = JSON.parse(formSchema);
        const userFields = extractRequiredUserTypeFields(schema);
        const userFieldIds = userFields.map(field => field.fieldId);

        if (userFieldIds.length === 0) {
          return;
        }

        const response = await apiCall.post<ObjectClassFieldsValidateResponse>(
          generatePath(OBJECT_CLASS_FIELD_VALIDATION, {
            id: objectClassId,
          }),
          userFieldIds
        );

        if (response.status === StatusCodes.NO_CONTENT) {
          return;
        }

        const errorsMap = buildValidationErrorsMapFromResponse(response.data);
        onValidationCompleted(true);
        setFulfillmentErrors(errorsMap);
      } catch (error) {
        showUnhandledErrorToast(error);
      } finally {
        setIsValidatingFulfillment(false);
      }
    })();
  }, [
    buildValidationErrorsMapFromResponse,
    extractRequiredUserTypeFields,
    formSchema,
    objectClassId,
    onValidationCompleted,
  ]);

  return { isValidatingFulfillment, fulfillmentErrors };
};
