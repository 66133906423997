import { useCallback, useEffect, useMemo, useState } from 'react';
import useData from 'hooks/useData';
import useValidationSchemaBuilder from 'hooks/useValidationSchemaBuilder';
import { mapTaskOptionsToDictionary } from 'pages/TaskTemplates/utils';
import {
  OBJECT_CLASSES_OWNED_AUTOCOMPLETE,
  SEQUENCES_LIST,
  SEQUENCE_ASSIGNEES,
  SEQUENCE_DETAILS,
  SEQUENCE_STAGE_ASSIGNEES,
} from 'utils/endpoints';
import { FormMode } from 'utils/Enums/FormMode';
import { OptionsResponse } from 'utils/types';
import {
  SequenceDefaultAssigneesStagesOptions,
  SequenceDefaultTaskAssigneesStagesSchema,
  SequenceFormSchema,
  SequencesForm,
  SequencesFormFields,
  StageDefaultAssignees,
} from './types';
import { FormikHelpers } from 'formik';
import { usePostWithToasts } from 'hooks/usePostWithToasts';
import { Sequence } from 'utils/types/api/sequences.types';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import useBackToList from 'hooks/useBackToList';
import { useIntl } from 'react-intl';
import routes from 'utils/routingPaths';
import {
  setDefaultTaskAssignees,
  setDefaultTaskAssigneesLoading,
  setSequencesSelectedRow,
} from 'store/actions/sequencesActions';
import usePermissions from 'hooks/usePermissions';
import { mapBaseUserInfoToAvatarItem } from 'utils/functions/mapBaseUserInfoToAvatarItem';
import { getSequenceDefaultTaskAssignees } from 'store/selectors/sequencesSelectors';
import { StageNames } from 'utils/types/api/tasks.types';
import { apiCall } from 'utils/api';

export const useSequenceForm = (mode: FormMode, data?: Sequence) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { generateBackPath } = useBackToList();
  const [fetchedData, { loading, fetchData }] = useData<
    StageDefaultAssignees[]
  >(
    data?.id
      ? generatePath(SEQUENCE_ASSIGNEES, {
          id: data.id,
        })
      : '',
    { fetchOnLoad: false }
  );
  const sequenceTaskDefaultAssignees = useSelector(
    getSequenceDefaultTaskAssignees
  );

  const { sendData, isLimitExceeded } = usePostWithToasts<
    SequencesForm,
    Sequence
  >(mode);
  const { id } = data || ({} as Sequence);

  const [initialValues, setInitialValues] = useState<SequencesForm>({
    [SequencesFormFields.Name]: '',
  });

  const successMessage = {
    title: intl.formatMessage({
      id: 'misc.success',
      defaultMessage: 'Success!',
    }),
    subtitle: intl.formatMessage({
      id: 'misc.sequenceCreated',
      defaultMessage: 'Sequence has been created.',
    }),
  };

  const addDefaultTaskAssigneesToSequence = useCallback(
    async (sequenceId?: string) => {
      if (!sequenceTaskDefaultAssignees?.results?.length) return;

      try {
        if (!sequenceId) throw new Error();
        await apiCall.post(
          generatePath(SEQUENCE_STAGE_ASSIGNEES, {
            id: sequenceId,
            stageId: StageNames.Stage1,
          }),
          { users: sequenceTaskDefaultAssignees.results.map(({ id }) => id) }
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [sequenceTaskDefaultAssignees]
  );

  const onSubmit = useCallback(
    async (
      formData: SequencesForm,
      { setErrors, setSubmitting }: FormikHelpers<SequencesForm>
    ) => {
      const callback = async (data?: Sequence) => {
        await addDefaultTaskAssigneesToSequence(data?.id);

        dispatch(setSequencesSelectedRow(data?.id));
        history.push(generateBackPath(routes.SEQUENCES));
      };

      try {
        await sendData({
          url:
            mode === FormMode.Edit
              ? generatePath(SEQUENCE_DETAILS, { id })
              : SEQUENCES_LIST,
          data: formData,
          fields: SequencesFormFields,
          setErrors,
          callback,
          initialData: initialValues,
          setSubmitting,
          successMessage: mode === FormMode.Edit ? undefined : successMessage,
        });
      } catch {}
    },
    [
      addDefaultTaskAssigneesToSequence,
      dispatch,
      history,
      generateBackPath,
      sendData,
      mode,
      id,
      initialValues,
      successMessage,
    ]
  );

  useEffect(() => {
    if (data) {
      const { name, description, object_class: objectClassId } = data;

      setInitialValues(prevValues => ({
        ...prevValues,
        name,
        description,
        [SequencesFormFields.ObjectClass]: String(objectClassId),
      }));
    }
  }, [data]);

  useEffect(() => {
    if (data?.id) {
      fetchData();
    }
  }, [fetchData, data, dispatch]);

  const taskAssignees = useMemo(
    () =>
      fetchedData?.flatMap(stage =>
        stage.assignees?.users?.map(item => mapBaseUserInfoToAvatarItem(item))
      ) ?? [],
    [fetchedData]
  );

  useEffect(() => {
    if (taskAssignees.length) dispatch(setDefaultTaskAssignees(taskAssignees));
  }, [dispatch, taskAssignees]);

  useEffect(() => {
    dispatch(setDefaultTaskAssigneesLoading(loading));
  }, [dispatch, loading]);

  return {
    initialValues,
    setInitialValues,
    isLimitExceeded,
    onSubmit,
    taskAssignees: sequenceTaskDefaultAssignees?.results || [],
    assigneesLoading: loading || sequenceTaskDefaultAssignees?.loading,
  };
};

export const useSequenceOptions = () => {
  const dataTransformFn = (data: OptionsResponse) =>
    mapTaskOptionsToDictionary<SequenceFormSchema>(data.details.schema);

  const [options, { loading: optionsLoading, error: optionsError }] = useData<
    SequenceFormSchema
  >(SEQUENCES_LIST, {
    requestType: 'options',
    dataTransformFn,
  });

  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<SequenceFormSchema>(options);

  return {
    rawValidationSchema,
    buildValidationSchema,
    optionsLoading,
    optionsError,
  };
};

export const useSequenceDefaultTaskAssigneesOptions = () => {
  const parseDefaultAssignees = (
    data: SequenceDefaultAssigneesStagesOptions
  ) => {
    const stageOptions = {
      ...(data?.restrictions || {}),
      ...(data?.batch || {}),
    };

    return {
      [StageNames.Stage1]: stageOptions,
    };
  };

  const [
    defaultTaskAssigneesOptions,
    {
      loading: defaultTaskAssigneesOptionsLoading,
      error: defaultTaskAssigneesOptionsError,
    },
  ] = useData<SequenceDefaultTaskAssigneesStagesSchema>(
    generatePath(SEQUENCE_ASSIGNEES, { id: 0 }),
    {
      requestType: 'options',
      dataTransformFn: parseDefaultAssignees,
    }
  );

  return {
    loading: defaultTaskAssigneesOptionsLoading,
    error: defaultTaskAssigneesOptionsError,
    defaultTaskAssigneesOptions,
  };
};

export const useHasAccessToObjectClasses = () => {
  const { hasPermissionToRoute } = usePermissions();

  return hasPermissionToRoute(routes.OBJECT_CLASSES);
};

export const useIsOwningAnyObjectClass = () => {
  const [userOwnsAnyClass, setUserOwnsAnyClass] = useState<
    boolean | 'fetching'
  >('fetching');
  useEffect(() => {
    const checkIfUserOwnsAnyClass = async () => {
      try {
        const response = await apiCall.get(
          `${OBJECT_CLASSES_OWNED_AUTOCOMPLETE}?limit=0`
        );

        if (response?.data?.total_count > 0) setUserOwnsAnyClass(true);
        else setUserOwnsAnyClass(false);
      } catch {
        setUserOwnsAnyClass(false);
      }
    };

    checkIfUserOwnsAnyClass();
  }, []);

  return userOwnsAnyClass;
};
