import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useCustomValueDisplayDocumentsStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      fileLink: {
        color: theme['primary-color'],
        textDecoration: 'underline',
      },
    };
  }
);
