import React from 'react';
import { EditSelectedUsersAndGroupsProps } from './EditSelectedUsersAndGroups.types';
import { sortUsers } from 'utils/functions/sortUsers';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import { sortGroups } from 'utils/functions/sortGroups';
import GroupListElement from 'pages/UserGroups/components/Permissions/GroupListElement';
import { useEditSelectedUsersAndGroupsStyles } from './EditSelectedUsersAndGroups.styles';

export const EditSelectedUsersAndGroups = ({
  selectedUsers,
  selectedGroups,
  onUserDelete,
  onGroupDelete,
}: EditSelectedUsersAndGroupsProps) => {
  const styles = useEditSelectedUsersAndGroupsStyles();

  return (
    <ul className={styles.userAndGroupList}>
      {sortGroups(selectedGroups).map(group => (
        <li key={`group-${group.id}`}>
          <GroupListElement
            id={group.id}
            userGroup={group.text}
            truncateTooLongNames
            size='small'
            onDelete={() => onGroupDelete(group.id)}
          />
        </li>
      ))}

      {sortUsers(selectedUsers).map(user => (
        <li key={`user-${user.id}`}>
          <PeopleListElement
            id={user.id}
            firstName={user.first_name}
            lastName={user.last_name}
            accountType={user.account_type}
            size='small'
            onDelete={() => onUserDelete(user.id)}
          />
        </li>
      ))}
    </ul>
  );
};
