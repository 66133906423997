import React from 'react';
import { useValidationListingStyles } from './ValidationListing.styles';
import { useUsersAndGroupsExpandablePickerContext } from '../../../../contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import { ErrorsComponent } from 'components/ErrorsComponent';
import MinMaxInfo from 'components/MinMaxInfo';
import { MinMaxInfoLabelType } from 'components/MinMaxInfo/types';
import { ValidationListingState } from './ValidationListing.types';
import { USERS_AND_GROUPS_SELECT_VALIDATION_CONTAINER_TESTID } from 'utils/testIds';

/**
 * Lists limits and error messages below the users and groups list.
 */
export const ValidationListing = () => {
  const styles = useValidationListingStyles();
  const {
    limits,
    errors,
    minMaxInfoErrorPairs,
  } = useUsersAndGroupsExpandablePickerContext();

  const hasErrors = errors !== undefined && errors.length > 0;
  const hasValidation = limits.selectionCountLimits !== undefined;
  const { selectionCountLimits } = limits;

  if (!hasErrors && !hasValidation) {
    return <></>;
  }

  const minMaxInfoErrors = Array.from(minMaxInfoErrorPairs?.values() ?? []);

  // This state handling is defined by this ticket: https://redmine.dev.autologyx.com/issues/40820
  // We need to show certain errors by swapping the min max info, while other errors displayed as a separate list.
  // This diagram shows how the state is evaluated: https://imgur.com/DBQkZEp
  const evalListingState = () => {
    if (!hasValidation) {
      return ValidationListingState.ErrorList;
    }

    if (errors === undefined || errors.length === 0) {
      return ValidationListingState.ValidationList;
    }

    return errors.some(error => minMaxInfoErrors.includes(error))
      ? ValidationListingState.ValidationList
      : ValidationListingState.ErrorList;
  };

  const hasMinMaxError = (labelType: MinMaxInfoLabelType) => {
    const labelError = minMaxInfoErrorPairs?.get(labelType);

    if (!labelError) {
      return false;
    }

    return errors?.includes(labelError);
  };

  const validationState = evalListingState();

  const minUsersErrorPair = minMaxInfoErrorPairs?.get(
    MinMaxInfoLabelType.Users
  );

  const minGroupsErrorPair = minMaxInfoErrorPairs?.get(
    MinMaxInfoLabelType.Groups
  );

  return (
    <div
      className={styles.validationContainer}
      data-testid={USERS_AND_GROUPS_SELECT_VALIDATION_CONTAINER_TESTID}
    >
      {validationState === ValidationListingState.ErrorList && (
        <ErrorsComponent
          key={`info-errors`}
          rawErrors={errors ?? []}
          blacklist={minMaxInfoErrors}
        />
      )}
      {validationState === ValidationListingState.ValidationList && (
        <>
          {!hasMinMaxError(MinMaxInfoLabelType.Users) ? (
            <MinMaxInfo
              key={`min-max-info-users`}
              className={styles.minMaxInfo}
              maximum={selectionCountLimits?.maxUsers ?? undefined}
              minimum={selectionCountLimits?.minUsers ?? undefined}
              labelType={MinMaxInfoLabelType.Users}
              checkboxField
            />
          ) : (
            <ErrorsComponent
              rawErrors={errors}
              whitelist={minUsersErrorPair ? [minUsersErrorPair] : undefined}
            />
          )}
          {!hasMinMaxError(MinMaxInfoLabelType.Groups) ? (
            <MinMaxInfo
              key={`min-max-info-groups`}
              className={styles.minMaxInfo}
              maximum={selectionCountLimits?.maxGroups ?? undefined}
              minimum={selectionCountLimits?.minGroups ?? undefined}
              labelType={MinMaxInfoLabelType.Groups}
              checkboxField
            />
          ) : (
            <ErrorsComponent
              rawErrors={errors}
              whitelist={minGroupsErrorPair ? [minGroupsErrorPair] : undefined}
            />
          )}
        </>
      )}
    </div>
  );
};
