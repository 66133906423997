import { AxiosError } from 'axios';
import { FilterValue } from 'store/actions/filtersActions';
import { Logger, LogLevel } from 'utils/logger';
import { checkResponseForFilterErrors } from './utils/checkResponseForFilterErrors';
import { displayWarningToastsForCorrectedFilters } from './utils/displayWarningToastsForCorrectedFilters';
import { correctFilterList } from './utils/correctFilterList';

/**
 * Checks the provided exception error to see if it's related to records filtering error. If it does, it will
 * try to correct the provided filters structure and return corrected filters list for further use.
 * @param currentFilters Filters that should be corrected based on the error response.
 * @param error API error response that contains information about wrong filter.
 * @returns Corrected list of filters if correction was made. Null if nothing was corrected.
 */
export const tryCorrectingFiltersBasedOnApiError = (
  currentFilters: FilterValue[],
  error: AxiosError
) => {
  Logger.log(
    'Fetching records failed. Analyzing if correction can be made.',
    LogLevel.Verbose
  );

  const filterErrors = checkResponseForFilterErrors(error.response?.data);

  if (error.response?.status !== 400 || filterErrors.length === 0) {
    Logger.log('Not filter error. Aborting.', LogLevel.Verbose);
    return null;
  }

  Logger.log(
    'Potential filter errors. Correcting.',
    LogLevel.Verbose,
    filterErrors
  );

  // When we receive invalid filters error from API, we fix the filters and try fetching again.
  const correctedFilters = correctFilterList(currentFilters, filterErrors);

  displayWarningToastsForCorrectedFilters(filterErrors);
  Logger.log(
    'Filters corrected. Reapplying new filters and fetching data.',
    LogLevel.Verbose
  );

  return correctedFilters;
};
