import { useMemo } from 'react';
import isMultiline from 'utils/functions/isMultiline';
import { EventData, EventFieldType, EventType } from '../../../../../types';
import { useEventItems } from './useEventItems';
import { useEventLabel } from './useEventLabel';
import { useEventIcon } from '../../EventItemErrorFallback/hooks/useEventIcon';

export const useEventData = (eventData: EventData, eventType: EventType) => {
  const eventIcon = useEventIcon(eventType);
  const { items, value, fieldType } = useEventItems(eventData, eventType);
  const label = useEventLabel(items, eventType, value, fieldType);

  const isExpandableTextField =
    items.length > 0 &&
    fieldType === EventFieldType.string &&
    typeof items[0].value === 'string' &&
    (items[0].value.length > 100 || isMultiline(items[0].value));

  const isMultiple = useMemo(
    () =>
      items.length > 1 ||
      isExpandableTextField ||
      fieldType === EventFieldType.document ||
      fieldType === EventFieldType.user ||
      fieldType === EventFieldType.set ||
      fieldType === EventFieldType.json ||
      eventType === EventType.DocumentGenerated,
    [eventType, fieldType, isExpandableTextField, items.length]
  );

  return { items, label, fieldType, isMultiple, ...eventIcon };
};
