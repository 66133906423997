import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonSecondaryOutlined } from 'components/lib/Button';
import {
  BUTTON_CLOSE_TESTID,
  CLASS_PERMISSION_MANAGE_ASSIGNEES_PANEL_TESTID,
  SELECTED_PERMISSION_SET_NAME_TESTID,
} from 'utils/testIds';
import { TabPane, Tabs } from 'components/lib/Tabs';
import { ManageClassPermissionUsers } from './components/ManageClassPermissionUsers';
import { useManageAssigneesPanelStyles } from './ManageAssigneesPanel.styles';
import {
  useClassPermissionAssigneeOptions,
  useManageAssigneesPanelTabs,
} from './hooks';
import { ManageClassPermissionGroups } from './components/ManageClassPermissionGroups';
import { UsersOrGroupsTabKey } from 'utils/Enums/UsersOrGroupsTabKey';
import { useSelector } from 'react-redux';
import { getObjectClassSelectedPermissionSet } from 'store/selectors/objectClassPermissionsSelectors';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import usePanels from 'hooks/usePanels';

export const ManageAssigneesPanel = () => {
  const styles = useManageAssigneesPanelStyles();

  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const { togglePanelsPreferences } = usePanels();
  const { availableComponents, closePanelIfOpen } = useFlexLayoutContext();
  const {
    activeTab,
    usersTab,
    groupsTab,
    onTabChange,
  } = useManageAssigneesPanelTabs();

  const handleTabChange = (tabKey: string) => {
    onTabChange(tabKey as UsersOrGroupsTabKey);
  };

  const handleCancel = () => {
    closePanelIfOpen(FlexLayoutWindows.ClassPermissionsOwners);
    togglePanelsPreferences(FlexLayoutWindows.ClassPermissionsOwners, true);

    const onPanelClose =
      availableComponents[FlexLayoutWindows.ClassPermissionsOwners]?.onClose;

    if (!onPanelClose) {
      return;
    }

    onPanelClose();
  };

  useClassPermissionAssigneeOptions();

  return (
    <div
      className={styles.panelWrapper}
      data-testid={CLASS_PERMISSION_MANAGE_ASSIGNEES_PANEL_TESTID}
    >
      <header className={styles.header}>
        <h3
          className={styles.permissionSetName}
          data-testid={SELECTED_PERMISSION_SET_NAME_TESTID}
        >
          {selectedPermissionSet?.name}
        </h3>
      </header>

      <Tabs
        className={styles.tabs}
        activeKey={activeTab}
        destroyInactiveTabPane
        onChange={handleTabChange}
      >
        <TabPane key={usersTab.key} tab={usersTab.text}>
          <ManageClassPermissionUsers />
        </TabPane>
        <TabPane key={groupsTab.key} tab={groupsTab.text}>
          <ManageClassPermissionGroups />
        </TabPane>
      </Tabs>

      <footer className={styles.footer}>
        <ButtonSecondaryOutlined
          onClick={handleCancel}
          data-testid={BUTTON_CLOSE_TESTID}
        >
          <FormattedMessage id='misc.close' defaultMessage='Close' />
        </ButtonSecondaryOutlined>
      </footer>
    </div>
  );
};
