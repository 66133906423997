import Tooltip from 'components/lib/Tooltip';
import { BoldedPartOfText } from 'components/Text/BoldedPartOfText';
import { CloseIcon, PlusIcon } from 'components/Icon';
import { ButtonPrimary } from 'components/lib/Button';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CHILD_CLASS_COMPONENT_CLEAR_BUTTON,
  CHILD_CLASS_COMPONENT_CREATE_BUTTON,
} from 'utils/testIds';
import { ChildClassMultiplicity } from 'utils/types/api/objectClassModels.types';
import { RenderOption } from './components/ChildClassTableAutocompleteSelect/types';
import { SelectOption as Option } from 'components/lib/Select';
import AutocompleteChildRecordsSelect from './components/ChildClassTableAutocompleteSelect';
import { UseTableHeaderProps } from './types';
import { useNestedChildClassTableStyles } from './styles';
import LimitExceededNotification from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassPermissions/components/AddUsersPanel/components/LimitExceededNotification';
import { childClassUICOmponentStates } from './enums';
import clsx from 'clsx';
const ZERO_OR_MORE_MAX = 5; //limited to 5 records, because it would require backend to respect order given in id__in filter or to add pagination on our side, both are time consuming.
const ZERO_OR_ONE_MAX = 1;

const useTableHeader = ({
  filteredCount: itemsCount,
  totalCount,
  selectedIds,
  multiplicity,
  readOnly,
  autocompleteUrl,
  isLoadingData,
  recordId,
  selectedForFiltering,
  clearSelectedForFiltering,
  handleAutocompleteOptionChange,
  onCreateClick,
  hasCreateEditViewEnabled,
  uiPermissionsConfig,
}: UseTableHeaderProps) => {
  const { formatMessage } = useIntl();

  const getLimit = () => {
    if (multiplicity === ChildClassMultiplicity.ZERO_OR_MORE) {
      if (recordId) {
        return false; //there is no limit for number of child records in existing record
      } else {
        return ZERO_OR_MORE_MAX;
      }
    } else {
      return ZERO_OR_ONE_MAX;
    }
  };

  const limit = getLimit();
  const isLimitExceeded = !!limit && itemsCount >= limit;

  const classes = useNestedChildClassTableStyles({
    isDisabled: isLimitExceeded,
  });

  const tableHeaderResults = (
    <span className={classes.headerText}>
      {multiplicity === ChildClassMultiplicity.ZERO_OR_MORE &&
      !isLoadingData ? (
        <>
          {!selectedForFiltering && (
            <FormattedMessage
              id='nestedObjectRecords.results'
              defaultMessage='{total, plural, =0 {# results} =1 {# result} other {# results}}'
              values={{ total: itemsCount }}
            />
          )}

          {selectedForFiltering && (
            <FormattedMessage
              id='nestedObjectRecords.ofResults'
              defaultMessage='{filteredCount} of {total, plural, =0 {# results} =1 {# result} other {# results}}'
              values={{
                filteredCount: itemsCount,
                total: recordId ? totalCount : selectedIds?.length,
              }}
            />
          )}
        </>
      ) : (
        <span>&nbsp;</span>
      )}
    </span>
  );

  const onClearClick = () => {
    clearSelectedForFiltering();
  };

  const renderOption = useCallback<RenderOption>(
    ({ is_child, value, text }, searchValue) => {
      const isChild = is_child || (selectedIds ?? []).includes(Number(value)); //is_child is information from the backend, selectedIds is information from the frontend whether user has already selected this option

      return (
        <Option
          key={value}
          is_child={isChild.toString()} //is_child is a boolean, but the Option component expects a string
          value={value}
          title={text}
          className={classes.selectItem}
        >
          <div className={classes.optionContainer}>
            <div>
              {value.toString() !== text && (
                <BoldedPartOfText value={text} boldPhrase={searchValue} />
              )}
            </div>
            <div className={classes.bottomText}>
              <div>{value}</div>
              <div className={classes.disabledText}>
                {isChild && (
                  <FormattedMessage
                    id='nestedObjectRecords.alreadyAdded'
                    defaultMessage='Already added'
                  />
                )}
              </div>
            </div>
          </div>
        </Option>
      );
    },
    [
      classes.bottomText,
      classes.disabledText,
      classes.optionContainer,
      classes.selectItem,
      selectedIds,
    ]
  );

  const isSearchBoxDisabled = isLimitExceeded;
  const isCreateButtonDisabled =
    isSearchBoxDisabled ||
    !hasCreateEditViewEnabled ||
    uiPermissionsConfig.createButton !== childClassUICOmponentStates.ENABLED;

  const childClassTableAddtionalHeader = (
    <>
      {!readOnly && (
        <>
          <Tooltip
            shouldBeRendered={isSearchBoxDisabled}
            overlay={
              <FormattedMessage
                id='nestedObjectRecords.maxReached'
                defaultMessage='The maximum number of related records ({limit}) has been added.'
                values={{
                  limit,
                }}
              />
            }
          >
            <div className={classes.tooltip}>
              {uiPermissionsConfig.createButton !==
                childClassUICOmponentStates.HIDDEN && (
                <Tooltip
                  shouldBeRendered={
                    uiPermissionsConfig.createButton ===
                    childClassUICOmponentStates.DISABLED
                  }
                  overlay={
                    <FormattedMessage
                      id='nestedObjectRecords.noCreatePermission'
                      defaultMessage='You do not have permissions to create records.'
                      values={{
                        limit,
                      }}
                    />
                  }
                >
                  <ButtonPrimary
                    onClick={onCreateClick}
                    data-testid={CHILD_CLASS_COMPONENT_CREATE_BUTTON}
                    role='pagination-button'
                    icon={<PlusIcon size={10} />}
                    className={clsx(classes.buttonWrapper, {
                      [classes.buttonWrapperDisabled]: isCreateButtonDisabled,
                    })}
                    disabled={isCreateButtonDisabled}
                  >
                    <FormattedMessage
                      id='misc.create'
                      defaultMessage='Create'
                    />
                  </ButtonPrimary>
                </Tooltip>
              )}
              {uiPermissionsConfig.recordLookup !==
                childClassUICOmponentStates.HIDDEN && (
                <AutocompleteChildRecordsSelect
                  disabled={
                    !!selectedForFiltering ||
                    isSearchBoxDisabled ||
                    uiPermissionsConfig.recordLookup !==
                      childClassUICOmponentStates.ENABLED
                  }
                  placeholder={selectedForFiltering?.title}
                  selectMultiple={false}
                  autocompleteUrl={autocompleteUrl}
                  onChange={handleAutocompleteOptionChange}
                  renderOption={renderOption}
                />
              )}
              {selectedForFiltering && (
                <button
                  type='button'
                  data-testid={CHILD_CLASS_COMPONENT_CLEAR_BUTTON}
                  onClick={onClearClick}
                  className={classes.clearIconWrapper}
                >
                  <CloseIcon size={14} />
                </button>
              )}
            </div>
          </Tooltip>
        </>
      )}
    </>
  );

  const limitExceededNotification = () => (
    <>
      {!recordId &&
        multiplicity === ChildClassMultiplicity.ZERO_OR_MORE &&
        isLimitExceeded && (
          <div className={classes.limitNotification}>
            <LimitExceededNotification
              customMessage={formatMessage(
                {
                  id: 'nestedObjectRecords.creationMaxReached',
                  defaultMessage:
                    'The maximum number of child records ({limit}) has been added. You will be able to add more child records after the parent record is saved.',
                },
                {
                  limit,
                }
              )}
              itemsCount={itemsCount}
              itemsLimit={limit as number}
            />
          </div>
        )}
    </>
  );

  return {
    limitExceededNotification,
    tableHeaderResults,
    childClassTableAddtionalHeader,
  };
};

export default useTableHeader;
