import React, { useMemo } from 'react';
import clsx from 'clsx';
import {
  TABLE_BULK_SELECTION_HEADER_TESTID,
  TABLE_SORTABLE_RESIZER_TESTID,
} from 'utils/testIds';
import { BulkSelectionHeaderCheckbox } from './components/BulkSelectionHeaderCheckbox';
import { BulkSelectionCountMessage } from './components/BulkSelectionCountMessage';
import { BulkSelectionLimitAlert } from './components/BulkSelectionLimitAlert';
import { BulkSelectionActionButton } from './components/BulkSelectionActionButton/BulkSelectionActionButton';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { TABLE_HEAD_ROW_ID } from 'utils/elementsIds';
import { ACTIONS_COLUMN_WIDTH } from '../../../../CatalystTable.consts';
import { FormattedMessage } from 'react-intl';
import { calculateMinimumHeaderWidth } from './utils';
import useGlobalStyle from 'hooks/useGlobalStyle';
import { useHasVerticalScroll } from 'components/CatalystTable/hooks/useHasVerticalScroll';
import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import { useTableContext } from 'components/CatalystTable/contexts/TableContext';
import { useTableRowContext } from 'components/CatalystTable/contexts/TableRowContext';
import { useCatalystTableStyles } from 'components/CatalystTable/CatalystTable.styles';
import { TableHeader } from './components/TableHeader';
import { useTableHeadersContext } from 'components/CatalystTable/components/Table/contexts/TableHeadersContext';
import { useTableStyles } from '../../Table.styles';

export const TableHeaderGroups = () => {
  const {
    rows,
    totalColumnsWidth,
    columnResizing,
    headerGroups,
    dragAndDrop,
  } = useTableHeadersContext();

  const {
    onColumnDragStart,
    onDragUpdate,
    onDragEnd,
    getItemStyle,
  } = dragAndDrop;

  const hasScrollY = useHasVerticalScroll();
  const { density, onActionCellClick } = useTableRowContext();

  const {
    withBordersAroundTable,
    fullWidth: isFullWidth,
    isVisibleBulkSelectionColumn,
    bulkSelectionList,
  } = useTableContext();

  const globalStyles = useGlobalStyle();
  const sharedStyles = useCatalystTableStyles({
    ACTIONS_COLUMN_WIDTH,
  });
  const styles = useTableStyles({
    withBordersAroundTable,
    hasScrollY,
    fullWidth: isFullWidth,
    density: density ?? TableDensity.Default,
  });

  const currentRowsIds = useMemo(
    () =>
      rows.map(({ id, values, ...row }) => {
        const originalRow = (row.original as MappedObject<any>) || {};
        return originalRow.id !== undefined
          ? Number(originalRow.id)
          : Number(values.id || id);
      }),
    [rows]
  );

  const minWidth = calculateMinimumHeaderWidth(
    totalColumnsWidth,
    onActionCellClick !== undefined,
    isVisibleBulkSelectionColumn
  );

  return (
    <div
      className={clsx(styles.tableHeader, {
        [globalStyles.disabledTextHighlight]: !!columnResizing.isResizingColumn,
      })}
    >
      {headerGroups.map(headerGroup =>
        bulkSelectionList.length > 0 ? (
          <div
            {...headerGroup.getHeaderGroupProps({
              style: { minWidth },
            })}
            className={styles.tableBulkSelectionHead}
            data-testid={TABLE_BULK_SELECTION_HEADER_TESTID}
          >
            <div className={sharedStyles.flexAlignCenter}>
              <BulkSelectionHeaderCheckbox {...{ currentRowsIds }} />
              <BulkSelectionCountMessage />
              <BulkSelectionLimitAlert />
            </div>
            <BulkSelectionActionButton />
          </div>
        ) : (
          <DragDropContext
            key={headerGroup.getHeaderGroupProps().key}
            onDragStart={onColumnDragStart}
            {...{ onDragUpdate, onDragEnd }}
          >
            <Droppable droppableId='droppable' direction='horizontal'>
              {droppableProvided => (
                <div
                  {...headerGroup.getHeaderGroupProps({
                    style: {
                      width: 'var(--table-row-width)',
                    },
                  })}
                  ref={droppableProvided.innerRef}
                  className={styles.tableHead}
                  id={TABLE_HEAD_ROW_ID}
                >
                  {isVisibleBulkSelectionColumn && (
                    <BulkSelectionHeaderCheckbox {...{ currentRowsIds }} />
                  )}
                  {headerGroup.headers.map((column, index) => (
                    <TableHeader
                      key={column.id}
                      column={column}
                      headerGroup={headerGroup}
                      index={index}
                      getItemStyle={getItemStyle}
                    />
                  ))}
                  {onActionCellClick && (
                    <div
                      className={clsx([
                        sharedStyles.tableCell,
                        styles.tableHeadCell,
                      ])}
                      style={{
                        minWidth: ACTIONS_COLUMN_WIDTH,
                        marginLeft: 'auto',
                      }}
                      data-testid={`${TABLE_SORTABLE_RESIZER_TESTID}actions`}
                    >
                      <div className={sharedStyles.flexAlignCenter}>
                        <span>
                          <FormattedMessage
                            id='misc.actions'
                            defaultMessage='Actions'
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )
      )}
    </div>
  );
};
