import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAddColumnStyles = createUseStyles((theme: ThemeState) => {
  return {
    columnIndicator: {
      color: theme['error-color-pink'],
      transition: 'color 0.2s ease-in-out',
      '&:hover': {
        color: theme['error-color-dark-pink'],
      },
    },
    disabledColumnIndicator: {
      color: theme['error-color-pink'],
      cursor: 'default',
    },
    popoverWrapper: {
      position: 'absolute',
      top: -10,
      right: -11,
      zIndex: 30,
    },
    pin: { padding: 0 },
  };
});
