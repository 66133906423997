import { ID_FIELD, NAME_FIELDS } from '../constants';
import { FieldWithAliasAndLabel } from '../types';

export const sortColumnFields = <TField extends FieldWithAliasAndLabel>(
  a: TField,
  b: TField
) => {
  if (a.alias === ID_FIELD) {
    return -1;
  }

  if (b.alias === ID_FIELD) {
    return 1;
  }

  if (NAME_FIELDS.includes(a.alias)) {
    return -1;
  }

  if (NAME_FIELDS.includes(b.alias)) {
    return 1;
  }

  if (a.label < b.label) {
    return -1;
  }

  if (a.label > b.label) {
    return 1;
  }

  return 0;
};
