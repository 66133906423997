import { AvatarProps } from 'antd/lib/avatar';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseAvatarStylesProps = {
  isInteractive?: boolean;
  size?: AvatarProps['size'];
};

const useAvatarStyles = createUseStyles((theme: ThemeState) => ({
  countAvatar: {
    border: `1px solid ${theme['primary-color']}`,
    backgroundColor: '#fff',
    color: theme['primary-color'],
    fontSize: ({ size }: UseAvatarStylesProps = {}) =>
      size === 'small' ? 12 : 'initial',
    width: ({ size }: UseAvatarStylesProps = {}) =>
      size === 'small' ? 25 : 'initial',
    height: ({ size }: UseAvatarStylesProps = {}) =>
      size === 'small' ? 25 : 'initial',
  },
  avatarsGroupWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& .ant-avatar': {
      marginRight: 1,
    },
  },
  popover: {
    '& .ant-popover-arrow': {
      display: 'none',
    },
    '& .ant-popover-inner': {
      borderRadius: 5,
    },
    '& .ant-popover-inner-content': {
      padding: 10,
    },
  },
  avatar: ({ isInteractive }: UseAvatarStylesProps) => {
    return {
      cursor: isInteractive ? 'pointer' : 'unset',
      pointerEvents: isInteractive ? 'all' : 'none',
    };
  },
  moreItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

    width: '100%',
    maxHeight: 240,
    overflow: 'auto',
  },
  listItem: {
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',

    '&>p': {
      margin: [0, 10],
    },
  },
}));

export default useAvatarStyles;
