import { useFormikContext } from 'formik';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { TaskFormType } from '../../../types';
import { useIntl } from 'react-intl';
import {
  DEFAULT_GROUP_ASSIGNEES_LIMIT,
  DEFAULT_USER_ASSIGNEES_LIMIT,
} from 'utils/consts';

/**
 * Handles logic related to assignees limits.
 */
export const useTaskFormAssigneesLimits = (
  usersLimit: number | undefined,
  groupsLimit: number | undefined
) => {
  const intl = useIntl();

  const { values } = useFormikContext<TaskFormType>();

  const selectedUsers = values[TaskFormField.Assignees].users;
  const isUsersLimitReached = usersLimit
    ? selectedUsers.length >= usersLimit
    : selectedUsers.length >= DEFAULT_USER_ASSIGNEES_LIMIT;
  const usersLimitReachedText = intl.formatMessage(
    {
      id: 'tasksPanel.form.usersLimitReached',
      defaultMessage: 'Maximum of {limit} user assignees has been reached.',
    },
    {
      limit: usersLimit ?? DEFAULT_USER_ASSIGNEES_LIMIT,
    }
  );

  const selectedGroups = values[TaskFormField.Assignees].groups;
  const isGroupsLimitReached = groupsLimit
    ? selectedGroups.length >= groupsLimit
    : selectedGroups.length >= DEFAULT_GROUP_ASSIGNEES_LIMIT;
  const groupsLimitReachedText = intl.formatMessage(
    {
      id: 'tasksPanel.form.groupsLimitReached',
      defaultMessage:
        'Maximum of {limit} user group assignees has been reached.',
    },
    {
      limit: groupsLimit ?? DEFAULT_GROUP_ASSIGNEES_LIMIT,
    }
  );

  return {
    areBothLimitsReached: isGroupsLimitReached && isUsersLimitReached,
    groupsLimitReachedText,
    isAnyLimitReached: isGroupsLimitReached || isUsersLimitReached,
    isGroupsLimitReached,
    isUsersLimitReached,
    usersLimitReachedText,
  };
};
