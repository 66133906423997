import { createUseStyles } from 'react-jss';

export const useToastIconStyles = createUseStyles({
  icon: {
    flexShrink: 0,

    height: '2rem',
    width: '2rem',
  },
});
