import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { getAlertColors } from './utils';
import { AlertProps } from 'antd/lib/alert';

export type AlertStylesProps = {
  type: AlertProps['type'];
  isLarge: boolean;
};

export const useAlertStyles = createUseStyles((theme: ThemeState) => {
  const iconSize = '1.5rem';
  const getPadding = (isLarge: boolean) => (isLarge ? '16px' : '8px');

  return {
    alert: ({ type, isLarge }: AlertStylesProps) => {
      const { color, backgroundColor } = getAlertColors(type, theme);

      return {
        display: 'flex',
        alignItems: 'flex-start',
        justifyItems: 'center',

        height: 'fit-content',
        padding: getPadding(isLarge),

        backgroundColor: backgroundColor,

        '& .anticon-close': {
          color: color,
        },

        '& .ant-alert-message': {
          display: 'inline-block',
          alignSelf: 'center',

          fontSize: '0.75rem',
          color: color,
        },
      };
    },
    alertIcon: ({ isLarge }: AlertStylesProps) => ({
      position: 'static',
      flexShrink: 0,
      marginRight: getPadding(isLarge), // we cannot use alert gap as we always have empty description span in the DOM.
      height: iconSize,
      width: iconSize,
    }),
  };
});
