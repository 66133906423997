import React from 'react';
import { AutoSaveErrorModalContextProvider } from 'contexts/AutoSaveErrorModalContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { FormattedMessage } from 'react-intl';
import { TaskForm } from './components/TaskForm';
import { useCreateTaskStyles } from './CreateTask.styles';
import { useRecordNotFoundOnTaskCreation } from './hooks';

export const CreateTask = () => {
  const styles = useCreateTaskStyles();

  const {
    selectedResource: { record: { identifier = undefined } = {} } = {},
  } = useSelectedResourceContext();
  const {
    modalTitle,
    modalSubtitle,
    onModalClose,
  } = useRecordNotFoundOnTaskCreation();

  return (
    <div className={styles.panelWrapper}>
      <header className={styles.panelHeader}>
        <div className={styles.identifier}>{identifier}</div>
        <div className={styles.panelTitle}>
          <FormattedMessage
            id='tasksPanel.createTask'
            defaultMessage='Create task'
          />
        </div>
      </header>

      <AutoSaveErrorModalContextProvider
        title={modalTitle}
        subtitle={modalSubtitle}
        onCloseErrorModal={onModalClose}
      >
        <TaskForm />
      </AutoSaveErrorModalContextProvider>
    </div>
  );
};
