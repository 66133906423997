import SupportedLanguages from 'utils/Enums/SupportedLanguages';
import { TableState } from 'react-table';
import { FilterMap, QuickFilterMap } from 'store/actions/filtersActions';
import { TableDensity } from 'components/CatalystTable/types/tableDensity';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { ViewMode } from 'contexts/types';
import { ActiveTabGroups } from 'components/lib/FlexLayout/types';
import { SupportedDateFormats } from 'utils/Enums/SupportedDateFormats';
import { NumbersSeparator } from 'utils/Enums/NumbersSeparator';

export interface TableUrlParams {
  pageSize: number;
  currentPage: number;
  sort: string;
  sortById?: { [key: string]: string }; // keep column names for sorting between object classes
  isInfinite: boolean | undefined;
}

export type ExtendedTableState = MappedObject<
  Partial<TableState<object>>,
  string | number
>;

export type GeneralPreferences = {
  language: SupportedLanguages;
  isSidebarCollapsed: boolean;
  timezone: string | undefined;
  dateFormat: SupportedDateFormats;
  decimalFormat: NumbersSeparator;
  thousandSeparator: NumbersSeparator;
  formBuilderToolboxWidth: number;
  isNavigationMenuPinned: boolean;
  selectedClassId: string | undefined;
};

export interface LayoutPreferences {
  flexLayoutJSONModel: Object;
  panels: {
    openedPanels:
      | MappedObject<boolean, FlexLayoutWindows | string>[]
      | undefined;
    activePanels: MappedObject<FlexLayoutWindows, ActiveTabGroups>;
  };
  tasks: {
    taskPropertiesOpen: boolean;
  };
}

export interface TablePreferences {
  tables: MappedObject<Partial<TableState<object>> | ExtendedTableState>;
}

export interface TableLayoutPreferences {
  selectedColumns?: MappedObject<string[] | undefined>;
  tableDensity: TableDensity | undefined;
  tablesViewMode: Partial<MappedObject<ViewMode, CatalystTableType>>;
  tablesState: MappedObject<TableUrlParams, CatalystTableType>;
}

export interface FilterPreferences {
  filters: FilterMap;
  quickFilters: QuickFilterMap;
}

export enum PreferencesTypes {
  GeneralPreferences = 'General',
  LayoutPreferences = 'Layout',
  TablePreferences = 'Table',
  TableLayoutPreferences = 'TableLayout',
  FilterPreferences = 'Filter',
}

export type PreferencesDataState = {
  [PreferencesTypes.GeneralPreferences]: GeneralPreferences;
  [PreferencesTypes.LayoutPreferences]: LayoutPreferences;
  [PreferencesTypes.TablePreferences]: TablePreferences;
  [PreferencesTypes.TableLayoutPreferences]: TableLayoutPreferences;
  [PreferencesTypes.FilterPreferences]: FilterPreferences;
};
