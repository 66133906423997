import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { showWarningToast } from 'features/toasts/utils/showWarningToast';

export const showSomeGroupsHaveBeenDeletedToast = () => {
  showWarningToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.warning',
      defaultMessage: 'Warning!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage({
      id: 'tasksPanel.someGroupsHaveBeenDeleted',
      defaultMessage:
        'None of the selected user groups could be assigned to the task because some of them have been deleted.',
    }),
  });
};
