import { Reducer } from 'redux';
import {
  CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES,
  SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS,
  SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING,
  SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS,
  SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF,
  SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES,
  SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS,
  SET_OBJECT_CLASS_SELECTED_PERMISSION_SET,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUPS,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUPS_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS,
  SET_OBJECT_CLASS_PERMISSION_SET_USER_OPTIONS_FETCHING,
  SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS_FETCHING,
} from 'store/constants/objectClassPermissions.consts';
import { USERS_LIST_AUTOCOMPLETE } from 'utils/endpoints';
import { RootAction } from '.';
import { ObjectClassPermissionsState } from './types/objectClassPermissions';

const initialState: ObjectClassPermissionsState = {
  isRemovingHimself: false,
  selectedPermissionSet: undefined,
  classPermissions: {
    owners: {
      fetching: false,
      list: [],
      avatars: [],
      totalCount: 0,
      options: {
        restrictions: {
          limit_items: undefined,
        },
        batch: {
          autocomplete: USERS_LIST_AUTOCOMPLETE,
          required: false,
          type: 'set',
        },
      },
    },
    assignees: {},
    groups: {},
  },
};

const objectClassPermissionsReducer: Reducer<
  ObjectClassPermissionsState,
  RootAction
> = (state = initialState, action) => {
  switch (action.type) {
    case SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          owners: {
            ...state.classPermissions.owners,
            ...action.payload,
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_OWNERS_FETCHING:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          owners: {
            ...state.classPermissions.owners,
            fetching: action.payload,
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSIONS_CLASS_OWNERS_OPTIONS:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          owners: {
            ...state.classPermissions.owners,
            options: {
              ...state.classPermissions.owners.options,
              ...action.payload,
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSIONS_IS_REMOVING_HIMSELF:
      return {
        ...state,
        isRemovingHimself: action.payload,
      };
    case SET_OBJECT_CLASS_SELECTED_PERMISSION_SET:
      return {
        ...state,
        selectedPermissionSet: action.payload,
      };
    case CLEAR_OBJECT_CLASS_PERMISSION_SETS_ASSIGNEES:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          assignees: {},
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          assignees: {
            ...state.classPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.assignees[
                action.payload.permissionSetId
              ] ?? {}),
              ...action.payload,
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_GROUPS:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          groups: {
            ...state.classPermissions.groups,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.groups[
                action.payload.permissionSetId
              ] ?? {}),
              ...action.payload,
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_OPTIONS:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          assignees: {
            ...state.classPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.assignees[
                action.payload.permissionSetId
              ] ?? {}),
              options: {
                ...action.payload.options,
              },
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          groups: {
            ...state.classPermissions.groups,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.groups[
                action.payload.permissionSetId
              ] ?? {}),
              options: {
                ...action.payload.options,
              },
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_ASSIGNEES_FETCHING:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          assignees: {
            ...state.classPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.assignees[
                action.payload.permissionSetId
              ] ?? {}),
              fetching: action.payload.fetching,
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_GROUPS_FETCHING:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          groups: {
            ...state.classPermissions.groups,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.groups[
                action.payload.permissionSetId
              ] ?? {}),
              fetching: action.payload.fetching,
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_USER_OPTIONS_FETCHING:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          assignees: {
            ...state.classPermissions.assignees,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.assignees[
                action.payload.permissionSetId
              ] ?? {}),
              areOptionsFetching: action.payload.isFetching,
            },
          },
        },
      };
    case SET_OBJECT_CLASS_PERMISSION_SET_GROUP_OPTIONS_FETCHING:
      return {
        ...state,
        classPermissions: {
          ...state.classPermissions,
          groups: {
            ...state.classPermissions.groups,
            [action.payload.permissionSetId]: {
              ...(state.classPermissions.groups[
                action.payload.permissionSetId
              ] ?? {}),
              areOptionsFetching: action.payload.isFetching,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default objectClassPermissionsReducer;
