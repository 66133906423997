export interface MiscState {
  apiVersion: string | undefined;
  historyVersion: string;
  isAboutModalVisible: boolean;
  isErrorDetailsModalVisible: boolean;
  errorDetailsModalPayload: unknown;
}

export enum ApiHistoryType {
  NA = 'N/A',
}

export interface ApiVersionPayload {
  version: string;
  historyVersion: string;
}
