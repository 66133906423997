import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type SearchIconProps = {
  disabled?: boolean;
};

const useAutocompleteSelectStyles = createUseStyles((theme: ThemeState) => {
  return {
    autocompleteSelect: {
      '& .ant-select-selector': {
        paddingTop: 4,
        '& span:not(.ant-select-selection-search)': {
          maxWidth: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    selectionItem: {
      padding: '2px 8px',
      backgroundColor: theme['primary-color-light'],
      color: theme['primary-color'],
      marginBottom: 4,
      '&:hover': {
        opacity: 1,
      },
    },
    wrapper: { fontSize: 14 },
    autocompleteWrapper: { width: '100%' },
    selectComponentWrapper: {
      position: 'relative',
    },
    searchIcon: {
      color: ({ disabled }: SearchIconProps) =>
        disabled ? theme['disabled-switch-gray'] : theme['primary-color'],
    },
  };
});

export default useAutocompleteSelectStyles;
