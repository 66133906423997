import { useSelector } from 'react-redux';
import {
  getObjectClassPermissionSetAssigneesAvatars,
  getObjectClassSelectedPermissionSet,
} from 'store/selectors/objectClassPermissionsSelectors';

export const useClassPermissionUserAvatars = () => {
  const selectedPermissionSet = useSelector(
    getObjectClassSelectedPermissionSet
  );

  const selectedPermissionSetId = selectedPermissionSet?.id?.toString() ?? '';

  const userAvatars =
    useSelector(
      getObjectClassPermissionSetAssigneesAvatars(selectedPermissionSetId)
    ) ?? [];

  return { userAvatars };
};
