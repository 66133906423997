import { createContext, useContext } from 'react';
import { TableHeadersContextType } from './TableHeadersContext.types';

export const TableHeadersContext = createContext<TableHeadersContextType | null>(
  null
);

export const useTableHeadersContext = () => {
  const context = useContext(TableHeadersContext);

  if (!context) {
    throw new Error(
      'useTableHeadersContext has null context. Did you wrap your component in TableHeadersContextProvider?'
    );
  }

  return context;
};
