import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useUsersGroupsListItemSelectAndDeselectStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      disabled: {
        '& .ant-avatar': {
          background: theme['border-color-base'],
        },
        '& div:not(:first-child)': {
          color: `${theme['disabled-switch-gray']} !important`,
          '& mark': {
            color: theme['disabled-switch-gray'],
          },
        },
        cursor: 'not-allowed',
      },
      checkmarkIcon: {
        color: theme['primary-color'],
      },
    };
  }
);
