/**
 * This function acts as a safer option to the traditional JSON.stringify
 * which has problems with correct typing and throwing errors.
 *
 * Sample explanatory article:
 * https://evanhahn.com/when-stringify-doesnt-return-a-string/
 */

export const stringifyJson = (
  value: unknown,
  space: number | string = 4
): string | undefined => {
  try {
    return JSON.stringify(value, null, space);
  } catch {
    return undefined;
  }
};
