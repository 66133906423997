import { ActionCellProps } from 'components/CatalystTable/types/actionCellProps';
import { OptionData } from 'utils/types/selectInput.types';
export interface ActionsMenuProps extends ActionCellProps {
  onActionMenuOptionClick: (key: RowActions) => void;
  hasCreateEditViewEnabled: boolean;
  tableId?: string;
}

export enum RowActions {
  RemoveChild = 'removeChild',
  Open = 'open',
}

export type UseChildClassTableActionsParams = {
  identifier?: string;
  recordId?: string | number;
  classId: string;
  label?: string;
  clearSelectedForFiltering: () => void;
  hasCreateEditViewEnabled: boolean;
  selectedForFiltering: OptionData | undefined;
};
