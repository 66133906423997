import { useMemo, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { apiCall } from 'utils/api';
import { isSuccess } from 'utils/apiUtils';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { generatePath } from 'react-router-dom';
import { ObjectClassFieldMap, ObjectClassPropertyFieldMap } from './types';
import { OBJECT_CLASS_DETAILS_FIELDS } from 'utils/endpoints';
import { ChildClassColumns } from 'components/formBuilder/formBuilder/types';
import { FIELD_PREFIX } from 'utils/consts';
import { FormBuilderField } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import { GetResponse } from 'utils/types';

// the record props fields seem stable so instead of fetching each time, use hard coded vals.
// code below implemented to make it simple to change to fetching from api if ever needed.
export const useRecordPropFieldsMap = () => {
  const intl = useIntl();
  const objectRecrdPropFields = useMemo(() => {
    const translations: MappedObject<string, string> = {
      id: 'ID',
      created_at: intl.formatMessage({
        id: 'objectRecords.created_at',
        defaultMessage: 'Created',
      }),
      created_by: intl.formatMessage({
        id: 'objectRecords.created_by',
        defaultMessage: 'Created by',
      }),
      modified_at: intl.formatMessage({
        id: 'objectRecords.modified_at',
        defaultMessage: 'Last edited',
      }),
      modified_by: intl.formatMessage({
        id: 'objectRecords.modified_by',
        defaultMessage: 'Last edited by',
      }),
    };
    return [
      {
        alias: 'id',
        sort_ok: false, // set to true once child class table in form implements sorting
      },
      {
        alias: 'created_at',
        sort_ok: false, // set to true once child class table in form implements sorting
      },
      {
        alias: 'created_by',
        sort_ok: false,
      },
      {
        alias: 'modified_at',
        sort_ok: false, // set to true once child class table in form implements sorting
      },
      {
        alias: 'modified_by',
        sort_ok: false,
      },
    ].reduce((acc, field) => {
      return {
        ...acc,
        [field.alias]: {
          ...field,
          label: translations[field.alias],
        },
      };
    }, {} as ObjectClassPropertyFieldMap);
  }, [intl]);
  return objectRecrdPropFields;
};

export const useRecordFieldsMap = (
  childClassID: string,
  columns: ChildClassColumns
) => {
  const [selectedObjectrecordFields, setSelectedObjectrecordFields] = useState<
    MappedObject<FormBuilderField, string>
  >({});
  const [totalFieldsUnfiltered, setTotalFieldsUnfiltered] = useState(0);
  const fetchClassFields = async () => {
    if (!childClassID) {
      return;
    }
    const fieldPrefixRegex = new RegExp(`^${FIELD_PREFIX}`);
    const fieldIdsToFetch = columns.reduce((acc, col) => {
      if (col.id.startsWith(FIELD_PREFIX)) {
        const id = col.id.replace(fieldPrefixRegex, '');
        return [...acc, id];
      }
      return acc;
    }, [] as string[]);

    const queryString =
      '?id__in=' +
      fieldIdsToFetch.reduce<string>(
        (wholeString, currentValue) => wholeString + `${currentValue},`,
        ''
      );
    const path = generatePath(OBJECT_CLASS_DETAILS_FIELDS, {
      id: childClassID,
    });
    try {
      const { data, status } = await apiCall.get<GetResponse<FormBuilderField>>(
        path + queryString
      );
      if (isSuccess(status)) {
        setSelectedObjectrecordFields(
          data.results.reduce((acc, f) => {
            return { ...acc, [`${FIELD_PREFIX}${f.id}`]: f };
          }, {} as ObjectClassFieldMap)
        );
        setTotalFieldsUnfiltered(data.total_count);
      }
    } catch (error) {
      showUnhandledErrorToast(error);
    }
  };

  useEffect(() => {
    fetchClassFields();
    // we only ever want to fetch the class fields once -
    // not everytime we update the columns due to add, re-order or resize!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    selectedObjectrecordFields,
    setSelectedObjectrecordFields,
    totalFieldsUnfiltered,
  };
};
useRecordFieldsMap.displayName = 'useRecordFieldsMap';
