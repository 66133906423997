import React from 'react';
import { FormattedMessage } from 'react-intl';
import { usePropertyFieldStyles } from './PropertyField.styles';
import { PropertyFieldProps } from './PropertyField.types';

export const PropertyField = ({ value }: PropertyFieldProps) => {
  const styles = usePropertyFieldStyles();

  return (
    <span className={styles.disabledText}>
      {value ?? (
        <FormattedMessage
          id='formBuilder.visibleOnlyWhenEditing'
          defaultMessage='(visible only when editing)'
        />
      )}
    </span>
  );
};
