import { ColumnsMetadata } from 'utils/types/api/table.types';
import { UserMe } from 'utils/types/api/users.types';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { OWNERSHIP_PREDICATE_LABELS } from '../consts/ownershipPredicateLabels';
import { getOwnerPredicate } from './getOwnerPredicate';
import { TasksQuickFilterOwnership } from '../types/tasksQuickFilterOwnership';

const { Assignees } = TasksQuickFilterOwnership;

export const getAssigneePredicate = (
  columns: MappedObject<ColumnsMetadata, string>,
  user: UserMe
) => {
  return [
    {
      column: columns[Assignees].alias,
      type: columns[Assignees].type,
      predicates: columns[Assignees].predicates ?? [],
      label: OWNERSHIP_PREDICATE_LABELS[Assignees],
      value: {
        predicateKey: 'containsall',
        predicateValues: {
          value: {
            users: [
              {
                id: parseInt(user.id),
                username: user.username,
                first_name: user.first_name,
                last_name: user.last_name,
                company_name: user.company_name,
                account_type: user.account_type,
                type: UserOrGroupSelectOptionType.User as const,
                value: user.id,
                text: `${user.first_name} ${user.last_name} (${user.username})`,
              },
            ],
            groups: [],
          },
        },
        predicateArgs: ['value'],
        isMultipleSelection: true,
      },
    },
    ...getOwnerPredicate(columns, user, 'not_exact'),
  ];
};
