export const SET_API_VERSION = 'SET_API_VERSION';
export const SET_IS_ABOUT_MODAL_VISIBLE = 'SET_IS_ABOUT_MODAL_VISIBLE';
export const SHOW_ERROR_DETAILS_MODAL = 'SHOW_ERROR_DETAILS_MODAL';
export const CLOSE_ERROR_DETAILS_MODAL = 'CLOSE_ERROR_DETAILS_MODAL';

export const INITIAL_TABLE_STATE = {
  fetching: false,
  byId: {},
  allIds: [],
  total: 0,
  filtered: 0,
  columns: {},
  selectedRow: undefined,
  restrictions: { limit_items: undefined },
  customOffset: 0,
};

export const INITIAL_NESTED_TABLE_STATE = {
  NO_ID: {
    fetching: false,
    byId: {},
    allIds: [],
    total: 0,
    filtered: 0,
    columns: {},
    restrictions: { limit_items: undefined },
    customOffset: 0,
    selectedColumns: [],
  },
};
