import { createUseStyles } from 'react-jss';

export const useDisabledButtonTooltipStyles = createUseStyles({
  tooltip: {
    width: 'fit-content',
  },
  buttonWrapper: {
    position: 'relative',

    width: 'fit-content',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'not-allowed',
  },
});
