import { CustomTableGroupKeys } from 'components/CatalystTable/contexts/TableContext/types/customTableGroupKeys';
import { CustomDataGroupsType } from 'components/CatalystTable/contexts/TableContext/types/customDataGroupsType';
import { ColumnsMetadata } from 'utils/types/api/table.types';

export const generateAdditionalQueryParams = (
  currentColumns: MappedObject<ColumnsMetadata, string>,
  hiddenColumns: string[],
  customDataGroups: CustomDataGroupsType | undefined
) => {
  if (!Object.keys(currentColumns).length) {
    return undefined;
  }

  if (!customDataGroups) {
    return '';
  }

  const groupedFields = Object.values(currentColumns).reduce(
    (groups, { alias, groupKey }) => {
      if (!groupKey) return groups;

      if (!hiddenColumns.includes(alias)) {
        if (groupKey in groups) {
          groups[groupKey].push(alias);
        } else {
          groups[groupKey] = [alias];
        }
      }

      return groups;
    },
    {} as MappedObject<string[]>
  );

  return (Object.keys(groupedFields) as CustomTableGroupKeys[]).reduce(
    (tmpQueryParams, groupKey) => {
      const addQueryParameters = customDataGroups[groupKey]?.addQueryParameters;

      if (!addQueryParameters) return tmpQueryParams;

      return `${tmpQueryParams}&${addQueryParameters(groupedFields[groupKey])}`;
    },
    ''
  );
};
