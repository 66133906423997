import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const showSomeUsersHaveBeenDeletedToast = (resourceName: string) => {
  showErrorToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.error',
      defaultMessage: 'Error!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage(
      {
        id: 'misc.someUsersHaveBeenDeletedToast',
        defaultMessage:
          'None of the selected users could be assigned to {name} because some of them have been deleted.',
      },
      {
        name: resourceName,
      }
    ),
  });
};
