import React, { useMemo } from 'react';
import { TaskOptionsProviderProps } from './TaskOptionsContext.types';
import { TaskOptionsContext } from './TaskOptionsContext';
import { useTaskOptions } from './hooks/useTaskOptions';
import { useTaskUserAssigneeOptions } from './hooks/useTaskUserAssigneeOptions';
import { useTaskGroupAssigneeOptions } from './hooks/useTaskGroupAssigneeOptions';

export const TaskOptionsProvider = ({ children }: TaskOptionsProviderProps) => {
  const {
    options: taskOptions,
    areOptionsLoading: areTaskOptionsLoading,
    optionsError: taskOptionsError,
  } = useTaskOptions();
  const {
    options: taskUserAssigneeOptions,
    areOptionsLoading: areTaskUserAssigneeOptionsLoading,
    optionsError: taskUserAssigneeOptionsError,
  } = useTaskUserAssigneeOptions();
  const {
    options: taskGroupAssigneeOptions,
    areOptionsLoading: areTaskGroupAssigneeOptionsLoading,
    optionsError: taskGroupAssigneeOptionsError,
  } = useTaskGroupAssigneeOptions();

  const contextValue = useMemo(
    () => ({
      areTaskGroupAssigneeOptionsLoading,
      areTaskOptionsLoading,
      areTaskUserAssigneeOptionsLoading,
      taskGroupAssigneeOptions,
      taskGroupAssigneeOptionsError,
      taskOptions,
      taskOptionsError,
      taskUserAssigneeOptions,
      taskUserAssigneeOptionsError,
    }),
    [
      areTaskGroupAssigneeOptionsLoading,
      areTaskOptionsLoading,
      areTaskUserAssigneeOptionsLoading,
      taskGroupAssigneeOptions,
      taskGroupAssigneeOptionsError,
      taskOptions,
      taskOptionsError,
      taskUserAssigneeOptions,
      taskUserAssigneeOptionsError,
    ]
  );

  return (
    <TaskOptionsContext.Provider value={contextValue}>
      {children}
    </TaskOptionsContext.Provider>
  );
};
