import { orderBy } from 'lodash';
import { SelectUserOption } from 'utils/types/selectInput.types';

/**
 * Sorts users array alphabetically by user full name.
 *
 * @param users Array of users to be sorted.
 *
 * @returns Alphabetically sorted users array.
 */
export const sortUsers = (users: SelectUserOption[]) => {
  return orderBy(users, user => user.text.toLowerCase());
};
