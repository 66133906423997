import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useObjectClassFieldLabelCellStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      tag: {
        borderRadius: '2px',
        marginLeft: '8px',
        padding: '2px 8px 2px 8px',
        fontWeight: 600,
      },
      identifier: {
        color: theme['field-secondary-tag-font-color'],
        backgroundColor: theme['field-secondary-tag-bg-color'],
      },
    };
  }
);
