import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useObjectClassViewUsageData } from './useObjectClassViewUsageData';
import { UsageViewConfig } from 'components/ResourceUsagePanelView/types/usageViewConfig';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';
import { ObjClassRecordUsageView } from './components/ObjClassRecordUsageView';

export const useObjectClassViewUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: {
      num_of_records: numOfRecords = 0,
      sequences = [],
      object_models: relations = [],
      task_group_templates: taskGroupTemplates = [],
      task_templates: taskTemplates = [],
    } = {},
  } = useObjectClassViewUsageData(id);

  const displayConfig = useMemo<UsageViewConfig[]>(
    () => [
      {
        title: intl.formatMessage({
          id: 'misc.objectRecords',
          defaultMessage: 'Object records',
        }),
        type: UsageViewType.Custom,
        content: <ObjClassRecordUsageView numOfRecords={numOfRecords} />,
        shouldBeVisible: numOfRecords > 0,
      },
      {
        usage: taskTemplates,
        title: intl.formatMessage({
          id: 'misc.taskTemplates',
          defaultMessage: 'Task templates',
        }),
        type: UsageViewType.List,
      },
      {
        usage: taskGroupTemplates,
        title: intl.formatMessage({
          id: 'misc.taskGroups',
          defaultMessage: 'Task group templates',
        }),
        type: UsageViewType.List,
      },
      {
        usage: sequences,
        title: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
        type: UsageViewType.List,
      },
      {
        usage: relations,
        title: intl.formatMessage({
          id: 'misc.relations',
          defaultMessage: 'Relations',
        }),
        type: UsageViewType.List,
      },
    ],
    [
      intl,
      numOfRecords,
      taskTemplates,
      taskGroupTemplates,
      sequences,
      relations,
    ]
  );

  return { loading, error, displayConfig };
};
