import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusIcon } from 'components/Icon';
import { ButtonPrimary } from 'components/lib/Button';
import {
  TABLE_BULK_SELECTION_ACTION_ADD_BUTTON_TESTID,
  TABLE_BULK_SELECTION_ACTION_REMOVE_BUTTON_TESTID,
} from 'utils/testIds';
import { useBulkSelectionActionButtonStyles } from './BulkSelectionActionButton.styles';
import { useBulkSelectionActionButton } from './hooks/useBulkSelectionActionButton';
import { ActionButtonType } from './types/actionButtonType';

export const BulkSelectionActionButton = () => {
  const styles = useBulkSelectionActionButtonStyles();
  const {
    onClickAdd,
    onClickRemove,
    typeOfAction,
  } = useBulkSelectionActionButton();

  if (typeOfAction === undefined) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {typeOfAction === ActionButtonType.Adding ? (
        <ButtonPrimary
          icon={<PlusIcon size={10} />}
          onClick={onClickAdd}
          data-testid={TABLE_BULK_SELECTION_ACTION_ADD_BUTTON_TESTID}
          className={styles.button}
        >
          <FormattedMessage
            id='misc.addAsMembers'
            defaultMessage='Add as members'
          />
        </ButtonPrimary>
      ) : (
        <ButtonPrimary
          onClick={onClickRemove}
          data-testid={TABLE_BULK_SELECTION_ACTION_REMOVE_BUTTON_TESTID}
          className={styles.button}
          danger
        >
          <FormattedMessage
            id='misc.removeFromGroup'
            defaultMessage='Remove from group'
          />
        </ButtonPrimary>
      )}
    </div>
  );
};
