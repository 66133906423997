import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useCustomValueDisplayEmailStyles = createUseStyles(
  (theme: ThemeState) => {
    return {
      link: {
        color: theme['link-color'],
        textDecoration: 'underline',
      },
    };
  }
);
