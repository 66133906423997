import { createUseStyles } from 'react-jss';

export const useUnhandledErrorToastContentStyles = createUseStyles({
  contentWrapper: {
    display: 'flex',
    gap: '12px',
  },
  title: {
    marginBottom: 0,

    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: 'inherit',
    lineHeight: '1.5',
    wordBreak: 'break-word',
  },
  subtitle: {
    marginBottom: 0,

    fontSize: '0.75rem',
    color: 'inherit',
    lineHeight: '1.5',
    wordBreak: 'break-word',
  },
  button: {
    marginTop: '8px',
    padding: 0,

    textDecoration: 'underline',
    backgroundColor: 'transparent',

    outline: 'none',
    border: 'none',
    transition: '0.3s',

    '&:hover, &:focus': {
      opacity: '0.8',
      outline: 'none',
    },
  },
});
