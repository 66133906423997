import React from 'react';
import { TreeUsageViewProps } from './TreeUsageView.types';
import { useTreeUsageViewStyles } from './TreeUsageView.styles';

export const TreeUsageView = ({ usage, depth = 0 }: TreeUsageViewProps) => {
  const styles = useTreeUsageViewStyles({ depth });

  return (
    <ul className={styles.list}>
      {usage.map(item => (
        <li key={item.id}>
          {item.name}

          {item.children?.length && (
            <TreeUsageView usage={item.children} depth={depth + 1} />
          )}
        </li>
      ))}
    </ul>
  );
};
