import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import NoDataFoundMessage from 'components/NoDataFoundMessage';
import { FormMode } from 'utils/Enums/FormMode';
import {
  ClassFieldDetailLocationState,
  ClassFieldFormWrapperProps,
} from './types';
import {
  useLimitExceededModal,
  useClassFieldForm,
  useClassFieldOptions,
  useNoExistsFieldModal,
} from './hooks';
import ErrorComponent from 'components/ErrorComponent';
import { Loader } from 'components/lib/Loader';
import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import clsx from 'clsx';
import ConfirmationModal from 'components/ConfirmationModal';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { useDispatch } from 'react-redux';
import { ClassFieldFormComponent } from './components/ClassFieldForm/ClassFieldFormComponent';
import { ClassFieldFormContextProvider } from '../ObjectClassForm/contexts/ClassFieldFormContext';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { ClassFieldForm } from './components/ClassFieldForm/types';

export const ClassFieldFormWrapper: React.FC<ClassFieldFormWrapperProps> = ({
  mode,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const styles = useClassFieldPropertiesStyles();

  const { state: { id } = {} } = useLocation<ClassFieldDetailLocationState>();

  const {
    externalWindowsMethods: { checkIfExternalWindowExists },
  } = useFlexLayoutContext();

  const isExternalOpen = useMemo(
    () =>
      checkIfExternalWindowExists(FlexLayoutWindows.ObjectClassEditField) ||
      checkIfExternalWindowExists(FlexLayoutWindows.ObjectClassAddField),
    [checkIfExternalWindowExists]
  );
  const isCreateMode = useMemo(() => mode === FormMode.Create, [mode]);
  const panelId = useMemo(
    () =>
      isCreateMode
        ? FlexLayoutWindows.ObjectClassAddField
        : FlexLayoutWindows.ObjectClassEditField,
    [isCreateMode]
  );

  const {
    initialValues,
    setInitialValues,
    onSubmit,
    error,
    loading,
    isLimitExceeded,
    isOpenNoExistsFieldModal,
    onConfirmNoExistsField,
    deletedUsers,
  } = useClassFieldForm(mode, panelId);

  const {
    options,
    buildValidationSchema,
    rawValidationSchema,
    parsedTypes,
  } = useClassFieldOptions();

  const { onLimitExceededModalClose, modalProps } = useLimitExceededModal(
    isLimitExceeded
  );

  const { noExistsFieldModalProps } = useNoExistsFieldModal(
    initialValues.label
  );

  const handlePanelValues = useCallback(
    (values: ClassFieldForm) => dispatch(setSidebarData(panelId, { values })),
    [dispatch, panelId]
  );

  if (error) {
    return <ErrorComponent error={error.status} />;
  }

  if (id === undefined && !isCreateMode)
    return (
      <NoDataFoundMessage
        title={intl.formatMessage({
          id: 'objectClasses.fields.noDataFound',
          defaultMessage: 'No data found',
        })}
        subtitle={intl.formatMessage({
          id: 'objectClasses.fields.pleaseSelectAnItemToSeeEditMode',
          defaultMessage: 'Please select an item to see edit mode.',
        })}
      />
    );

  return (
    <div
      className={clsx(styles.fullWidth, {
        [styles.externalWrapper]: isExternalOpen,
      })}
    >
      <ConfirmationModal
        {...modalProps}
        onConfirm={onLimitExceededModalClose}
      />
      <ConfirmationModal
        visible={isOpenNoExistsFieldModal}
        onConfirm={onConfirmNoExistsField}
        {...noExistsFieldModalProps}
      />
      <Loader spinning={loading}>
        <Formik<ClassFieldForm>
          enableReinitialize
          {...{ initialValues, onSubmit }}
          validationSchema={
            mode === FormMode.View ? undefined : buildValidationSchema()
          }
        >
          <ClassFieldFormContextProvider
            value={{
              mode,
              readOnly: mode === FormMode.View,
              validators: rawValidationSchema,
              options,
              panelId,
              setInitialValues,
              deletedUsers,
            }}
          >
            <ClassFieldFormComponent
              savePanelValues={handlePanelValues}
              {...{ parsedTypes }}
            />
          </ClassFieldFormContextProvider>
        </Formik>
      </Loader>
    </div>
  );
};
