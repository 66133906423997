import { RootState } from 'store/reducers';

export const getIsAboutModalVisible = (state: RootState) => {
  return state.misc.isAboutModalVisible;
};

export const getErrorDetailsModalData = (state: RootState) => {
  return {
    error: state.misc.errorDetailsModalPayload,
    isVisible: state.misc.isErrorDetailsModalVisible,
  };
};
