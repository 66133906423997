import { CloseIcon } from 'components/Icon';
import { ButtonTransparent } from 'components/lib/Button';
import { Dropdown } from 'components/lib/Dropdown';
import { Menu, MenuItem } from 'components/lib/Menu';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  RECORD_ACCESS_REMOVE_PERMISSION_SETS_BUTTON,
  RECORD_ACCESS_VIEW_PERMISSION_SETS,
  RECORD_ACCESS_VIEW_PERMISSION_SETS_BUTTON,
} from 'utils/testIds';
import { PermissionSetSelectOptions } from '../RecordOwners/types';
import RemovePermissionSetModal from './RemovePermissionSetModal';
import usePeopleListElementStyles from './styles';
import { PermissionSetListProps, PermissionSetToDeleteType } from './types';

const PermissionSetList = ({
  permissionSetIds,
  permissionSetNames,
  getUsers,
  firstName,
  lastName,
  userId,
}: PermissionSetListProps) => {
  const classes = usePeopleListElementStyles();
  const [visible, setVisible] = useState(false);
  const [permissionSetToDelete, setPermissionSetToDelete] = useState<
    PermissionSetToDeleteType | undefined
  >();

  const closeList = () => {
    setVisible(false);
  };

  const userName = `${firstName} ${lastName}`;

  const {
    selectedResource: { record: { recordId = '0' } = {} } = {},
  } = useSelectedResourceContext();

  const permissionSets = useMemo(
    () =>
      permissionSetNames
        ? (permissionSetIds || [])
            .filter(
              id =>
                id in permissionSetNames &&
                id !== PermissionSetSelectOptions.Owners
            )
            .map(id => ({
              id,
              name: permissionSetNames[id],
            }))
        : [],
    [permissionSetIds, permissionSetNames]
  );

  if (!permissionSets.length) return <>-</>;

  return (
    <>
      <Dropdown
        {...{ visible }}
        overlay={
          <Menu selectable={false} className={classes.permissionSetList}>
            {permissionSets.map(({ id, name }) => (
              <MenuItem
                key={id}
                data-testid={RECORD_ACCESS_VIEW_PERMISSION_SETS}
                data-actions-item={true}
              >
                <div className={classes.permissionSetListItem}>
                  <span data-actions-item={true} data-text={name}>
                    {name}
                  </span>
                  <ButtonTransparent
                    onClick={() =>
                      setPermissionSetToDelete({
                        id,
                        name,
                      })
                    }
                    data-testid={RECORD_ACCESS_REMOVE_PERMISSION_SETS_BUTTON}
                  >
                    <CloseIcon size={18} />
                  </ButtonTransparent>
                </div>
              </MenuItem>
            ))}
          </Menu>
        }
        onVisibleChange={setVisible}
        trigger={['click']}
        placement='topLeft'
      >
        <ButtonTransparent
          onClick={() => setVisible(true)}
          className={classes.buttonTransparent}
          data-testid={RECORD_ACCESS_VIEW_PERMISSION_SETS_BUTTON}
        >
          <FormattedMessage
            id='misc.permissionSetCount'
            defaultMessage='{count, plural, one {# permission set} other {# permission sets}}'
            values={{ count: permissionSets.length }}
          />
        </ButtonTransparent>
      </Dropdown>
      <RemovePermissionSetModal
        {...{
          recordId,
          permissionSetToDelete,
          setPermissionSetToDelete,
          closeList,
          userName,
          userId,
          getUsers,
        }}
      />
    </>
  );
};

export default PermissionSetList;
