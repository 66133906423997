import { useTranslateError } from 'components/FormPreview2/translateError';
import { useCallback } from 'react';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { isNil } from 'lodash';
import { UsersAndGroupsSelectionCountLimits } from 'utils/types/selectInput.types';
import { ErrorKeys } from 'alx-dynamic-form';

export const useUsersValidation = (
  fieldLabel: string,
  limits: UsersAndGroupsSelectionCountLimits | undefined
) => {
  const translateError = useTranslateError();

  const validateUsers = useCallback(
    (fieldValue: UsersAndGroupsFormValue | null) => {
      if (isNil(fieldValue) || isNil(limits)) {
        return [];
      }

      if (
        !isNil(limits.minUsers) &&
        fieldValue.users.length < limits.minUsers
      ) {
        return [
          translateError(ErrorKeys.MIN_USERS, limits.minUsers, fieldLabel),
        ];
      }

      if (
        !isNil(limits.maxUsers) &&
        fieldValue.users.length > limits.maxUsers
      ) {
        return [
          translateError(ErrorKeys.MAX_USERS, limits.maxUsers, fieldLabel),
        ];
      }

      return [];
    },
    [fieldLabel, limits, translateError]
  );

  return { validateUsers };
};
