import { defineMessages, useIntl } from 'react-intl';
import { MinMaxInfoLabelType } from '../types';
import useFormatNumber from 'hooks/useFormatNumber';

/**
 * Prepares final translated messages for MinMaxInfo component based on the label type and other parameters.
 */
export const useMinMaxInfoMessage = (
  minimum: number | undefined,
  maximum: number | undefined,
  isCheckboxField: boolean,
  labelType: MinMaxInfoLabelType
) => {
  const formatNumberWithSeparators = useFormatNumber();
  const intl = useIntl();

  const messages = defineMessages({
    defaultMin: {
      id: 'formBuilder.min',
      defaultMessage: 'Min: {value}',
    },
    defaultMax: {
      id: 'formBuilder.max',
      defaultMessage: 'Max: {value}',
    },
    defaultMinCheckbox: {
      id: 'formBuilder.minCheckboxField',
      defaultMessage: 'Min selection: {value}',
    },
    defaultMaxCheckbox: {
      id: 'formBuilder.maxCheckboxField',
      defaultMessage: 'Max selection: {value}',
    },
    usersCheckboxMin: {
      id: 'formBuilder.minUserCheckboxField',
      defaultMessage: 'Min users: {value}',
    },
    usersCheckboxMax: {
      id: 'formBuilder.maxUserCheckboxField',
      defaultMessage: 'Max users: {value}',
    },
    groupsCheckboxMin: {
      id: 'formBuilder.minUserGroupCheckboxField',
      defaultMessage: 'Min groups: {value}',
    },
    groupsCheckboxMax: {
      id: 'formBuilder.maxUserGroupCheckboxField',
      defaultMessage: 'Max groups: {value}',
    },
  });

  const minMessageResolver = () => {
    switch (labelType) {
      case MinMaxInfoLabelType.Users:
        throw new Error('Out of scope exception. Please implement this case.');
      case MinMaxInfoLabelType.Groups:
        throw new Error('Out of scope exception. Please implement this case.');
      default:
        return messages.defaultMin;
    }
  };

  const maxMessageResolver = () => {
    switch (labelType) {
      case MinMaxInfoLabelType.Users:
        throw new Error('Out of scope exception. Please implement this case.');
      case MinMaxInfoLabelType.Groups:
        throw new Error('Out of scope exception. Please implement this case.');
      default:
        return messages.defaultMax;
    }
  };

  const minCheckboxMessageResolver = () => {
    switch (labelType) {
      case MinMaxInfoLabelType.Users:
        return messages.usersCheckboxMin;
      case MinMaxInfoLabelType.Groups:
        return messages.groupsCheckboxMin;
      default:
        return messages.defaultMinCheckbox;
    }
  };

  const maxCheckboxMessageResolver = () => {
    switch (labelType) {
      case MinMaxInfoLabelType.Users:
        return messages.usersCheckboxMax;
      case MinMaxInfoLabelType.Groups:
        return messages.groupsCheckboxMax;
      default:
        return messages.defaultMaxCheckbox;
    }
  };

  const minMessageDescriptor = isCheckboxField
    ? minCheckboxMessageResolver()
    : minMessageResolver();
  const maxMessageDescriptor = isCheckboxField
    ? maxCheckboxMessageResolver()
    : maxMessageResolver();

  const minMessage = intl.formatMessage(minMessageDescriptor, {
    value: formatNumberWithSeparators(minimum),
  });

  const maxMessage = intl.formatMessage(maxMessageDescriptor, {
    value: formatNumberWithSeparators(maximum),
  });

  return { minMessage, maxMessage };
};
