import { useFormikContext } from 'formik';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { useCallback } from 'react';
import { ClassFieldForm } from '../../../../../types';

export const useSelectMaxMinValidation = () => {
  const {
    setFieldValue,
    validateField,
    values: { min_values: minValues, max_values: maxValues },
  } = useFormikContext<ClassFieldForm>();

  return useCallback(
    (name: string, value?: number) => {
      const isMinValueChange = name === ClassFieldFormFields.MinValues;

      if (
        value !== undefined &&
        (isMinValueChange
          ? maxValues !== undefined && value > maxValues
          : minValues !== undefined && value < minValues)
      ) {
        const valueKeyToChange = isMinValueChange
          ? ClassFieldFormFields.MaxValues
          : ClassFieldFormFields.MinValues;
        const valueToSet = Math.floor(value);

        setFieldValue(valueKeyToChange, valueToSet);
        validateField(valueKeyToChange);
      }
    },
    [maxValues, minValues, validateField, setFieldValue]
  );
};
