import useData from 'hooks/useData';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { USER_GROUP_USAGE } from 'utils/endpoints';
import { UserGroupUsage } from 'utils/types/api/userGroups.types';

/**
 * Fetches usage data for provided user group.
 *
 * @param id User group id.
 */
export const useUserGroupUsageQuery = (id: string | undefined) => {
  const [data, { loading: isLoading, error, fetchData }] = useData<
    UserGroupUsage
  >(
    generatePath(USER_GROUP_USAGE, {
      id: id ?? '0',
    }),
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    fetchData();
  }, [fetchData, id]);

  return { usage: data, error, isLoading };
};
