import React from 'react';
import { TaskUserAssigneesLimitAlertProps } from './TaskUserAssigneesLimitAlert.types';
import { LimitAlert } from 'components/LimitAlert';
import { useIntl } from 'react-intl';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';

export const TaskUserAssigneesLimitAlert = ({
  isOneTCUserAssigned,
  usersLimit,
}: TaskUserAssigneesLimitAlertProps) => {
  const intl = useIntl();

  const { taskAssignees } = useTasksPanelContext();

  const errorMessage = isOneTCUserAssigned
    ? intl.formatMessage(
        {
          id: 'misc.oneTCUserAssigneesLimit',
          defaultMessage:
            'Maximum of {limit} one-time completion user {limit, plural, one {assignee} other {assignees}} has been reached.',
        },
        { limit: usersLimit }
      )
    : intl.formatMessage(
        {
          id: 'misc.userAssignessLimitError',
          defaultMessage:
            'Maximum of {limit} assigned {limit, plural, one {user} other {users}} has been reached.',
        },
        { limit: usersLimit }
      );
  const warningMessage = intl.formatMessage(
    {
      id: 'tasksPanel.userAssignessLimitWarning',
      defaultMessage:
        'A task can have up to {limit} {limit, plural, one {user} other {users}} assigned. This one currently has {count}.',
    },
    { limit: usersLimit, count: taskAssignees.users.length }
  );

  return (
    <LimitAlert
      itemsCount={taskAssignees.users.length}
      itemsLimit={usersLimit}
      errorMessage={errorMessage}
      warningMessage={warningMessage}
    />
  );
};
