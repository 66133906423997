export const useSelectOptions = (
  values: (string | number)[] | { users: (string | number)[] }
) => {
  const items = Array.isArray(values) ? values : values.users;

  return items.map(item => ({
    value: item.toString(),
    text: item.toString(),
  }));
};
