import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { TaskTypes } from 'pages/TaskTemplates/enums';
import { TaskFormType } from '../types';

export const TASK_FORM_INITIAL_VALUES: TaskFormType = {
  [TaskFormField.Name]: '',
  [TaskFormField.TaskType]: TaskTypes.Form,
  [TaskFormField.TaskTemplate]: '',
  [TaskFormField.Assignees]: { users: [], groups: [] },
  [TaskFormField.Instructions]: '',
};
