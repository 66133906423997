import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useFilterItemStyles = createUseStyles((theme: ThemeState) => ({
  filterItem: {
    padding: '12px',
    marginBottom: '16px',
    border: `1px solid ${theme['placeholder-border-color']}`,
    borderRadius: '3px',
  },
  wrapper: {
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
