import { FilterValue } from 'store/actions/filtersActions';
import { Logger, LogLevel } from 'utils/logger';

/**
 * Seeks the {@link FilterValue} based on its key from the provided filter value array.
 * @param filterValueKey Filter value key.
 * @param filterValues Array of filters to search in.
 * @returns Found filter value or null if not found.
 */
export const findFilterWithKey = (
  filterValueKey: string,
  filterValues: FilterValue[]
) => {
  const filterValue = filterValues.find(
    filter => filter.column === filterValueKey
  );

  if (!filterValue) {
    Logger.log(
      `No filter for provided field key: ${filterValueKey}. Skipping.`,
      LogLevel.Verbose
    );
    return null;
  }

  Logger.log(
    `Found filter that requires correction: ${filterValueKey}. Fixing.`,
    LogLevel.Verbose,
    filterValue
  );

  return filterValue;
};
