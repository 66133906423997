import React from 'react';
import { GroupClassPemrmissionSetUsageViewProps } from './GroupClassPemrmissionSetUsageView.types';
import { ItalicTextPartsFallback } from 'utils/intl/fallbacks/ItalicTextParts';
import { FormattedMessage } from 'react-intl';
import { useGroupClassPemrmissionSetUsageViewStyles } from './GroupClassPemrmissionSetUsageView.styles';

export const GroupClassPemrmissionSetUsageView = ({
  usage,
}: GroupClassPemrmissionSetUsageViewProps) => {
  const styles = useGroupClassPemrmissionSetUsageViewStyles();

  return (
    <ul className={styles.list}>
      {usage.map(({ id, name, object_class_name: objClassName }) => (
        <li key={id}>
          <FormattedMessage
            id='fieldUsage.objClassPermissionSetBased'
            defaultMessage='{permissionSetName} permission set <i>({objClassName} object class)</i>'
            values={{
              i: ItalicTextPartsFallback,
              permissionSetName: name,
              objClassName,
            }}
          />
        </li>
      ))}
    </ul>
  );
};
