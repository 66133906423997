import React from 'react';
import ExpiredLinkClock from '../../../../img/expired-token-clock.png';
import { FormattedMessage, useIntl } from 'react-intl';
import useExpiredLinkPageStyles from './styles';
import CardWrapper from '../../../../components/CardWrapper';
import { useRenewLink } from '../../hooks';
import RequestSentPage from '../../../../components/RequestSentPage';
import {
  EXPIRED_LINK_NEWLINK_TESTID,
  EXPIRED_LINK_SUBTITLE_TESTID,
  EXPIRED_LINK_TITLE_TESTID,
} from 'utils/testIds';

const ExpiredLinkPage: React.FC = () => {
  const styles = useExpiredLinkPageStyles();
  const intl = useIntl();
  const { renewLink, isSuccessRenewLink } = useRenewLink();

  if (isSuccessRenewLink)
    return (
      <RequestSentPage
        title={intl.formatMessage({
          id: 'activateAccount.requestSent',
          defaultMessage: 'Your request has been sent',
        })}
        subtitle={intl.formatMessage({
          id: 'activateAccount.receivedRequest',
          defaultMessage:
            'Our system administrators received your request. Once accepted, you will receive a new activation link.',
        })}
      />
    );

  return (
    <CardWrapper>
      <img
        src={ExpiredLinkClock}
        className={styles.icon}
        alt='Expired Activation Link'
      />
      <p className={styles.title} data-testid={EXPIRED_LINK_TITLE_TESTID}>
        <FormattedMessage
          id='activateAccount.expiredActivateLink'
          defaultMessage='The activation link you have provided is no longer valid'
        />
      </p>
      <p className={styles.subtitle} data-testid={EXPIRED_LINK_SUBTITLE_TESTID}>
        <FormattedMessage
          id='activateAccount.refreshActivationLink'
          defaultMessage='The activation link has expired. New accounts must be activated within a certain timeframe. To request a new activation email please click {link}.'
          values={{
            link: (
              <button
                type='button'
                className={styles.link}
                onClick={renewLink}
                data-testid={EXPIRED_LINK_NEWLINK_TESTID}
              >
                <FormattedMessage
                  id='activateAccount.here'
                  defaultMessage='here'
                />
              </button>
            ),
          }}
        />
      </p>
    </CardWrapper>
  );
};

export default ExpiredLinkPage;
