import { useCallback } from 'react';
import {
  UserOrGroupSelectOptionType,
  SelectUserAndGroupOptionMap,
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';

export const useToggleSelection = (
  setSelectedItems: React.Dispatch<
    React.SetStateAction<SelectUserAndGroupOptionMap>
  >
) => {
  const toggleItemSelectionState = useCallback(
    (item: SelectUserOption | SelectUserGroupOption) => {
      // user was clicked
      if (item.type === UserOrGroupSelectOptionType.User) {
        setSelectedItems(prev => {
          const isAlreadySelected = prev.users.get(item.id);
          const newUsers = new Map(prev.users);
          if (!isAlreadySelected) {
            newUsers.set(item.id, item);
          } else {
            newUsers.delete(item.id);
          }

          return {
            ...prev,
            users: newUsers,
          };
        });
      } else {
        setSelectedItems(prev => {
          // group was clicked;
          const isAlreadySelected = prev.groups.get(item.id);

          const newGroups = new Map(prev.groups);
          if (!isAlreadySelected) {
            newGroups.set(item.id, item);
          } else {
            newGroups.delete(item.id);
          }

          return {
            ...prev,
            groups: newGroups,
          };
        });
      }
    },
    [setSelectedItems]
  );

  return { toggleItemSelectionState };
};
