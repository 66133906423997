import { default as lazy } from './lazyWithRetry';
import routes from './routingPaths';
import { Route, ClassFormRoute } from './types/routing.types';

const Home = lazy(() => import('pages/Home'));
const Sequences = lazy(() => import('pages/Sequences'));
const TaskTemplates = lazy(() => import('pages/TaskTemplates/List'));
const MyAccount = lazy(() => import('pages/MyAccount'));
const TaskGroups = lazy(() => import('pages/TaskGroups'));
const Users = lazy(() => import('pages/Users/List'));
const Role = lazy(() => import('pages/Role'));
const CreateTaskTemplate = lazy(() => import('pages/TaskTemplates/Create'));
const UserGroups = lazy(() => import('pages/UserGroups'));
const CompanyDetails = lazy(() => import('pages/CompanyDetails'));
const OutboundMailSetup = lazy(() => import('pages/OutboundMailSetup'));
const StandardAuthentication = lazy(
  () => import('pages/StandardAuthentication')
);
const CreateTaskGroupTemplate = lazy(() => import('pages/TaskGroups/Create'));
const EditTaskGroupTemplate = lazy(() => import('pages/TaskGroups/Edit'));
const EditTaskTemplate = lazy(() => import('pages/TaskTemplates/Edit'));
const CreateRole = lazy(() => import('pages/Role/Create'));
const EditRole = lazy(() => import('pages/Role/Edit'));
const CreateUser = lazy(() => import('pages/Users/Create'));
const EditUser = lazy(() => import('../pages/Users/Edit'));
const SingleSignOns = lazy(() => import('pages/SingleSignOn/List'));
const Workspace = lazy(() => import('pages/Workspace'));
const SystemConfigurationOverview = lazy(
  () => import('pages/SystemConfigurationOverview')
);
const Administration = lazy(() => import('pages/Administration'));
const ObjectClasses = lazy(() => import('pages/ObjectClasses/List'));
const CreateObjectClass = lazy(() => import('pages/ObjectClasses/Create'));
const EditObjectClass = lazy(() => import('../pages/ObjectClasses/Edit'));
const CreateSingleSignOn = lazy(() => import('../pages/SingleSignOn/Create'));
const CreateRecord = lazy(() => import('pages/Records/Create'));
const EditSingleSignOn = lazy(() => import('../pages/SingleSignOn/Edit'));
const AuthenticationObjects = lazy(
  () => import('pages/AuthenticationObjects/List')
);
const CreateAuthenticationObject = lazy(
  () => import('pages/AuthenticationObjects/Create')
);
const EditAuthenticationObject = lazy(
  () => import('pages/AuthenticationObjects/Edit')
);
const Record = lazy(() => import('../pages/Records'));
const RecordListing = lazy(
  () => import('pages/Records/RecordsListing/RecordsListingWrapper')
);
const CreateUserGroup = lazy(() => import('pages/UserGroups/Create'));
const EditUserGroup = lazy(() => import('pages/UserGroups/Edit'));
const HelpMapping = lazy(() => import('pages/HelpMapping'));
const PageNotFound = lazy(() => import('components/PageNotFound'));
const TaskList = lazy(() => import('pages/Tasks/List'));
const CreateSequence = lazy(() => import('pages/Sequences/Create'));
const EditSequence = lazy(() => import('pages/Sequences/Edit'));
const DesignSequence = lazy(() => import('pages/Sequences/Design'));
const TaskView = lazy(() => import('pages/Tasks/View'));
const TaskComplete = lazy(() => import('pages/Tasks/Complete'));
const LandingPage = lazy(() => import('pages/LandingPage'));
const Theme = lazy(() => import('pages/Theme'));
const ClassForm = lazy(() => import('pages/ClassForm'));
const ClassFormComplete = lazy(() => import('pages/ClassFormComplete'));
const RelationalModeller = lazy(() => import('pages/RelationalModeller'));
const CustomComponents = lazy(() => import('pages/CustomComponents/List'));
const CustomComponentsCreate = lazy(
  () => import('pages/CustomComponents/Create')
);
const CustomComponentsEdit = lazy(() => import('pages/CustomComponents/Edit'));

const classFormRoutes: ClassFormRoute[] = [
  {
    path: routes.CLASS_FORM_COMPLETE,
    Component: ClassFormComplete,
  },
  {
    path: routes.CLASS_FORM_DETAILS,
    Component: ClassForm,
  },
];

const protectedRoutes: Route[] = [
  {
    path: routes.HOMEPAGE,
    breadcrumbName: 'misc.home',
    Component: Home,
  },
  {
    path: routes.SYSTEM_CONFIGURATION,
    breadcrumbName: 'misc.systemConfiguration',
    Component: SystemConfigurationOverview,
  },
  {
    path: routes.WORKSPACE,
    breadcrumbName: 'misc.workspace',
    Component: Workspace,
  },
  {
    path: routes.ADMINISTRATION,
    breadcrumbName: 'misc.administration',
    Component: Administration,
  },
  {
    path: routes.RECORDS,
    breadcrumbName: 'misc.records',
    Component: RecordListing,
  },
  {
    path: routes.TASKS,
    breadcrumbName: 'misc.tasks',
    Component: TaskList,
  },
  {
    path: routes.TASK,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.TASK_VIEW,
    breadcrumbName: 'misc.taskView',
    Component: TaskView,
  },
  {
    path: routes.TASK_COMPLETE,
    breadcrumbName: 'misc.taskComplete',
    Component: TaskComplete,
  },
  {
    path: routes.CREATE_RECORD,
    breadcrumbName: '',
    Component: CreateRecord,
  },
  {
    path: routes.RECORD,
    breadcrumbName: '',
    Component: Record,
  },
  {
    path: routes.SEQUENCES,
    breadcrumbName: 'misc.sequences',
    Component: Sequences,
  },
  {
    path: routes.SEQUENCE_CREATE,
    breadcrumbName: 'misc.createSequence',
    Component: CreateSequence,
  },
  {
    path: routes.SEQUENCE,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.SEQUENCE_EDIT,
    breadcrumbName: 'misc.edit',
    Component: EditSequence,
  },
  {
    path: routes.SEQUENCE_DESIGN,
    breadcrumbName: 'misc.editDesign',
    Component: DesignSequence,
  },
  {
    path: routes.LANDING_PAGE,
    breadcrumbName: 'misc.landingPage',
    Component: LandingPage,
  },
  {
    path: routes.THEME,
    breadcrumbName: 'misc.theme',
    Component: Theme,
  },
  {
    path: routes.OBJECT_CLASSES,
    breadcrumbName: 'misc.objectClasses',
    Component: ObjectClasses,
  },
  {
    path: routes.OBJECT_CLASSES_CREATE,
    breadcrumbName: 'misc.createObjectClass',
    Component: CreateObjectClass,
  },
  {
    path: routes.OBJECT_CLASS,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.OBJECT_CLASSES_EDIT,
    breadcrumbName: 'misc.editObjectClass',
    Component: EditObjectClass,
  },
  {
    path: routes.RELATIONAL_MODELLER,
    breadcrumbName: 'misc.relationalModeller',
    Component: RelationalModeller,
  },
  {
    path: routes.TASK_TEMPLATES,
    breadcrumbName: 'misc.taskTemplates',
    Component: TaskTemplates,
  },
  {
    path: routes.TASK_TEMPLATE_CREATE,
    breadcrumbName: 'misc.createTaskTemplate',
    Component: CreateTaskTemplate,
  },
  {
    path: routes.TASK_TEMPLATE,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.TASK_TEMPLATE_EDIT,
    breadcrumbName: 'misc.edit',
    Component: EditTaskTemplate,
  },
  {
    path: routes.TASK_GROUPS,
    breadcrumbName: 'misc.taskGroups',
    Component: TaskGroups,
  },
  {
    path: routes.TASK_GROUP_TEMPLATES_CREATE,
    breadcrumbName: 'misc.createTaskGroupTemplate',
    Component: CreateTaskGroupTemplate,
  },
  {
    path: routes.USERS,
    breadcrumbName: 'misc.users',
    Component: Users,
  },
  {
    path: routes.USER_CREATE,
    breadcrumbName: 'misc.createUser',
    Component: CreateUser,
  },
  {
    path: routes.USER,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.USER_EDIT,
    breadcrumbName: 'misc.editUser',
    Component: EditUser,
  },
  {
    path: routes.USER_GROUPS,
    breadcrumbName: 'misc.userGroups',
    Component: UserGroups,
  },
  {
    path: routes.USER_GROUP_CREATE,
    breadcrumbName: 'misc.createUserGroup',
    Component: CreateUserGroup,
  },
  {
    path: routes.USER_GROUP_EDIT,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
    Component: EditUserGroup,
  },
  {
    path: routes.ROLES,
    breadcrumbName: 'misc.roles',
    Component: Role,
  },
  {
    path: routes.ROLES_CREATE,
    breadcrumbName: 'misc.createRole',
    Component: CreateRole,
  },
  {
    path: routes.ROLE,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.ROLES_EDIT,
    breadcrumbName: 'misc.edit',
    Component: EditRole,
  },
  {
    path: routes.COMPANY_DETAILS,
    breadcrumbName: 'misc.companyDetails',
    Component: CompanyDetails,
  },
  {
    path: routes.STANDARD_AUTHENTICATION,
    breadcrumbName: 'misc.standardAuthentication',
    Component: StandardAuthentication,
  },
  {
    path: routes.SINGLE_SIGN_ONS,
    breadcrumbName: 'misc.singleSignOnSettings',
    Component: SingleSignOns,
  },
  {
    path: routes.SINGLE_SIGN_ON_CREATE,
    breadcrumbName: 'misc.createConfiguration',
    Component: CreateSingleSignOn,
  },
  {
    path: routes.SINGLE_SIGN_ON,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.SINGLE_SIGN_ON_EDIT,
    breadcrumbName: 'misc.edit',
    Component: EditSingleSignOn,
  },
  {
    path: routes.CUSTOM_COMPONENTS,
    breadcrumbName: 'misc.customComponents',
    Component: CustomComponents,
  },
  {
    path: routes.CUSTOM_COMPONENTS_CREATE,
    breadcrumbName: 'misc.customComponentsCreate',
    Component: CustomComponentsCreate,
  },
  {
    path: routes.CUSTOM_COMPONENT,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.CUSTOM_COMPONENTS_EDIT,
    breadcrumbName: 'misc.customComponentsEdit',
    Component: CustomComponentsEdit,
  },
  {
    path: routes.OUTBOUND_MAIL_SETUP,
    breadcrumbName: 'misc.outboundMailSetup',
    Component: OutboundMailSetup,
  },
  {
    path: routes.MY_ACCOUNT,
    breadcrumbName: 'misc.myAccount',
    Component: MyAccount,
  },
  {
    path: routes.TASK_GROUP_TEMPLATES,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.TASK_GROUP_TEMPLATES_EDIT,
    breadcrumbName: 'misc.edit',
    Component: EditTaskGroupTemplate,
  },
  {
    path: routes.AUTHENTICATION_OBJECTS,
    breadcrumbName: 'misc.authenticationObjects',
    Component: AuthenticationObjects,
  },
  {
    path: routes.AUTHENTICATION_OBJECT_CREATE,
    breadcrumbName: 'misc.authenticationObjectCreate',
    Component: CreateAuthenticationObject,
  },
  {
    path: routes.AUTHENTICATION_OBJECT,
    breadcrumbName: 'misc.breadcrumbId',
    paramsKey: 'id',
  },
  {
    path: routes.AUTHENTICATION_OBJECT_EDIT,
    breadcrumbName: 'misc.edit',
    Component: EditAuthenticationObject,
  },
  {
    path: routes.HELP_MAPPING,
    breadcrumbName: 'misc.helpMapping',
    Component: HelpMapping,
  },
  {
    path: routes.ERROR_404,
    breadcrumbName: '',
    Component: PageNotFound,
  },
];

export { protectedRoutes, classFormRoutes };
