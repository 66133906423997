import { useIntl } from 'react-intl';

/**
 * Handles logic related to assignees select disabled state.
 */
export const useTaskFormAssigneesSelectDisabledState = (
  areBothAssigneeLimitsReached: boolean,
  isOneTCUserSelected: boolean
) => {
  const intl = useIntl();

  const isSelectDisabled = isOneTCUserSelected || areBothAssigneeLimitsReached;

  const disabledSelectTooltip = isOneTCUserSelected
    ? intl.formatMessage({
        id: 'tasksPanel.form.oneTimeCompletionUserSelected',
        defaultMessage:
          'A one-time completion user has been assigned to this task, therefore no further users or user groups can be added.',
      })
    : intl.formatMessage({
        id: 'misc.maxOptionsAdded',
        defaultMessage: 'Maximum options have been added',
      });

  return { disabledSelectTooltip, isSelectDisabled };
};
