import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootAction, RootState } from 'store/reducers';
import {
  SET_CUSTOM_THEME,
  SET_CUSTOM_THEME_LOADING,
  SET_SAVING_SET_CUSTOM_THEME,
} from 'store/constants/customTheme.consts';
import { apiCall } from 'utils/api';
import { THEME_CONFIG } from 'utils/endpoints';
import { isSuccess } from 'utils/apiUtils';
import { customThemeSelector } from 'store/selectors/customThemeSelectors';
import { StatusCodes } from 'http-status-codes';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { ThemeFormSchema } from 'pages/Theme/components/ThemeConfiguration/types';

export interface SetCustomThemeAction extends Action<typeof SET_CUSTOM_THEME> {
  payload: ThemeFormSchema;
}

export interface SetCustomThemeLoadingAction
  extends Action<typeof SET_CUSTOM_THEME_LOADING> {
  loading: boolean;
}

export interface SetSavingCustomThemeAction
  extends Action<typeof SET_SAVING_SET_CUSTOM_THEME> {
  saving: boolean;
}

export type CustomThemeAction =
  | SetCustomThemeAction
  | SetCustomThemeLoadingAction
  | SetSavingCustomThemeAction;

export const setTheme = (payload: ThemeFormSchema): SetCustomThemeAction => ({
  type: SET_CUSTOM_THEME,
  payload,
});

export const setThemeLoading = (
  loading: boolean
): SetCustomThemeLoadingAction => ({
  type: SET_CUSTOM_THEME_LOADING,
  loading,
});

export const setSavingCustomTheme = (
  saving: boolean
): SetSavingCustomThemeAction => ({
  type: SET_SAVING_SET_CUSTOM_THEME,
  saving,
});

export const getCustomTheme = (): ThunkAction<
  Promise<boolean>,
  RootState,
  undefined,
  RootAction
> => async (dispatch, getState) => {
  try {
    dispatch(setThemeLoading(true));
    const { data, status } = await apiCall.get(THEME_CONFIG);

    if (isSuccess(status)) {
      const themeData: ThemeFormSchema = data;

      dispatch(setTheme(themeData));

      return true;
    }

    return false;
  } catch (error) {
    //Theme not found - fallback to defaults
    if (error?.response?.status === StatusCodes.NOT_FOUND) {
      const currentCustomTheme = customThemeSelector(getState());
      try {
        apiCall.post(THEME_CONFIG, {
          content: currentCustomTheme,
        });
      } catch {
        // there is nothing we can do here
        // either the endpoint has changed or the user does not have permission
        console.warn('default theme not peristed');
      }
      return false;
    }

    return false;
  } finally {
    dispatch(setThemeLoading(false));
  }
};

export const updateCustomTheme = (
  newCustomTheme: ThemeFormSchema
): ThunkAction<
  Promise<void>,
  RootState,
  undefined,
  CustomThemeAction
> => async dispatch => {
  dispatch(setSavingCustomTheme(true));

  try {
    dispatch(setTheme(newCustomTheme));
    await apiCall.post(THEME_CONFIG, {
      content: newCustomTheme,
    });

    dispatch(setTheme(newCustomTheme));
    dispatch(setSavingCustomTheme(false));

    return Promise.resolve();
  } catch (error) {
    dispatch(setSavingCustomTheme(false));
    showUnhandledErrorToast(error);

    return Promise.reject();
  } finally {
    dispatch(setSavingCustomTheme(false));
  }
};
