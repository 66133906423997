import React from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { generatePath } from 'react-router-dom';
import { TASK_USER_ASSIGNEES } from 'utils/endpoints';
import { useIntl } from 'react-intl';
import useFormatNumber from 'hooks/useFormatNumber';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { TasksPanelMode } from 'components/SidePanels/TasksPanel/types';
import { AddUserAssigneesPanelView } from 'components/AddAssigneesPanelView/views/AddUserAssigneesPanelView';
import { useTaskOptionsContext } from 'components/SidePanels/TasksPanel/contexts/TaskOptionsContext';

export const AddTaskUserAssignees = () => {
  const inlt = useIntl();

  const formatNumberWithSeparators = useFormatNumber();
  const {
    task,
    taskAssignees,
    setMode,
    refetchTaskDetails,
  } = useTasksPanelContext();
  const { taskUserAssigneeOptions } = useTaskOptionsContext();

  const autocompleteEndpoint =
    taskUserAssigneeOptions?.batch?.autocomplete ?? '';
  const hasAnyAssignees =
    taskAssignees.users.length > 0 || taskAssignees.userGroups.length > 0;
  const postEndpoint = generatePath(TASK_USER_ASSIGNEES, {
    id: task?.id,
  });
  const usersLimit =
    taskUserAssigneeOptions?.restrictions?.limit_assignees_standard;
  const assignedUserIds = taskAssignees.users.map(assignee => {
    return assignee.id;
  });
  const additionalContextText = inlt.formatMessage(
    {
      id: 'tasksPanel.recordId',
      defaultMessage: 'Record ID: {recordId}',
    },
    {
      recordId: formatNumberWithSeparators(Number(task?.object_record)),
    }
  );

  const changePanelView = () => {
    setMode(TasksPanelMode.Reassign);
  };

  return (
    <AddUserAssigneesPanelView
      resourceName={task?.name ?? ''}
      panelKey={FlexLayoutWindows.Tasks}
      assignedUserIds={assignedUserIds}
      autocompleteEndpoint={autocompleteEndpoint}
      postEndpoint={postEndpoint}
      additionalContextText={additionalContextText}
      hasAnyAssignees={hasAnyAssignees}
      usersLimit={usersLimit}
      changePanelView={changePanelView}
      refetchUserAssignees={refetchTaskDetails}
    />
  );
};
