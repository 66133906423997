import { createUseStyles } from 'react-jss';

export const useUsersAndGroupsPickerRejectButtonStyles = createUseStyles(
  () => ({
    editModeButton: {
      position: 'absolute',
      top: 0,
      right: -20,
    },
  })
);
