import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { TaskFormAssigneesFieldProps } from './TaskFormAssigneesField.types';
import { useTaskAssigments } from './hooks/useTaskAssigments';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { useTaskFormAssigneesFieldStyles } from './TaskFormAssigneesField.styles';
import { FUsersAndGroupsSelect } from 'components/UsersAndGroupsSelection/FUsersAndGroupsSelect';
import { useFormikContext } from 'formik';
import { TaskFormType } from '../../types';
import Tooltip from 'components/lib/Tooltip';
import { SelectedUsersAndGroupsEditor } from 'components/SelectedUsersAndGroupsEditor';
import { Alert } from 'components/lib/Alert';
import { useTaskFormAssigneesLimits } from './hooks/useTaskFormAssigneesLimits';
import { useTaskFormAssigneesSelectDisabledState } from './hooks/useTaskFormAssigneesSelectDisabledState';

export const TaskFormAssigneesField = ({
  formSchema,
}: TaskFormAssigneesFieldProps) => {
  const styles = useTaskFormAssigneesFieldStyles();

  const { values } = useFormikContext<TaskFormType>();
  const {
    isOneTCUserSelected,
    checkShouldOptionBeDisabled,
    handleUserDelete,
    handleGroupDelete,
  } = useTaskAssigments();
  const {
    areBothLimitsReached,
    groupsLimitReachedText,
    isAnyLimitReached,
    isGroupsLimitReached,
    isUsersLimitReached,
    usersLimitReachedText,
  } = useTaskFormAssigneesLimits(
    formSchema?.[TaskFormField.Assignees].limitUsersStandard,
    formSchema?.[TaskFormField.Assignees].limitGroups
  );
  const {
    disabledSelectTooltip,
    isSelectDisabled,
  } = useTaskFormAssigneesSelectDisabledState(
    areBothLimitsReached,
    isOneTCUserSelected
  );

  const selectedUsers = values[TaskFormField.Assignees].users;
  const selectedGroups = values[TaskFormField.Assignees].groups;
  const isAssigneesFieldRequired =
    formSchema?.[TaskFormField.Assignees].required ?? false;

  return (
    <div className={styles.contentWrapper}>
      <FormLabel bolded={false} required={isAssigneesFieldRequired}>
        <FormattedMessage
          id='tasksPanel.form.taskAssignees'
          defaultMessage='Task assignees'
        />
      </FormLabel>

      <div className={styles.fieldWrapper}>
        <Tooltip
          title={disabledSelectTooltip}
          shouldBeRendered={isSelectDisabled}
        >
          <FUsersAndGroupsSelect
            name={TaskFormField.Assignees}
            isDisabled={isSelectDisabled}
            usersAutocompleteUrl={
              formSchema?.[TaskFormField.Assignees].usersAutocompleteUrl
            }
            groupsAutocompleteUrl={
              formSchema?.[TaskFormField.Assignees].groupsAutocompleteUrl
            }
            usersLimit={
              formSchema?.[TaskFormField.Assignees].limitUsersStandard
            }
            groupsLimit={formSchema?.[TaskFormField.Assignees].limitGroups}
            checkShouldOptionBeDisabled={checkShouldOptionBeDisabled}
          />
        </Tooltip>

        {isAnyLimitReached && (
          <div className={styles.alertsWrapper}>
            {isGroupsLimitReached && (
              <Alert type='info' message={groupsLimitReachedText} />
            )}

            {isUsersLimitReached && (
              <Alert type='info' message={usersLimitReachedText} />
            )}
          </div>
        )}

        <SelectedUsersAndGroupsEditor
          selectedUsers={selectedUsers}
          selectedGroups={selectedGroups}
          onUserDelete={handleUserDelete}
          onGroupDelete={handleGroupDelete}
        />
      </div>
    </div>
  );
};
