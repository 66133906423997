import { FormValue } from 'alx-dynamic-form';
import { FormBuilderField } from 'components/formBuilder/formBuilder/FormBuilderContext/types';
import { NestedRecordNode } from 'hooks/useNestedRecordsTree/types';
import { OptionsResponseDetails } from 'utils/types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { ColumnsMetadata, Restrictions } from 'utils/types/api/table.types';
import { ResponseError } from 'utils/types/errorResponse';

export type NestedTableColumnConfig = {
  id: string;
  width: number;
};
export type NestedObjectRecordsStackElement = {
  recordIdentifier: string | undefined;
  recordId: string | number | undefined;
  classId: string;
  formData: MappedObject<FormValue> | undefined;
  className: string;
};

export type NestedRecordsStackModalData =
  | undefined
  | {
      isVisible: boolean;
      onCancel: () => void;
      onConfirm: () => void;
    };
export type NestedRecordsStackModals = MappedObject<
  {
    data: NestedRecordsStackModalData;
  },
  NestedObjectRecordsModalTypes
>;

export type NestedObjectRecordsStack = NestedObjectRecordsStackElement[];

export type NestedCommonTableState<T> = {
  data: NestedTableState<T>;
  selectedRow?: string;
  nestedRecordsStackModals?: NestedRecordsStackModals;
  stack: NestedObjectRecordsStack;
  tree: NestedRecordNode;
};

type NestedTableState<T> = MappedObject<{
  fetching: boolean;
  byId: { [id: string]: T };
  allIds: string[];
  total: number;
  filtered: number;
  columns: MappedObject<ColumnsMetadata>;
  restrictions: Restrictions;
  selectedColumns: FormBuilderField[];
  details?: OptionsResponseDetails;
  customOffset: number;
  error?: ResponseError;
}>;

export type NestedObjectRecordsState = NestedCommonTableState<
  ObjectRecordDetails
>;

export enum NestedObjectRecordsModalTypes {
  CANCEL_CONFIG_MODAL = 'CANCEL_CONFIG_MODAL',
}
