import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TOAST_TESTID } from 'utils/testIds';
import { ToastType } from 'features/toasts/types/toastType';
import { ToastIcon } from '../../components/ToastIcon';
import { useUnhandledErrorToastContentStyles } from './UnhandledErrorToastContent.styles';
import { UnhandledErrorToastContentProps } from './UnhandledErrorToastContent.types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { showErrorDetailsModal } from 'store/actions/miscActions';

/**
 * Content of the toast displayed when an unhandled error occurs. Contains a button that allows to
 * show error details by opening a dedicated modal.
 */
export const UnhandledErrorToastContent = ({
  error,
  toastProps,
}: UnhandledErrorToastContentProps) => {
  const intl = useIntl();
  const styles = useUnhandledErrorToastContentStyles();
  const dispatch = useDispatch<AppDispatch>();

  const title = intl.formatMessage({
    id: 'errors.somethingWentWrong',
    defaultMessage: 'Something went wrong',
  });
  const subtitle = intl.formatMessage({
    id: 'misc.errorEncountered',
    defaultMessage: 'We encountered an error, please try again later.',
  });

  const handleDetailsBtnClick = () => {
    toastProps.closeToast();
    dispatch(showErrorDetailsModal(error));
  };

  return (
    <article className={styles.contentWrapper} data-testid={TOAST_TESTID.error}>
      <ToastIcon type={ToastType.Error} />

      <section>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subtitle}>{subtitle}</h3>

        <button className={styles.button} onClick={handleDetailsBtnClick}>
          <FormattedMessage
            id='misc.showDetails'
            defaultMessage='Show details'
          />
        </button>
      </section>
    </article>
  );
};
