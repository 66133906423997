import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import { DocumentTemplateStatus } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/components/TemplateStatusCell/components/DocumentTemplateStatus';
import React from 'react';

export const TemplateStatusCell = ({ row }: CatalystTableCellProps) => {
  const { id, generated_document } = row;
  return (
    <DocumentTemplateStatus id={id} generated_document={generated_document} />
  );
};
