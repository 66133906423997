import { useCallback, useEffect } from 'react';

let isRegistered = false;
let mousePosition = { x: 0, y: 0 };
let usageCount = 0; // Track the number of components using this hook

/**
 * Tracks the mouse position globally (viewport space) by using a single, shared event listener. Mouse position change doesn't trigger a re-render.
 */
export const useGlobalMousePosition = () => {
  const onMouseMove = (event: MouseEvent) => {
    mousePosition = { x: event.clientX, y: event.clientY };
  };

  const getMousePosition = useCallback(() => {
    return mousePosition;
  }, []);

  useEffect(() => {
    usageCount += 1;

    if (!isRegistered) {
      window.addEventListener('mousemove', onMouseMove);
      isRegistered = true;
    }

    return () => {
      usageCount -= 1;

      if (usageCount > 0) {
        return;
      }

      window.removeEventListener('mousemove', onMouseMove);
      isRegistered = false;
    };
  }, []);

  return {
    getMousePosition,
  };
};
