import React from 'react';
import { useAddAssigneesPanelViewFooterStyles } from './AddAssigneesPanelViewFooter.styles';
import { ButtonSecondaryOutlined, ButtonTertiary } from 'components/lib/Button';
import { PlusIcon } from 'components/Icon';
import { BUTTON_CANCEL_TESTID, BUTTON_SUBMIT_TESTID } from 'utils/testIds';
import { FormattedMessage } from 'react-intl';
import { AddAssigneesPanelViewFooterProps } from './AddAssigneesPanelViewFooter.types';

/**
 * Footer for the add assignees panel view.
 */
export const AddAssigneesPanelViewFooter = ({
  hasSelectedItems,
  isSubmitting,
  onAddBtnClick,
  onCancelBtnClick,
}: AddAssigneesPanelViewFooterProps) => {
  const styles = useAddAssigneesPanelViewFooterStyles();

  return (
    <footer className={styles.footer}>
      <ButtonSecondaryOutlined
        data-testid={BUTTON_CANCEL_TESTID}
        onClick={onCancelBtnClick}
      >
        <FormattedMessage id='misc.cancel' defaultMessage='Cancel' />
      </ButtonSecondaryOutlined>

      <ButtonTertiary
        icon={<PlusIcon size={10} />}
        loading={isSubmitting}
        disabled={!hasSelectedItems}
        data-testid={BUTTON_SUBMIT_TESTID}
        onClick={onAddBtnClick}
      >
        <FormattedMessage id='misc.add' defaultMessage='Add' />
      </ButtonTertiary>
    </footer>
  );
};
