import { FilterValue } from 'store/actions/filtersActions';
import { FIELD_PREFIX } from 'utils/consts';

/**
 * Determines if provided filter is the one used to filter user and groups.
 *
 * Normally we use filter.type == 'user_and_group' but due to legacy reasons, object class user type
 * fields return filter.type === 'user' so a different type of detection has to be performed.
 */
export const isUsersAndGroupsFilter = (filter: FilterValue) => {
  const { column: filterKey, type: filterType } = filter;

  const isUserTypeField =
    filterType === 'user' && filterKey.includes(FIELD_PREFIX);

  return filter.type === 'user_and_group' || isUserTypeField;
};
