import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { MENU_INLINE_INDENT } from 'utils/consts';
import get from 'lodash/get';

type UseLayoutStylesProps = {
  breadcrumbsWidth?: number;
  isActive?: boolean;
};

const useLayoutStyles = createUseStyles((theme: ThemeState) => {
  return {
    mainLayout: {
      minHeight: '100vh',
      minWidth: 1366,
    },
    sidebarLogoWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: `18px 13px 18px 10px`,
    },
    sidebarLogo: {
      marginRight: 16,
    },
    sidebarMenu: {
      transition: 'opacity 0.3s',
      backgroundColor: `${theme['sider-bg']} !important`,
      '& > .ant-menu-submenu': {
        borderLeft: '9px solid transparent',
        '& > .ant-menu-submenu-title': {
          color: 'white',
          fontWeight: 600,
          fontSize: 14,
          '& > .ant-menu-submenu-arrow': {
            display: 'none',
          },
        },
        '&.ant-menu-submenu-open > .ant-menu-submenu-title': {
          fontWeight: 700,
        },
        '& > .ant-menu-sub': {
          marginTop: -13,
          marginBottom: 13,
        },
        '& .ant-menu-submenu > .ant-menu-submenu-title': {
          margin: '4px 0',
          paddingTop: 7,
          paddingBottom: 7,
          height: 'auto !important',
          lineHeight: `18px !important`,
          fontWeight: 400,
          whiteSpace: 'pre-line',
        },
      },
      '& .ant-menu-submenu': {
        '& .ant-menu-item-selected': {
          backgroundColor: `${theme['sider-bg-third']} !important`,
          background:
            'linear-gradient(90deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%)',
        },
        '& > .ant-menu-submenu-title': {
          margin: 0,
          height: 75,
          lineHeight: 75,
        },
        '&.ant-menu-submenu-open.purple': {
          borderLeftColor: get(
            theme,
            ['sider-bg-separater'],
            theme['pink-color']
          ),
        },
        '&.ant-menu-submenu-open.dark-blue': {
          borderLeftColor: get(
            theme,
            ['sider-bg-separater'],
            theme['light-blue-color']
          ),
        },
        '&.ant-menu-submenu-open.sea-blue': {
          borderLeftColor: get(
            theme,
            ['sider-bg-separater'],
            theme['sea-blue-light-color']
          ),
        },
      },
      '& .ant-menu-submenu-open': {
        color: theme['layout-trigger-color'],
        background: 'rgba(255, 255, 255, 0.1)',
        '& .ant-menu-submenu-title': {
          '& .anticon': {
            color: 'white',
            transition: 'color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
          },
          '& > span:not(.anticon)': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
        '& .ant-menu-submenu-title:hover': {
          '& .anticon': {
            color: 'white',
          },
        },
      },
      '&.ant-menu-dark .ant-menu-inline.ant-menu-sub': {
        background: 'transparent',
      },
      '&.ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover': {
        fontWeight: 'bold',
      },
      '& .ant-menu-submenu-title': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .ant-menu-submenu-open .anticon': {
        color: theme['default-red'],
      },
      '& .ant-menu-item': {
        display: 'flex',
        alignItems: 'center',
        height: 'auto !important',
        lineHeight: '18px !important',
        padding: '7px 22px !important',
        gap: '12px',
      },
      '& .ant-menu-submenu .ant-menu-submenu .ant-menu-item': {
        paddingLeft: `${MENU_INLINE_INDENT * 2}px !important`,
        fontSize: 12,
      },
      '& .ant-menu-item .anticon, .ant-menu-submenu-title .anticon': {
        marginRight: 14,
        marginLeft: -9,
      },
      '&.ant-menu-inline-collapsed': {
        '& .ant-menu-item-selected': {
          backgroundColor: 'transparent!important',
          padding: '0!important',
          display: 'flex',
          justifyContent: 'center',
          '& .anticon': {
            marginRight: 0,
            backgroundColor: theme['default-red'],
            height: 40,
            width: 40,
            borderRadius: 9,
            color: 'white',
          },
        },
      },
      '& .ant-menu-dark .ant-menu-submenu-vertical a': {
        color: 'white!important',
      },
      //do not remove these, may be useful for Menu component styling

      // '&.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected': {
      //   backgroundColor: theme['sider-bg-third'],
      // },
      // '&.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
      //   backgroundColor: theme['primary-color-opacity'],
      // },
      // '& .ant-menu-item:not(.ant-menu-item-active):hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover': {
      //   color: theme['primary-color'],
      //   backgroundColor: theme['sider-bg-secondary'],
      // },
      // '& .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before': {
      //   background: theme['primary-color'],
      // },
      // '& .ant-menu-submenu-selected': {
      //   color: theme['primary-color'],
      // },
      // // '& .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after': {
      // //   borderRight: `3px solid ${theme['primary-color']}`,
      // // },
      // '&.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active': {
      //   color: theme['primary-color'],
      // },
      // '& .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover': {
      //   backgroundColor: theme['sider-bg-secondary'],
      // },
      // '& .ant-menu-inline.ant-menu-sub': {
      //   background: theme['sider-bg-secondary'],
      // },
    },
    collapsedSidebarMenu: {
      background: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 50,
      border: 'none',
      '& .ant-menu-submenu': {
        height: 75,
        display: 'flex',
        alignItems: 'center',
        margin: 0,
        padding: 0,
      },
      '& .ant-menu-submenu-title': {
        padding: '0 !important',
      },
      '& .ant-menu-item': {
        padding: 0,
        height: 39,
        display: 'flex',
        alignItems: 'center',
        marginTop: -13,
        marginBottom: 13,
        paddingLeft: 4,
      },
    },
    collapsedMenuItem: ({ isActive }: UseLayoutStylesProps) => ({
      color: isActive ? 'white' : 'grey',
    }),
    layoutHeader: {
      padding: '0px',
      position: 'fixed',
      zIndex: 1,
      width: `calc(100% - var(--navigationMenuWidth))`,
    },
    topMenu: ({ breadcrumbsWidth = 0 }: UseLayoutStylesProps) => ({
      maxWidth: `calc(100% - ${breadcrumbsWidth}px)`,
      '& > li': {
        height: '100%',
      },
    }),
    headerMenu: {
      textAlign: 'right',
      width: '100%',
      border: 'none',
      '&.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected': {
        backgroundColor: theme['primary-color'],
      },
      '&.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover': {
        backgroundColor: theme['primary-color'],
      },
      '&.ant-menu-light .ant-menu-item-active:hover, &.ant-menu-light .ant-menu-submenu-active:hover': {
        color: theme['primary-color'],
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover': {
        color: theme['primary-color'],
        borderBottom: `0px solid transparent`,
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover': {
        borderBottom: `0px solid transparent`,
        color: theme['primary-color'],
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-title:hover': {
        color: theme['primary-color'],
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-open.ant-menu-submenu-active': {
        color: theme['primary-color'],
        borderBottom: `0px solid transparent`,
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected': {
        color: theme['primary-color'],
        borderBottom: `0px solid transparent`,
        fontWeight: 'bold',
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected': {
        color: theme['primary-color'],
        borderBottom: `0px solid transparent`,
        fontWeight: 'bold',
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-selected a': {
        height: 'calc(100% + 4px)',
        borderBottom: `2px solid ${theme['primary-color']}`,
        display: 'block',
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active a': {
        height: 'calc(100% + 4px)',
        borderBottom: `2px solid ${theme['primary-color']}`,
        display: 'block',
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active button': {
        borderBottom: `2px solid ${theme['primary-color']}`,
      },
      '&.ant-menu-horizontal.ant-menu-dark .ant-menu-submenu-open.ant-menu-submenu-active': {
        color: 'white',
        backgroundColor: theme['primary-color'],
      },
      '&.ant-menu-horizontal.ant-menu-dark .ant-menu-submenu-selected': {
        backgroundColor: theme['primary-color'],
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item': {
        margin: '1px 10px 0',
        top: '-2px',
        height: `calc(100% - 2px)`,
      },
      '&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active': {
        borderBottom: `0px solid transparent`,
      },
    },
    helpButton: {
      height: 'calc(100% + 4px)',
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    aboutButton: {
      height: 'calc(100% + 4px)',
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      '&:focus': {
        outline: 'none',
      },
    },
    layoutFooter: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px',
      height: '48px',
    },
    submenuPopup: {
      '& .ant-menu-item': {
        '&:hover': {
          backgroundColor: theme['primary-color-opacity'],
          color: theme['primary-color'],
        },
        '&:active': {
          backgroundColor: theme['primary-color-opacity'],
        },
        '&.ant-menu-item-selected': {
          color: theme['primary-color'],
          backgroundColor: theme['primary-color-opacity'],
        },
      },
      '&.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected': {
        color: 'white',
        backgroundColor: theme['primary-color'],
      },
      '&.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:hover': {
        color: 'white',
        backgroundColor: theme['primary-color'],
      },
    },
    toggleSidebar: {
      marginRight: 15,
      '& svg': {
        transition: 'background-color 0.5s',
        '&:hover': {
          backgroundColor: theme['purple-color'],
        },
      },
    },
    collapsedSidebar: {
      '& svg': {
        transition: 'background-color 0.5s',
        '&:hover': {
          backgroundColor: theme['sider-bg-third'],
        },
      },
    },
    submenuContent: {
      '&.ant-space + i': {
        display: 'none',
      },
    },
    siderWrapper: {
      backgroundColor: `${theme['sider-bg']} !important`,
      width: 'var(--navigationMenuWidth)',
    },
    appVersionInfo: {
      fontSize: 14,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      color: theme['text-color'],
    },
    collapsedArrow: { transform: 'rotate(180deg)' },
    submenuItemLink: {
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      fontSize: 12,
    },
    submenuItemLinkIcon: { marginRight: 16 },
    centeredText: { textAlign: 'center' },
    topMenuArrow: { marginLeft: 5 },
    dropdownMenuWrapper: {
      margin: 0,
      padding: 0,
      cursor: 'pointer',
      '&.ant-dropdown-open': {
        color: theme['primary-color'],
        borderBottom: `2px solid ${theme['primary-color']}`,
      },
      '&:hover': {
        borderBottom: `2px solid ${theme['primary-color']}`,
        color: theme['primary-color'],
        transition:
          'border-color .3s cubic-bezier(.645,.045,.355,1), background .3s cubic-bezier(.645,.045,.355,1)',
      },
    },
    dropdownMenuEmpty: {
      '& ul.ant-dropdown-menu': {
        padding: 0,
      },
    },
    dropdownMenu: {
      width: 200,
      '& li': {
        padding: '4px 10px',
        display: 'flex',
      },
      '& li.ant-dropdown-menu-item:hover': {
        backgroundColor: theme['dropdown-hover-gray'],
        '& > a, & > span': {
          color: theme['text-color'],
        },
      },
      '& li.ant-dropdown-menu-item:active, & li.ant-dropdown-menu-item.ant-dropdown-menu-item-selected': {
        backgroundColor: theme['dropdown-active-gray'],
        '& > a, & > span': {
          color: theme['text-color'],
        },
      },
      '& li.ant-dropdown-menu-item:hover a, li.ant-dropdown-menu-item:hover span': {
        color: theme['primary-color'],
      },
      '& li.ant-dropdown-menu-item-selected, .ant-dropdown-menu-item-selected > a': {
        color: theme['primary-color'],
        backgroundColor: 'transparent',
      },
    },
    timezoneInfo: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 230,
    },
    sideMenuLink: {
      display: 'flex',
      alignItems: 'flex-start',
      overflow: 'hidden',
    },
    section: {
      display: 'block',
      cursor: 'pointer',
      '&:not(:last-child)': {
        borderBottom: '1px solid #DDE3E8',
      },
      '& li:disabled': {
        cursor: 'not-allowed',
      },
    },
    iconWrapper: {
      color: theme['primary-color'],
      paddingRight: 9,
      minWidth: 21,
    },
    disabledLabel: { opacity: 0.4 },
  };
});

export default useLayoutStyles;
