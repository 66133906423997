import { ExtendedUiSchema } from 'components/FormPreview/customFields/ObjectField/types';
import {
  ExtendedJsonSchema,
  SchemaComponentProperty,
} from 'components/FormPreview/types';
import { RecordItem } from 'contexts/types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { NEW_SECTION_1_ID } from '../formBuilder/consts';
import { TextInputTypes } from '../formBuilder/FBGeneralInput/components/ControlPreferences/RecordView/FieldProperties/types';
import { InputTypes } from '../formBuilder/FormBuilder/enums';
import { PREVIEW_FIELD_VALUES } from './previewFieldValues';

export const getExampleValue = (
  { type, multipleOf, format, enum: fieldEnum, items }: SchemaComponentProperty,
  { 'ui:widget': widget }: any,
  elementIndex: number
) => {
  const indexRemainder = elementIndex % 3;
  const multiselectEnum = items?.enum;

  if (fieldEnum !== undefined)
    return fieldEnum[elementIndex % fieldEnum.length];

  if (multiselectEnum !== undefined)
    return [
      multiselectEnum[elementIndex % multiselectEnum.length],
      multiselectEnum[(elementIndex + 1) % multiselectEnum.length],
    ];
  switch (type) {
    case 'string':
      if (widget === TextInputTypes.MultiLine) {
        return PREVIEW_FIELD_VALUES['multilineString'][indexRemainder];
      }
      switch (format) {
        case 'date-time':
          return PREVIEW_FIELD_VALUES['dateTime'][indexRemainder];
        case 'date':
          return PREVIEW_FIELD_VALUES['date'][indexRemainder];
        case 'email':
          return PREVIEW_FIELD_VALUES['email'][indexRemainder];
        case 'phoneNumber':
          return PREVIEW_FIELD_VALUES['phone'][indexRemainder];
        case 'url':
          return PREVIEW_FIELD_VALUES['url'];
        case 'json':
          return PREVIEW_FIELD_VALUES['json'];
        default:
          return PREVIEW_FIELD_VALUES['string'][indexRemainder];
      }
    case 'number':
      if (multipleOf === 1)
        return PREVIEW_FIELD_VALUES['number'][indexRemainder];
      return PREVIEW_FIELD_VALUES['float'][indexRemainder];
    case 'boolean':
      return PREVIEW_FIELD_VALUES['boolean'][indexRemainder];
    case 'array':
      if (widget === InputTypes.Owners) return PREVIEW_FIELD_VALUES['owners'];
      if (widget === InputTypes.File) return PREVIEW_FIELD_VALUES['documents'];
  }
  if (widget === InputTypes.User) return PREVIEW_FIELD_VALUES['users'];
  return undefined;
};

export const prepareMockValues = (
  schema: ExtendedJsonSchema,
  uiSchema: ExtendedUiSchema,
  data?: ObjectRecordDetails[]
) => {
  const properties = schema?.properties?.[NEW_SECTION_1_ID]?.properties;
  const uiProperties = uiSchema?.[NEW_SECTION_1_ID];

  if (!data || !properties || !uiProperties) return [];

  const fieldKeys = Object.keys(properties);

  return data.map((record, index) => {
    const formData = fieldKeys.reduce((data, currentFieldKey) => {
      const fieldProperties = properties[currentFieldKey];
      const fieldUiProperties = uiProperties[currentFieldKey] || {};

      const fieldValue = getExampleValue(
        fieldProperties,
        fieldUiProperties,
        index
      );

      if (fieldValue !== undefined)
        return {
          ...data,
          [currentFieldKey]: fieldValue,
        };

      return data;
    }, {});

    return { ...record, formData };
  });
};

export const parseToRecordItem = ({
  id: recordId,
  object_name,
  _meta: { permissions },
}: ObjectRecordDetails): RecordItem => ({
  recordId: recordId.toString(), //handle if recordId is a number (it can be if coming from the API)
  permissions,
  identifier: object_name,
});
