import { createUseStyles } from 'react-jss';

const maxWidth = 991;

const useResetPasswordPageStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
  },
  informationWrapper: {
    width: '100%',
  },
  footer: {
    position: 'absolute',
    bottom: '10px',
    textAlign: 'center',
    fontSize: 12,
  },
  footerText: {
    fontSize: 12,
    fontWeight: 400,
  },
  [`@media (max-width: ${maxWidth}px)`]: {
    informationWrapper: {
      display: 'none',
    },
  },
});

export default useResetPasswordPageStyles;
