import React, { useRef } from 'react';
import UserAvatar from 'components/UserAvatar';
import { PhoneIcon, EmailIcon, MobilePhoneIcon } from 'components/Icon';
import clsx from 'clsx';
import { UserDetailsComponentProps } from './types';
import useDetailsStyles from '../styles';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  USER_DETAILS_AT_ICON_TESTID,
  USER_DETAILS_COMPANY_NAME,
  USER_DETAILS_EMAIL,
  USER_DETAILS_FULL_NAME,
  USER_DETAILS_JOB,
  USER_DETAILS_MOBILE_PHONE,
  USER_DETAILS_PHONE,
  USER_DETAILS_WRAPPER,
} from 'utils/testIds';

const UserDetails = ({ userData, closePopover }: UserDetailsComponentProps) => {
  const classes = useDetailsStyles({});
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    username,
    mobile,
    phone,
    job_title: job,
    account_type: accountType,
    company_name: companyName,
  } = userData || {};

  const containerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, closePopover, { event: 'click' });

  return (
    <div
      className={classes.wrapper}
      ref={containerRef}
      data-testid={USER_DETAILS_WRAPPER}
    >
      {firstName && lastName && (
        <div className={clsx([classes.center, classes.titleWrapper])}>
          <UserAvatar
            {...{ id, firstName, lastName, accountType }}
            disablePopover
            size='small'
          />
          <span
            data-testid={USER_DETAILS_FULL_NAME}
            className={clsx([classes.title, classes.wrapText])}
          >{`${firstName} ${lastName}`}</span>
        </div>
      )}
      {job && (
        <div
          data-testid={USER_DETAILS_JOB}
          className={clsx([classes.job, classes.wrapText])}
        >
          {job}
        </div>
      )}
      {companyName && (
        <div
          data-testid={USER_DETAILS_COMPANY_NAME}
          className={clsx([classes.description, classes.wrapText])}
        >
          {companyName}
        </div>
      )}
      {username && (
        <div className={classes.iconItem} data-testid={USER_DETAILS_EMAIL}>
          <EmailIcon
            className={classes.icon}
            size={16}
            data-testid={USER_DETAILS_AT_ICON_TESTID}
          />
          <a href={`mailto:${username}`} className={classes.link}>
            {username}
          </a>
        </div>
      )}
      {mobile && (
        <div className={clsx([classes.iconItem, classes.center])}>
          <MobilePhoneIcon className={classes.icon} size={16} />
          <span
            className={clsx(classes.wrapText, classes.iconText)}
            data-testid={USER_DETAILS_MOBILE_PHONE}
          >
            {mobile}
          </span>
        </div>
      )}
      {phone && (
        <div className={clsx([classes.iconItem, classes.center])}>
          <PhoneIcon className={classes.icon} size={16} />
          <span
            className={clsx(classes.wrapText, classes.iconText)}
            data-testid={USER_DETAILS_PHONE}
          >
            {phone}
          </span>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
