import useFormatNumber from 'hooks/useFormatNumber';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReassignTaskHeaderProps } from './ReassignTaskHeader.types';
import { useReassignTaskHeaderStyles } from './ReassignTaskHeader.styles';

export const ReassignTaskHeader = ({
  recordId = '',
  taskName = '',
  children,
}: ReassignTaskHeaderProps) => {
  const styles = useReassignTaskHeaderStyles();
  const formatNumberWithSeparators = useFormatNumber();

  return (
    <header className={styles.header}>
      <div className={styles.titleWrapper}>
        <div className={styles.panelTitle}>
          <FormattedMessage
            id='misc.reassignTask'
            defaultMessage='Reassign task'
          />
        </div>
        <div className={styles.resultsInfo}>
          <FormattedMessage
            id='tasksPanel.recordId'
            defaultMessage='Record ID: {recordId}'
            values={{ recordId: formatNumberWithSeparators(Number(recordId)) }}
          />
        </div>
      </div>

      <div className={styles.nameWrapper}>
        <p className={styles.taskName}>{taskName}</p>
        {children}
      </div>
    </header>
  );
};
