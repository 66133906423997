import React from 'react';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { AccountTypeOptions } from 'pages/Users/enums';
import { useIntl } from 'react-intl';
import { UsersOrGroupsTabKey } from 'utils/Enums/UsersOrGroupsTabKey';
import Tooltip from 'components/lib/Tooltip';

export const useReassignTaskTabs = (
  userAssigneesCount: number,
  groupAssigneesCount: number
) => {
  const intl = useIntl();

  const { activeTab, taskAssignees, setActiveTab } = useTasksPanelContext();

  const isGroupsTabDisabled = taskAssignees.users.some(
    user => user.account_type === AccountTypeOptions.OneTimeCompletion
  );
  const usersTabText = intl.formatMessage(
    {
      id: 'misc.usersTab',
      defaultMessage: 'Users ({count})',
    },
    { count: userAssigneesCount }
  );
  const groupsTabText = intl.formatMessage(
    {
      id: 'misc.groupsTab',
      defaultMessage: 'User groups ({count})',
    },
    { count: groupAssigneesCount }
  );
  const groupsTabDisabledText = intl.formatMessage({
    id: 'misc.oneTCUserAssignedNoFurtherGroupCanBeAssigned',
    defaultMessage:
      'A one-time completion user has been assigned, therefore no user group can be assigned.',
  });

  const handleTabChange = (tabKey: string) => {
    setActiveTab(tabKey as UsersOrGroupsTabKey);
  };

  return {
    activeTab: activeTab,
    usersTab: {
      key: UsersOrGroupsTabKey.Users,
      label: usersTabText,
    },
    groupsTab: {
      key: UsersOrGroupsTabKey.Groups,
      label: (
        <Tooltip
          shouldBeRendered={isGroupsTabDisabled}
          title={groupsTabDisabledText}
        >
          {groupsTabText}
        </Tooltip>
      ),
      isDisabled: isGroupsTabDisabled,
    },
    handleTabChange,
  };
};
