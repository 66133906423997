import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const usePredicateSelectStyles = createUseStyles(
  (theme: ThemeState) => ({
    wrapper: {
      width: '100%',

      '& > .ant-select-selector': {
        minHeight: '32px !important',
      },
    },
    divider: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      borderBottom: `1px solid ${theme['border-color-base']}`,
    },
  })
);
