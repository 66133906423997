import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const showUserAssigneesLimitExceededToast = (
  usersLimit: number | undefined
) => {
  showErrorToast({
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.error',
      defaultMessage: 'Error!',
    }),
    subtitle: GlobalIntlSingleton.intl.formatMessage(
      {
        id: 'misc.maxUserAssigneesExceeded',
        defaultMessage:
          'Maximum of {limit} user {limit, plural, one {assignee} other {assignees}} has been exceeded.',
      },
      { limit: usersLimit }
    ),
  });
};
