import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';

export const IdentifierAllowedTypes = [
  ObjectClassFieldTypes.String,
  ObjectClassFieldTypes.Email,
  ObjectClassFieldTypes.Phone,
  ObjectClassFieldTypes.Int,
  ObjectClassFieldTypes.Date,
  ObjectClassFieldTypes.Datetime,
  ObjectClassFieldTypes.Url,
];
