import { CatalystTableCellProps } from 'components/CatalystTable/components/TableRow/components/CatalystTableCell/CatalystTableCell.types';
import TagContainer from 'components/TagContainer';
import { TagTypes } from 'components/TagContainer/enums';
import { TagNames } from 'pages/ObjectClasses/components/ObjectClassForm/components/ObjectClassDisplay/components/DisplayTable/enums';
import React from 'react';

export const ClassFormEnabledCell = ({ row }: CatalystTableCellProps) => {
  const { is_enabled: isEnabled } = row;

  return (
    <TagContainer
      type={isEnabled ? TagTypes.Primary : TagTypes.Error}
      name={isEnabled ? TagNames.Enabled : TagNames.Disabled}
    />
  );
};
