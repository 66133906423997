import { UsersGroupsSelector } from 'components/UsersAndGroupsSelection/UsersGroupsSelector';
import React from 'react';
import { FilterUserAndGroupValueProps } from './FilterUserAndGroupValue.types';
import { TabbedUsersAndGroupsList } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/components/TabbedUsersAndGroupsList';
import { UsersGroupsListItemSelectAndDeselect } from 'components/UsersAndGroupsSelection/components/UsersGroupsListItemSelectAndDeselect';
import {
  SelectUserAndGroupOption,
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';
import {
  USER_GROUPS_LIST_AUTOCOMPLETE,
  USERS_LIST_AUTOCOMPLETE,
} from 'utils/endpoints';
import { SelectedUsersAndGroupsEditor } from 'components/SelectedUsersAndGroupsEditor';
import { useFilterUserAndGroupValueStyles } from './FitlerUserAndGroupValue.styles';

export const FilterUserAndGroupValue = ({
  value,
  testId,
  onChange,
}: FilterUserAndGroupValueProps) => {
  const styles = useFilterUserAndGroupValueStyles();

  const handleChange = (value: SelectUserAndGroupOption) => {
    onChange(value);
  };

  const handleUserDelete = (newUsers: SelectUserOption[]) => {
    handleChange({ users: newUsers, groups: value?.groups ?? [] });
  };

  const handleGroupDelete = (newGroups: SelectUserGroupOption[]) => {
    handleChange({ users: value?.users ?? [], groups: newGroups });
  };

  return (
    <div className={styles.wrapper} data-testid={testId}>
      <UsersGroupsSelector
        value={value ?? { users: [], groups: [] }}
        onChange={handleChange}
        popoverContent={props => (
          <TabbedUsersAndGroupsList
            {...props}
            disableMaxedOutTabs
            usersAutocompleteUrl={USERS_LIST_AUTOCOMPLETE}
            groupsAutocompleteUrl={USER_GROUPS_LIST_AUTOCOMPLETE}
            optionContent={optionProps => (
              <UsersGroupsListItemSelectAndDeselect {...optionProps} />
            )}
          />
        )}
      />

      <SelectedUsersAndGroupsEditor
        selectedUsers={value?.users ?? []}
        selectedGroups={value?.groups ?? []}
        onUserDelete={handleUserDelete}
        onGroupDelete={handleGroupDelete}
      />
    </div>
  );
};
