import { createUseStyles } from 'react-jss';

export const useTaskFormStyles = createUseStyles({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  loaderWrapper: {
    display: 'grid',
    placeContent: 'center',

    height: '25rem',
    maxHeight: '100%',
  },
  formField: {
    display: 'flex',
    flexDirection: 'column',
  },
});
