import { useIntl } from 'react-intl';
import { isNil } from 'lodash';

/**
 * Handles the logic for checking if the limit of groups that can be assigned to the resource is
 * reached.
 */
export const useGroupAssigneeesLimit = (
  groupsLimit: number | undefined,
  selectedGroupsCount: number,
  assignedGroupsCount: number
) => {
  const intl = useIntl();

  const groupsTotalCount = selectedGroupsCount + assignedGroupsCount;
  const isLimitReached = !isNil(groupsLimit) && groupsTotalCount >= groupsLimit;

  const limitReachedText = intl.formatMessage(
    {
      id: 'misc.selectedGroupAssigneesLimitReached',
      defaultMessage:
        'Maximum of {selectedGroupsCount} new user group {selectedGroupsCount, plural, one {assignee} other {assignees}} can be selected ({assignedGroupsCount} already assigned).',
    },
    {
      selectedGroupsCount,
      assignedGroupsCount,
    }
  );

  return { isLimitReached, limitReachedText };
};
