import React, { useRef } from 'react';
import clsx from 'clsx';
import MessageBox from 'components/InPlaceEditControls/components/MessageBox';
import { useToggle } from 'hooks/useToggle';
import { FormattedMessage } from 'react-intl';
import { MoreProps } from './types';
import useMoreStyles from './styles';
import { Dropdown } from 'components/lib/Dropdown';
import { useOutsideClick } from 'hooks/useOutsideClick';
import TooltipString from 'components/TooltipString';

const More = ({
  moreElements,
  visibleElementsCount,
  getPopupContainer,
  dropdownClassName,
}: MoreProps) => {
  const classes = useMoreStyles({});

  const [
    isMoreOpen,
    { toggleOff: setIsMoreClosed, toggleOn: setIsMoreOpen },
  ] = useToggle(false);

  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, setIsMoreClosed);

  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsMoreOpen();
  };

  return (
    <div className={classes.wrapper}>
      <Dropdown
        {...{ getPopupContainer }}
        visible={isMoreOpen}
        overlay={
          <MessageBox
            fitContent
            className={clsx(classes.box, dropdownClassName)}
            message={
              <div ref={dropdownRef}>
                {moreElements.map(element => (
                  <div className={classes.viewOption} key={element}>
                    <TooltipString text={element}>{element}</TooltipString>
                  </div>
                ))}
              </div>
            }
          />
        }
      >
        <span
          className={classes.textWrapper}
          onClick={handleOpen}
          role='button'
          tabIndex={0}
        >
          <FormattedMessage
            id='misc.xMore'
            defaultMessage='+{value} more'
            values={{ value: moreElements.length - visibleElementsCount }}
          />
        </span>
      </Dropdown>
    </div>
  );
};

export default More;
