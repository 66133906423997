import React from 'react';
import { useIntl } from 'react-intl';
import { COLUMNS_LIMIT } from '../../consts';
import { useColumnsAmmountAlertStyles } from './styles';
import { ColumnsAmountAlertProps } from './types';
import { Alert } from 'components/lib/Alert';

const MaxColumnsAlert = ({ currentAmount }: ColumnsAmountAlertProps) => {
  const intl = useIntl();

  const styles = useColumnsAmmountAlertStyles();

  const message = intl.formatMessage(
    {
      id: 'formBuilder.maxChildColumnsAlert',
      defaultMessage:
        'The maximum number of columns ({currentAmount}) have been reached.',
    },
    { currentAmount }
  );

  return currentAmount >= COLUMNS_LIMIT ? (
    <Alert message={message} className={styles.alert} />
  ) : null;
};

export default MaxColumnsAlert;
