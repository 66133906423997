import { createContext, useContext } from 'react';
import { TaskOptionsContextProps } from './TaskOptionsContext.types';

export const TaskOptionsContext = createContext<TaskOptionsContextProps | null>(
  null
);

export const useTaskOptionsContext = () => {
  const context = useContext(TaskOptionsContext);

  const contextName = 'TaskOptionsContext';

  if (!context) {
    throw new Error(
      `${contextName} has null value. Did you wrap your component with context's provider?`
    );
  }

  return context;
};
