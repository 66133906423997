import { AxiosError } from 'axios';
import { isNil } from 'lodash';
import { tryCorrectingFiltersBasedOnApiError } from 'pages/Records/RecordsListing/utils/tryCorrectingFiltersBasedOnApiError';
import { ThunkDispatch } from 'redux-thunk';
import { setFilters, setApplyFilter } from 'store/actions/filtersActions';
import { RootState, RootAction } from 'store/reducers';
import { getCurrentTableFilters } from 'store/selectors/filtersSelectors';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';

export const checkErrorForFilterCorrections = (
  error: AxiosError,
  state: RootState,
  dispatch: ThunkDispatch<RootState, undefined, RootAction>
) => {
  const filters = getCurrentTableFilters(state);

  const correctedFilters = tryCorrectingFiltersBasedOnApiError(filters, error);

  if (isNil(correctedFilters)) {
    showUnhandledErrorToast(error);
    return;
  }

  dispatch(setFilters(CatalystTableType.ObjectRecords, correctedFilters));
  dispatch(setApplyFilter(true, { sendToApi: true }));
};
