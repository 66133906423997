import { ColumnsMetadata } from 'utils/types/api/table.types';

export const composeAllHiddenColumns = (
  hiddenColumns: string[] | null | undefined,
  knownColumns: string[] | null | undefined,
  allColumns: MappedObject<ColumnsMetadata>
) => {
  if (hiddenColumns === null || hiddenColumns === undefined) return null;

  return [
    ...(hiddenColumns || []),
    ...Object.keys(allColumns).filter(
      column => knownColumns !== undefined && !knownColumns?.includes(column)
    ),
  ];
};
