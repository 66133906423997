import { FilterValue } from 'react-table';
import { Logger, LogLevel } from 'utils/logger';
import { correctFilterAvailableValues } from './correctFilterAvailableValues';
import { correctFilterSelectedValues } from './correctFilterSelectedValues';
import { extractFilterValueFromError } from './extractFilterValueFromError';
import { findFilterWithKey } from './findFilterWithKey';
import { removeFilter } from './removeFilter';
import { shouldRemoveFilter } from './shouldRemoveFilter';
// eslint-disable-next-line
import { checkResponseForFilterErrors } from '../../checkResponseForFilterErrors/checkResponseForFilterErrors';
import { InvalidFilterData } from '../../../types/InvalidFilterData';

/**
 * Scans the provided filters and list of invalid filter entries and tries to correct those filters by either
 * removing wrong filter form the list or correcting their selected values (if possible).
 * @param invalidEntry Object that represents data of invalid filter. Usually retrieved with {@link checkResponseForFilterErrors} functions.
 * @param filters filters list to modify.
 */
export const correctFiltersForInvalidEntry = (
  invalidEntry: InvalidFilterData,
  filters: FilterValue[]
) => {
  const filter = findFilterWithKey(invalidEntry.fieldKey, filters);

  if (!filter) {
    return;
  }

  invalidEntry.invalidValues.forEach(error => {
    const filterValueInError = extractFilterValueFromError(error);

    Logger.log(
      `Filter value: ${filterValueInError} no longer exists. Removing from filter.`,
      LogLevel.Verbose
    );

    correctFilterAvailableValues(filterValueInError, filter);

    if (shouldRemoveFilter(filterValueInError, filter)) {
      removeFilter(filters, filter);
      return;
    }

    correctFilterSelectedValues(filterValueInError, filter);
  });
};
