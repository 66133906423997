import React from 'react';
import TooltipString from 'components/TooltipString';
import { useHighlightedAppliedFilterValue } from 'hooks/useHighlightedAppliedFilterValue';
import { CommaSeparatedStringProps } from './CommaSeparatedString.types';

export const CommaSeparatedString = ({
  values,
  highlightedColumnKey,
}: CommaSeparatedStringProps) => {
  const { rawText, highlightedText } = useHighlightedAppliedFilterValue(
    values,
    highlightedColumnKey
  );

  return <TooltipString text={rawText} modifiedText={highlightedText} />;
};
