import React from 'react';
import { UnassignTaskGroupAssigneeModalProps } from './UnassignTaskGroupAssigneeModal.types';
import { useIntl } from 'react-intl';
import ConfirmationModal from 'components/ConfirmationModal';
import WarningIllustration from 'img/illustrations/warning-triangle.svg';

/**
 * Confirmation modal for unassigning a user group from a task.
 */
export const UnassignTaskGroupAssigneeModal = ({
  groupToUnassign,
  isLoading,
  onConfirm,
  onCancel,
}: UnassignTaskGroupAssigneeModalProps) => {
  const intl = useIntl();

  const title = intl.formatMessage(
    {
      id: 'misc.sureToUnassign',
      defaultMessage: 'Are you sure you want to unassign {name}?',
    },
    {
      name: groupToUnassign?.name,
    }
  );
  const subtitle = intl.formatMessage({
    id: 'tasksPanel.groupMayLoseAccessToTask',
    defaultMessage: 'Members of the group may lose access to this task.',
  });
  const confirmBtnText = intl.formatMessage({
    id: 'misc.yesUnassign',
    defaultMessage: 'Yes, unassign',
  });

  return (
    <ConfirmationModal
      visible={!!groupToUnassign}
      isLoading={isLoading}
      title={title}
      subtitle={subtitle}
      confirmationButtonLabel={confirmBtnText}
      image={WarningIllustration}
      withCancel
      onClose={onCancel}
      onConfirm={onConfirm}
    />
  );
};
