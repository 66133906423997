export const calculateFixedSizeListHeight = (
  tableBodyRef: React.MutableRefObject<DOMRect | null>,
  preventOverflow: boolean,
  pageSize: number,
  cellHeight: number
) => {
  let fixedSizeListSize = tableBodyRef.current
    ? window.innerHeight - tableBodyRef?.current?.top
    : 600;

  if (preventOverflow) {
    fixedSizeListSize = pageSize * cellHeight;
  }

  return fixedSizeListSize;
};
