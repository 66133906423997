import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { rightActionButton } from 'styles/panelsStyles';

type UseTaskItemStylesProps = {
  isSelected?: boolean;
};

export const useTaskItemStyles = createUseStyles((theme: ThemeState) => ({
  taskName: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 1.3,
    color: theme['heading-color'],
    margin: 0,
  },
  dateWrapper: {
    fontSize: 14,
    lineHeight: '19px',
    marginTop: 5,
    marginBottom: 3,
    display: 'block',
  },
  wrapper: ({ isSelected }: UseTaskItemStylesProps) => ({
    display: 'flex',
    flex: 1,
    padding: '8px 12px',
    backgroundColor: isSelected ? theme['primary-color-light'] : 'unset',
    width: '100%',
  }),
  leftCol: {
    flex: 1,
  },
  rightCol: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  completeSection: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 12,
  },
  label: {
    paddingRight: 8,
    color: theme['secondary-color'],
  },
  button: rightActionButton,
  taskNameWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    '& .ant-dropdown-trigger': {
      padding: 0,
      height: 22,
      marginLeft: 4,
    },
    '& .ant-btn-circle': {
      minWidth: 22,
    },
  },
}));
