import React, { useMemo } from 'react';
import { TableContextProviderProps } from './TableContextProvider.types';
import { TableContext } from './TableContext';

export const TableContextProvider = (props: TableContextProviderProps) => {
  const contextValue: TableContextProviderProps = useMemo(
    () => ({
      ...props,
    }),
    [props]
  );

  const { children } = props;

  return (
    <TableContext.Provider value={contextValue}>
      {children}
    </TableContext.Provider>
  );
};
