import React from 'react';
import { ExpandablePickerUserGroupItem } from './components/ExpandablePickerUserGroupItem';
import { ExpandablePickerItemListProps } from './ExpandablePickerItemList.types';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { ExpandablePickerUserItem } from './components/ExpandablePickerUserItem';

/**
 * Shows a combined list of users and user groups.
 * Users are directly selectable as a toggle.
 * Groups are expandable with a click to its user list and additional UX options.
 */
export const ExpandablePickerItemList = ({
  items,
  onExpandedPanelOpen,
}: ExpandablePickerItemListProps) => {
  return (
    <>
      {items.map(item => {
        const { id } = item;

        if (item.type === UserOrGroupSelectOptionType.Group) {
          return (
            <ExpandablePickerUserGroupItem
              key={`group-${id}`}
              item={item}
              limitReached={false}
              onPopupOpen={onExpandedPanelOpen}
            />
          );
        }

        return <ExpandablePickerUserItem key={`user-${id}`} item={item} />;
      })}
    </>
  );
};
