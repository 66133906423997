import { TaskFormFields } from './enums';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  [TaskFormFields.TaskName]: Yup.string(),
  [TaskFormFields.Prompt]: Yup.string(),
  [TaskFormFields.Description]: Yup.string().max(2000),
  [TaskFormFields.Instructions]: Yup.string(),
  [TaskFormFields.TaskCreation]: Yup.string(),
  [TaskFormFields.CompleteBy]: Yup.string(),
  [TaskFormFields.CompleteByTime]: Yup.number(),
  [TaskFormFields.CompleteByDate]: Yup.date(),
  [TaskFormFields.CompleteByTimeUnit]: Yup.string(),
});

export const completeByFieldsKeys = [
  TaskFormFields.CompleteBy,
  TaskFormFields.CompleteByDate,
  TaskFormFields.CompleteByTimeUnit,
  TaskFormFields.CompleteByTime,
];

export const expiryFieldsKeys = [
  TaskFormFields.Expiry,
  TaskFormFields.ExpiryDate,
  TaskFormFields.ExpiryDay,
  TaskFormFields.ExpiryPeriodicDay,
  TaskFormFields.ExpiryPeriodicMonth,
  TaskFormFields.ExpiryTimeRelTo,
  TaskFormFields.ExpiryTimeUnit,
  TaskFormFields.ExpiryTimeValue,
];

export const renewalFieldsKeys = [
  TaskFormFields.RenewalTimeValue,
  TaskFormFields.RenewalTimeUnit,
];

export const lifecycleFieldsKeys = [
  ...completeByFieldsKeys,
  ...expiryFieldsKeys,
  ...renewalFieldsKeys,
];

export const CUSTOM_DATE_FORMAT = 'YYYY-MM-DD';
export const CUSTOM_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export const FORM_BUILDER_SIDEPANEL_MIN_WIDTH = 230;
export const FORM_BUILDER_SIDEPANEL_MAX_WIDTH = 770;
