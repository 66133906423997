import { createUseStyles } from 'react-jss';

export const useTaskFormInstructionsSectionStyles = createUseStyles({
  sectionWrapper: {
    '& .ant-collapse-header': {
      margin: '0px -15px 15px',
    },
    '& .ant-collapse-content': {
      minHeight: '200px',
    },
  },
});
