import { AvatarItem } from 'components/lib/Avatar/types';
import { orderBy } from 'lodash';

/**
 * Sorts user avatars by full name.
 *
 * @param users User avatars to sort.
 *
 * @returns Alphatecially sorted list of user avatars.
 */
export const sortUserAvatars = (users: AvatarItem[]) => {
  return orderBy(users, user => {
    const fullName = `${user.firstName} ${user.lastName}`;

    return fullName.toLowerCase();
  });
};
