import {
  SelectUserOption,
  SelectUserGroupOption,
} from 'utils/types/selectInput.types';
import { UserAvatarData, UserGroupAvatarData } from './types';
import { useUserAndGroupAvatars } from './hooks/useUserAndGroupAvatars';

/**
 * Special wrapper for {@link useUserAndGroupAvatars} hook that transforms specifically user
 * and user group select options into singular avatar list.
 *
 * @param users User select options to transform.
 * @param groups Group select options to transform.
 *
 * @returns Alphatecially sorted list of user and group avatars.
 */
export const useSelectOptionAvatars = (
  users: SelectUserOption[],
  groups: SelectUserGroupOption[]
) => {
  const transformedUsers: UserAvatarData[] = users.map(user => ({
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    accountType: user.account_type,
    isDeleted: !!user.is_deleted,
  }));
  const transformedGroups: UserGroupAvatarData[] = groups.map(group => ({
    id: group.id,
    name: group.text,
  }));

  return useUserAndGroupAvatars(transformedUsers, transformedGroups);
};
