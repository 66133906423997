import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useToastsContainerStyles = createUseStyles(
  (theme: ThemeState) => ({
    toastify: {
      width: '400px',

      '& .Toastify__toast': {
        fontSize: '0.75rem',
        boxShadow: 'none',
        cursor: 'auto',
      },
      '& .Toastify__toast--info': {
        backgroundColor: theme['toast-info-color'],
        color: theme['toast-info-text-color'],
      },
      '& .Toastify__toast--success': {
        backgroundColor: theme['toast-success-color'],
        color: theme['toast-success-text-color'],
      },
      '& .Toastify__toast--warning': {
        backgroundColor: theme['toast-warning-color'],
        color: theme['toast-warning-text-color'],
      },
      '& .Toastify__toast--error': {
        backgroundColor: theme['toast-error-color'],
        color: theme['toast-error-text-color'],
      },

      '& .Toastify__progress-bar--info': {
        backgroundColor: theme['toast-info-progress-color'],
      },
      '& .Toastify__progress-bar--success': {
        backgroundColor: theme['toast-success-progress-color'],
      },
      '& .Toastify__progress-bar--warning': {
        backgroundColor: theme['toast-warning-progress-color'],
      },
      '& .Toastify__progress-bar--error': {
        backgroundColor: theme['toast-error-progress-color'],
      },

      '& .Toastify__close-button': {
        color: 'inherit',

        outline: 'none',

        '& > svg': {
          height: '0.875rem',
          width: '0.875rem',
        },
      },
    },
  })
);
