import { UsageViewConfig } from 'components/ResourceUsagePanelView/types/usageViewConfig';
import { UsageViewType } from 'components/ResourceUsagePanelView/types/usageViewType';
import useData from 'hooks/useData';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { TASK_GROUP_TEMPLATE_USAGE } from 'utils/endpoints';
import { ObjectClassUsage } from 'utils/types/api/objectClasses.types';

const useTaskGroupUsage = (id?: string) => {
  const intl = useIntl();
  const {
    loading,
    error,
    data: { sequences = [] } = {},
  } = useTaskGroupUsageData(id);

  const displayConfig = useMemo<UsageViewConfig[]>(
    () => [
      {
        usage: sequences,
        title: intl.formatMessage({
          id: 'misc.sequences',
          defaultMessage: 'Sequences',
        }),
        type: UsageViewType.List,
      },
    ],
    [intl, sequences]
  );

  return { loading, error, displayConfig };
};

const useTaskGroupUsageData = (id?: string) => {
  const [data, { loading, error, fetchData }] = useData<ObjectClassUsage>(
    id !== undefined
      ? generatePath(TASK_GROUP_TEMPLATE_USAGE, {
          id,
        })
      : '',
    {
      fetchOnLoad: false,
    }
  );

  useEffect(() => {
    if (id !== undefined) fetchData();
  }, [fetchData, id]);

  return { error, loading, data };
};

export default useTaskGroupUsage;
