import React, { useCallback, useMemo } from 'react';
import {
  setFilterColumn,
  setFilterPredicateSet,
} from 'store/actions/filtersActions';
import { Space } from 'components/lib/Space';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from 'components/lib/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentTable } from 'store/selectors/filtersSelectors';
import {
  REMOVE_SINGLE_FILTER_TESTID,
  SINGLE_FILTER_CONTAINER_TESTID,
} from 'utils/testIds';
import { PredicateSet, TPredicateTypes } from 'utils/types/predicates.types';
import { AutocompleteSelectValue } from 'utils/types/selectInput.types';
import { isAutoCompleteValueString } from './utils';
import { FilterItemProps } from './FilterItem.types';
import { useFilterItemStyles } from './FilterItem.styles';
import { ColumnSelectPure } from '../ColumnSelect';
import { PredicateSelect } from './components/PredicateSelect';
import { Predicate } from './components/Predicate';
import { AutocompleteObjectClassFieldFilterValue } from 'components/SidePanels/FiltersPanel/types';

export const FilterItem = ({
  columns,
  usedColumns,
  filter,
  onRemoveFilter,
}: FilterItemProps) => {
  const dispatch = useDispatch();
  const tableName = useSelector(getCurrentTable);
  const styles = useFilterItemStyles();

  const {
    column: filterValue,
    value,
    predicateSet,
    label,
    values: filterValues,
    predicates,
  } = filter;
  const type = filter.type as TPredicateTypes;

  const onColumnChange = useCallback(
    (
      newColumn:
        | AutocompleteSelectValue
        | AutocompleteObjectClassFieldFilterValue
        | string
    ) => {
      let columnName;
      if (typeof newColumn === 'string') {
        columnName = newColumn;
      } else {
        columnName = newColumn.value;
      }
      const existingColumn = columns?.[columnName];

      if (existingColumn && !existingColumn.id) {
        // no id idicates that the column is not a class_field, but a common property of it's type
        dispatch(
          setFilterColumn(
            tableName ?? '',
            filterValue,
            existingColumn.alias,
            existingColumn.type,
            existingColumn.label,
            existingColumn.predicates,
            existingColumn.values
          )
        );
        return;
      }

      if (isAutoCompleteValueString(newColumn)) {
        // use info retrieved from backend - this should occur only on class fields filter
        dispatch(
          setFilterColumn(
            tableName ?? '',
            filterValue,
            newColumn.value,
            newColumn.field_type,
            newColumn.label,
            newColumn.predicates,
            newColumn.values
          )
        );
      }
    },
    [dispatch, columns, filterValue, tableName]
  );

  const onPredicateSetChange = useCallback(
    (predicateSet: PredicateSet | undefined) =>
      dispatch(
        setFilterPredicateSet(tableName || '', filterValue, predicateSet)
      ),
    [dispatch, filterValue, tableName]
  );

  const onRemoveClick = useCallback(() => onRemoveFilter(filterValue), [
    onRemoveFilter,
    filterValue,
  ]);

  const { values, autocomplete } = useMemo(() => columns?.[filterValue] ?? {}, [
    filterValue,
    columns,
  ]);

  return (
    <div
      className={styles.filterItem}
      data-testid={SINGLE_FILTER_CONTAINER_TESTID}
    >
      <Space direction='vertical' className={styles.wrapper}>
        <label>
          <FormattedMessage id='misc.field' defaultMessage='Field' />
        </label>

        <ColumnSelectPure
          columns={columns}
          usedColumns={usedColumns}
          predicateSet={predicateSet}
          onPredicateSetChange={onPredicateSetChange}
          label={label}
          onChange={onColumnChange}
          defaultValue={filterValue !== '' ? [filterValue] : undefined}
        />

        {filterValue && (
          <>
            <PredicateSelect
              allowedPredicates={predicates}
              value={value.predicateKey}
              filterKey={filterValue}
              type={type}
            />
            <Predicate
              filterKey={filterValue}
              value={value}
              type={type}
              predicateSet={predicateSet}
              selectOptions={values ?? filterValues}
              autocompleteUrl={autocomplete}
            />
          </>
        )}

        <div className={styles.container}>
          <ButtonLink
            onClick={onRemoveClick}
            data-testid={REMOVE_SINGLE_FILTER_TESTID}
          >
            <FormattedMessage id='misc.remove' defaultMessage='Remove' />
          </ButtonLink>
        </div>
      </Space>
    </div>
  );
};
