import { detect } from 'detect-browser';
import { createUseStyles } from 'react-jss';

const HORIZONTAL_SCROLL_HEIGHT = 12;

type UseTableBodyStylesProps = {
  loading?: boolean;
  addColumnMode?: boolean;
  isInitialLoading?: boolean;
  isNoData?: boolean;
  preventOverflow?: boolean;
  tableBodyTopOffset?: number;
};

export const useTableBodyStyles = createUseStyles({
  tableBody: {
    position: 'relative',
    pointerEvents: ({ loading, addColumnMode }: UseTableBodyStylesProps) =>
      loading || addColumnMode ? 'none' : 'auto',
    overflow: ({
      addColumnMode,
      isInitialLoading,
      isNoData,
      preventOverflow,
    }: UseTableBodyStylesProps) => {
      if (preventOverflow) {
        return 'none';
      } else if (addColumnMode || isInitialLoading || isNoData) {
        return 'hidden';
      } else {
        return 'auto';
      }
    },
    maxHeight: ({
      tableBodyTopOffset,
      preventOverflow,
    }: UseTableBodyStylesProps) => {
      return preventOverflow
        ? '100%'
        : `calc(100vh - ${
            (tableBodyTopOffset || 0) + HORIZONTAL_SCROLL_HEIGHT
          }px)`;
    },
    height: '100%',
    width: '100%',
    paddingRight:
      detect()?.name === 'safari' || detect()?.name === 'firefox' ? 15 : 0,
  },
});
