export enum AccountTypeOptions {
  OneTimeCompletion = 'one_time_completion',
  /**
   * Also known as ConfigAdmin
   */
  Standard = 'full',
  SuperAdmin = 'super_admin',
  External = 'external',
  Internal = 'internal',
}
