import React from 'react';
import { TaskFormTaskTemplateFieldProps } from './TaskFormTaskTemplateField.types';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { FormattedMessage, useIntl } from 'react-intl';
import FAutocompleteSelect from 'components/AutocompleteSelect/FAutocompleteSelect';
import { useFormikContext } from 'formik';
import { TaskFormType } from '../../types';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';

export const TaskFormTaskTemplateField = ({
  isRequired,
  taskTypeSpecificOptions,
  className,
}: TaskFormTaskTemplateFieldProps) => {
  const intl = useIntl();

  const {
    selectedResource: { objectClassId } = {},
  } = useSelectedResourceContext();
  const { values } = useFormikContext<TaskFormType>();

  const selectedTaskType = values[TaskFormField.TaskType];

  const getAutocompleteUrl = () => {
    const autocompleteUrlBase =
      taskTypeSpecificOptions?.[selectedTaskType].autocomplete ?? '';

    return autocompleteUrlBase.replace('{object_id}', objectClassId ?? '0');
  };

  return (
    <div className={className}>
      <FormLabel bolded={false} required={isRequired}>
        <FormattedMessage
          id='tasksPanel.form.taskTemplate'
          defaultMessage='Task template'
        />
      </FormLabel>

      <FAutocompleteSelect
        key={selectedTaskType}
        name={TaskFormField.TaskTemplate}
        placeholder={intl.formatMessage({
          id: 'tasksPanel.form.taskTemplatePlaceholder',
          defaultMessage: 'Search for task templates',
        })}
        autocompleteUrl={getAutocompleteUrl()}
        withBoldedSearchPhrase
      />
    </div>
  );
};
