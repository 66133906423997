import { DEFAULT_SPACING_AROUND } from 'components/formBuilder/consts/defaultSpacingAround';
import { DEFAULT_SPACING_WITHIN } from 'components/formBuilder/consts/defaultSpacingWithin';
import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

type UseSectionStylesProps = {
  spacingAround?: number;
  spacingWithin?: number;
};

export const useSectionStyles = createUseStyles((theme: ThemeState) => {
  return {
    section: ({ spacingAround, spacingWithin }: UseSectionStylesProps) => ({
      '--spacingAround': `${spacingAround ?? DEFAULT_SPACING_AROUND}px`,
      '--spacingWithin': `${spacingWithin ?? DEFAULT_SPACING_WITHIN}px`,
      '& .ant-collapse-content': {
        padding: '0px 0px',
        overflow: 'visible !important',
      },
      '& .ant-collapse-item': {
        '& .ant-collapse-header': {
          minHeight: 30,
          paddingLeft: '15px !important',
          paddingRight: '15px !important',
          fontSize: 12,
          lineHeight: 1.3,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',

          '& svg': {
            right: '10px !important',
          },
        },
        '& .ant-collapse-content': {
          backgroundColor: theme['section-content-bg'],

          '& .ant-collapse-content-box > .ant-row > .ant-col > div': {
            margin: 'var(--spacingAround, 5px)',
          },
        },
      },
    }),
  };
});
