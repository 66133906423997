import React from 'react';

export interface IconProps {
  size?: number;
  style?: React.CSSProperties;
  className?: string;
  width?: number;
  height?: number;
  minWidth?: number;
  id?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
}

export interface WrapperProps extends IconProps {
  Svg: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
}

export enum FallbackLogoOptions {
  SMALL = 'small',
  NORMAL = 'normal',
  SIDEBAR = 'sidebar',
}

export interface LogoIconProps extends Optional<WrapperProps, 'Svg'> {
  fallbackLogo: FallbackLogoOptions;
  useDefaultAlxLogo?: boolean;
}
