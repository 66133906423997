import EmptyData from 'components/EmptyData';
import NoMatchesFound from 'components/NoMatchesFound';
import { Loader } from 'components/lib/Loader';
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { UsersAndGroupsExpandablePickerProps } from './types';
import { ExpandablePickerItemList } from './components/ExpandablePickerItemList';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import { getContainerRef } from 'components/InPlaceEditControls/InPlaceEditMultiSelect/utils';
import { useUsersAndGroupsExpandablePickerVisibleItems } from './hooks';
import { useUsersAndGroupsExpandablePickerContext } from '../../contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import { useUsersAndGroupsExpandablePickerStyles } from './UsersAndGroupsExpandablePicker.styles';
import { ValidationListing } from './components/ValidationListing';
import { UsersAndGroupsPickerRejectButton } from './components/UsersAndGroupsPickerRejectButton';
import {
  USERS_AND_GROUPS_SELECT_ITEMS_LIST_TESTID,
  USERS_AND_GROUPS_SELECT_MAIN_DROPDOWN_CONTENT_TESTID,
  USERS_AND_GROUPS_SELECT_OPTIONS_VIEWPORT_TESTID,
} from 'utils/testIds';

/**
 * Content for {@link UsersAndGroupsExpandableSelect} that displays groups and users together on a single list.
 * Users are directly pickable using select & deselect UX.
 * Groups are expandable with dropdown which allows syncing the whole group or picking individual users belonging to a group.
 */
export const UsersAndGroupsExpandablePicker = ({
  isLoadingAvailableItems,
  onExpandedPanelStateChange,
}: UsersAndGroupsExpandablePickerProps) => {
  const [isSecondaryPanelOpen, setIsSecondaryPanelOpen] = useState(false);

  const {
    availableItems,
    searchText,
    selectedItems,
    editModeOptions,
    errors,
  } = useUsersAndGroupsExpandablePickerContext();

  const isError = errors !== undefined && errors.length > 0;
  const styles = useUsersAndGroupsExpandablePickerStyles({
    isError,
  });

  const { elementRef } = useShouldRenderOnTop<HTMLDivElement>(getContainerRef);
  const intl = useIntl();

  const onPopupOpen = useCallback(
    newOpenState => {
      setIsSecondaryPanelOpen(newOpenState);

      if (onExpandedPanelStateChange) {
        onExpandedPanelStateChange(newOpenState);
      }
    },
    [onExpandedPanelStateChange]
  );

  const {
    visibleItems,
    allItems,
  } = useUsersAndGroupsExpandablePickerVisibleItems(
    availableItems,
    selectedItems,
    searchText
  );

  return (
    <div
      ref={elementRef}
      className={styles.errorBorder}
      data-testid={USERS_AND_GROUPS_SELECT_MAIN_DROPDOWN_CONTENT_TESTID}
      onClick={e => e.stopPropagation()}
      onFocus={e => e.stopPropagation()}
    >
      <div
        className={styles.optionsViewport}
        data-testid={USERS_AND_GROUPS_SELECT_OPTIONS_VIEWPORT_TESTID}
      >
        {isLoadingAvailableItems ? (
          <div className={styles.loadingContainer}>
            <Loader size='small' />
          </div>
        ) : (
          <div
            className={styles.optionList}
            data-testid={USERS_AND_GROUPS_SELECT_ITEMS_LIST_TESTID}
          >
            {visibleItems.length > 0 && (
              <ExpandablePickerItemList
                items={visibleItems}
                onExpandedPanelOpen={onPopupOpen}
              />
            )}
            {visibleItems.length === 0 &&
              allItems.length > 0 &&
              !isLoadingAvailableItems && <NoMatchesFound />}
            {visibleItems.length === 0 &&
              allItems.length === 0 &&
              !isLoadingAvailableItems && (
                <EmptyData
                  title=''
                  description={intl.formatMessage({
                    id: 'misc.noUsersAvailable',
                    defaultMessage: 'No users available',
                  })}
                />
              )}
          </div>
        )}
        {editModeOptions?.isSaving && (
          <div className={styles.savingOverlay}></div>
        )}
      </div>
      <ValidationListing />
      {editModeOptions && !isSecondaryPanelOpen && (
        <UsersAndGroupsPickerRejectButton
          onClick={editModeOptions.onRejection}
          isSaving={editModeOptions.isSaving}
        />
      )}
    </div>
  );
};
