export enum TaskTypes {
  Form = 'tt_form',
  RecordUpdate = 'tt_object_record_update',
}

export enum TaskResponseDataTypes {
  Json = 't_json',
  Bool = 't_bool',
  ObjectRecordFieldChanges = 't_field_change_log',
  DocumentGeneratorStatus = 't_document_generator_status',
  DocumentReviewStatus = 't_document_review_status',
  DocumentSignatureStatus = 't_document_signature_status',
  AddressHistory = 't_address_history',
  InstructionsStatus = 't_instructions_status',
  Unknown = 't_unknown',
}

export enum FillInAFormTaskResponse {
  Form = 't_form',
}

export enum ReminderNoticeTypes {
  TaskCompleteBy = 'task_complete_by',
  TaskOverdue = 'task_overdue',
  TaskExpiry = 'task_expiry',
}

export enum TimeUnits {
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
}
