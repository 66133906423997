import React from 'react';
import { AlertIconProps } from './AlertIcon.types';
// eslint-disable-next-line
import { Alert } from '../Alert';
import { ReactComponent as ErrorIcon } from 'img/icons/exclamation-mark.svg';
import { ReactComponent as WarningIcon } from 'img/icons/warning-exclamation-mark.svg';
import { ReactComponent as InfoIcon } from 'img/icons/info-mark.svg';
import { ReactComponent as SuccessIcon } from 'img/icons/success-mark.svg';

/**
 * Renders the icon for {@link Alert} component based on it's type.
 */
export const AlertIcon = ({ type, className }: AlertIconProps) => {
  const Icon = (() => {
    switch (type) {
      case 'success':
        return SuccessIcon;
      case 'warning':
        return WarningIcon;
      case 'error':
        return ErrorIcon;
      default:
        return InfoIcon;
    }
  })();

  return <Icon className={className} />;
};
