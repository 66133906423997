import React, { useCallback, useState } from 'react';
import {
  RcCustomRequestOptions,
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadFileStatus,
} from 'antd/lib/upload/interface';
import {
  CustomFileItem,
  ExtenedFileUpload,
  FileUploadParams,
  UploadResponse,
  UseUploadFiles,
} from './types';
import { apiCall } from 'utils/api';
import { FILE_UPLOAD } from 'utils/endpoints';
import { useIntl } from 'react-intl';
import { CANCEL_MSG, DEFAULT_MAX_ITEMS, MAX_FILESIZE_MB } from './consts';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import axios, { AxiosError, CancelTokenSource } from 'axios';
import encodeRFC3986URIComponent from 'utils/encodeRFC3986URIComponent';
import { StatusCodes } from 'http-status-codes';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const useFieldsValidation = ({
  maxItems = DEFAULT_MAX_ITEMS,
  files,
  allowedExtensions = undefined,
  noSizeMessage,
}: FileUploadParams) => {
  const [error, setError] = useState<React.ReactNode | string>();
  const intl = useIntl();

  const beforeUpload = (file: RcFile, fileList: RcFile[]) => {
    setError('');
    if (fileList.length + files.length > maxItems) {
      const filesCountExceeded = intl.formatMessage(
        {
          id: 'formBuilder.fileUploadError.tooMany',
          defaultMessage:
            '{maxItems, plural, one {Only a single file can be uploaded} other {Max number of files (<bold>#</bold>) was exceeded}}',
        },
        { maxItems, bold: (...chunks) => <b>{chunks}</b> }
      );

      setError(filesCountExceeded);

      return Promise.reject(filesCountExceeded);
    }
    const extension = file.name.split('.').pop() ?? '';

    if (allowedExtensions && !allowedExtensions.includes(`.${extension}`)) {
      const notAllowedFormat = intl.formatMessage({
        id: 'formBuilder.fileUploadError.youCannotUploadFile',
        defaultMessage: 'You cannot upload files of that type',
      });

      setError(notAllowedFormat);

      return Promise.reject(notAllowedFormat);
    }

    if (noSizeMessage) {
      if (fileList.some(({ size }) => size <= 0)) {
        setError(noSizeMessage);

        return Promise.reject(noSizeMessage);
      }
    }

    if (fileList.some(({ size }) => size / (1024 * 1024) > MAX_FILESIZE_MB)) {
      const fileTooBig = intl.formatMessage(
        {
          id: 'formBuilder.fileUploadError.tooBig',
          defaultMessage:
            'Selected file - {fileName} is too big. Max file size is {maxSize}MB',
        },
        { fileName: file.name, maxSize: MAX_FILESIZE_MB }
      );
      setError(fileTooBig);

      return Promise.reject(fileTooBig);
    }

    return true;
  };

  return {
    limitExceeded: files.length >= maxItems,
    beforeUpload,
    error,
  };
};

export const useUploadFiles = ({
  onChange,
  onUpload,
  onUploadCompleted,
  customUploadUrl,
  customProgressCalulator,
}: UseUploadFiles) => {
  const intl = useIntl();
  const [progressData, setProgressData] = useState<MappedObject<number>>(
    {} as MappedObject<number>
  );
  const [cancelSources, setCancelSources] = useState<
    MappedObject<CancelTokenSource>
  >({} as MappedObject<CancelTokenSource>);

  const handleOnChange = useCallback(
    (info: UploadChangeParam<UploadFile<UploadResponse>>) => {
      const { status } = info.file;

      const parseItems = (newStatus: UploadFileStatus) =>
        (info.fileList as ExtenedFileUpload[]).map<CustomFileItem>(
          ({
            name,
            status,
            response: { token } = {},
            size,
            type,
            uid,
            token: savedToken,
            url,
          }) => ({
            name,
            id: uid,
            token: token || savedToken,
            url,
            status: status || newStatus,
            size,
            type,
          })
        );

      if (status && onChange) {
        onChange(parseItems(status).filter(({ status }) => status !== 'error'));
      }
    },
    [onChange]
  );

  const uploadFile = async ({
    onSuccess,
    onError,
    file,
  }: RcCustomRequestOptions) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const manualSetProgress = (progress: number) => {
      setProgressData(prev => ({
        ...prev,
        [file.uid]: progress,
      }));
    };

    const config = {
      headers: {
        'Content-Type': file.type,
        'Content-Disposition': `attachment; filename*=UTF-8''${encodeRFC3986URIComponent(
          file.name
        )}`,
      },
      onUploadProgress: (event: { loaded: number; total: number }) => {
        setProgressData(prev => ({
          ...prev,
          [file.uid]: customProgressCalulator
            ? customProgressCalulator(event.loaded, event.total)
            : Math.floor((event.loaded / event.total) * 100),
        }));
      },
      cancelToken: source.token,
    };

    setCancelSources(prev => ({
      ...prev,
      [file.uid]: source,
    }));

    try {
      // this call to onUpload tells when field started uploading
      if (onUpload) onUpload(file.uid, true);

      const { data } = await apiCall.post(
        customUploadUrl || FILE_UPLOAD,
        file,
        config
      );

      if (onUploadCompleted)
        await onUploadCompleted(data?.token, file.name, manualSetProgress);

      onSuccess({ token: data?.token }, file);
    } catch (err) {
      const error = err as AxiosError;

      const isCancelled = error?.message === CANCEL_MSG;

      onError(err as Error, false, file);

      if (error.response?.status === StatusCodes.BAD_REQUEST) {
        const notAllowedFormat = intl.formatMessage({
          id: 'formBuilder.fileUploadError.notAllowedType',
          defaultMessage: 'Unsupported file format.',
        });

        showErrorToast({
          title: intl.formatMessage({
            id: 'misc.error',
            defaultMessage: 'Error!',
          }),
          subtitle: notAllowedFormat,
        });

        return;
      }

      if (isCancelled) return;

      if (error?.message)
        showErrorToast({
          title: intl.formatMessage({
            id: 'misc.error',
            defaultMessage: 'Error!',
          }),
          subtitle: error.message,
        });
      else showUnhandledErrorToast(error);
    } finally {
      // this call to onUpload tells when field is no longer uploading
      if (onUpload) onUpload(file.uid, false);

      const getFilteredIds = <T,>(value: MappedObject<T>) =>
        Object.fromEntries(
          Object.entries(value).filter(([key]) => key !== file.uid)
        );

      setCancelSources(prev => getFilteredIds<CancelTokenSource>(prev));
      setProgressData(prev => getFilteredIds<number>(prev));
    }
  };

  const skipUpload = (options: RcCustomRequestOptions) => {
    const { onSuccess, file } = options;

    setTimeout(() => {
      onSuccess({}, file);
    }, 0);
  };

  return {
    handleOnChange,
    uploadFile,
    progressData,
    skipUpload,
    cancelSources,
  };
};
