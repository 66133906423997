import React, { useRef } from 'react';
import { Avatar as AntAvatar } from 'antd';
import { GroupProps } from 'antd/lib/avatar';
import clsx from 'clsx';
import useAvatarStyles from './styles';
import { AvatarExtendedProps, AvatarsGroupProps } from './types';
import UserAvatar from 'components/UserAvatar';
import Popover from 'components/lib/Popover';
import Tooltip from 'components/lib/Tooltip';
import {
  AVATARS_GROUP,
  AVATARS_GROUP_MORE_AVATAR,
  AVATARS_GROUP_REST_ITEMS,
} from 'utils/testIds';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ScrollableParentSeekMode } from 'hooks/useScrollableParent/enums';
import { ExpandedAvatarItems } from './components/ExpandedAvatarItems/ExpandedAvatarItems';

const LIMIT = 6;

export const Avatar: React.FC<AvatarExtendedProps> = ({
  isCountAvatar,
  className,
  size,
  ...rest
}) => {
  const classes = useAvatarStyles({ size });

  return (
    <AntAvatar
      {...{ ...rest, size }}
      className={clsx(className, {
        [classes.countAvatar]: isCountAvatar,
      })}
    />
  );
};

export const AvatarGroup: React.FC<GroupProps> = ({ ...rest }) => {
  return <AntAvatar.Group {...rest} />;
};

export const CustomAvatarGroup = ({
  items,
  disablePopover,
  moreItemsTooltipMessage,
  getPopupContainer,
  limitItems = LIMIT,
  stopPropagation = false,
  onClickShowMore,
  hiddenItems = 0,
  customMoreText,
  showAllInMore,
  hasFullUserDetails,
  groupNoPermissionText,
}: AvatarsGroupProps) => {
  const styles = useAvatarStyles({ isInteractive: !disablePopover });
  const elementRef = useRef<HTMLDivElement>(null);

  const validItemsToDisplay = items.filter(({ isDeleted }) => !isDeleted);
  const calculatedLimit =
    validItemsToDisplay.length >= limitItems
      ? limitItems - 1
      : validItemsToDisplay.length;

  const itemsToDisplay = validItemsToDisplay.slice(0, calculatedLimit);

  const { getScrollableParent } = useScrollableParent(
    elementRef,
    undefined,
    ScrollableParentSeekMode.AnyClosest
  );

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement>) =>
    stopPropagation ? e.stopPropagation() : null;

  return (
    <div
      onClick={onContainerClick}
      ref={elementRef}
      className={styles.avatarsGroupWrapper}
      data-testid={AVATARS_GROUP}
    >
      {itemsToDisplay.map(
        ({
          firstName,
          lastName,
          userGroup,
          id,
          accountType,
          isDeleted,
          email,
          company,
        }) =>
          id ? (
            <UserAvatar
              key={userGroup ? `group-${id}` : `user-${id}`}
              {...{
                id,
                userGroup,
                firstName,
                lastName,
                disablePopover,
                getPopupContainer,
                accountType,
                isDeleted,
                email,
                company,
                hasFullUserDetails,
                groupNoPermissionText,
              }}
              className={styles.avatar}
              size='small'
            />
          ) : null
      )}
      {(items.length > itemsToDisplay.length || hiddenItems > 0) && (
        <Tooltip placement='top' title={moreItemsTooltipMessage}>
          <Popover
            getPopupContainer={getPopupContainer ?? getScrollableParent}
            placement='top'
            destroyTooltipOnHide
            overlayStyle={{ maxWidth: 'min(100%, 250px)' }}
            trigger='click'
            content={
              <div
                className={styles.moreItems}
                id={AVATARS_GROUP_REST_ITEMS}
                data-testid={AVATARS_GROUP_REST_ITEMS}
                onScroll={event => event.stopPropagation()}
              >
                <ExpandedAvatarItems
                  items={items}
                  showAllInMore={showAllInMore}
                  alreadyDisplayedItemIds={itemsToDisplay.map(({ id }) => id)}
                  disablePopover={disablePopover}
                  groupNoPermissionText={groupNoPermissionText}
                />
              </div>
            }
            overlayClassName={styles.popover}
          >
            <Avatar
              isCountAvatar
              size='small'
              className={styles.avatar}
              data-testid={AVATARS_GROUP_MORE_AVATAR}
              onClick={onClickShowMore}
            >
              {customMoreText ?? '···'}
            </Avatar>
          </Popover>
        </Tooltip>
      )}
    </div>
  );
};
