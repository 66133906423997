import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import FormField from 'pages/TaskTemplates/components/FormField';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { Col } from 'components/lib/Grid';
import TextInput from 'components/Inputs/TextInput';
import Select from 'components/Inputs/Select';
import { FormMode } from 'utils/Enums/FormMode';
import { FormikCodeNameInput } from 'components/lib/CodeName';
import { useCodeNameTextBox } from 'components/lib/CodeName/hooks';
import formatStringToKey from 'utils/functions/formatStringToKey';
import {
  OBJECT_CLASS_FIELD_ALIAS,
  OBJECT_CLASS_FIELD_LABEL,
  OBJECT_CLASS_FIELD_TYPE,
} from 'utils/testIds';
import { ObjectClassFieldTypes } from 'utils/types/api/objectClassesFields.types';
import { FieldDefaultValue } from '../FieldDefaultValue/FieldDefaultValue';
import { useObjectTypeOptions, useTypeChange } from './hooks';
import { useClassFieldFormContext } from 'pages/ObjectClasses/components/ObjectClassForm/contexts/ClassFieldFormContext';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { FormContentProps } from '../../types';
import { ClassFieldForm } from '../../../../types';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';

export const FieldProperties: React.FC<FormContentProps> = ({
  shouldBeDisabled,
  children,
  parsedTypes,
}) => {
  const styles = useClassFieldPropertiesStyles();
  const intl = useIntl();
  const { readOnly, mode, validators = {} } = useClassFieldFormContext();
  const { values, touched, setFieldValue } = useFormikContext<ClassFieldForm>();
  const typeValues = useObjectTypeOptions();
  const type = useMemo(() => values[ClassFieldFormFields.Type], [values]);
  const handleTypeChange = useTypeChange();

  const {
    onTextBoxManualChange,
    resetIsTextBoxEdited,
    textBoxEdited: aliasManuallyEdited,
  } = useCodeNameTextBox(ClassFieldFormFields.Alias, mode !== FormMode.Create);
  const aliasMaxLength = validators[ClassFieldFormFields.Alias]?.max_length;

  useEffect(() => {
    if (aliasManuallyEdited) return;

    setFieldValue(
      ClassFieldFormFields.Alias,
      formatStringToKey(values.label, true).slice(0, aliasMaxLength)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.label]);

  useEffect(() => {
    if (
      mode === FormMode.Create &&
      aliasManuallyEdited &&
      Object.keys(touched).length === 0
    )
      resetIsTextBoxEdited();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, touched]);

  return (
    <div className={styles.fieldPropertiesWrapper}>
      <FormField gutter={0}>
        <Col span={24}>
          <FormLabel required>
            <FormattedMessage
              id='objectClasses.fields.label'
              defaultMessage='Label'
            />
          </FormLabel>
          <TextInput
            name={ClassFieldFormFields.Label}
            disabled={readOnly || shouldBeDisabled}
            maxLength={validators[ClassFieldFormFields.Label]?.max_length}
            data-testid={OBJECT_CLASS_FIELD_LABEL}
          />
        </Col>
      </FormField>
      <FormField gutter={0}>
        <Col span={24}>
          <FormLabel required>
            <FormattedMessage
              id='objectClasses.fields.alias'
              defaultMessage='Alias'
            />
          </FormLabel>
          <FormikCodeNameInput
            name={ClassFieldFormFields.Alias}
            disabled={mode !== FormMode.Create}
            withCounter
            maxLength={aliasMaxLength}
            description={intl.formatMessage({
              id: 'objectClasses.fields.cannotContainSpacesAndUppercase',
              defaultMessage: 'Cannot contain spaces and uppercase letters',
            })}
            onChange={onTextBoxManualChange}
            hideDescription={mode !== FormMode.Create}
            data-testid={OBJECT_CLASS_FIELD_ALIAS}
          />
        </Col>
      </FormField>
      <FormField gutter={0}>
        <Col span={24}>
          <FormLabel required>
            <FormattedMessage
              id='objectClasses.fields.type'
              defaultMessage='Type'
            />
          </FormLabel>
          <Select
            name={ClassFieldFormFields.Type}
            disabled={mode !== FormMode.Create}
            options={typeValues}
            onChange={handleTypeChange}
            data-testid={OBJECT_CLASS_FIELD_TYPE}
            virtual={false}
          />
        </Col>
      </FormField>
      {type !== ObjectClassFieldTypes.Enum &&
        type !== ObjectClassFieldTypes.Bool && (
          <FieldDefaultValue {...{ type, shouldBeDisabled, parsedTypes }} />
        )}
      {children}
    </div>
  );
};
