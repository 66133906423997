import axios from 'axios';
import { useTasksPanelContext } from 'components/SidePanels/TasksPanel/contexts/TasksPanelContext';
import { useCancelToken } from 'hooks/useCancelToken';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { apiCall } from 'utils/api';
import { TASK_GROUP_ASSIGNEES } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { AssigneeToDelete } from 'utils/types/assigneeToDelete';

/**
 * Handles logic responsible for unassigning a user group from a task.
 */
export const useUnassignTaskGroupAssignee = () => {
  const { task, refetchTaskDetails } = useTasksPanelContext();
  const { createCancelToken, cancelOngoingRequests } = useCancelToken();

  const [groupToUnassign, setGroupToUnassign] = useState<AssigneeToDelete>();
  const [isLoading, setIsLoading] = useState(false);

  const cancelUnassigning = () => {
    cancelOngoingRequests();
    setGroupToUnassign(undefined);
    setIsLoading(false);
  };

  const unassignGroupFromTask = async () => {
    if (!groupToUnassign) {
      return;
    }

    try {
      cancelOngoingRequests();
      setIsLoading(true);

      const cancelToken = createCancelToken();

      const endpoint = generatePath(TASK_GROUP_ASSIGNEES, {
        id: task?.id,
      });

      await apiCall.delete(endpoint, {
        data: [groupToUnassign.id],
        cancelToken,
      });

      refetchTaskDetails();
      setGroupToUnassign(undefined);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }

      showUnhandledErrorToast(error);
      setIsLoading(false);
    }
  };

  return {
    groupToUnassign,
    isUnassigning: isLoading,
    cancelUnassigning,
    setGroupToUnassign,
    unassignGroupFromTask,
  };
};
