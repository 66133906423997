import { DynamicSection } from 'components/formBuilder/formBuilder/types';

export const getFieldsInSchema = (
  schemaSectionsDictionary: MappedObject<DynamicSection>
) => {
  return Object.entries(schemaSectionsDictionary).reduce<{
    [key: string]: string[];
  }>(
    (prev, [key, value]) => ({
      ...prev,
      [key]: value.properties ? Object.keys(value.properties) : [],
    }),
    {}
  );
};
