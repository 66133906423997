import { useCallback, useRef } from 'react';
import {
  SelectUserAndGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';
// eslint-disable-next-line
import { CustomUserWidget } from '../standard/CustomUserWidget/CustomUserWidget';
// eslint-disable-next-line
import { InPlaceEditUser } from 'components/InPlaceEditControls/InPlaceEditUser';

/**
 * Takes object class user field possible options and tracks loaded group members to provide
 * a single source of {@link SelectUserOption} for {@link CustomUserWidget} or {@link InPlaceEditUser} purposes.
 */
export const useUserFormFieldSelectionOptions = (
  optionsFromObjectClassFieldApi: SelectUserAndGroupOption
) => {
  // There might be group members selected in the value ids. Form widgets for users won't have the
  // full information about such users coming from meta and object class user field options.
  // We track what group members were loaded for each group to to cache them
  // and reuse their data to recreate selection from their ids.
  const loadedGroupMembers = useRef<Map<number, SelectUserOption>>(new Map());

  const onLoadedUserOptions = useCallback((options: SelectUserOption[]) => {
    options.forEach(option => {
      if (loadedGroupMembers.current.has(option.id)) {
        return;
      }

      loadedGroupMembers.current.set(option.id, option);
    });
  }, []);

  const getCurrentOptions = useCallback(() => {
    return {
      users: [
        ...optionsFromObjectClassFieldApi.users,
        ...Array.from(loadedGroupMembers.current.values()),
      ],
      groups: optionsFromObjectClassFieldApi.groups,
    };
  }, [
    optionsFromObjectClassFieldApi.groups,
    optionsFromObjectClassFieldApi.users,
  ]);

  return {
    getCurrentOptions,
    onLoadedUserOptions,
  };
};
