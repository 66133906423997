import { useCallback } from 'react';
import { useInternalWindowContext } from 'components/lib/FlexLayout/InternalWindowContext';
import { useWindowContext } from 'components/lib/FlexLayout/WindowContext';
import { SCROLLABLE_CONTAINER } from 'utils/elementsIds';

const usePopupContainer = () => {
  const { windowRef } = useWindowContext();
  const { internalWindowRef } = useInternalWindowContext();

  return useCallback(
    () =>
      windowRef?.document?.body.querySelector(`#${SCROLLABLE_CONTAINER}`) ||
      windowRef?.document?.body ||
      internalWindowRef?.querySelector(`#${SCROLLABLE_CONTAINER}`) ||
      internalWindowRef ||
      document.body.querySelector(`#${SCROLLABLE_CONTAINER}`) ||
      document.body.querySelector(`#content`) ||
      document.body,
    [windowRef, internalWindowRef]
  );
};

export default usePopupContainer;
