import { EventFieldType, UserFieldValueUpdate } from '../../../../../types';
import { nameOrDefaultValue } from './nameOrDefaultValue';

export const prepareUserFieldValueData = (
  eventData: UserFieldValueUpdate[]
) => {
  //type of event occuring in User field
  const { value_labels: valueLabels, value } = eventData[0]; //value_labels is an array of string which contains `${firstName} ${lastName}` of user

  const userItems = valueLabels.users
    ? valueLabels.users
        .map((user: string, index) => ({
          id: value.users[index],
          value: user,
        }))
        .sort((left, right) => left.value.localeCompare(right.value))
    : [];

  const userGroupItems = valueLabels.user_groups
    ? valueLabels.user_groups
        .map((userGroup: string, index) => ({
          id: value.user_groups[index],
          value: userGroup,
        }))
        .sort((left, right) => left.value.localeCompare(right.value))
    : [];

  return {
    items: [...userGroupItems, ...userItems],
    value: nameOrDefaultValue(eventData[0].name, ''),
    fieldType: EventFieldType.user,
  };
};
