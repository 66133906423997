import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SelectUserAndGroupOption,
  SelectUserAndGroupOptionMap,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';
import {
  usePatchObjectRecordUserField,
  useInPlaceEditUserValidation,
} from './hooks';
import { InPlaceEditUserProps } from './types';
import { useInPlaceEditUserStyles } from './InPlaceEditUser.styles';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useObjectClassUserFieldOptions } from 'components/UsersAndGroupsSelection/hooks';
import { UsersAndGroupsExpandableSelect } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect';
import { UsersAndGroupsExpandablePickerRef } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types';
import {
  createUsersAndGroupsSelectionCountLimits,
  userAndGroupOptionMapToUserFormValue,
} from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/utils';
import { createUsersAndGroupsSelectionMap } from './utils';
import { isEqual, isNil } from 'lodash';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { MinMaxInfoLabelType } from 'components/MinMaxInfo/types';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { useSelectOptionAvatars } from 'hooks/avatars/useSelectOptionAvatars';
import { convertIdsToUserAndGroupOptions } from 'components/FormPreview2/utils';
import { useUserFormFieldSelectionOptions } from 'components/FormPreview2/widgets/hooks';
import { USERS_AND_GROUPS_FIELD_EDIT_CONTENT_TESTID } from 'utils/testIds';
import { SearchBarDisplay } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types/searchBarDisplay';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { getEligibleUserOptions } from 'components/UsersAndGroupsSelection/utils/getEligibleUserOptions';

/**
 * Allows users and groups selection while editing records in the "in place edit" style where a popover
 * allows the user to make the changes and apply them when closing it.
 */
export const InPlaceEditUser = ({
  label,
  value,
  disabled,
  propertyName,
  patchUrl,
  readOnly,
  size,
  withUnderline,
  required,
  fieldId,
  objectClassId,
  minUsers,
  maxUsers,
  minGroups,
  maxGroups,
  allowGroupMemberSelection,
  allowGroupSync,
  onSaveSuccess,
  meta: objectRecordMetadata,
}: InPlaceEditUserProps<ObjectRecordDetails>) => {
  const styles = useInPlaceEditUserStyles();

  const usersAndGroupsExpandablePickerRef = useRef<UsersAndGroupsExpandablePickerRef | null>(
    null
  );

  const [tempValue, setTempValue] = useState<UsersAndGroupsFormValue | null>(
    value ?? null
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const {
    patchUserField,
    errors: apiErrors,
    isSaving,
  } = usePatchObjectRecordUserField(patchUrl, objectClassId, propertyName);

  const limits: UsersAndGroupsSelectLimits = {
    selectionCountLimits: createUsersAndGroupsSelectionCountLimits(
      minUsers,
      maxUsers,
      minGroups,
      maxGroups
    ),
    isFieldRequired: required ?? false,
    isAllowedToSelectIndividualMembers: allowGroupMemberSelection ?? false,
    isAllowedToSyncGroupMembers: allowGroupSync ?? false,
  };

  const {
    validateFulfillment,
    validateField,
    errors,
    tooltip,
    clearErrors,
    minGroupsError,
    minUsersError,
  } = useInPlaceEditUserValidation(
    label ?? 'Unknown field',
    apiErrors,
    limits.selectionCountLimits,
    required ?? false,
    minUsers,
    minGroups
  );

  const validateFulfillmentPossibility = useCallback(
    (options: SelectUserAndGroupOption, isMinUsersFulfilled: boolean) =>
      validateFulfillment(
        value,
        {
          users: getEligibleUserOptions(options.users, value),
          groups: options.groups,
          isRequired: required ?? false,
          limits,
        },
        isMinUsersFulfilled
      ),
    [limits, required, validateFulfillment, value]
  );

  const { fetchOptions, isLoading, options } = useObjectClassUserFieldOptions(
    fieldId ?? null,
    objectClassId,
    (fetchedOptions, isMinUsersFulfilled) => {
      validateFulfillmentPossibility(fetchedOptions, isMinUsersFulfilled);

      // Because objectRecordMetadata will contain a maximum of 50 users. We need to reapply selection containing
      // newly loaded users meta to the selection state to properly rebuild UserAndGroupOptionMap with full data.
      const { users, groups } = convertIdsToUserAndGroupOptions(
        value?.users ?? [],
        value?.user_groups ?? [],
        fetchedOptions,
        objectRecordMetadata
      );

      setSelection(createUsersAndGroupsSelectionMap(users, groups));
    }
  );

  const {
    getCurrentOptions,
    onLoadedUserOptions,
  } = useUserFormFieldSelectionOptions(options);

  const userAndGroupOptions = convertIdsToUserAndGroupOptions(
    value?.users ?? [],
    value?.user_groups ?? [],
    getCurrentOptions(),
    objectRecordMetadata
  );

  const { avatars } = useSelectOptionAvatars(
    userAndGroupOptions.users,
    userAndGroupOptions.groups
  );

  const handleSetSelection = useCallback(
    (newSelection: React.SetStateAction<SelectUserAndGroupOptionMap>) => {
      setSelection(prev => {
        const value =
          typeof newSelection === 'function'
            ? newSelection(prev)
            : newSelection;

        const newFormValue = userAndGroupOptionMapToUserFormValue(value);

        setTempValue(newFormValue);
        return value;
      });
    },
    [setTempValue]
  );

  const [selection, setSelection] = useState<SelectUserAndGroupOptionMap>(
    createUsersAndGroupsSelectionMap(
      userAndGroupOptions.users,
      userAndGroupOptions.groups
    )
  );

  const onDropdownOpenChange = useCallback(
    isOpen => {
      if (!isOpen) {
        setIsEditing(false);
        return;
      }

      if (disabled) {
        return;
      }

      setIsEditing(true);
      fetchOptions();
    },
    [disabled, fetchOptions]
  );

  const restoreOriginalValue = useCallback(() => {
    setTempValue(value ?? null);

    const {
      users: restoredUsers,
      groups: restoredGroups,
    } = convertIdsToUserAndGroupOptions(
      value?.users ?? [],
      value?.user_groups ?? [],
      options,
      objectRecordMetadata
    );

    setSelection(
      createUsersAndGroupsSelectionMap(restoredUsers, restoredGroups)
    );
  }, [objectRecordMetadata, options, value]);

  /**
   * Restores the original field state and closes the dropdown to reject any changes the user
   * could have done while editing the field.
   */
  const rejectChanges = useCallback(() => {
    restoreOriginalValue();
    clearErrors();

    if (usersAndGroupsExpandablePickerRef.current) {
      usersAndGroupsExpandablePickerRef.current.blur();
    }
  }, [clearErrors, restoreOriginalValue]);

  /**
   * Handles the intent to close the dropdown when the user tries to save the changes by clicking away from it.
   * @returns Returns true if the dropdown should be allowed to close, false otherwise.
   */
  const onCloseIntent = useCallback(async () => {
    if (!validateField(tempValue) || errors.length > 0) {
      return false;
    }

    if (isEqual(tempValue, value)) {
      clearErrors();
      return true;
    }

    try {
      const responseData = await patchUserField(tempValue);

      if (isNil(responseData)) {
        return false;
      }

      if (onSaveSuccess) {
        onSaveSuccess(responseData, tempValue);
      }

      if (usersAndGroupsExpandablePickerRef.current) {
        usersAndGroupsExpandablePickerRef.current.blur();
      }

      return true;
    } catch (error) {
      rejectChanges();
      showUnhandledErrorToast(error);
      return true;
    }
  }, [
    validateField,
    tempValue,
    errors.length,
    value,
    clearErrors,
    patchUserField,
    onSaveSuccess,
    rejectChanges,
  ]);

  useEffect(() => {
    if (!usersAndGroupsExpandablePickerRef.current || !isEditing) {
      return;
    }

    usersAndGroupsExpandablePickerRef.current.focus();
  }, [isEditing]);

  const selectionCount = selection.groups.size + selection.users.size;

  return (
    <div className={styles.userFieldWrapper}>
      <InPlaceEditWrapper
        label={label ?? 'Unknown name'}
        required={!!required}
        readOnly={readOnly}
        disabled={disabled}
        withUnderline={withUnderline ?? false}
        isEditMode={isEditing}
        isSaving={isSaving}
        tooltipText={tooltip}
        rowAdditionalClassName={styles.editContentContainer}
        onViewClick={() => {
          if (disabled || readOnly) {
            return;
          }

          setIsEditing(true);
        }}
        size={size}
        noAfter={true}
        viewContent={
          <div data-testid={`users-viewcontent-${label}`}>
            {selectionCount > 0 ? (
              <CustomAvatarGroup
                items={avatars}
                disablePopover={false}
                onClickShowMore={event => event?.stopPropagation()}
              />
            ) : (
              '-'
            )}
          </div>
        }
        editContent={
          <UsersAndGroupsExpandableSelect
            ref={usersAndGroupsExpandablePickerRef}
            isLoadingOptions={isLoading}
            selection={selection}
            setSelection={handleSetSelection}
            onDropdownOpenChange={onDropdownOpenChange}
            onGroupMembersLoad={onLoadedUserOptions}
            options={options}
            limits={limits}
            required={required}
            isFieldDisabled={disabled || readOnly}
            withUnderline={false}
            errors={errors}
            searchBarBehavior={SearchBarDisplay.Always}
            testId={`${USERS_AND_GROUPS_FIELD_EDIT_CONTENT_TESTID}${label}`}
            editModeOptions={{
              isSaving: isSaving,
              onCloseIntent: onCloseIntent,
              onRejection: rejectChanges,
            }}
            minMaxInfoErrorPairs={
              new Map<MinMaxInfoLabelType, string>([
                [MinMaxInfoLabelType.Users, minUsersError],
                [MinMaxInfoLabelType.Groups, minGroupsError],
              ])
            }
          />
        }
      />
    </div>
  );
};
