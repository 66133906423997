import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  FilterValue,
  setAppliedFiltersForTable,
  setApplyFilter,
  setFilters,
} from 'store/actions/filtersActions';
import { updatePreferences } from 'store/actions/preferencesActions';
import {
  getCurrentTable,
  getCurrentTableFilters,
} from 'store/selectors/filtersSelectors';
import { getTasksQuickFilters } from 'store/selectors/preferencesSelectors';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { PreferencesTypes } from 'utils/types/api/preferences.types';
import { TASK_QUICK_FILTER_OVERDUE_DATES } from './consts/taskQuickFilterOverdueDates';
import { getDatePredicate } from './utils/getDatePredicate';
import { addStatusOverduePredicate } from './utils/addStatusOverduePredicate';
import { getDatePredicateExpiry } from './utils/getDatePredicateExpiry';
import { TasksQuickFilterOwnership } from '../../../TasksQuickFilterOwnership/types/tasksQuickFilterOwnership';
import { TasksQuickFilterDates } from '../../types/tasksQuickFilterDates';
import { useCurrentTableSupportedColumns } from 'components/CatalystTable/hooks/useCurrentTableSupportedColumns';

export const useTasksQuickFiltersDates = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const filters = useSelector(getCurrentTableFilters);
  const currentTable = useSelector(getCurrentTable) || CatalystTableType.Tasks;
  const { ownership, dates: datePeriod } = useSelector(getTasksQuickFilters);
  const columns = useCurrentTableSupportedColumns();

  const datesOptions = useMemo(
    () => [
      { value: TasksQuickFilterDates.Default, label: '------' },
      {
        label: intl.formatMessage({
          id: 'task.taskDueDate',
          defaultMessage: 'Task due date',
        }),
        options: [
          {
            value: TasksQuickFilterDates.NoDueDate,
            label: intl.formatMessage({
              id: 'predicates.noDueDate',
              defaultMessage: 'No due date',
            }),
          },
          {
            value: TasksQuickFilterDates.Today,
            label: intl.formatMessage({
              id: 'predicates.today',
              defaultMessage: 'Today',
            }),
          },
          {
            value: TasksQuickFilterDates.ThisWeek,
            label: intl.formatMessage({
              id: 'predicates.thisWeek',
              defaultMessage: 'This week',
            }),
          },
          {
            value: TasksQuickFilterDates.WithinAWeek,
            label: intl.formatMessage({
              id: 'predicates.withinWeek',
              defaultMessage: 'Within a week',
            }),
          },
          {
            value: TasksQuickFilterDates.Within30Days,
            label: intl.formatMessage({
              id: 'predicates.within30Days',
              defaultMessage: 'Within 30 days',
            }),
          },
        ],
      },
      {
        label: intl.formatMessage({
          id: 'task.overdueDate',
          defaultMessage: 'Overdue date',
        }),
        options: [
          {
            value: TasksQuickFilterDates.Yesterday,
            label: intl.formatMessage({
              id: 'predicates.yesterday',
              defaultMessage: 'Yesterday',
            }),
          },
          {
            value: TasksQuickFilterDates.WithinTheLastWeek,
            label: intl.formatMessage({
              id: 'predicates.withinLastWeek',
              defaultMessage: 'Within last week',
            }),
          },
          {
            value: TasksQuickFilterDates.WithinTheLast30Days,
            label: intl.formatMessage({
              id: 'predicates.withinLast30Days',
              defaultMessage: 'Within the last 30 days',
            }),
          },
        ],
      },
      {
        value: TasksQuickFilterDates.ExpiredTasks,
        label: intl.formatMessage({
          id: 'predicates.expiredTasks',
          defaultMessage: 'Expired tasks',
        }),
        className: 'add-border-top',
      },
    ],
    [intl]
  );

  const handleChangeDatePeriod = useCallback(
    (value: TasksQuickFilterDates) => {
      dispatch(
        updatePreferences(PreferencesTypes.FilterPreferences, {
          quickFilters: {
            tasks: {
              dates: value,
              ownership: ownership || TasksQuickFilterOwnership.Default,
            },
          },
        })
      );

      if (!columns['due_date'] || !columns['expiry_date'] || !columns['status'])
        return;
      const userFilters = ownership
        ? filters?.filter((f: FilterValue) => f.type === 'user')
        : [];
      if (value === TasksQuickFilterDates.Default) {
        dispatch(setFilters(currentTable, userFilters));

        if (userFilters?.length === 0) {
          dispatch(
            setAppliedFiltersForTable({ id: currentTable, value: false })
          );
        }
        dispatch(
          setApplyFilter(true, { sendToApi: true, isQuickFilter: true })
        );
        return;
      }

      if (value === TasksQuickFilterDates.ExpiredTasks) {
        dispatch(
          setFilters(currentTable, [
            ...userFilters,
            ...getDatePredicateExpiry(columns),
          ])
        );
        dispatch(
          setApplyFilter(true, { sendToApi: true, isQuickFilter: true })
        );
        return;
      }

      if (TASK_QUICK_FILTER_OVERDUE_DATES.includes(value)) {
        dispatch(
          setFilters(currentTable, [
            ...userFilters,
            ...addStatusOverduePredicate(columns),
            ...getDatePredicate(columns, value),
          ])
        );

        dispatch(
          setApplyFilter(true, { sendToApi: true, isQuickFilter: true })
        );
        return;
      }

      if (value) {
        dispatch(
          setFilters(currentTable, [
            ...userFilters,
            ...getDatePredicate(columns, value),
          ])
        );
        dispatch(
          setApplyFilter(true, { sendToApi: true, isQuickFilter: true })
        );
      }
    },
    [dispatch, columns, filters, currentTable, ownership]
  );

  return { datePeriod, datesOptions, handleChangeDatePeriod };
};
