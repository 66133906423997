import isMultiline from 'utils/functions/isMultiline';
import { FieldValueType, EventFieldType } from '../../../../../types';

export const useIsExpandable = (
  value: FieldValueType,
  eventFieldType?: EventFieldType
) => {
  return (
    eventFieldType === EventFieldType.document ||
    eventFieldType === EventFieldType.user ||
    eventFieldType === EventFieldType.set ||
    eventFieldType === EventFieldType.json ||
    (eventFieldType === EventFieldType.string &&
      typeof value === 'string' &&
      (value.length > 100 || isMultiline(value)))
  );
};
