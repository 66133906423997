import { Reducer } from 'redux';
import { RootAction } from '.';
import {
  SET_TASKS,
  SET_TASKS_FETCHING,
  APPEND_TASKS,
  RESET_TASKS,
  SET_TASKS_COLUMNS,
  RESET_TASKS_COLUMNS,
  SET_TASKS_SELECTED_ROW,
} from 'store/constants/tasks.consts';
import {
  appendTableContent,
  resetTable,
  setTableContent,
  setTableLoading,
  resetTableColumns,
  setTableColumns,
  setSelectedRow,
} from './tablesCommonStateModificators';
import { Task } from 'utils/types/api/tasks.types';
import { INITIAL_TABLE_STATE } from 'store/constants/misc.consts';
import { CommonTableState } from 'utils/types/api/table.types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = cloneDeep(INITIAL_TABLE_STATE);
const TasksReducer: Reducer<CommonTableState<Task>, RootAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_TASKS: {
      return setTableContent<Task, typeof SET_TASKS>(state, action);
    }

    case APPEND_TASKS:
      return appendTableContent<Task, typeof APPEND_TASKS>(state, action);

    case SET_TASKS_FETCHING:
      return setTableLoading<Task, typeof SET_TASKS_FETCHING>(state, action);

    case RESET_TASKS:
      return resetTable<Task>(state, initialState);

    case RESET_TASKS_COLUMNS:
      return resetTableColumns<Task>(state, initialState);

    case SET_TASKS_COLUMNS:
      return setTableColumns<Task, typeof SET_TASKS_COLUMNS>(state, action);

    case SET_TASKS_SELECTED_ROW:
      return setSelectedRow<Task, typeof SET_TASKS_SELECTED_ROW>(state, action);

    default:
      return state;
  }
};

export default TasksReducer;
