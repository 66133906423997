import { createUseStyles } from 'react-jss';

export const useTaskFormDueDateFieldStyles = createUseStyles({
  dueDateSection: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
  },
  dueDatePicker: {
    width: '100%',
  },
});
