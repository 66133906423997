import { AlertType } from '../types/alertType';

export const getLimitAlertType = (itemsCount: number, itemsLimit: number) => {
  if (itemsCount >= itemsLimit) {
    return AlertType.Error;
  }

  if (itemsCount >= itemsLimit * 0.9) {
    return AlertType.Warning;
  }

  if (itemsCount >= itemsLimit * 0.8) {
    return AlertType.Info;
  }

  return AlertType.None;
};
