import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import Membership from 'utils/Enums/Membership';
import { getTagColorsByMembership } from './utils/getTagColorsByMembership';

type UseMembershipTagStylesProps = {
  membership: Membership;
};

export const useMembershipTagStyles = createUseStyles((theme: ThemeState) => {
  return {
    membershipTag: ({ membership }: UseMembershipTagStylesProps) => ({
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 600,
      padding: '3px 6px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 3,
      border: 'none',
      ...getTagColorsByMembership(membership, theme),
    }),
  };
});
