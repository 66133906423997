import { UserAssignee } from 'utils/types/api/users.types';
import { UserGroupAssignee } from 'utils/types/api/userGroups.types';
import { UserAvatarData, UserGroupAvatarData } from './types';
import { useUserAndGroupAvatars } from './hooks/useUserAndGroupAvatars';

/**
 * Special wrapper for {@link useUserAndGroupAvatars} hook that transforms specifically user
 * and user group assignees into singular avatar list.
 *
 * @param users User assignees to transform.
 * @param groups Group assignees to transform.
 *
 * @returns Alphatecially sorted list of user and group avatars.
 */
export const useAssigneeAvatars = (
  users: UserAssignee[],
  groups: UserGroupAssignee[]
) => {
  const transformedUsers: UserAvatarData[] = users.map(user => ({
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    accountType: user.account_type,
    isDeleted: !!user.is_deleted,
  }));
  const transformedGroups: UserGroupAvatarData[] = groups.map(group => ({
    id: group.id,
    name: 'user_group' in group ? group.user_group.name : group.name,
  }));

  return useUserAndGroupAvatars(transformedUsers, transformedGroups);
};
