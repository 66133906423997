import { Select } from 'components/lib/Select';
import React from 'react';
import { useFilterSelectStyles } from './FilterSelect.styles';
import { FilterSelectProps } from './FilterSelect.types';
import { CONTENT_ID } from 'utils/elementsIds';

export const FilterSelect: React.FC<FilterSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const styles = useFilterSelectStyles();

  const [{ value: defaultValue }] = options;

  return (
    <div className={styles.selectWrapper}>
      <Select
        value={value || defaultValue}
        className={styles.select}
        {...{ defaultValue, onChange, options }}
        getPopupContainer={() =>
          document.getElementById(CONTENT_ID) || document.body
        }
      />
    </div>
  );
};
