import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

import { getIdentifier } from 'styles/panelsStyles';

const COMMON_PADDING = '1rem';

export const useManageAssigneesPanelStyles = createUseStyles(
  (theme: ThemeState) => ({
    panelWrapper: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',

      height: '100%',
    },
    permissionSetName: {
      ...getIdentifier(theme),
    },
    header: {
      padding: COMMON_PADDING,
    },
    tabs: {
      '& .ant-tabs-nav': {
        margin: 0,
      },
      '& .ant-tabs-content': {
        height: '100%',
      },
      '& .ant-tabs-tab': {
        paddingLeft: COMMON_PADDING,
        paddingRight: COMMON_PADDING,
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',

      padding: COMMON_PADDING,
    },
  })
);
