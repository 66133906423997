import axios from 'axios';
import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { stringifyJson } from 'utils/functions/stringifyJson';
import { ErrorDetails } from '../types/errorDetails';

/**
 * Safely extracts error details from the given error.
 *
 * @param error Any type of error to extract details from.
 *
 * @returns Error details object.
 */
export const extractErrorDetails = (error: unknown): ErrorDetails => {
  if (axios.isAxiosError(error)) {
    return {
      title: error.response
        ? `${error.response?.status} ${error.response?.statusText}`
        : GlobalIntlSingleton.intl.formatMessage({
            id: 'misc.unhandledError',
            defaultMessage: 'Unhandled error',
          }),
      message:
        stringifyJson(error.response?.data, 0) ?? String(error.response?.data),
      method: error.config.method?.toUpperCase(),
      url: error.config.url,
    };
  }

  if (error instanceof Error) {
    return {
      title:
        error.name ||
        GlobalIntlSingleton.intl.formatMessage({
          id: 'misc.unhandledError',
          defaultMessage: 'Unhandled error',
        }),
      message: stringifyJson(error.message, 0) ?? String(error.message),
    };
  }

  return {
    title: GlobalIntlSingleton.intl.formatMessage({
      id: 'misc.unhandledError',
      defaultMessage: 'Unhandled error',
    }),
    message: stringifyJson(error, 0) ?? String(error),
  };
};
