import More from 'components/More';
import TooltipString from 'components/TooltipString';
import React from 'react';
import { VISIBLE_ELEMENTS_COUNT } from './CustomValueDisplayMultiselect.consts';
import { CustomValueDisplayMultiselectProps } from './CustomValueDisplayMultiselect.types';
import { useCustomValueDisplayMultiselectStyles } from './CustomValueDisplayMultiselect.styles';

export const CustomValueDisplayMultiselect = ({
  values,
}: CustomValueDisplayMultiselectProps) => {
  const styles = useCustomValueDisplayMultiselectStyles();

  return (
    <div className={styles.wrapper}>
      {!!values.length && (
        <span className={styles.option}>
          <TooltipString noStyle text={values[0]}>
            {values[0]}
          </TooltipString>
        </span>
      )}
      {values.length > VISIBLE_ELEMENTS_COUNT && (
        <More
          moreElements={values}
          visibleElementsCount={VISIBLE_ELEMENTS_COUNT}
          dropdownClassName={styles.dropdown}
        />
      )}
    </div>
  );
};
