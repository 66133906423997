import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useDocumentListStyles = createUseStyles((theme: ThemeState) => {
  return {
    dropdownWrapper: {
      padding: 10,
      backgroundColor: 'white',
      borderRadius: 3,
      width: 250,
      maxHeight: 230,
      boxShadow: '0px 4px 4px rgba(46, 63, 87, 0.3)',
      overflow: 'auto',
    },
    loadingWrapper: {
      padding: 10,
      backgroundColor: 'white',
      borderRadius: 3,
      boxShadow: '0px 4px 4px rgba(46, 63, 87, 0.3)',
    },
    scrollWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gridGap: 5,
    },
    listItem: {
      border: `1px solid ${theme['placeholder-border-color']}`,
      borderRadius: 2,
      padding: 5,
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
});
