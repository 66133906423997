import { useScrollableParent } from 'hooks/useScrollableParent';
import { ScrollableParentSeekMode } from 'hooks/useScrollableParent/enums';
import { RefObject, useEffect, useState } from 'react';

/**
 * Provides you with the state control for tooltip with wrapped functionality
 * that will hide the tooltip when the trigger element gets scrolled.
 * @param triggerElementRef - reference to the element that will trigger the tooltip
 */
export const useTooltipVisibility = (
  triggerElementRef: RefObject<HTMLElement>
) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onScroll = () => {
    setIsTooltipVisible(false);
  };

  const { getScrollableParent } = useScrollableParent(
    triggerElementRef,
    undefined,
    ScrollableParentSeekMode.AnyClosest
  );

  useEffect(() => {
    if (!isTooltipVisible) {
      return;
    }

    const scrollableParent = getScrollableParent();

    if (scrollableParent === null) {
      return;
    }

    scrollableParent.addEventListener('scroll', onScroll);

    return () => {
      if (scrollableParent === null) {
        return;
      }

      scrollableParent.removeEventListener('scroll', onScroll);
    };
  }, [getScrollableParent, isTooltipVisible]);

  return { isTooltipVisible, setIsTooltipVisible };
};
