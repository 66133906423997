import { useFlexLayoutContext } from 'components/lib/FlexLayout/FlexLayoutContext';
import React from 'react';
import FlexLayoutWindows from 'utils/Enums/FlexLayoutWindows';
import { FileViewerLauncherProps } from './types';
import { useFileViewerLauncherStyles } from './styles';
import { setSidebarData } from 'store/actions/flexLayoutActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const FileViewerLauncher = ({
  fileName,
  fileUrl,
  fieldLabel,
  recordIdentifier,
}: FileViewerLauncherProps) => {
  const dispatch = useDispatch();

  const styles = useFileViewerLauncherStyles();

  const { pathname } = useLocation();
  const {
    flexLayoutMethods: { onAddTab },
  } = useFlexLayoutContext();

  const handleClick = () => {
    dispatch(
      setSidebarData(FlexLayoutWindows.Viewer, {
        fileName,
        fileUrl,
        fieldLabel,
        recordIdentifier,
        originPathname: pathname,
      })
    );
    onAddTab(FlexLayoutWindows.Viewer);
  };

  return (
    <span
      className={styles.fileViewerLauncher}
      onClick={handleClick}
      role='button'
      tabIndex={0}
    >
      {fileName}
    </span>
  );
};
