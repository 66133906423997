export const FILTERS_WRAPPER = 'filters-wrapper';

/*
use this id on scrollable elements inside flex layout windows to allow
usePopupContainer to find them, this fixes dropdowns not scrolling with parent container
*/
export const SCROLLABLE_CONTAINER = 'scrollable-container';
export const appContainer = 'app';

// id for Form Builder toolbox
export const FB_TOOLBOX_ID = 'FBToolbox';

export const FB_CONTROL_ID = 'form-builder-control';

// id in formbuilder single select options input
export const OPTIONS_INPUT_ID = 'optionsInput';

export const COLUMNS_INPUT_ID = 'columnsInput';
export const COLUMNS_RATIO_INPUT_ID = 'columnsRatioInput';

export const FB_CHILD_RECORDS_COLS_POPOVER = 'fb-child-records-cols-popover';

export const FB_FORM_MODAL_WRAPPER = 'formBody';

export const TABLE_PAGINATION_ID = 'pagination';

export const TABLE_WRAPPER = 'table-wrapper';
export const TABLE_BODY_ID = 'table-body';
export const TABLE_HEAD_ID = 'table-head';
export const TABLE_HEAD_ROW_ID = 'table-head-row';
export const TABLE_ROW = 'row-{0}';
export const DROPPABLE_ID = 'droppable-table';
export const CONTENT_ID = 'content';

export const ARROW_UP_ID = 'arrow-up';
export const ARROW_DOWN_ID = 'arrow-down';
export const ARROWS_WRAPPER_ID = 'arrows-wrapper';
export const LINE_TEXT_AREA_ID = 'line-text-area-wrapper';
export const CODE_NAME_BASIC_ID = 'code-name-basic';
export const CODE_NAME_DETAILED_ID = 'code-name-detailed';

// expected value element in form builder conditional logic
export const EXPECTED_VALUE_CLASS = 'expected-value-class';

export const FIELD_SELECT_WRAPPER = 'field-select-wrapper';

export const SIDEBAR_MENU_ID = 'sidebar-menu';
export const TOP_MENU_ID = 'top-menu';
export const BREADCRUMBS_WRAPPER_ID = 'breadcrumbs-wrapper';

export const NOT_ALLOW_ID = 'not-allowed';

export const TAB_CONTENT_PREFIX_ID = 'scrollable-container-panel-';

export const OWNERS_LIST_ID = 'owners-list';

export const SELECT_USERS_WRAPPER = 'select-users-wrapper';
export const SCROLLABLE_TASKS_LIST_ID = 'scrollable-tasks-list';

export const FORM_PREVIEW_WRAPPER = 'form-preview-wrapper';

export const CHILD_FORM_PREVIEW_WRAPPER = 'child-form-preview-wrapper';
export const TASK_FORM_WRAPPER = 'task-form-wrapper';

export const LIST_WRAPPER = 'list-wrapper';

export const DUMMY_ELEM = 'dummy';

export const DRAWER_CONTENT_WRAPPER = 'drawer-content-wrapper';

export const SIDE_PANEL = 'side-panel';
export const RECORDS_BREADCRUMBS_DROPDOWN_ID = 'record-breadcrumbs-dropdown';

export const DOCUMENTS_TABLE = 'documents-table';
export const DOCUMENT_TEMPLATE_FIELD_LIST = 'SAVE_DOCUMENT_TEMPLATE_FIELD';
export const REPOSITORY_DOWNLOAD_BUTTON_ID = 'repository-download-button';
