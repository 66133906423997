import GlobalIntlSingleton from 'providers/IntlProviderWrapper/globalIntlSingleton';
import { apiCall } from 'utils/api';
import { USERS_GROUPS_LIST_AUTOCOMPLETE_IN } from 'utils/endpoints';
import { UserGroupMeta } from 'utils/types/api/objectClassesFields.types';
import { showErrorToast } from 'features/toasts/utils/showErrorToast';

export const getAvailableGroups = async (groups: UserGroupMeta[]) => {
  const ids = groups.map(({ id }) => id); //existing and deletetd group ids
  const url = `${USERS_GROUPS_LIST_AUTOCOMPLETE_IN}${ids.join(',')}`;

  try {
    const {
      data: { results }, //existing groups
    } = await apiCall.get(url);

    return groups.filter(({ id }) =>
      results.some((g: UserGroupMeta) => g.value === id)
    );
  } catch {
    return [];
  } finally {
    showErrorToast({
      title: GlobalIntlSingleton.intl.formatMessage({
        id: 'misc.error',
        defaultMessage: 'Error!',
      }),
      subtitle: GlobalIntlSingleton.intl.formatMessage({
        id: 'errors.groupsDeletetd',
        defaultMessage:
          'Group deleted from the system. Please review and update the selection.',
      }),
    });
  }
};
