/**
 * Checks the provided array for its length and returns the provided value if there are any element on this array. Undefined otherwise.
 */
export const getValueIfAnyElementOnArray = <
  TValueType extends number | string | boolean | undefined
>(
  array: Array<unknown>,
  value: TValueType
) => {
  return array.length > 0 ? value : undefined;
};
