import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import {
  panelWrapper,
  getPanelTitle,
  getIdentifier,
} from 'styles/panelsStyles';

export const useCreateTaskStyles = createUseStyles((theme: ThemeState) => ({
  panelWrapper: {
    ...panelWrapper,
    gap: '16px',
  },
  panelHeader: {
    display: 'flex',
    flexDirection: 'column',

    gap: '8px',
  },
  identifier: getIdentifier(theme),
  panelTitle: {
    ...getPanelTitle(theme),
    marginBottom: 0,
  },
}));
