import useDateFormat from 'hooks/useDateFormat';
import useFormatNumber from 'hooks/useFormatNumber';
import { useIntl } from 'react-intl';
import {
  FieldValueType,
  EventFieldType,
  EventType,
} from '../../../../../types';
import { getFieldValueDate } from '../../../../../utils';
import { useEventDataLabelStyles } from '../../../EventsList.styles';
import { tagsMapping } from '../utils';
import { useIsExpandable } from './useIsExpandable';
import { Key } from 'react';

export const useEventLabel = (
  items: {
    id: Key;
    value: FieldValueType;
  }[],
  eventType: EventType,
  field = '',
  eventFieldType?: EventFieldType
) => {
  const intl = useIntl();
  const styles = useEventDataLabelStyles();
  const { dateFormat, getTimeWithSecondsFormat } = useDateFormat();
  const formatNumber = useFormatNumber();
  const [{ value } = { value: '' }] = items;
  const isExpandable = useIsExpandable(value, eventFieldType);

  const { length } = items;

  switch (eventType) {
    case EventType.RecordCreated: {
      return intl.formatMessage(
        {
          id: 'activityLog.recordWasCreated',
          defaultMessage: '<b>Record was created</b>',
        },
        { ...tagsMapping(styles) }
      );
    }
    case EventType.OwnerInitialized: {
      return intl.formatMessage(
        {
          id: 'activityLog.wasMadeOwner',
          defaultMessage: '<b>{value}</b> was made owner',
        },
        { ...tagsMapping(styles), value }
      );
    }
    case EventType.OwnersAdded: {
      return intl.formatMessage(
        {
          id: 'activityLog.ownersAdded',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <g>added</g> to <b>Owners</b>} other {<b>Owners</b> were <g>added</g>}}`,
        },
        { ...tagsMapping(styles), value, length }
      );
    }
    case EventType.OwnersRemoved: {
      return intl.formatMessage(
        {
          id: 'activityLog.ownersRemoved',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <r>removed</r> from <b>Owners</b>} other {<b>Owners</b> were <r>removed</r>}}`,
        },
        { ...tagsMapping(styles), value, length }
      );
    }
    case EventType.AssigneesAdded: {
      return intl.formatMessage(
        {
          id: 'activityLog.assigneesAdded',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <g>added</g> to <b>{field}</b>} other {<b>{field}</b> assignees were <g>added</g>}}`,
        },
        { ...tagsMapping(styles), value, length, field }
      );
    }
    case EventType.AssigneesRemoved: {
      return intl.formatMessage(
        {
          id: 'activityLog.assigneesRemoved',
          defaultMessage: `{length, plural, one {<b>{value}</b> was <r>removed</r> from <b>{field}</b>} other {<b>{field}</b> assignees were <r>removed</r>}}`,
        },
        { ...tagsMapping(styles), value, length, field }
      );
    }
    case EventType.DocumentGenerated: {
      return intl.formatMessage(
        {
          id: 'activityLog.documentWasCreated',
          defaultMessage: '<b>Document</b> was generated',
        },
        { ...tagsMapping(styles) }
      );
    }
    case EventType.FieldValuesChanged: {
      let formattedValue = value;

      switch (eventFieldType) {
        case EventFieldType.int:
        case EventFieldType.float: {
          if (typeof value === 'number') formattedValue = formatNumber(value);
          break;
        }
        case EventFieldType.date: {
          formattedValue = getFieldValueDate(value, dateFormat);
          break;
        }
        case EventFieldType.datetime: {
          formattedValue = getFieldValueDate(
            value,
            dateFormat,
            getTimeWithSecondsFormat()
          );
          break;
        }
        case EventFieldType.bool: {
          return intl.formatMessage(
            {
              id: 'activityLog.wasChecked',
              defaultMessage: `<b>{field}</b> was {value, select, true {checked} other {unchecked}}`,
            },
            { ...tagsMapping(styles), field, value }
          );
        }
        case EventFieldType.url: {
          return intl.formatMessage(
            {
              id: 'activityLog.wasChangedUrl',
              defaultMessage: `<b>{field}</b> was changed to <a>{value}</a>`,
            },
            { ...tagsMapping(styles), field, value: value ?? '\0' }
          );
        }
      }

      if (isExpandable) {
        return intl.formatMessage(
          {
            id: 'activityLog.wasChanged',
            defaultMessage: `<b>{field}</b> was changed`,
          },
          { ...tagsMapping(styles), field }
        );
      }

      return intl.formatMessage(
        {
          id: 'activityLog.wasChangedTo',
          defaultMessage: `<b>{field}</b> was changed to <v>{value}</v>`,
        },
        {
          ...tagsMapping(styles),
          field,
          value: formattedValue ?? '\0', //workaround because null is changed to '' inside intl.formatMessage
        }
      );
    }
    default:
      return intl.formatMessage(
        {
          id: 'activityLog.unknownEvent',
          defaultMessage: '<b>Unknown event</b>',
        },
        { ...tagsMapping(styles) }
      );
  }
};
