import { createUseStyles } from 'react-jss';

type UseTreeUsageViewStylesProps = {
  depth: number;
};

export const useTreeUsageViewStyles = createUseStyles({
  list: ({ depth }: UseTreeUsageViewStylesProps) => ({
    margin: 0,
    paddingLeft: depth > 0 ? `${depth}rem` : 0,

    fontStyle: depth > 0 ? 'italic' : 'normal',
    listStylePosition: 'inside',
  }),
});
