import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAboutModalVisible } from 'store/actions/miscActions';
import { getApiVersion } from 'store/selectors/generalSelectors';
import { getIsAboutModalVisible } from 'store/selectors/miscSelectors';
import useTimezone from './useTimezone';
import React from 'react';
import { LogoIcon } from 'components/Icon';
import { FallbackLogoOptions } from 'components/Icon/types';
import useLayoutStyles from 'components/lib/Layout/styles';

function useAboutModal() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const visible = useSelector(getIsAboutModalVisible);
  const { REACT_APP_VERSION } = process.env;
  const classes = useLayoutStyles({});
  const { apiVersion, historyVersion } = useSelector(getApiVersion);
  const { userTimezoneName } = useTimezone();

  const title = intl.formatMessage({
    id: 'misc.aboutModal.title',
    defaultMessage: 'About Autologyx Catalyst',
  });

  const subtitle = (
    <div className={classes.appVersionInfo}>
      {!!REACT_APP_VERSION && (
        <span>
          <FormattedMessage id='misc.ui' defaultMessage='UI' />:{' '}
          <span>{REACT_APP_VERSION}</span>
        </span>
      )}
      {apiVersion && (
        <span>
          <FormattedMessage id='misc.api' defaultMessage='API' />:{' '}
          <span>{apiVersion}</span>
        </span>
      )}
      <span>
        <FormattedMessage id='misc.history' defaultMessage='History' />:{' '}
        <span>{historyVersion}</span>
      </span>
      {!!userTimezoneName && <span>{userTimezoneName}</span>}
    </div>
  );

  function onConfirm() {
    dispatch(setIsAboutModalVisible(false));
  }

  const customImage = (
    <LogoIcon
      fallbackLogo={FallbackLogoOptions.SMALL}
      width={100}
      height={100}
      useDefaultAlxLogo
      style={{ marginLeft: -2, marginRight: 20 }}
    />
  );

  const confirmationButtonLabel = intl.formatMessage({
    id: 'misc.aboutModal.confirmButton',
    defaultMessage: 'Ok',
  });

  return {
    visible,
    title,
    subtitle,
    onConfirm,
    customImage,
    confirmationButtonLabel,
  };
}

export default useAboutModal;
