import { FilterValue } from 'react-table';
import { Logger, LogLevel } from 'utils/logger';

/**
 * Removes a filter from the provided filter array without allocating a new array.
 * @param newFilters Array to remove the filter from.
 * @param filterValue Filter to be removed.
 */
export const removeFilter = (
  newFilters: FilterValue[],
  filterValue: FilterValue
) => {
  Logger.log(
    `Filter ${filterValue.column} is no longer applicable. Removing.`,
    LogLevel.Verbose
  );

  const filterIndex = newFilters.findIndex(
    filter => filter.column === filterValue.column
  );

  if (filterIndex !== -1) {
    newFilters.splice(filterIndex, 1);
  }
};
