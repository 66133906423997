import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from 'store/selectors/authSelectors';
import { updatePreferences } from 'store/actions/preferencesActions';
import { getTasksQuickFilters } from 'store/selectors/preferencesSelectors';
import {
  FilterValue,
  setAppliedFiltersForTable,
  setApplyFilter,
  setFilters,
} from 'store/actions/filtersActions';
import {
  getCurrentTable,
  getCurrentTableFilters,
} from 'store/selectors/filtersSelectors';
import { CatalystTableType } from 'components/CatalystTable/types/catalystTableType';
import { TasksQuickFilterDates } from '../TasksQuickFilterDates/types/tasksQuickFilterDates';
import { TasksQuickFilterOwnership } from './types/tasksQuickFilterOwnership';
import { getOwnerPredicate } from './utils/getOwnerPredicate';
import { getAssigneePredicate } from './utils/getAssigneePredicate';
import { PreferencesTypes } from 'utils/types/api/preferences.types';
import { useCurrentTableSupportedColumns } from 'components/CatalystTable/hooks/useCurrentTableSupportedColumns';

const { Default, Assignees, Owners } = TasksQuickFilterOwnership;

export const useTasksQuickFiltersOwnership = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const filters = useSelector(getCurrentTableFilters);
  const user = useSelector(getUserDetails);
  const currentTable = useSelector(getCurrentTable) || CatalystTableType.Tasks;
  const { ownership, dates } = useSelector(getTasksQuickFilters);
  const columns = useCurrentTableSupportedColumns();

  const ownershipOptions = useMemo(
    () => [
      {
        value: Default,
        label: '------',
      },
      {
        value: Assignees,
        label: intl.formatMessage({
          id: 'tasks.imAssigneeOf',
          defaultMessage: `Tasks I'm only an assignee of`,
        }),
      },
      {
        value: Owners,
        label: intl.formatMessage({
          id: 'tasks.imOwnerOf',
          defaultMessage: `Tasks I'm the owner of`,
        }),
      },
    ],
    [intl]
  );
  const handleChangeOwnership = useCallback(
    (value: TasksQuickFilterOwnership) => {
      dispatch(
        updatePreferences(PreferencesTypes.FilterPreferences, {
          quickFilters: {
            tasks: {
              ownership: value,
              dates: dates || TasksQuickFilterDates.Default,
            },
          },
        })
      );

      if (!user || !columns[Assignees] || !columns[Owners]) {
        return;
      }

      const datesFilters = dates
        ? filters?.filter((f: FilterValue) => f.type === 'date')
        : [];

      switch (value) {
        case Assignees: {
          dispatch(
            setFilters(currentTable, [
              ...datesFilters,
              ...getAssigneePredicate(columns, user),
            ])
          );
          break;
        }
        case Owners: {
          dispatch(
            setFilters(currentTable, [
              ...datesFilters,
              ...getOwnerPredicate(columns, user),
            ])
          );
          break;
        }
        default: {
          dispatch(setFilters(currentTable, datesFilters));
          if (datesFilters?.length === 0) {
            dispatch(
              setAppliedFiltersForTable({ id: currentTable, value: false })
            );
          }
        }
      }
      dispatch(setApplyFilter(true, { sendToApi: true, isQuickFilter: true }));
    },
    [filters, user, columns, dispatch, currentTable, dates]
  );

  return { ownership, ownershipOptions, handleChangeOwnership };
};
