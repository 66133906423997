import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useBeforeunload } from 'react-beforeunload';
import FormPreview2 from 'components/FormPreview2';
import { Loader } from 'components/lib/Loader';
import SanitizedHTML from 'components/SanitizedHTML';
import SimpleLayout from 'components/SimpleLayout';
import Tooltip from 'components/lib/Tooltip';
import { ButtonPrimary, ButtonTertiary } from 'components/lib/Button';
import { COMPLETE_TASK_BUTTON, TASK_1TC_INSTRUCTIONS } from 'utils/testIds';
import SaveProgressModal from './SaveProgressModal';
import TaskHeader from './TaskHeader';
import useCompleteTaskBy1TC from './hooks';
import useCompleteTaskBy1TCStyles from './styles';
import LoaderOverlay from 'components/LoaderOverlay';
import TaskSummary from './TaskSummary';

const CompleteTaskBy1TC = () => {
  const classes = useCompleteTaskBy1TCStyles();
  const {
    data,
    schema,
    uiSchema,
    error,
    onFormStateChange,
    isFormTouched,
    hasErrors,
    formRef,
    loading,
    onSubmit,
    handleSaveProgress,
    handleCompleteTask,
    isTaskCompleted,
    saveProgressModal,
    customUploadUrl,
    initialValues,
    remountKey,
    objectClassId,
  } = useCompleteTaskBy1TC();
  useBeforeunload(event => isFormTouched && event.preventDefault());

  if (isTaskCompleted || error) {
    return <TaskSummary isSuccess={isTaskCompleted} name={data?.name} />;
  }

  if (!data) {
    return <LoaderOverlay />;
  }

  const { id, name, instructions, due_date, status } = data;

  return (
    <SimpleLayout>
      {loading && (
        <div className={classes.taskLoader}>
          <Loader size='large' />
        </div>
      )}
      <SaveProgressModal {...{ ...saveProgressModal, name }} />

      <div className={classes.taskLayout}>
        <div className={classes.taskContainer}>
          <TaskHeader {...{ id, name, due_date, status }} />

          {instructions && (
            <SanitizedHTML
              className={classes.taskInstructions}
              html={instructions}
              data-testid={TASK_1TC_INSTRUCTIONS}
            />
          )}

          <FormPreview2
            key={remountKey}
            ref={formRef}
            uischema={uiSchema}
            schema={schema}
            withFormHeader={false}
            isCreateMode
            additionalFieldProps={{
              customUploadUrl,
              shouldDownloadFileOnClick: true,
              classId: objectClassId?.toString(),
            }}
            {...{ onFormStateChange, onSubmit, initialValues }}
          />
        </div>

        <div className={classes.taskFormButtons}>
          <ButtonPrimary onClick={handleSaveProgress} disabled={!isFormTouched}>
            <FormattedMessage
              id='misc.saveProgress'
              defaultMessage='Save progress'
            />
          </ButtonPrimary>
          <div className={classes.taskFormButtonsDivider} />
          <Tooltip
            title={
              <FormattedMessage
                id='misc.mandatoryFields'
                defaultMessage='Mandatory fields are not complete'
              />
            }
            shouldBeRendered={hasErrors}
          >
            <ButtonTertiary
              onClick={handleCompleteTask}
              disabled={hasErrors}
              data-testid={COMPLETE_TASK_BUTTON}
            >
              <FormattedMessage id='misc.complete' defaultMessage='Complete' />
            </ButtonTertiary>
          </Tooltip>
        </div>
      </div>
    </SimpleLayout>
  );
};

export default CompleteTaskBy1TC;
