import InPlaceInputAfter from 'components/InPlaceEditControls/components/InPlaceInputAfter';
import { CheckboxGroup } from 'components/lib/Checkbox';
import MinMaxInfo from 'components/MinMaxInfo';
import useShouldRenderOnTop from 'hooks/useShouldRenderOnTop';
import React, { ForwardRefRenderFunction } from 'react';
import { IN_PLACE_EDIT_MULTISELECT_DROPDOWN } from 'utils/testIds';
import FieldValidationMessage from '../../components/FieldValidationMessage';
import { EditContentProps } from '../types';
import { getContainerRef } from '../utils';
import useEditContentStyles from './styles';

const EditContent: ForwardRefRenderFunction<
  HTMLDivElement,
  EditContentProps
> = (
  {
    isSaving,
    tempValue,
    onChange,
    errorMessage,
    options,
    maxSelected,
    minSelected,
    onClose,
  },
  ref
) => {
  const hasMinOrMaxSet =
    (maxSelected !== undefined && maxSelected !== null) ||
    (minSelected !== undefined && minSelected !== null);

  const { elementRef, customHeight } = useShouldRenderOnTop<HTMLDivElement>(
    getContainerRef
  );

  const styles = useEditContentStyles({
    isSaving,
    hasError: !!errorMessage,
    hasMinMaxSet: hasMinOrMaxSet,
  });

  const handleChange = (newValue: string[]) => {
    if (newValue.length === 0) {
      onChange(null);

      return;
    }
    if (!options) {
      return;
    }

    const sortedNewValue = [...newValue].sort((a, b) => {
      return (
        options.findIndex(option => option === a) -
        options.findIndex(option => option === b)
      );
    });

    onChange(sortedNewValue);
  };

  return (
    <div {...{ ref }} className={styles.editContentWrapper}>
      <div
        className={styles.checkboxesListWrapper}
        ref={elementRef}
        data-testid={IN_PLACE_EDIT_MULTISELECT_DROPDOWN}
        style={{ height: customHeight }}
      >
        <CheckboxGroup
          value={!!tempValue ? tempValue : undefined}
          onChange={newValue => handleChange(newValue as string[])}
          options={options?.map(option => ({
            label: option,
            value: option,
          }))}
        />
        {!isSaving && (
          <FieldValidationMessage
            className={styles.messageBox}
            error={errorMessage}
            displayAfterParent
            classNameOnTop={styles.messageBoxOnTop}
            message={
              hasMinOrMaxSet ? (
                <MinMaxInfo
                  maximum={maxSelected !== null ? maxSelected : undefined}
                  minimum={minSelected !== null ? minSelected : undefined}
                />
              ) : undefined
            }
          />
        )}
      </div>
      <InPlaceInputAfter
        isLoading={isSaving}
        className={styles.inPlaceEditAfter}
        onCloseClick={onClose}
      />
    </div>
  );
};

export default React.forwardRef(EditContent);
