import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useValidationListingStyles = createUseStyles(
  (theme: ThemeState) => ({
    validationContainer: {
      position: 'relative',
      borderTop: `1px solid ${theme['border-color-base']}`,
      background: theme['white-color'],
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      fontSize: '0.75rem',
      padding: '8px',
    },
    minMaxInfo: {
      marginTop: 0,
    },
  })
);
