import React, { useCallback } from 'react';
import { FormFulfillmentValidationProps } from './FormFulfillmentValidation.types';
import { useToggle } from 'hooks/useToggle';
import { useFormFulfillmentValidation } from 'pages/Records/components/FormFulfillmentValidation/hooks/useFormFulfillmentValidation';
import { FulfillmentValidationErrorModal } from './components/FulfillmentValidationErrorModal';
// eslint-disable-next-line
import FormPreview2 from 'components/FormPreview2';

/**
 * Wrapper component that validates fulfillment for {@link FormPreview2} component with proper modal display and validation state updates.
 */
export const FormFulfillmentValidation = ({
  formSchema,
  objectClassId,
  validatedContent,
}: FormFulfillmentValidationProps) => {
  const [
    isFulfillmentValidationModalOpen,
    {
      toggleOff: closeFulfillmentValidationModal,
      toggleOn: openFulfillmentValidationModal,
    },
  ] = useToggle(false);

  const onFulfillmentValidation = useCallback(
    (hasErrors: boolean) => {
      if (!hasErrors) {
        return;
      }

      openFulfillmentValidationModal();
    },
    [openFulfillmentValidationModal]
  );

  const {
    isValidatingFulfillment,
    fulfillmentErrors,
  } = useFormFulfillmentValidation(
    objectClassId,
    formSchema,
    onFulfillmentValidation
  );

  return (
    <>
      {validatedContent(isValidatingFulfillment, fulfillmentErrors)}
      <FulfillmentValidationErrorModal
        isOpen={isFulfillmentValidationModalOpen}
        closeModal={closeFulfillmentValidationModal}
      />
    </>
  );
};
